import React from 'react';
import { Grid } from '@mui/material';
import theme from "./theme/theme";
import { ThemeProvider as MuiThemeProvider } from '@mui/styles';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import SecureRoute from './components/security/secureRoute'
import NotAuthorized from "./components/noMatchPage/NoMatchApplication"
import NoMatchPage from "./components/noMatchPage/NoMatchPage"
import MainDashboard from './components/MainDashboard/MainDashboard';
import Login from './components/security/Login';
import { Spinner } from './utils/spinner/spinner';
import './App.css';
import { PATH_APP } from './constants';
import Reply_assessment from './components/noMatchPage/Reply_assessment';

function App() {

 

  return (
    <>
      
      <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <Spinner />
        <Grid id="mainGridContainer" style={{ backgroundColor: "#DFE7FD"}}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/auth/login" />}
            />
            <Route exact path="/auth/login" component={Login} code="LOGIN"/>
            <SecureRoute path={`${PATH_APP}`} component={MainDashboard} code='HOME'/> 
            <Route path="/pages/forbidden" component={NotAuthorized} />
            <Route path="/auth/reply-assessment" component={Reply_assessment} />
            <Route  component={NoMatchPage} />
          </Switch>
        </Grid>
      </MuiThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
