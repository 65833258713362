export const initialState = {
    totalValueQuestionnaire: '',
    typeValoration: '',
    data: {},
    activeValoration: false,
    disabledValors: true
  };
  
  const answerEvaluatorReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_ANSWER_EVALUATOR":
        return {
          ...state,
          totalValueQuestionnaire: action.totalValueQuestionnaire,
          typeValoration: action.typeValoration,
          data: action.data,
          activeValoration: action.activeValoration,
          disabledValors: action.disabledValors,

        };
      default:
        return state;
    }
  };
  
  export default answerEvaluatorReducer;
  