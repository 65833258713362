import HttpClient from '../services/HttpClient';
import {  QUESTION } from '../constants'

const type = "QUESTION"
export const getQuestions = () => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${type}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const createQuestion = (body, dispatch) => {
  dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
  return new Promise((resolve, reject) => {
      HttpClient.post(`${QUESTION}`, body).then(response => {
        dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        resolve(response);
      }).catch(error => {
        dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        resolve(error.response);
      });
  });
}

export const updateQuestion = (body, id) => {
    return new Promise((resolve, reject) => {
        HttpClient.patch(`${QUESTION}/${id}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response)
        })
    })
}

export const deleteQuestion = (id) => {
    return new Promise((resolve, reject) => {
      HttpClient.delete(`${QUESTION}/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve(error.response);
        });
    });
  };

  export const getfilterQuestions = (dataFilter, order, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true})
    return new Promise((resolve, reject) => {
       
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

        const keys = ['question', 'tagName', 'answerAlternativeCode' ];

        for (let index = 0; index < keys?.length; index++) {
          if(dataFilter[keys[index]] ){
            if(keys[index] === 'question' && dataFilter[keys[index]]){
              filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
            }else {
              filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
            }
        } else {
            continue
        }
          
          
        }
        HttpClient.get(`${QUESTION}/${filters}&orderBy=${order?.orderBy}`).then(response => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            resolve(response);
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            if (typeof error.response === 'undefined') {
                dispatch({
                    type: "SET_MODAL_ACTION",
                    open: true,
                    icon:'WARNING',
                    message: 'Occurió un problema comuniquese con el administrador.'
                  })
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getfilterQuestionsRelations = (dataFilter, order, dispatch ) => {
  dispatch({
      type: 'SET_ACTIVE_SPINNER',
      active: true
    })
  return new Promise((resolve, reject) => {
     
      let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}&totalElements=true`

      const keys = ['question', 'tagName' ];

      for (let index = 0; index < keys?.length; index++) {
        if(dataFilter[keys[index]]){
          filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
        } else {
            continue
        }
        
        
      }
      HttpClient.get(`${QUESTION}/${filters}&orderBy=${order?.orderBy}`).then(response => {
          dispatch({
              type: 'SET_ACTIVE_SPINNER',
              active: false
            })
          resolve(response);
      }).catch(error => {
          if (typeof error.response === 'undefined') {
              dispatch({
                  type: 'SET_ACTIVE_SPINNER',
                  active: false
                })
              dispatch({
                  type: "SET_MODAL_ACTION",
                  open: true,
                  icon:'WARNING',
                  message: 'Occurió un problema comuniquese con el administrador.'
                })
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}

export const getfilterQuestionsByAnswerAlternativeCode = (dataFilter, dispatch, type ) => {
  dispatch({ type: 'SET_ACTIVE_SPINNER', active: true })
  return new Promise((resolve, reject) => {
     
      let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}&totalElements=true&answerAlternativeCode=${type.type}`

      const keys = ['question', 'tagName' ];

      for (let index = 0; index < keys?.length; index++) {
        if(dataFilter[keys[index]]){
          filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
        } else {
            continue
        }
        
        
      }
      HttpClient.get(`${QUESTION}/${filters}`).then(response => {
          dispatch({
              type: 'SET_ACTIVE_SPINNER',
              active: false
            })
          resolve(response);
      }).catch(error => {
          if (typeof error.response === 'undefined') {
              dispatch({
                  type: 'SET_ACTIVE_SPINNER',
                  active: false
                })
              dispatch({
                  type: "SET_MODAL_ACTION",
                  open: true,
                  icon:'WARNING',
                  message: 'Occurió un problema comuniquese con el administrador.'
                })
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}
export const filterAtBankQuestions = (dataFilter, dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_SPINNER',
        active: true
      })
    return new Promise((resolve, reject) => {
       
        let filters = `?pageNumber=0&pageSize=10&totalElements=true`

        const keys = ['question', 'tagName' ];

        for (let index = 0; index < keys?.length; index++) {
          if(dataFilter[keys[index]]){
            filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
          } else {
              continue
          }
          
          
        }
        HttpClient.get(`${QUESTION}/${filters}`).then(response => {
            dispatch({
                type: 'SET_ACTIVE_SPINNER',
                active: false
              })
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                dispatch({
                    type: 'SET_ACTIVE_SPINNER',
                    active: false
                  })
                dispatch({
                    type: "SET_MODAL_ACTION",
                    open: true,
                    icon:'WARNING',
                    message: 'Occurió un problema comuniquese con el administrador.'
                  })
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}