import React, { useState } from "react";
import {
  Tooltip,
  Button,
} from "@mui/material";
import ModalDelete from '../../../../utils/form/modalConfirmBase'
import {  makeStyles } from "@mui/styles";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalConfirmAplication from "../modalAplicationCuestionary";
import moment from 'moment';
import {dowloadReport} from "../../../../actions/reportQuestionnaire"
import ModalReport from "../modalReport"
import { useStateValue } from "../../../../context/store";
import { useHistory } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PATH_APP } from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  MuiIconButtonEdgeEnd: {
    order: "-1",
  },
  root: {
    [theme.breakpoints.down('md')]: {
        flex: "1 1 auto",
        margin: "25% 2% 0% 2%",
        minHeight: "80vh",
        borderRadius: 10
      },
      [theme.breakpoints.up('md')]: {
        width: "97.5%",
        borderRadius: 10

      },
      [theme.breakpoints.up('lg')]: {
        margin: '24px 0% auto 2%',
        borderRadius: 10

      },
  },
  rootGrid: {
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    color: '#8E8E8E'
  },
  secondaryHeading: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  iconEvent: {
    color: "#646464",
    cursor: "pointer",
    height: 18,
    width: 18,
    pointerEvents: 'auto',
    '&:hover':{
      color: "#2F71CB",
    }
  },
  details: {
    alignItems: "center",
    backgroundColor: "#EEFBFF",
    pointerEvents: 'auto'
  },
  column: {
    flexBasis: "17.6%",
    padding: '1.5% 0px',
    background: '#FFFFFF', 
    textAlign: 'center'
  },
  columnOneTitle:{
    flexBasis: "15.6%",
    padding: '1.5% 0px',
    background: '#FFFFFF',
    
  },
  columnEvent: {
    paddingLeft: "10%",
    flexBasis: "33.33%",
    display: 'flex',
    alignItems: 'center'
  },
  withOutDisplay: {
    paddingLeft: "10%",
    display: 'flex',
    justifyContent: 'end',
    flexBasis: "33.33%",
    alignItems: 'center'
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  }, 
  titlesTable: {
    fontSize: 12, 
    display: 'flex', 
    color: '#8E8E8E', 
    width: '100%', 
    marginLeft: '2%', 
    position:'relative', 
    marginBottom: -8
  },
  header: {
    color: "#8E8E8E",
    fontSize: 14,
  },
  wrapper: {
    color: "#8E8E8E",
    fontSize: 14,
    fontWeight: 700,
  },
  table: {
      "& MuiTable-root": {
        backgroundColor: 'inherit'
      },
    "& .MuiTableCell-root": {
      backgroundColor: 'inherit',
      borderBottom: 'inherit'
    },
    "& .MuiTableCell-head": {
      paddingBottom: 0,
    },
    "& .MuiTableBody-root": {
      backgroundColor: 'white',
      borderRadius: '50rem !important'

    },
  },
}));

export default function Index({
  headers,
  dataQuestionary,
}) {
  const history = useHistory()
  const classes = useStyles();
  const [, dispatch] = useStateValue();
  const [openAlication, setOpenAplication] = useState(false)
  const [dataAplication, ] = useState({})
  const [messageDelete, ] = useState({
    open: false,
    type: '',
    description: '',
    handleClickOut: () => {},
    handleClick: () => {}
  })
  const [messageReport, setMessageReport] = useState({
    open: false,
    handleClickOutReport: () => {},
    handleClick: () => {},
    handleClose: () => {}
  })
  
  const closeReport = () => setMessageReport({...messageReport, open: false,})

  const handleReports = (response, name ) => {
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([response.data]))
    const contentType = response.headers['content-type']
    let reportExtension = ''
    if (contentType === 'application/vnd.ms-excel') {
      reportExtension = '.xlsx'
      link.setAttribute('download', `${name}` + reportExtension)
      document.body.appendChild(link)
      link.click()
    } else if (contentType === 'application/octet-stream') {
      reportExtension = 'xls'
    }
   
  }

  const reportQuestionary = (id) => {
    setMessageReport({...messageReport, open: false,})
    const dowload = async() => {
      const reportQuestionnaire = "QUESTIONNAIRE_REPORT";
      const nameReport = "REPORTE_CUESTIONARIO"
      const result = await dowloadReport(id, reportQuestionnaire);
      handleReports(result, nameReport)
    }
    dowload()
  }

  const reportQuestion = (id) => {
    setMessageReport({...messageReport, open: false,})
    const dowload = async() => {
      const reportQuestionnaire = "REPORT_BY_QUESTION";
      const nameReport = "REPORTE_POR_PREGUNTA"
      const result = await dowloadReport(id, reportQuestionnaire);
      handleReports(result, nameReport)
    }
    dowload()
  }


  const changeOpenModalReport = (x) => {
    return setMessageReport({
    open: true,
    handleClickOut: () => reportQuestionary(x?.uuid),
    handleClick: () =>  reportQuestion(x?.uuid),
    handleClose: () => closeReport()
  })
}
  
  const getDataAplication = (x) =>{
   
    history.push(`${PATH_APP}/aplication-detail`);
    dispatch({
      type: "SET_DATA_QUESTIONNAIRE",
      data: x,
      
    })
  }
  
  if(dataQuestionary.length){
  return (
    <React.Fragment>
      {/* TODO: PENDIENTE REVISION VISUAL */}
      <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Fecha de creación
                </TableCell>
                <TableCell align="left" width={"18%"} className={classes.wrapper}>
                  Desde
                </TableCell>
                <TableCell align="left" width={"15%"} className={classes.wrapper}>
                  Hasta
                </TableCell>
                <TableCell align="left" width={"28%"} className={classes.wrapper}>
                  Cuestionario
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataQuestionary.map((header, index) => (
                <TableRow key={index}>
                  <TableCell align="left" width={"20%"} className={classes.header}
                    style={{
                      borderTopLeftRadius: index === 0 ? 10 : 0,
                      borderBottomLeftRadius: index === dataQuestionary.length - 1 ? 10 : 0
                    }}
                  >
                    {moment(header?.createdAt).format('DD/MM/YYYY')}

                  </TableCell>
                  <TableCell align="left" width={"18%"} className={classes.header}>
                    {moment(header.startDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="left" width={"15%"} className={classes.header}>
                    {moment(header.endDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="left" width={"28%"} className={classes.header}>
                    {header?.questionnaire?.name}
                  </TableCell>
                  <TableCell align="left" width={"28%"} className={classes.header}
                    style={{ borderTopRightRadius: index === 0 ? 10 : 0, borderBottomRightRadius: index === dataQuestionary.length - 1 ? 10 : 0 }}
                  >
                    {/* <div className={header?.reportGenerated ? classes.columnEvent : classes.withOutDisplay} > */}
                    <div className={classes.columnEvent} >
                      <Tooltip title="Ver detalle" arrow placement="bottom">
                        <VisibilityIcon
                          onClick={() => getDataAplication(header)}
                          className={classes.iconEvent} />
                      </Tooltip>
                      &nbsp;&nbsp;
                      {header?.reportGenerated ?
                        <Button
                          variant="contained"
                          disableElevation
                          onClick={() => changeOpenModalReport(header)}
                        >
                          REPORTE
                        </Button>
                        :
                        <Tooltip title="Aún está vigente" arrow placement="bottom">
                          <Button
                            variant="contained"
                            disableElevation
                            style={{ background: '#BEBEBE', color: '#ffffff', cursor: 'auto' }}
                          >
                            REPORTE
                          </Button>
                        </Tooltip>
                      }
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    {/* <Grid container direction="row"  className={classes.root} cellspacing="0">
     <div className={classes.titlesTable}>
       <Typography style={{fontSize: 12, flexBasis: '20%'}}>Fecha de creación</Typography>
       <Typography style={{fontSize: 12, flexBasis: '18%'}}>Desde</Typography>
       <Typography style={{fontSize: 12, flexBasis: '15%'}}>Hasta</Typography>
       <Typography style={{fontSize: 12, flexBasis: '28%'}}>Cuestionario</Typography>
      </div>
      {dataQuestionary.map((header, index) => ( 
        <React.Fragment  key={index}>
      <Grid container  style={{  pointerEvents: 'auto', borderTopLeftRadius: index === 0 ? 10: 0, borderTopRightRadius: index === 0 ? 10: 0, background: '#FFFFFF', display: 'flex', alignItems: 'center' }}  id={index}>
        <Grid item lg={2} xs={2.5} className={classes.columnOneTitle} 
            id={index} 
            style={{  background: '#FFFFFF', borderTopLeftRadius: index === 0 ? 10 : 0, 
                      borderBottomLeftRadius: index === dataQuestionary.length -1 ? 10 : 0, display: 'flex', alignItems: 'center' }}
            >
          <Typography className={classes.heading} style={{marginLeft: '15%', marginTop: -10}}>
          {moment(header?.createdAt).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item lg={2} xs={3} className={classes.column} > 
          <Typography className={classes.heading} >
          {moment(header.startDate).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item lg={3} xs={3} className={classes.column} > 
          <Typography className={classes.heading} >
          {moment(header.endDate).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item lg={3} xs={3} className={classes.column} > 
          <Typography className={classes.heading} >
          {header?.questionnaire?.name}
          </Typography>
        </Grid>
        <Grid item lg={2} xs={2} 
        style={{  background: '#FFFFFF', display: 'flex', justifyContent: 'center', marginLeft: '10%',borderTopRightRadius: index === 0 ? 10 : 0, borderBottomRightRadius: index === dataQuestionary.length -1 ? 10 : 0}} 
        className={classes.column}
        >
          <div className={ classes.columnEvent } >
            <Tooltip title="Ver detalle" arrow placement="bottom">
              <VisibilityIcon 
                onClick={()=> getDataAplication(header) }
                className={classes.iconEvent} />
            </Tooltip>
            &nbsp;&nbsp;
            {header?.reportGenerated ?
                <Button 
                    variant="contained"
                    disableElevation
                    onClick={()=> changeOpenModalReport(header)}
                  >
                  REPORTE
                </Button> 
              :
              <Tooltip title="Aún está vigente"  arrow placement="bottom">
                <Button 
                    variant="contained"
                    disableElevation
                     style={{ background:'#BEBEBE' , color: '#ffffff',cursor: 'auto'}}
                  >
                  REPORTE
                </Button> 
              </Tooltip>
            }
          </div>
        </Grid>
      </Grid>
     
     </React.Fragment>
        ))}
        </Grid> */}
        {openAlication ? <ModalConfirmAplication 
          openAlication={openAlication}
          setOpenAplication={setOpenAplication}
          name={dataAplication?.name}
          type={dataAplication?.type === 'EVALUATIVE' ? 'Evaluativo' : 'No evaluativo' }
        /> :
        null
        }
         <ModalDelete 
            open={messageDelete.open}
            type={messageDelete.type}
            description={messageDelete.description}
            handleClick={messageDelete.handleClick}
            handleClickOut={messageDelete.handleClickOut}
          />
        <ModalReport 
          open={messageReport.open}
          handleClick={messageReport.handleClick}
          handleClose={messageReport.handleClose}
          handleClickOut={messageReport.handleClickOut}
        />
    </React.Fragment>
  )
  }else {
    return null
  }
}
