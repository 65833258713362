import React, {useEffect, useState} from 'react'
import { 
    Grid, 
    Typography, 
    CardContent, 
    Card, 
    TextField, 
    FormControl, 
    FormHelperText, 
    Accordion, 
    AccordionDetails, 
    AccordionSummary 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStateValue } from '../../../../context/store'
import Question from "./questionLinearScale"
import {styles} from "./styles";
import _ from "lodash";
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const Index = (props) => {
    const classes = styles();
    const [{ valorationAplication}, dispatch] = useStateValue()

    const [dataYup, ] = useState({})
    const [totalEva, setTotalEva] = useState(0);
    const [expanded, setExpanded] = useState({});
    const [totalInPercentage, setTotalInPercentage] = useState(0)

    const schema = yup.object().shape(dataYup);
    const {register, formState:{errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onTouched',
        reValidateMode: 'onChange'
    })
    
     
    useEffect(() => {

       
        setTotalEva(props?.dataDetail?.performanceEvaluation?.totalResult);
        setTotalInPercentage(props?.dataDetail?.performanceEvaluation?.totalResultPercentage)
        dispatch({
            type: "SET_VALORATE_APLICATION",
            disabledValors: valorationAplication?.disabledValors,
            data:  {competences :props?.dataDetail?.performanceEvaluation?.competences?.map((x,index)=> (
                {
                    uuid: x?.uuid,  
                    value: x?.value,  
                    position: index,
                    conducts:  x?.conducts?.map((c, pos=index)=> (
                        {
                            uuid: c?.uuid,
                            position: pos,
                            value: c?.value,
                            questions: c?.questions?.map((q, posQ=index)=> (
                                {
                                    uuid: q?.uuid,
                                    value: x?.value,
                                    position: posQ
                                }
                            ))
                        }
                    ))       
                }
             ))}
         })
       
    }, [])
    

    const handleChange = (panel) => {
      
        if(expanded[panel]){
            const differents = {...expanded, [panel]:false}
            setExpanded(differents)
        }else {

            setExpanded({...expanded, [panel]: true});
        }
      };

       
  
    return (
     
        <Grid container spacing={2} justifyContent="center" >
            {props?.dataDetail?.performanceEvaluation?.competences?.sort((a, b)=> Number(a?.position) - Number(b?.position) )?.map((c,index)=> (
            <Grid item lg={12}>
                 <Accordion expanded={expanded[`Competence${index+1}`]} onChange={()=>handleChange(`Competence${index+1}`)}>
                    <AccordionSummary 
                        aria-controls="panel1d-content" 
                        id="panel1d-header" 
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Grid item container lg={12} md={12} style={{background: 'rgba(154, 187, 100, 0.46)', borderRadius: 5}} alignItems="center">
                            <Grid item lg={7} md={7}>
                                <Typography style={{textAlign: 'start', fontSize: 20, fontWeight: 400, padding: 12}}>{`Criterio ${index+1}`} </Typography>
                            </Grid>
                            <Grid item container lg={5} md={5} alignItems="center" justifyContent="space-between">
                                <Grid item  lg={5} style={{display: 'flex', alignItems: 'center'}}>
                                    <Typography className={classes.titleLabels} >TOTAL </Typography>
                                    <Grid item xs={12} sm={12} md={12} lg={4.5}>
                                        <FormControl variant='outlined' fullWidth size="small">
                                            <TextField
                                            {...register(c?.uuid)}
                                            name={c?.uuid}
                                            className={classes.dropdown}
                                            fullWidth
                                            value={c?.result?.toFixed(2)}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true ,
                                                notched:true ,
                                            }}
                                            size="small"
                                            error={!!errors.hasOwnProperty(c?.uuid) && errors[c?.uuid].message}
                                            />
                                        </FormControl>
                                        <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                                            {errors.hasOwnProperty(c?.uuid) &&
                                                errors[c?.uuid].message}
                                        </FormHelperText> 
                                    </Grid>
                                </Grid>
                                <Grid item  lg={7} style={{display: 'flex', alignItems: 'center'}}>
                                    <Typography className={classes.titleLabels} >PORCENTAJE </Typography>
                                    <Grid item xs={12} sm={12} md={12} lg={4}>
                                        <FormControl variant='outlined' fullWidth size="small">
                                            <TextField
                                            {...register(c?.uuid)}
                                            name={c?.uuid}
                                            className={classes.dropdown}
                                            fullWidth
                                            value={`${c?.resultPercentage?.toFixed(2)}%`}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true ,
                                                notched:true ,
                                            }}
                                            size="small"
                                            error={!!errors.hasOwnProperty(c?.uuid) && errors[c?.uuid].message}
                                            />
                                        </FormControl>
                                        <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                                            {errors.hasOwnProperty(c?.uuid) &&
                                                errors[c?.uuid].message}
                                        </FormHelperText> 
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>

                <AccordionDetails>
                    <Grid item lg={12} md={12}>
                        <Typography style={{textAlign: 'justify', fontSize: 14, fontWeight: 400, color: '#8E8E8E', margin: ' 10px auto 20px auto'}}>{c?.name} </Typography>
                    </Grid>
                    <Grid item container lg={12} md={12} style={{background: '#E4E4E4', borderRadius: 5,  marginBottom: '5%',}} alignItems="center">
                        <Grid item lg={7} md={7}>
                            <Typography style={{textAlign: 'start', fontSize: 20, fontWeight: 400, padding: 12}}>NIVEL DE DOMINIO: </Typography>
                        </Grid>
                        <Grid item container lg={5} md={5} alignItems="center" justifyContent="space-between">
                            <Grid item  lg={6} style={{display: 'flex', alignItems: 'center'}}>
                                <Typography className={classes.titleLabels} >OBTENIDO </Typography>
                                <Grid item xs={12} sm={12} md={12} lg={3.5}>
                                    <FormControl variant='outlined' fullWidth size="small">
                                        <TextField
                                        {...register(c?.uuid)}
                                        name={c?.uuid}
                                        className={c?.domainLevelResult === "REACHED" ? classes.dropdownDomainGreen : classes.dropdownDomainRed}
                                        fullWidth
                                        value={c?.domainLevelObtained?.toFixed(2)}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true ,
                                            notched:true ,
                                        }}
                                        size="small"
                                        error={!!errors.hasOwnProperty(c?.uuid) && errors[c?.uuid].message}
                                        />
                                    </FormControl>
                                    <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                                        {errors.hasOwnProperty(c?.uuid) &&
                                            errors[c?.uuid].message}
                                    </FormHelperText> 
                                </Grid>
                            </Grid>
                            <Grid item  lg={6} style={{display: 'flex', alignItems: 'center'}}>
                                <Typography className={classes.titleLabels} >ESPERADO </Typography>
                                <Grid item xs={12} sm={12} md={12} lg={3.5}>
                                    <FormControl variant='outlined' fullWidth size="small">
                                        <TextField
                                        {...register(c?.uuid)}
                                        name={c?.uuid}
                                        className={c?.domainLevelResult === "REACHED" ? classes.dropdownDomainGreen : classes.dropdownDomainRed}
                                        fullWidth
                                        value={c?.domainLevel}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true ,
                                            notched:true ,
                                        }}
                                        size="small"
                                        error={!!errors.hasOwnProperty(c?.uuid) && errors[c?.uuid].message}
                                        />
                                    </FormControl>
                                    <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                                        {errors.hasOwnProperty(c?.uuid) &&
                                            errors[c?.uuid].message}
                                    </FormHelperText> 
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                  
                </AccordionDetails>

                {_.orderBy(c?.conducts, ['position'],["asc"])?.map((b, posB=index)=> (
                    <Accordion expanded={expanded[`Aspecto ${posB+1}`]} onChange={()=>handleChange(`Aspecto ${posB+1}`)}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header"  expandIcon={<ExpandMoreIcon />}>
                        <Grid item container lg={12} md={12} style={{background: 'rgba(245, 178, 117, 0.55)', borderRadius: 5}} alignItems="center">
                            <Grid item lg={7} md={7}>
                                <Typography style={{textAlign: 'start', fontSize: 20, fontWeight: 400, padding: 12}}>{`Aspecto ${posB+1}`} </Typography>
                            </Grid>
                            <Grid item container lg={5} md={5} alignItems="center" justifyContent="space-between">
                                <Grid item  lg={5} style={{display: 'flex', alignItems: 'center'}}>
                                    <Typography className={classes.titleLabels} >TOTAL </Typography>
                                    <Grid item xs={12} sm={12} md={12} lg={4}>
                                        <FormControl variant='outlined' fullWidth size="small">
                                            <TextField
                                            {...register(`${c?.uuid}${posB}`)}
                                            name={`${c?.uuid}${posB}`}
                                            className={classes.dropdown}
                                            fullWidth
                                            disabled
                                            value={b?.result?.toFixed(2)}
                                            InputLabelProps={{
                                                shrink:  true,
                                                notched: true,
                                            }}
                                            size="small"
                                            error={!!errors.hasOwnProperty(`${c?.uuid}${posB}`) && errors[`${c?.uuid}${posB}`].message}
                                            />
                                        </FormControl>
                                        <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                                            {errors.hasOwnProperty(`${c?.uuid}${posB}`) &&
                                                errors[`${c?.uuid}${posB}`].message}
                                        </FormHelperText> 
                                    </Grid>
                                </Grid>
                                <Grid item  lg={7} style={{display: 'flex', alignItems: 'center'}}>
                                    <Typography className={classes.titleLabels} >PORCENTAJE </Typography>
                                    <Grid item xs={12} sm={12} md={12} lg={4}>
                                        <FormControl variant='outlined' fullWidth size="small">
                                            <TextField
                                            {...register(`${c?.uuid}${posB}`)}
                                            name={`${c?.uuid}${posB}`}
                                            className={classes.dropdown}
                                            fullWidth
                                            disabled
                                            value={`${b?.resultPercentage}%`}
                                            InputLabelProps={{
                                                shrink:  true,
                                                notched: true,
                                            }}
                                            size="small"
                                            error={!!errors.hasOwnProperty(`${c?.uuid}${posB}`) && errors[`${c?.uuid}${posB}`].message}
                                            />
                                        </FormControl>
                                        <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                                            {errors.hasOwnProperty(`${c?.uuid}${posB}`) &&
                                                errors[`${c?.uuid}${posB}`].message}
                                        </FormHelperText> 
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item lg={12} md={12}>
                                <Typography style={{textAlign: 'justify', fontSize: 14, fontWeight: 400, color: '#8E8E8E', margin: ' 10px auto 20px auto'}}>{b?.name} </Typography>
                            </Grid>
                            {_.orderBy(b?.questions, ['position'],["asc"])?.map((x, index)=> (
                                <>
                                    <Grid item container lg={12} md={12} >
                                        <Grid item lg={12} style={{textAlign: 'center'}}>
                                            <Typography className={classes.key} style={{ fontSize: 20, fontWeight: 400, background: '#B7C1F3'}}>{`Pregunta ${index+1}`} </Typography>
                                        </Grid>
                                        <Grid item lg={12} style={{textAlign: 'center'}}>
                                            <Typography className={classes.value} style={{ wordBreak: 'break-all', margin: ' 10px auto 20px auto'}}>{x?.question}</Typography>
                                        </Grid>
                                    </Grid> 
                                    <Grid item={10}>
                                        {x?.answerAlternativeCode === 'ESL' ?
                                            <Question 
                                                competenceId={c?.uuid}
                                                behaviorId={b?.uuid}
                                                uuid={x?.uuid}
                                                valuesRanking={x?.questionAlternativeList}
                                            /> 
                                        : null
                                        }
                                    </Grid>
                                </>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}

         </Accordion>
          
        </Grid>
            ))}
            <Grid item lg={12} md={12}>
                  <Card elevation={3} style={{ background:'rgb(239 239 239)', padding: 5, marginBottom: '3%'}}>
                    <CardContent style={{background:  '#5EA058' }}>
                        <Grid item container lg={12} alignItems="center" justifyContent="space-between">
                            <Grid item lg={6.8} md={7}>
                                <Typography style={{textAlign: 'start', fontSize: 20, fontWeight: 400, background: '#5EA058', color: '#ffffff'}}>RESULTADO</Typography>
                            </Grid>
                            <Grid item container lg={5.2} md={5} alignItems="center" justifyContent="space-between">
                                <Grid item xs={12} sm={12} md={12} lg={4} style={{display: 'flex', alignItems: 'center'}}>
                                <Typography className={classes.titleLabelsTotal}>TOTAL</Typography>
                                    <FormControl variant='outlined' fullWidth size="small">
                                        <TextField
                                        className={classes.dropdown}
                                        fullWidth
                                        disabled
                                        value={totalEva}
                                        InputLabelProps={{
                                            shrink:  true,
                                            notched: true,
                                        }}
                                        size="small"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} style={{display: 'flex', alignItems: 'center'}}>
                                <Typography className={classes.titleLabelsTotal}>PORCENTAJE</Typography>
                                    <FormControl variant='outlined' fullWidth size="small">
                                        <TextField
                                            className={classes.dropdown}
                                            fullWidth
                                            disabled
                                            value={`${totalInPercentage}%`}
                                            InputLabelProps={{
                                                shrink:  true,
                                                notched: true,
                                            }}
                                            size="small"
                            
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                       
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Index