import React from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styles } from "../styles";
import { Grid, CardContent, Typography, Card } from "@mui/material";
import "./questions.styles.css";

export default function Preview(props) {
  const classes = styles();

  return (
    <div>
      <Dialog
        className={
          props?.scale ? classes.modalPreviewScale : classes.modalPreview
        }
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.titleCreteQuestion}
        >
          PREVISUALIZAR
        </DialogTitle>
        <DialogContent className="modal-preview">
          <DialogContentText id="alert-dialog-description">
            <Card elevation={3} className="card">
              <CardContent className="cardContent">
                <Grid container>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography className="key">Pregunta</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item lg={12} className="wrapperQuestion">
                      <Typography className="value">
                        {props?.question}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {props?.children}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <div style={{ marginTop: "2%" }}>
              <Button
                variant="outlined"
                disableElevation
                onClick={() => props.handleClose()}
                style={{
                  color: "#6D9FFF",
                  marginBottom: 5,
                  borderColor: "#80808070",
                  marginLeft: "47%",
                }}
              >
                VOLVER
              </Button>
              &nbsp;&nbsp;
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
