import React from 'react';
import { Grid, Typography } from '@mui/material';

const DemoBar = ({data}) => {
  

  return (
      <Grid item container lg={10} style={{paddingBottom: '2%', display: 'flex'}} alignItems="center" justifyContent="space-between">
        <Grid item container lg={10} justifyContent="space-between" style={{borderBottom: '2px solid',   color: 'gray', display: 'flex'}}>
          <Typography style={{fontWeight: 700, fontSize: 10}}>0%</Typography>
          <Typography style={{fontWeight: 700, fontSize: 10}}>50%</Typography>
          <Typography style={{fontWeight: 700, fontSize: 10}}>100%</Typography>

        </Grid>
      
        {data?.map((x)=> (
          <Grid item container lg={11} style={{borderLeft: '2px solid', color: 'gray', padding: '4% 0px 2% 0%'}} alignItems="center">
          <Grid item lg={11} style={{border: `2px solid ${x?.color}`, height: 30}}>
            <div style={{ background: `${x?.color}`, padding: '2px 0px', width: `${x?.value}%`, height: 28}}>
            </div>
            
          </Grid>
          <Grid item lg={1} style={{paddingLeft: '2%'}}>
            <Typography style={{fontSize: 10, fontWeight: 700}}>{x?.value}%</Typography>
          </Grid>
        </Grid>
        ))}
      </Grid>
  )
};

export default DemoBar;