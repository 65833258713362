import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../../context/store';
import Header from "./header";
import { getByIdAplicationEvaluationAdmin } from "../../../actions/ManagementAplicationEvaluations-actions"
import Evaluation from "./evaluation";
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import { PATH_APP } from '../../../constants';
import { showMessageWithOutAction } from '../../../utils/constants';
import LoadingPage from './../../../utils/loadingPage/loadinPage';

const Index = (props) => {
    const {id} = props?.match?.params
    const idEvaluation = id
    const [dataDetail, setDataDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [, dispatch] = useStateValue();
    const [message, setMessage] = useState({
      description : '',
      textButtonSubmit : '',
      handleClick : () => {} ,
      handleClickOut : () => {},
      oneButtons : false,
      title: '',
      list: [],
      type : '',
      open : false
    })

  
    useEffect(()=> {
      setLoading(true);
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Detalle aplicación de evaluación",
        index: 13,
        module: 'DETAIL_APLICATION_EVALUATION',
        subPath: `${PATH_APP}/management-aplication-evaluations`,
      }); 
          getEvaluation()
    },[])

    const getEvaluation = async() => {
      const result = await getByIdAplicationEvaluationAdmin(idEvaluation, dispatch);

      if(result?.status >= 200 && result?.status <= 204 ){
        setDataDetail(result?.data);
        setLoading(false);
        return;
      }else {
        showMessageWithOutAction(dispatch, 'Información de evaluación no encontrada', '/management-aplication-evaluations' );
        setLoading(false);
      }
      

  }

  

  return (
    <React.Fragment>
        {loading 
          ? <LoadingPage />
          : (
              <>
                <Header id={idEvaluation} dataDetail={dataDetail} message={message} setMessage={setMessage}/>
                <Evaluation dataDetail={dataDetail}/>
              </>
            ) 
             }

        <ModalConfirm 
          description = { message.description }
          textButtonSubmit = { message.textButtonSubmit }
          handleClick = { message.handleClick }
          handleClickOut = { message.handleClickOut }
          oneButtons = { message.oneButtons }
          title = { message.title }
          list = {message.list}
          type = { message.type }
          open = { message.open }
        />
      
    </React.Fragment>
  )
}

export default Index