import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

function CriteriaTable({ criteriaRegister }) {


  return (
    <TableContainer id="headerCriteria">
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: '#C5E6EF', fontWeight: 500, fontSize: '20px' }}>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Criterio</TableCell>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Aspecto</TableCell>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Autoevaluación</TableCell>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Estudiantes</TableCell>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Institucional</TableCell>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Promedio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {criteriaRegister.map(registro => (
            <React.Fragment key={registro.id}>
              {registro?.title 
               ?  <TableRow>
                    <TableCell
                      colSpan={6}
                      style={{ backgroundColor: '#DDDDDD', fontWeight: 400, fontSize: '16px' }}
                    >
                      {registro.title}
                    </TableCell>
                  </TableRow>
                : null
              }
              {!registro?.title 
                ? <TableRow key={registro?.id} style={{ backgroundColor: '#EFF4F6' }}>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.appearance}</TableCell>
                    <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>
                      {registro.selfAssessment}
                    </TableCell>
                    <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.student}</TableCell>
                    <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>
                      {registro.institutional}
                    </TableCell>
                    <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.average}</TableCell>
                  </TableRow> 
                : null
              }
                
         
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CriteriaTable;
