export const APP_URL = `${process.env.REACT_APP_API_URL}`
export const PATH_APP = '/autoevaluaciones'
export const MAX = "/max"
export const QUESTION = "v1/question"
export const LABEL = "v1/tag"
export const CONDUCT = "v1/conduct"
export const MULTIPLE = "/multiple";
export const QUESTIONARY = 'v1/questionnaire';
export const ANSWER_ALTERNATIVE = 'v1/answer-alternative'
export const TYPE = "/type";
export const RESULT = "/results";
export const GENERALRESULT = "/general-result"
export const USER = "v1/user"
export const STATUS = "v1/status"
export const APLICATION = "/apply"
export const LOGIN = "v1/authentication"
export const USERS = "v1/users"
export const REPORT = "/report"
export const QUESTIONNAIRETOANSWER = "v1/questionnaire-to-answer"
export const STATUSANSWER = "/status-type"
export const CRITERIA_EVALUATION = 'v1/criteria-evaluation'
export const PERFORMANCE = "v1/performance-evaluation";
export const PERFORMANCEV2 = "v2/performance-evaluation";
export const EVALUATION_EVALUATED = "v2/performance-evaluation-to-answer"
export const SECTION = "v1/section"
export const COMPETENCE = "v1/competence";
export const ORGANIZATION_CHART = "v1/organization-chart";
export const REGIONAL = "/regional";
export const POSITION = "/position";
export const EMPLOYEE = "/employee"
export const EVALUATED = "/evaluated"
export const STATES = "/status";
export const EVALUATOR = "/evaluator";
export const BY_EVALUATED = "/by-evaluated";
export const BY_PROGRAM = "/by-programId";
export const EVALUATOR_TYPE = "/evaluator-type";
export const REQUIRED_MESSAGE = "Este campo es obligatorio";
export const THERE_EXIST_TAG_NAME = 'Ya existe una etiqueta con ese nombre'
export const ROLACCESS = {
      
    ADMINISTRATOR: 
    [
        'HOME',
        'LABEL',
        'QUESTION',
        'CREATE_QUESTION',
        // 'QUESTIONARY',
        // 'QUESTIONARY_CREATE',
        // 'QUESTIONARY_APLICATION',
        'DETAIL_APLICATION',
        // 'ASSESS_QUESTIONARY',
        // 'ANSWER_QUESTIONARY',
        // 'ANSWER_QUESTIONARY_RESPONSE',
        'COMPETENCIES',
        'CRITERIA',
        'BEHAVIORS',
        'PERFORMANCE_EVALUATION',
        'PERFORMANCE_EVALUATION_CREATE',
        'MANAGEMENT_APLICATION_EVALUATIONS'
    ],
    EVALUATED: 
    [
        'HOME',
        // 'ANSWER_QUESTIONARY',
        'PERFORMANCE_EVALUATION_EVALUATED',
    ],
    EVALUATOR: 
    [
      'HOME',
      // 'ANSWER_QUESTIONARY',
      'PERFORMANCE_EVALUATION_EVALUATED',
    ]
}

export const typesEvaluators = {
    TEACHER: 'Docente tiempo',
    PROGRAM_DIRECTOR: 'Director',
    STUDENT: 'Estudiante',
    CHAIR_TEACHER : 'Docente catedra'
}

export const typesEvaluation = {
    SELF_EVALUATION:  'Autoevaluación',
    PEER_EVALUATION: 'Evaluación estudiantes',
    LEADER_EVALUATION: 'Evaluación institucional'
};

export const titleHeader = (title) => {
    switch (title) {
      case 'Dir.Prog':
        return "Dr_Prg";
   
      case 'Coor':
        return 'Cor';
    
      case 'Doc':
        return 'Doc'
      
        case 'Est':
        return 'Est'

      default: return title
    }
  }

  export const typesQuestions={
    ESL: 'Escala lineal',
    TEX: 'Texto' 
  }

  export const resetDispatchs = (dispatch) => {
    dispatch({
      type: "SET_RELATIONS_EVALUATION",
      data: [],
      competencieSelected: ''
    })
    dispatch({
      type: "SET_DATA_QUESTIONNAIRE",
      data: null
    })
    dispatch({
      type: "SET_RESET_PAGE",
      reset: true
    })
    dispatch({
      type: "SET_TYPES_EVALUATIONS",
      automatic: null,
      applicationTypes: [],
      submitPercentage: false,
      users: [],
      pages: [],
      selectNewLeaders: []
    });
    dispatch({
      type: "SET_FILTER_QUESTION",
      filter: {},
      isFilter: false,
    })
    dispatch({
      type: "SET_FILTER_BEHAVIOR",
      filter: {},
      isFilter: false,
    });
    dispatch({
      type: "SET_FILTER_COMPETENCIE",
      filter: {},
      isFilter: false,
    });
    dispatch({
      type: "SET_FILTER_EVALUATION_PERFORMANCE",
      filter: {},
      isFilter: false,
    });
    dispatch({
      type: "SET_FILTER_MANAGEMENT_APLICATION_EVALUATION",
      filter: {},
      isFilter: false,
    })
    dispatch({
      type: "SET_FILTER_ANSWER_EVALUATION",
      filter: {},
      isFilter: false,
    });
  }

