import React, {useState, useEffect} from "react";
import { makeStyles } from "@mui/styles";
import { Button, Modal } from "@mui/material";
import SearchBehaviors from "../../behavior/searchModalPerformance"
import { useStateValue } from "../../../context/store";
import ModalConfirm from "../../../utils/form/modalConfirmBase"
import { getfilterBehaviors, getLengthBehaviors } from '../../../actions/Behavior-actions';
import ListBehaviors from "./listBehaviors";
import _ from "lodash"

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: '90%',
    height: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.24)",
    outline: "none",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
  },
  cancel1: { 
     color: "#2F71CB", 
     marginBottom: 5, 
     borderColor: '#80808070',
     "&:hover": {
      borderColor: '#80808070',
    },
  },
  cancel: { 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5, 
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
 },
  text: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#4B4B4B",
    fontWeight: 400,
    textAlign: "center",
    margin: "25px 20px",
  },
  titleAlert: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#2C2C2C",
  },
  widthIcon: {
    fontSize: 70,
    color: '#2F71CB',
    marginTop: '3%'

  },
  confirmBanck :{ 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5,
    // width: 'fit-content' ,
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
  }
}));

const ModalConfirmBase = ({
  open,
  handleClickOut,
  item,
  levens,
  onChangeLevels,
  description,
  title,
  oneButtons,
  textButtonSubmit,
}) => {
  const classes = useStyles();
  const [{competencieSearch, behaviorSearch, loadingTables, relations}, dispatch] = useStateValue()
  const [modalStyle] = React.useState(getModalStyle);
 const [listCompetencies, setListCompetencies] = useState([])
 const [selectedBehaviors, setSelectedBehaviors] = useState([])
 const [clearFielForm, setClearFielForm]= useState(false)
 const [data, setData] = useState({
  code: null,
  tagUuid: null,
  status: null,
  question: null,
  })
  const [paginadorResponse, setPaginadorResponse] = useState({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  const [paginadorRequest, setPaginadorRequest] = useState({
    page: 0,
    size: 10,
  });
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
  })
  

  const closeModalAndClear = () => setClearFielForm(!clearFielForm)
  const closeModal = () => {setMessage({...message, open:false}); closeModalAndClear()}
  const dataFilter = () => setPaginadorResponse({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  

  const getAllBehaviorsActives = async() => {
    const result = await getLengthBehaviors({orderBy: 'name'})
    dispatch({
      type: "SET_FILTER_COMPETENCIE",
      filter: {
          competencie: data?.competencie ,
          code: data?.code,
          status:  data.status,
          page: paginadorRequest?.page,
          size: paginadorRequest?.size
        },
        isFilter: false,
  })
  if(result?.status === 204 && !competencieSearch?.typeAction){
    setMessage({
      open : true,
      description: 'No se encontró información relacionada a la búsqueda',
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
   }
   if(result?.data?.content?.length < 1 && !competencieSearch?.typeAction){
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
    return setMessage({
      open : true,
      description: 'No se encontró información relacionada a la búsqueda',
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
   }
  if ((result && result.status === 200) || (result.status === 201)) {
    const data = result?.data;
    if (data?.content?.length > 0) {
      setPaginadorResponse({
        data: data?.content,
        totalRows: data?.totalElements,
        pageSize: data?.size,
        pageNumber: data?.number,
      });
    }
    
  } else {
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
  }
  } 

  const getAllCompetencies = async() => {
    const result = await getfilterBehaviors({
      code : data.code === null ? null : data?.code,
      name: data.question === null ? null : data?.question,
      status: data.status === null ? null : data?.status,
      page: paginadorRequest?.page === null ? null : paginadorRequest?.page,
      size:  paginadorRequest?.size === null ? null : paginadorRequest?.size,
      
  },
  dispatch,
  {orderBy: 'createdAt'},
  );


 if(result?.status === 204 && !behaviorSearch?.typeAction){
  setMessage({
    open : true,
    description: 'No se encontró información relacionada a la búsqueda',
    handleClick : () => closeModal() ,
    oneButtons : true,
    textButtonSubmit: 'ACEPTAR',
    type : 'WARNING',
  })
 }
 if(result?.status === 400){
  setMessage({
    open : true,
    description: 'No se encontró información relacionada a la búsqueda',
    handleClick : () => closeModal() ,
    oneButtons : true,
    textButtonSubmit: 'ACEPTAR',
    type : 'WARNING',
  })
 }
 if(result?.status > 399 && result?.status < 405  && !behaviorSearch?.typeAction){
  setMessage({
    open : true,
    description: 'No se encontró información relacionada a la búsqueda',
    handleClick : () => closeModal() ,
    oneButtons : true,
    textButtonSubmit: 'ACEPTAR',
    type : 'WARNING',
  })
 }
 if(result?.data?.content?.length < 1 && !behaviorSearch?.typeAction){
  setPaginadorResponse({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  return setMessage({
    open : true,
    description: 'No se encontró información relacionada a la búsqueda',
    handleClick : () => closeModal() ,
    oneButtons : true,
    textButtonSubmit: 'ACEPTAR',
    type : 'WARNING',
  })
 }
if ((result && result.status === 200 )|| (result.status === 201)) {
  const data = result?.data;
  if (data?.content?.length > 0) {
    setPaginadorResponse({
      data: data?.content,
      totalRows: data?.totalElements,
      pageSize: data?.size,
      pageNumber: data?.number,
    });
  }
  
} else {
  setPaginadorResponse({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
}
  }



  if(behaviorSearch &&
    behaviorSearch?.isFilter) {
      const newData = behaviorSearch?.filter
      data.code = newData?.code;
      data.tagUuid = newData?.tagUuid
      data.status = newData?.status;
      data.question = newData?.question;
      paginadorRequest.page = newData?.page;
      paginadorRequest.size = newData?.size
      getAllCompetencies()
      dispatch({
        type: "SET_FILTER_BEHAVIOR",
        filter: behaviorSearch?.filter,
        isFilter: false,
      })
  }


  const addBehaviors = () => {
 
      const findLeven = levens?.find((x)=> x?.domainLevel === item)
      const differentLevel = levens?.filter((x)=> x?.domainLevel !== item)
      const newLevel = {...findLeven, behaviors: selectedBehaviors }

      differentLevel?.push(newLevel)

      onChangeLevels(_.orderBy(differentLevel, ['domainLevel'],['asc']) )
      handleClickOut()

  }

  const getBehaviors = (x) => setSelectedBehaviors(x)

  const bodyModal = (

    <div style={modalStyle} className={classes.paper}>
      <SearchBehaviors 
        competencies={listCompetencies}
        clearFielForm={clearFielForm}
        dataFilter={dataFilter}
        getAllBehaviorsActives={getAllBehaviorsActives}
      />
       <ListBehaviors data={[...paginadorResponse?.data]} getBehaviors={getBehaviors} levens={levens} item={item}/>
      <div style={{ display: "flex", justifyContent: "center", alignItems: 'baseline', flexDirection: null}}>
        <React.Fragment >
          <Button
            className={ classes.cancel1 }
            variant="outlined"
            onClick={()=>handleClickOut()}
          >
            CANCELAR
          </Button>

          <Button
            type="submit"
            variant="contained"
            onClick={()=> addBehaviors()}
            style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 5, marginTop: 20 }}
          >
            ASOCIAR
          </Button>
        </React.Fragment>
      </div>
    </div>
  );

  
  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
    </div>
  );
};

export default ModalConfirmBase;
