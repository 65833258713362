import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../../../../context/store';
import Header from "./header";
import { getResultEvaluationEvalutor } from "../../../../../actions/ManagementAplicationEvaluations-actions"
import Evaluation from "./evaluation";
import CircularProgress from '@mui/material/CircularProgress';
import ModalConfirm from "../../../../../utils/form/modalConfirmBase";
import { Box } from '@mui/material';
import { PATH_APP } from '../../../../../constants';
import { showMessageWithOutAction } from '../../../../../utils/constants';

const Index = (props) => {
    const {applicationId, evaluatorId, evaluatedId, typeEvaluation} = props?.match?.params
    const idEvaluation = applicationId
    const type = typeEvaluation; 
    const [dataDetail, setDataDetail] = useState({})
    const [{spinner, }, dispatch] = useStateValue();
    const [message, setMessage] = useState({
      description : '',
      textButtonSubmit : '',
      handleClick : () => {} ,
      handleClickOut : () => {},
      oneButtons : false,
      title: '',
      list: [],
      type : '',
      open : false
    })

  
    useEffect(()=> {
  
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Resultado de la evaluación",
        index: 13,
        module: 'EVALUATION_EVALUATED',
        subPath: `${PATH_APP}/management-aplication-evaluateds/${idEvaluation}`,
      }); 
          getEvaluation()
    },[])

    const getEvaluation = async() => {
       await getResultEvaluationEvalutor(applicationId, evaluatorId, evaluatedId, type,dispatch)
      .then((res) => {
        setDataDetail(res?.data)
      })
      .catch((err) => {
         showMessageWithOutAction(dispatch, `${err?.data?.message ?? 'Ocurrió un error al obtener los resultados'}`, `${PATH_APP}/management-aplication-evaluateds/${idEvaluation}`,)
      })
  }

  

  return (
    <React.Fragment>
       { !spinner?.active 
          &&  (
                <>
                  <Header id={idEvaluation} dataDetail={dataDetail} message={message} setMessage={setMessage}/>
                  <Evaluation dataDetail={dataDetail}/>
                </>
            )
             }
            <ModalConfirm 
              description = { message.description }
              textButtonSubmit = { message.textButtonSubmit }
              handleClick = { message.handleClick }
              handleClickOut = { message.handleClickOut }
              oneButtons = { message.oneButtons }
              title = { message.title }
              list = {message.list}
              type = { message.type }
              open = { message.open }
            />
      
    </React.Fragment>
  )
}

export default Index