import HttpClient from '../services/HttpClient';
import {  SECTION } from '../constants'


export const getSesion = (id) => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${SECTION}/${id}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const createSection = (body, dispatch) => {
    return new Promise((resolve, reject) => {
        HttpClient.post(`${SECTION}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    });
}

export const updateSesion = (body, id) => {
    return new Promise((resolve, reject) => {
        HttpClient.patch(`${SECTION}/${id}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response)
        })
    })
}

export const deleteSection = (id) => {
    return new Promise((resolve, reject) => {
      HttpClient.delete(`${SECTION}/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve(error.response);
        });
    });
  };


