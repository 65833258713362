import * as React from "react";
import {Grid} from "@mui/material";



export default function CustomizedProgressBars(props) {
  return (
    
    <Grid item lg={12} >
      <div style={{border: `2px solid ${!props?.value ? 'red': '#469be2'}`, borderRadius: 10, height: 30}}>
        <div style={{ background: !props?.value ? 'red':'#469be2', borderTopLeftRadius: 8, borderBottomLeftRadius: 8, padding: '2px 0px', width: `${props?.value ? props?.value : 100}%`, borderTopRightRadius: props?.value === 100 ? 5 :1, borderBottomRightRadius: props?.value === 100 ? 5 :1}}>
            <p style={{ color: '#ffffff', paddingLeft: "1%" , verticalAlign: 'center', margin: 'auto', textAlign: !props?.value ? 'center' : null}}>
            {`${props?.value ? props?.value : 0}%`}
            </p>
        </div>
      </div>
    </Grid>
  );
}
