import React, { useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  Select,
  FormHelperText,
  InputLabel,
  MenuItem,
  Typography,
  TextField,
  Paper,
} from "@mui/material";
import { useStateValue } from "../../../context/store";
import { styles } from "./styles";
import { Scrollbars } from "react-custom-scrollbars";
import { PATH_APP, REQUIRED_MESSAGE } from "../../../constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import * as yup from "yup";

export default function Detail(props) {
  const classes = styles();
  const [{ dataToPage }, dispatch] = useStateValue();
  const [valuesForm, setValuesForm] = useState({
    status: "",
  });

  const schema = yup.object().shape({
    description: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(3, "Ingrese mínimo 3 caracteres")
      .max(1000, "Ingrese máximo 1000 caracteres"),
    name: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(3, "Ingrese mínimo 3 caracteres")
      .max(300, "Ingrese máximo 300 caracteres"),
    status: yup.string().required(REQUIRED_MESSAGE),
    code: null,
  });

  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Detalle criterio",
      index: 8,
      subPath: `${PATH_APP}/competencies`,
      idForm: "formCompetencie",
      module: "MANAGE_COMPETENCIES",
    });
    setValue("code", dataToPage?.data?.code);
    setValue("description", dataToPage?.data?.description);
    setValue("status", dataToPage?.data?.status);
    setValue("name", dataToPage?.data?.name);
    setValuesForm({ ...valuesForm, status: dataToPage?.data?.status });
  }, [props?.open]);

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={7.5}>
          <FormControl variant="outlined" fullWidth size="small">
            <TextField
              {...register("name")}
              className={classes.dropdown}
              fullWidth
              type="text"
              disabled
              InputLabelProps={{
                shrink: watch("name") ? true : false,
                notched: watch("name") ? true : false,
              }}
              multiline
              minRows={3}
              maxRows={7}
              size="small"
              label="Criterio"
              error={!!errors.hasOwnProperty("name") && errors["name"].message}
            />
          </FormControl>
          <FormHelperText style={{ color: "red", marginLeft: "3%" }}>
            {errors.hasOwnProperty("name") && errors["name"].message}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <TextField
              {...register("code")}
              className={classes.dropdown}
              fullWidth
              disabled
              InputLabelProps={{
                shrink: watch("code") ? true : false,
                notched: watch("code") ? true : false,
              }}
              size="small"
              label="Código"
              error={!!errors.hasOwnProperty("code") && errors["code"].message}
              helperText={
                !!errors.hasOwnProperty("code") && errors["code"].message
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2.5}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel
              error={
                !!errors.hasOwnProperty("status") && errors["status"].message
              }
            >
              Estado
            </InputLabel>
            <Select
              {...register("status")}
              name="status"
              value={valuesForm.status}
              shrink={true}
              disabled
              className={classes.dropdown}
              label="Estado "
              error={
                !!errors.hasOwnProperty("status") && errors["status"].message
              }
            >
              <MenuItem value="ACTIVE" key={1}>
                Activo
              </MenuItem>
              <MenuItem value="INACTIVE" key={2}>
                Inactivo
              </MenuItem>
            </Select>
            <FormHelperText style={{ color: "red" }}>
              {errors.hasOwnProperty("status") && errors["status"].message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl variant="outlined" fullWidth size="small">
            <>
              <TextField
                {...register("description")}
                className={classes.dropdown}
                fullWidth
                disabled
                type="text"
                InputLabelProps={{
                  shrink: watch("description") ? true : false,
                  notched: watch("description") ? true : false,
                }}
                multiline
                minRows={7}
                maxRows={10}
                size="small"
                label="Descripción"
                error={
                  !!errors.hasOwnProperty("description") &&
                  errors["description"].message
                }
              />
              <div className={classes.accountant}>{`${
                watch("description")?.length ? watch("description")?.length : 0
              }/1000`}</div>
            </>
          </FormControl>
          <FormHelperText style={{ color: "red", marginLeft: "3%" }}>
            {errors.hasOwnProperty("description") &&
              errors["description"].message}
          </FormHelperText>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid item container lg={12} md={12} style={{ marginLeft: "-0.5%" }}>
            {dataToPage?.data?.domainLevelList?.map((x) => (
              <Grid item container lg={12}>
                <Grid
                  item
                  lg={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5%",
                    marginBottom: "1%",
                  }}
                >
                  <Typography
                    style={{ color: "#4B4B4B", fontSize: 14 }}
                  >{`Nivel de dominio ${x?.number}`}</Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography
                    style={{
                      paddingLeft: "11%",
                      color: "#8E8E8E",
                      fontSize: 10,
                      marginBottom: "1%",
                    }}
                  >
                    Código
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography
                    style={{
                      color: "#8E8E8E",
                      fontSize: 10,
                      marginBottom: "1%",
                    }}
                  >
                    Aspecto
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12}>
                  <Paper
                    elevation={2}
                    style={{
                      borderRadius: 10,
                      paddingBottom: 15,
                      paddingTop: 10,
                    }}
                  >
                    <Scrollbars
                      style={{
                        width: "100%",
                        height: "150px",
                        backgroundColor: "#ffffff",
                        borderRadius: 10,
                      }}
                    >
                      <table
                        style={{
                          paddingTop: 8,
                          paddingBottom: 8,
                          width: "99%",
                        }}
                      >
                        {x?.conductList?.map((b) => (
                          <tr style={{ height: 30 }}>
                            <th
                              style={{
                                width: "30%",
                                color: "gray",
                                fontWeight: 400,
                                fontSize: 14,
                                paddingLeft: "6%",
                                verticalAlign: "baseline",
                                textAlign: "start",
                              }}
                            >
                              {b?.code}
                            </th>
                            <th
                              style={{
                                width: "65%",
                                color: "gray",
                                fontWeight: 400,
                                fontSize: 14,
                                wordBreak: "break-word",
                                textAlign: "start",
                              }}
                            >
                              {b?.name}
                            </th>
                          </tr>
                        ))}
                      </table>
                    </Scrollbars>
                  </Paper>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
