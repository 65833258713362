import { makeStyles } from "@mui/styles";
// import theme from "../../../../theme/theme";

export const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      minWidth: '60%',
      height: 260,
      backgroundColor: theme.palette.background.paper,
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.24)",
      outline: "none",
      borderRadius: 15,
      padding: theme.spacing(2, 4, 3),
    },
    cancel1: { 
       color: "#2F71CB", 
       borderColor: '#80808070',
       "&:hover": {
        borderColor: '#80808070',
      },
    },
    cancel: { 
      color: "#ffffff", 
      background: '#2F71CB',
      "&:hover": {
        color: "#ffffff", 
        background: '#2F71CB',
     },
   },
    text: {
      fontSize: 14,
      fontFamily: "Roboto",
      color: "#4B4B4B",
      fontWeight: 400,
      textAlign: "center",
      margin: "25px 20px",
    },
    titleAlert: {
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Roboto",
      fontWeight: 700,
      color: "#2C2C2C",
    },
    widthIcon: {
      fontSize: 70,
      color: '#2F71CB',
      marginTop: '3%'
  
    },
    confirmBanck :{ 
      color: "#ffffff", 
      background: '#2F71CB',
      marginBottom: 5,
      // width: 'fit-content' ,
      "&:hover": {
        color: "#ffffff", 
        background: '#2F71CB',
     },
    },
    titleOpenSection: {
      textAlign: 'center', 
      color: '#545353', 
      fontSize: 15, 
      fontWeight: 400, 
      marginBottom: '3%'
    },
    dropdown: {
        background: "#ffffff",
        boxSizing: "border-box",
        borderRadius: "8px",
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
        '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
          background: '#ffffff',
        },
      },
  }));