import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import MaterialIcon from "../materialIcon/materialIcon";
import { Scrollbars } from "react-custom-scrollbars";
import clsx from "clsx";
import { makeStyles } from '@mui/styles';
import {
  ListItem,
  Divider,
  List,
  ListItemText,
  Drawer,
  IconButton,
  Grid,
  Collapse
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import HomeIcon from "@mui/icons-material/Home";
import { Menu } from "@mui/icons-material";
import { useStateValue } from "../../context/store";
import { menuDataResponse } from './jsonMenu';
import { PATH_APP, resetDispatchs } from "../../constants";

const useStyles = makeStyles((theme) => ({
  modal: {
    [` & .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop`]: {
      opacity: '0 !important'
    },
    width: '20%'
  },
  drawer: {
    width: '20%',
    flexShrink: 0,
    whiteSpace: "nowrap",
   
  },
  toolbar: {
    minWidth: 280,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [` & .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop`]: {
      opacity: 0
    },
    
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "47px!important",
  },
  textMenu: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "-0.05px",
    color: "#4B4B4B",
    paddingRight: 5,
    "&:hover": {
      color: "#6D9FFF",
    },
  },
  textMenuSelected: {
    color: '#6D9FFF',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "-0.05px",
    // color: "#4B4B4B",
    "&:hover": {
      color: "#6D9FFF",
    },
  },
  nested: {
    paddingLeft: theme.spacing(6),
    color: "#66788A",
    "&:hover": {
      background: "#F7F7F7",
      color: "#ffffff",
    },
  },
  nestedTwo: {
    paddingLeft: theme.spacing(7),
    "&:hover": {
      background: "#F7F7F7",
      color: "#ffffff",
    },
  },
  menuHover: {
    padding: "0px",
  },
  menuHoverOpen: {
    color: "#6D9FFF",
    "&:hover": {
      backgroundColor: '#ffffff',
      color: "#ffffff",
      borderRadius: 10,
      "& $iconHover": {
        color: "#6D9FFF",
      },
    },
  },
  menuHoverSelected: {
    color: "#6D9FFF",
    "&:hover": {
      backgroundColor: '#ffffff',
      color: "#ffffff",
      borderRadius: 10,
      "& $iconHover": {
        color: "#4B4B4B",
      },
    },
  },
  menuHoverClose: {
    color: "#6D9FFF",
    "&:hover": {
      background: '#ffffff',
      color: "#ffffff",
      "& $iconHover": {
        color: "#6D9FFF",
        background: '#ffffff',
      },
    },
  },
  iconHover: {
    color: "#6D9FFF",
    padding: 10
  },
  iconsExpan: {
    color: "#4B4B4B", 
    marginRight: 10
  }
}));

const LeftMenu = ( props ) => {
  const [{breadCrumb}, dispatch] = useStateValue();
  const [access, setAccess] = useState([])
  const classes = useStyles();
  const [open, setOpen] = React.useState(false); 
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const resetReducers = () => {
    resetDispatchs(dispatch)
    localStorage.removeItem('typesEvaluation')
  };

  const handleListItemClick = (index, URL) => {setSelectedIndex(index ); resetReducers()}
  const handleClick = () => setOpen(!open);
  
  useEffect(() => {
    setSelectedIndex(breadCrumb?.index);
    intialPermissions();
  },[breadCrumb?.index ])

 
  const intialPermissions = () => {
    const state = localStorage.getItem('session');
    const roles = JSON.parse(state)
    setAccess(roles?.permissions)
  }


  return (
    <React.Fragment key='left' >
    <Drawer
      className={classes.modal}
      anchor='left' 
      open={props.isOpen.left ?? false} 
      onClose={props.toggleDrawer('left', false)}
    >
      <div className={classes.toolbar}>
        <Grid item xs={12} md={12} lg={12} style = {{ display: 'flex', alignItems: 'center'}}>
        <IconButton
          style = {{ color: '#6D9FFF',}}
          onClick={props.toggleDrawer('left', false)}>
            <Menu />
          </IconButton>
        </Grid>

        <IconButton  
          onClick={() => {props.toggleDrawer('left', false, 'Inicio'); setSelectedIndex(0); props.history.push('/autoevaluaciones/home'); resetReducers()}}  
          style = {{ color : '#6D9FFF',  margin: '0px 30px' }}
        >
          <HomeIcon />
        </IconButton>
      </div>
      <Divider />

      <Scrollbars style={{ width: "auto", height: "inherit", backgroundColor: "#ffffff", }}>
        <List 
          component="div" 
          disablePadding 
        
        >
          {menuDataResponse ? menuDataResponse?.menus.map((menu, index) => (
            
            <div 
              key={index}
              onClick={() =>  props?.toggleDrawer('left', false) }  
              style={{borderBottom: access?.find((x)=> x === menu?.code ) ?'1px solid #6e686824' : null}} 
            >
              {menu?.breadCrumbText  && access?.find((x)=> x === menu?.code ) && !menu?.subMenus &&
            <div
              onClick={ props?.toggleDrawer('left', false)}
              onKeyDown={ props?.toggleDrawer('left', false)}
            > 
         
            <ListItem
            
               className={clsx(classes?.menuHover, { 
                 [classes?.menuHoverOpen]: props?.isOpen,
                 [classes?.menuHoverClose]: !props?.isOpen,
               })}
               to={`${PATH_APP}${menu?.url}`}
               component={Link}
               aria-controls="simple-menu"
               aria-haspopup="true"
               selected={selectedIndex === menu?.index}
              //  onClick={() => {handleListItemClick( menu.index, menu.breadCrumbText)}}
               onClick={() => {handleListItemClick( menu?.index, menu?.url)}}

             >
               <IconButton className={classes.iconHover} >
                 <MaterialIcon fill="outlined" name = { menu?.icon } />
               </IconButton>
               <ListItemText
                 className = { clsx(classes.textMenu, {
                   [classes.textMenu]: selectedIndex !== menu?.index,
                   [classes.textMenuSelected]: selectedIndex === menu?.index,
                 }) }
                 primary = { menu?.title }
               />
            </ListItem>
            </div>
            }
            { menu?.subMenus && access?.find((x)=> x === menu?.code ) ? 
            <div>
          
              <ListItem
              button
              className={clsx(classes.menuHover, {
                [classes.menuHoverOpen]: props?.isOpen,
                [classes.menuHoverClose]: !props?.isOpen,
              })}
              aria-controls="simple-menu"
              aria-haspopup="true"
              selected={ selectedIndex === menu?.index}
              onClick={() => handleClick()}
            >
              <IconButton className={classes.iconHover}>
                <MaterialIcon fill="outlined" name = { menu?.icon } />
              </IconButton>
              <ListItemText
                className = { classes.textMenu }
                primary = { menu.title }
              />
              {open ? <ExpandMoreOutlinedIcon className = { classes?.iconsExpan }/> : <KeyboardArrowLeftIcon className = { classes.iconsExpan }/>}
           </ListItem>
           
           { menu?.subMenus?.map((sub, index) => (
             <React.Fragment key={index}>
             { access?.find((x)=> x === sub?.code )  &&
              <Collapse in={open ? true : false} timeout="auto" unmountOnExit
              className={clsx(classes.menuHover, {
               [classes.menuHoverOpen]: props?.isOpen,
               [classes.menuHoverClose]: !props?.isOpen,
             })}
             aria-controls="simple-menu"
             aria-haspopup="true"
             onClick={() => handleListItemClick(  sub?.index, sub?.breadCrumbText )}
             >
             <List component="div" disablePadding
             
             >
               <ListItem
                 to={`${PATH_APP}${sub?.url}`}
                 component={Link}
                 selected={selectedIndex === sub?.index}
                 onClick={ props.toggleDrawer('left', false)}
              
               >
                 <ListItemText style = {{ marginLeft: '20%'}}
                   className = { classes.textMenu }
                   primary= {sub.title} />
               </ListItem>
             </List>
           </Collapse>
           } 
           </React.Fragment>
            ))}
           </div>
            : null}
           
          
          </div>
          )): null}
           
        </List>
      </Scrollbars>
    </Drawer>
    </React.Fragment>
  );
};

export default withRouter(LeftMenu);
