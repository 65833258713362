import React,{useEffect, useState} from 'react'
import { 
    TextField, 
    MenuItem, 
    FormHelperText, 
    InputLabel, 
    Grid, 
    FormControl,
    Button,
    IconButton,
    Container
} from '@mui/material';
import { styles } from '../styles'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModalConfirm from "../../utils/form/modalConfirmBase";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useStateValue } from "../../context/store";
import { PATH_APP, REQUIRED_MESSAGE } from '../../constants';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import QuestionsBank from './aplicationQuestionnaire/questionBank/questionBank';
import { getTypesQuestionnaire, getStatusQuestionnaire, createQuestionnaire, updateQuestionary } from '../../actions/questionary-actions'
import { filterAtBankQuestions } from '../../actions/question-action';
import { getALLTags } from '../../actions/label-actions';
import Question from '../questions/createQuestions';
import ShowDetail from "./detailQuestionaryComponent"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CreateNewQuestion from './createNewQuestion';
import _ from 'lodash'
import * as yup from "yup";




const Questionary = (props) => {
    const classes = styles()

    const [{ questionSearch, questionarySearch, modalClose }, dispatch] = useStateValue();
    const [openModalBank, setOpenModalBank] = useState(false)
    const [openModalQuestion, setOpenModalQuestion] = useState(false)
    const [ clearIcon, setClearIcon] = useState(true)
    const [listStatus, setListStatus] = useState([])
    const [ setDataCreate] = useState({})
    const [currentData, setCurrentData] = useState([])
    const [namesForm, setNamesForm] = useState({})
    const [valuesAnswer, setValuesAnswer] = useState([ ])
    const [edit, setEdit] = useState(false);
    const [questionary, setQuestionary] = useState(false)
    const [valuesOptions, setValuesOptions ] = useState([])
    const [deleteQuestions, setDeleteQuestions] = useState([])
    const [labels, seLabels] = useState([])
    const [listQuestions, setListQuestions] = useState([])
    const [data, ] = useState({
        question : null,
        tagName: null,
    })
    const [copyOptions, setCopyOptions ] = useState([])
    const [listTypes, setLisTypes] = useState([])
    const [values, setValues] = useState({
        ranking1: 'placeholder',
        typeQuestionary: '',
        ranking2: 'placeholder',
        status: '',
    })
    const [message, setMessage ] = useState({
        description : '',
        textButtonSubmit : '',
        handleClick : () => {} ,
        handleClickOut : () => {},
        handleClickThree: () => {},
        oneButtons : false,
        threeButtons: false,
        title: '',
        type : '',
        open : false
        })

       

  useEffect(() => {
        const fieldsQuestionary = []
      if(props?.edit || props?.showDetail){
       
        returnDispatch(props?.edit, props.showDetail, props?.detailAplication)
        // const mapeo = props?.dataDetail?.associatedQuestions?.map((x) => ({question: x?.question, uuid: x?.uuid, position: x?.position }))
          const orderPosition = _.orderBy( props?.dataDetail?.associatedQuestions, ['position'], ['asc'])
         
        for (let index = 0; index < props?.dataDetail?.associatedQuestions?.length; index++) {
            const dataEdit = Object.assign(namesForm, {  [`question${index +1}`]: true,
            [`answer${index +1}`]: true,})
            
            setNamesForm(dataEdit)
            fieldsQuestionary.push(
                {
                    answer: `answer${index+1}`, 
                    question: `question${index +1}`, 
                    createQuestion: orderPosition[index], 
                    value: index+1 
                }
            )
        }

        for (let index = 0; index < props?.dataDetail?.associatedQuestions?.length; index++) {
            setValue(`question${index+1}`, orderPosition[index].question )
            const valuesOnchange = Object.assign(values, {[`answer${index+ 1}`]: props?.dataDetail?.associatedQuestions[index]})
            setValues(valuesOnchange)
        }
        setEdit(true)
        setValuesAnswer(fieldsQuestionary)
        setCurrentData(fieldsQuestionary)
        setCopyOptions(fieldsQuestionary)
        setValue('description',  props?.dataDetail?.description)
        setValues({...values, 
            status: props?.dataDetail?.status, 
            typeQuestionary: props?.dataDetail?.type , 
        })
        // setDeleteQuestions(fieldsQuestionary)
        setValue('typeQuestionary',props?.dataDetail?.type )
        setValue('status',props?.dataDetail?.status )
        setValue('nameQuestionary', props?.dataDetail?.name)

      return null  
      } else {
        dispatchQuestionary()
        setQuestionary(true)
        window.scrollTo(0,0)
} 
   
  }, [])


  useEffect(() => {
    getAllLabels();
    typesQuestionnaires()
    statusQuestionnaires()
   
  }, [])


  const returnDispatch = (x,y,z)=> {
    if(x && y ){
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Gestionar cuestionario",
        index: 4,
        path: `${PATH_APP}/questionary-create`,
        textIsSubmit: "CREAR CUESTIONARIO",
      });
    }
    if(y && z){
      return null
    }
  }

   useEffect(() => {
    returnDispatch(props?.edit, props.showDetail, props?.detailAplication)
  }, [modalClose?.openEdit])

  useEffect(() => {
    if(props?.detailAplication ){
      dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Gestionar Aplicación de cuestionarios",
          index: 5,
          
        });
    }
  
}, [props?.showDetail])


  const dispatchQuestionary = () => dispatch({
    type: "SET_BREADCRUMB",
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Crear cuestionario",
    index: 4,
    isSubmit: true,
    subPath: `${PATH_APP}/questionary`,
    textIsSubmit: "GUARDAR",
    idForm: 'Newary',
    module: "MANAGE_QUESTIONNAIRE"
  });

  const dispatchQuestionaryDisabled = () => dispatch({
    type: "SET_BREADCRUMB",
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Crear cuestionario",
    index: 4,
    isSubmit: true,
    subPath: `${PATH_APP}/questionary`,
    textIsSubmit: "GUARDAR",
    disabledSubmit: true,
    idForm: 'Newary',
    module: "MANAGE_QUESTIONNAIRE"

  });

  const closeModal = () => setMessage({open: false})

  const getAllQuestions = async() => {
    const response = await filterAtBankQuestions({
      question : data.question === null ? null : data?.question,
      tagName: data.uuid === null ? null : data?.tagName,
    }, dispatch)
    setListQuestions(response?.data?.content ? response?.data?.content : [])

    if(response?.data?.length < 1  && !questionSearch?.typeAction ){
 
        setMessage({
          open : true,
          description: 'No se encontró información relacionada a la búsqueda',
          handleClick : () => closeModal() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
       }
    
       if(response?.status >=400 ){
       
        setMessage({
          open : true,
          description: 'Occurió un problema comuniquese con el administrador.',
          handleClick : () => closeModal() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
       }
  }

  const statusQuestionnaires = async() => {
    const result = await getStatusQuestionnaire();
    const filterStatus = _.filter(result?.data, (o)=> o?.type !== 'APPLIED')
    setListStatus( props?.edit || !props?.dataDetail ? filterStatus : result?.data )
  }

  const typesQuestionnaires = async() => {
    const result = await getTypesQuestionnaire();
    setLisTypes(result?.data)
  }

  

  const getAllLabels = async() => {
    const result = await getALLTags({status: 'ACTIVE'}, {orderBy: 'name'});
    seLabels(result?.data?.content)
  }



  const clearList = () => setListQuestions([]);

  if(questionSearch &&
    questionSearch?.isFilter) {
      const newData = questionSearch?.filter
      data.question = newData?.question;
      data.tagName = newData?.tagName;
      getAllQuestions()
      dispatch({
        type: "SET_FILTER_QUESTION",
        filter: questionSearch?.filter,
        isFilter: false,
      })
  }

  const schema = yup.object().shape({ 
    typeQuestionary: yup.string().nullable(true).required(REQUIRED_MESSAGE),
    status: yup.string().nullable().required(REQUIRED_MESSAGE),
    nameQuestionary:  yup.string().required(REQUIRED_MESSAGE).min(10, 'Ingrese mínimo 10 caracteres').max(100, 'Ingrese máximo 100 caracteres'),
    description:  yup.string().required(REQUIRED_MESSAGE).min(10, 'Ingrese mínimo 10 caracteres').max(500, 'Ingrese máximo 500 caracteres'),
    });
  
  const { register, handleSubmit, control, setValue, formState:{errors}, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const handleOnchange = (e) => {
      const {name, value} = e.target
    setValues({...values, [name]: value})
  }


  const getData = (x) => {

      const newData = []
      const newDataCopy = []
      const dataAnswer= []
      const current = []
      const nameFields = []

      for (let index = 0; index < x.length; index++) {
      
            current.push(
                {answer: `answer${dataAnswer.length + 1}`, 
                question: `question${dataAnswer.length + 1}`,
                valueAnswer: dataAnswer.length + 1, disabled: true,
                createQuestion: x[index].createQuestion,
                operationType: 'CREATE'
            })
            dataAnswer.push(
                {answer: `answer${dataAnswer.length + 1}`, 
                question: `question${dataAnswer.length + 1}`,
                value: dataAnswer.length + 1, disabled: true,
                createQuestion: x[index].createQuestion
            })
            newData.push(
                {answer: `answer${dataAnswer.length + 1}`, 
                question: `question${dataAnswer.length + 1}`,
                value: dataAnswer.length + 1
            })
            newDataCopy.push(
                {answer: `answer${dataAnswer.length}`, 
                question: `question${dataAnswer.length }`,
                value: dataAnswer.length,
                createQuestion: x[index].createQuestion
            })
            nameFields.push({answer: `answer${dataAnswer.length }`, question: `question${dataAnswer.length }`})
         
        
    }
    setValuesOptions(newData)
    setCopyOptions(newDataCopy)
    setValuesAnswer(dataAnswer)
    

      for (let index = 0; index < nameFields.length; index++) {

    setValue(nameFields[index]?.question, x[index].value)
    const newValuesAnswer = Object.assign(values,{[nameFields[index]?.answer]:  x[index].code})
    setValues(newValuesAnswer)
    const newValuesYup = Object.assign(namesForm, {[nameFields[index].answer]: true ,
        [nameFields[index].question]: true})
    setNamesForm(newValuesYup)
}
  }

  const getDataCreate = (x) => {

    closeModalCreateQuestion()
    
    setDataCreate(x)
    setValuesOptions([...valuesOptions, 
        {answer: `answer${valuesAnswer.length + 1}`, 
        question: `question${valuesAnswer.length + 1}`,
        value: valuesAnswer.length + 1
    }])
    setCopyOptions([...copyOptions, 
        {answer: `answer${valuesAnswer.length + 1}`, 
        question: `question${valuesAnswer.length + 1}`,
        value: valuesAnswer.length + 1,
        createQuestion: x
    }])
    setValuesAnswer([...valuesAnswer, 
        {answer: `answer${valuesAnswer.length + 1}`, 
        question: `question${valuesAnswer.length + 1}`,
        value: valuesAnswer.length + 1,
        createQuestion: x
    }])
   setValues({...values, [`answer${valuesAnswer.length + 1}`]: ''})
   setNamesForm({...namesForm, [`answer${valuesAnswer.length + 1}`]: true,
        [`question${valuesAnswer.length + 1}`]: true
    })
}

  const handleClose = () =>  ({...message, open: false})

  const optionOnequestion = () => {
      setOpenModalQuestion(true)
    handleClose()

    }

  const optionMultipleQuestion = () => {
    handleClose()  
    modalBank()
  }

  const closeModalCreateQuestion = () => {

      setOpenModalQuestion(false)
      dispatchQuestionary()
    }
  const modalBank = () => setOpenModalBank(!openModalBank)

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(valuesAnswer);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setValuesAnswer(items);
    setCopyOptions(items)
    setClearIcon(true)
  }

  const handleStart = () => {
    setClearIcon(false)
  }


const fieldsQuestions = () => {
    if(props?.showDetail){
       return < ShowDetail  
            setOpenModal={props.setOpenModal} 
            isQuestionary={questionary}
            showDetail={props?.showDetail}
            isEdit = {props?.edit}
            valuesAnswer = {valuesAnswer}
        />
    } else if (!props?.showDetail){
    return  <Container>
          
      
      <DragDropContext onDragEnd={ handleOnDragEnd} onDragStart={handleStart}>
        <Droppable droppableId="characters" >
          {(provided) => (
            <div item container lg={12} md={12} sm={12} 
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
              style={{flex: 'auto'}}
            >
              {valuesAnswer?.map((x, index) => (
                  <Draggable key={x?.answer } draggableId={x?.createQuestion?.uuid} index={index} >
                    {(provided) => (
                    
                        <Grid item container style={{display: 'flex', alignItems: 'baseline'}} lg={12} md={12} sm={12} 
                          ref={!props?.showDetail ? provided.innerRef :  null} 
                          {...provided.draggableProps} 
                          {...provided.dragHandleProps}
                        >
                            <Grid item lg={11.5} md={11} sm={11.5} >
                                <Question 
                                    mapQuestionQuestionary={x.createQuestion} 
                                    setOpenModal={props.setOpenModal} 
                                    isQuestionary={questionary}
                                    showDetail={props?.showDetail}
                                    isEdit = {props?.edit}
                                    // disabledCreate={true}
                                />
                            </Grid>
                            {props.showDetail || !clearIcon? null :
                            <Grid item sm={2} md={1} lg={0.5} 
                            style={{display: 'flex', marginTop: '1.6%', position: 'relative', alignItems: 'flex-start', }}
                            >
                                <IconButton 
                                    onClick={() => {
                                  
                                        if(!copyOptions?.length){
                                            return null
                                        } else {
                                          
                                            const newDelete = [...deleteQuestions]
                                            const deleteQuestion = _.filter(copyOptions, (o)=> o?.question !== `question${x.value}`) 
                                          
                                            const addDeleteQuestion = _.find(copyOptions, (o)=> o?.value === x?.value) 
                                            
                                            newDelete.push(addDeleteQuestion)
                                            setDeleteQuestions(newDelete)
                                            const questions = []
                                            const answer = []
                                            for (let index = 0; index < deleteQuestion.length; index++) {
                                              questions.push(deleteQuestion[index])
                                              answer.push(watch(deleteQuestion[index].answer))
                                              
                                            }
                                            
                                            const namesForForm= {}
                                            if(deleteQuestion.length) {
                                              const findIncurrentData = _.find(currentData, (o)=>o?.createQuestion?.uuid === addDeleteQuestion?.createQuestion?.uuid)
                                            if(findIncurrentData){
                                                let filterIncurrentData = _.filter(currentData, (o)=>o?.createQuestion?.uuid !== addDeleteQuestion?.createQuestion?.uuid)
                                                filterIncurrentData.push(
                                                  {
                                                    answer: addDeleteQuestion?.answer,
                                                    question: addDeleteQuestion?.question,
                                                    createQuestion: addDeleteQuestion?.createQuestion,
                                                    value: addDeleteQuestion?.value,
                                                    operationType: 'DELETE'
                                                  }
                                                  )
                                                setCurrentData(filterIncurrentData)
                                            }
                                            
                                                for (let index = 0; index < deleteQuestion.length; index++) { 
                                    
                                                    setValue(deleteQuestion[index]?.question , watch(questions[index]?.question))
                                                    setValue(deleteQuestion[index]?.answer , answer[index])
                                                    
                                                    
                                                    const valuefinal = Object.assign(namesForForm, {[deleteQuestion[index].answer]: true,
                                                        [deleteQuestion[index].question]: true,
                                                        
                                                    })
                                                    setNamesForm(valuefinal)
                                                    setValuesAnswer(deleteQuestion)
                                                }
                                                if(props?.edit){
                                                  const ordervaluesAnswer = []
                                                
                                                  for (let index = 0; index < valuesAnswer.length; index++) {
                                                    ordervaluesAnswer.push(
                                                      {
                                                        answer: valuesAnswer[index]?.answer,
                                                        question: valuesAnswer[index]?.question,
                                                        createQuestion: {
                                                          answerAlternativeCode: valuesAnswer[index]?.createQuestion?.answerAlternativeCode,
                                                          createdAt: valuesAnswer[index]?.createQuestion?.createdAt,
                                                          lastModifiedAt: valuesAnswer[index]?.createQuestion?.lastModifiedAt,
                                                          position: index,
                                                          question: valuesAnswer[index]?.createQuestion?.question,
                                                          uuid: valuesAnswer[index]?.createQuestion?.uuid
                                                        },
                                                        value: valuesAnswer[index]?.value,
                                                      }
                                                    )
                                                    
                                                  }
                                                  }
                                                
                                            } else {
                                              const findIncurrentData = _.find(currentData, (o)=>o?.createQuestion?.uuid === addDeleteQuestion?.createQuestion?.uuid)
                                              if(findIncurrentData){
                                                let filterIncurrentData = _.filter(currentData, (o)=>o?.createQuestion?.uuid !== addDeleteQuestion?.createQuestion?.uuid)
                                                filterIncurrentData.push(
                                                  {
                                                    answer: addDeleteQuestion?.answer,
                                                    question: addDeleteQuestion?.question,
                                                    createQuestion: addDeleteQuestion?.createQuestion,
                                                    value: addDeleteQuestion?.value,
                                                    operationType: 'DELETE'
                                                  }
                                                  )
                                                setCurrentData(filterIncurrentData)
                                              }
                                                setNamesForm(namesForForm)
                                                setValuesAnswer(deleteQuestion)
                                            }
                                            
                                            setCopyOptions(deleteQuestion)
                                        }
                                    }
                                }
                                    >
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                                
                            </Grid>
                          }
                          {provided.placeholder}
                        </Grid>
                     
                     
                    )}
                  </Draggable>
                
              ))}
              
            </div>
          )}
        </Droppable>
      </DragDropContext>
  </Container>
    } else {
      <Container style={{display: 'flex', flexDirection: 'column'}}>
        {valuesAnswer.map((x, index) => {
          return <Grid item lg={11} >
                <Question 
                    mapQuestionQuestionary={x.createQuestion} 
                    setOpenModal={props.setOpenModal} 
                    isQuestionary={questionary}
                    showDetail={props?.showDetail}
                    // disabledCreate={true}
                />
            </Grid>
        })}
      </Container>
    }
}


const handleCloseModal = () => {setMessage({...message, open: false}); dispatchQuestionary()}
const handleCloseModalAndReset = () => {
    handleCloseModal();
    props?.history.push(`${PATH_APP}/questionary`)
    dispatch({
      type: "SET_FILTER_QUESTIONARY",
      filter: questionarySearch?.filter,
      isFilter: true,
      typeAction: 'EDIT'
    })
}
const handleCloseModalEdit = () => {
    dispatch({
        type: "SET_FILTER_QUESTIONARY",
        filter: questionarySearch?.filter,
        isFilter: true,
        typeAction: 'EDIT'
      })
    dispatch({
    type: "SET_MODAL",
    openEdit:false
  });
  dispatch({
    type: "SET_BREADCRUMB",
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Gestionar cuestionario",
    index: 4,
    path: `${PATH_APP}/questionary-create`,
    textIsSubmit: "CREAR CUESTIONARIO",
  });
}


const onSubmit = (data) => {

if(!copyOptions.length ){
    return setMessage({
        open : true,
        description: 'Añada al menos una pregunta al cuestionario',
        handleClick : () => handleCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
}
    const questions = [];

   for (let index = 0; index < copyOptions.length; index++) {
       questions.push({uuid: valuesAnswer[index]?.createQuestion?.uuid, position: index }) 
   }
   const newData = {
       name: data?.nameQuestionary,
       type: data?.typeQuestionary,
       description: data?.description,
       status: data?.status,
       associatedQuestions: questions,
   }

   const createQuestionary = async() => {
     dispatchQuestionaryDisabled();

     const result = await createQuestionnaire(newData)
     if (result && (result?.status === 200 || result?.status === 201)) {
      setMessage({
        open : true,
        title: '¡Proceso exitoso!',
        description: 'Información almacenada correctamente ',
        handleClick : () => handleCloseModalAndReset() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'SUCCESS',
      })
     }
     else if (result?.status === 400) {
      setMessage({
        open : true,
        description: result?.data?.message,
        handleClick : () => handleCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }else if (result?.status > 399) {
      setMessage({
        open : true,
        description: 'Error al guardar la información',
        handleClick : () => handleCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
     } else {
      setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => handleCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
     }
    }

    
    const currentPositions = [];
    for (let index = 0; index <  valuesAnswer.length ; index++) {
      currentPositions.push(
        { 
          uuid: valuesAnswer[index]?.createQuestion?.uuid,
          position: index.toString(), 
        }
        )
      }

      const uuidCurrentsData = []
      for (let index = 0; index <  currentData.length ; index++) {
        uuidCurrentsData.push(
          { 
            uuid: currentData[index]?.createQuestion?.uuid,
            position: currentData[index]?.createQuestion?.position?.toString(), 
          }
          )
        }
      
   
    const newValuesAnswer = []
    for (let index = 0; index < currentData.length; index++) {

      const result = currentPositions.find((o)=> o?.uuid === currentData[index]?.createQuestion?.uuid)
      const resultCurrentData = currentData.find((o) => o?.createQuestion?.uuid === result?.uuid)

      if(!result ){
        newValuesAnswer.push(
             { 
               uuid: currentData[index]?.createQuestion?.uuid,
               operationType: 'DELETE', 
             }
           )
      }else if(result?.position === resultCurrentData?.createQuestion?.position ){
        newValuesAnswer.push(
          { 
             uuid: valuesAnswer[index]?.createQuestion?.uuid,
             position: result?.position, 
             operationType: null, 
           }
         )
       }else if(result?.position !== resultCurrentData?.createQuestion?.position ){
         newValuesAnswer.push(
           { 
             uuid: currentData[index]?.createQuestion?.uuid,
             position: result?.position,  
             operationType: 'UPDATE', 
           }
         )
       }
      
    }
    
    for (let index = 0; index < currentPositions.length; index++) {

      const result =  newValuesAnswer.find((x) => x?.uuid === currentPositions[index]?.uuid)  
      if(result) {
        continue
      }else {
        newValuesAnswer.push(
            { 
              uuid: currentPositions[index]?.uuid,
              position:  currentPositions[index]?.position, 
              operationType: 'CREATE', 
            }
          )
      }
      }
  
   
    const newDataEdit = {
        name: data?.nameQuestionary,
        type: data?.typeQuestionary,
        description: data?.description,
        status: data?.status,
        associatedQuestions: newValuesAnswer,
    }


    const update = async() => {
    
    const result = await updateQuestionary(props?.dataDetail?.uuid, newDataEdit)
         
         if (result && (result?.status === 200 || result?.status <= 204)) {
          setMessage({
            open : true,
            title: '¡Proceso exitoso!',
            description: 'Información almacenada correctamente ',
            handleClick : () => handleCloseModalEdit() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'SUCCESS',
          })
         }
         else if (result?.status === 400) {
          setMessage({
            open : true,
            description: result?.data?.message,
            handleClick : () => handleCloseModal() ,
            oneButtons : true,
            type : 'WARNING',
          })
        }else if (result?.status > 399) {
          setMessage({
            open : true,
            description: 'Error al guardar la información',
            handleClick : () => handleCloseModal() ,
            oneButtons : true,
            type : 'WARNING',
          })
         } else {
          setMessage({
            open : true,
            description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
            handleClick : () => handleCloseModal() ,
            oneButtons : true,
            type : 'WARNING',
          })
         }
        }
        props?.edit ? update() :  createQuestionary()
}


const changeStateEdit = () => setEdit(false)

    return (
        <React.Fragment>
            <QuestionsBank 
                labels={[...labels]} 
                getData={getData} 
                open={openModalBank} 
                modalBank={modalBank} 
                clearList={clearList}
                data={[...listQuestions]}
                isEdit={edit}
                dataEdit={valuesAnswer}
                clearStateEdit={changeStateEdit}
                arrayDeletes={deleteQuestions}

                />
            <CreateNewQuestion 
            getDataCreate={getDataCreate}
            open={openModalQuestion}
            closeModalCreateQuestion={closeModalCreateQuestion}
            />
            
            <form id="Newary" onSubmit={handleSubmit(onSubmit)} style={{marginLeft: props?.showDetail || props?.edit? null :'-2%', marginTop: props?.showDetail || props?.edit? '1%': null}}>
                <Grid container spacing={1} style={{display: 'flex', alignItems:'flex-start', marginBottom: '1%',}}>
                <Grid item lg={props.edit || props.showDetail ? 2.7 : 2.2}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <Controller 
                        control={control}
                        render={({field, fieldState})=> (
                          <div style={{display: 'flex', alignItems: 'baseline'}}>
                              <ErrorOutlineIcon color="error" style={{visibility: errors['nameQuestionary'] ? "initial": "hidden"}}  className={classes.iconErrorWithoutFlex}/> 
                            <TextField
                            {...register('nameQuestionary')}
                            inputRef={field.ref}
                            className={classes.dropdownNameCuestionary}
                            disabled={props.showDetail }
                            style={{background: watch("nameQuestionary") && props?.showDetail ? '#E1E1E1' : '#ffffff'}}
                            fullWidth
                            multiline
                            maxRows={10}
                            value={field.value}
                            size="small"
                            placeholder="Nombre Cuestionario"
                            label={props?.showDetail ? "Nombre Cuestionario": 'Nombre cuestionario'}
                            error={!!errors.hasOwnProperty("nameQuestionary") && errors["nameQuestionary"].message}
                            />
                          </div>
                        )}
                        />
                    </FormControl>
                    <FormHelperText style={{color:'#d70909', fontSize: 11, marginLeft: '10%'}}>
                        {!!errors.hasOwnProperty("nameQuestionary")  &&
                        errors["nameQuestionary"].message }
                    </FormHelperText> 
                        </div>
                </Grid>
                <Grid item md={3} lg={ props.edit || props.showDetail ? 2.5 : 2.2} style={{display: 'flex', alignItems: 'center'}}>
                    <ErrorOutlineIcon 
                        color="error"  
                        style={{visibility: errors[`typeQuestionary`] && !values.typeQuestionary ? "initial": "hidden"}} 
                        fontSize="small" 
                        className={classes.iconErrorStatus}
                    /> 
                    <FormControl fullWidth size="small">
                        <Controller
                        control={control}
                        name='typeQuestionary'
                        render={({field, fieldState})=> (
                        <TextField
                        select
                        disabled={props.showDetail}
                        name='typeQuestionary'
                        size="small"
                        shrink={true}
                        InputLabelProps={{
                            className: classes.labelProps
                        }}
                        label="Tipo de cuestionario"
                        {...register('typeQuestionary')}
                        onChange={ (e) =>field.onChange(handleOnchange(e))}
                        value={values?.typeQuestionary}
                        error={!!errors.hasOwnProperty("typeQuestionary") && !values.typeQuestionary}
                        className={classes.dropdownNameCuestionary}
                        style={{background:props?.showDetail ? '#E1E1E1' : '#ffffff'}}

                        > 
                        {listTypes?.map((x) => (
                            <MenuItem value={x?.type} key={x?.type}>{x?.description}</MenuItem>
                        ))}  
                        </TextField> 
                        )}
                        />
                        <FormHelperText style={{color:'#d70909', fontSize: 11}}>
                            {(!!errors.hasOwnProperty("typeQuestionary") && !values.typeQuestionary) &&
                            errors["typeQuestionary"].message }
                        </FormHelperText> 
                    </FormControl>
                    </Grid>
                    <Grid item md={3} lg={ props.edit || props.showDetail ? 2 : 2} style={{display: 'flex', alignItems: 'center'}}>
                    <ErrorOutlineIcon 
                        color="error"  
                        style={{visibility: errors[`status`] && !values.status ? "initial": "hidden", marginBottom: '10%', }} 
                        fontSize="small" 
                        className={classes.iconErrorStatus}
                    /> 
                    <FormControl fullWidth size="small">
                    <InputLabel  error={!!errors.hasOwnProperty("status") && !values.status}></InputLabel>
                    <Controller
                        control={control}
                        name='status'
                        render={({field, fieldState})=> (
                        <TextField
                        select
                        disabled={props.showDetail}
                        size="small"
                        {...register('status')}
                        label="Estado"
                        InputLabelProps={{
                            className: classes.labelProps
                        }}
                        onChange={ (e) =>field.onChange(handleOnchange(e))}
                        value={values.status}
                        error={!!errors.hasOwnProperty("status") && !values.status}
                        className={classes.dropdownNameCuestionary}
                        style={{background:  props?.showDetail? '#E1E1E1' : '#ffffff'}}
                        >
                        {listStatus?.map((x) => (
                            <MenuItem value={x?.type} key={x?.type}>{x?.description}</MenuItem>
                        ))}  
                        </TextField>
                        )}
                        />
                        <FormHelperText style={{color:'#d70909', fontSize: 11 }}>
                            {(!!errors.hasOwnProperty("status") && !values.status) &&
                            errors["status"].message}
                        </FormHelperText> 
                    </FormControl>
                    </Grid>
                    <Grid item lg={props?.edit || props.showDetail ? 4.8 : 3.5} style={{  marginBottom: '1.5%', flexDirection:'column', position: 'relative'}}>
                  
                      
                        <div style={{display: 'flex', flexDirection: 'column',}}>
                        <FormControl variant='outlined' fullWidth size="small">
                        <Controller 
                        control={control}
                        render={({field, fieldState})=> (
                          <div style={{display: 'flex', alignItems: 'baseline',}}>
                          <ErrorOutlineIcon color="error"  style={{visibility: errors['description'] ? "initial": "hidden"}} fontSize="small" className={classes.iconErrorWithoutFlex}/> 
                            <TextField
                            {...register('description')}
                            inputRef={field.ref}
                            className={ props?.showDetail ? classes.dropdownMultilineDisabled : classes.dropdownNameCuestionary}
                            fullWidth
                            disabled={props.showDetail}
                            multiline
                            label="Descripción"
                            maxRows={12}
                            minRows={4}
                            value={field.value}
                            size="small" 
                            placeholder="Descripción"
                            error={!!errors.hasOwnProperty("description") && errors["description"].message}
                        />
                         </div>
                        )}
                        />
                        </FormControl>
                        <FormHelperText style={{color:'#d70909', fontSize: 11, marginLeft: '10%' }}>
                            {!!errors.hasOwnProperty("description") &&
                            errors["description"].message}
                        </FormHelperText> 
                        </div>
                        {props.showDetail ? null :
                            <Grid item lg={12}>
                                <div className={props.edit ? classes.buttonsBanks : classes.largeButtonsBanks  }>
                                    <Button
                                        className={ classes.buttonBank }
                                        variant="outlined"
                                        onClick={optionMultipleQuestion}
                                    >
                                        BANCO DE PREGUNTAS
                                    </Button>

                                    <Button
                                        style={{fontSize:12}}
                                        className={classes.cancel}
                                        variant="contained"
                                        onClick={optionOnequestion}
                                    >
                                        CREAR PREGUNTA
                                    </Button>
                                </div>
                                    </Grid> 
                        }
                    </Grid>
                    {props?.edit || props.showDetail ? null :
                    <Grid item lg={1.9} style={{marginLeft: '1%'}}>
                        <Button 
                            variant="outlined"  
                            style={{ color: "#6D9FFF", marginBottom: 5, background: '#ffffff', width: '100%', borderRadius: 10, fontSize: 12 }}
                        >
                                AGREGAR SECCIÓN
                        </Button>
                    </Grid>
                    }
                          <Grid container spacing={2} style={{display: 'flex', marginTop: props?.showDetail ? '5%' : '4%'}}>
                            {fieldsQuestions()}
                          </Grid>
                </Grid>
                <ModalConfirm 
                    description = { message.description }
                    textButtonSubmit = { message.textButtonSubmit }
                    handleClick = { message.handleClick }
                    handleClickOut = { message.handleClickOut }
                    handleClickThree = {message.handleClickThree}
                    oneButtons = { message.oneButtons }
                    threeButtons = {message.threeButtons}
                    title = { message.title }
                    type = { message.type }
                    open = { message.open }
                />
            </form>           
        </React.Fragment>
    )
}

export default Questionary
