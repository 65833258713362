import React,{useState, useEffect} from 'react';
import {
  Grid
} from "@mui/material";
import Question from "../../../questions/createQuestions"
import { useStateValue } from "../../../../context/store";
import _ from 'lodash'

const ListQuestions = (props) => {
  const [{ dataAplication}, ] = useStateValue();
  const [questions, setQuestions] = useState([])

  useEffect(()=> {
    orderQuestions()
  },[dataAplication?.data?.questionnaire?.associatedQuestions])


  const orderQuestions = () => {
    const data = dataAplication?.data?.questionnaire?.associatedQuestions
    const result = _.orderBy(data, ['position'], ['asc']);
    setQuestions(result)
  }
    return    <Grid container style={{marginLeft: '-4%', marginTop: 60, }}>
    {questions?.map((x, index)=> {
      return <Grid item lg={12} style={{ marginBottom: 50, }}>
                <Question 
                    index={index+1}
                    mapQuestionAssess={x} 
                    isAnswer={true}
                    isDetail={props?.isDetail}
                />
              </Grid>
    })}
    </Grid>
 
};

export default ListQuestions;
