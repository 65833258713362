import React from 'react';
import { Column } from '@ant-design/plots';

export const GraphicBar = ({colors, data, totalEval}) => {

      const config = {
        data,
        isGroup: true,
        xField: 'type',
        yField: 'total',
        seriesField: 'label',
        // colorField: 'color',
        dodgePadding: 2,
        yAxis: {
          min: 0, // Valor mínimo para el eje Y
          max: totalEval+10, // Valor máximo para el eje Y
        },
        maxColumnWidth: 20,
        // columnStyle: {
        //   width: 5, // Ajusta este valor según tus necesidades
        // },
        barThickness: 20,
        label: {
          position: 'top',
          offset: 4,
        },
       
     
      };
      return <Column {...config} />;
    };
    

export default GraphicBar