import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function TableResultGroup({ resultGroup }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: '#C5E6EF', fontWeight: 500, fontSize: '20px' }}>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Asignatura</TableCell>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Autoevaluación</TableCell>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Estudiantes</TableCell>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Institucional</TableCell>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Definitivo</TableCell>
            <TableCell style={{ fontWeight: 500, fontSize: '20px' }}>Población</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resultGroup.map((registro, index) => (
            <TableRow key={registro.id} style={{ backgroundColor: index % 2 === 0 ? '#EFF4F6' : '#DDDDDD' }}>
              <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.subject}</TableCell>
              <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.selfAssessment}</TableCell>
              <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.student}</TableCell>
              <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.institutional}</TableCell>
              <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.definitive}</TableCell>
              <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.population}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableResultGroup;
