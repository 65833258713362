import React, {useState, useEffect} from 'react';
import {Grid,
    FormControl,
    TextField,
    Checkbox,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useStateValue } from "../../../../../context/store";
import {styles} from "./styles"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Index = (props) => {
    const classes = styles();
    const [{ valorationQuestionnaire, valorateAplication },  dispatch] = useStateValue();
    const [checkeds, setCheckeds] = useState([])
    const [, setValueQuestion] = useState()

    const schema = yup.object().shape({
     
      valueTotalQuestion: yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,2}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
    })
    
    const { register, handleSubmit, formState:{errors}, setValue, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });

    const validateChecked = (data) => {
        const result =  checkeds?.find((x) =>x.uuid === data )
       return result && result?.checked ? true : false
     }


    const handleChangeChecked = (event) => {

      const {name} = event.target;
      const copy= [...checkeds]

        const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)
        const filterCheckeds = checkeds?.find((x) => x.uuid === name)

       if(filterCheckeds?.checked) {
          const different = checkeds.filter((x)=> x?.uuid !== name)
          const notChecked =[]
          //aqui realizo for para dividir valoracion cuando quito una opcion seleccionada
          
          for (let index = 0; index < different?.length; index++) {
            notChecked.push({
              uuid: different[index]?.uuid, 
              checked: true
            }) 
          }
          setCheckeds(notChecked)
        
         dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList: notChecked,
              }] : 
              [{uuid: `${props?.uuid}`, answer: different }],
         })
        }else {
        
          // buscamos el uuid en los checked para ponerlo en true
          const different = checkeds.filter((x)=> x?.uuid !== name)
          const isChecked = different.filter((x)=> x?.checked === true)
          //consulto las no selecionadas 
          const notChecked = different.filter((x)=> x?.checked !== true)
          //realizo for para poner valueAplied igual a todas las seleccionadas 
          for (let index = 0; index < isChecked.length; index++) {
            
            notChecked.push({
              uuid: isChecked[index]?.uuid, 
              checked: true}) 
          }
          notChecked.push({
            uuid: name,  
            checked: true})

          
          dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList: notChecked,
              }
            ] : [{uuid: `${props?.uuid}`, answer: copy }],
         })
         setCheckeds(notChecked);
       } 
    };



    

    useEffect(()=> {
        if(valorationQuestionnaire?.typeValoration === 'automatic'){
          
        dispatch({
          type: "SET_VALUE_QUESTIONNAIRE",
          totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
          typeValoration: 'automatic',
          activeValoration: false
        });
        }else {
          dispatch({
            type: "SET_VALUE_QUESTIONNAIRE",
            totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
            typeValoration: 'manual',
            activeValoration: false
          });
        }
        setValue('valueTotalQuestion', Number(valorationQuestionnaire?.totalValueQuestionnaire) ? (Number(valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
        setValueQuestion(Number(valorationQuestionnaire?.totalValueQuestionnaire) ? (Number(valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '' )
      },[valorationQuestionnaire?.activeValoration ])


      const validateValueCheckeds = (index) => {
        const isChecked = checkeds.filter((x)=> x?.checked === true)
        if( valorationQuestionnaire?.totalValueQuestionnaire 
          && checkeds 
          && validateChecked(`${props?.valuesOptions[index]?.uuid}`) ){
            return ( valorationQuestionnaire?.totalValueQuestionnaire/isChecked.length).toFixed(2)
          }
       else if (checkeds && !valorationQuestionnaire?.totalValueQuestionnaire && watch('valueTotalQuestion') && validateChecked(`${props?.valuesOptions[index]?.uuid}`)){
        return (Number(watch('valueTotalQuestion'))/isChecked.length) % 1 !== 0 
        ? (Number(watch('valueTotalQuestion'))/isChecked.length).toFixed(2) 
        : Number(watch('valueTotalQuestion'))/isChecked.length
       } else {
         return 0
       }
      
      }

    const onSubmit= () => {
      const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)
      const newOptions = []

      for (let index = 0; index < checkeds.length; index++) {
        newOptions.push({uuid: checkeds[index]?.uuid, valueApplied: checkeds[index]?.valueApplied})
      }
      filterUuid.push(
          {
            uuid: `${props?.uuid}`,
            questionAlternativeList: filterUuid
          }
        )
        dispatch({
              type: "SET_MODAL_VALORATE_APLICATION",
              activeValorate: true,
              dataClear: filterUuid,
              data: filterUuid
            })
      }


    return <Grid container spacing={1}  maxWidth="lg" justifyContent="center" alignItems="center" style={{ margin: 'auto', display: 'flex', justifyContent: 'flex-start', marginLeft: '3.5%' }} >
     <Grid item lg={10} style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{display: 'flex', alignItems: "center", marginBottom: 15}}>
              <Grid item lg={0.4} style={{color: '#6E6E6E'}}>{props?.position}</Grid>
              <Grid item lg={11.6}>
                <Typography className={classes.question}>{props?.question}</Typography>
              </Grid>
          </div>
          <div>
          <Grid item lg={1} md={2} style={{display: 'flex', marginLeft: '3.6%', marginBottom: 15}}>
            <FormControl variant='outlined'  size="small">
              <TextField
              {...register('valueTotalQuestion')}
                className={classes.dropdown}
                
                size="small"
                placeholder='Valor'
                disabled={true}
                // onChange={(e)=>handleOnchange(e)}
                error={!!errors.hasOwnProperty("valueTotalQuestion") && errors["valueTotalQuestion"].message}
                value={ props?.valueQuestion }
              />
            </FormControl>
          </Grid>
          </div>
        </Grid>
     <Grid container spacing={2} maxWidth="md" direction="column" style={{margin: '5px 0px 10px 0%', width: '90%'}}>
     {props?.valuesOptions.map((x, index) => {
      return  <form id="formAplication" onSubmit={handleSubmit(onSubmit)} style={{ marginBotton: 20}}>
      <Grid item xs={12} sm={12} md={12} lg={9} style={{display: 'flex', alignItems: 'center', marginBotton: 20}}>
               <div style={{marginRight:5}}>
               <Checkbox
                     disabled={props?.isDetail  ? true: false}
                     checked={props?.isDetail ? x?.valueApplied !== 0 : validateChecked(`${props?.valuesOptions[index]?.uuid}`)}
                     name={`${props?.valuesOptions[index]?.uuid}`}
                     onChange={handleChangeChecked}
                   />
               </div>
             <FormControl variant='outlined' fullWidth size="small">
                 <TextField
                 disabled={true}
                 className={classes.dropdown}
                 fullWidth
                 multiline
                 maxRows={14}
                 value={x?.key}
                 size="small"
               />
             </FormControl>
       </Grid> 
       </form>
     })}
       {checkeds?.length ? null :<Typography className={classes.messageInformative}>Seleccione mínimo 1 opción</Typography>}
     </Grid>
   </Grid>
};

export default Index;
