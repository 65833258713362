import React, {useEffect, useState} from 'react'
import { useStateValue } from '../../../context/store'
import Relations from "../../createEvaluationPerformance/relationShip";
import FormHeader from "../../createEvaluationPerformance/formHeader";
import { getByIdEvaluation } from "../../../actions/Performance_Evaluation-action"
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import _ from "lodash"


const Index = (props) => {
    const {id} = props?.match?.params
    const idEvaluation = id
    const [, dispatch] = useStateValue();
    const [showRelations, setShowRelations]= useState(false);
    const [, setDataDetail] = useState({});
    const [currentData, setCurrentData] = useState([]);
    const [message, setMessage] = useState({
        description : '',
        textButtonSubmit : '',
        handleClick : () => {} ,
        handleClickOut : () => {},
        oneButtons : false,
        title: '',
        type : '',
        open : false
      })


    useEffect(() => {
        getData()
    }, [])

   

    const getData =  () => {
  
        const getEvaluation = async() => {
            const result = await getByIdEvaluation(idEvaluation, dispatch)
            setDataDetail(result?.data)
        if(result?.data?.competences?.length){
         
            const newData = { 
                name: result?.data?.name,
                description: result?.data?.description,
                referredTo: result?.data?.evaluators?.map((x)=> x?.username),
                startDate: result?.data?.startDate,
                endDate:  result?.data?.endDate,
                status:  result?.data?.status, 
            }
            
            dispatch({
                type: "SET_DATA_QUESTIONNAIRE",
                data: newData
            })

            const competences = [];
            const copyData = [...result?.data?.competences]
            for (let index = 0; index < copyData?.length; index++) {
                if( copyData[index]?.section?.uuid){
                    competences.push(
                        {
                            uuid : copyData[index]?.competence?.uuid, 
                            position: index+1,
                            domainLevel: copyData[index]?.domainLevel,
                            name: copyData[index]?.competence?.name,
                            domainLevelList: copyData[index]?.competence?.domainLevelList,
                            section: copyData[index]?.section,
                            behaviors: _.orderBy(copyData[index]?.conducts, ['position'],["asc"])?.map((x, index)=>(
                            {uuid: x?.uuid, 
                                name: x?.name,
                                position: index +1,
                                questions: _.orderBy(x?.questions, ["position"],["asc"])?.map((f, index)=> (
                                {
                                    questionAlternativeList: f?.questionAlternativeList,
                                    answerAlternativeCode: f?.answerAlternativeCode,
                                    value: f?.question,
                                    position: index+1,
                                    uuid: f?.uuid,
                                    rols: f?.evaluators?.map((r) => r?.uuid)
                                }
                                ))
                            }))
                        })
                }else {
                    competences.push(
                        {
                            uuid : copyData[index]?.competence?.uuid, 
                            position: index+1,
                            domainLevel: copyData[index]?.domainLevel,
                            domainLevelList: copyData[index]?.competence?.domainLevelList,
                            name: copyData[index]?.competence?.name,
                            behaviors: _.orderBy(copyData[index]?.conducts, ['position'],["asc"])?.map((x, index)=>(
                            {uuid: x?.uuid, 
                                name: x?.name,
                                position: index +1,
                                questions: _.orderBy(x?.questions, ["position"],["asc"])?.map((f, index)=> (
                                {
                                    questionAlternativeList: f?.questionAlternativeList,
                                    answerAlternativeCode: f?.answerAlternativeCode,
                                    value: f?.question,
                                    position: index+1,
                                    uuid: f?.uuid,
                                    rols: f?.evaluators?.map((r) => r?.uuid)
                                }
                                ))
                            }))
                        })
                }
                
                
                }


            let dataSections = [];
            let dataCompetences = [];
            for (let index = 0; index < competences?.length; index++) {
                if( competences[index]?.section){
                    dataCompetences.push(
                        {
                            domainLevel : competences[index]?.domainLevel,
                            name : competences[index]?.name,
                            position : competences[index]?.position,
                            uuid : competences[index]?.uuid,
                            behaviors : competences[index]?.behaviors,
                            domainLevelList: competences[index]?.domainLevelList,
                            into: true,
                            section: competences[index]?.section?.uuid
                        }
                    )
                    dataSections.push(
                        {
                            ...competences[index],
                            idSec: competences[index]?.section?.uuid,
                            into: true,
                        }
                    )
                } else {
                    dataCompetences.push(
                        {
                            domainLevel : competences[index]?.domainLevel,
                            name : competences[index]?.name,
                            position : competences[index]?.position,
                            uuid : competences[index]?.uuid,
                            behaviors : competences[index]?.behaviors,
                            domainLevelList: competences[index]?.domainLevelList,
                            into: false,
                            section: competences[index]?.section?.uuid
                        }
                    )
                }
                
            }

            const newSections = [];
 
            const newArrays = _.groupBy(dataSections, 'idSec');

            const positionSections = Object.values(newArrays)

            for (let index = 0; index < positionSections?.length; index++) {
              const totalSection = positionSections[index]
                
               for (let x = 0; x < 1; x++) {
                newSections.push(totalSection[x]?.section)
                
                
               }
            }

            const finalDataSec = []
            for (let index = 0; index < newSections?.length; index++) {
                const compeSect = dataSections.filter((x)=> x?.idSec === newSections[index]?.uuid)
        
                if(compeSect?.length) {
                    finalDataSec.push(
                        {
                            ...newSections[index],
                            position: index +1,
                            competences : compeSect?.map((x)=> (
                                {domainLevel : x?.domainLevel,
                                name : x?.name,
                                position : x?.position,
                                uuid : x?.uuid,
                                domainLevelList: x?.domainLevelList,
                                behaviors : x?.behaviors,
                                idSec: x?.section?.uuid,
                                into: true,
                            }))
                            
                        }
                    )
                }
                
            }

                dispatch({
                    type: 'SET_RELATIONS_EVALUATION',
                    data: dataCompetences,
                    dataSections : finalDataSec
                })
                setCurrentData(competences)
            setShowRelations(true)
            dispatch({
                type: 'SET_TYPE_OPERATION',
                operation: 'EDIT'
            })
        }
    }
    getEvaluation()
}
  


    if(showRelations){
  return (
    <div >
        < Relations isEdit={true} currentData={currentData} children={ 
        < FormHeader isEdit={true} setMessage={setMessage} message={message} currentData={currentData} idEvaluation={idEvaluation}/>
        }/>
        <ModalConfirm 
            description = { message.description }
            textButtonSubmit = { message.textButtonSubmit }
            handleClick = { message.handleClick }
            handleClickOut = { message.handleClickOut }
            oneButtons = { message.oneButtons }
            title = { message.title }
            type = { message.type }
            open = { message.open }
        />
    </div>
  )
}else {
    return null
}
}

export default Index