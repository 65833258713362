import React, { useState} from 'react'
import { Grid, Typography, CardContent, Card, TextField, FormControl, FormHelperText } from '@mui/material'
import QuestionLinear from "../questionLinearScale";
import QuestionTex from "../questionText";
import {styles} from "./styles";
import _ from "lodash";
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const Index = (props) => {
    const classes = styles();

    const [dataYup, ] = useState({})

    const schema = yup.object().shape(dataYup)
    const {register, formState:{errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onTouched',
        reValidateMode: 'onChange'
    })


    
    
    const typeQuestion = (comp, beh, val) => {
        if(val?.answerAlternativeCode === 'ESL'){
            return  (
                <QuestionLinear 
                    competenceId={comp?.competence?.uuid}
                    behaviorId={beh?.uuid}
                    uuid={val?.uuid}
                    valuesRanking={val?.questionAlternativeList}
                    question={val?.question}
                /> 
            )
        }else if(val?.answerAlternativeCode === 'TEX'){
            return (
                <QuestionTex 
                    competenceId={comp?.competence?.uuid}
                    behaviorId={beh?.uuid}
                    uuid={val?.uuid}
                    question={val?.question}
                />
            )
        }
        
    }

  
    return (
     
        <Grid container spacing={2} justifyContent="center" >
            {props?.dataDetail?.performanceEvaluation?.competences?.sort((a, b)=> Number(a?.position) - Number(b?.position) )?.map((c,index)=> (
            <Grid item lg={12}>
            <Card elevation={3} style={{ background:'rgb(239 239 239)', padding: '10px', marginBottom: '3%', borderRadius: 20}}>
            <CardContent style={{background:  'rgb(239 239 239)' }}>
                <Grid item lg={12} md={12}>
                    <Typography style={{textAlign: 'center', fontSize: 20, fontWeight: 400, background: 'rgba(154, 187, 100, 0.46)'}}>{`Criterio ${index+1}`} </Typography>
                </Grid>
                <Grid item lg={12} md={12}>
                    <Typography style={{textAlign: 'justify', fontSize: 14, fontWeight: 400, color: '#8E8E8E', margin: ' 10px auto 20px auto'}}>{c?.competence?.name} </Typography>
                </Grid>
                <Grid item container justifyContent="space-between">
                    <Grid lg={6} md={6}style={{display: 'flex'}}>
                        <Typography style={{color: '#6E6E6E', fontSize: 20, fontWeight: 900, marginBottom: '1%', marginRight: 20}}>VALOR :</Typography>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                            <FormControl variant='outlined' fullWidth size="small">
                                <TextField
                                {...register(c?.competence?.uuid)}
                                name={c?.competence?.uuid}
                                className={classes.dropdown}
                                fullWidth
                                value={`${c?.value}%`}
                                disabled
                                InputLabelProps={{
                                    shrink: true ,
                                    notched:true ,
                                }}
                                size="small"
                                label="Valor"
                                error={!!errors.hasOwnProperty(c?.competence?.uuid) && errors[c?.competence?.uuid].message}
                                />
                            </FormControl>
                            <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                                {errors.hasOwnProperty(c?.competence?.uuid) &&
                                    errors[c?.competence?.uuid].message}
                            </FormHelperText> 
                        </Grid>
                    </Grid>
                </Grid>
                
            {_.orderBy(c?.conducts, ['position'],["asc"])?.map((b, posB=index)=> (
                <>
                <Grid item lg={12} md={12} >
                    <Typography style={{textAlign: 'center', fontSize: 20, fontWeight: 400, background: 'rgba(245, 178, 117, 0.55)'}}>{`Aspecto ${posB+1}`} </Typography>
                </Grid>
                <Grid item lg={12} md={12}>
                    <Typography style={{textAlign: 'justify', fontSize: 14, fontWeight: 400, color: '#8E8E8E', margin: ' 10px auto 20px auto'}}>{b?.name} </Typography>
                </Grid>
                <Grid lg={12} style={{display: 'flex'}}>
                    <Typography style={{color: '#6E6E6E', fontSize: 20, fontWeight: 900, marginBottom: '1%', marginRight: 20}}>VALOR :</Typography>
                    <Grid item xs={12} sm={12} md={12} lg={1}>
                        <FormControl variant='outlined' fullWidth size="small">
                            <TextField
                             {...register(`${c?.competence?.uuid}${posB}`)}
                            name={`${c?.competence?.uuid}${posB}`}
                            className={classes.dropdown}
                            fullWidth
                            disabled
                            value={`${b?.value}%`}
                            InputLabelProps={{
                                shrink:  true,
                                notched: true,
                            }}
                            size="small"
                            label="Valor"
                            error={!!errors.hasOwnProperty(`${c?.competence?.uuid}${posB}`) && errors[`${c?.competence?.uuid}${posB}`].message}
                            />
                        </FormControl>
                        <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                            {errors.hasOwnProperty(`${c?.competence?.uuid}${posB}`) &&
                                errors[`${c?.competence?.uuid}${posB}`].message}
                        </FormHelperText> 
                    </Grid>
                </Grid>
                {_.orderBy(b?.questions, ['position'],["asc"])?.map((x, index)=> (
                    <>
                    <Grid item container lg={12} md={12} >
                        <Grid item lg={12} style={{textAlign: 'center'}}>
                            <Typography className={classes.key} style={{ fontSize: 20, fontWeight: 400, background: '#B7C1F3'}}>{`Pregunta ${index+1}`} </Typography>
                        </Grid>
                        <Grid item lg={12} style={{textAlign: 'center'}}>
                            <Typography className={classes.value} style={{ wordBreak: 'break-all', margin: ' 10px auto 20px auto'}}>{x?.question}</Typography>
                        </Grid>
                    </Grid> 
                    <Grid item={10} style={{marginBottom: 40}}>
                        {typeQuestion(c, b, x)}
                    </Grid>
                    </>
                ))}
            </>
            ))}
            </CardContent> 
        </Card>
        </Grid>
            ))}
               
        </Grid>
    )
}

export default Index