import React,{useState, useEffect} from 'react'
import { FormControl, Grid,  InputLabel, Button, TextField, MenuItem, FormHelperText } from '@mui/material'
import { PATH_APP, REQUIRED_MESSAGE } from '../../../constants';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import {styles} from "./styles";
import {updateEvaluation, createPerformance} from "../../../actions/Performance_Evaluation-action"
import * as yup from 'yup'
import { useStateValue } from '../../../context/store';

const Index = (props) => {
    const classes = styles();
    const history = useHistory();
  
    const [{relations, evaluationPerformanceSearch, dataAplication}, dispatch] = useStateValue()
    const [valuesForm, setValuesForm ] = useState({
        status: ''
    })

    const schema = yup.object().shape({
        name: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .min(10, 'Ingrese mínimo 10 caracteres')
        .max(500, 'Ingrese máximo 500 caracteres'),
        description: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .min(10, 'Ingrese mínimo 10 caracteres')
        .max(1000, 'Ingrese máximo 1000 caracteres'),
        status: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .nullable(true)
    })
    const {register, handleSubmit, setValue, trigger, formState:{errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onTouched',
        reValidateMode: 'onChange'
    })

    const closeModal = () => {props?.setMessage({...props?.message, open:false}); dispatchEditNotDisabled()}
    const handleCloseSuccess = () => {
        closeModal();
        history.push(`${PATH_APP}/evaluation-performance-evaluator`); 
        dispatch({
        type: "SET_FILTER_EVALUATION_PERFORMANCE",
        filter: evaluationPerformanceSearch?.filter,
        isFilter: true,
        typeAction: 'GO_BACK'
      })
      dispatch({ 
        type: 'SET_RELATIONS_EVALUATION',
        data: [],
        dataSections: [],
        sectionSelected: '',
        behaviorSelected: '',
        competencieSelected: '',
        selectedQuestions: ''
    })
    dispatch({
        type: "SET_DATA_QUESTIONNAIRE",
        data: [],
        dataSections: []
    })
      dispatchEditNotDisabled()
    }


    const dispatchEditDisabled = () =>  dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Gestionar modelo de evaluación",
        index: 12,
        module: 'EVALUATION_EVALUATOR',
        idForm: 'submitRelations',
        textIsSubmit: props?.isCopy ? "CREAR COPIA" : "EDITAR",
        isSubmit: true,
        disabledSubmit: true,
        subPath: props?.isCopy ? `/autoevaluaciones/evaluation-performance-evaluator/detail/${props?.idEvaluation}` :"/autoevaluaciones/evaluation-performance-evaluator"
      });

      const dispatchEditNotDisabled = () =>  dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Gestionar modelo de evaluación",
        index: 12,
        module: 'EVALUATION_EVALUATOR',
        idForm: 'submitRelations',
        textIsSubmit: props?.isCopy ? "CREAR COPIA" :"EDITAR",
        isSubmit: true,
        disabledSubmit: false,
        subPath: props?.isCopy ? `/autoevaluaciones/evaluation-performance-evaluator/detail/${props?.idEvaluation}` : "/autoevaluaciones/evaluation-performance-evaluator"
      });

    const onSubmit = (data) => {

        dispatch({
            type: "SET_DATA_QUESTIONNAIRE",
            data: data,
            dataSections: []
        })


        if(props?.isEdit || props?.isCopy){
            let behaviorsWithOut = 0;
            let questionsWithOutRol = 0;

            const newDataForm = {
                description: data?.description,
                status: data?.status, 
                name: data?.name
            }
            const newDataFormCopy = {
                description: data?.description,
                status: 'ACTIVE', 
                name: data?.name
            }
           
            for (let index = 0; index < relations?.dataSections.length; index++) {
                if(relations?.dataSections[index]?.competences?.length){
                 continue
                } else {
                 return props?.setMessage({
                   open : true,
                   description: "Verifique que cada una de las secciones contenga relaciones asociadas",
                   handleClick : () => closeModal() ,
                   oneButtons : true,
                   textButtonSubmit: 'ACEPTAR',
                   type : 'WARNING',
               })
                }
                 
               }

            const validateBehavior = (com, action) => {
 
                
                if(com ){

                    const newBehaviors= []
                    let positionB = 0;

    
                    const findCompetence = props?.currentData?.find((x)=> x?.uuid === com);
                    const currentBehaviors = findCompetence?.behaviors

              
                    const totalData = relations?.data?.find((x)=> x?.uuid === com);
                    const totalBehaviors = totalData?.behaviors

                    if(action === 'CREATE'){
              
                        for (let index = 0; index < totalData?.behaviors?.length; index++) {
                           positionB++
                            newBehaviors.push(
                                {
                                    uuid: totalBehaviors[index]?.uuid,
                                    position: positionB,
                                    questions: totalBehaviors[index]?.questions
                                }
                            )
                        }
                    return newBehaviors
                    }else {

    
                        for (let index = 0; index < totalBehaviors?.length; index++) {
                            positionB++
                            const findBehavior = currentBehaviors.find((x)=> x?.uuid === totalBehaviors[index]?.uuid)
                             
                            if(findBehavior) {
                                    newBehaviors.push(
                                        {
                                            uuid: findBehavior?.uuid,
                                            position: positionB,
                                            operationType: null,
                                            questions: totalBehaviors[index]?.questions
                                        }
                                    )
                            } else {
                                newBehaviors.push(
                                    {
                                        uuid: totalBehaviors[index]?.uuid,
                                        position: positionB,
                                        operationType: "CREATE",
                                        questions: totalBehaviors[index]?.questions
                                    }
                                )
                            }

                  
                        }

                            for (let index = 0; index < currentBehaviors.length; index++) {

                                const deleteBehavior = totalBehaviors.find((x)=> x?.uuid === currentBehaviors[index]?.uuid)
                                if(!deleteBehavior) {
                                    newBehaviors.push(
                                        {
                                            uuid: currentBehaviors[index]?.uuid,
                                            operationType: "DELETE",
                                        }
                                    )
                                }
                            }
                        
     
                            return newBehaviors
                            }
                    
                    }
                
              }
           
         
          const validateQuestions = (com, beh, dataQues, operC, operB) => {


            if(com && beh ){
            const findCompetence = props?.currentData?.find((x)=> x?.uuid === com)
            const findBehavior = findCompetence?.behaviors?.find((x)=> x?.uuid === beh )

            const newQuestions = []
            let count = 0;
            for (let index = 0; index < dataQues?.length; index++) {
                const findQuestion = findBehavior?.questions?.find((x)=> x?.uuid === dataQues[index]?.uuid)
               
                if(operC === 'CREATE'  ){
            
                    count++;
                    newQuestions.push(
                        {
                            position: count,
                            uuid: dataQues[index]?.uuid,
                            evaluators: dataQues[index]?.rols?.map((r) => r)
                        })
                  }else if ( operC === 'UPDATE' && findQuestion){
                    count++;
                    newQuestions.push(
                        {
                              position: count,
                              uuid: dataQues[index]?.uuid,
                              evaluators: dataQues[index]?.rols?.map((r) => r)
                        })
                  }else if ( operC === 'UPDATE' && !findQuestion){

                    count++;
                    newQuestions.push(
                        {
                            position: count,
                            uuid: dataQues[index]?.uuid,
                            evaluators: dataQues[index]?.rols?.map((r) => r)
                        })
                  }else if ( operC === null && operB === 'CREATE'){

                    count++;
                    newQuestions.push(
                        {
                              position: count,
                              uuid: dataQues[index]?.uuid,
                              evaluators: dataQues[index]?.rols?.map((r) => r)
                        })
                  }else if ( operC === 'UPDATE' && operB === 'CREATE' ){

                    count++;
                    newQuestions.push(
                        {
                              position: count,
                              uuid: dataQues[index]?.uuid,
                              evaluators: dataQues[index]?.rols?.map((r) => r)
                        })
                  }else if ( operC === null && operB === null && findQuestion){

                    count++;
                     newQuestions.push(
                        {
                            operationType: null,
                            position: count,
                            uuid: findQuestion?.uuid,
                            evaluators: dataQues[index]?.rols?.map((r) => r)
                        })
                  }else if ( operC === null && operB === null && !findQuestion){

                    count++;
                     newQuestions.push(
                        {
                            operationType: 'CREATE',
                            position: count,
                            uuid: dataQues[index]?.uuid,
                            evaluators: dataQues[index]?.rols?.map((r) => r)
                        })
                  }
            }


            for (let index = 0; index < findBehavior?.questions?.length; index++) {
                const validateIfExists = newQuestions?.find((x)=> x?.uuid === findBehavior?.questions[index]?.uuid)
               if(validateIfExists){
                continue
               } else {
                newQuestions.push(
                    {
                        operationType: 'DELETE',
                        uuid: findBehavior?.questions[index]?.uuid
                    }
                )
               }
                
            }
            
            return newQuestions
            }else {
                return null
            }
            
          }


          const validateLevel = relations?.data?.find((x)=> !x?.domainLevel )
          const validateBehaviors = relations?.data?.find((x)=> !x?.behaviors?.length )
            if(!relations?.data?.length){
                return props?.setMessage({
                    open : true,
                    description: 'No hay ninguna acción de relación',
                    handleClick : () => closeModal() ,
                    oneButtons : true,
                    textButtonSubmit: 'ACEPTAR',
                    type : 'WARNING',
                })
            } else if(validateBehaviors || !relations?.data?.length) {
                return props?.setMessage({
                            open : true,
                            description: 'Un/@s criterios no tienen aspectos asociados ',
                            handleClick : () => closeModal() ,
                            oneButtons : true,
                            textButtonSubmit: 'ACEPTAR',
                            type : 'WARNING',
                        })
            }else {
      
            for (let index = 0; index < relations?.data?.length; index++) {
              const behaviors = relations?.data[index]?.behaviors
      
              for (let index = 0; index < behaviors?.length; index++) {
                const question = behaviors[index]?.questions
      
                if(question?.length >= 1){
                    for (let posQ = 0; posQ < question?.length; posQ++) {
                        if(!question[posQ]?.rols){
                            questionsWithOutRol = questionsWithOutRol+1
                        }
                    }
                 
                  continue
                } else {
                  behaviorsWithOut = behaviorsWithOut+1
                  continue
                }
              } 
            }
      
            if(behaviorsWithOut >= 1){
              return dispatch({
                type: 'SET_MODAL_ACTION',
                open: true,
                message: 'Algún aspecto no tiene relacionada ninguna pregunta',
                icon: 'WARNING'
              })
            }else if(validateLevel) {
                return props?.setMessage({
                            open : true,
                            description: 'Criterio/s sin nivel de dominio',
                            handleClick : () => closeModal() ,
                            oneButtons : true,
                            textButtonSubmit: 'ACEPTAR',
                            type : 'WARNING',
                        })
            }else if(questionsWithOutRol >= 1) {
                return props?.setMessage({
                            open : true,
                            description: 'Pregunta/s sin asignación de almenos un tipo de evaluación',
                            handleClick : () => closeModal() ,
                            oneButtons : true,
                            textButtonSubmit: 'ACEPTAR',
                            type : 'WARNING',
                        })
            }else {
                const copyData = [...relations?.data]
                let comAndBeh = [];

                const newCompetences = []
                let countC = 0;
                for (let index = 0; index < copyData?.length; index++) {

                    countC++;
                  
                    const findCompetence = props?.currentData?.find((x)=> x?.uuid === copyData[index]?.uuid)

                    if(findCompetence  ){

                        if(findCompetence?.section?.uuid !== copyData[index]?.section && copyData[index]?.section === ""){
                      
    
                            newCompetences.push(
                                {
                                    operationType: 'UPDATE',
                                    position: countC,
                                    uuid: findCompetence?.uuid,
                                    section: null,
                                    domainLevel: copyData[index]?.domainLevel,
                                    conducts: copyData[index]?.behaviors
    
                                })
                        } else if(findCompetence?.section?.uuid !== copyData[index]?.section && copyData[index]?.section !== "") {
                            newCompetences.push(
                                {
                                    operationType: 'UPDATE',
                                    position: countC,
                                    uuid: findCompetence?.uuid,
                                    section: {uuid: copyData[index]?.section},
                                    domainLevel: copyData[index]?.domainLevel,
                                    conducts: copyData[index]?.behaviors
    
                                })
                        } else {
                            newCompetences.push(
                                {
                                    operationType: null,
                                    position: countC,
                                    uuid: findCompetence?.uuid,
                                    section: {uuid: copyData[index]?.section},
                                    domainLevel: copyData[index]?.domainLevel,
                                    conducts: copyData[index]?.behaviors
    
                                })
                        }
                       
                    } else {
                        newCompetences.push(
                            {
                                operationType: "CREATE",
                                position: countC,
                                uuid: copyData[index]?.uuid,
                                section: {uuid:copyData[index]?.section},
                                domainLevel: copyData[index]?.domainLevel,
                                conducts: copyData[index]?.behaviors
                            })
                    }

                }
                for (let index = 0; index < props?.currentData?.length; index++) {
                    const validateIfExists = newCompetences?.find((x)=> x?.uuid === props?.currentData[index]?.uuid);

                    if(validateIfExists){
                        continue
                    } else {
                        newCompetences.push(
                            {
                                operationType: 'DELETE',
                                uuid: props?.currentData[index]?.uuid
                            })
                        }
                        
                }



                for (let index = 0; index < newCompetences?.length; index++) {
                    if(newCompetences[index]?.operationType !== 'DELETE'){
                        comAndBeh.push(
                            {
                              uuid : newCompetences[index]?.uuid, 
                              position: newCompetences[index]?.position,
                              operationType: newCompetences[index]?.operationType,
                              section:  newCompetences[index]?.section,
                              domainLevel: newCompetences[index]?.domainLevel,
                              conducts: validateBehavior(newCompetences[index]?.uuid, newCompetences[index]?.operationType )  
                            })
                    } else {
                        comAndBeh.push(
                            {
                                operationType: 'DELETE',
                                uuid: newCompetences[index]?.uuid
                            })
                    }
                    
                   
                    }


                    const finalCompetence = [];
                    let currentCompe = {};

                for (let indexC = 0; indexC < comAndBeh?.length; indexC++) {

                     currentCompe = {
                        uuid : comAndBeh[indexC]?.uuid, 
                        position: comAndBeh[indexC]?.position,
                        domainLevel: comAndBeh[indexC]?.domainLevel,
                        section:  comAndBeh[indexC]?.section,
                        operationType: comAndBeh[indexC]?.operationType,
                        conducts: []
                      }

                    for (let index = 0; index < comAndBeh[indexC]?.conducts?.length; index++) {


                        if(comAndBeh[indexC]?.conducts[index]?.operationType === "DELETE"){
                            currentCompe?.conducts?.push({uuid: comAndBeh[indexC]?.conducts[index]?.uuid, operationType: comAndBeh[indexC]?.conducts[index]?.operationType })
                       }else if (!comAndBeh[indexC]?.conducts[index]?.operationType ){
                        currentCompe?.conducts?.push(
                            {
                                uuid:  comAndBeh[indexC]?.conducts[index]?.uuid,
                                position:  comAndBeh[indexC]?.conducts[index]?.position, 
                                questions: 
                                validateQuestions(
                                    comAndBeh[indexC]?.uuid, 
                                    comAndBeh[indexC]?.conducts[index]?.uuid, 
                                    comAndBeh[indexC]?.conducts[index]?.questions, 
                                    comAndBeh[indexC]?.operationType,  
                                    comAndBeh[indexC]?.conducts[index]?.operationType
                                )
                               
                            })
                       } else {
                        currentCompe?.conducts?.push(
                            {
                                uuid:  comAndBeh[indexC]?.conducts[index]?.uuid,
                                position:  comAndBeh[indexC]?.conducts[index]?.position, 
                                operationType: comAndBeh[indexC]?.conducts[index]?.operationType,
                                questions: 
                                validateQuestions(
                                    comAndBeh[indexC]?.uuid, 
                                    comAndBeh[indexC]?.conducts[index]?.uuid, 
                                    comAndBeh[indexC]?.conducts[index]?.questions, 
                                    comAndBeh[indexC]?.operationType,  
                                    comAndBeh[indexC]?.conducts[index]?.operationType
                                )
                               
                            })
                       }
                    }
                  
                   finalCompetence.push(currentCompe)
                   
                }
 
              const newData = {...newDataForm, competences: [...finalCompetence]}

            const updateformance = async()=> {

              dispatchEditDisabled()
              const result = await updateEvaluation(props?.idEvaluation, newData, dispatch) 
              if (result && (result?.status >= 200 && result?.status <= 204)) {
                props?.setMessage({
                  open : true,
                  title: '¡Proceso exitoso!',
                  description: 'Información almacenada correctamente ',
                  handleClick : () => handleCloseSuccess() ,
                  textButtonSubmit: 'ACEPTAR',
                  oneButtons : true,
                  type : 'SUCCESS',
                })
                }
                else if (result?.status > 399) {
                    props?.setMessage({
                    open : true,
                    description: result?.data?.message,
                    handleClick : () => closeModal() ,
                    oneButtons : true,
                    type : 'WARNING',
                  })
                } else {
                    props?.setMessage({
                    open : true,
                    description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
                    handleClick : () => closeModal() ,
                    oneButtons : true,
                    type : 'WARNING',
                  })
                }
              };
              const copyEvaluations = async()=> {
                
                let behaviorsWithOut = 0;
                if(!dataAplication?.data?.description){
                  return null
                } else {
        
                    const validateBehaviors = relations?.data?.find((x)=> !x?.behaviors?.length )
                    const validateLevel = relations?.data?.find((x)=> !x?.domainLevel )
                    if(!relations?.data?.length){
                        return props?.setMessage({
                            open : true,
                            description: 'No hay ninguna acción de relación',
                            handleClick : () => closeModal() ,
                            oneButtons : true,
                            textButtonSubmit: 'ACEPTAR',
                            type : 'WARNING',
                        })
                    } else if(validateBehaviors || !relations?.data?.length) {
                        return props?.setMessage({
                                    open : true,
                                    description: 'Un/@s criterios no tienen aspectos asociados ',
                                    handleClick : () => closeModal() ,
                                    oneButtons : true,
                                    textButtonSubmit: 'ACEPTAR',
                                    type : 'WARNING',
                                })
                    }else {
            
                    for (let index = 0; index < relations?.data?.length; index++) {
                    const behaviors = relations?.data[index]?.behaviors
            
                    for (let index = 0; index < behaviors?.length; index++) {
                        const question = behaviors[index]?.questions
            
                        if(question?.length >= 1){
                        continue
                        } else {
                        behaviorsWithOut = behaviorsWithOut+1
                        continue
                        }
                    } 
                    }
            
                    if(behaviorsWithOut >= 1){
                    return dispatch({
                        type: 'SET_MODAL_ACTION',
                        open: true,
                        message: 'Algún/os aspecto/s no tiene/n relacionada ninguna pregunta',
                        icon: 'WARNING'
                    })
                    }else if(validateLevel) {
                    return props?.setMessage({
                                open : true,
                                description: 'Criterio/s sin nivel de dominio',
                                handleClick : () => closeModal() ,
                                oneButtons : true,
                                textButtonSubmit: 'ACEPTAR',
                                type : 'WARNING',
                            })
                    }else {
                    const copyData = [...relations?.data]
                    let competences = []
                    for (let index = 0; index < copyData?.length; index++) {
                       
                            competences.push(
                                {
                                uuid : copyData[index]?.uuid, 
                                position: copyData[index]?.position,
                                domainLevel: copyData[index]?.domainLevel,
                                conducts: copyData[index]?.behaviors?.map((x, index)=>(
                                    {uuid: x?.uuid,
                                    position: index, 
                                    questions: x?.questions?.map((f, index)=> (
                                        {
                                            position: index,
                                            uuid: f?.uuid,
                                            evaluators: f?.rols?.map((r) => r)
                                        }
                                    ))
                                    }))
                                })
                            
                            }
                
                            const newData = {...newDataFormCopy, competences: [...competences]}
                            
                          
                        for (let index = 0; index < relations?.dataSections?.length; index++) {
                        if(relations?.dataSections[index]?.competences?.length){
                        continue
                        } else {
                        return props?.setMessage({
                            open : true,
                            description: "Verifique que cada una de las secciones contenga relaciones asociadas",
                            handleClick : () => closeModal() ,
                            oneButtons : true,
                            textButtonSubmit: 'ACEPTAR',
                            type : 'WARNING',
                        })
                        }
                        
                        }

                        dispatchEditDisabled()
                        const result = await createPerformance( newData, dispatch) 
                        if (result && (result?.status >= 200 && result?.status <= 204)) {
                          props?.setMessage({
                            open : true,
                            title: '¡Proceso exitoso!',
                            description: 'Información almacenada correctamente ',
                            handleClick : () => handleCloseSuccess() ,
                            textButtonSubmit: 'ACEPTAR',
                            oneButtons : true,
                            type : 'SUCCESS',
                          })
                          }
                          else if (result?.status > 399) {
                              props?.setMessage({
                              open : true,
                              description: result?.data?.message,
                              handleClick : () => closeModal() ,
                              oneButtons : true,
                              type : 'WARNING',
                            })
                          } else {
                              props?.setMessage({
                              open : true,
                              description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
                              handleClick : () => closeModal() ,
                              oneButtons : true,
                              type : 'WARNING',
                            })
                          }
                    }
               
            }
        }
                }
            if( props?.isCopy){
                return copyEvaluations()
            }else {
                return updateformance();
            }
            }
          }
       }else{
        history.push('evaluation-performance/create')
       } 
    }

    useEffect(() => {
        window.scrollTo(0,0)
        if(dataAplication?.data?.name && !props?.isCopy){

            setValue('status', dataAplication?.data?.status)
            setValue('name', dataAplication?.data?.name)
            setValue('description', dataAplication?.data?.description)
            setValuesForm({...valuesForm, status:dataAplication?.data?.status })
            dispatch({
                        type: "SET_BREADCRUMB",
                        isSubmit: true,
                        breadCrumbText: "Autoevaluaciones",
                        breadCrumbSubText: "Crear modelo de evaluación",
                        module: "EVALUATION_PERFORMANCE",
                        idForm: 'submitRelations',
                        index: 10,
                        textIsSubmit: "CREAR",
                        disabledSubmit: true,
                      });
        } else if (props?.isCopy) {
            setValue('status', dataAplication?.data?.status)
            setValue('name', props?.isCopy ? `-- copia -- ${dataAplication?.data?.name}` : dataAplication?.data?.name)
            setValue('description', dataAplication?.data?.description)
            setValuesForm({...valuesForm, status:'ACTIVE' })
        }else if(props?.isEdit){
            setValue('status', dataAplication?.data?.status)
            setValue('name', dataAplication?.data?.name)
            setValue('description', dataAplication?.data?.description)
            setValuesForm({...valuesForm, status:dataAplication?.data?.status })
        }
    }, [])


    const handleChange = (e)=> {
        const {value, name} = e.target
        setValue(name, value)
        setValuesForm({...valuesForm, [name]:value})
        trigger('status')
        
    }
     
  return (
        <form id={props?.isEdit || props?.isCopy ? "submitRelations" : null} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} maxWidth="inherit" alignItems="flex-start" 
                style={{ background: props?.isEdit ? 'rgb(239 239 239)': null, position: 'initial'}}
            >
                <Grid item lg={4.4} md={4.4} fullWidth>
                    <FormControl fullWidth variant='outlined' size='small'>
                        <TextField 
                            {...register('name')}
                            fullWidth
                            size='small'
                            placeholder='Nombre'
                            multiline
                            margin='none'
                            maxRows={15}
                            className={classes.dropdownMultiple}
                            error={!!errors.hasOwnProperty("name") }
                        />
                    <FormHelperText style={{color:'#d70909'}}>
                        {!!errors.hasOwnProperty("name") &&
                        errors["name"].message}
                    </FormHelperText>
                    </FormControl>
                </Grid>
                    <Grid item md={2} lg={2} style={{alignItems: 'center'}}>
                        <FormControl fullWidth size="small">
                        <InputLabel  error={!!errors.hasOwnProperty("status") && errors["status"].message}></InputLabel>
                            <TextField
                            select
                            size="small"
                            {...register('status')}
                            onChange={handleChange}
                            value={valuesForm.status}
                            label="Estado"
                            InputLabelProps={{
                                className: classes.labelProps
                            }}
                            error={!!errors.hasOwnProperty("status") && errors["status"].message}
                            className={classes.dropdown}
                            >
                                <MenuItem value='ACTIVE' key={1}>Activo</MenuItem>
                                <MenuItem value='INACTIVE' key={2}>Inactivo</MenuItem>
                            
                            </TextField>
                            <FormHelperText style={{color:'#d70909'}}>
                                {!!errors.hasOwnProperty("status") &&
                                errors["status"].message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} fullWidth style={{marginBottom: props?.isEdit || props?.isCopy ?'4%': null}}>
                    <FormControl fullWidth variant='outlined' size='small'>
                        <TextField 
                            {...register('description')}
                            fullWidth
                            size='small'
                            multiline
                            minRows={4}
                            maxRows={20}
                            placeholder='Descripción'
                            className={classes.dropdownMultiple}
                            error={!!errors.hasOwnProperty("description") }
                        />
                    <FormHelperText style={{color:'#d70909'}}>
                        {!!errors.hasOwnProperty("description") &&
                        errors["description"].message}
                    </FormHelperText>
                    </FormControl>
                </Grid>
                {props?.isEdit || props?.isCopy ? null :
                    <Grid item lg={12} md={12} style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', marginTop: '3%'}}>
                        &nbsp;&nbsp;
                        <Button
                            type="submit"
                            variant="contained"
                            style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 5 }}
                        >
                            CONTINUAR
                        </Button>
                    </Grid>
                }
            </Grid>
        </form>
        )
}

export default Index