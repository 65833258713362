export const initialState = {
    filter: {
        positionName: null,
        regionalName: null,
    },
    isFilter: false,
    typeAction: null
  };
  
  const searchGraphicReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_FILTER_GRAPHIC":
        return {
          ...state,
          filter: action.filter,
          isFilter: action.isFilter,
          typeAction: action.typeAction,
        };
      default:
        return state;
    }
  };
  
  export default searchGraphicReducer;
  