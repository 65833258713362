export const initialState = {
    active: false,
  };
  
  const loadingTablesReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_ACTIVE_LOADING":
        return {
          ...state,
          active: action.active,
        };
      default:
        return state;
    }
  };
  
  export default loadingTablesReducer;
  