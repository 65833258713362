import React, {useState, useEffect} from 'react';
import {
    Grid,
    FormControl,
    TextField,
    Container,
    Typography
} from "@mui/material";
import { useStateValue } from "../../../../../context/store";
import {styles} from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Index = (props) => {
    
    const classes = styles();
    const [{  valorationQuestionnaire,valorateAplication }, dispatch ] = useStateValue();
    const [press, setPress] = useState({})
    const [, setValueQuestion] = useState()


    const schema = yup.object().shape({
     
      valueTotalQuestion: yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,2}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
    })

    const { register, handleSubmit, formState:{errors}, setValue } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });

   useEffect(()=> {
        if(valorationQuestionnaire?.typeValoration === 'automatic'){
        dispatch({
          type: "SET_VALUE_QUESTIONNAIRE",
          totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
          typeValoration: 'automatic',
          activeValoration: false
        });
        }else {
          dispatch({
            type: "SET_VALUE_QUESTIONNAIRE",
            totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
            typeValoration: 'manual',
            activeValoration: false
          });
        }
        setValue('valueTotalQuestion', Number((valorationQuestionnaire?.totalValueQuestionnaire)) ? Number((valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
        setValueQuestion(Number((valorationQuestionnaire?.totalValueQuestionnaire)) ? Number((valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
      },[valorationQuestionnaire?.activeValoration ])

      const onSubmit= () => {
        dispatch({
              type: "SET_MODAL_VALORATE_APLICATION",
              activeValorate: true,
              dataClear: valorateAplication?.data ?  valorateAplication?.data :[],
              data: valorateAplication?.data ?  valorateAplication?.data :[]
            })
      }

    const handleOnclick = (name) => {

      const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)
      if(press?.press === name){
        return null
      }else { 
        dispatch({
          type: "SET_MODAL_VALORATE_APLICATION",
          icon:'WARNING',
          data: valorateAplication?.data ? 
          [...filterUuid, 
            {
              uuid: `${props?.uuid}`, 
              questionAlternativeList: [
                { 
                  uuid: name,
                },
              ],
            }] 
            : 
            [
              {
                uuid: `${props?.uuid}`,  
                questionAlternativeList: [
                  { 
                    uuid: name, 
                  },
                ],
              }],
        })
      }
      if(name){
        setPress({press: name})
      }
    }

  const checkboxBigLeft = (name, value) => {
  
    return   <Container style={{display: 'flex', alignItems: 'center', flexDirection: props?.preview ? 'column' : null }}>
    {props?.preview ? null : <span style={{marginRight: 10, fontSize: 14, color: "#8E8E8E", width: 'max-content'}}>{value}</span>}
  <div className={classes.circulo} name={name} onClick={() => {
    if(press.press === name){
     return handleOnclick(null)
    }
    handleOnclick(name)}
  }
    >
    <div  className={ press.press === name ? classes.circulo2 : null}></div>
  </div>
  {/* {props?.preview ? <Typography style={{fontSize: 14, color: 'rgb(142, 142, 142)', wordBreak: 'break-all'}}>{watch(`description1`)}</Typography> : null} */}
  </Container>
  }

  const checkboxBigStandard = (name) => {
    return   <Container style={{display: 'flex', alignItems: 'center', flexDirection: props?.preview ? 'column' : null}}>
  <div className={classes.circuloStand} name={name} onClick={() => {
    if(press.press === name){
      return handleOnclick(null)
    }
    handleOnclick(name)}
  }
  >
    <div  className={ press.press === name ? classes.circuloStand2 : null}></div>
  </div>
  {props?.preview ? <Typography style={{fontSize: 14, color: 'rgb(142, 142, 142)', wordBreak: 'break-all'}}></Typography> : null}
  </Container>
  }

  const checkboxsmall = (name) => {
    return   <Container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: props?.preview ? 'column' : null}} maxWidth="sm">
    {/* <span style={{marginRight: 10, fontSize: 14, color: "#8E8E8E"}}>minitmo</span> */}
  <div className={classes.circuloMed} name={name} onClick={() => {
    if(press.press === name){
      return handleOnclick(null)
    }
    handleOnclick(name)}
  }
    >
    <div  className={ press.press=== name ? classes.circuloMed2 : null}></div>
  </div>
  {/* {props?.preview ? <Typography style={{fontSize: 14, color: 'rgb(142, 142, 142)', wordBreak: 'break-all'}}></Typography> : null} */}
  </Container>
  }

  const checkboxBigRight = (name, value) => {
    return   <Container style={{display: 'flex', alignItems: 'center', flexDirection: props?.preview ? 'column' : null }}>
    <div className={classes.circulo} name={name} onClick={() => {
      if(press.press === name){
        return  handleOnclick(null)
      }
      handleOnclick(name)} 
    }
    >
    <div  className={ press.press === name ? classes.circulo2 : null}></div>
  </div>
    {!props?.preview ? 
    <span style={{marginLeft: 10, fontSize: 14, color: "#8E8E8E", width: 'max-content'}}>{value}</span> 
    : 
    null
    // <Typography style={{fontSize: 14, color: 'rgb(142, 142, 142)', wordBreak: 'break-all'}}>{watch(`description${num}`)}</Typography> 
     } 
  </Container>
  }

  
  const checkbox = () => {
    
    
    if(props?.valuesRanking?.length === 3){
     
      return <Container 
        style={{display: 'flex', margin: 25, width: 600, alignItems: props?.preview ? 'baseline' : null }} >
      {checkboxBigLeft(props?.valuesRanking[0]?.uuid, props?.valuesRanking[0]?.key)}
      {checkboxsmall(props?.valuesRanking[1]?.uuid)}
      {checkboxBigRight(props?.valuesRanking[2]?.uuid, props?.valuesRanking[2]?.key)}
      </Container >
    }
    if(props?.valuesRanking?.length === 5){
      return <Container 
        style={{display: 'flex', margin: 25, alignItems: props?.preview ? 'baseline' : null}} maxWidth="sm">
      {checkboxBigLeft(props?.valuesRanking[0]?.uuid, props?.valuesRanking[0]?.key)}
      {checkboxBigStandard(props?.valuesRanking[1]?.uuid)}
      {checkboxsmall(props?.valuesRanking[2]?.uuid)}
      {checkboxBigStandard(props?.valuesRanking[3]?.uuid)}
      {checkboxBigRight(props?.valuesRanking[4]?.uuid, props?.valuesRanking[4]?.key)}
      </Container >
    }
    if(props?.valuesRanking?.length === 7){
      return <Container 
        style={{display: 'flex', margin: '25px -45px', alignItems: props?.preview ? 'baseline' : null}}>
      {checkboxBigLeft(props?.valuesRanking[0]?.uuid, props?.valuesRanking[0]?.key)}
      {checkboxBigStandard(props?.valuesRanking[1]?.uuid)}
      {checkboxBigStandard(props?.valuesRanking[2]?.uuid)}
      {checkboxsmall(props?.valuesRanking[3]?.uuid)}
      {checkboxBigStandard(props?.valuesRanking[4]?.uuid)}
      {checkboxBigStandard(props?.valuesRanking[5]?.uuid)}
      {checkboxBigRight(props?.valuesRanking[6]?.uuid, props?.valuesRanking[6]?.key)}
      </Container>
    }
    if(props?.valuesRanking?.length === 9){
      return <Container 
        style={{display: 'flex', margin: '25px -45px', marginLeft: props?.preview ? 0 :-40, alignItems: props?.preview ? 'baseline' : null}}>
      {checkboxBigLeft(props?.valuesRanking[0]?.uuid, props?.valuesRanking[0]?.key)}
      {checkboxBigStandard(props?.valuesRanking[1]?.uuid)}
      {checkboxBigStandard(props?.valuesRanking[2]?.uuid)}
      {checkboxBigStandard(props?.valuesRanking[3]?.uuid)}
      {checkboxsmall(props?.valuesRanking[4]?.uuid)}
      {checkboxBigStandard(props?.valuesRanking[5]?.uuid)}
      {checkboxBigStandard(props?.valuesRanking[6]?.uuid)}
      {checkboxBigStandard(props?.valuesRanking[7]?.uuid)}
      {checkboxBigRight(props?.valuesRanking[8]?.uuid, props?.valuesRanking[8]?.key)}
      </Container>
     
    }
  
  }

    return  <form id="formAplication" onSubmit={handleSubmit(onSubmit)}>
    <Grid container spacing={1} justifyContent="center" direction="column"  style={{ margin: 'auto', display: 'flex', justifyContent: 'flex-start', marginLeft: '3.5%' }} >
    <Grid item lg={10} style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', alignItems: "center", marginBottom: 15}}>
            <Grid item lg={0.4} style={{color: '#6E6E6E'}}>{props?.position}</Grid>
            <Grid item lg={11.6}>
              <Typography className={classes.question}>{props?.question}</Typography>
            </Grid>
        </div>
        <div>
        <Grid item lg={1} md={2} style={{display: 'flex', marginLeft: '3%', marginBottom: 15}}>
          <FormControl variant='outlined'  size="small">
            <TextField
            {...register('valueTotalQuestion')}
              className={classes.dropdown}
              
              size="small"
              placeholder='Valor'
              disabled={true}
              // onChange={(e)=>handleOnchange(e)}
              error={!!errors.hasOwnProperty("valueTotalQuestion") && errors["valueTotalQuestion"].message}
              value={ props?.valueQuestion }
            />
          </FormControl>
        </Grid>
        </div>
      </Grid>
    </Grid>
     {checkbox()}
     </form>
};

export default Index;
