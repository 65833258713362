import React, { useState, useEffect } from "react";
import * as IdKey from "short-uuid";
import {
  Typography,
  Tooltip,
  Grid,
  IconButton,
  Checkbox
} from "@mui/material";
import { useStateValue } from "../../../../../../context/store";
import {styles} from "./styles"
import _ from "lodash";

export default function DetailedAccordion({
  setMessage,
  message,
  data,
  clearFields,
  page,
  firsLeader,
  confirm,
  handleClickOut,
  handleClick,
  leader,
}) {
  const classes = styles();
  const id = IdKey.generate();
  const [{typesEvaluations }, dispatch] = useStateValue();
  const [checkeds, setCheckeds] = useState([]);
  


const validateChecked = (user) => {

  const result =  checkeds?.find((x) =>x?.uuid === user )
 return result ? true : false
}


const handleChangeChecked = (event, info) => {
  const copy= [...checkeds]
  const {name} = event.target;
  const validateExists = validateChecked(name)


  if(checkeds?.length){
    if(validateExists) {
 
      setCheckeds(checkeds?.filter((x)=> x?.uuid !== name))
      dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: typesEvaluations?.automatic,
        applicationTypes: typesEvaluations?.applicationTypes,
        submitPercentage: typesEvaluations?.submitPercentage,
        users: typesEvaluations?.users,
        selectAll: typesEvaluations?.selectAll,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders?.filter((x)=> x?.uuid !== name)
      });

   }else {
     
    const newCheckeds = [...checkeds, info]

     setCheckeds(newCheckeds);
     dispatch({
      type: "SET_TYPES_EVALUATIONS",
      automatic: typesEvaluations?.automatic,
      applicationTypes: typesEvaluations?.applicationTypes,
      submitPercentage: typesEvaluations?.submitPercentage,
      users: typesEvaluations?.users,
      selectAll: typesEvaluations?.selectAll,
      dataEvaluation: typesEvaluations?.dataEvaluation,
      pages: typesEvaluations?.pages,
      selectNewLeaders: newCheckeds
    });

  }
 
 } else {

  copy.push({...info, pos: checkeds?.length})
  const newCheckeds = [...checkeds, info];

    setCheckeds(newCheckeds);
    dispatch({
      type: "SET_TYPES_EVALUATIONS",
      automatic: typesEvaluations?.automatic,
      applicationTypes: typesEvaluations?.applicationTypes,
      submitPercentage: typesEvaluations?.submitPercentage,
      users: typesEvaluations?.users,
      selectAll: typesEvaluations?.selectAll,
      dataEvaluation: typesEvaluations?.dataEvaluation,
      pages: typesEvaluations?.pages,
      selectNewLeaders: newCheckeds
    });
 }
};



  useEffect(()=> {
      dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: typesEvaluations?.automatic,
        applicationTypes: typesEvaluations?.applicationTypes,
        submitPercentage: typesEvaluations?.submitPercentage,
        users: typesEvaluations?.users,
        selectAll: typesEvaluations?.selectAll,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders
      });
    
      setCheckeds(typesEvaluations?.selectNewLeaders?.length ? typesEvaluations?.selectNewLeaders : [])
  },[data])

  



  useEffect(()=> {

    if(confirm){
      const findEvaluated = typesEvaluations?.users?.find((x)=> x?.uuid === firsLeader?.evaluated);
      const differentsUsers = typesEvaluations?.users?.filter((x)=> x?.uuid !== firsLeader?.evaluated);
  
      const createLeaders = {...findEvaluated, newLeaders: typesEvaluations?.selectNewLeaders}
  
      differentsUsers?.push(createLeaders)
  
       dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: typesEvaluations?.automatic,
        applicationTypes: typesEvaluations?.applicationTypes,
        submitPercentage: typesEvaluations?.submitPercentage,
        users: _.orderBy(differentsUsers, ['pos'],['asc']),
        selectAll: typesEvaluations?.selectAll,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders
      });
      handleClickOut();
      handleClick();
      setCheckeds([])
    }
    

  },[confirm])
  

if(data?.length) {
  return (
    <div className={classes.root}>
      <Grid item container lg={12} direction="row" className={classes.root} cellspacing="0">
        <div className={classes.titlesTable}>
          <span style={{ flexBasis: '8%', display: 'flex'}}>
          </span>
          <Typography style={{fontSize: 12, flexBasis: '39%', textAlign: 'initial' }}>Nombre</Typography>
          <Typography style={{fontSize: 12, flexBasis: '23%', textAlign: 'initial'}}>Identificación</Typography>
          <Typography style={{fontSize: 12, flexBasis: '18%', textAlign: 'initial'}}> Cargo</Typography>
          <Typography style={{fontSize: 12, textAlign: 'initial'}}> Regional</Typography>
        </div>
        {data?.map((data, idColumn=id) => ( 
          <React.Fragment  key={idColumn}>
        <Grid container style={{marginBottom: '-20px'}}>

          <Grid item lg={5} md={3.5} xs={2} className={classes.columnOneTitle} 
              style={{   borderTopLeftRadius: idColumn === 0 ? 10 : 0, 
                        borderBottomLeftRadius: idColumn === data?.length -1 ? 10 : 0, display: 'flex', alignItems: 'center' }}
              >
                {(data?.uuid === firsLeader?.evaluated) || (data?.uuid === firsLeader?.evaluator) ? 
                <Tooltip title="Líder actual" arrow>
                <IconButton>
                  <Checkbox
                    size="small"
                    className={classes.checkboxDisabled}
                    checked={validateChecked(data?.uuid)}
                    name={data?.uuid}
                    disabled={data?.uuid === firsLeader?.evaluated || data?.uuid === firsLeader?.evaluator}
                    onChange={(e) => handleChangeChecked(e,data)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    // disabled={!relations?.behaviorSelected}
                  />
                </IconButton>
              </Tooltip>
              :
               
                  <Checkbox
                    size="small"
                    checked={validateChecked(data?.uuid)}
                    className={classes.checkbox}
                    name={data?.uuid}
                    onChange={(e) => handleChangeChecked(e,data)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    // disabled={!relations?.behaviorSelected}
                  />
                  
                 
                }
                
            <Typography className={classes.heading} style={{marginLeft: '10%'}}>
              <b>{`${data?.name ? data?.name : ''} ${data?.surname ? data?.surname :''}`}</b>
            </Typography>

          </Grid>
          <Grid item lg={2} md={2} xs={2} className={classes.columnOneTitle} >
            <Typography className={classes.heading} style={{marginLeft: '10%',  marginTop: '2%'}}>
              {data?.identificationNumber}
            </Typography>
          </Grid>
          <Grid item lg={3} md={3.5} xs={2} className={classes.columnOneTitle} >
            <Typography className={classes.heading} style={{marginLeft: '10%',  marginTop: '2%'}}>
              {data?.position?.name}
            </Typography>
          </Grid>
          <Grid item lg={2} md={2} xs={2} className={classes.columnOneTitle} style={{  background: '#FFFFFF', 
            borderTopRightRadius: idColumn === 0 ? 10 : 0, 
            borderBottomRightRadius: idColumn === data?.length -1 ? 10 : 0}} >
            <Typography className={classes.heading} style={{marginRight: '10%',  marginTop: '2%'}}>
              {data?.regional?.name}
            </Typography>
          </Grid>
        </Grid>
     </React.Fragment>
        ))}
      </Grid>
      
    </div>
  );
      }else {
        return null;
      }
}
