import React, {useState, useEffect} from 'react';
import { useStateValue } from '../../../context/store';
import Search from "./search";
import Table from "./table";
import Leader from "./leader";
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import { getRegionals, getEmployees, getPositions } from '../../../actions/OrganizationChart-action';
import { Grid, Typography, Paper } from '@mui/material';


const Index = () => {

    const [{filterOrganizationChart, loadingTables, organization, resetPage}, dispatch] = useStateValue();
    const [clearAll, setClearAll] = useState(false);
    const [clearFielForm, setClearFielForm] = useState(false);
    const [allRegionals, setAllRegionals] = useState([]);
    const [allPositions, setAllPositions] = useState([]);
    const [allEmployees, ] = useState([]);
    const [leader, setLeader] = useState({})
    const [currentsLeaders, setCurrentsLeaders] = useState([]);
    const [data, ] = useState({
      idKeyword: null,
      nameKeyword: null,
      positionName: null,
      regionalName: null,
      })
      const [paginadorResponse, setPaginadorResponse] = useState({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });
      const [paginadorRequest, setPaginadorRequest] = useState({
        page: 0,
        size: 10,
      });
    const [message, setMessage] = useState({
      description : '',
      textButtonSubmit : '',
      textButtonCancel: '',
      handleClick : () => {} ,
      handleClickOut : () => {},
      oneButtons : false,
      title: '',
      type : '',
      open : false
    })

    useEffect(()=> {
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Definición de organigrama",
          index: 14,
         
        });
        getRegional();
        getPosition();
      },[])


  useEffect(()=> {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Definición de organigrama",
      index: 14,
     
    });
    if(organization?.leaderId && !resetPage?.reset){
      getAllEmployees()
    }else {
      dataFilter()
      dispatch({
        type: "SET_RESET_PAGE",
        reset: false
      })
    }
   
  },[paginadorRequest])



    const getAllEmployees = async() => {
      const result = await getEmployees({
          identificationKeyword : data.idKeyword === null ? null : data?.idKeyword,
          nameKeyword: data.nameKeyword === null ? null : data?.nameKeyword,
          positionName: data.positionName === null ? null : data?.positionName,
          regionalName: data.regionalName === null ? null : data?.regionalName,
          page: paginadorRequest?.page === null ? null : paginadorRequest?.page,
          size:  paginadorRequest?.size === null ? null : paginadorRequest?.size,
          
      },
      dispatch,
      // {orderBy: 'createdAt'},
      );

    if(result?.status === 204 && !filterOrganizationChart?.typeAction){
      setMessage({
        open : true,
        description: 'No se encontró información relacionada a la búsqueda',
        handleClick : () => closeModalWarning() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })
    }
    if(result?.status === 400){
      setMessage({
        open : true,
        description: 'No se encontró información relacionada a la búsqueda',
        handleClick : () => closeModalWarning() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })
      setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });
    }
    if(result?.status > 399 && result?.status < 405  && !filterOrganizationChart?.typeAction){
      setMessage({
        open : true,
        description: 'No se encontró información relacionada a la búsqueda',
        handleClick : () => closeModalWarning() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })
    }
    if(result?.data?.content?.length < 1 && !filterOrganizationChart?.typeAction){
      setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });
      return setMessage({
        open : true,
        description: 'No se encontró información relacionada a la búsqueda',
        handleClick : () => closeModalWarning() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })
    }
    if (result && (result.status === 200 || result.status === 201)) {
      const data = result?.data;
      if (data?.content?.length > 0) {

        setPaginadorResponse({
          data: data?.content,
          totalRows: data?.totalElements,
          pageSize: data?.size,
          pageNumber: data?.number,
        });

        if(organization?.page !== paginadorRequest?.page  ){
          dispatch({
                      type: 'ORGANIZATION_CHART',
                      changeLeader: organization?.changeLeader,
                      editLeader: organization?.editLeader,
                      leaderToEdit: organization?.leaderToEdit,
                      infoLeader: organization?.infoLeader,
                      subalterns: organization?.subalterns,
                      newSubalterns: organization?.newSubalterns,
                      dataDiagram: organization?.dataDiagram,
                      leaderId: organization?.leaderId,
                      page: null
                  })
        }
      }
      
    } else {
      setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });
    }
    }


    if(filterOrganizationChart &&
      filterOrganizationChart?.isFilter) {
        const newData = filterOrganizationChart?.filter
        data.idKeyword = newData?.idKeyword;
        data.nameKeyword = newData?.nameKeyword
        data.positionName = newData?.positionName;
        data.regionalName = newData?.regionalName;
        paginadorRequest.page = newData?.page;
        paginadorRequest.size = newData?.size
        getAllEmployees()
        dispatch({
          type: "SET_FILTER_ORGANIZATION_CHART",
          filter: filterOrganizationChart?.filter,
          isFilter: false,
        })
    }

    const changePage = (event, nuevaPagina) => {
 
      setPaginadorRequest({
        ...paginadorRequest,
        page: nuevaPagina,
    
       
      });

    };

    const dataFilter = () => setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 1,
    });
  
    const changeAmountOfRecords = (event, siguiente) => {
      
      setPaginadorRequest({
        ...paginadorRequest,
        size: event.target.value,
        page: 0 
      });

    };

    const closeModal = () => {
      setMessage({...message, open: false})
      dataFilter();
      setClearFielForm(!clearFielForm);
      clierFilterLeader()
    }

    const closeModalWarning = () => {
      setMessage({...message, open: false})
      dataFilter();
      setClearFielForm(!clearFielForm);
    }


    const clierFilterLeader = () => setClearAll(!clearAll)

      const getRegional = async() => {
        const result = await getRegionals();  
        setAllRegionals(result?.data?.length ? result?.data : [] )
      }

      const getPosition = async() => {
        const result = await getPositions();  
        setAllPositions(result?.data?.length ? result?.data : [] )
      }


      const getLeader = (x) => setLeader(x);
      const getListLeaders = (x) => setCurrentsLeaders(x)
   
  return (
    <>
      < Leader 
        clearFielForm={clearAll}
        getLeader={getLeader} 
        setCurrentsLeaders={getListLeaders} 
        setMessage={setMessage} 
        message={message}
      /> 
      <Typography style={{margin: '3% 0% 2% 0%', fontWeight: 400, fontSize: 14}}>Seleccione los colaboradores relacionados</Typography>
      <Paper style={{background: '#F5F5F5', borderRadius: '10px'}} elevation={3}>
        <Grid container>
         
          <Grid item lg={11} style={{margin: 'auto'}}>
            < Search 
              dataFilter={dataFilter}
              regionals={allRegionals} 
              employees={allEmployees} 
              positions={allPositions} 
              clearFielForm={clearFielForm}
              setMessage={setMessage}
              message={message}
            />
          </Grid>
          <Grid item lg={11} style={{margin: 'auto'}}>
            {loadingTables?.active ?
              <Box sx={{ display: 'flex',margin: '5%', justifyContent: 'center' }}>
                  <CircularProgress />
              </Box>
                : 
              < Table 
                data={paginadorResponse?.data} 
                clearFields={closeModal} 
                leader={leader} 
                page={paginadorResponse?.pageNumber}
                currentsLeaders={currentsLeaders}
                message={message}
                setMessage={setMessage}
              /> 
            }
            {paginadorResponse?.totalRows !== 0 && !loadingTables?.active &&
              <TablePagination
                  style={{ marginLeft: "28%", display:"flex", color: '#747578' }}
                  component="div"
                  rowsPerPageOptions={[5, 10, 15]}
                  count={paginadorResponse.totalRows}
                  rowsPerPage={paginadorRequest.size}
                  page={paginadorRequest.page}
                  labelRowsPerPage='Registros por página'
                  onPageChange={changePage}
                  onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
              />
            }
          </Grid>
        </Grid>
       
          
      </Paper>

      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        textButtonCancel = {message.textButtonCancel}
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
    </>
  )
}

export default Index