import React, {useEffect, useState} from 'react'
import { useStateValue } from '../../context/store';
import Table from "./table"
import Search from "./search";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import { getCompetencies } from '../../actions/Competencies-actions';
import ModalConfirm from "../../utils/form/modalConfirmBase";
import { PATH_APP } from '../../constants';

const Index = () => {
 const [{competencieSearch, loadingTables}, dispatch] = useStateValue()
 const [clearFielForm, setClearFielForm]= useState(false)

const [paginadorResponse, setPaginadorResponse] = useState({
  data: [],
  totalRows: 0,
  pageSize: 0,
  pageNumber: 0,
});

const [message, setMessage] = useState({
  description : '',
  textButtonSubmit : '',
  handleClick : () => {} ,
  handleClickOut : () => {},
  oneButtons : false,
  title: '',
  type : '',
  open : false
})


  useEffect(()=> {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar criterios",
      index: 8,
      path: `${PATH_APP}/competencies-create`,
      textIsSubmit: "Crear criterio",
      module: "MANAGE_COMPETENCIES"
    });
    getAllCompetencies();
    window.scrollTo(0,0)
  },[])

  useEffect(() => {
    if(competencieSearch?.isFilter){
      getAllCompetencies()
    }
  },[competencieSearch?.isFilter])

  const resetDispatch = () => dispatch({
    type: "SET_FILTER_COMPETENCIE",
    filter: competencieSearch?.filter,
    isFilter: false,
  });

  const activeConsult = () => {
    return dispatch({
      type: "SET_FILTER_COMPETENCIE",
      filter: {page: 0, size: 10},
      isFilter: true,
    });
  }
  const closeModalAndClear = () => setClearFielForm(!clearFielForm)
  const closeModal = () => {setMessage({...message, open:false}); closeModalAndClear()}
  const dataFilter = () => {
      setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
    return activeConsult()
  }


  const notFoundData = (message) => {
    return  setMessage({
      open : true,
      description: message,
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
  }
  

  const getAllCompetencies = async() => {
    try{
      const result = await getCompetencies({
        code : competencieSearch?.filter?.code ?? null,
        name: competencieSearch?.filter?.competencie ?? null,
        status: competencieSearch?.filter?.status ?? null,
        page: competencieSearch?.filter?.page ?? 0,
        size:  competencieSearch?.filter?.size  ?? 10,
        
      },
        {orderBy: 'createdAt'},
        dispatch
      );
      if ((result && result.status === 200) || (result.status === 201)) {
        const data = result?.data;
        if (data?.content?.length > 0) {
        return setPaginadorResponse({
            data: data?.content,
            totalRows: data?.totalElements,
            pageSize: data?.size,
            pageNumber: data?.number,
          });
        }
        
      }
      if(result?.status === 204 && !competencieSearch?.typeAction){
        notFoundData('No se encontró información relacionada a la búsqueda')
      }
      if(result?.data?.content?.length < 1 && !competencieSearch?.typeAction){
        dataFilter()
        return notFoundData('No se encontró información relacionada a la búsqueda')
      }
      
    }catch{
      dataFilter()
      return notFoundData('Occurió un problema comuniquese con el administrador. ')
    }
  }


  useEffect(()=> {
    if(competencieSearch &&
      competencieSearch?.isFilter) {
        getAllCompetencies()
        dispatch({
          type: "SET_FILTER_COMPETENCIE",
          filter: competencieSearch?.filter,
          isFilter: false,
        })
      
        
    }
  },[competencieSearch?.isFilter])
  

  const changePage = (event, nuevaPagina) => {
       
    dispatch({
      type: "SET_FILTER_COMPETENCIE",
      filter: { ...competencieSearch?.filter, page: nuevaPagina},
      isFilter: true,
    })
  };

  const changeAmountOfRecords = (event, siguiente) => {
    dispatch({
      type: "SET_FILTER_COMPETENCIE",
      filter: { ...competencieSearch?.filter, page: 0, size: event.target.value},
      isFilter: true,
    })
  };
  

  return (
    <>
      <Search 
        dataFilter={dataFilter}
        clearFielForm={clearFielForm}
        />
     
     
      {loadingTables?.active ?
          <Box sx={{ display: 'flex', marginTop:'15%', justifyContent: 'center' }}>
           <CircularProgress />
          </Box>
          : 
        <Table 
          data={paginadorResponse?.data}
          message={message}
          setMessage={setMessage}
        />
      }
      {paginadorResponse?.totalRows !== 0 && !loadingTables?.active &&
        <TablePagination
            style={{ display:"flex", justifyContent: 'center', color: '#747578' }}
            component="div"
            rowsPerPageOptions={[5, 10, 15]}
            count={paginadorResponse.totalRows}
            rowsPerPage={competencieSearch?.filter?.size}
            page={competencieSearch?.filter?.page}
            labelRowsPerPage='Registros por página'
            onPageChange={changePage}
            onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
        />
      }
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
    </>
  )
}

export default Index