export const initialState = {
    totalValueQuestionnaire: '',
    typeValoration: '',
    activeValoration: false,
    disabledValors: true
  };
  
  const ValorationQuestionnaireReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_VALUE_QUESTIONNAIRE":
        return {
          ...state,
          totalValueQuestionnaire: action.totalValueQuestionnaire,
          typeValoration: action.typeValoration,
          activeValoration: action.activeValoration,
          disabledValors: action.disabledValors
        };
      default:
        return state;
    }
  };
  
  export default ValorationQuestionnaireReducer;
  