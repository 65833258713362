import React, {useState, useEffect} from 'react';
import {Grid,
    FormControl,
    TextField,
    Checkbox,
    Typography,
} from "@mui/material";
import {styles} from "./styles"
import { useStateValue } from "../../../../../context/store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Index = (props) => {
    const classes = styles();
    const [{ valorationQuestionnaire, valorateAplication },  dispatch] = useStateValue();
    const [checked, setChecked] = useState({})
    const [valueQuestion, setValueQuestion] = useState()


    const schema = yup.object().shape({
     
      valueTotalQuestion: yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,2}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
    })


    const { register, handleSubmit, formState:{errors}, setValue } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });

    const handleChecked = (e) => {
        const { name} = e.target;
        const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)
        if(checked?.name === name){
          return null
        }else { 
          dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList: [
                  { 
                    uuid: name, 
                  },
                ],
              }] 
              : 
              [
                {
                  uuid: `${props?.uuid}`,  
                  questionAlternativeList: [
                    { 
                      uuid: name, 
                    },
                  ],
                }],
          })
        }
        if(name){
          setChecked({name: name})
        }
      }


      useEffect(()=> {
        setValue('valueTotalQuestion', Number((valorationQuestionnaire?.totalValueQuestionnaire)) ? Number((valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
        setValueQuestion(Number((valorationQuestionnaire?.totalValueQuestionnaire)) ? Number((valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
        if(valorationQuestionnaire?.typeValoration === 'automatic'){
        
        dispatch({
          type: "SET_VALUE_QUESTIONNAIRE",
          totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
          typeValoration: 'automatic',
          activeValoration: false
        });
        const findUuid= valorateAplication?.data?.find((x)=> x?.uuid === `${props?.uuid}`)
        const filterUuid = valorateAplication?.data?.filter((x)=> x?.uuid !== `${props?.uuid}`)
      
         // agregando nuevo valor aplicado a la pregunta y sus opciones cuando es manual
         if(findUuid?.questionAlternativeList.length){
           
          const newValues = []
           dispatch({
             type: "SET_MODAL_VALORATE_APLICATION",
             icon:'WARNING',
             data: valorateAplication?.data ? 
             [...filterUuid, 
               {
                 uuid: `${props?.uuid}`, 
                 questionAlternativeList: [
                   {
                    uuid: findUuid?.questionAlternativeList[0]?.uuid,   
                   }
                 ],
               }
             ] 
               : [{uuid: `${props?.uuid}`,  questionAlternativeList: newValues, }],
           })
         } else {
        
          return dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList: [],
              }
            ] 
              : [{uuid: `${props?.uuid}`,  questionAlternativeList: [] }],
          })
         
         }
        }else {
          dispatch({
            type: "SET_VALUE_QUESTIONNAIRE",
            totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
            typeValoration: 'manual',
            activeValoration: false
          });
        }
      
      },[valorationQuestionnaire?.activeValoration ])
    
      
      const onSubmit= () => {
      
        dispatch({
          type: "SET_MODAL_VALORATE_APLICATION",
          activeValorate: true,
          dataClear: valorateAplication?.data ?  valorateAplication?.data :[],
          data: valorateAplication?.data ?  valorateAplication?.data :[],
          valuesQuestions: valueQuestion ? valueQuestion : ''
            })
          }

        
    return  <Grid container spacing={1} justifyContent="center" direction="column"  style={{ margin: 'auto', display: 'flex', justifyContent: 'flex-start', marginLeft: '3.5%' }} >
    <Grid item lg={10} style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', alignItems: "center", marginBottom: 15}}>
            <Grid item lg={0.4} style={{color: '#6E6E6E'}}>{props?.position}</Grid>
            <Grid item lg={11.6}>
              <Typography className={classes.question}>{props?.question}</Typography>
            </Grid>
        </div>
        <div>
        <Grid item lg={1} md={2} style={{display: 'flex', marginLeft: '3%', marginBottom: 15}}>
          <FormControl variant='outlined'  size="small">
            <TextField
            {...register('valueTotalQuestion')}
              className={classes.dropdown}
              
              size="small"
              placeholder='Valor'
              disabled={true}
              // onChange={(e)=>handleOnchange(e)}
              error={!!errors.hasOwnProperty("valueTotalQuestion") && errors["valueTotalQuestion"].message}
              value={ props?.valueQuestion }
            />
          </FormControl>
        </Grid>
        </div>
      </Grid>
     <Grid container spacing={1} direction="column"  maxWidth="sm" style={{margin: '5px 0px 10px 0%'}}>
     {props?.valuesOptions.map((x, index) => { 
      return <form id="formAplication" onSubmit={handleSubmit(onSubmit)} >
          <Grid item xs={12} sm={12} md={12} lg={9} style={{display: 'flex', alignItems: 'center', marginBotton: 20}}>
              <div style={{marginRight:5}}>
                <Checkbox
                  disabled={props?.isDetail ? true : null}
                  checked={props?.isDetail ? x?.valueApplied !== 0 : checked?.name === `${props?.valuesOptions[index]?.uuid}`}
                  name={`${props?.valuesOptions[index]?.uuid}`}
                  indeterminate={checked?.name === `${props?.valuesOptions[index]?.uuid}`}
                  onChange={handleChecked}
                />
              </div>
            <FormControl variant='outlined' fullWidth size="small">
                <TextField
                disabled={true}
                className={classes.dropdown}
                fullWidth
                value={x?.key}
                size="small"
              />
            </FormControl>
       </Grid> 
       </form>
     })}
     {checked?.name ? null :<Typography className={classes.messageInformative}>Seleccione mínimo 1 opción</Typography>}
     </Grid>
   </Grid>
};

export default Index;
