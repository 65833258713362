import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../../../context/store';
import { getByIdEvaluationEvaluated } from "../../../../actions/Performance_Evaluation-action"
import EvaluationEvaluated from "./evaluation"
import HeaderEvaluated from "./header"
import ModalConfirm from "../../../../utils/form/modalConfirmBase";
import { PATH_APP, typesEvaluation } from '../../../../constants';
import LoadingPage from './../../../../utils/loadingPage/loadinPage';
import { showMessageWithOutAction } from '../../../../utils/constants';

const Index = (props) => {
    const {id} = props?.match?.params
    const idEvaluation = id
    const [dataDetail, setDataDetail] = useState({})
    const [activeLoading, setActiveLoading] = useState(false);
    const [ , dispatch] = useStateValue();
    const [message, setMessage] = useState({
      description : '',
      textButtonSubmit : '',
      handleClick : () => {} ,
      handleClickOut : () => {},
      oneButtons : false,
      title: '',
      type : '',
      open : false
    })

  
    useEffect(()=> {
          getEvaluation()
    },[])


    
    const getEvaluation = async() => {
      setActiveLoading(true);
      try{
        const result = await getByIdEvaluationEvaluated(idEvaluation, dispatch)

      if(!result?.data?.expired ){
  
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: typesEvaluation[result?.data?.applicationAnswerType?.type] ?? '',
          index: 12,
          idForm: 'Evaluation',
          module: 'EVALUATION_EVALUATED',
          subPath: `${PATH_APP}/evaluation-performance-evaluated`,
          isSubmit: true,
          textIsSubmit:  "ENVIAR",
        });
        setDataDetail({...result?.data, performanceEvaluation: {
          ...result?.data?.performanceEvaluation,
          competences: result?.data?.performanceEvaluation?.competences?.filter((x)=> x?.conducts?.length)
          }
        })
      }else {
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Responder evaluación",
          index: 12,
          idForm: 'Evaluation',
          module: 'EVALUATION_EVALUATED',
          subPath: `${PATH_APP}/evaluation-performance-evaluated`,
          isSubmit: true,
          textIsSubmit:  "ENVIAR",
        });
      }
      setActiveLoading(false);

      }catch {
        setActiveLoading(false);
        showMessageWithOutAction(dispatch, 'Ocurrio un error en el servicio para mostrar la evaluación', `${PATH_APP}/evaluation-performance-evaluated`)
      }
      

  }



  return (
    <React.Fragment>
      {activeLoading && !dataDetail?.performanceEvaluation?.competences?.length
        ? <LoadingPage />
        : (<>
            <HeaderEvaluated id={idEvaluation} dataDetail={dataDetail} message={message} setMessage={setMessage}/>
            <EvaluationEvaluated dataDetail={dataDetail}/>
          </> )
       }
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
    </React.Fragment>
  )
}

export default Index