import { makeStyles } from '@mui/styles';
import theme  from '../../../../../../theme/theme';

export const styles = makeStyles({
    dropdown: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
        '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
          background: '#dfe7fd',
        },
    },
    circulo :{
        width: 80,
        height: 80,
        borderColor:' #8e8e8e',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial',
      },
      circulo2 :{
        width: 60,
        height: 60,
        borderColor:' #3B38C0',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial',
        margin: 7,
        background: 'rgb(239, 239, 239)'
      },
      circuloMed :{
        width: 30,
        height: 30,
        borderColor:' #8e8e8e',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial',
      },
      circuloMed2 :{
        width: 10,
        height: 10,
        borderColor:'#3B38C0',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial',
        margin: 7,
      },
      circuloStand :{
        width: 60,
        height: 60,
        borderColor:' #8e8e8e',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial',
      },
      circuloStand2 :{
        width: 40,
        height: 40,
        borderColor:'#3B38C0',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial',
        margin: 7,
      },
      radioButton: {
        '& .MuiSvgIcon-root': {
          [theme.breakpoints.only('lg')]:{
            fontSize: 35,
          },
          [theme.breakpoints.only('md')]:{
            fontSize: 35,
          },
          [theme.breakpoints.only('sm')]:{
            fontSize: 25,
          },
          [theme.breakpoints.only('xs')]:{
            fontSize: 25,
          }
        },
      },
      labelCheckbox: {
       color: 'gray',
        [theme.breakpoints.only('sm')]:{
          marginLeft: 0,
        },
        [theme.breakpoints.only('xs')]:{
          marginLeft: 0,
        }
          
      
      }

      
})