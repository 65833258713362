import  React,{useState, useEffect} from 'react';
import { 
    FormControl, 
    Grid, 
    Select,
    FormHelperText,
    InputLabel, 
    MenuItem,
    TextField,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useStateValue } from "../../../context/store";
import { useRouteMatch } from "react-router-dom";
import { styles } from './styles'
import { PATH_APP, REQUIRED_MESSAGE } from '../../../constants';
import {getLengthComtencies} from "../../../actions/Competencies-actions"
import {createBehavior, getBehaviorCode, updateBehavior} from "../../../actions/Behavior-actions"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"; 
import ModalConfirm from "../../../utils/form/modalConfirmBase"; 
import * as yup from "yup";


export default function CreateBehavior(props) {

    const classes = styles();
    const url = useRouteMatch()
    const params = url?.params


  const [{  dataAplication, behaviorSearch }, dispatch] = useStateValue();
  const [, setDisabledSubmit] = useState(false)
  const [, setCompetencies] = useState([])
  const [valuesForm, setValuesForm] = useState({
    code: '',
    status: ''
  })
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
  })


  useEffect(()=> {
    if(params?.id){
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Gestionar aspectosaspectos",
        index: 9,
        isSubmit: true,
        subPath: `${PATH_APP}/behavior`,
        idForm: 'formCompetencie',
        module: 'MANAGE_BEHAVIOR',
        textIsSubmit: "EDITAR",
      });
      sendData()
    } else {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Gestionar aspectos",
        index: 9,
        isSubmit: true,
        subPath: `${PATH_APP}/behavior`,
        idForm: 'formCompetencie',
        module: 'MANAGE_BEHAVIOR',
        textIsSubmit: "CREAR",
      });
      code()
    }
    allCompetencies()
    window.scrollTo(0,0)
  },[])


  const schema = yup.object().shape({
    name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(1, "Ingrese mínimo 1 caracter")
    .max(2000, "Ingrese máximo 2000 caracteres"),
    status: yup
    .string()
    .required(REQUIRED_MESSAGE),
  })
  
  const { register, handleSubmit, trigger, formState:{errors}, setValue,  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

 
  const sendData = () => {
    setValue('status', dataAplication?.data?.status)
    setValue('name', dataAplication?.data?.name)
    setValuesForm({...valuesForm, code:  dataAplication?.data?.code, status:  dataAplication?.data?.status})
  }

  const code = async() => {
    const result = await getBehaviorCode();
    setValuesForm({...valuesForm, code: result?.data?.code ? result?.data?.code : 0})
  }

  const allCompetencies = async() => {
    const result = await getLengthComtencies(dispatch);
    setCompetencies(result?.data?.content)
  }

  const handleCloseModal = () => setMessage({...message, open:false})
  const submitAndCloseModal = () => {
    disabledButtonCreate(false)
    setDisabledSubmit(false)
    handleCloseModal()
  }
  const closeModalError = () => {
    disabledButtonEdit(false)
    handleCloseModal()
  }


  const handleCloseModalAndReset = ()=> {
    disabledButtonCreate(false)
    setDisabledSubmit(false)
    handleCloseModal()
     props?.history?.push(`${PATH_APP}/behavior`)
    dispatch({
        type: "SET_FILTER_BEHAVIOR",
        filter: behaviorSearch?.filter,
        isFilter: true,
        typeAction: 'CREATE'
      });
  }

  const disabledButtonEdit = (bool) => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar aspectos",
      index: 8,
      isSubmit: true,
      subPath: `${PATH_APP}/behavior`,
      idForm: 'formCompetencie',
      module: 'MANAGE_BEHAVIOR',
      textIsSubmit: "EDITAR",
      disabledSubmit: bool
    });
  }
  const disabledButtonCreate = (bool) => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar aspectos",
      index: 8,
      isSubmit: true,
      subPath: `${PATH_APP}/behavior`,
      idForm: 'formCompetencie',
      module: 'MANAGE_BEHAVIOR',
      textIsSubmit: "CREAR",
      disabledSubmit: bool
    });
  }

  const onSubmit = (data) => {
   const create = async() => {
    disabledButtonCreate(true)
    setDisabledSubmit(true)
     const result = await createBehavior(data)
     if (result && (result?.status >= 200 & result?.status <= 204)) {
     setMessage({
        open : true,
        title: '¡Proceso exitoso!',
        description: 'Información almacenada correctamente ',
        handleClick : () => handleCloseModalAndReset() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'SUCCESS',
      })
     }
     else if (result?.status === 400) {
      setMessage({
        open : true,
        description: result?.data?.message,
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }else if (result?.status > 399) {
      setMessage({
        open : true,
        description: 'Error al guardar la información',
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
     } else {
      setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
     }
   } 
   const update = async() => {
    setDisabledSubmit(true)
    disabledButtonEdit(true)
     const result = await updateBehavior(data, params?.id)
     if (result && (result?.status >= 200 & result?.status <= 204)) {
     setMessage({
        open : true,
        title: '¡Proceso exitoso!',
        description: 'Información almacenada correctamente ',
        handleClick : () => handleCloseModalAndReset() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'SUCCESS',
      })
     }
     else if (result?.status === 400) {
      setMessage({
        open : true,
        description: result?.data?.message,
        handleClick : () => closeModalError() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }else if (result?.status > 399) {
      setMessage({
        open : true,
        description: 'Error al guardar la información',
        handleClick : () => closeModalError() ,
        oneButtons : true,
        type : 'WARNING',
      })
     } else {
      setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => closeModalError() ,
        oneButtons : true,
        type : 'WARNING',
      })
     }
   } 
   if(params?.id){
      update()
  }else {
    create()
  }
 }

  const handleOnchange = (e) => {
    const {name, value} = e.target;
    setValuesForm({...valuesForm, [name]:value})
    setValue(name, value)
    trigger(name)
  }

 
  return (
    <form id="formCompetencie" onSubmit={handleSubmit(onSubmit)} >
    <Grid container spacing={2} style={{marginBottom: 20, display: 'flex', alignItems: 'flex-start'}}>
    <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex'}}>
    <ErrorOutlineIcon color="error"  style={{visibility: errors[`name`] ? "initial": "hidden"}} fontSize="small" className={classes.iconError}/>
      <FormControl variant='outlined' fullWidth size="small">
          <TextField
          {...register('name')}
          className={classes.dropdown}
          fullWidth
          type="text"
          onChange={handleOnchange}
          multiline
          maxRows={22}
          size="small"
          label="Aspecto"
          error={!!errors.hasOwnProperty("name") && errors["name"].message}
      />
      <FormHelperText style={{color:'red', marginLeft: '1%'}}>
          {errors.hasOwnProperty("name") &&
              errors["name"].message}
      </FormHelperText> 
      </FormControl>
    </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} style={{display: 'flex'}}>
          <ErrorOutlineIcon color="error"  style={{visibility: errors[`code`] ? "initial": "hidden"}} fontSize="small" className={classes.iconErrorCode}/>
        <FormControl variant='outlined' fullWidth size="small">
            <TextField
            name="code"
            className={classes.dropdown}
            fullWidth
            disabled
            value={params?.id ? valuesForm?.code :valuesForm?.code+1}
            onChange={handleOnchange}
            notched={valuesForm?.code ? 'true' : 'false'}
            InputLabelProps={{ notched: valuesForm?.code ? true : false }}
            size="small"
            label="Código"
            error={!!errors.hasOwnProperty("code") && errors["code"].message}
            />
        </FormControl>
        <FormHelperText style={{color:'red', marginLeft: '4%'}}>
            {errors.hasOwnProperty("code") &&
                errors["code"].message}
        </FormHelperText> 
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} style={{display: 'flex'}}>
          <ErrorOutlineIcon color="error"  style={{visibility: errors[`status`] ? "initial": "hidden"}} fontSize="small" className={classes.iconErrorCode}/>
        <FormControl variant='outlined' fullWidth size="small">
            <InputLabel error={!!errors.hasOwnProperty("status") && errors["status"].message}>Estado</InputLabel>
                <Select
                {...register('status')}
                name='status'
                value={valuesForm.status}
                onChange={handleOnchange}
                shrink={true}
                status="Estado"
                className={classes.dropdown}
                label="Estado "
                error={!!errors.hasOwnProperty("status") && errors["status"].message}
            >
                <MenuItem value='ACTIVE' key={1}>Activo</MenuItem>
                <MenuItem value='INACTIVE' key={2}>Inactivo</MenuItem>
            </Select>
            <FormHelperText style={{color:'red'}}>
            {errors.hasOwnProperty("status") &&
                errors["status"].message}
            </FormHelperText> 
            </FormControl>
        </Grid>
    </Grid>
    <ModalConfirm 
      description = { message.description }
      textButtonSubmit = { message.textButtonSubmit }
      handleClick = { message.handleClick }
      handleClickOut = { message.handleClickOut }
      oneButtons = { message.oneButtons }
      title = { message.title }
      type = { message.type }
      open = { message.open }
    />
</form>
  );
}
