import { makeStyles, withStyles } from '@mui/styles';

export const styles = makeStyles({
    expandText :{ 
        textDecoration: 'underline',
        cursor: 'pointer',
        marginLeft: 5,
        color: '#3300ff'
       },
    modalBank: {
        [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
          maxWidth: 900,
          minWidth: 900,
          borderRadius: 16
      },
      [`& .jss59 .css-uhb5lp`] : {
        maxWidth: 900,
        minWidth: 900,
        borderRadius: 16
      },
      [`& .css-uhb5lp`]: {
        borderRadius: 12,
        maxWidth: 900,
        minWidth: 900,
      }
      },
    titleModalBank: {
        fontWeight: 400,
        textAlign: 'center',
        fontSize: 16,
        fontFamily: 'Roboto',
        letterSpacing: '0.25px'
      },
      wrapperSelect: {
        border: '1px solid gray',
        cursor: 'pointer',
        borderRadius: 10,
        marginBottom: 8,
        display: 'flex', 
        background: '#ffffff',
        wordBreak: 'break-word', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        marginLeft: '4%'
      },
      objectsSelect: {
        display: 'flex',
        position: 'relative', 
        alignItems: 'flex-start', 
      },
  questions: {
    border: '0.5px solid gray',
    borderTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    background: '#e4c4de',
    borderRight: 0
  },
  checkbox :{
    border: '1px solid gray',
    borderTop: 0,
    cursor: 'pointer',
    display: 'grid',
    placeItems: 'center'
  },
  containerquestion: {
    borderRight:'1px solid gray',
  },
})


