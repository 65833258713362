import  React,{useState, useEffect} from 'react';
import { 
    FormControl, 
    Grid, 
    Select,
    FormHelperText,
    InputLabel, 
    MenuItem,
    Typography,
    TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useStateValue } from "../../../context/store";
import DialogContent from '@mui/material/DialogContent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DialogContentText from '@mui/material/DialogContentText';
import { styles } from './styles'
import { REQUIRED_MESSAGE } from '../../../constants';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from 'lodash'
import {updateCriteria } from "../../../actions/CriteriaEvaluation-action"
import * as yup from "yup";


export default function EditCompetencie(props) {

    const classes = styles();
  const [{ criteriaSearch }, dispatch] = useStateValue();
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [valuesForm, setValuesForm] = useState({
    status: '',
    rangeFrom: '',
    rangeTo: ''
  })

  const schema = yup.object().shape({
    description: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(3, 'Ingrese mínimo 3 caracteres')
    .max(1000, 'Ingrese máximo 1000 caracteres'),
    name:  yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(1, "Ingrese mínimo 1 caractere")
    .max(2000, 'Ingrese máximo 2000 caracteres'),
    status: yup
    .string()
    .required(REQUIRED_MESSAGE),
    rangeFrom: yup
    .string()
    .required('Este campo es requerido')
    .matches(
      /^[0-9]{1,2}(\.[0-9]{0,1})?$/g,
      "Número inválido"
    )
    .test("maxLenght", "Ingrese un valor entre 0 y 100", val => val <= 100 && val >= 0)
    .nullable(true),
    rangeTo: yup
    .string()  
    .required('Este campo es requerido')
    .matches(
      /^[0-9]{1,3}(\.[0-9]{0,1})?$/g,
      "Número inválido"
    )
    
    .test( "maxLenght", '', val =>  Number(val) > Number(valuesForm?.rangeFrom) && Number(val) <= 100)
    .nullable(true),
    code: null
  })
  
  useEffect(()=> {
    setValue('code', props?.data?.code)
    setValue('description', props?.data?.description)
    setValue('name', props?.data?.name)
    setValue('status', props?.data?.status)
    setValue('rangeFrom', props?.data?.rangeFrom)
    setValue('rangeTo', props?.data?.rangeTo)
    setValuesForm(
      {...valuesForm, 
        status: props?.data?.status, 
        rangeFrom: props?.data?.rangeFrom, 
        rangeTo: props?.data?.rangeTo
      })

  },[props?.open])
  const { register, handleSubmit,  trigger, clearErrors, formState:{errors}, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });


  const handleCloseModal = () => props.setMessage({...props.message, open:false})
  const submitAndCloseModal = () => {
    setDisabledSubmit(false)
    handleCloseModal()
  }
 

  const handleCloseModalAndReset = ()=> {
    setDisabledSubmit(false)
    props?.closeEdit()
    props?.setMessage({...props?.message, open: false})
    if(props?.createInQuestion) {
      props?.refrehsTags()
     }
    if(criteriaSearch) {
      return  dispatch({
        type: "SET_FILTER_CRITERIA",
        filter: criteriaSearch?.filter,
        isFilter: true,
        typeAction: 'EDIT'
      });
    } else {
      return null
    }
   
  }


  const onSubmit = (data) => {

    let newData = {}
    if(props?.data?.rangeFrom !== Number(data?.rangeFrom) || props?.data?.rangeTo !== Number(data?.rangeTo)){
      newData = { 
        description: data?.description,
        rangeFrom: data?.rangeFrom,
        rangeTo: data?.rangeTo,
        name: data?.name,
        status: data?.status
      }
    }else{
      newData = { 
        description: data?.description,
        name: data?.name,
        status: data?.status
      }
    }
   const update = async() => {
    setDisabledSubmit(true)
     const result = await updateCriteria(props?.data?.uuid, newData)
     if (result && (result?.status === 200 || result?.status === 204)) {
      props.setMessage({
        open : true,
        title: '¡Proceso exitoso!',
        description: 'Información almacenada correctamente ',
        handleClick : () => handleCloseModalAndReset() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'SUCCESS',
      })
     }
     else if (result?.status === 400) {
      props.setMessage({
        open : true,
        description: result?.data?.message ? "Existe un escala de valoración que incluye el mismo rango": 'Error al guardar la información',
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }else if (result?.status > 399) {
      props.setMessage({
        open : true,
        description: result?.data?.message ? "Existe un escala de valoración que incluye el mismo rango": 'Error al guardar la información',
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
     } else {
      props.setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
     }
   } 
  update()
 }


  const handleOnchange = (e) => {
    const {name, value} = e.target;
    setValuesForm({...valuesForm, [name]: value})
    setValue(name, value)
    trigger(name)

  }

  const closeModalEdit = () => {
    clearErrors(["code", "description", "status", "name", 'rangeFrom', 'rangeTo'])
    setValue('rangeFrom', '')
    setValue('code', '')
    setValue('rangeTo', '')
    setValue('description', '')
    setValue('status', '')
    setValue('name', '')
    setValuesForm({...valuesForm, status: ''})
    props?.closeEdit()
  }

  useEffect(() => {
    if( watch('rangeTo') !== '' && Number(watch('rangeTo')) <= Number(watch('rangeFrom')) ){
      setValue('rangeTo', watch('rangeTo'))
      trigger('rangeTo')
    }if(watch('rangeTo') !== '') {
      setValue('rangeTo', watch('rangeTo'))
      trigger('rangeTo')
    }
  }, [watch('rangeFrom') ])


  
  useEffect(() => {
    if( Number(watch('rangeTo')) > 100 ){
      setValue('rangeTo', watch('rangeTo'))
      trigger('rangeTo')
    }
  }, [watch('rangeTo')])

  const valuesForTotal = () => {
    if(watch('rangeTo') <= Number(valuesForm?.rangeFrom) ){
      return "Ingrese un valor mayor"
    }
    if(watch('rangeTo')  > 100 ){
      return "Ingrese un valor menor a 100"
    }
  }
 
  return (
    <div>
      <Dialog
        className={classes.widthModal}
        open={props?.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
        <Typography id="alert-dialog-title" style={{fontSize: 24, fontWeight: 400, marginBottom: '2%'}}>
          Editar escala de valoración
        </Typography>
          <DialogContentText id="alert-dialog-description">
              {/* <Container maxWidth="lg"> */}
                <form id="create" onSubmit={handleSubmit(onSubmit)}>
                <Grid container  alignItems="flex-start" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: errors[`name`] ? 'flex' : null}}>
              {errors[`name`] ? <ErrorOutlineIcon color="error"  style={{visibility: errors[`name`] ? "initial": "hidden"}} fontSize="small" className={classes.iconError}/> : null}
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('name')}
                    className={classes.dropdown}
                    fullWidth
                    type="text"
                    onChange={handleOnchange}
                    InputLabelProps={{
                        shrink: watch('name')? true : false,
                        notched: watch('name')? true : false,
                    }}
                    multiline
                    maxRows={20}
                    size="small"
                    label="Escala de valoración "
                    error={!!errors.hasOwnProperty("name") && errors["name"].message}
                />
                 <FormHelperText style={{color:'#d32f2f'}}>
                    {errors.hasOwnProperty("name") &&
                      errors["name"].message}
                  </FormHelperText> 
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <FormControl variant='outlined' fullWidth size="small">
                  <TextField
                  {...register('code')}
                  className={classes.dropdown}
                  fullWidth
                  disabled
                  InputLabelProps={{
                      shrink: watch('code') ? true : false,
                      notched: watch('code') ? true : false,
                  }}
                  size="small"
                  label="Código"
                  />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2.5} style={{display: errors[`rangeFrom`] ? 'flex' : null}}>
              {errors[`rangeFrom`] ? <ErrorOutlineIcon color="error"  style={{visibility: errors[`rangeFrom`] ? "initial": "hidden"}} fontSize="small" className={classes.errorIconPercentage}/> : null}
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('rangeFrom')}
                    className={classes.dropdown}
                    fullWidth
                    type="text"
                    onChange={handleOnchange}
                    // value={valuesForm?.rangeFrom}
                    InputLabelProps={{
                        shrink: watch('rangeFrom') || watch('rangeFrom') === 0? true : false,
                        notched: watch('rangeFrom') || watch('rangeFrom') === 0? true : false,
                    }}
                    size="small"
                    label="Desde"
                    error={!!errors.hasOwnProperty("rangeFrom") && errors["rangeFrom"].message}
                />
                 <FormHelperText style={{color:'#d32f2f'}}>
                    {errors.hasOwnProperty("rangeFrom") &&
                      errors["rangeFrom"].message}
                  </FormHelperText> 
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2.5} style={{display: errors[`rangeTo`] ? 'flex' : null}}>
              {errors[`rangeTo`] ? <ErrorOutlineIcon color="error"  style={{visibility: errors[`rangeTo`] ? "initial": "hidden"}} fontSize="small" className={classes.errorIconPercentage}/> : null}
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('rangeTo')}
                    className={classes.dropdown}
                    fullWidth
                    onChange={handleOnchange}
                    type="text"
                    InputLabelProps={{
                      shrink: watch('rangeTo') ? true : false,
                      notched: watch('rangeTo') ? true : false,
                  }}
                    size="small"
                    label="Hasta"
                    error={!!errors.hasOwnProperty("rangeTo")}
                />
                 <FormHelperText style={{color:'#d32f2f'}}>
                    {errors.hasOwnProperty("rangeTo") &&
                     valuesForTotal()}
                  </FormHelperText> 
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2.5} style={{display: errors[`status`] ? 'flex' : null}}>
             {errors[`status`] ? <ErrorOutlineIcon color="error"  style={{visibility: errors[`status`] ? "initial": "hidden"}} fontSize="small" className={classes.iconErrorSelect}/> : null}
              <FormControl variant='outlined' fullWidth size="small">
                <InputLabel error={!!errors.hasOwnProperty("status") && errors["status"].message}>Estado</InputLabel>
                    <Select
                    {...register('status')}
                    name='status'
                    onChange={handleOnchange}
                    shrink={true}
                    value={valuesForm.status}
                    className={classes.dropdown}
                    label="Estado "
                    error={!!errors.hasOwnProperty("status") && errors["status"].message}
                >
                    <MenuItem value='ACTIVE' key={1}>Activo</MenuItem>
                    <MenuItem value='INACTIVE' key={2}>Inactivo</MenuItem>
                </Select>
                  <FormHelperText style={{color:'#d32f2f'}}>
                    {errors.hasOwnProperty("status") &&
                      errors["status"].message}
                  </FormHelperText> 
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <div style={{display: 'flex'}}>
            { errors[`description`] ? 
                <ErrorOutlineIcon 
                  color="error" 
                  style={{visibility: errors[`description`] ? "initial": "hidden"}} 
                  fontSize="small" 
                  className={classes.iconErroDescription}
                /> : null 
            }
                <FormControl variant='outlined' fullWidth size="small">
                  <>
                    <TextField
                    {...register('description')}
                    className={classes.dropdownMultiple}
                    fullWidth
                    type="text"
                    InputLabelProps={{
                        shrink: watch('description')? true : false,
                        notched: watch('description')? true : false,
                    }}
                    multiline
                    maxRows={12}
                    // minRows={7}
                    rows={7}
                    size="small"
                    label="Descripción"
                    error={!!errors.hasOwnProperty("description") && errors["description"].message}
                />
                 <div className={classes.accountant}>{`${watch('description')?.length ? watch('description')?.length : 0}/1000`}</div>
                 </>
                </FormControl>
                </div>
                <FormHelperText style={{color:'#d32f2f', marginLeft: '2%'}}>
                    {errors.hasOwnProperty("description") &&
                        errors["description"].message}
                </FormHelperText> 
            </Grid>
          </Grid>
                </form>
            {/* </Container> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{marginRight: '1%'}}>
            <Button variant="outlined"  
                    disableElevation 
                  onClick={()=>closeModalEdit()} 
                    style={{color: "#6D9FFF", marginBottom: 5, borderColor: '#80808070'}}
                >
                CANCELAR
                </Button>
                &nbsp;&nbsp;
                <Button
                    type="submit"
                    form='create'
                    disabled={disabledSubmit}
                    variant="contained"
                    style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 5 }}
                >
                    GUARDAR
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
