import React, {useState, useEffect} from 'react';
import {Grid,
    FormControl,
    InputAdornment,
    OutlinedInput,
    InputLabel,
    IconButton,
    Typography
} from "@mui/material";
import {styles} from "./styles"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import DeleteIcon from '@mui/icons-material/Delete';
import * as yup from "yup";

export const QuestionQuestionary = ({ mapQuestionQuestionary, dataQuestion}) => {
    const classes = styles();
    const [valuesOptions, setValuesOptions ] = useState([
      { name: 'valueOption1', value: 1},
      { name: 'valueOption2', value: 2},
  ])


  useEffect(() => {
    if(mapQuestionQuestionary?.answerAlternativeCode === "RAN" ){
      const newData = [];
      for (let index = 0; index < mapQuestionQuestionary?.questionAlternativeList.length; index++) {
        
        newData.push( { name: `valueOption${index +1}`, value: index +1})
   
        for (let index = 0; index < newData.length; index++) {
         setValue(`valueOption${index +1}`, mapQuestionQuestionary?.questionAlternativeList[index]?.key)
        }
      }
     setValuesOptions(newData)
    }
    if(dataQuestion?.answerAlternativeCode === 'RAN'){
      const newData = [];
      const history = []
      for (let index = 0; index < dataQuestion?.questionAlternativeList.length; index++) {
        
        newData.push( { name: `valueOption${index +1}`, value: index +1, uuid:dataQuestion?.questionAlternativeList[index]?.uuid, operationType: null})
        history.push( { name: `valueOption${index +1}`, value: index +1, uuid:dataQuestion?.questionAlternativeList[index]?.uuid, operationType: null})

   
        for (let index = 0; index < newData.length; index++) {
         setValue(`valueOption${index +1}`, dataQuestion?.questionAlternativeList[index]?.key)
        }
      }
      setValuesOptions(newData)
    }
   }, [])


    const schema = yup.object().shape({
     
      valueTotalQuestion: yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,2}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
    })


    const { register, handleSubmit, control, trigger, unregister, clearErrors, formState:{errors}, setValue, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });

    
    const boxForValue = (x) => {
      return <Grid item xs={12} md={12} lg={12} className={classes.boxNumber}>
          <Typography style={{marginTop: '10%'}}> {x}</Typography>
        </Grid>
 
    }
        
    return <Grid item container spacing={2}  direction="column">
    {valuesOptions?.map((x, index) => {
     return   <Grid item container spacing={2} alignItems="center" justifyContent="center" direction="row"  style={{display:'flex'}}>
     <Grid item md={1} lg={0.7} sm={1}>{boxForValue(index+1)}</Grid>
     <Grid item lg={11.3} md={11} sm={11}>
      <FormControl fullWidth size="small" >
          <InputLabel htmlFor="outlined-adornment-password" >
              {`Opción ${index +1}`} 
          </InputLabel>
          <OutlinedInput
              disabled
              {...register(`valueOption${x.value}`)}
              className={classes.dropdownMultiple}
              
              endAdornment={
              <InputAdornment position="end">
              <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  disabled
                  >
                  {<DeleteIcon />}
              </IconButton>
              </InputAdornment>
              }
              label={`Opción ${x.value}`}
          />
        </FormControl>
     </Grid>
     </Grid>
    //  <br/>

    })}
    </Grid>
};

QuestionQuestionary.propTypes = {
  mapQuestionQuestionary: PropTypes.object,
  dataQuestion: PropTypes.object
}

QuestionQuestionary.defaultProps = {
  mapQuestionQuestionary: {},
  dataQuestion: {}
}