/**
 * Component to render icons from the Material Icons repository
 * https://material.io/resources/icons/?icon=dashboard&style=baseline
 * @param fill 'outlined' for Oulined icons or '' filled icons 
 */

import React from "react";

const MaterialIcon = (props) => {

    const fillClass = props.fill ? `material-icons-${props.fill}` : 'material-icons';

    return (
        <React.Fragment>
            <span className={fillClass}>{props.name}</span>
        </React.Fragment>
    );
};

export default MaterialIcon;