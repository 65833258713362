import React from 'react'
import { TableRow, TableCell, TableContainer, Table, TableHead, TableBody, Paper  } from '@mui/material';
import { styles } from "./styles";
import { typesEvaluators } from '../../../../../../constants';

const Index = (
        {   index, dataStudents, evaluationPeer, findSelecteType, openModalEdit, handleOnchange, 
            currentSubalterns, sectionId, setSectionId, setIdSelect, idSelect, saveUser, setCurrentSubalterns,
            setIdEdit, openModal, deleteUser
        }) => {
    const classes = styles();
  

  return (
        <TableRow>
            <TableCell colSpan={5}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead >
                        <TableRow>
                            <TableCell colSpan={5} style={{textAlign: 'initial'}}>Evaluadores Estudiantiles</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableHead style={{background: 'rgb(223, 231, 253)'}}>
                            <TableRow>
                                <TableCell width={`20%`} size='small' >Nombre</TableCell>
                                <TableCell width={`20%`} size='small'>Cargo</TableCell>
                                <TableCell width={`20%`} size='small'>Programa</TableCell>
                                <TableCell width={`20%`} size='small'>Asignatura</TableCell>
                                <TableCell width={`20%`} size='small'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { findSelecteType('SELF_EVALUATION') && 
                                dataStudents?.map((s, pos=index) => (
                                    <TableRow>
                                    <React.Fragment key={pos}>
                                        <TableCell width={`30%`} size='medium' className={classes.subHeader}>
                                        {`${s?.name ? s?.name : ''} ${s?.surname ? s?.surname : '' }` }
                                        </TableCell>
                                        <TableCell  width={`20%`} size='medium' className={classes.subHeader}>
                                        {typesEvaluators[s?.typeEvaluator]  ?? ''}
                                        </TableCell>
                                        <TableCell  width={`25%`} size='medium' className={classes.subHeader}>
                                        {s?.regional?.name}
                                        </TableCell>
                                        <TableCell width={`20%`} size='medium' className={classes.subHeader}>
                                        {s?.position?.name ?? '--/--/--/--/--/' }    
                                        </TableCell>
                                        <TableCell width={`5%`} size='medium' className={classes.subHeader}>
                                        
                                        </TableCell>
                                    </React.Fragment>
                        </TableRow>
                                ))
                            
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableCell>
        </TableRow>
  )
}

export default Index