import React, {useState, useEffect} from 'react'
import { FormControl, Grid, TextField, Typography, FormHelperText, FormControlLabel,Checkbox, FormGroup } from '@mui/material';
import { styles } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStateValue } from '../../../context/store';
import * as yup from "yup";
import _ from "lodash";
import { PATH_APP } from '../../../constants';

const Index = (props) => {

    const {id, status} = props?.props?.computedMatch?.params;
    const idEvaluation = id
    const classes = styles();
    const [{typesEvaluations}, dispatch] = useStateValue();
    const [valueYup, setValueYup] = useState({});
    const [checked, setChecked] = useState({});
    const [disabledInput, setDisabledInput] = useState(false);

    const schema = yup.object().shape(valueYup);
    const typeslocal = localStorage.getItem('typesEvaluation')
    const jsonTypes =  JSON.parse(typeslocal)
  

    useEffect(()=> {
       if(jsonTypes?.applicationTypes?.length){
            for (let index = 0; index < jsonTypes?.applicationTypes?.length; index++) {
                
                setValue(jsonTypes?.applicationTypes[index]?.type, jsonTypes?.applicationTypes[index]?.percentage)
               
            }   
            setDisabledInput(jsonTypes?.automatic)
       }else if(typesEvaluations?.applicationTypes?.length){
        for (let index = 0; index < typesEvaluations?.applicationTypes?.length; index++) {
            
            setValue(typesEvaluations?.applicationTypes[index]?.type, typesEvaluations?.applicationTypes[index]?.percentage)

        }
        setDisabledInput(jsonTypes?.automatic)
       }
    },[])

   
    
      const { register, handleSubmit, setValue, trigger, unregister, watch, clearErrors, formState:{errors} } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });



      useEffect(() => {
    
        let newValues = {}
        if(props?.typeDelete){
            unregister(props?.typeDelete);
            clearErrors(props?.typeDelete)
            setValue(props?.typeDelete, '')

        }
        for (let index = 0; index < typesEvaluations?.applicationTypes?.length; index++) {
            newValues = Object.assign(newValues,{
                [typesEvaluations?.applicationTypes?.[index]?.type]: yup.string().required('obligatorio').matches(  
                    /^[0-9]{1,3}(\.[0-9]{1,2})?$/g,
                   "Ingresa un valor de 1 - 100 ").test("maxLenght", "Ingrese un valor entre 1 y 100", val => val <= 100 && val >= 1)
            })
            
        }

        if(checked?.name){
            for (let index = 0; index < typesEvaluations?.applicationTypes?.length; index++) {
                setValue(typesEvaluations?.applicationTypes[index]?.type, '')
                clearErrors(typesEvaluations?.applicationTypes[index]?.type)
            }
            setChecked({})
            setDisabledInput(true);
        }
        setValueYup(newValues);
        
    }, [ typesEvaluations?.applicationTypes])


      const validateLabel = (name) => {
        if(name === 'SELF_EVALUATION'){
            return 'Autoevaluación :'
        }
        else if(name === 'PEER_EVALUATION'){
            return 'Evaluación estudiantes :'
        }
        else if(name === 'LEADER_EVALUATION'){
            return 'Evaluación institucional :'
        }
        else if(name === 'CLIENT_EVALUATION'){
            return 'Evaluación cliente :'
        }
      }    

      const handleChangeValor = (e) => {
        const {name} = e.target

        if(name === 'Automatic') {

            const newPercentages = [];

            setDisabledInput(true)
            for (let index = 0; index < typesEvaluations?.applicationTypes?.length; index++) {
                if(typesEvaluations?.applicationTypes[index]?.employees?.length){
                    newPercentages.push(
                        {
                            type: typesEvaluations?.applicationTypes[index]?.type,
                            percentage: (100/typesEvaluations?.applicationTypes?.length).toFixed(2),
                            position: typesEvaluations?.applicationTypes[index]?.position,
                            employees: typesEvaluations?.applicationTypes[index]?.employees
                        })
                }else{
                    newPercentages.push(
                        {
                            type: typesEvaluations?.applicationTypes[index]?.type,
                            percentage: (100/typesEvaluations?.applicationTypes?.length).toFixed(2),
                            position: typesEvaluations?.applicationTypes[index]?.position,
                        })
                }
                
                    setValue(typesEvaluations?.applicationTypes[index]?.type, (100/typesEvaluations?.applicationTypes?.length).toFixed(2))
                    trigger(typesEvaluations?.applicationTypes[index]?.type)
            }

            dispatch({
                type: "SET_TYPES_EVALUATIONS",
                automatic: true,
                applicationTypes: newPercentages,
                submitPercentage: false,
                selectAll: typesEvaluations?.selectAll,
                users: typesEvaluations?.users,
                dataEvaluation: typesEvaluations?.dataEvaluation,
                pages: typesEvaluations?.pages,
                selectNewLeaders: typesEvaluations?.selectNewLeaders
              });

            //   const dataLocalStorage = {
            //     automatic: true,
            //     applicationTypes: newPercentages,
            //     submitPercentage: typesEvaluations?.submitPercentage,
            //     selectAll: typesEvaluations?.selectAll,
            //     users: typesEvaluations?.users,
            //     dataEvaluation: typesEvaluations?.dataEvaluation,
            //     pages: typesEvaluations?.pages,
            //     selectNewLeaders: typesEvaluations?.selectNewLeaders
            //   }
            //   localStorage.setItem('typesEvaluation', JSON.stringify(dataLocalStorage))
        }else {
            setDisabledInput(false)

            const newPercentages = [];
            for (let index = 0; index < jsonTypes?.applicationTypes.length; index++) {
                if(jsonTypes?.applicationTypes[index]?.employees?.length){
                    newPercentages.push(
                        {
                            type: jsonTypes?.applicationTypes[index]?.type,
                            percentage: '',
                            position: jsonTypes?.applicationTypes[index]?.position,
                            employees: jsonTypes?.applicationTypes[index]?.employees
                        })
                }else{
                    newPercentages.push(
                        {
                            type: jsonTypes?.applicationTypes[index]?.type,
                            percentage: '',
                            position: jsonTypes?.applicationTypes[index]?.position
                        })
                }
                    setValue(jsonTypes?.applicationTypes[index]?.type, '')

            }
            dispatch({
                type: "SET_TYPES_EVALUATIONS",
                automatic: false,
                applicationTypes: newPercentages,
                submitPercentage: false,
                selectAll: jsonTypes?.selectAll,
                users: jsonTypes?.users,
                dataEvaluation: jsonTypes?.dataEvaluation,
                pages: jsonTypes?.pages,
                selectNewLeaders: jsonTypes?.selectNewLeaders
              });
            
        }
    }

    useEffect(() => {

        if(props?.typeDelete){

            const filterType = typesEvaluations?.applicationTypes?.filter((x)=> x?.type !== props?.typeDelete)
            for (let index = 0; index < filterType?.length; index++) {
        
                setValue(typesEvaluations?.applicationTypes[index]?.type, typesEvaluations?.applicationTypes[index]?.percentage)
    
            }
        }else {

            for (let index = 0; index < typesEvaluations?.applicationTypes?.length; index++) {
                setValue(typesEvaluations?.applicationTypes[index]?.type, typesEvaluations?.applicationTypes[index]?.percentage)
            }
        }
        
    }, [typesEvaluations?.automatic])
    
    const blurPercentage = (e) => {
        const {name, value} = e.target;
        const findType = typesEvaluations?.applicationTypes?.find((x)=> x?.type === name)
        const filterDifferenst = typesEvaluations?.applicationTypes?.filter((x)=> x?.type !== name)
        
        const newPercentage = {...findType, percentage: value}
        filterDifferenst.push(newPercentage)

        dispatch({
            type: "SET_TYPES_EVALUATIONS",
            automatic: typesEvaluations?.automatic,
            applicationTypes: filterDifferenst,
            selectAll: typesEvaluations?.selectAll,
            submitPercentage: typesEvaluations?.submitPercentage,
            users: typesEvaluations?.users,
            dataEvaluation: typesEvaluations?.dataEvaluation,
            pages: typesEvaluations?.pages,
            selectNewLeaders: typesEvaluations?.selectNewLeaders
          });
    }


    useEffect(() => {
        if( errors['SELF_EVALUATION'] || 
            errors['PEER_EVALUATION'] || 
            errors['LEADER_EVALUATION'] ||
            errors['CLIENT_EVALUATION'] ||
            typesEvaluations?.automatic === null
            ){
                dispatch({
                    type: "SET_TYPES_EVALUATIONS",
                    automatic: typesEvaluations?.automatic,
                    applicationTypes: typesEvaluations?.applicationTypes,
                    submitPercentage: true,
                    selectAll: typesEvaluations?.selectAll,
                    users: typesEvaluations?.users,
                    dataEvaluation: typesEvaluations?.dataEvaluation,
                    pages: typesEvaluations?.pages,
                    selectNewLeaders: typesEvaluations?.selectNewLeaders
                  });
            }else {
                dispatch({
                    type: "SET_TYPES_EVALUATIONS",
                    automatic: typesEvaluations?.automatic,
                    applicationTypes: typesEvaluations?.applicationTypes,
                    submitPercentage: false,
                    selectAll: typesEvaluations?.selectAll,
                    users: typesEvaluations?.users,
                    dataEvaluation: typesEvaluations?.dataEvaluation,
                    pages: typesEvaluations?.pages,
                    selectNewLeaders: typesEvaluations?.selectNewLeaders
                  });
            }
       
    }, [errors['SELF_EVALUATION'] || errors['PEER_EVALUATION'] || errors['LEADER_EVALUATION'] ||errors['CLIENT_EVALUATION']  ])
    

    const closeModal = () => props?.setMessage({open: false})

    const submit = () => {
        let total = 0
        for (let index = 0; index < typesEvaluations?.applicationTypes?.length; index++) {
          
          if(typesEvaluations?.applicationTypes[index]?.percentage){
            total += Number(typesEvaluations?.applicationTypes[index]?.percentage);
          }
          
        }
        if(Math.round(total) !== 100){
         return props?.setMessage({
            open : true,
            description: 'La suma de los porcentajes no es igual a 100',
            handleClick : () => closeModal() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
        }else {
    
            const dataLocalStorage = {
                automatic: typesEvaluations?.automatic,
                applicationTypes: typesEvaluations?.applicationTypes,
                submitPercentage: typesEvaluations?.submitPercentage,
                selectAll: typesEvaluations?.selectAll,
                users: typesEvaluations?.users,
                dataEvaluation: typesEvaluations?.dataEvaluation,
                pages: typesEvaluations?.pages,
                selectNewLeaders: typesEvaluations?.selectNewLeaders
              }
              localStorage.setItem('typesEvaluation', JSON.stringify(dataLocalStorage))
          return props?.history.push(`${PATH_APP}/evaluation-performance/evaluator/aplication/${idEvaluation}/type-evaluation/evaluateds`)
        }
        
      }


if(typesEvaluations?.applicationTypes?.length ){
  return (
    <Grid lg={12} md={12} style={{marginTop: '4%'}}>
        <Typography style={{color:'#4B4B4B', fontSize: 14}}>Seleccione la asignación de porcentaje</Typography>
        <Grid lg={12} style={{display: 'flex', alignItems: 'center', }}>
            <Typography style={{fontSize: 12, fontWeight: 700, margin: '3% 2% 3% 1%'}}>valorar :</Typography>
            <FormGroup >
                <FormControlLabel
                    style={{width: 'max-content', fontSize: 14, color: '#8E8E8E'}}
                    control={
                    <Checkbox
                        disabled={status === 'APPLIED'}
                        checked={typesEvaluations?.automatic === false} 
                        onChange={handleChangeValor} 
                        name="Manual" 
                    />
                    }
                    label="Manual "
                />
            </FormGroup>
                <FormGroup >
                <FormControlLabel
                    style={{width: 'max-content', color: '#8E8E8E'}}
                    control={
                    <Checkbox 
                        disabled={status === 'APPLIED'}
                        checked={typesEvaluations?.automatic === true} 
                        onChange={handleChangeValor} 
                        name="Automatic" 
                    />
                    }
                    label="Automático"
                />
                </FormGroup>
        </Grid>
        <Grid item lg={12} style={{marginBottom: '2%'}}>
          <Typography style={{color:'#4B4B4B', fontSize: 14}}>Agregue el porcentaje a cada tipo de evaluación (la suma de los porcentajes debe ser igual a 100) </Typography>
        </Grid>
        <form id="Evaluation" onSubmit={handleSubmit(submit)}>
        {_.orderBy(jsonTypes?.applicationTypes, ['position'], ['asc'])?.map((x)=> (
        <Grid style={{display: 'flex', alignItems: 'center', marginBottom: '1%'}}>
            <Grid item lg={3}>
                <Typography>{validateLabel(x?.type)}</Typography>
            </Grid>
            <Grid item lg={1.5}>
                <FormControl variant='outlined' fullWidth>
                    <TextField
                        {...register(x?.type)}
                        fullWidth
                        size='small'
                        name={x?.type}
                        onBlur={(e)=>blurPercentage(e)}
                        disabled={disabledInput}
                        InputLabelProps={
                            {
                                shrink: watch(x?.type) ? true : false,
                                notched: watch(x?.type) ? true : false
                            }
                        }
                        className={classes.dropdown}
                        placeholder='valor'
                        label="valor"
                        type="text"
                        error={!!errors.hasOwnProperty(x?.type) && errors[x?.type].message}
                    />
                </FormControl>
            </Grid>
            <Grid item lg={3}>
            <FormHelperText style={{color:'red', marginLeft: '3%'}}>
                    {errors.hasOwnProperty(x?.type) &&
                        errors[x?.type].message}
                </FormHelperText> 
            </Grid>
        </Grid>
        ))}
        </form>
    </Grid>
   
  )}else {
    return null;
  }
}

export default Index