import _ from "lodash";

export const VALUES = {
  MENU: {
    DRAWER_WIDTH: '20%'
  }
}

export const typeContract = [

  {key: 'CONTRATO_POR_OBRA_LABOR', value: "Contrato por obra labor" },
  {key: 'CONTRATO_TERMINO_INDEFINIDO', value: "Contrato término Indefinido"},
  {key: 'CONTRATO_PRESTACION_DE_SERVICIO', value: "Contrato Prestación de servicio "},
  {key: 'CONTRATO_TERMINO_FIJO', value: "Contrato término fijo"},
  {key: 'CONTRATO_DE_APRENDIZAJE', value: "Contrato de aprendizaje"}
]

 export const Logo = {
  logo:"http://cuidadoseguro.com.co/principal/wp-content/uploads/2020/07/LOGO_CSC_HORINZONTAL.png"
 
}

const TRANSLATE = {
  LABELS: {
    ASSIGNED_ROLES: 'Roles Asignados'
  },
  BUTTONS: {
    ROLES: 'Roles',
    APPLY: 'APLICAR',
    CANCEL: 'CANCELAR'
  },
  API_MESSAGES: {
    ROLES: {
      GET_ERROR: 'Ocurrió un error procesando la solicitud, inténtelo nuevamente',
      POST_SUCCESS: 'Se guardaron los roles exitosamente',
      POST_ERROR: 'Ocurrió un error procesando la solicitud, inténtelo nuevamente'
    }
  },
  FORM: {
    NO_MORE_THAN_N_CHARACTERS: 'No se permite más de %n% caracteres',
    ENTER_VALID_NAME_MESSAGE: 'Ingrese un nombre válido',
    MANDATORY_SUBDOMAIN_MESSAGE: 'Este campo es obligatorio',
    VALID_DESCRIPTION_MESSAGE: 'Ingrese una descripción válida',
    SUBDOMAIN_SUCCESSFULLY_SAVED: 'Se guardó el subdominio exitosamente'
  },
  FOOTER: {
    COPY_RIGHT_MSG: '© Copyright 2020 Todos los derechos reservados AUTOEVALUACIONES ',
    DATA_PROTECTION_POLICY_MSG: 'Política de protección de datos personales - Conozca nuestra política para garantizar la seguridad de sus datos personales.'
  }
};

export default TRANSLATE;

export const pushDataRelations =  (dataDetail, dispatch) => {

  if(dataDetail?.competences?.length){

      const competences = [];
      const copyData = [...dataDetail?.competences]
      for (let index = 0; index < copyData?.length; index++) {
          competences.push(
              {
                  uuid : copyData[index]?.competence?.uuid, 
                  position: index+1,
                  domainLevel: copyData[index]?.domainLevel,
                  domainLevelList: copyData[index]?.competence?.domainLevelList,
                  name: copyData[index]?.competence?.name,
                  behaviors: _.orderBy(copyData[index]?.conducts, ['position'],["asc"])?.map((x, index)=>(
                  {uuid: x?.uuid, 
                      name: x?.name,
                      position: index +1,
                      questions: _.orderBy(x?.questions, ["position"],["asc"])?.map((f, index)=> (
                      {
                          questionAlternativeList: f?.questionAlternativeList,
                          answerAlternativeCode: f?.answerAlternativeCode,
                          value: f?.question,
                          position: index+1,
                          uuid: f?.uuid,
                          rols: f?.evaluators?.map((r) => r?.uuid)
                      }
                      ))
                  }))
              })
      }
          
      let dataCompetences = [];
      for (let index = 0; index < competences?.length; index++) {
              dataCompetences.push(
                  {
                      domainLevel : competences[index]?.domainLevel,
                      name : competences[index]?.name,
                      position : competences[index]?.position,
                      uuid : competences[index]?.uuid,
                      behaviors : competences[index]?.behaviors,
                      domainLevelList: competences[index]?.domainLevelList,
                      into: false,
                      section: competences[index]?.section?.uuid
                  }
              )
          
          
      }
      
      dispatch({
          type: 'SET_RELATIONS_EVALUATION',
          data: dataCompetences,
          dataSections : []
      })
  }
};

export const validWithRol = (data, rol) => {
  return data?.map(value => {
    const behaviors = value?.behaviors?.map(beha => {
      const questions = beha?.questions
        ?.filter(quest => quest.rols?.includes(rol))
        .map(quest => ({ ...quest, question: quest?.value }))
        .filter(Boolean); // Elimina valores nulos o indefinidos

      return questions?.length ? { ...beha, questions } : null;
    }).filter(Boolean);

    return behaviors?.length ? { competence: value, conducts: behaviors, position: value?.position?.toString() } : null;
  }).filter(Boolean);
};

export const showMessageWithOutAction = (dispatch, message, path) => {
  return  dispatch({
      type: "SET_MODAL_ACTION",
      open: true,
      icon:'WARNING',
      message: message ?? 'Ingrese un mensaje para mostrar',
      redirect: path
    })
};  


