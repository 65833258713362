import React,{useState, useEffect} from 'react'
import { FormControl, Grid,  Checkbox,
    FormGroup,FormControlLabel, TextField,  FormHelperText, Typography, Paper } from '@mui/material'
import { PATH_APP, REQUIRED_MESSAGE } from '../../../../constants';
import ModalConfirm from "../../../../utils/form/modalConfirmBase";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {styles} from "./styles";


import * as yup from 'yup'
import moment  from "moment"
import { useStateValue } from './../../../../context/store';
import { useHistory } from "react-router-dom";
import { getTypesEvaluators } from './../../../../actions/Performance_Evaluation-action';
import { titleHeader } from './../../../../constants';
import CustomizedButtonMenu from "../../../createEvaluationPerformance/relationShip/relations/components/button-menu";
import ModalReviewRol from "../../../createEvaluationPerformance/relationShip/relations/components/modal-review-rol";
import { pushDataRelations, showMessageWithOutAction, validWithRol } from '../../../../utils/constants';

const Index = (props) => {
    const classes = styles();
    const history = useHistory();
       
    const [{ relations }, dispatch] = useStateValue();
    const [, setChecked] = useState({})
    const [valuesForm, setValuesForm ] = useState({
        status: 'ACTIVE',
        startDate: '',
        endDate: ''
    })
    const [currentDay, ] = useState()
    const [dataTypes, setDataTypes] = useState([]);
    const [typesEvaluators, setTypesEvaluators] = useState([]);
    const [typesEvaluation, setTypesEvaluation] = useState([]);
    const [messageModalReviewRol, setMessageModalReviewRol] = useState({
        description: '',
        textButtonSubmit: '',
        currentData: null,
        oneButtons: () => { },
        handleClick: () => { },
        handleClickOut: () => { },
        title: '',
        open: false
      })
    const [message, setMessage] = useState({
        description : '',
        textButtonSubmit : '',
        handleClick : () => {} ,
        handleClickOut : () => {},
        oneButtons : false,
        title: '',
        type : '',
        open : false
      });

    

    const schema = yup.object().shape({
        name: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .min(10, 'Ingrese mínimo 10 caracteres')
        .max(500, 'Ingrese máximo 500 caracteres'),
        user: yup
        .string()
        .required(REQUIRED_MESSAGE),
        description: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .min(10, 'Ingrese mínimo 10 caracteres')
        .max(1000, 'Ingrese máximo 1000 caracteres'),
        startDate: yup
        .date()
        .nullable(true),
        created: yup
        .date()
        .nullable(true),
        endDate: yup
        .date()
        .required('Este campo es obligatorio')
        .nullable(true),
        startDateAplied: yup
        .date()
        .min(moment(new Date()).format('YYYY-MM-DD'),'Ingrese mínimo la fecha actual' )
        .required('Este campo es obligatorio')
        .typeError('Este campo es obligatorio'),
        endDateAplied: yup
        .date()
        .required('Este campo es obligatorio')
        .typeError('Este campo es obligatorio')
        .test("maxLenght", "Ingrese una superior o igual a la fecha desde", val =>  currentDay <= moment(val).format('YYYY-MM-DD')   ),
    })
    const {register, setValue, clearErrors, watch, formState:{errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange'
    })

    const backToFormInitial = () =>  history.push(`${PATH_APP}/management-aplication-evaluations`);
    const closeModalReviewRol = () => setMessageModalReviewRol({ ...messageModalReviewRol, currentData: null, open: false })


    const getTypes = async() => {
        const result = await getTypesEvaluators(dispatch);
        if(result?.data?.length) {
            setTypesEvaluation(result?.data?.map((x)=> ({...x, id: x?.uuid })))
            setTypesEvaluators(result?.data?.map((x)=> ({id: x?.uuid, value: titleHeader(x?.abbreviation)})))
        }else {
            return setMessage({
                open : true,
                description: 'Se presento un error en el servicio de tipos de evaluadores',
                handleClick : () => backToFormInitial() ,
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                type : 'WARNING',
            })
        }
        
      }

    const handleDataFromButtonMenu = (idRol) => {

        let roleName = typesEvaluation?.find(item => item.id === idRol)?.name;
        const dataByRol = idRol ? {competences: validWithRol(relations?.data, idRol), roleName} : [];
        if (!dataByRol?.competences?.length) {
            showMessageWithOutAction(dispatch, null, "No se han relacionado preguntas para el rol");
        } else {
          setMessageModalReviewRol({
            ...messageModalReviewRol,
            currentData: {performanceEvaluation: dataByRol},
            open: true,
            oneButtons: true,
            handleClick: closeModalReviewRol,
          });
        }
      };

      useEffect(() => {
          getTypes();
          pushDataRelations(props?.dataDetail?.performanceEvaluation, dispatch)
      },[]);

    useEffect(() => {
        if(props?.dataDetail?.uuid){
            window.scrollTo(0,0)
            setValue('name', props?.dataDetail?.performanceEvaluation?.name)
            setValue('user', `${props?.dataDetail?.creatorUser?.name} ${props?.dataDetail?.creatorUser?.surname}`)
            setValue('description', props?.dataDetail?.performanceEvaluation?.description)
            setValue('endDateAplied',moment(props?.dataDetail?.endDate).format('YYYY-MM-DD'));
            setValue('created',moment(props?.dataDetail?.createdAt).format('YYYY-MM-DD'))
            setValue('startDateAplied',moment(props?.dataDetail?.startDate).format('YYYY-MM-DD'))
            setValue('endDate',moment(props?.dataDetail?.performanceEvaluation?.endDate).format('YYYY-MM-DD'))
            setValue('startDate',moment(props?.dataDetail?.performanceEvaluation?.startDate).format('YYYY-MM-DD'))

            setValuesForm(
                {
                    ...valuesForm, 
                    startDate: moment(props?.dataDetail?.performanceEvaluation?.startDate).format('YYYY-MM-DD'),
                    endDate: moment(props?.dataDetail?.performanceEvaluation?.endDate).format('YYYY-MM-DD')
                }
                )
                setChecked({name: props?.dataDetail?.automatic === true ? 'Automatic' : 'Manual'})
        }
        
        setDataTypes(props?.dataDetail?.applicationTypes)

    }, [props?.dataDetail])



  
    const handleChange = (e)=> {
        const {value, name} = e.target
        setValue(name, value)
        setValuesForm({...valuesForm, [name]:value})
        
    }


     const validateErros = () => {
        if(errors['startDateAplied'] ){
            return 20
        } else if(errors['endDateAplied'] && errors['endDateAplied'].message === 'Este campo es obligatorio' ){
            return 20
        }else if (errors['endDateAplied']){
            return 40
        }  else {
            return null
        }
     }

     useEffect(()=> {
        if(watch('endDateAplied') && errors['endDateAplied']){
            setValue('endDateAplied', '')
            clearErrors('endDateAplied')
        }
       
     },[watch('startDateAplied')])

    
    const validateLabel = (name) => {
        if(name === 'SELF_EVALUATION'){
            return 'Autoevaluación '
        }
        else if(name === 'PEER_EVALUATION'){
            return 'Evaluación estudiantes '
        }
        else if(name === 'LEADER_EVALUATION'){
            return 'Evaluación institucional '
        }
        else if(name === 'CLIENT_EVALUATION'){
            return 'Evaluación cliente '
        }
      }    



  return (
        <div>
            <Paper elevation={3} style={{background: '#FAFAFA', borderRadius: 20, padding: 10, marginBottom: '3%'}}>
                <Grid container spacing={1} maxWidth="inherit" alignItems="end" 
                        style={{ background: props?.isEdit ? 'rgb(239 239 239)': null}}
                    >
                    
                    <Grid item container spacing={1} lg={12} md={12} style={{display:'flex ', alignItems: 'end', marginBottom: errors && validateErros()}}>
                        <Grid item lg={2} md={2} fullWidth style={{ marginBottom: 5 }}>
                            <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('created')}
                                    size="small"
                                    type="date"
                                    label="fecha de creación"
                                    disabled
                                    onChange={handleChange}
                                    className={classes.fieldDateTimeErrorSearch}
                                    notched="true"
                                    InputLabelProps={{ shrink: true }}
                                    error={!!errors.hasOwnProperty("startDate") && errors["startDate"].message }
                                />
                            </FormControl>
                        </Grid> 
                        <Grid item lg={5} md={5}>
                            <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Periodo de evaluación</Typography>
                            <div style={{display:'flex ', flexDirection: 'row' }}>    
                                <Grid item xs={12} sm={12} md={12} lg={6} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                    <FormControl fullWidth size="small">
                                    <TextField 
                                        {...register('startDate')}
                                        size="small"
                                        type="date"
                                        label="Desde"
                                        disabled
                                        onChange={handleChange}
                                        // value={breadCrumb?.module === "APLICATION_CUESTIONARY" ? questionarySearch?.filter?.createdAtStartDate : null}
                                        className={classes.fieldDateTimeErrorSearch}
                                        minDate={new Date()}
                                        InputLabelProps={{
                                            shrink: true,
                                            notched: true
                                        }}
                                        error={!!errors.hasOwnProperty("startDate") && errors["startDate"].message }
                                    />
                                    <FormHelperText style={{color:'#d70909'}}>
                                        {!!errors.hasOwnProperty("startDate") &&
                                        errors["startDate"].message}
                                    </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} style={{display: 'flex', marginLeft: 5}}>
                                <FormControl fullWidth size="small">
                                    <TextField 
                                        {...register('endDate')}
                                        size="small"
                                        type="date"
                                        label="Hasta"
                                        disabled
                                        onChange={handleChange}
                                        className={classes.fieldDateTimeErrorSearch}
                                        minDate={new Date()}
                                        InputLabelProps={{
                                            shrink: true,
                                            notched: true
                                        }}
                                        error={!!errors.hasOwnProperty("endDate") && errors["endDate"].message }
                                    />
                                    <FormHelperText style={{color:'#d70909'}}>
                                        {!!errors.hasOwnProperty("endDate") &&
                                        errors["endDate"].message}
                                    </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item container spacing={1} lg={5} md={5} style={{display:'flex ', flexDirection: 'column', paddingLeft: '2%' }}>
                            <div >
                                <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Periodo de aplicación</Typography>
                                <div style={{display:'flex ', flexDirection: 'row' }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                        <FormControl fullWidth size="small">
                                        <TextField 
                                            {...register('startDateAplied')}
                                            size="small"
                                            type="date"
                                            label="Desde"
                                            disabled
                                            inputProps={{min: moment(new Date()).format('YYYY-MM-DD')}}
                                            className={classes.fieldDateTimeErrorSearch}
                                            InputLabelProps={{
                                                shrink: true,
                                                notched: true
                                            }}
                                            error={!!errors.hasOwnProperty("startDateAplied") && errors["startDateAplied"].message }
                                        />
                                        <FormHelperText style={{color:'#d70909'}}>
                                            {!!errors.hasOwnProperty("startDateAplied") &&
                                            errors["startDateAplied"].message}
                                        </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                                    <FormControl fullWidth size="small">
                                        <TextField 
                                            {...register('endDateAplied')}
                                            size="small"
                                            type="date"
                                            label="Hasta"
                                            disabled
                                            className={classes.fieldDateTimeErrorSearch}
                                            inputProps={{min: moment(new Date()).format('YYYY-MM-DD')}}
                                            minDate={new Date()}
                                            min={new Date()}
                                            InputLabelProps={{
                                                shrink: true,
                                                notched: true
                                            }}
                                            error={!!errors.hasOwnProperty("endDateAplied") && errors["endDateAplied"].message }
                                        />
                                        <FormHelperText style={{color:'#d70909'}}>
                                            {!!errors.hasOwnProperty("endDateAplied") &&
                                            errors["endDateAplied"].message}
                                        </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid> 
                    <Grid item lg={12} md={12} fullWidth style={{marginBottom: errors && validateErros() }}>
                            <FormControl fullWidth variant='outlined' size='small'>
                                <TextField 
                                    {...register('user')}
                                    fullWidth
                                    size='small'
                                    disabled
                                    label="Usuario creador"
                                    placeholder='Usuario creador'
                                    multiline
                                    InputLabelProps={{ shrink: true }}
                                    notched="true"
                                    margin='none'
                                    maxRows={15}
                                    className={classes.dropdownMultiple}
                                    error={!!errors.hasOwnProperty("user") && errors["user"].message}
                                />
                            <FormHelperText style={{color:'#d70909'}}>
                                {!!errors.hasOwnProperty("user") &&
                                errors["user"].message}
                            </FormHelperText>
                            </FormControl>
                    </Grid> 
                    <Grid item lg={12} md={12} fullWidth>
                        <FormControl fullWidth variant='outlined' size='small'>
                            <TextField 
                                {...register('name')}
                                fullWidth
                                size='small'
                                label="Nombre de la evaluación"
                                placeholder='Nombre de la evaluación'
                                multiline
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                    notched: true
                                }}
                                margin='none'
                                maxRows={15}
                                className={classes.dropdownMultiple}
                                error={!!errors.hasOwnProperty("name") && errors["name"].message}
                            />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("name") &&
                            errors["name"].message}
                        </FormHelperText>
                        </FormControl>
                        </Grid>
                            <Grid item lg={12} md={12} fullWidth style={{marginBottom: props?.isEdit ?'4%': null}}>
                            <FormControl fullWidth variant='outlined' size='small'>
                                <TextField 
                                    {...register('description')}
                                    fullWidth
                                    size='small'
                                    multiline
                                    minRows={2}
                                    maxRows={20}
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                        notched: true
                                    }}
                                    label="Descripción"
                                    placeholder='Descripción'
                                    className={classes.dropdownMultiple}
                                    error={!!errors.hasOwnProperty("description") && errors["description"].message}
                                />
                            <FormHelperText style={{color:'#d70909'}}>
                                {!!errors.hasOwnProperty("description") &&
                                errors["description"].message}
                            </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item container lg={12}>
                        <FormGroup style={{display: 'flex', flexDirection: 'row'}}>
                            {
                                dataTypes?.map((x)=> (
                                    <Grid item container lg={12/dataTypes?.length} alignItems="center"  style={{padding: '0px 10px'}}>
                                        <Grid item  style={{display: 'flex', alignItems: 'center'}}>
                                            <FormControlLabel
                                                className={classes.labelChecked}
                                                control={
                                                    <Checkbox 
                                                        checked={true} 
                                                    />
                                                }
                                                label={validateLabel(x?.type)}
                                            />
                                        <Grid item >
                                            <Typography style={{fontSize: 14, color: '#2C2C2C', fontWeight: 900, paddingRight: 20}}>Porcentaje</Typography>
                                        </Grid>
                                        <Grid item lg={2}>
                                            <FormControl fullWidth variant='outlined' size='small'>
                                                <TextField 
                                                    fullWidth
                                                    size='small'
                                                    value={x?.percentage}
                                                    disabled
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        notched: true
                                                    }}
                                                    margin='none'
                                                    className={classes.dropdownValues}
                                                />
                                            </FormControl>
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        
                            </FormGroup>
                            
                        </Grid>
                </Grid>
            </Paper>
            {/* <Grid item container lg={12} md={12} justifyContent="flex-start" style={{margin: '5% 0%'}}>
                <CustomizedButtonMenu onDataSend={handleDataFromButtonMenu} typesEvaluators={typesEvaluation}/>
            </Grid> */}
            <ModalReviewRol
                handleClick={messageModalReviewRol.handleClick}
                handleClickOut={messageModalReviewRol.handleClickOut}
                oneButtons={messageModalReviewRol.oneButtons}
                open={messageModalReviewRol.open}
                currentData= {messageModalReviewRol.currentData}
            />
            <ModalConfirm 
                description = { message.description }
                textButtonSubmit = { message.textButtonSubmit }
                textButtonCancel = {message.textButtonCancel}
                handleClick = { message.handleClick }
                handleClickOut = { message.handleClickOut }
                list = {message.list}
                oneButtons = { message.oneButtons }
                title = { message.title }
                type = { message.type }
                open = { message.open }
            />

        </div>
        )
}

export default Index