import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  Button,
} from "@mui/material";
import { useStateValue } from "../../../context/store";
import { styles } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SearchCompetencies = (props) => {
  const classes = styles();
  const [{ competencieSearch, breadCrumb }, dispatch] = useStateValue();
  const [valuesForm, setValuesForm] = useState({
    status: "",
    competencie: "",
    code: "",
  });

  const schema = yup.object().shape({
    code: valuesForm?.code
      ? yup
          .string()
          .max(6, "Ingrese máximo 6 caracteres")
          .matches(/^([0-9]{0,6})$/, "Código no válido")
      : null,
    competencie: valuesForm?.competencie
      ? yup
          .string()
          .notRequired()
          .nullable(true)
          .max(100, "Ingrese máximo 100 caracteres")
      : null,
    status: null,
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    unregister,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const refreshFilter = () => {
    props?.getAllCompetencies();
    refreshForm();
  };

  const onSubmit = (data) => {
    if (
      data?.competencie !== "" ||
      data?.code !== "" ||
      valuesForm.status !== "" ||
      props?.statusCompetences
    ) {
      dispatch({
        type: "SET_FILTER_COMPETENCIE",
        filter: {
          competencie: data?.competencie,
          code: valuesForm?.code,
          status: valuesForm.status,
          page: 0,
          size: competencieSearch?.filter?.size
            ? competencieSearch?.filter?.size
            : 10,
        },
        isFilter: true,
      });
    } else {
      dispatch({
        type: "SET_FILTER_COMPETENCIE",
        filter: {
          competencie: null,
          status: null,
          code: null,
        },
        isFilter: true,
      });
    }
  };

  const refreshForm = () => {
    setValue("competencie", "");
    setValue("code", "");
    unregister(["code", "competencie", "status"]);
    setValue("status", "");
    setValuesForm({ ...valuesForm, status: "", competencie: "", code: "" });
  };
  const clear = () => {
    refreshForm();
    dispatch({
      type: "SET_FILTER_COMPETENCIE",
      filter: {
        competencie: null,
        status: null,
        code: null,
        page: 0,
        size: 10,
      },
      isFilter: false,
    });
  };

  useEffect(() => {
    clearForm();
  }, [props?.clearFielForm]);

  useEffect(() => {
    if (props?.modalGetAll) {
      return dispatch({
        type: "SET_FILTER_COMPETENCIE",
        filter: {
          competencie: null,
          code: valuesForm?.code,
          status: "ACTIVE",
          page: 0,
          size: competencieSearch?.filter?.size
            ? competencieSearch?.filter?.size
            : 10,
        },
        isFilter: true,
      });
    }
  }, [props?.modalGetAll]);

  const clearForm = () => {
    clear();
    props.dataFilter();
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setValuesForm({ ...valuesForm, [name]: value });
    setValue(name, value);
    trigger(name);
  };

  useEffect(() => {
    if (
      breadCrumb?.module === "MANAGE_COMPETENCIES" &&
      competencieSearch?.isFilter
    ) {
      setValue("competencie", competencieSearch?.filter?.competencie);
      setValue("status", competencieSearch?.filter?.status);
      setValue("code", competencieSearch?.filter?.code);
      setValuesForm({
        ...valuesForm,
        status: competencieSearch?.filter?.status,
        code: competencieSearch?.filter?.code,
        competencie: competencieSearch?.filter?.competencie,
      });
      setValue("status", competencieSearch?.filter?.status);
    }
  }, [breadCrumb?.module === "MANAGE_COMPETENCIES"]);

  const validate =
    valuesForm.status || watch("code") || valuesForm?.competencie;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 20, display: "flex", alignItems: "flex-start" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <FormControl variant="outlined" fullWidth size="small">
            <TextField
              {...register("competencie")}
              className={classes.dropdown}
              fullWidth
              type="text"
              onChange={handleOnchange}
              notched={valuesForm?.competencie ? "true" : "false"}
              InputLabelProps={{
                shrink: valuesForm?.competencie ? true : false,
              }}
              multiline
              maxRows={4}
              size="small"
              label="Criterio"
              error={
                !!errors.hasOwnProperty("competencie") &&
                errors["competencie"].message
              }
            />
          </FormControl>
          <FormHelperText style={{ color: "red", marginLeft: "3%" }}>
            {errors.hasOwnProperty("competencie") &&
              errors["competencie"].message}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={props?.modalGetAll ? 3 : 2}>
          <FormControl variant="outlined" fullWidth size="small">
            <TextField
              {...register("code")}
              name="code"
              className={classes.dropdown}
              fullWidth
              onChange={handleOnchange}
              InputLabelProps={{ shrink: valuesForm?.code ? true : false }}
              notched={valuesForm?.code ? "true" : "false"}
              size="small"
              label="Código"
              inputProps={{autocomplete: 'off'}}
              error={!!errors.hasOwnProperty("code") && errors["code"].message}
            />
          </FormControl>
          <FormHelperText style={{ color: "red", marginLeft: "4%" }}>
            {errors.hasOwnProperty("code") && errors["code"].message}
          </FormHelperText>
        </Grid>
        {!props?.modalGetAll && (
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel
                error={
                  !!errors.hasOwnProperty("status") && errors["status"].message
                }
              >
                Estado
              </InputLabel>
              <Select
                {...register("status")}
                name="status"
                value={valuesForm.status}
                onChange={handleOnchange}
                status="Estado"
                className={classes.dropdown}
                label="Estado "
                error={
                  !!errors.hasOwnProperty("status") && errors["status"].message
                }
              >
                <MenuItem value="ACTIVE" key={1}>
                  Activo
                </MenuItem>
                <MenuItem value="INACTIVE" key={2}>
                  Inactivo
                </MenuItem>
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {errors.hasOwnProperty("status") && errors["status"].message}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={props?.modalGetAll ? 3 : 2}
          className={classes.buttons}
        >
          <Button
            variant="contained"
            type="submit"
            disableElevation
            disabled={!validate}
            color="primary"
          >
            BUSCAR
          </Button>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <Button
            variant="outlined"
            onClick={clearForm}
            disableElevation
            style={{ background: "#ffffff" }}
          >
            Limpiar
          </Button>
          &nbsp;&nbsp; &nbsp;&nbsp;
          {props?.modalGetAll && (
            <Button
              variant="contained"
              onClick={() => refreshFilter()}
              disableElevation
              color="primary"
            >
              TODAS
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchCompetencies;
