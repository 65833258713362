export const initialState = {
    filter: {
      code: null,
      status: null,
      question: null,
      page: null,
      size: null
    },
    isFilter: false,
    typeAction: null
  };
  
  const searchBehaviorReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_FILTER_BEHAVIOR":
        return {
          ...state,
          filter: action.filter,
          isFilter: action.isFilter,
          typeAction: action.typeAction,
        };
      default:
        return state;
    }
  };
  
  export default searchBehaviorReducer;
  