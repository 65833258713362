import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
    table: {
        display: 'flex', 
        justifyContent: 'center', 
        color: '#8E8E8E',
        '& MuiList-root MuiList-padding MuiMenu-list css-6hp17o-MuiList-root-MuiMenu-list' :{
            display: 'flex',
        },
        '& .MuiTablePagination-actions': {
            flex: '0 0 auto',
          },
          '& .MuiTablePagination-selectRoot': {
            marginLeft: 'auto',
          },
        '& .MuiSelect-select':{
            color: 'gray',
            fontSize: 14
        },
        '& .MuiNativeSelect-select':{
            color: 'gray',
            fontSize: 14
        },
        '& .MuiIconButton-colorInherit': {
            padding: '0px !important'
        },
        '& .Mui-disabled': {
            padding: '0px !important'
        }
    }
  
})


