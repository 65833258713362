import React, {useState, useEffect} from "react";
import { makeStyles, withStyles } from "@mui/styles";
import { useStateValue } from "../../../../context/store";
import { Scrollbars } from "react-custom-scrollbars";
import { Button, Modal, Paper, Tooltip, Typography, Switch } from "@mui/material";
import { useHistory, useParams} from "react-router-dom";
import {enableEvaluator} from "../../../../actions/ManagementAplicationEvaluations-actions"
import { PATH_APP } from "../../../../constants";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: 'green',
        borderColor: 'green',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.white}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.error.main,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 1100,
    backgroundColor: '#F5F5F5',
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.24)",
    outline: "none",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
  },
  cancel1: { 
     color: "#2F71CB", 
     marginBottom: 5, 
     borderColor: '#80808070',
     "&:hover": {
      borderColor: '#80808070',
    },
  },
  cancel: { 
    border: '1px solid #ada7a8',
    background: 'transparent',
    "&:hover": {
      border: '1px solid #ada7a8',
      background: 'transparent',
   },
 },
  text: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#4B4B4B",
    fontWeight: 400,
    textAlign: "center",
    margin: "25px 20px",
  },
  titleAlert: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#2C2C2C",
  },
  widthIcon: {
    fontSize: 70,
    color: '#2F71CB',
    marginTop: '3%'

  },
  confirmBanck :{ 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5,
    // width: 'fit-content' ,
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
  }
}));

const ModalEvaluators = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const [{ evaluatedsSearch }, dispatch] = useStateValue();
  const [, setChangeState] = useState(true);
  const [evaluators, setEvaluators] = useState([]);
  const [modalStyle] = React.useState(getModalStyle);


const closeModal = () => props?.setMessage({...props?.message, open:false})
const successAction = () => {
    props?.setMessage({...props?.message, open:false}); 
    dispatch({
      type: "EVALUATEDS_APPLICATION_SEARCH",
      filter: {...evaluatedsSearch?.filter},
      isFilter: true,
    })
  }

  const cancelAction = () =>  props?.setMessage({...props?.message, open:false});

const toggleChecked = async(x) =>{
  const evaluatorsEnables = evaluators?.filter((x)=> x?.type === 'LEADER_EVALUATION' && !x?.disabled)
  if(x?.disabled){
    return null
  }else {
    if(evaluatorsEnables?.length <=1){
      return props?.setMessage({
        open : true,
        description: 'No es posible inactivar el evaluador líder, se requiere al menos un evaluador líder para la evaluación',
        handleClick : () => closeModal() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })
    }else {
      return props?.setMessage({
        open : true,
        description: `¿Desea inactivar el evaluador líder?`,
        handleClick : () => aceptAction(x) ,
        handleClickOut: () => cancelAction(),
        textButtonSubmit: 'ACEPTAR',
        textButtonCancel: 'CANCELAR',
        buttonsTwo: true,
        type : 'WARNING',
      })
   
    }
  }
  
 
}


  const aceptAction = async(x)=> {
    const result = await enableEvaluator(params?.applicationId, props?.idEvaluated, x?.evaluator?.uuid, {disabled:!x?.disabled}, dispatch)
    if(result?.status >= 200 && result?.status <= 204){
      setChangeState(true)
      return successAction() 
    }
    if(result?.status === 404){
      setChangeState(false)
      return  props?.setMessage({
        open : true,
        description: 'La aplicación de modelo de evaluación no existe',
        handleClick : () => closeModal() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })
    }
    else {
      setChangeState(false)
      return  props?.setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => closeModal() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })
    }
  }

const validateLabel = (name) => {
  if(name === 'SELF_EVALUATION'){
      return 'Autoevaluación'
  }
  else if(name === 'PEER_EVALUATION'){
      return 'Evaluación estudiantes'
  }
  else if(name === 'LEADER_EVALUATION'){
      return 'Evaluación institucional'
  }
  else if(name === 'CLIENT_EVALUATION'){
      return 'Evaluación cliente'
  }
}    

const validateColorText = (x)=> {
  if(x?.disabled && x?.type === 'LEADER_EVALUATION'){
    return 'red'
  }else {
    return '#8E8E8E'
  }
}

  useEffect(() => {
    setEvaluators(props?.evaluators)
  }, [props])
  


  const bodyModal = (
    <div style={modalStyle} className={classes.paper}>
      <Typography variant="h5" style={{marginBottom: '1.5%'}}>Evaluadores</Typography>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '0.5%'}}>
          <Typography style={{fontSize: 10, color: '#8E8E8E', paddingLeft: '4%', textAlign: 'start', flexBasis: '17%'}}>Nombre</Typography>
          <Typography style={{fontSize: 10, color: '#8E8E8E', flexBasis: '35%'}}>Programa</Typography>
          <Typography style={{fontSize: 10, color: '#8E8E8E', paddingLeft: '3%', textAlign: 'start', flexBasis: '21%'}}>Asignatura</Typography>
          <Typography style={{fontSize: 10, color: '#8E8E8E', textAlign: 'start', flexBasis: '30%'}}>Tipo de evaluación</Typography>
          <div></div>
        </div>
      <Paper elevation={3} style={{marginBottom: '3%'}}>
        
        <table style={{width: '100%'}}>
          <Scrollbars style={{ width: "auto", height: "270px", backgroundColor: "#ffffff", marginTop: '1%' }}>
          {evaluators?.map((x)=> (
              <tr style={{background: '#ffffff', height: 50}}>
                <td style={{width: '27%', wordBreak: 'break-all'}}>
                  <Typography style={{fontSize: 14, color: validateColorText(x) , textAlign: 'start', paddingLeft: 20}}>{`${x?.evaluatorName ? x?.evaluatorName : ''} ${x?.evaluatorSurname ? x?.evaluatorSurname : ''}`}</Typography>
                </td>
                <td style={{width: '18%', wordBreak: 'break-all'}}>
                  <Typography style={{fontSize: 14, color: validateColorText(x) }}>{x?.programName ?? ''}</Typography>
                </td>
                <td style={{width: '30%', wordBreak: 'break-all'}}>
                  <Typography style={{fontSize: 14, color: validateColorText(x), wordBreak: 'break-all'}}>{x?.positionName ?? 'N/A'}</Typography>
                </td>
                <td style={{width: '20%', wordBreak: 'break-all'}}>
                  <Typography style={{fontSize: 14, color: validateColorText(x), wordBreak: 'break-all', textAlign: 'initial'}}>{validateLabel(x?.type)}</Typography>
                </td>
                <td style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                {/* {x?.type === 'LEADER_EVALUATION' && <div style={{marginRight:'10px'}}>
                    <Tooltip title={x?.disabled ? "Inactivo" : "Inhabilitar"} arrow>
                      <AntSwitch
                        style={{ fontSize: 40, pointerEvents: 'auto'}}
                        checked={ !x?.disabled}
                        name={x?.uuid}
                        onClick={(event) => { event.stopPropagation(); toggleChecked(x) }}
                        onFocus={(event) => event.stopPropagation()}
                      />
                    </Tooltip>
                  </div>
                } */}
                  <div>
                    {x?.status === "ANSWERED" && x?.status !== 'PENDING_TO_ANSWER' ? 
                      // <Button 
                      //   variant="contained" 
                      //   color="primary"
                      //   disabled={x?.status === 'PENDING_TO_ANSWER'}
                      //   onClick={()=> history.push(`${PATH_APP}/management-aplication-evaluateds/${params?.applicationId}/${x?.evaluator?.uuid}/${props?.idEvaluated}/${x?.type}`)}
                      // >
                      //   RESULTADO HOLa
                      // </Button>
                      <span
                      style={{
                        display: 'inline-block',
                        padding: '6px 16px',
                        fontWeight: '700',
                        color: 'rgb(25, 118, 210)',
                        cursor: 'default', // Cambiar el cursor al comportamiento predeterminado
                      }}
                    >
                      RESUELTO
                    </span>
                      :
                      // <Tooltip title="No ha sido resuelta" arrow>
                      //   <Button 
                      //     variant="contained"
                      //     disableElevation 
                      //     style={{background: 'rgba(0, 0, 0, 0.12)', color: 'rgba(0, 0, 0, 0.26)'}}
                      //   >
                      //     RESULTADO NO
                      //   </Button>
                      // </Tooltip>
                    <Tooltip title="No ha sido resuelta" arrow>
                      <span
                        style={{
                          display: 'inline-block',
                          padding: '6px 16px',
                          fontWeight: '700',
                          color: 'rgba(0, 0, 0, 0.26)',
                          cursor: 'default', // Cambiar el cursor al comportamiento predeterminado
                        }}
                      >
                        PENDIENTE
                      </span>
                    </Tooltip>
                    }
                  </div>
                  
                </td>
              </tr>
          ))}
          </Scrollbars>
          
        </table>
      </Paper>
      
      <div style={{ display: "flex", justifyContent:  "space-evenly",}}>
      <Button
        className={ classes.cancel }
        variant="outlined"
        onClick={() =>props?.handleClick()}
      >
        VOLVER
      </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={props?.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
};

export default ModalEvaluators;
