import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField,  
     InputLabel, 
     FormControl, 
     FormHelperText, 
     MenuItem,
     Button,
     Typography
} from '@mui/material'
import {styles} from './styles';
import { useStateValue } from "../../../context/store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SearchLabel = (props) => {

    const classes = styles()
    const [{answerQuestionary, breadCrumb}, dispatch] = useStateValue();
    const [dataForm, setDataForm] = useState({
        label: '',
        keyword: '',
        status: ''
    })
    
    const schema = yup.object().shape({
        keyword: dataForm?.keyword ? yup
        .string()
        .notRequired()
        .nullable(true)
        .max(100, 'Ingrese máximo 100 caracteres') : null,
        from: yup
        .string()
        .notRequired()
        .nullable(true),
        until: yup
        .string()
        .notRequired()
        .nullable(true), 
        status: yup
        .string()
        .notRequired()
        .nullable(true)
      
      });
    
      
      const { register, handleSubmit, clearErrors, trigger, formState:{errors}, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });

      const handleClose = () => props.setMessage({...props.message, open: false})

      const onSubmit = (data) => {
    
          if(data.from === '' && data.keyword === '' && data.status === '' && data?.until === ''){
            return  props?.setMessage({
                message: 'Ingrese información en algúno de los campos',
                open: true,
                severity: 'error',
                handleClose: () => handleClose()
              })
          }
          if(watch('until') && watch('from') && (data.until < data?.from )){
            return  props?.setMessage({
                open : true,
                description: 'La fecha final no puede ser menor que la fecha inicial',
                handleClick : () => handleClose() ,
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                type : 'WARNING',
              })
          }
          if(
            data?.from !== '' ||
            data?.until !== '' ||
            data?.keyword !== '' ||
            data?.status !== '' 
        ){
            dispatch({
                type: "FILTER_QUESTIONARY_ANSWER",
                filter: {
                    effectiveStartDate : data?.from,
                    effectiveEndDate: data?.until,
                    nameKeyword: data?.keyword,
                    status: data?.status,
                    page: 0,
                    size: 10
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "FILTER_QUESTIONARY_ANSWER",
                filter: {
                    effectiveStartDate : null,
                    effectiveEndDate: null,
                    nameKeyword: null,
                    status: null,
                  },
                  isFilter: true,
            })
        }
      }

      useEffect(() => {
        clear()
      }, [props?.clearFielForm])


      const handleChange = (e) => {
        const {value, name} = e.target;
        setDataForm({...dataForm, [name]:value})
        if(name === 'keyword'){
            setValue(name, value)
            trigger(name)
        }
      }

    const clear = () => {
        setValue('from','')
        setValue('until', '')
        setValue('keyword', '')
        setDataForm({...dataForm, status: ''})
        setValue('status', '')
        clearErrors('keyword')
        props.clearFilter()
    }


    useEffect(()=> {
        if(breadCrumb?.module === "ANSWER_QUESTIONARY"){
            setValue('from',answerQuestionary?.filter?.effectiveStartDate )
            setValue('until', answerQuestionary?.filter?.effectiveEndDate)
            setValue('keyword', answerQuestionary?.filter?.nameKeyword)
            setDataForm({...dataForm, status: answerQuestionary?.filter?.status})
            setValue('status', answerQuestionary?.filter?.status)
        }
    },[breadCrumb?.module === "ANSWER_QUESTIONARY"])


      const validate = watch('from') || watch('keyword') || dataForm?.status || watch('until') 
      const disabledButton = errors['keyword'] || !validate

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container spacing={2} style={{alignItems: 'flex-end', marginTop: '-4%'}}>
                <Grid item spacing={1} lg={4} style={{display:'flex ', flexDirection: 'column' }}>
                    <div >
                        <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Fecha de vigencia</Typography>
                        <div style={{display:'flex ', flexDirection: 'row' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('from')}
                                    size="small"
                                    type="date"
                                    label="Desde"
                                    value={breadCrumb?.module === "APLICATION_CUESTIONARY" ? answerQuestionary?.filter?.effectiveStartDate  : null}
                                    className={classes.fieldDateTimeErrorSearch}
                                    minDate={new Date()}
                                    InputLabelProps={{
                                        shrink: true,
                                        notched: true
                                    }}
                                    error={!!errors.hasOwnProperty("from") && errors["from"].message }
                                />
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("from") &&
                                    errors["from"].message}
                                </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                            <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('until')}
                                    size="small"
                                    type="date"
                                    label="Hasta"
                                    value={breadCrumb?.module === "APLICATION_CUESTIONARY" ? answerQuestionary?.filter?.effectiveEndDate : null}
                                    className={classes.fieldDateTimeErrorSearch}
                                    minDate={new Date()}
                                    InputLabelProps={{
                                        shrink: true,
                                        notched: true
                                    }}
                                    error={!!errors.hasOwnProperty("until") && errors["until"].message }
                                />
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("until") &&
                                    errors["until"].message}
                                </FormHelperText>
                                </FormControl>
                            </Grid>
                        </div>
                </div>
               
                </Grid>
            
                <Grid item xs={12} sm={12} md={12} lg={2.4}>
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('keyword')}
                    className={classes.dropdownNameCuestionary}
                    fullWidth
                    onChange={handleChange}
                    size="small"
                    placeholder="Palabra clave"
                    // InputLabelProps={{
                    //     className: classes.labelProps
                    // }}
                    error={!!errors.hasOwnProperty("keyword") && errors["keyword"].message}
                    helperText={!!errors.hasOwnProperty("keyword") && errors["keyword"].message}
                  />
                </FormControl>
                <FormHelperText style={{color:'#d70909'}}>
                    {!!errors.hasOwnProperty("from") &&
                    errors["from"].message}
                </FormHelperText>
                </Grid>
                <Grid item md={3} lg={1.6} style={{display: 'flex', alignItems: 'center'}}>
                    <FormControl fullWidth size="small">
                    <InputLabel  error={!!errors.hasOwnProperty("status") && errors["status"].message}></InputLabel>
                        <TextField
                        select
                        size="small"
                        {...register('status')}
                        onChange={handleChange}
                        value={dataForm.status}
                        label="Estado"
                        InputLabelProps={{
                            className: classes.labelProps
                        }}
                        error={!!errors.hasOwnProperty("status") && errors["status"].message}
                        className={classes.dropdown}
                        // style={{background: watch("status") && !errors["status"] ? '#E1E1E1' : '#ffffff'}}
                        >
                            {props?.listStatus?.map((x) => (
                                <MenuItem value={x?.type} key={x?.type}>{x?.description}</MenuItem>
                            ))}  
                           
                        </TextField>
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("status") &&
                            errors["status"].message}
                        </FormHelperText>
                    </FormControl>
                    
                    </Grid>
               
                <Grid item xs={12} sm={12} md={12} lg={3.8} style={{display: 'flex',  alignItems: 'center', marginLeft: '1%', marginBottom: '0.5%'}}>
                    <Button 
                        variant="contained" 
                        type="submit" 
                        disabled={disabledButton}
                        disableElevation 
                        style={{color: "#ffffff",background: disabledButton ? '#C0C0C0' : "#2F71CB" }}
                    >
                        BUSCAR
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button 
                        variant="contained"  
                        disableElevation 
                        onClick={clear}
                        style={{color: "#ffffff",background:  "#2F71CB", width: '65%'}}
                    >
                        LIMPIAR
                    </Button>

                </Grid>
            </Grid>
         </form>
    )
}

export default SearchLabel
