import React from 'react';
import { 
  Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {styles} from '../styles';
import DialogTitle from '@mui/material/DialogTitle';
import { useStateValue } from "../../context/store";
import CreateQuestionary from "./createQuestionary"
import { PATH_APP } from '../../constants';

export default function EditQuestionary(props) {
    const classes = styles();
    const [{ modalClose }, dispatch] = useStateValue();
 

  const handleCloseModalCreate = () => {
    props.setOpenModal()
    dispatch({
      type: "SET_MODAL",
      openEdit:false
    });
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar cuestionario",
      index: 4,
      path: `${PATH_APP}/questionary-create`,
      textIsSubmit: "CREAR CUESTIONARIO",
    });
  }

  return (
    <div >
      <Dialog
       className={classes.widthModalEditQuestionary}
        open={modalClose?.openEdit}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleEditQuestionary}>
         EDITAR CUESTIONARIO
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           < CreateQuestionary edit={true} dataDetail={props?.dataEdit}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display:'flex', justifyContent:'center'}}>
        <Button variant="outlined"  
          disableElevation 
          onClick={handleCloseModalCreate} 
          style={{color: "#6D9FFF", marginBottom: 5, borderColor: '#80808070'}}
        >
            VOLVER
        </Button>
          &nbsp;&nbsp;
        <Button
          type="submit"
          variant="contained"
          // disabled={breadCrumb?.disabledSubmit}
          form="Newary"
          style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 5 }}
        >
            GUARDAR
        </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
