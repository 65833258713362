import React,{useState, useEffect} from "react";
import {Typography, Grid,  TableBody, TableRow, TableCell, TableContainer, Table } from "@mui/material";
import { useParams} from "react-router-dom";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import 'moment/locale/es';

import GraphicPercentage from "../graphicBarPercentage";
import Logo from "../../../../../../images/Logo_autoevaluciones.png";
import { useStateValue } from '../../../../../../context/store';
import Loading from "../../../../../../utils/form/loading";
import { getResultEvaluationEvaluated } from "../../../../../../actions/ManagementAplicationEvaluations-actions";
import {styles} from "./styles";
import TableResultGroup from '../tableResultGroup';
import CriteriaTable from "../criteriaTableCopy";
import DivColor from "../divColor";
import QuestionsAnswers from "../questionsAnswers";

const ModalConfirmBase = (props) => {
  const classes = styles();
  const params = useParams();
  const {applicationId, evaluated} = params;
  const [{dataToPage }, dispatch] = useStateValue();
  const [showButton, setShowButton] = useState(false);
  const [observations, setObservations]= useState();
  const [, setIdGraphics] = useState([])
  const [, setDisabledButton] = useState(false);
  const [numberRefresh, setNumberRefresh] = useState(0);
  const [dataGraphic, setDataGraphic] = useState([]);
  const [divideGraphic, setDivideGraphic] = useState([])
  const [constants, setConstants] = useState([]);
  const [usedGraphics, setUsedGraphics] = useState();
  const [graphicsUser, setGraphicsUser] = useState()
  const [copyHtmlCompete, setCopyHtmlCompete] = useState([]);
  const [htmlCompete, setHtmlCompete] = useState([]);
  const [askAndAnswer, setAskAndAnswer] = useState([]);
  const [leftoverSpace, setLeftoverSpace] = useState();
  const [htmlTableGroup, setHtmlTableGroup] = useState([]);
  const [dataField, setDataField] = useState({
    observations: ''
  })
  const [namePdf, setNamePdf] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    textButtonCancel: '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    buttonsTwo: false,
    title: '',
    type : '',
    open : false
    })
 const [criteriaRegister, setCriteriaRegister] = useState([]);
 const [newDataCriteria, setNewDataCriteria] = useState([]);
 const [resultGroup, setResultGroup] = useState([]);



 const valorCell = 55.972225189208984;
 const valHeader = 105.52083587646484;
//  const heightOfPage =  1672.7431411743164;
 const heightOfPage =  1700;
 const valHeaderCriteria = 62.500003814697266;
 const valPie = 167.91667556762695

  useEffect(() => {
    // getDataPdf()
    window.scrollTo(0,0); 
}, []);



  

  useEffect(() => {
    setNumberRefresh(numberRefresh+1)
    setObservations();
    setIdGraphics([]);
    setDataGraphic([]);
    setDivideGraphic([]);
    setConstants([]);
    setUsedGraphics();
    setGraphicsUser();
    setCopyHtmlCompete([]);
    setHtmlCompete([]);
    if(dataToPage?.data?.notes?.length){
        setDataField({...dataField, observations: dataToPage?.data?.notes?.length ? dataToPage?.data?.notes : ''})
    }

    tableResultGroup();
            
    const lastTable = htmlTableGroup?.[htmlTableGroup?.length-1]?.props?.id
    setLeftoverSpace( document.getElementById(lastTable)?.getBoundingClientRect().height)
   
  }, [dataToPage])
  
  

  const handleClickForm = (e) => {
    e.preventDefault()

    if(showButton){
        return  setMessage({
            open : true,
            description: 'Guarde los cambios realizados en el campo de observaciones para continuar con el proceso',
            handleClick : () => aceptActions() ,
            // handleClickOut: () => cancelAction(),
            oneButtons: true,
            // twoButtons : true,
            // textButtonCancel: 'CANCELAR',
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
    }
    setShowButton(false)
    setDisabledButton(true)

    dowloadPdf()
    setLoading(true)
  }

  const dowloadPdf = async() => {
    const elementoHTML = document.getElementById('pagec');
    const doc = new jsPDF('p', 'mm', 'a4');


 
  const generarPaginaPDF = async (elemento) => {
    const canvas = await html2canvas(elemento,{
        scale: 2, // Ajusta la escala para obtener una mayor resolución
        logging: true, // Habilita el registro de información en la consola (opcional)
        dpi: 150 // Ajusta el valor DPI para obtener una mayor calidad de imagen (opcional)
      });
        const imageData = canvas.toDataURL('image/png');
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        doc.addImage(imageData, 'PDF', 0, 0, pdfWidth, pdfHeight);
  };

  // Dividir el contenido en secciones basadas en la clase "pagebreak"
  const secciones = elementoHTML.getElementsByClassName('pagebreak');

  // Generar una página PDF para cada sección
  for (let i = 0; i < secciones.length; i++) {
    const seccion = secciones[i];
    if (i !== 0) {
      doc.addPage();
    }
    await generarPaginaPDF(seccion);
  }
    doc.save(`./resultado/${namePdf}.pdf`);
    setLoading(false)
    cancelDowload()
  }

  const cancelDowload = () => {
    setDisabledButton(false)
    dispatch({
        type: 'SET_MODAL',
        open: false,
      })
  };
  const closeModal = () => setMessage({...message, open: false});
  const aceptActions = () => closeModal()
//   const getDataPdf = () => {

//     const dowload = async() => {
 
//              const result = await getResultEvaluationEvaluated( applicationId, evaluated, dispatch);
            
//              if(result?.status >= 200 && result?.status <= 204){
//                return dispatch({
//                  type: 'SEND_DATA_TO_PAGE',
//                  data: {...dataToPage?.data, ...result?.data}
//                })
//              }
//              if(result?.status === 404){
//                return  setMessage({
//                  open : true,
//                  description: 'La aplicación de modelo de evaluación no existe',
//                  handleClick : () => closeModal() ,
//                  oneButtons : true,
//                  textButtonSubmit: 'ACEPTAR',
//                  type : 'WARNING',
//                })
//              }
//              else {
//                return  setMessage({
//                  open : true,
//                  description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
//                  handleClick : () => closeModal() ,
//                  oneButtons : true,
//                  textButtonSubmit: 'ACEPTAR',
//                  type : 'WARNING',
//                })
//              }
             
//            }
//      dispatch({
//        type: 'SET_MODAL',
//        open: true,
//      })
 
//      dowload()
//  };

  const validateLabel = (name) => {
    if(name === 'SELF_EVALUATION'){
        return 'Autoevaluación'
    }
    else if(name === 'PEER_EVALUATION'){
        return 'Evaluación estudiantes'
    }
    else if(name === 'LEADER_EVALUATION'){
        return 'Evaluación institucional'
    }
    else if(name === 'CLIENT_EVALUATION'){
        return 'Evaluación cliente'
    }
  }    



  useEffect(()=> {
    const data = dataToPage?.data?.performanceEvaluation?.competences
    setNamePdf(`${dataToPage?.data?.evaluated?.name} ${dataToPage?.data?.evaluated?.surname}`)
    const newData = [];
    for (let index = 0; index < data?.length; index++) {
       
        newData.push({
            nameCompetence: dataToPage?.data?.performanceEvaluation?.competences[index]?.competence?.name,
            competences: [
                {
                    label: `Resultado`,
                    type: 'esperado',
                    value: dataToPage?.data?.performanceEvaluation?.competences[index]?.value,
                    color: '#072641',
                },
                {
                    label: `Resultado`,
                    type: 'obtenido',
                    value: dataToPage?.data?.performanceEvaluation?.competences[index]?.valueObtained,
                    color: '#18B0BE',
                },
            ]
       
        },
        )
    }


    setDataGraphic(newData)
    addIdCompetences()

    setCriteriaRegister([
        {
            id: 1,
            title: 'Cirterio 1',
            result: [
                {
                    id: 1, appearance: 'Aspecto 1', selfAssessment: 4.70,
                    student: 3.80, institutional: 4.90, average: 3.71
                },
                {
                    id: 2, appearance: 'Aspecto 2', selfAssessment: 4.70,
                    student: 3.80, institutional: 4.90, average: 3.71
                },
                {
                    id: 3, appearance: 'Aspecto 3', selfAssessment: 4.70,
                    student: 3.80, institutional: 4.90, average: 3.71
                },
            ]
        },
        {
            id: 2,
            title: 'Cirterio 2',
            result: [
                {
                    id: 1, appearance: 'Aspecto 1', selfAssessment: 4.70,
                    student: 3.80, institutional: 4.90, average: 3.71
                },
                {
                    id: 2, appearance: 'Aspecto 2', selfAssessment: 4.70,
                    student: 3.80, institutional: 4.90, average: 3.71
                },
                {
                    id: 3, appearance: 'Aspecto 3', selfAssessment: 4.70,
                    student: 3.80, institutional: 4.90, average: 3.71
                },
            ]
        },
    ]);

    const newCriteria = [];
    for (let index = 0; index < criteriaRegister?.length; index++) {
        newCriteria.push(
            {
                id: criteriaRegister[index]?.id,
                title: criteriaRegister[index]?.title
            }
        )
        
        for (let prom = 0; prom < criteriaRegister[index]?.result?.length; prom++) {
           
            newCriteria.push(
                {
                    id: criteriaRegister[index]?.result[prom]?.id, 
                    appearance: criteriaRegister[index]?.result[prom]?.appearance, 
                    selfAssessment: criteriaRegister[index]?.result[prom]?.selfAssessment,
                    student: criteriaRegister[index]?.result[prom]?.student, 
                    institutional: criteriaRegister[index]?.result[prom]?.institutional, 
                    average: criteriaRegister[index]?.result[prom]?.average
                }
            )
        }
    }

    setNewDataCriteria(newCriteria)

    //Data para la tabla group
    const dataTableGroup = []
    for (let index = 0; index < 57; index++) {
        
        dataTableGroup.push(
            {
                subject: `Maestría en Ambientes - ${index+1}`,
                selfAssessment: 4.90, student: 4.50,
                institutional: 3.75, definitive: 4.17, population: 30 / 40
            },
        )
    }

    setResultGroup(dataTableGroup);

    setAskAndAnswer([
      {
        ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. ',
        answer: [
          {
            type: 'Estudiante',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Autoevaluación',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Institucional',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Estudiante',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
        ],
      },
      {
        ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        answer: [
          {
            type: 'Estudiante',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Autoevaluación',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Institucional',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
        ],
      },
      {
        ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. ',
        answer: [
          {
            type: 'Estudiante',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Autoevaluación',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Institucional',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Estudiante',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
        ],
      },
      {
        ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        answer: [
          {
            type: 'Estudiante',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Autoevaluación',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Institucional',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
        ],
      },
      {
        ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. ',
        answer: [
          {
            type: 'Estudiante',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Autoevaluación',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Institucional',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Estudiante',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
        ],
      },
      {
        ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        answer: [
          {
            type: 'Estudiante',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Autoevaluación',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
          {
            type: 'Institucional',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
          },
        ],
      },
      
    ]);


  },[dataToPage])


  //le damos id a cada criterio
  const addIdCompetences = () => {
    const newData = [];

    let idCompetences = 0; 
    let arrConstants = []
    for (let index = 0; index < dataToPage?.data?.performanceEvaluation?.competences?.length; index++) {
        idCompetences = idCompetences+1
        arrConstants.push(`page_${idCompetences}`)

        newData.push(
                    <Grid item container lg={10}  id={`page_${idCompetences}`} justifyContent="center">
                                <Grid item container lg={12} justifyContent="center">
                                    <Grid item lg={9.5} md={9.5}>
                                        <Typography></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container lg={12} style={{breakInside: 'avoid'}}>
                                    <Grid item lg={12} style={{background: 'rgba(161, 213, 229, 0.61)', pageBreakInside: 'avoid'}}>
                                        <Typography style={{padding: 10, fontWeight: 700, fontSize: 13, textAlign: 'initial', pageBreakInside: 'avoid', whiteSpace: 'nowrap' }}>
                                        {dataToPage?.data?.performanceEvaluation?.competences[index]?.competence?.name}
                                        </Typography>
                                    </Grid>
                                    
                                </Grid>
                                <Grid item lg={12} md={12}>
                                    {dataToPage?.data?.performanceEvaluation?.competences[index]?.competence?.domainLevelList?.find((d)=> d?.number === dataToPage?.data?.performanceEvaluation?.competences[index]?.domainLevelObtained)?.conductList?.map((l)=> {
                                        return <Typography style={{padding: 10, fontSize: 13, fontWeight: 400, textAlign: 'initial'}}>{`- ${l?.name}`}</Typography>
                                    })}
                                </Grid>
                    </Grid>
        )

        if(dataToPage?.data?.performanceEvaluation?.competences[index]?.domainLevelObtained < dataToPage?.data?.performanceEvaluation?.competences[index]?.domainLevel){
            idCompetences = idCompetences+1;
            arrConstants.push(`page_${idCompetences}`)
            newData?.push(
                <Grid item container lg={10} id={`page_${idCompetences}`} justifyContent="center">
                <Grid item lg={12}style={{padding: 10, background: '#ff9a38',  textAlign: 'initial' }}>
                    <Typography style={{fontWeight: 700, fontSize: 13}}>A continuación los aspectos esperados no alcanzados que se deben mejorar.</Typography>
                </Grid>
                <Grid item lg={12} >
                {dataToPage?.data?.performanceEvaluation?.competences[index]?.competence?.domainLevelList?.find((d)=> d?.number === dataToPage?.data?.performanceEvaluation?.competences[index]?.domainLevel)?.conductList?.map((l)=> {
                        return <Typography style={{fontSize: 13, fontWeight: 400, padding: '10px 0px 10px 10px', textAlign: 'initial'}}>{`- ${l?.name}`}</Typography>
                    })}
                </Grid>               
            </Grid>
            )
        }
    }


    setConstants(arrConstants)
    setCopyHtmlCompete(newData)
    setHtmlCompete(newData)
  }
  

    // TODO : PASO 1
    // ! aqui podremos agregar la Tabla de Resultados por Grupo y sus diferentes secciones
    const tableResultGroup = () => {

      
            const maxCells = 8;
            const maxCellsforPage = 28;
            const newData =[];

            //Agregamos por pagina el resto de datos de la primer tabla
            const missingCells = (data) => {
                let firstIndex = 0;
                let lastIndex = 0;
                let iteration = 0;
                const numberSections = Math.ceil(data?.length/maxCellsforPage)

                for (let index = 0; index < numberSections; index++) {
                    firstIndex = index * maxCellsforPage;
                    lastIndex +=maxCellsforPage;
                    iteration++

                    newData.push(
                        <>
                        <Grid id={`subject${index}`} item container lg={12} className='page3 page pagebreak' style={{ pageBreakInside: 'avoid', marginBottom: '7%' }} justifyContent="center">
                            {header()}
                            <Grid item container lg={10} >
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            {data?.slice(firstIndex, lastIndex).map((registro, index) => (
                                                <TableRow key={registro.id} style={{ backgroundColor: index % 2 === 0 ? '#EFF4F6' : '#DDDDDD' }}>
                                                <TableCell align="left" style={{ fontWeight: 400, fontSize: '16px' }}>{registro.subject}</TableCell>
                                                <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.selfAssessment}</TableCell>
                                                <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.student}</TableCell>
                                                <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.institutional}</TableCell>
                                                <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.definitive}</TableCell>
                                                <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.population}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            {
                                iteration === numberSections && (heightOfPage - (data?.slice(firstIndex, lastIndex)?.length*valorCell + valHeader)) >= (valorCell*3 + valHeaderCriteria) 
                                ? criteriaTable(data?.slice(firstIndex, lastIndex)?.length) 
                                : null
                            }

                        </Grid>
                            {
                                iteration === numberSections && (heightOfPage - (data?.slice(firstIndex, lastIndex)?.length*valorCell + valHeader)) < (valorCell*3 + valHeaderCriteria) 
                                ? criteriaTable(data?.slice(firstIndex, lastIndex)?.length) 
                                : null
                            }
                        </>
                    ) 
                }      
            }
            if(resultGroup?.length <= 8) {
                newData.push(
                    <Grid container id="tableResult1" className="page2 page" style={{ pageBreakInside: 'avoid', marginBottom: '7%' }} justifyContent="center">
                        <Grid item container lg={12} justifyContent="center">
                            <Grid item lg={10} style={{ marginBottom: '4%', pageBreakBefore: 'always' }}>
                                <Typography style={{ padding: 20, background: 'rgb(161 213 229 / 61%)', fontWeight: 700, fontSize: 15 }}>
                                    TABLA DE RESULTADOS POR GRUPO
                                </Typography>
                            </Grid>
                            <Grid item container lg={10}>
                                <TableResultGroup resultGroup={resultGroup} />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }else if(resultGroup?.length > 8) {

                newData.push(
    
                        <Grid container id="tableResult1" className="page2 page" style={{ pageBreakInside: 'avoid', marginBottom: '7%' }} justifyContent="center">
                            <Grid item container lg={12} justifyContent="center">
                                <Grid item lg={10} style={{ marginBottom: '4%', pageBreakBefore: 'always' }}>
                                    <Typography style={{ padding: 20, background: 'rgb(161 213 229 / 61%)', fontWeight: 700, fontSize: 15 }}>
                                        TABLA DE RESULTADOS POR GRUPO
                                    </Typography>
                                </Grid>
                                <Grid item container lg={10}>
                                    <TableResultGroup resultGroup={resultGroup.slice(0, maxCells)} />
                                </Grid>
                            </Grid>
                            
                        </Grid>
                )
                missingCells(resultGroup.slice(maxCells, resultGroup?.length))
            }

            return setHtmlTableGroup( newData?.map(x=> x)   )
        
    }

    // TODO : PASO 2
    // ! Agregamos ahora la Tabla para los criterios 
    const criteriaTable = (number) => {

        const heigthHeader = [];
        const maxCellsforPage = 28;
            const newData =[];

        const leftoverSpace = heightOfPage - (number*valorCell + valHeader)
        const countCells = leftoverSpace - valPie - valHeaderCriteria;
        const totalCells = countCells/valorCell 

        const missingCells = (data) => {
            let firstIndex = 0;
            let lastIndex = 0;
            const numberSections = Math.ceil(data?.length/maxCellsforPage)

            for (let index = 0; index < numberSections; index++) {
                firstIndex = index * maxCellsforPage;
                lastIndex +=maxCellsforPage;
                newData.push(
                    
                    <Grid id={`subject${index}`} item container lg={12} className='page4 page pagebreak' style={{ pageBreakInside: 'avoid', marginBottom: '7%' }} justifyContent="center">
                        {header()}
                        <Grid item container lg={10} >
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {data?.slice(firstIndex, lastIndex).map((registro, index) => (
                                             <React.Fragment key={registro.id}>
                                             {registro?.title 
                                              ?  <TableRow>
                                                   <TableCell
                                                     colSpan={6}
                                                     style={{ backgroundColor: '#DDDDDD', fontWeight: 400, fontSize: '16px' }}
                                                   >
                                                     {registro.title}
                                                   </TableCell>
                                                 </TableRow>
                                               : null
                                             }
                                             {!registro?.title 
                                               ? <TableRow key={registro?.id} style={{ backgroundColor: '#EFF4F6' }}>
                                                   <TableCell></TableCell>
                                                   <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.appearance}</TableCell>
                                                   <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>
                                                     {registro.selfAssessment}
                                                   </TableCell>
                                                   <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.student}</TableCell>
                                                   <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>
                                                     {registro.institutional}
                                                   </TableCell>
                                                   <TableCell style={{ fontWeight: 400, fontSize: '16px' }}>{registro.average}</TableCell>
                                                 </TableRow> 
                                               : null
                                             }
                                               
                                        
                                           </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                ) 
            }

        
            return newData?.map((x)=> x)
        }
        
        if((heightOfPage - (number*valorCell + valHeader)) >= (valorCell*3 + valHeaderCriteria)){

            // ! si vemos que el countCells no tiene espacio (es decir, no me da para agrear por lo menos el encabezado y una fila ) solo dejaremos el header
                if(totalCells >= valorCell/valorCell  ){

                    heigthHeader.push(
                        <Grid container id="tableResult1" className="page2 page" style={{ pageBreakInside: 'avoid', marginBottom: '7%' }} justifyContent="center">
                            {headerCriteria()}
                            <Grid  item container lg={10}>
                                <CriteriaTable  criteriaRegister={newDataCriteria.slice(0, Math.round(totalCells) )} />
                            </Grid>
                        </Grid>
                        )
                   
                            heigthHeader.push(missingCells( newDataCriteria.slice(Math.round(totalCells), newDataCriteria?.length) ))
                }else {
                    heigthHeader.push(headerCriteria())
                }

            return heigthHeader?.map((x)=> x)
           
        }else if(heightOfPage - (number*valorCell + valHeader) >= (valorCell*3 )){
           
            heigthHeader.push(
                 <Grid item container lg={12} className=" pagebreak" justifyContent="center">
                    {header()}
                    {headerCriteria()}
                    <Grid  item container lg={10}>
                        <CriteriaTable  criteriaRegister={criteriaRegister} />
                    </Grid>
                 </Grid>
            )
            return heigthHeader?.map((x)=> x)

        }else {
           
            heigthHeader.push(
                <Grid item container lg={12} className=" pagebreak" justifyContent="center">
                   {header()}
                   {headerCriteria()}
                   <Grid  item container lg={10}>
                       <CriteriaTable  criteriaRegister={criteriaRegister} />
                   </Grid>
                </Grid>
           )
           return heigthHeader?.map((x)=> x)
           
        }
    }




  const header = () => {
    return  <Grid id='encabezado' item lg={12} md={12} style={{paddingTop: '2%', background: 'rgb(161 213 229 / 61%)'}}>
                <div  style={{textAlign: 'center', justifyContent: 'space-between', alignItems: 'center', display: 'flex', color: 'black'}}>
                    <img src={Logo} className={classes.img} alt="Logo"/>
                    <h3 style={{textAlign: 'center', margin: 'auto'}}>RESULTADO DE LA EVALUACIÓN</h3>
                    <p style={{ fontSize: 16, fontWeight: 400, margin: 'auto' }}>{ moment(new Date()).format("DD-MM-YYYY")}</p>
                </div>
            </Grid>
  }

  const headerCriteria = () => {
    return (
            <Grid id="headerCriterias" item lg={10} style={{ margin: '4% 0%', pageBreakBefore: 'always' }}>
                <Typography style={{ padding: 20, background: 'rgb(161 213 229 / 61%)', fontWeight: 700, fontSize: 15 }}>
                    TABLA DE CRITERIOS
                </Typography>
            </Grid>
    )
  }

  // Tabla valoracion por tipo de evaluación
  const tablaPonderaciones = () => {
    return <>
        <Grid id="tablaPonderaciones" item container lg={12} justifyContent="center">
            <Grid item lg={10} style={{margin: '3% 0px'}}>
                <Typography style={{fontSize: 14, fontWeight: 700}}>Información del colaborador</Typography>
            </Grid>
            <Grid item lg={10}>
                <table className={classes.table}>
                    <tr>
                        <td className={classes.titleInfo}>Nombre</td>
                        <td className={classes.valueInfo}>
                        {`${dataToPage?.data?.evaluated?.name} ${dataToPage?.data?.evaluated?.surname}`}{' '}
                        </td>
                        <td className={classes.titleInfo}>Identificación</td>
                        <td
                        className={classes.valueInfo}
                        >{`${dataToPage?.data?.evaluated?.identificationNumber}`}</td>
                    </tr>
                    <tr style={{ border: '1px solid' }}>
                        <td className={classes.titleInfo}>Programa</td>
                        <td className={classes.valueInfo}>{dataToPage?.data?.evaluated?.regional?.name}</td>
                        <td className={classes.titleInfo}>Cargo</td>
                        <td className={classes.valueInfo}>{dataToPage?.data?.evaluated?.position?.name}</td>
                    </tr>
                </table>
            </Grid>
            <Grid item lg={10}
                style={{
                marginBottom: dataGraphic?.length > 4 ? '4%' : '1%',
                marginTop: '3%',
                }}
            >
                <Typography style={{ fontSize: 24, fontWeight: 400 }}>Información de la evaluación</Typography>
            </Grid>
            <Grid item lg={10}>
                <table className={classes.table}>
                <tr>
                    <td className={classes.titleInfo} style={{ width: '20%' }}>
                    Nombre
                    </td>
                    <td className={classes.valueInfo} style={{ textAlign: 'start' }}>
                    {dataToPage?.data?.performanceEvaluation?.name}{' '}
                    </td>
                </tr>
                </table>
            </Grid>
            <Grid item lg={10} style={{ margin: '1% 0%' }}>
                <table className={classes.table}>
                <tr>
                    <td className={classes.titleInfo} style={{ width: '20%' }}>
                    Descripción
                    </td>
                    <td className={classes.valueInfo} style={{ textAlign: 'start' }}>
                    {dataToPage?.data?.performanceEvaluation?.description}
                    </td>
                </tr>
                </table>
            </Grid>
            <Grid item lg={10}>
                <table className={classes.table}>
                <tr>
                    <td className={classes.titleInfo} style={{ width: '20%' }}>
                    Periodo de evaluación
                    </td>
                    <td className={classes.valueInfo} style={{ textAlign: 'start' }}>{`${moment(
                    dataToPage?.data?.performanceEvaluation?.startDate
                    ).format('DD [de] MMMM [de] YYYY')} hasta ${moment(
                    dataToPage?.data?.performanceEvaluation?.endDate
                    ).format('DD [de] MMMM [de] YYYY')}`}</td>
                </tr>
                </table>
            </Grid>
            <Grid item lg={10} style={{ margin: '1% 0%' }}>
                <table className={classes.table}>
                <tr>
                    <td className={classes.titleInfo} style={{ width: '20%' }}>
                    Periodo de aplicación
                    </td>
                    <td className={classes.valueInfo} style={{ textAlign: 'start' }}>{`${moment(
                    dataToPage?.data?.performanceEvaluation?.startDate
                    ).format('DD [de] MMMM [de] YYYY')} hasta ${moment(
                    dataToPage?.data?.performanceEvaluation?.endDate
                    ).format('DD [de] MMMM [de] YYYY')}`}</td>
                </tr>
                </table>
            </Grid>
            <Grid item lg={10} style={{marginBottom: '1%', marginTop: '3%'}}>
                <Typography style={{fontSize: 14, fontWeight: 400, textAlign: 'start'}}>{`En seguida se describen los distintos tipos de evaluación utilizados (autoevaluación, evaluación entre pares y evaluación del líder), junto con el peso asignado a cada uno y los resultados obtenidos en cada evaluación.`} </Typography>
            </Grid>
            <Grid
                    item
                    lg={10}
                    style={{
                    marginBottom: '6%',
                    marginTop: dataGraphic?.length > 4 ? '4%' : '1%',
                    }}
                >
                    <table className={classes.table}>
                    <tr>
                        <th className={classes.titleTablePercentagesBorder}>Tipo de evaluación</th>
                        <th className={classes.titleTablePercentages}>Total obtenido</th>
                        <th className={classes.titleTablePercentages}>Valoración</th>
                    </tr>
                    {dataToPage?.data?.applicationTypes?.map(x => (
                        <tr>
                        <td className={classes.headerTable}>
                            {validateLabel(x?.type)}
                            <span className={classes.headerTableSpan}>{x?.percentage}</span>
                        </td>
                        <td className={classes.titleGraphic}>
                            <DivColor quantityDivisions={5} coloredDivisions={x?.totalObtained} />
                        </td>
                        <td className={classes.titleGraphicSpan}>
                            Bajo
                            {x?.level}
                        </td>
                        </tr>
                    ))}

                    <tr>
                        <td className={classes.headerTable}>
                        Rendimiento definitivo
                        <span className={classes.headerTableSpan}>
                            {dataToPage?.data?.performanceEvaluation?.totalResultPercentage}
                        </span>
                        </td>
                        <span className={classes.titleGraphicPerformance}>
                        <GraphicPercentage value={dataToPage?.data?.performanceEvaluation?.totalResultPercentage} />
                        </span>
                    </tr>
                    </table>
                </Grid>
        </Grid>
    </>
  }
 
  return (
    <>
        <form id="pdf" onSubmit={(e)=> handleClickForm(e)} style={{ backgroundColor: '#ffffff', zIndex: -1, position: 'fixed' }}>
        <Grid container id="pagec" justifyContent="center">
            <Grid container id="informationUser" className="page1 page pagebreak"  justifyContent="center">
                {header()}
                {tablaPonderaciones()}
                {htmlTableGroup?.map((x)=> x)}

            </Grid>
            <Grid item container lg={12} justifyContent="center">
            <Grid item lg={10} style={{ marginBottom: '4%', pageBreakBefore: 'always' }}>
              <Typography
                style={{
                  padding: '2rem 4rem',
                  background: 'rgb(161 213 229 / 61%)',
                  fontWeight: 900,
                  fontSize: 24,
                }}
              >
                RESPUESTA A PREGUNTAS ABIERTAS
              </Typography>
            </Grid>
            <Grid item container lg={10}>
              <QuestionsAnswers askAndAnswer={askAndAnswer} />
            </Grid>
          </Grid>
            {observations}
    </Grid>
        </form>
        <Loading active={loading}/>
    </>
    );
  
};

export default ModalConfirmBase;
