import { makeStyles, withStyles } from '@mui/styles';

export const styles = makeStyles({
  dropdown: {
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "8px",
    // height: 40,
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
      background: '#dfe7fd',
    },
  },
  iconErrorFieldLabel: {
    marginRight: '2%',
    marginBottom: '3%',
    fontSize: 'initial'
  },
  buttons: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'end'
}
})


