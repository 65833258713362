import React, {useEffect, useState} from "react";
import { Typography, Button, Grid } from "@mui/material";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Snackbar from '../../utils/snackbars/SnackBars';
import {loginUser, getRoleUser} from  "../../actions/login-action"
import { PATH_APP } from "../../constants";
import { useStateValue } from "../../context/store";
import * as CryptoJS from 'crypto-js';



const style = {
  paper: {
    flexDirection: "column",
},
  leftside: {
    backgroundColor: "#004B93",
    width: "100%",
    height: "100%",
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    align: "center",
  },
  alignText: {
   display: 'grid',
   placeItems: 'center'
    },
    submit: {
        marginTop: '3%'
    },
    message: {
        color: "#124387", 
        paddingTop: '10%', 
        background: '#dfe7fd', 
        textAlign: 'center', 
        wordBreak: 'break-word', 
        width: '90%'
    }
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ReplyAssessment = (props) => {
  const query = useQuery();
  const [ , dispatch] = useStateValue();
  const [messageSnackbar, setMessageSnackbar] = useState({
    message: '',
    open: false,
    severity: '',
    handleClose: () => {}
  })


    const decryptData = () => {

      const queryUrl = query?.get('payload')?.replace(/ /g, '+')
      if(queryUrl){
        const result =  JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(queryUrl,  process.env.REACT_APP_SECRET_KEY)));
        const loginUser = {username: result?.username ?? '', password: result?.password ?? ''};
        return postCredentialsUser(loginUser);
      }else {
        redirectToLogin()
      }
    }



  const handleCloseSnackbar = () => {
    setMessageSnackbar({...messageSnackbar, open: false});
    return props.history.push("/auth/login");
  }
  const redirectToLogin = () => {
    setMessageSnackbar({
      message: 'ocurrió un error serás redireccionado al login de Autoevaluaciones',
      open: true,
      severity: 'error',
      handleClose: () => handleCloseSnackbar()
    });
  }

  const postCredentialsUser = async(data) => {
    await loginUser(data, dispatch)
    .then(
      (result) => {
        if(result?.status >= 200 && result?.status <= 204){
          const getRoles = async() => {
    
            const res = await getRoleUser(dispatch)
            if(res?.status >= 200 && res?.status <= 204){
              return props.history.push(`${PATH_APP}/home`);
            }
            
          }
          getRoles()
        }
      }
    ).catch((err)=>{

      if (err?.status === 400){
         return setMessageSnackbar({
            message: 'Usuario inactivo',
            open: true,
            severity: 'error',
            handleClose: () => handleCloseSnackbar()
          });
      }else {
        redirectToLogin()
      }
    })
   
    
  }



  return (
      <>
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{height: '100vh'}}>
        <Grid item lg={10} md={10} sm={10} xs={10} style={style.alignText}>
            <Typography
                component="h1"
                variant="h5"
                style={style.message}
            >
                <b>
                    Estimado usuario
                    para poder acceder al software académico debe realizar primero las evaluaciones pendientes
                </b>
            </Typography>
           
        </Grid>
        <Grid item lg={10} md={10} sm={10} xs={10} style={style.alignText}>
            <Button
                type="button"
                variant="contained"
                color="primary"
                style={style.submit}
                onClick={decryptData}
            >
                Ver evaluaciones
            </Button>
        </Grid>
      {/* <div style={style.paper}>
          
      </div> */}

      </Grid>
      < Snackbar 
          severity={messageSnackbar.severity} 
          message={messageSnackbar.message} 
          open={messageSnackbar.open}
          handleClose={messageSnackbar.handleClose}
      />
      </>
  );
};

export default withRouter(ReplyAssessment);
