import React,{ useState } from 'react';
import {
    Button,
    Grid,
    FormControl,
    TextField,
    Typography,
    OutlinedInput,
    InputAdornment,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {styles} from './styles';
import ModalConfirm from "../../../../utils/form/modalConfirmBase";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useStateValue } from "../../../../context/store";
import FilterLeader from "./newLeader/index";
import { updateEmployee } from '../../../../actions/OrganizationChart-action';


export default function AlertDialog(props) {
    const classes = styles()
    const [{ newleader, organization }, dispatch] = useStateValue();
    const [message, setMessage] = useState({
        description : '',
        textButtonSubmit : '',
        handleClick : () => {} ,
        handleClickOut : () => {},
        oneButtons : false,
        title: '',
        type : '',
        open : false
      })



  const closeAndReset= () => {
    props?.handleClose()
    closeModal();
    dispatch({
        type: 'SET_FILTER_NEW_LEADER',
        idKeyword: newleader?.idKeyword,
        nameKeyword: newleader?.nameKeyword,
        positionName: newleader?.positionName,
        regionalName: newleader?.regionalName,
        leaderId: newleader?.leaderId,
        subalterns: newleader?.subalterns,
        editLeader: true
    })
    }  

    const closeModal = () => setMessage({...message, open: false})
  
  const submitData = ()=> {

    

    const newCheckeds = []
    for (let index = 0; index < organization?.subalterns?.length; index++) {
      newCheckeds.push({employeeId: organization?.subalterns[index]?.uuid})
    }

    const currentLeader ={
        operationType: "DELETE",
        leaderId: organization?.leaderId,
        subalterns: newCheckeds
      }
      const newLeader ={
        operationType: "CREATE",
        leaderId: newleader?.leaderId,
        subalterns: newCheckeds
      }


    return setMessage({
        open : true,
        description: '¿ Esta seguro ? al modificar el lÍder también se le asignarán todos los colaboradores ',
        handleClick : () => deleteSubalterns(currentLeader, newLeader) ,
        type : 'WARNING',
        handleClickOut: () => closeModal(),
      })
   
  
    
}

 

const deleteSubalterns = async(currentLeader, newLeader) => {
    const result = await updateEmployee(currentLeader)
    if (result && (result?.status === 200 || result?.status <= 204)) {
      create(newLeader)
    }
    else if (result?.status === 400 ) {
      setMessage({
        open : true,
        description: result?.data?.message,
        handleClick : () => closeModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    } else {
      setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => closeModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }
  }

  const create = async(newLeader) => {
    const result = await updateEmployee(newLeader)
    if (result && (result?.status === 200 || result?.status <= 204)) {
      return  setMessage({
        open : true,
        type : 'SUCCESS',
        oneButtons : true,
        title: '¡Proceso exitoso!',
        textButtonSubmit: 'ACEPTAR',
        handleClick : () => closeAndReset(),
        description: 'Información almacenada correctamente ',
          })
    }
    else if (result?.status === 400 ) {
      setMessage({
        open : true,
        description: result?.data?.message,
        handleClick : () => closeModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    } else {
      setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => closeModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }
  }

  return (
    <div >
      <Dialog
       className={classes.modalBank}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" 
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleModalBank}>
         Cambio de Líder
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{marginTop: 10}}>
            <Typography style={{fontSize: 14, fontWeight: 400, color: '#4B4B4B', marginBottom: '2%'}}>Líder anterior</Typography>
            <Grid container spacing={2}>
                <Grid item lg={3}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <OutlinedInput
                            type='text' 
                            value={props?.data?.regional}
                            disabled
                            className={classes.dropdown}
                            placeholder="Programa"
                            notched={false}
                            endAdornment={
                            <InputAdornment position="end">
                                { <ArrowDropDownIcon style={{fontSize: 20}}/>  }
                            </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={3}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <OutlinedInput
                            type='text' 
                            value={props?.data?.position}
                            disabled
                            className={classes.dropdown}
                            placeholder="Cargo"
                            notched={false}
                            endAdornment={
                            <InputAdornment position="end">
                                { <ArrowDropDownIcon style={{fontSize: 20}}/>  }
                            </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={2}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <TextField
                        className={classes.dropdown}
                        fullWidth
                        type="text"
                        placeholder="identificación"
                        disabled
                        value={props?.data?.userId}
                        size="small"
                    />
                    </FormControl>
                </Grid>
                <Grid item lg={4}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <OutlinedInput
                            type='text' 
                            value={props?.data?.name}
                            disabled
                            className={classes.dropdown}
                            placeholder="Identificación"
                            notched={false}
                            endAdornment={
                            <InputAdornment position="end">
                                { <ArrowDropDownIcon style={{fontSize: 20}}/>  }
                            </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item lg={12} style={{marginTop: '2%'}}>
                <Typography style={{fontSize: 14, fontWeight: 400, color: '#4B4B4B'}}>Líder nuevo</Typography>
                <FilterLeader 
                    edit={true}  
                    regionals={props?.regionals} 
                    positions={props?.positions}
                    setMessage={setMessage}
                    message={message}
                />
            </Grid>
            <React.Fragment >
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '16%'}}>
                <Button variant="outlined"  
                  disableElevation 
                  onClick={()=>props?.handleClose()}
                  style={{ color: "#6D9FFF", marginBottom: 5, borderColor: '#80808070' }} 
               
                >
                VOLVER
                </Button>
                    &nbsp;&nbsp;
                <Button
                    disabled={!newleader?.leaderId}
                  onClick={() => submitData()}
                  variant="contained"
                  style={{ color: "#ffffff", background: !newleader?.leaderId ? '#C0C0C0' : '#2F71CB', marginBottom: 5, marginLeft: 5 }}
                >
                    ACEPTAR
                </Button>
                </div>
               </React.Fragment>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
        </DialogActions>
      </Dialog>
      <ModalConfirm 
            description = { message.description }
            textButtonSubmit = { message.textButtonSubmit }
            handleClick = { message.handleClick }
            handleClickOut = { message.handleClickOut }
            oneButtons = { message.oneButtons }
            title = { message.title }
            type = { message.type }
            open = { message.open }
        />
    </div>
  );
}
