import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField, 
     Select, 
     InputLabel, 
     FormControl, 
     FormHelperText, 
     MenuItem,
     Button,
} from '@mui/material'
import { useStateValue } from "../../../context/store";
import {styles} from './styles';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SearchCompetencies = (props) => {


    const classes = styles()
    const [{behaviorSearch, breadCrumb}, dispatch] = useStateValue();
    const [getAll, setGetAll] = useState(false)
    const [valuesForm, setValuesForm] = useState({
        status: '',
        code: '',
        behavior: ''
    })


    const schema = yup.object().shape({
        behavior: valuesForm?.behavior ? yup
        .string()
        .max(100, 'Ingrese máximo 100 caracteres') : null,
        code: valuesForm?.code ? yup
        .string()
        .max(6, "Ingrese máximo 6 caracteres")
        .matches(  
            /^([0-9]{0,6})$/,
           "Código no válido") : null,
      });
    
      
      const { register, handleSubmit, setValue, trigger, unregister, control, watch, formState:{errors} } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });
      

      const onSubmit = (data) => {
        if(
            data?.code !== '' ||
            valuesForm.status !== '' ||
            data?.behavior !== ''
        ){
            dispatch({
                type: "SET_FILTER_BEHAVIOR",
                filter: {
                    code: data?.code,
                    status: 'ACTIVE',
                    question: data?.behavior,
                    page: 0,
                    size: 10
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "SET_FILTER_BEHAVIOR",
                filter: {
                    question: null,
                    status: null,
                    code: null
                  },
                  isFilter: true,
            })
        }
      }

      const clear = () => {
        setValue('behavior', '')
        setValue('code', '')
        unregister(['code', 'status'])
        setValuesForm({...valuesForm, status: '', code: '', behavior: ''})
      }

      useEffect(() => {
        clear()
      }, [props?.clearFielForm])

      
      const clearForm = () => {
        clear()
          dispatch({
              type: "SET_FILTER_BEHAVIOR",
              filter: {
                  behavior: null,
                  status: null,
                  code: null,
                  page: null,
                  size: null
                },
                isFilter: true,
            })
            setGetAll(false)
            props.dataFilter()
        }
        

        const getAllBehaviors = () => {props?.getAllBehaviorsActives(); setGetAll(true)}
        const handleOnchange = (e) => {
            const {name, value} = e.target;
            setValuesForm({...valuesForm, [name]:value})
            setValue(name, value)
            trigger(name)
        }

        useEffect(()=> {
            clearForm()
        },[])

        const validate =  watch('code') || valuesForm?.behavior
        const disabledButton = !validate || errors['code'] || errors['behavior']

    return (
         <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container spacing={2} style={{marginBottom: 20, display: 'flex', alignItems: 'flex-start'}}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <TextField
                        {...register('behavior')}
                        className={classes.dropdown}
                        fullWidth
                        type="text"
                        onChange={handleOnchange}
                        InputLabelProps={{
                            shrink: valuesForm?.behavior? true : false,
                            notched: valuesForm?.behavior? true : false,
                        }}
                        size="small"
                        label="Aspecto"
                        error={!!errors.hasOwnProperty("behavior") && errors["behavior"].message}
                    />
                    </FormControl>
                <FormHelperText style={{color:'red', marginLeft: '3%'}}>
                    {errors.hasOwnProperty("behavior") &&
                        errors["behavior"].message}
                </FormHelperText> 
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register("code")}
                    name="code"
                    className={classes.dropdown}
                    fullWidth
                    onChange={handleOnchange}
                    InputLabelProps={{
                        shrink: valuesForm?.code ? true : false,
                        notched: valuesForm?.code ? true : false,
                    }}
                    size="small"
                    label="Código"
                    error={!!errors.hasOwnProperty("code") && errors["code"].message}
                    />
                </FormControl>
                <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                    {errors.hasOwnProperty("code") &&
                        errors["code"].message}
                </FormHelperText> 
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} className={classes.buttons}>
                   
                    <Button 
                        variant="contained" 
                        type="submit" 
                        disableElevation 
                        disabled={!validate}
                        style={{color: "#ffffff", background: !disabledButton ? "#2F71CB": '#C0C0C0', width: '50%'}}
                    >
                        FILTRAR
                    </Button>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 
                    <Button 
                        variant="contained" 
                        onClick={clearForm} 
                        disableElevation 
                        disabled={!validate && !getAll}
                        style={{color: "#ffffff", background: validate ||  getAll ? "#2F71CB": '#C0C0C0', width: '40%'}}
                    >
                         Limpiar
                    </Button>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 
                    <Button 
                        variant="contained" 
                        onClick={()=> getAllBehaviors()}
                        disableElevation 
                        style={{color: "#ffffff", background:  "#2F71CB", width: '50%'}}
                    >
                        TODOS
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default SearchCompetencies
