import React, { useState, useEffect } from 'react';
import { FormControl, Grid, TextField, Button, FormHelperText, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import * as yup from 'yup';

import { styles } from './styles';
import { useStateValue } from '../../../../context/store';
import ModalConfirm from '../../../../utils/form/modalConfirmBase';
import { PATH_APP, REQUIRED_MESSAGE, titleHeader } from '../../../../constants';
import { getTypesEvaluators } from '../../../../actions/Performance_Evaluation-action';
import CustomizedButtonMenu from '../../../createEvaluationPerformance/relationShip/relations/components/button-menu';
import ModalReviewRol from "../../../createEvaluationPerformance/relationShip/relations/components/modal-review-rol";

const Index = props => {
  const classes = styles();
  const history = useHistory();

  const [{ typesEvaluations, relations }, dispatch] = useStateValue();
  const [currentDay, setCurrentDay] = useState();
  const [typesEvaluators, setTypesEvaluators] = useState([]);
  const [typesEvaluation, setTypesEvaluation] = useState([]);
  const [valuesForm, setValuesForm] = useState({
    status: 'ACTIVE',
    startDate: '',
    endDate: '',
  });
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
  })
const [messageModalReviewRol, setMessageModalReviewRol] = useState({
    description: '',
    textButtonSubmit: '',
    currentData: null,
    oneButtons: () => { },
    handleClick: () => { },
    handleClickOut: () => { },
    title: '',
    open: false
  })




  const schema = yup.object().shape({
    name: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(10, 'Ingrese mínimo 10 caracteres')
      .max(500, 'Ingrese máximo 500 caracteres'),
    user: yup.string().required(REQUIRED_MESSAGE),
    description: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(10, 'Ingrese mínimo 10 caracteres')
      .max(1000, 'Ingrese máximo 1000 caracteres'),
    startDate: yup.date().nullable(true),
    creation: yup.date().nullable(true),
    endDate: yup.date().required('Este campo es obligatorio').nullable(true),
    startDateAplied: yup
      .date()
      .min(moment(new Date()).format('YYYY-MM-DD'), 'Ingrese mínimo la fecha actual')
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio'),
    endDateAplied: yup
      .date()
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio')
      .test(
        'maxLenght',
        'Ingrese una superior o igual a la fecha desde',
        val => currentDay <= moment(val).format('YYYY-MM-DD')
      ),
  });
  const {
    register,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props?.dataDetail?.name) {
      setValue('name', props?.dataDetail?.name);
      setValue('user', props?.dataDetail?.creatorUser?.surname);
      setValue('description', props?.dataDetail?.description);
      setValue('endDate', moment(props?.dataDetail?.endDate).format('YYYY-MM-DD'));
      setValue('startDate', moment(props?.dataDetail?.startDate).format('YYYY-MM-DD'));
      setValue('creation', moment(props?.dataDetail?.createdAt).format('YYYY-MM-DD'));
      setValuesForm({
        ...valuesForm,
        startDate: moment(props?.dataDetail?.startDate).format('YYYY-MM-DD'),
        endDate: moment(props?.dataDetail?.endDate).format('YYYY-MM-DD'),
      });
    }
  }, []);

  useEffect(() => {
    getTypes();
    if (typesEvaluations?.dataEvaluation?.competences?.length) {
      setValue('endDateAplied', moment(typesEvaluations?.dataEvaluation?.endDate).format('YYYY-MM-DD'));
      setValue('startDateAplied', moment(typesEvaluations?.dataEvaluation?.startDate).format('YYYY-MM-DD'));
    }
  }, []);

  useEffect(() => {
    setCurrentDay(watch('startDateAplied'));
  }, [watch('startDateAplied')]);

  const validateErros = () => {
    if (errors['startDateAplied']) {
      return 20;
    } else if (errors['endDateAplied'] && errors['endDateAplied'].message === 'Este campo es obligatorio') {
      return 20;
    } else if (errors['endDateAplied']) {
      return 40;
    } else {
      return null;
    }
  };

  const backToFormInitial = () =>  history.push(`${PATH_APP}/evaluation-performance-evaluator`);
const getTypes = async() => {
    const result = await getTypesEvaluators(dispatch);
    if(result?.data?.length) {
        setTypesEvaluation(result?.data?.map((x)=> ({...x, id: x?.uuid })))
        setTypesEvaluators(result?.data?.map((x)=> ({id: x?.uuid, value: titleHeader(x?.abbreviation)})))
    }else {
        return setMessage({
            open : true,
            description: 'Se presento un error en el servicio de tipos de evaluadores',
            handleClick : () => backToFormInitial() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
        })
    }
    
  }


const validWithRol = (data, rol) => {
    return data?.map(value => {
      const behaviors = value?.behaviors?.map(beha => {
        const questions = beha?.questions
          ?.filter(quest => quest.rols?.includes(rol))
          .map(quest => ({ ...quest, question: quest?.value }))
          .filter(Boolean); 

        return questions?.length ? { ...beha, questions } : null;
      }).filter(Boolean);

      return behaviors?.length ? { competence: value, conducts: behaviors, position: value?.position?.toString() } : null;
    }).filter(Boolean);
  };

  const closeModalReviewRol = () => setMessageModalReviewRol({ ...messageModalReviewRol, currentData: null, open: false })
  const closeModalValidation = () => setMessage({ ...message, open: false });
 
  const handleDataFromButtonMenu = (idRol) => {

    let roleName = typesEvaluation?.find(item => item.id === idRol)?.name;
    const dataByRol = idRol ? {competences: validWithRol(relations?.data, idRol), roleName} : [];
  
    if (!dataByRol?.competences?.length) {
      setMessage({
        open: true,
        description: "No se han relacionado preguntas para el rol",
        handleClick: closeModalValidation,
        oneButtons: true,
        textButtonSubmit: 'ACEPTAR',
        type: 'WARNING',
      });
    } else {
      setMessageModalReviewRol({
        ...messageModalReviewRol,
        currentData: {performanceEvaluation: dataByRol},
        open: true,
        oneButtons: true,
        handleClick: closeModalReviewRol,
      });
    }
  };


  useEffect(() => {
    if (watch('endDateAplied') && errors['endDateAplied']) {
      setValue('endDateAplied', '');
      clearErrors('endDateAplied');
    }
  }, [watch('startDateAplied')]);

  return (
    <>
    <form>
      <Grid
        container
        spacing={1}
        maxWidth="inherit"
        alignItems="end"
        style={{ background: props?.isEdit ? 'rgb(239 239 239)' : null }}
      >
        <Grid
          item
          spacing={1}
          lg={3.6}
          style={{ display: 'flex ', flexDirection: 'column', marginBottom: errors && validateErros() }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: 'flex', flexDirection: 'row', marginRight: 5 }}
          >
            <FormControl fullWidth size="small">
              <TextField
                {...register('creation')}
                size="small"
                type="date"
                label="fecha de creación"
                disabled
                className={classes.fieldDateTimeErrorSearch}
                minDate={new Date()}
                InputLabelProps={{
                  shrink: true,
                  notched: true,
                }}
                error={!!errors.hasOwnProperty('creation') && errors['creation'].message}
              />
              <FormHelperText style={{ color: '#d70909' }}>
                {!!errors.hasOwnProperty('creation') && errors['creation'].message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item lg={3.4} md={6} fullWidth style={{ marginBottom: errors && validateErros() }}>
          <FormControl fullWidth variant="outlined" size="small">
            <TextField
              {...register('user')}
              fullWidth
              size="small"
              disabled
              label="Usuario creador"
              placeholder="Usuario creador"
              multiline
              margin="none"
              maxRows={15}
              className={classes.dropdownMultiple}
              error={!!errors.hasOwnProperty('user') && errors['user'].message}
            />
            <FormHelperText style={{ color: '#d70909' }}>
              {!!errors.hasOwnProperty('user') && errors['user'].message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item spacing={1} lg={5} style={{ display: 'flex ', flexDirection: 'column' }}>
          {/* <div >
                            <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Periodo de evaluación</Typography>
                            <div style={{display:'flex ', flexDirection: 'row' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                    <FormControl fullWidth size="small">
                                    <TextField 
                                        {...register('startDate')}
                                        size="small"
                                        type="date"
                                        label="Desde"
                                        disabled
                                        onChange={handleChange}
                                        // value={breadCrumb?.module === "APLICATION_CUESTIONARY" ? questionarySearch?.filter?.createdAtStartDate : null}
                                        className={classes.fieldDateTimeErrorSearch}
                                        minDate={new Date()}
                                        InputLabelProps={{
                                            shrink: true,
                                            notched: true
                                        }}
                                        error={!!errors.hasOwnProperty("startDate") && errors["startDate"].message }
                                    />
                                    <FormHelperText style={{color:'#d70909'}}>
                                        {!!errors.hasOwnProperty("startDate") &&
                                        errors["startDate"].message}
                                    </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                                <FormControl fullWidth size="small">
                                    <TextField 
                                        {...register('endDate')}
                                        size="small"
                                        type="date"
                                        label="Hasta"
                                        disabled
                                        onChange={handleChange}
                                        className={classes.fieldDateTimeErrorSearch}
                                        minDate={new Date()}
                                        InputLabelProps={{
                                            shrink: true,
                                            notched: true
                                        }}
                                        error={!!errors.hasOwnProperty("endDate") && errors["endDate"].message }
                                    />
                                    <FormHelperText style={{color:'#d70909'}}>
                                        {!!errors.hasOwnProperty("endDate") &&
                                        errors["endDate"].message}
                                    </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </div>
                        </div> */}
        </Grid>
        <Grid item lg={12} md={12} fullWidth>
          <FormControl fullWidth variant="outlined" size="small">
            <TextField
              {...register('name')}
              fullWidth
              size="small"
              label="Nombre de la evaluación"
              placeholder="Nombre de la evaluación"
              multiline
              disabled
              margin="none"
              maxRows={15}
              className={classes.dropdownMultiple}
              error={!!errors.hasOwnProperty('name') && errors['name'].message}
            />
            <FormHelperText style={{ color: '#d70909' }}>
              {!!errors.hasOwnProperty('name') && errors['name'].message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12} fullWidth style={{ marginBottom: props?.isEdit ? '4%' : null }}>
          <FormControl fullWidth variant="outlined" size="small">
            <TextField
              {...register('description')}
              fullWidth
              size="small"
              multiline
              minRows={2}
              maxRows={20}
              disabled
              label="Descripción"
              placeholder="Descripción"
              className={classes.dropdownMultiple}
              error={!!errors.hasOwnProperty('description') && errors['description'].message}
            />
            <FormHelperText style={{ color: '#d70909' }}>
              {!!errors.hasOwnProperty('description') && errors['description'].message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item container lg={4} style={{ display: 'flex', alignItems: 'center', margin: '0% 0% 3% 0%' }}>
          <Grid item lg={2}>
            <Typography style={{ fontSize: 12, fontWeight: 700, margin: '3% 2% 3% 1%' }}>valor :</Typography>
          </Grid>
          <Grid item lg={4}>
            <FormControl fullWidth variant="outlined" size="small">
              <TextField
                fullWidth
                size="small"
                value={100}
                disabled
                placeholder="valor total"
                className={classes.dropdownMultiple}
              />
            </FormControl>
          </Grid>
          
          <Grid item container spacing={1} lg={12} md={12} style={{ margin: '7% 0% 3% 0%', display: 'flex'}}>
            <Grid item lg={6} style={{paddingLeft: 0}}>
              <CustomizedButtonMenu onDataSend={handleDataFromButtonMenu} typesEvaluators={typesEvaluation}/>
            </Grid>
            <Grid item lg={6} style={{paddingLeft: 0}}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  props?.props?.history.push(`${PATH_APP}/evaluation-performance-evaluator/copy/${props?.id}`)
                }
              >
                DUPLICAR MODELO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>

    <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
    />
    <ModalReviewRol
        handleClick={messageModalReviewRol.handleClick}
        handleClickOut={messageModalReviewRol.handleClickOut}
        oneButtons={messageModalReviewRol.oneButtons}
        open={messageModalReviewRol.open}
        currentData= {messageModalReviewRol.currentData}
    />
    </>
  );
};

export default Index;
