import React from 'react';

function QuestionsAnswers({ askAndAnswer }) {
  const getColor = index => {
    return index % 2 === 0 ? 'rgba(239, 244, 246, 1)' : '';
  };

  const getColorTag = type => {
    switch (type) {
      case 'Estudiante':
        return 'rgba(134, 219, 255, 1)';
      case 'Autoevaluación':
        return 'rgba(207, 165, 254, 1)';
      default:
        return 'rgba(255, 212, 147, 1)';
    }
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', width: '100%', fontWeight: 400, fontSize: '18px' }}
    >
      {askAndAnswer.map((item, index) => (
        <div key={index} style={{ marginBottom: '1.4rem' }}>
          <div
            style={{
              backgroundColor: 'rgba(225, 225, 225, 1)',
              minHeight: '5rem',
              padding: '0.3rem',
              marginBottom: '1rem',
            }}
          >
            <span style={{ fontWeight: 600 }}>¿Pregunta: </span>
            <span>{item.ask}?</span>
          </div>
          <div>
            {item.answer.map((respuesta, i) => (
              <div
                key={i}
                style={{
                  backgroundColor: getColor(i),
                  minHeight: '5rem',
                  padding: '0.6rem',
                  marginBottom: '1rem',
                }}
              >
                <span
                  style={{
                    backgroundColor: getColorTag(respuesta.type),
                    padding: '0 0.4rem',
                    borderRadius: '.5rem',
                    fontSize: '14px',
                    fontWeight: '600',
                    display: 'inline-block',
                    marginBottom: '.6rem',
                    display: 'block',
                    width: 'max-content',
                  }}
                >
                  {respuesta.type}
                </span>
                <span style={{ fontWeight: 500 }}>Respuesta {i + 1}: </span>
                <span>{respuesta.answer}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default QuestionsAnswers;
