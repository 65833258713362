import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField, 
     IconButton,
     Box,
     Autocomplete,
     FormControl,
     Tooltip,
     FormHelperText,
    OutlinedInput,
    InputAdornment,
    Typography
} from '@mui/material'
import { useStateValue } from "../../../../../context/store";
import CloseIcon from '@mui/icons-material/Close';
import {styles} from './styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AsyncPaginate } from "react-select-async-paginate";
import { useForm } from "react-hook-form";
import { getEmployeesSelect } from '../../../../../actions/OrganizationChart-action';
import ModalEditLeader from "../../modalEditLeader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalDiagram from "../../components/modalDiagram";
import DemoOrganizationGraph from '../../components/shared/organizationChart';
import { typesEvaluators } from './../../../../../constants';

const SearchCompetencies = (props) => {


    const classes = styles()
    const [{organization, newleader}, dispatch] = useStateValue();
    const [notData, setNotData] = useState(false);
    const [page, setPage] = useState(null);
    const [optionSelected, setOptionSelected]= useState('');
    const [userSelected, setUserSelected] = useState({});
    const [showDiagram, setShowDiagram] = useState(false);
    const [data, setData] = useState({
        idKeyword: null,
        nameKeyword: null,
        positionName: null,
        regionalName: null,
        })
    const [openEdit, setOpenEdit] = useState(false)
    const schema = yup.object().shape({

        idKeyword: yup
        .string()
        .max(16, 'Ingrese máximo 16 caracteres'),
        nameKeyword: yup
        .string(),
        positionName: yup
        .string(),
        regionalName:   yup
        .string()
        
      });
    
  
      
      const { register, setValue, watch, clearErrors, formState:{errors} } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });
      
    useEffect(() => {
        clear()
    }, [props?.clearFielForm])
    
    

    useEffect(()=> {
        clear()
    },[newleader?.editLeader])

    

    const clear = () => {
        setNotData(!notData)
        setPage(null);
        setValue('idKeyword', '')
        setOptionSelected('')
        clearErrors('idKeyword');
        setUserSelected({});
     setData(
        {
            idKeyword: null,
            nameKeyword: null,
            positionName: null,
            regionalName: null, 
        }
        )
        dispatch({
            type: 'ORGANIZATION_CHART',
            changeLeader: false,
            leaderToEdit: organization?.leaderToEdit,
            infoLeader: organization?.infoLeader,
            leaderId: '',
            subalterns: [],
            newSubalterns: [],
            dataDiagram: {},
            page: organization?.page
        })
    }

    const changeModalDiagram = () => setShowDiagram(!showDiagram)

        
          const loadOptions = async (pag) => {

            const result = await getEmployeesSelect({
                identificationKeyword : data.idKeyword === null ? null : data?.idKeyword,
                nameKeyword: data.nameKeyword === null ? null : data?.nameKeyword,
                positionName: data.positionName === null ? null : data?.positionName?.name,
                regionalName: data.regionalName === null ? null : data?.regionalName?.name,
                page: !page ? 0 : page,
                size:  20 === null ? null : 20,
                
            },
            dispatch,
            );

            if(result?.status === 204 ){
                    setPage(null);
                     return {
                        options: [],
                        hasMore: false,
                    };
            } if(result?.status > 204 && result?.status <= 401 ){
                setPage(null);
                 return {
                    options: [],
                    hasMore: false,
                };
        }else {

                setPage(page+1)
                const dataOptions = result?.data?.content?.map(({ uuid, name, secondName, surname, secondSurname, subalterns, typeEvaluator, regional, position, leader   }) => (
                    {
                        label: `${name ? name : ''} ${secondName ? secondName : ''} ${surname ? surname :''} ${secondSurname ? secondSurname :''}`,
                        value: uuid,
                        subalterns: subalterns?.length ? subalterns : [],
                        typeEvaluator: typeEvaluator,
                        regional: regional?.name ?? '',
                        position: position?.name ?? '', 
                        leader: leader ?? ''
                    }
                    ));

                return {
                    options: dataOptions,
                    hasMore: result?.data?.content?.length > 1 ? true : false,
                };
            }
                   
                    
          };

        const blur = (e) => {
            const { value} = e.target;
            setNotData(true)
            setPage(null)
            setOptionSelected('')
            setData({...data, idKeyword: value, });
            setUserSelected({});
            dispatch({
                type: 'ORGANIZATION_CHART',
                changeLeader: false,
                leaderToEdit: organization?.leaderToEdit,
                infoLeader: organization?.infoLeader,
                leaderId: '',
                subalterns: organization?.subalterns,
                newSubalterns: organization?.subalterns,
                dataDiagram: organization?.dataDiagram,
                page: organization?.page
            })
        }

        const onchangeSelect = (option) => {
            const leader =  typeof option?.leader?.uuid === 'string';
            setOptionSelected(option);
            if(leader) {
                setUserSelected({
                
                    id: option?.leader?.uuid,
                    value: {
                        title:  `${option?.leader?.name ?? ''} ${option?.leader?.surname ?? ''}`,
                        items: [
                            { 
                                text: 'Cargo: ',
                                value:  typesEvaluators[option?.leader?.typeEvaluator] ?? ''
                            }
                        ],
                    },
                    children:[
                        {   id: option?.value,
                            value: {
                                title: `${option?.label ?? ''}`,
                                items: [
                                    { 
                                        text: 'Cargo: ',
                                        value: typesEvaluators[option?.typeEvaluator] ?? ''
                                    }
                                ],
                            },
                            children: !option?.subalterns?.length?[]:option?.subalterns?.map((x)=> (
                                {   id: x?.uuid,
                                    value: {
                                        title: `${x?.name ?? ''} ${x?.surname ?? ''}`,
                                        items: [
                                            { 
                                                text: 'Cargo: ',
                                                value: typesEvaluators[x?.typeEvaluator] ?? ''
                                            }
                                        ],
                                    }
                                }
                            ))
                        },
                        
                    ],
                   
                })
            }else {
                setUserSelected({
                
                    id: option?.value,
                    value: {
                        title: `${option?.label ?? ''}`,
                        items: [
                            { 
                                text: 'Cargo: ',
                                value: typesEvaluators[option?.typeEvaluator] ?? ''
                            }
                        ],
                    },
                    children:  option?.subalterns?.map((x)=> (
                        {   id: x?.uuid,
                            value: {
                                title: `${x?.name ?? ''} ${x?.surname ?? ''}`,
                                items: [
                                    { 
                                        text: 'Cargo: ',
                                        value: typesEvaluators[x?.typeEvaluator] ?? ''
                                    }
                                ],
                            }
                        }
                    )) ?? []
                })
            }
           
            dispatch({
                type: 'ORGANIZATION_CHART',
                changeLeader: false,
                leaderToEdit: organization?.leaderToEdit,
                infoLeader: option?.label,
                leaderId: option?.value,
                subalterns: option?.subalterns,
                newSubalterns: option?.subalterns,
                dataDiagram: option,
                page: organization?.page
            })
           
        }



    const openModalEdit = () => {
        setOpenEdit(!openEdit)
        dispatch({
            type: 'SET_FILTER_NEW_LEADER',
            idKeyword: '',
            nameKeyword: '',
            positionName: '',
            regionalName: '',
            leaderId: '',
            subalterns: [],
            editLeader: false
        })
    }
           

    return (
         <form  >
            <Typography style={{fontSize: 14, fontWeight: 400, color: '#4B4B4B', marginBottom: '2%'}}>Seleccione el líder</Typography>
            <Grid container maxWidth="lg" spacing={2}>
                <Grid item lg={props?.edit ? 3 : 2.5}>
                <FormControl variant='outlined' fullWidth size="small">
                    <Autocomplete
                        {...register('regionalName')}
                        options={props?.regionals}
                        autoHighlight
                        noOptionsText="No hay opciones"
                        className={classes.dropdown}
                        size="small"
                        openText='Abrir'
                        closeText='Cerrar'
                        clearText='Borrar'
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => {
                            setValue('regionalName', newValue);
                            setNotData(true)
                            setData({...data, regionalName: newValue})
                            setPage(null)
                            setOptionSelected('')
                            setUserSelected({});
                            dispatch({
                                type: 'ORGANIZATION_CHART',
                                changeLeader: false,
                                leaderToEdit: organization?.leaderToEdit,
                                infoLeader: organization?.infoLeader,
                                leaderId: '',
                                subalterns: organization?.subalterns,
                                newSubalterns: organization?.subalterns,
                                page: organization?.page
                            })
                        }}
                        value={data?.regionalName}
                        renderOption={(props, option) => (
                        <Box component="li" {...props}>
                                {option.name}
                        </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            {...register('regionalName')}
                            placeholder="Programa"
                            // label="Regional"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            />
                        )}
                        
                    />
                </FormControl>
            </Grid>
            <Grid item lg={ props?.edit ? 3 : 3.5}>
                <FormControl variant='outlined' fullWidth size="small">
                    <Autocomplete
                        {...register('positionName')}
                        id="country-select-demo"
                        options={props?.positions}
                        autoHighlight
                        openText='Abrir'
                        closeText='Cerrar'
                        clearText='Borrar'
                        noOptionsText="No hay opciones"
                        className={classes.dropdown}
                        size="small"
                        getOptionLabel={(option) => option?.name }
                        value={data?.positionName}
                        onChange={(e, newValue) => {
                            setOptionSelected('')
                            setValue('positionName', newValue);
                            setNotData(true);
                            setUserSelected({});
                            setData({...data, positionName: newValue})
                            setPage(null)
                            dispatch({
                                type: 'ORGANIZATION_CHART',
                                changeLeader: false,
                                leaderToEdit: organization?.leaderToEdit,
                                infoLeader: organization?.infoLeader,
                                leaderId: '',
                                subalterns: organization?.subalterns,
                                newSubalterns: organization?.subalterns,
                                page: organization?.page
                            })
                          }}
                        renderOption={(props, option) => (
                        <Box component="li" {...props}>
                                {option?.name}
                        </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            {...register('positionName')}
                            placeholder="cargo"
                            // label="cargo"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            
        <Grid item lg={ 2}>
                <FormControl variant='outlined' fullWidth size="small">
                    {/* <InputLabel htmlFor="outlined-adornment-password" >Password</InputLabel> */}
                        <OutlinedInput
                            {...register('idKeyword')}
                            type='text' 
                            className={classes.dropdown}
                            placeholder="Identificación"
                            notched={false}
                            onBlur={(e)=>blur(e)}
                            inputProps={{
                                maxlength: 16
                            }}
                            error={!!errors.hasOwnProperty("idKeyword") && errors["idKeyword"].message}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setValue('idKeyword', '');
                                    setNotData(true)
                                    setData({...data, idKeyword: ''})
                                    setPage(null)
                                }}
                                edge="end"
                                >
                                { watch('idKeyword') ? <CloseIcon style={{fontSize: 17}}/> : null }
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Password"
                        />
                         <FormHelperText style={{color:'#d32f2f', marginLeft: '2%'}}>
                            {errors.hasOwnProperty("idKeyword") &&
                                errors["idKeyword"].message}
                        </FormHelperText>
                </FormControl>
            </Grid>
        <Grid item lg={props?.edit ? 4 :3}>
            <FormControl variant='outlined' fullWidth size="small">
                 <AsyncPaginate
                    loadOptions={loadOptions}
                    placeholder="Seleccione el Líder"
                    tabSelectsValue={true}
                    onChange={onchangeSelect}
                    onInputChange={(e)=> {setPage(null); setData({...data, nameKeyword: e})} }
                    blurInputOnSelect={true}
                    cacheUniqs={[data]}
                    value={optionSelected}
                    cacheOptions
                    loadingMessage={() => 'Cargando...'}
                    noOptionsMessage={() => 'No hay coincidencias'}
                    onMenuOpen={()=> { setPage(null);  setData({...data, nameKeyword: null})}}
                />
                        
            </FormControl>
        </Grid>
        { props?.edit ? null :<Grid item lg={1} xs={2} style={{paddingTop: '2%'}}>
            <div className={classes.columnEvent} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                { userSelected?.id &&
                    <Tooltip title="Ver Diagrama" arrow >
                        <VisibilityIcon
                            onClick={() => changeModalDiagram()}
                            color='action'
                            className={classes.iconEvent}
                        />
                    </Tooltip>
                }
                &nbsp;&nbsp;
                <Tooltip title="Limpiar" arrow >
                  <DeleteIcon
                    onClick={() => clear()}
                    color='action'
                    className={classes.iconEvent}
                  />
                </Tooltip>
                &nbsp;&nbsp;
              {
                organization?.subalterns?.length ?
                <Tooltip title="Editar" arrow >
                    <EditIcon
                    onClick={() => openModalEdit()}
                    color='action'
                    className={classes.iconEvent}
                    />
              </Tooltip>
              : null
              } 
            </div>
          </Grid>
        }          
    </Grid>
    {/* { userSelected?.id && <DemoOrganizationGraph data={userSelected} /> } */}
        <ModalEditLeader 
            open={openEdit}
            handleClose={openModalEdit}
            data={
                {name: organization?.infoLeader, 
                regional: data?.regionalName?.name, 
                userId: data?.idKeyword, 
                position: data?.positionName?.name
                }}
            regionals={props?.regionals} 
            positions={props?.positions}   
        />
        < ModalDiagram 
            open={showDiagram} 
            children={<DemoOrganizationGraph data={userSelected} />}
            handleClose={changeModalDiagram}    
        />
        </form>
    )
}

export default SearchCompetencies
