import HttpClient from '../services/HttpClient';
import { QUESTIONARY, APLICATION, USERS } from '../constants'


export const createAplication = (id, body) => {
   
    return new Promise((resolve, reject) => {
        HttpClient.post(`${QUESTIONARY}/${id}${APLICATION}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    });
}


export const getfilterAplications = (dataFilter, dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_LOADING',
        active: true
      })
    return new Promise((resolve, reject) => {
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

        const keys = ['createdAtStartDate', 'createdAtEndDate', 'questionnaireNameKeyword' ];

        for (let index = 0; index < keys?.length; index++) {
          if(dataFilter[keys[index]]){
            filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
          } else {
              continue
          }
        }
        HttpClient.get(`${QUESTIONARY}${APLICATION}${filters}`).then(response => {
            resolve(response);
            dispatch({
                type: 'SET_ACTIVE_LOADING',
                active: false
              })
        }).catch(error => {
            dispatch({
                type: 'SET_ACTIVE_LOADING',
                active: false
              })
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getAllUsers = (rol) => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${USERS}?role=${ rol?.rol ? rol?.rol : 'EVALUATED'}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getAllUsersWithOutRol = () => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${USERS}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}