import React,{ useState, useEffect } from 'react';
import {
    Button,
    Grid,
    FormControl,
    TextField,
    Typography,
    OutlinedInput,
    InputAdornment,
    InputLabel,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {styles} from './styles';
import ModalConfirm from "../../../../../../utils/form/modalConfirmBase";
import { getRegionals, getPositions } from '../../../../../../actions/OrganizationChart-action';
import { useStateValue } from "../../../../../../context/store";
import FilterLeader from "./newLeader/index";
import _ from "lodash";


export default function AlertDialog(props) {
    const classes = styles()
    const [{ newleader, typesEvaluations }, dispatch] = useStateValue();
    const [allRegionals, setAllRegionals] = useState([]);
    const [allPositions, setAllPositions] = useState([]);
    const [message, setMessage] = useState({
        description : '',
        textButtonSubmit : '',
        handleClick : () => {} ,
        handleClickOut : () => {},
        oneButtons : false,
        title: '',
        type : '',
        open : false
      })


      useEffect(()=> {
        getRegional();
        getPosition();
      },[])

      
    const closeModal = () => setMessage({...message, open: false})
    const submitData = ()=> {

      return setMessage({
        open : true,
        description: '¿Desea modificar el evaluador líder?',
        handleClick : () => changeLeader() ,
        type : 'WARNING',
        handleClickOut: () => closeModal(),
      })
   
    }

    const getRegional = async() => {
      const result = await getRegionals();  
      setAllRegionals(result?.data?.length ? result?.data : [] )
    }

    const getPosition = async() => {
      const result = await getPositions();  
      setAllPositions(result?.data?.length ? result?.data : [] )
    }

 

const changeLeader = () => {
  
  const findUser = typesEvaluations?.users?.find((x)=> x?.uuid === props?.currentLeader?.uuid);
  const differentsUsers = typesEvaluations?.users?.filter((x)=> x?.uuid !== props?.currentLeader?.uuid);


  const newUser = {...findUser, pos: undefined ? 0 : findUser?.pos, leader: {
    identificationNumber: newleader?.leaderId?.identificationNumber,
    name: newleader?.leaderId?.name,
    position: newleader?.leaderId?.position,
    regional: newleader?.leaderId?.regional ,
    secondSurname: newleader?.leaderId?.secondSurname,
    subalterns: newleader?.leaderId?.subalterns,
    surname: newleader?.leaderId?.surname,
    uuid: newleader?.leaderId?.uuid
  }}

  differentsUsers.push(newUser);
  dispatch({
    type: "SET_TYPES_EVALUATIONS",
    automatic: typesEvaluations?.automatic,
    applicationTypes: typesEvaluations?.applicationTypes,
    submitPercentage: typesEvaluations?.submitPercentage,
    users: _.orderBy(differentsUsers, ['pos'],['asc']),
    selectAll: typesEvaluations?.selectAll,
    dataEvaluation: typesEvaluations?.dataEvaluation,
    pages: typesEvaluations?.pages,
    selectNewLeaders: typesEvaluations?.selectNewLeaders
  });
  closeModal();
  props?.handleClose();
  }

 


  return (
    <div >
      <Dialog
       className={classes.modalBank}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" 
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleModalBank}>
         Cambio de Líder
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{marginTop: 10}}>
            <Typography style={{fontSize: 14, fontWeight: 400, color: '#4B4B4B', marginBottom: '2%'}}>Líder anterior</Typography>
            <Grid container spacing={2}>
                <Grid item lg={3}>
                    <FormControl variant='outlined' fullWidth size="small">
                      <InputLabel>Programa</InputLabel>
                        <OutlinedInput
                            type='text' 
                            value={props?.data?.regional?.name}
                            disabled
                            className={classes.dropdown}
                            placeholder="Programa"
                            notched={true}
                            endAdornment={
                            <InputAdornment position="end" >
                                { <ArrowDropDownIcon style={{fontSize: 20}}/>  }
                            </InputAdornment>
                            }
                            label="Programa"
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={3}>
                    <FormControl variant='outlined' fullWidth size="small">
                      <InputLabel>Cargo</InputLabel>
                        <OutlinedInput
                            type='text' 
                            value={props?.data?.position?.name}
                            disabled
                            className={classes.dropdown}
                            placeholder="Cargo"
                            notched={true}
                            endAdornment={
                            <InputAdornment position="end">
                                { <ArrowDropDownIcon style={{fontSize: 20}}/>  }
                            </InputAdornment>
                            }
                            label="Cargo"
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={2}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <TextField
                        className={classes.dropdown}
                        fullWidth
                        type="text"
                        label="Identificación"
                        placeholder="Identificación"
                        disabled
                        value={props?.data?.identificationNumber}
                        size="small"
                    />
                    </FormControl>
                </Grid>
                <Grid item lg={4}>
                    <FormControl variant='outlined' fullWidth size="small">
                      <InputLabel>Nombre</InputLabel>
                        <OutlinedInput
                            type='text' 
                            value={`${props?.data?.name ? props?.data?.name : ''} ${props?.data?.surname ? props?.data?.surname : ''}`}
                            disabled
                            className={classes.dropdown}
                            placeholder="Identificación"
                            notched={true}
                            endAdornment={
                            <InputAdornment position="end">
                                { <ArrowDropDownIcon style={{fontSize: 20}}/>  }
                            </InputAdornment>
                            }
                            label="Nombre"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item lg={12} style={{marginTop: '2%'}}>
                <Typography style={{fontSize: 14, fontWeight: 400, color: '#4B4B4B'}}>Líder nuevo</Typography>
                <FilterLeader 
                    edit={true}  
                    regionals={[...allRegionals]} 
                    positions={[...allPositions]}
                    setMessage={setMessage}
                    message={message}
                    currentLeader={props?.currentLeader}
                />
            </Grid>
            <React.Fragment >
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '16%'}}>
                <Button variant="outlined"  
                  disableElevation 
                  onClick={()=>props?.handleClose()}
                  style={{ color: "#6D9FFF", marginBottom: 5, borderColor: '#80808070' }} 
               
                >
                VOLVER
                </Button>
                    &nbsp;&nbsp;
                <Button
                    disabled={!newleader?.leaderId}
                  onClick={() => submitData()}
                  variant="contained"
                  style={{ color: "#ffffff", background: !newleader?.leaderId ? '#C0C0C0' : '#2F71CB', marginBottom: 5, marginLeft: 5 }}
                >
                    ACEPTAR
                </Button>
                </div>
               </React.Fragment>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
        </DialogActions>
      </Dialog>
      <ModalConfirm 
            description = { message.description }
            textButtonSubmit = { message.textButtonSubmit }
            handleClick = { message.handleClick }
            handleClickOut = { message.handleClickOut }
            oneButtons = { message.oneButtons }
            title = { message.title }
            type = { message.type }
            open = { message.open }
        />
    </div>
  );
}
