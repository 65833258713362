import React,{useState} from "react";
import { makeStyles } from '@mui/styles';
import { withRouter } from "react-router-dom";
import { useStateValue } from "../../context/store";
import ModalConfirm from "../../utils/form/modalConfirmBase";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { PATH_APP } from "../../constants";
 


const useStyles = makeStyles((theme) => ({
  appBar: {
    minHeight: 52,
    marginTop: 42,
    boxShadow: "10px 5px 5px transparent",
    backgroundColor: "#fff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  appBarDown: {
    minHeight: 52,
    marginTop: 42,
    boxShadow: "10px 5px 5px transparent",
    backgroundColor: "transparent",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  appBarClose: {
    backgroundColor: "#fff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShiftClose: {
    marginLeft: '20%',
    width: "3%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShift: {
    marginLeft: '20%',
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    minHeight: 80,
    marginLeft: "5%",
    marginRight: '5%',
    display: "flex",
    justifyContent: "space-between",
    background: "#fff",
    alignItems: "flex-end",
    marginBottom: 5,
    position: 'relative'
  },
  toolbarDown: {
    minHeight: 80,
    marginLeft: "5%",
    marginRight: '5%',
    display: "flex",
    justifyContent: "space-between",
    background: "transparent",
    alignItems: "flex-end",
    marginBottom: 5,
    position: 'relative'
  },
  toolbarIconsContainer: {
    display: "flex",
    alignItems: "center",
    color: "white",
    marginTop: 3
  },
  menuButton: {
    marginLeft: "-20px!important",
  },
  hide: {
    display: "none",
  },
  avatar: {
    marginLeft: ".6em",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  icons: {
    color: "white",
  },
  linkNav: {
    marginLeft: "24px",
    color: "#f7f7f7",
    fontSize: "12px",
    textDecoration: "none",
  },
}));

const SubHeader = ({ history }) => {
  const classes = useStyles();

  const [{ breadCrumb, answerQuestionary, questionSearch, managementAplicationEvaluation, answerEvaluation, evaluationPerformanceSearch, questionarySearch, relations, aplicationQuestionary,criteriaSearch, competencieSearch }, dispatch] = useStateValue();
  const [changeSubHeader] = useState(false);
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    textButtonCancel : '',
    oneButtons : false,
    title: '',
    type : '',
    open : false
  });

  const changeOpenModal = () => {
    dispatch({
      type: "SET_MODAL",
      openCreateTag: true
    });
  }

  const dowload = () => dispatch({
    type: "SET_BREADCRUMB",
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Resultado General",
    index: 13,
    module: 'DETAIL_APLICATION_EVALUATION',
    dowload: true, 
    disabledDowload: true,
    textDowload: 'Descargar archivo',
    subPath: `${PATH_APP}/management-aplication-evaluations`,
  })
  
  
  
  const redirect = () => {
    if(breadCrumb?.module === 'EVALUATION_PERFORMANCE'){
       dispatch({
        type: 'SET_RELATIONS_EVALUATION',
        sectionSelected: relations?.sectionSelected,
        dataSections: relations?.dataSections,
        data: relations?.data,
        behaviorSelected: relations?.behaviorSelected,
        competencieSelected: relations?.competencieSelected,
        backToForm: true
    
    })
    history.push(`${breadCrumb?.subPath}`)
    }
    if(breadCrumb?.module === 'CRITERIA_EVALUATION'){
      dispatch({
        type: "SET_FILTER_CRITERIA",
        filter: criteriaSearch?.filter,
        isFilter: true,
        typeAction: 'APLICATION'
      })
      history.push(`${breadCrumb?.subPath}`)
    } 
     if(breadCrumb?.module === 'DETAIL_APLICATION'){
      dispatch({
        type: "SET_FILTER_APLICATION",
        filter: aplicationQuestionary?.filter,
        isFilter: true,
        typeAction: 'APLICATION'
      })
      history.push(`${breadCrumb?.subPath}`)
    } if(breadCrumb?.module === 'ANSWER_QUESTIONARY'){
      dispatch({
        type: "FILTER_QUESTIONARY_ANSWER",
        filter: answerQuestionary?.filter,
        isFilter: true,
      })
      history.push(`${breadCrumb?.subPath}`)
    }else if(breadCrumb?.module === 'MANAGE_QUESTIONNAIRE'){
      dispatch({
        type: "SET_FILTER_QUESTIONARY",
        filter: questionarySearch?.filter,
        isFilter: true,
        typeAction: 'GESTIONAR'
      })
      history.push(`${breadCrumb?.subPath}`)
    }
    else if(breadCrumb?.module === 'SET_FILTER_QUESTION'){
      dispatch({
        type: "SET_FILTER_QUESTION",
        filter: questionSearch?.filter,
        isFilter: true,
        typeAction: 'GESTIONAR'
      })
      history.push(`${breadCrumb?.subPath}`)
    }
    else if(breadCrumb?.module === 'MANAGE_COMPETENCIES'){
      dispatch({
        type: "SET_FILTER_COMPETENCIE",
        filter: competencieSearch?.filter,
        isFilter: true,
        typeAction: 'ANSWER'
      })
      history.push(`${breadCrumb?.subPath}`)
    }else if(breadCrumb?.module === 'MANAGE_BEHAVIOR'){

      dispatch({
        type: "SET_FILTER_ANSWER_EVALUATION",
        filter: answerEvaluation?.filter,
        isFilter: true,
        typeAction: 'GO_BACK'
      })
      history.push(`${breadCrumb?.subPath}`)

    }else if(breadCrumb?.module === 'EVALUATION_EVALUATED'){
      dispatch({
        type: "SET_FILTER_ANSWER_EVALUATION",
        filter: answerEvaluation?.filter,
        isFilter: true,
        typeAction: 'GO_BACK'
      })
      history.push(`${breadCrumb?.subPath}`)
    }else if(breadCrumb?.module === 'DETAIL_APLICATION_EVALUATION'){
      dispatch({
        type: "SET_FILTER_MANAGEMENT_APLICATION_EVALUATION",
        filter: managementAplicationEvaluation?.filter,
        isFilter: true,
        typeAction: 'GO_BACK'
      })
      history.push(`${breadCrumb?.subPath}`)
    }else if(breadCrumb?.module === 'RESULT_APLICATION_EVALUATION'){
      dispatch({
        type: "SET_FILTER_ANSWER_EVALUATION",
        filter: answerEvaluation?.filter,
        isFilter: true,
        typeAction: 'GO_BACK'
      })
      history.push(`${breadCrumb?.subPath}`)
    }if(breadCrumb?.module === 'EVALUATION_EVALUATOR'){
      dispatch({
        type: "SET_FILTER_EVALUATION_PERFORMANCE",
        filter: evaluationPerformanceSearch?.filter,
        isFilter: true,
        typeAction: 'GO_BACK'
      })
      dispatch({
        type: 'SET_RELATIONS_EVALUATION',
        data: [],
        dataSections: []
    })
    dispatch({
      type: "SET_DATA_QUESTIONNAIRE",
      data: {}
  })
  dispatch({
    type: "SET_VALORATE_APLICATION",
    disabledValors: false,
    typeValoration: '',
    data: {}
})
      history.push(`${breadCrumb?.subPath}`)
   }else {
      history.push(`${breadCrumb?.subPath}`)
    }
  }

  const closeModal = () => setMessage({...message, open:false})
  const comfirmBackTo = () => {
    setMessage({
      open : true,
      description: '¿Está seguro que desea salir del formulario?',
      handleClick : () => backTo() ,
      handleClickOut: () => closeModal(),
      textButtonCancel: 'CANCELAR',
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
  }

  const backTo = () => {
   if(breadCrumb?.module === 'EVALUATION_EVALUATOR'){
      dispatch({
        type: "SET_FILTER_EVALUATION_PERFORMANCE",
        filter: evaluationPerformanceSearch?.filter,
        isFilter: true,
        typeAction: 'GO_BACK'
      })
      dispatch({
        type: 'SET_RELATIONS_EVALUATION',
        data: [],
        dataSections: []
      })
      dispatch({
        type: "SET_DATA_QUESTIONNAIRE",
        data: {}
      })
      dispatch({
        type: "SET_VALORATE_APLICATION",
        disabledValors: false,
        typeValoration: '',
        data: {}
      })
      history.push(`${breadCrumb?.subPathSearch}`)
   }else {
      history.push(`${breadCrumb?.subPathSearch}`)
    }
    closeModal()
  }
  
  
  return (
    <AppBar id="SubheaderComponent" className={changeSubHeader ? classes.appBarDown :classes.appBar}>
      {changeSubHeader ? null :
      <section>
        <Toolbar id="toolbarComponent" className={classes.toolbar}>
          <div className={classes.toolbarIconsContainer}>
            <Typography style={{ fontSize: "20px", color: "#2F71CB", fontFamily: 'Roboto', fontWeight: 'bold', }}>
    
              {breadCrumb?.breadCrumbSubText}

            </Typography>
          </div>
          <div className={classes.toolbarIconsContainer}>
            {breadCrumb?.path && (
              <Button
                onClick={() => history.push(`${breadCrumb?.path}`)}
                // icon={NoteAddIcon}
                // text="Crear"
                variant="contained"
                style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 25 }}
              >{breadCrumb?.textIsSubmit ? breadCrumb?.textIsSubmit : 'CREAR'}</Button>
            )}
            {
              breadCrumb?.threeButton && (
                  <Button
                    variant="outlined"
                    onClick={() => comfirmBackTo()}
                    style={{ color: "#6D9FFF", marginBottom: 5, marginRight: 15, borderColor: '#80808070' }}
                  >
                    CANCELAR
                  </Button>
              )
            }
            {breadCrumb?.subPath && (
              <Button
                variant="outlined"
                onClick={() => redirect()}
                style={{ color: "#6D9FFF", marginBottom: 5, borderColor: '#80808070' }}
              >
                Volver
              </Button>
            )}
            {breadCrumb?.isSubmit && (
              <Button
                color="primary"
                type="submit"
                disabled={breadCrumb?.disabledSubmit}
                form={breadCrumb.idForm }
                variant="contained"
                style={{
                  backgroundColor: breadCrumb?.disabledSubmit ? '#E0E0E0':"#2F71CB",
                  color: "#fff",
                  marginBottom: 5,
                  marginLeft: 15,
                }}
              >
              {breadCrumb?.textIsSubmit ? breadCrumb?.textIsSubmit : 'Guardar'}
              </Button>
            )}
            {breadCrumb?.openModal && (
                <Button
                variant="contained"
                onClick={changeOpenModal}
                style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 25 }}
              >
                {breadCrumb?.textIsSubmit ? breadCrumb?.textIsSubmit : 'Guardar'}
              </Button>
            )}
            {breadCrumb?.textDowload && (
                <Button
                variant="contained"
                onClick={() => dowload()}
                disabled={breadCrumb?.disabledDowload}
                style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 25 }}
              >
                {breadCrumb?.textDowload ? breadCrumb?.textDowload : 'Descargar archivo'}
              </Button>
            )}
          </div>

        </Toolbar>
        </section>
      }
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        textButtonCancel = { message.textButtonCancel }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
    </AppBar>
  );
};

export default withRouter(SubHeader);
