import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Modal } from "@mui/material";
import Evaluation from "../../../../../performanceEvaluation/evaluated/answerEvaluation/evaluation";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: "90%",
    maxHeight: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.24)",
    outline: "none",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
    display: "flex",
    flexDirection: "column",
  },
  containerDialog: {
    overflow: "auto",
    paddingRight: "1rem",
  },
  titleAlert: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#2C2C2C",
  },
  confirmBanck: {
    color: "#ffffff",
    background: "#2F71CB",
    marginTop: "1rem",
    width: "fit-content",
    "&:hover": {
      color: "#ffffff",
      background: "#2F71CB",
    },
  },
}));

const ModalReviewRol = ({ open, currentData, handleClick }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const bodyModal = (
    <div style={modalStyle} className={classes.paper}>
      <h1 className={classes.titleAlert}>
        {currentData?.performanceEvaluation?.roleName}
      </h1>
      <div className={classes.containerDialog}>
        {open ? <Evaluation dataDetail={currentData} disabled={true}/> : null}
      </div>
      <div>
        <Button
          className={classes.confirmBanck}
          variant="outlined"
          onClick={handleClick}
        >
          ACEPTAR
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
};

export default ModalReviewRol;
