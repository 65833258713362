import React from 'react';
import {
    Grid,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel
} from "@mui/material";

const Index = (props) => {
  const checkbox = () => {
    if(props?.valuesRanking?.length){

      const lastPosition = props?.valuesRanking.slice(0)[0];
      const otherPositions = props?.valuesRanking?.slice(1,props?.valuesRanking?.length);
      lastPosition && otherPositions?.push(lastPosition) 

     return <Grid item container  lg={12} justifyContent="center">
        <FormControl >
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue="top"
          >
            {otherPositions?.map( (x, index) => (
              <FormControlLabel
                value={`description${index+1}`}
                control={<Radio  sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 35,
                  },
                }}/>}
                style={{marginLeft: index === 3 ? 50 : ''}}
                className='labelCheckbox'
                label={x?.key}
                labelPlacement="top"
                checked={x?.selected}
                disabled
              />
            ))}
           
          </RadioGroup>
        </FormControl>      
      </Grid>
    }
  
  }

  if(props?.valuesRanking?.length){
    return  checkbox()
  }else {
    return null
  }
 
};

export default Index;

