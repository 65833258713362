import React,{useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ModalTable from "../evaluationLeader/addLeaders/modalTable";
import ModalEditLeader from "../evaluationLeader/addLeaders/modalEditLeader";

import {styles} from "./styles";
import _ from "lodash";
import { Grid, Typography,  Button } from '@mui/material';
import UsersIntitutional from "./components/Intitutional";
import Students from "./components/student";
import { useStateValue } from './../../../../context/store';

import { typesEvaluators } from '../../../../constants';

export default function StickyHeadTable() {
  const classes = styles();
  // Utilizamos useState para controlar si un registro está expandido o no
  const [expanded, setExpanded] = useState(null);
  const [{typesEvaluations}, dispatch] = useStateValue();
  const [evaluationPeer, setEvaluationPeer] = useState();
  const [currentSubalterns, setCurrentSubalterns] = useState([])
  const [idLeader, setIdLeader] = useState();
  const [sectionId, setSectionId] = useState();
  const [, setIdEdit] = useState();
  const [idSelect, setIdSelect] = useState();
  const [numberUsers, setNumberUsers] = useState(0);
  const [dataEdit, setDataEdit] = useState({});
  const [findType, setFindType] = useState({});
  const [rows] = useState([5,10,15])
  const [dataLeader, setDataLeader] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    textButtonCancel: '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    list: [],
    type : '',
    open : false
  });
  const [messageEdit, setMessageEdit] = useState({
    description : '',
    textButtonSubmit : '',
    textButtonCancel: '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    list: [],
    type : '',
    open : false
  })
  

  useEffect(() => {
    setFindType(typesEvaluations?.applicationTypes.find((x)=> x?.type === 'PEER_EVALUATION'))
  }, [typesEvaluations])

  useEffect(()=> {
    setNumberUsers(typesEvaluations?.users?.length)
  },[]);

  useEffect(() => {
    if(typesEvaluations?.users?.length === numberUsers && typesEvaluations?.applicationTypes.find((x)=> x?.type === 'PEER_EVALUATION')){
      return null
    } else {
      if(typesEvaluations?.users?.length < numberUsers && !typesEvaluations?.selectAll){
        setNumberUsers(typesEvaluations?.users?.length)
      }else if( typesEvaluations?.users?.length > numberUsers  && !typesEvaluations?.selectAll){
        addOneUser()
        setNumberUsers(typesEvaluations?.users?.length)
      }

    }
  
   }, [typesEvaluations?.users?.length])

   const openModalEdit = (x,l) => {setMessageEdit({...messageEdit, open: true}); setDataEdit(x); setDataLeader(l)}
   const closeModalEdit = () => setMessageEdit({...messageEdit, open: false})
   const closeWarning = () => setMessage({...message, open: false})
   const closeTable = () => setMessage({...message, open: false});
   const handleChangePage = (event, newPage) => {
    setExpanded(null)
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleExpandClick = (index) => {
    if (expanded === index) {
        setExpanded(null);
      } else {
        setExpanded(index);
      }
  };

  const findSelecteType = (t) => {
    const result = typesEvaluations?.applicationTypes?.find((x)=> x?.type === t)
    return result ? true : false
  }

  const handleOnchange = (e) => {
    const { value}= e.target;
    setIdSelect(value)
  }

  const openModal = (x, lead) => {
    setMessage({...message, open: true});
    setIdLeader(x);
    dispatch({
      type: "SET_TYPES_EVALUATIONS",
      automatic: typesEvaluations?.automatic,
      applicationTypes: typesEvaluations?.applicationTypes,
      submitPercentage: typesEvaluations?.submitPercentage,
      users: typesEvaluations?.users,
      selectAll: typesEvaluations?.selectAll,
      dataEvaluation: typesEvaluations?.dataEvaluation,
      pages: typesEvaluations?.pages,
      selectNewLeaders: lead?.length ? lead : []
    });
};

const addOneUser = () => {
  typesEvaluations.users = typesEvaluations?.users.map(value => ({
    ...value,
    students: value?.subalterns ?? [],
  }));  
  const userAdded = typesEvaluations?.users[typesEvaluations?.users?.length-1]
  const findType = typesEvaluations?.applicationTypes?.find((x)=> x?.type === 'PEER_EVALUATION');
  const differentsTypes = typesEvaluations?.applicationTypes?.filter((x)=> x?.type !== 'PEER_EVALUATION');
  const indexMajor = _.maxBy(findType?.employees, (p) => p?.pos )
  const findUser = findType?.employees?.find((x)=> x?.evaluated?.uuid === userAdded?.uuid)
  if(!findType){
    return null
  }else {

  if(findUser){
    return null
  }else{
  //nueva logica
    // 1- buscar el usuario() recien agregado ya esta en la varible userAdded
    // 2-filtrar los usuarios diferentes al usuario agregado
    // const differentsUsers = typesEvaluations?.users?.filter((u)=> u?.uuid !== userAdded);
    // 3- agregarle al usuario agregado los estudiantes
    // userAdded.students = userAdded?.subalterns ?? [];
    // 4- agregamos el usuario con sus estiantes añadidos a la lista de usuarios
    // differentsUsers.push(userAdded)
  const differentUser =  _.filter(userAdded?.leader?.subalterns, (x)=> x?.uuid !== userAdded?.uuid);
    let random = Math.random();
    random = random * differentUser?.length + 0;
    random = Math.trunc(random);

    let updateType = {}
    if(findType?.employees?.length){
      findType?.employees?.push(
        {
          evaluator: differentUser[random], 
          evaluated: userAdded, 
          pos: typeof indexMajor !== 'object' ? 0 : indexMajor?.pos +1 
        }
      )
      differentsTypes.push(findType)
    } else {
      updateType = {...findType, 
        students: userAdded?.subalterns ?? [],
        employees: [{evaluator: differentUser[random], evaluated: userAdded, pos: typeof indexMajor !== 'object' ? 0 : indexMajor?.pos +1 }]}
      differentsTypes.push(updateType)
      
    }

    dispatch({
      type: "SET_TYPES_EVALUATIONS",
      automatic: typesEvaluations?.automatic,
      applicationTypes: differentsTypes,
      selectAll: typesEvaluations?.selectAll,
      submitPercentage: typesEvaluations?.submitPercentage,
      users: typesEvaluations?.users,
      dataEvaluation: typesEvaluations?.dataEvaluation,
      pages: typesEvaluations?.pages,
      selectNewLeaders: typesEvaluations?.selectNewLeaders
    });
  }
}
 }


// useEffect(() => {
//   const dataLocalStorage = {
//     automatic: jsonTypes?.automatic,
//     applicationTypes: typesEvaluations?.applicationTypes,
//     submitPercentage: jsonTypes?.submitPercentage,
//     selectAll: jsonTypes?.selectAll,
//     users: typesEvaluations?.users?.length ? typesEvaluations?.users : [],
//     dataEvaluation: jsonTypes?.dataEvaluation,
//     pages: jsonTypes?.pages,
//     selectNewLeaders: jsonTypes?.selectNewLeaders
//   }
//   localStorage.setItem('typesEvaluation', JSON.stringify(dataLocalStorage))

// }, [typesEvaluations])


  const deleteUser = (major, leaderOption) => {

    const findUser = typesEvaluations?.users?.find((x)=> x?.uuid === major);
    const differentsUsers = typesEvaluations?.users?.filter((x)=> x?.uuid !== major);

    const differentsOptions = findUser?.newLeaders?.filter((x)=> x?.uuid !== leaderOption)
    const newUser = {...findUser, newLeaders: differentsOptions };
    differentsUsers?.push(newUser)

    dispatch({
    type: "SET_TYPES_EVALUATIONS",
    automatic: typesEvaluations?.automatic,
    applicationTypes: typesEvaluations?.applicationTypes,
    submitPercentage: typesEvaluations?.submitPercentage,
    users: _.orderBy(differentsUsers, ['pos'],['asc']),
    selectAll: typesEvaluations?.selectAll,
    dataEvaluation: typesEvaluations?.dataEvaluation,
    pages: typesEvaluations?.pages,
    selectNewLeaders: []
  });
 }

 const saveUser = (evaluated) => {

  //busco el tipo de evaluacion
  const differentsType = typesEvaluations?.applicationTypes.filter((x)=> x?.type !== 'PEER_EVALUATION');
  //encuentro el usuario evaluado
  const findPeer = findType?.employees?.find((x)=> x?.evaluated?.uuid === evaluated);
  //busco el lider y su lista de subalterns
  const findEvaluated = findPeer?.evaluated?.leader?.subalterns?.find((x)=> x?.uuid === idSelect);
  const differentsPeer = findType?.employees?.filter((x)=> x?.evaluated?.uuid !== evaluated);


  const validateLeader = typesEvaluations?.users?.find((x)=> x?.uuid === evaluated)
  if(validateLeader?.leader?.uuid === idSelect){
    return setMessage({
      open : true,
      description: 'El evaluador par no puede ser también evaluador líder en la misma iteración.',
      handleClick : () => closeWarning() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
  }
 const newPeer = {...findPeer, evaluator: findEvaluated}
 differentsPeer.push(newPeer)
 
 differentsType.push({...findType, employees: _.orderBy(differentsPeer, ['pos'],['asc']) })
 dispatch({
  type: "SET_TYPES_EVALUATIONS",
  automatic: typesEvaluations?.automatic,
  applicationTypes: differentsType,
  submitPercentage: typesEvaluations?.submitPercentage,
  users: typesEvaluations?.users,
  dataEvaluation: typesEvaluations?.dataEvaluation,
  pages: typesEvaluations?.pages,
  selectNewLeaders: typesEvaluations?.selectNewLeaders
});
  setSectionId('')
  setIdSelect('')
  setCurrentSubalterns([])
}

  useEffect(()=> {
    const typePeer = typesEvaluations?.applicationTypes?.find((x)=> x?.type === 'PEER_EVALUATION')
    setEvaluationPeer(typePeer?.employees ? typePeer?.employees : [])
    
   },[typesEvaluations])

   const filterStudentByRegional = (regionalUuid, codeSubject, students) => {
    return students?.length? students?.filter(value => value.regional.uuid === regionalUuid)
    ?.filter((x)=> x?.position?.group === codeSubject): students;
    
   }


  return (
    <Grid container style={{marginTop: '4%'}}>
      <Grid item lg={11} style={{display: 'flex', flexDirection: 'column'}}>
      <Typography style={{fontSize: 18, fontWeight: 500, color: 'rgb(75, 75, 75)', marginBottom: '1%'}}>
        A continuación se muestra la lista de los evaluados seleccionados, así como los evaluadores asignados para cada uno:
      </Typography>
      <Typography style={{fontSize: 14, fontWeight: 400, color: 'rgb(75, 75, 75)'}}>
        {`
        ${evaluationPeer?.length ? '- Los evaluadores estudiantes han sido asignados automáticamente por el sistema, tomando los estudiantes que cursan las asignaturas dictadas por los evaluados. Si se requiere, se pueden remover o añadir evaluadores estudiantes.' : ''}
        `}
      </Typography >
      <br/>
      <Typography style={{fontSize: 14, fontWeight: 400, color: 'rgb(75, 75, 75)'}}>{`
        ${findSelecteType('LEADER_EVALUATION') ? '- Los evaluadores institucionales han sido asignados automáticamente por el sistema, considerando como institucional los directores y/o coordinadores de los programas de las asignaturas dictadas por los evaluados. Sin embargo, en caso de ser necesario, se pueden remover o añadir evaluadores institucionales.': ''}
        `}
      </Typography>
      </Grid>
    <Paper sx={{ width: '100%', marginTop: '4%', borderRadius: 5}}>
      <TableContainer style={{borderRadius: 30}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.wrapper}>Nombre</TableCell>
              <TableCell className={classes.wrapper}>Identificación</TableCell>
              <TableCell className={classes.wrapper}>Cargo</TableCell>
              <TableCell className={classes.wrapper}>Programa</TableCell>
              <TableCell className={classes.wrapper}></TableCell>
            </TableRow> 
          </TableHead>
          <TableBody>
          {_.orderBy(typesEvaluations?.users, ['name', 'surname'],["asc", "asc"])?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell width={`20%`} className={classes.header}>{`${user?.name ? user?.name : ''} ${user?.surname ? user?.surname : '' }` }</TableCell>
                <TableCell width={`20%`} className={classes.header}>{user?.identificationNumber}</TableCell>
                <TableCell width={`20%`} className={classes.header}>{typesEvaluators[user?.typeEvaluator] ?? ''}</TableCell>
                <TableCell width={`20%`} className={classes.header}>{user?.regional?.name}</TableCell>
                <TableCell width={`20%`}>
                  <Button
                    color='primary'
                    variant="contained"
                    style={{ cursor: 'pointer', fontSize: 12 }}
                    onClick={() => handleExpandClick(index)}
                  >
                    VER EVALUADORES
                  </Button>
                </TableCell> 
              </TableRow>
              {expanded === index &&  (
                <>
                {(findSelecteType("LEADER_EVALUATION") || findSelecteType("SELF_EVALUATION")) &&
                    <UsersIntitutional 
                        index={ index }
                        user={user} 
                        evaluationPeer={evaluationPeer}
                        findSelecteType={ findSelecteType }
                        openModalEdit={ openModalEdit }
                        handleOnchange={handleOnchange}
                        currentSubalterns={currentSubalterns}
                        sectionId={sectionId}
                        setSectionId={setSectionId}
                        setIdSelect={setIdSelect}
                        idSelect={idSelect}
                        saveUser={saveUser}
                        setCurrentSubalterns={setCurrentSubalterns}
                        setIdEdit={setIdEdit}
                        openModal={openModal}
                        deleteUser={deleteUser}

                    />
                }
                    {(findSelecteType("PEER_EVALUATION")  && user?.subalterns?.length && filterStudentByRegional(user?.regional?.uuid, user?.position?.group, user?.subalterns)?.length) ?
                        <Students 
                            index={ index }
                            dataStudents={filterStudentByRegional(user?.regional?.uuid, user?.position?.group, user?.subalterns)} 
                            evaluationPeer={evaluationPeer}
                            findSelecteType={ findSelecteType }
                            openModalEdit={ openModalEdit }
                            handleOnchange={handleOnchange}
                            currentSubalterns={currentSubalterns}
                            sectionId={sectionId}
                            setSectionId={setSectionId}
                            setIdSelect={setIdSelect}
                            idSelect={idSelect}
                            saveUser={saveUser}
                            setCurrentSubalterns={setCurrentSubalterns}
                            setIdEdit={setIdEdit}
                            openModal={openModal}
                            deleteUser={deleteUser}

                        />
                    : null}
                </>
                
              )}
            </React.Fragment>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.table}
        rowsPerPageOptions={rows}
        component="div"
        labelRowsPerPage='Registros por página'
        count={typesEvaluations?.users?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    <ModalTable 
      open={message?.open}
      handleClickOut = {closeTable}
      firsLeader={idLeader}
    />
    <ModalEditLeader
      open = {messageEdit.open}
      handleClose = {closeModalEdit}
      data={dataEdit}
      currentLeader={dataLeader}
    />
    </Grid>
  );
}
