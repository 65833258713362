export const initialState = {
    filter: {
        nameKeyword: null,
        status: null,
        evaluationYear: null,
        createdAtStartDate: null,
        createdAtEndDate: null,
        page: null,
        size: null
    },
    isFilter: false,
    typeAction: null
  };
  
  const evaluationPerformanceSearchReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_FILTER_EVALUATION_PERFORMANCE":
        return {
          ...state,
          filter: action.filter,
          isFilter: action.isFilter,
          typeAction: action.typeAction,
        };
      default:
        return state;
    }
  };
  
  export default evaluationPerformanceSearchReducer;
  