import HttpClient from '../services/HttpClient';
import {  QUESTIONARY, APLICATION, REPORT } from '../constants'

export const dowloadReport = (id, type) => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${QUESTIONARY}${APLICATION}/${id}${REPORT}?type=${type}`, {responseType: 'blob'}).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}
