import React from 'react';
import { Grid, Box , } from '@mui/material';
import { withRouter } from 'react-router-dom';
import FontLogin from '../../images/Inicio_session.png';
import { styles } from './styles';


const LoginContainer = (props) => {
    const classes = styles();
  

    return (
        <Box  >
            <Grid container  alignItems="center" flexDirection="row" justifyContent="space-between">
                <Grid item lg={4} md={4} sm={12} xs={12} className={classes.containerImageLogin}>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.containerImage}>
                        <img src={FontLogin} alt="imagen_Login" width="80%" height="400px"/>
                    </Grid>
                </Grid>
                <Grid item lg={7} md={7} sm={12} xs={12}  className={classes.containerForm}>
                    {props.children}
                </Grid>
            </Grid>
        </Box>
    );
};

export default withRouter(LoginContainer);
