import React from 'react';
import {  Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Slide from '@mui/material/Slide';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';


function TransitionUp(props) {
  return <Slide {...props} direction="up" autoHideDuration={2000} />;
}

export default function Snackbars(props) {

  const useStyles = makeStyles((theme) => ({
    rootError: {
      marginTop:'20px',
      position:'absolute',
      width: '50%',
      '& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root':{
      background: '#D73A49',
      marginBottom: '10%',
      },
      '& .css-1gz1y6s': {
        background: '#D73A49',
        marginBottom: '10%',
      },
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    rootSuccess: {
      marginTop:'20px',
      position:'absolute',
      width: '50%',
      '& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root':{
      background: '#59D73A',
      marginBottom: '10%',
      },
      '& .css-1gz1y6s': {
        background: '#59D73A',
        marginBottom: '10%',
      },
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();
   
  return (
    <>
    {props.severity === 'error' ?
    <div className={ classes.rootError}>
      <Snackbar 
      direction="up"
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={props.open} 
        TransitionComponent='Fade'
        autoHideDuration={2000} 
        onClose={props.handleClose}
        message={props.message }
        key={TransitionUp}
        action={
          props.severity === 'error' ? <ErrorOutlinedIcon />
          : <CheckOutlinedIcon />
        }
        >
       </Snackbar>
    </div>
    :
    <div className={classes.rootSuccess}>
      <Snackbar 
        direction="up"
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={props.open} 
        TransitionComponent='Fade'
        autoHideDuration={2000} 
        onClose={props.handleClose}
        message={props.message }
        key={TransitionUp}
        action={
          props.severity === 'error' ? <ErrorOutlinedIcon />
          : <CheckOutlinedIcon />
        }
        >
      </Snackbar>
    </div>
    }
    </>
  );
}