import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
  dropdown: {
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "8px",
    // height: 40,
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
      background: '#dfe7fd',
    },
    '& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
      zIndex: '0 !important'
    },
    '& .css-u9osun.Mui-disabled': {
      zIndex: '0 !important'
    },
    '& .MuiInputLabel-root': {
      zIndex: '0 !important'
    },
    '& .css-u9osun': {
      zIndex: '0 !important'
    },
    '& .jss56': {
      zIndex: '0 !important'
    }
    
  },
  iconErrorFieldLabel: {
    marginRight: '2%',
    marginBottom: '3%',
    fontSize: 'initial'
  },
  buttons: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    paddingBottom: '2%'
}
})


