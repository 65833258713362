import  React,{useState, useEffect} from 'react';
import { 
    FormControl, 
    Grid, 
    Typography,
    TextField,
    FormHelperText
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { styles } from './styles'
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PATH_APP } from '../../../../constants';


export default function ModalConfirmAplication(props) {

    const classes = styles();
    const history = useHistory();
  const [valuesYup, setValuesYup] = useState({});
  const [disabledSubmit, ] = useState(false);


  const schema = yup.object().shape({
    name: yup
    .string()
    .required('Este campo es obligatorio'),
    type: yup
    .string()
    .required('Este campo es obligatorio'),
    from: yup
    .string()
    .required('Este campo es obligatorio')
    .nullable(true),
    until: yup
    .string()
    .required('Este campo es obligatorio')
    .nullable(true),
    sendTo: yup
    .string()
    .required('Este campo es obligatorio')
    .min(10, 'Ingrese mínimo 10 caracteres')
    .max(500, 'Ingrese máximo 500 caracteres')

  })
  
  const { register, handleSubmit, clearErrors, formState:{errors},  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });


  useEffect(() => {
    setValuesYup({...valuesYup, 
      name1: yup
      .string()
      .min(2, 'Ingrese mínimo 2 caracteres')
      .max(20, 'Ingrese máximo 20 caracteres')
      .matches(
         /^(([A-Za-z0-9]))*$/g,
        "Ingrese un nombre válido"
      ),
      code1: yup
      .number()
      })
      clearErrors('name1')
     
  }, [])



  const handleCloseModalCreate = () => {
    props?.setOpenAplication(false) 
  };

  const assess = () => history.push(`${PATH_APP}/assess-cuestionnaire`);


  const onSubmit = (data) => {

 }
 
  return (
    <div>
      <Dialog
        className={classes.widthModal}
        open={props?.openAlication}
        // onClose={handleCloseModalCreate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent style={{ height: 380,}}>
        <Typography id="alert-dialog-title" className={classes.title} >
         APLICACIÓN DE CUESTIONARIO
        </Typography>
          <DialogContentText id="alert-dialog-description">
      
            <form  onSubmit={handleSubmit(onSubmit)} style={{marginTop: 37}}>
            <Grid container spacing={3}  style={{display: 'flex'}}>
                <Grid item lg={6} >
                    <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                        <TextField 
                            {...register("name")}
                            fullWidth
                            size='small'
                            label="Nombre cuestionario"
                            disabled={true}
                            className={classes.dropdown}
                            value={props?.name}
                            error={!!errors.hasOwnProperty("name") && errors["name"].message}
                            helperText={!!errors.hasOwnProperty("name") && 
                            <p style={{fontSize: 12, margin: 'auto 2px'}}>{ errors["name"].message}</p>}
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={6} >
                    <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                        <TextField 
                            {...register(`type`)}
                            fullWidth 
                            size='small'
                            label="Tipo de cuestionario"
                            disabled={true}
                            className={classes.dropdown}
                            value={props?.type}
                            error={!!errors.hasOwnProperty("type") && errors["type"].message}
                            helperText={!!errors.hasOwnProperty("type") && 
                            <p style={{fontSize: 12, margin: 'auto 2px'}}>{ errors["type"].message}</p>}
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={4.5}>
                    <FormControl fullWidth size="small">
                        <TextField 
                            {...register('from')}
                            size="small"
                            type="date"
                            label="Desde"
                            className={classes.fieldDateTimeErrorSearch}
                            minDate={new Date()}
                            InputLabelProps={{
                                shrink: true,
                                notched: true
                            }}
                            error={!!errors.hasOwnProperty("from") && errors["from"].message }
                        />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("from") &&
                            errors["from"].message}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item lg={4.5}>
                    <FormControl fullWidth size="small">
                        <TextField 
                            {...register('until')}
                            size="small"
                            type="date"
                            label="Hasta"
                            className={classes.fieldDateTimeErrorSearch}
                            minDate={new Date()}
                            InputLabelProps={{
                                shrink: true,
                                notched: true
                            }}
                            error={!!errors.hasOwnProperty("until") && errors["until"].message }
                        />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("until") &&
                            errors["until"].message}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item lg={12}>
                    <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                        <TextField 
                            {...register(`sendTo`)}
                            fullWidth 
                            placeholder='Dirigido a:'
                            label="Dirigido a"
                            className={classes.dropdownMultiline}
                            multiline
                            maxRows={4}
                            rows={4}
                            error={!!errors.hasOwnProperty("sendTo") && errors["sendTo"].message}
                            helperText={!!errors.hasOwnProperty("sendTo") && 
                            <p style={{fontSize: 12, margin: 'auto 2px'}}>{ errors["sendTo"].message}</p>}
                        />
                    </FormControl>
                </Grid>
               
            </Grid>  
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'center'}}>
            <Button variant="outlined"  
                disableElevation 
                onClick={handleCloseModalCreate} 
                style={{ color: "#6D9FFF", marginBottom: 5, borderColor: '#80808070' }}
            >
                VOLVER
            </Button>
            &nbsp;&nbsp;
            <Button
                // type="submit"
                onClick={() => assess()} 
                variant="contained"
                disabled={disabledSubmit}
            >
                VALORAR
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
