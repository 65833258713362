export const initialState = {
    open: false,
    openEdit: false,
    openCreateTag: false,
    code: '',
    data: {}
  };
  
  const modalCloseReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_MODAL":
        return {
          ...state,
          open: action.open,
          openEdit: action.openEdit,
          openCreateTag: action.openCreateTag,
          openModalError: action.openModalError,
          code: action.code
        };
      default:
        return state;
    }
  };
  
  export default modalCloseReducer;
  