import React, { useEffect, useState } from 'react'
import { useStateValue } from "../../context/store";
import Table from './table';
import TablePagination from '@mui/material/TablePagination';
import SearchAplicationQuestionary from './search';
import ModalConfirm from "../../utils/form/modalConfirmBase";
import NotFoundSearch from "../../utils/notFoundSearch";
import {getfilterAplicationsEvaluations} from '../../actions/ManagementAplicationEvaluations-actions';


const Index = () => {
  const [{ aplicationQuestionary, spinner, managementAplicationEvaluation }, dispatch] = useStateValue();
  const [listTypes, ] = useState([])
  const [clearFielForm, setClearFielForm]= useState(false)
  const [listStatus, ] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [, setListQuestionnaires] = useState([])
  
  const [paginadorResponse, setPaginadorResponse] = useState({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  const [paginadorRequest, setPaginadorRequest] = useState({
    page: 0,
    size: 10,
  });
  
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    textButtonCancel: '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    buttonsTwo: false,
    title: '',
    type : '',
    open : false
    })

    const closeModalAndClear = () => setClearFielForm(!clearFielForm)


  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar aplicaciones ",
      index: 13,
    });
  window.scrollTo(0,0)
  }, [paginadorRequest])

  useEffect(() => {
    if(managementAplicationEvaluation?.filter){
      getAllQuestionnaires()
    }
  },[managementAplicationEvaluation?.filter])

  const closeModal = () => {
    setMessage({...message, open: false}); 
    resetDispatch();
    closeModalAndClear()
  }

  const resetDispatch = () => {
    return dispatch({
        type: "SET_FILTER_MANAGEMENT_APLICATION_EVALUATION",
        filter: {page: 0, size: 10},
        isFilter: false,
      })
  }

  const resetDataTable = () => setPaginadorResponse({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });

  const notFoundData = (message) => {
    resetDataTable()
    return setMessage({
      open : true,
      description: message,
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
  };


  const errorSistem = (message) => {
    return setMessage({
      open : true,
      description: message,
      handleClick : () => {setMessage({...message, open:false}); resetDispatch(); notFoundData()},
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
  };


  const getAllQuestionnaires = async() => {
    setNotFound(false);
    const data = managementAplicationEvaluation?.filter
    await getfilterAplicationsEvaluations(
      {
      createdAtStartDate : data?.createdAtStartDate ?? null ,
      createdAtEndDate: data?.createdAtEndDate ?? null,
      nameKeyword: data?.nameKeyword ?? null,
      page: data?.page ?? 0 ,
      size: data?.size ?? 10 ,
        
    }, dispatch)
    .then(
      (res) => {
        if((res && res?.status === 200) || (res?.status === 201)) {
          const data = res?.data;
          if (data?.content?.length > 0) {
            setPaginadorResponse({
              data: data?.content,
              totalRows: data?.totalElements,
              pageSize: data?.size,
              pageNumber: data?.number,
            });
          }
          
        }else if(res?.data?.content?.length < 1 && res?.status === 200){
            setNotFound(true);
            return setPaginadorResponse({
              data: [],
              totalRows: 0,
              pageSize: 0,
              pageNumber: 0,
            });
        }else if(res?.status === 204 ){
          return notFoundData('No se encontró información relacionada a la búsqueda');
         }
      }
    )
    .catch(
      (err) => {
        if(err?.status === 400 ){
          return notFoundData('No se encontró información relacionada a la búsqueda');
         }else {
          return errorSistem('Occurió un problema con el servidor por favor vuelva a intentar.')
         }
      }
    )
  }



  const changePage = (event, nuevaPagina) => {
   
    setPaginadorRequest({
      ...paginadorRequest,
      page: nuevaPagina,
    });
    dispatch({
      type: "SET_FILTER_MANAGEMENT_APLICATION_EVALUATION",
      filter: {
          createdAtStartDate: managementAplicationEvaluation?.filter?.createdAtStartDate,
          createdAtEndDate: managementAplicationEvaluation?.filter?.createdAtEndDate,
          nameKeyword: managementAplicationEvaluation?.filter?.nameKeyword,
          page: nuevaPagina,
          size: managementAplicationEvaluation?.filter?.size
        },
        isFilter: true,
    })
  };

  const changeAmountOfRecords = (event, siguiente) => {
    setPaginadorRequest({
      ...paginadorRequest,
      size: event.target.value,
      page: 0
    });
    dispatch({
      type: "SET_FILTER_MANAGEMENT_APLICATION_EVALUATION",
      filter: {
          createdAtStartDate: managementAplicationEvaluation?.filter?.createdAtStartDate,
          createdAtEndDate: managementAplicationEvaluation?.filter?.createdAtEndDate,
          nameKeyword: managementAplicationEvaluation?.filter?.nameKeyword,
          page: 0,
          size: event.target.value
        },
        isFilter: true,
    })
  };

  const clearFilter = () => {
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    })
    getAllQuestionnaires();
  }
    return (
        <div >
            < SearchAplicationQuestionary 
              message={message}
              setMessage={setMessage}
              listTypes={listTypes}
              listStatus={listStatus}
              clearFilter={clearFilter}
              clearFielForm={clearFielForm}
            />
            {!spinner?.active &&
                < Table  
                  data={ paginadorResponse?.data}
                  message={message}
                  setMessage={setMessage}
                />
              
              }

            <NotFoundSearch notFound={notFound}/>
            
            <ModalConfirm 
              description = { message.description }
              textButtonSubmit = { message.textButtonSubmit }
              textButtonCancel = {message.textButtonCancel}
              handleClick = { message.handleClick }
              handleClickOut = { message.handleClickOut }
              oneButtons = { message.oneButtons }
              buttonsTwo = {message.buttonsTwo}
              title = { message.title }
              type = { message.type }
              open = { message.open }
            />
             {paginadorResponse?.totalRows !== 0 && !spinner?.active &&
                <TablePagination
                  style={{ marginLeft: "25%", display:"flex", color: '#747578' }}
                  component="div"
                  rowsPerPageOptions={[5, 10, 15]}
                  count={paginadorResponse.totalRows}
                  rowsPerPage={managementAplicationEvaluation?.filter?.size ?? 10}
                  page={managementAplicationEvaluation?.filter?.page ?? 0}
                  labelRowsPerPage='Registros por página'
                  onPageChange={changePage}
                  onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
                />
             }
             
        </div>
    )
}

export default Index
