import { makeStyles } from '@mui/styles';
// import theme from "../../../../theme/theme";

export const styles = makeStyles({
    expandText :{ 
        textDecoration: 'underline',
        cursor: 'pointer',
        marginLeft: 5,
        color: '#3300ff'
       },
    modalBank: {
        [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
          maxWidth: 900,
          minWidth: 900,
          borderRadius: 16
      },
      [`& .jss59 .css-uhb5lp`] : {
        maxWidth: 900,
        minWidth: 900,
        borderRadius: 16
      },
      [`& .css-uhb5lp`]: {
        borderRadius: 12,
        maxWidth: 900,
        minWidth: 900,
      }
      },
    titleModalBank: {
        fontWeight: 400,
        textAlign: 'center',
        fontSize: 16,
        fontFamily: 'Roboto',
        letterSpacing: '0.25px'
      },
})