import React from "react";
import * as IdKey from "short-uuid";
import {
  Typography,
  Tooltip,
  Grid,
  Button,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {styles} from "./styles"
import { useHistory } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PATH_APP } from "../../../constants";

export default function DetailedAccordion({ data }) {
  const history = useHistory();
  const classes = styles();
  const id = IdKey.generate();


  const validateStatus = (x) => {
    if(x === 'ACTIVE'){
      return 'Activo'
    } else if (x === 'INACTIVE'){
      return 'Inactivo'
    } else if (x === 'APPLIED'){
      return 'Aplicado'
    }
  }
  

if(data?.length) {
  return (
    <div className={classes.root}>
      <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"50%"} className={classes.wrapper}>
                  Nombre del modelo de evaluación
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Estado
                </TableCell>
                <TableCell align="left" width={"30%"} className={classes.wrapper}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, idColumn = id) => (
                <TableRow key={idColumn}>
                  <TableCell align="left" width={"60%"} className={classes.header}>
                    <Typography className={classes.heading} >{row?.name}</Typography>
                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    {validateStatus(row?.status)}                  
                  </TableCell>
                  <TableCell align="left" width={"30%"} className={classes.header}
                    style={{
                      borderTopRightRadius: idColumn === 0 ? 10 : 0,
                      borderBottomRightRadius: idColumn === row?.length - 1 ? 10 : 0
                    }} >
                    <Grid item lg={12} xs={12} container direction="row" alignItems="center" >
                    <Grid item lg={2} xs={2}>
                        {row?.status === "INACTIVE" || row?.status === "ACTIVE" ?
                          <Tooltip title="Editar" arrow >
                            <EditIcon
                              onClick={() => history.push(`${PATH_APP}/evaluation-performance-evaluator/edit/${row?.uuid}`)}
                              className={classes.iconEvent}
                            />
                          </Tooltip>
                          : null}
                      </Grid>
                      <Grid item lg={2} xs={2}>
                        <Tooltip title="Ver detalle" arrow >
                          <VisibilityIcon
                            onClick={() => history.push(`${PATH_APP}/evaluation-performance-evaluator/detail/${row?.uuid}`)}
                            className={classes.iconEvent}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item lg={8} xs={8}>
                        {row?.status !== "INACTIVE" ?
                          <Button variant="contained" onClick={() => history.push(`${PATH_APP}/evaluation-performance/evaluator/aplication/${row?.uuid}`)}>
                            APLICAR
                          </Button>
                          : null}
                      </Grid>

                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
      }else {
        return null;
      }
}
