import http from '../services/HttpClient';
import axios from 'axios'
import { APP_URL, LOGIN, USER, ROLACCESS } from '../constants'

export const loginUser = (body, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
     const header={
        'Content-Type': 'application/json'
      }
        axios.post(`${APP_URL}${LOGIN}`,body, header).then(response => {
            localStorage.setItem('token_app', response?.data?.authToken);
            dispatch({ type: 'LOGIN_TOKEN', token: response?.data?.authToken })
            dispatch({ type: 'LOGIN_SESSION', token: response?.data?.authToken, authenticated: true});
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            getRoleUser(response?.data?.authToken)
            resolve(response);
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getRoleUser = ( dispatch, token) => {
    return new Promise((resolve, reject) => {
        http.get(`${USER} `).then(response => {
            const state = response?.data?.roles;
            let access = []

        if(state?.length === 3) {
            for (let index = 0; index < 1; index++) {
            const totalPermission = ROLACCESS['ADMINISTRATOR']
            
            for (let index = 0; index < totalPermission?.length; index++) {
                access.push(totalPermission[index])  
            }
        }
        }
        else if(state?.length === 2) {
            for (let index = 0; index < 1; index++) {
                const totalPermission = ROLACCESS['EVALUATOR']
                
                for (let index = 0; index < totalPermission?.length; index++) {
                    access.push(totalPermission[index])  
                }
            }
        }
        else if(state?.length === 1) {
            for (let index = 0; index < 1; index++) {
                const totalPermission = ROLACCESS['EVALUATED']
                
                for (let index = 0; index < totalPermission?.length; index++) {
                    access.push(totalPermission[index])  
                }
            }
         }
            dispatch({
                type: 'LOGIN_SESSION',
                token: token,
                authenticated: true,
                user:{
                    name: response?.data?.name ? response?.data?.name: '' ,
                    username: response?.data?.username,
                    surname: response?.data?.surname ? response?.data?.surname : ''
                },
                permissions:access,

            })
            resolve(response);
            
        }).catch(error => {
            resolve(error.response);
        });
    });
}