import React from 'react';

function DivColor({ quantityDivisions, coloredDivisions }) {
  const getColor = index => {
    if (index < coloredDivisions) {
      switch (true) {
        case coloredDivisions <= 2:
          return 'rgba(255, 0, 0, 1)';
        case coloredDivisions <= 3:
          return 'rgba(219, 160, 8, 1)';
        default:
          return 'rgba(111, 210, 4, 1)';
      }
    } else {
      return 'rgba(217, 217, 217, 1)';
    }
  };

  const generateDivs = () => {
    const divs = [];
    for (let i = 0; i < quantityDivisions; i++) {
      const color = getColor(i);
      const borderRadius = {
        borderTopLeftRadius: i === 0 ? '15px' : '0',
        borderBottomLeftRadius: i === 0 ? '15px' : '0',
        borderTopRightRadius: i === quantityDivisions - 1 ? '15px' : '0',
        borderBottomRightRadius: i === quantityDivisions - 1 ? '15px' : '0',
      };

      const opacity = i < coloredDivisions - 1 ? '0.5' : '1';
      const showNumber = i < coloredDivisions ? true : false;

      const cont = i + 1;
      const styleNumber = {
        color: 'rgba(255, 255, 255, ' + opacity + ')',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };

      const styleCustom = {
        backgroundColor: color,
        width: '120px',
        height: '34px',
        margin: '2px',
        position: 'relative',
        ...borderRadius,
      };
      divs.push(
        <div key={i} style={styleCustom}>
          <span style={styleNumber}>{showNumber ? cont : ''}</span>
        </div>
      );
    }
    return divs;
  };

  return <div style={{ display: 'flex', flexDirection: 'row' }}>{generateDivs()}</div>;
}

export default DivColor;
