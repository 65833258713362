import React from "react";
import { makeStyles } from "@mui/styles";
import Warning from "../../../images/icons/warning.svg";
import Question from "../../../images/icons/question.svg";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useStateValue } from "../../../context/store";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Button, Modal } from "@mui/material";
import { PATH_APP } from "../../../constants";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.24)",
    outline: "none",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
  },
  cancel1: { 
     color: "#2F71CB", 
     marginBottom: 5, 
     borderColor: '#80808070',
     "&:hover": {
      borderColor: '#80808070',
    },
  },
  cancel: { 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5, 
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
 },
  text: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#4B4B4B",
    fontWeight: 400,
    textAlign: "center",
    margin: "25px 20px",
  },
  titleAlert: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#2C2C2C",
  },
  widthIcon: {
    fontSize: 70,
    color: '#2F71CB',
    marginTop: '3%'

  },
  confirmBanck :{ 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5,
    // width: 'fit-content' ,
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
  }
}));

const ModalConfirmBase = () => {
  const classes = useStyles();
  const [{valorateAplication }, dispatch] = useStateValue()
  const [modalStyle] = React.useState(getModalStyle);

  const iconsAlert = () => {
    if (valorateAplication?.icon === "WARNING")
    return <img src={Warning} alt="" style={{ width: "25%" }} />;
    if (valorateAplication?.icon === "QUESTION")
      return <img src={Question} alt="" style={{ width: "25%" }} />;
    if (valorateAplication?.icon === "SUCCESS")
      return (
        <CheckCircleOutlineOutlinedIcon
          style={{ width: "30%", color: "green", height: "30%" }}
        />
      );
    if (valorateAplication?.icon === "DELETE")
      return <DeleteOutlineIcon style={{ width: "30%", color: "#2F71CB", height: "30%" }} />;
  };

  const handleClick = () => {dispatch({
    type: "SET_MODAL_VALORATE_APLICATION",
    open: false,
    data: valorateAplication?.data ? [...valorateAplication?.data] : []
  })
  dispatch({
    type: "SET_BREADCRUMB",
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Aplicar cuestionario",
    index: 5,
    subPath: `${PATH_APP}/questionary`,
    isSubmit: true,
    idForm: 'formAplication',
    module: 'APLICATION_CUESTIONARY',
    disabledSubmit: false,
    textIsSubmit: "GUARDAR",
  });
}

  const bodyModal = (
    <div style={modalStyle} className={classes.paper}>
      {iconsAlert()}
      {/* {title ? <h1 className={classes.titleAlert}> {title} </h1> : null} */}
      <p className={classes.text}>{valorateAplication?.message}</p>
      <div style={{ display: "flex", justifyContent:  "space-evenly",}}>
      <Button
        className={ classes.cancel }
        variant="contained"
        onClick={handleClick}
      >
        ACEPTAR
      </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={valorateAplication?.open ?? false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
};

export default ModalConfirmBase;
