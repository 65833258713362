import React from "react";
import {
  AccordionSummary,
  Typography,
  Button,
} from "@mui/material";
import { withStyles, makeStyles } from "@mui/styles";
import {getByIdQuestionary} from "../../../actions/AnswerQuestionnaire-action";
import { useStateValue } from "../../../context/store";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PATH_APP } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  MuiIconButtonEdgeEnd: {
    order: "-1",
  },
  root: {
    [theme.breakpoints.down('md')]: {
        flex: "1 1 auto",
        margin: "25% 2% 0% 2%",
        minHeight: "80vh",
        borderRadius: 10
      },
      [theme.breakpoints.up('md')]: {
        width: "90%",
        borderRadius: 10

      },
      [theme.breakpoints.up('lg')]: {
        margin: '24px 0% auto 2%',
        borderRadius: 10

      },
  },
  rootGrid: {
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    color: '#8E8E8E'
  },
  secondaryHeading: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  iconEvent: {
    color: "#646464",
    cursor: "pointer",
    height: 18,
    width: 18,
    pointerEvents: 'auto',
    '&:hover':{
      color: "#2F71CB",
    }
  },
  details: {
    alignItems: "center",
    backgroundColor: "#EEFBFF",
    pointerEvents: 'auto'
  },
  column: {
    flexBasis: "25.6%",
    padding: '1.5% 0px',
    background: '#FFFFFF', 
    textAlign: 'center'
  },
  columnOneTitle:{
    flexBasis: "39.6%",
    padding: '1.5% 0px',
    background: '#FFFFFF',
    
  },
  columnEvent: {
    paddingLeft: "20px",
    flexBasis: "33.33%",
    display: 'flex',
    justifyContent: 'end',
    marginRight: '5%',
    alignItems: 'center'
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  titlesTable: {
    fontSize: 12, 
    display: 'flex', 
    color: '#8E8E8E', 
    width: '100%', 
    marginLeft: '1%', 
    position:'relative', 
    marginBottom: -8
  },
  header: {
    color: "#8E8E8E",
    fontSize: 14,
  },
  wrapper: {
    color: "#8E8E8E",
    fontSize: 14,
    fontWeight: 700,
  },
  table: {
      "& MuiTable-root": {
        backgroundColor: 'inherit'
      },
    "& .MuiTableCell-root": {
      backgroundColor: 'inherit',
      borderBottom: 'inherit'
    },
    "& .MuiTableCell-head": {
      paddingBottom: 0,
    },
    "& .MuiTableBody-root": {
      backgroundColor: 'white',
      borderRadius: '50rem !important'

    },
  },
}));

export default function DetailedAccordion({dataQuestionary}) {
  const classes = useStyles();
  const history = useHistory();
  const [, dispatch] = useStateValue();
 
 
  const FlippedOrderExpansionPanelSummary = withStyles({
    root: {
      flexDirection: "row-reverse",
    },
  })(AccordionSummary);
  FlippedOrderExpansionPanelSummary.defaultProps = {
    IconButtonProps: { edge: "start" },
  };



  const dataQuestionnaire = async(x) => {
    const result = await getByIdQuestionary(x)

    dispatch({
      type: "SET_DATA_QUESTIONNAIRE",
      data: result?.data,
      
    })
    if(result?.data){
      history.push(`${PATH_APP}/answer-questionnaire/${x}`);
    }else {
      return null
    }
  }
  const getDataAplication = (x) =>{
    dataQuestionnaire(x)
  }


  if(dataQuestionary?.length){
  return (
    <React.Fragment>
      {/* TODO: PENDIENTE PRUEBA VISUAL */}
      <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Fecha de vigencia
                </TableCell>
                <TableCell align="left" width={"23%"} className={classes.wrapper}>
                  Fecha de vigencia
                </TableCell>
                <TableCell align="left" width={"22%"} className={classes.wrapper}>
                  Cuestionario
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataQuestionary.map((x, index) => (
                <TableRow key={index}>
                  <TableCell align="left" width={"20%"} className={classes.header}
                    style={{
                      borderTopLeftRadius: index === 0 ? 10 : 0,
                      borderBottomLeftRadius: index === dataQuestionary.length - 1 ? 10 : 0
                    }}
                  >
                    <Typography className={classes.heading} style={{ marginLeft: '10%' }}>
                      {moment(x?.applicationStartDate).format('DD/MM/YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" width={"23%"} className={classes.header}>
                    {moment(x?.applicationEndDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="left" width={"22%"} className={classes.header}>
                    {x?.questionnaire?.name}
                  </TableCell>
                  <TableCell align="left" className={classes.header}
                    style={{
                      background: '#FFFFFF', borderTopRightRadius: index === 0 ? 10 : 0,
                      borderBottomRightRadius: index === dataQuestionary.length - 1 ? 10 : 0,
                    }}
                  >
                    <div className={classes.columnEvent} >
                      &nbsp;&nbsp;

                      <Button
                        variant="contained"
                        disableElevation
                        disabled={x?.expired ? true : false}
                        onClick={() => getDataAplication(x?.uuid)}
                        style={{
                          background: x?.expired ? '#BEBEBE' : '#2F71CB',
                          color: '#ffffff', marginLeft: '3%'
                        }}
                      >
                        RESPONDER
                      </Button>

                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    {/* <Grid container direction="row"  className={classes.root} cellspacing="0">
     <div className={classes.titlesTable}>
       <Typography style={{fontSize: 12, flexBasis: '20%'}}>Fecha de vigencia </Typography>
       <Typography style={{fontSize: 12, flexBasis: '23%'}}>Fecha de vigencia</Typography>
       <Typography style={{fontSize: 12, flexBasis: '22%'}}>Cuestionario</Typography>
      </div>
      {dataQuestionary.map((x, index) => ( 
        <React.Fragment key={index}>
      <Grid container  style={{  pointerEvents: 'auto', borderRadius: 10,  }}  id={id}>
        <Grid item lg={2} xs={2} className={classes.columnOneTitle} 
            id={id} 
            style={{  background: '#FFFFFF', borderTopLeftRadius: index === 0 ? 10 : 0, 
                      borderBottomLeftRadius: index === dataQuestionary.length -1 ? 10 : 0 }}
            >
          <Typography className={classes.heading} style={{marginLeft: '10%'}}>
            {moment(x?.applicationStartDate).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item lg={2} xs={2} className={classes.column} > 
          <Typography className={classes.heading} >
          {moment(x?.applicationEndDate).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item lg={5} xs={5} className={classes.column} >  
          <Typography className={classes.heading} >
            {x?.questionnaire?.name }
          </Typography>
        </Grid>
        <Grid item lg={3} xs={4} 
          style={{  background: '#FFFFFF', borderTopRightRadius: index === 0 ? 10 : 0, 
                    borderBottomRightRadius: index === dataQuestionary.length -1 ? 10 : 0,
                  }} 
          className={classes.column}
          >
          <div className={classes.columnEvent} >
            &nbsp;&nbsp;
            
              <Button 
                variant="contained"
                disableElevation
                disabled={x?.expired ? true : false } 
                onClick={()=> getDataAplication(x?.uuid)}
                style={{ background: x?.expired ? '#BEBEBE' : '#2F71CB' , 
                    color: '#ffffff', marginLeft: '3%'
                  }}
              >
            RESPONDER
            </Button> 
         
          </div>
        </Grid>
      </Grid>
     </React.Fragment>
        ))}
        </Grid> */}
    </React.Fragment>
  );
  }else {
    return null;
  }
}
