import React, {useState, useEffect} from 'react';
import { Box, CircularProgress, Paper } from "@mui/material";
import { useStateValue } from '../../../context/store';
import Search from "./search";
import Table from "./tableUsers";
import TablePagination from '@mui/material/TablePagination';
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import { getRegionals, getEmployeesEvaluated, getPositions} from '../../../actions/OrganizationChart-action';
import { Grid, Typography } from '@mui/material';
import ModalActions from "../../../components/common";
import {styles} from "./styles";
import Tabla from "./tableEvaluatorsEvaluates";
import { PATH_APP } from '../../../constants';

const Index = (props) => {

    const classes = styles()
    const {id} = props?.match?.params
    const idEvaluation = id
    const [{filterOrganizationChart, spinner, typesEvaluations, loadingTables}, dispatch] = useStateValue();
    const [clearAll, setClearAll] = useState(false);
    const [clearFielForm, setClearFielForm] = useState(false);
    const [, setInitialFilter] = useState(false);
    const [allRegionals, setAllRegionals] = useState([]);
    const [allPositions, setAllPositions] = useState([]);

      const [paginadorResponse, setPaginadorResponse] = useState({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });
      const [paginadorRequest, ] = useState({
        page: 0,
        size: 10,
      });
    const [message, setMessage] = useState({
      description : '',
      textButtonSubmit : '',
      textButtonCancel: '',
      handleClick : () => {} ,
      handleClickOut : () => {},
      oneButtons : false,
      title: '',
      list: [],
      type : '',
      open : false
    })
    const [jsonTypes, setJsonTypesType] = useState();


    useEffect(() => {
     if(typesEvaluations?.users?.length >= 1){
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Evaluados",
        index: 12,
        idForm: 'typeEvaluation',
        textIsSubmit: "GENERAR APLICACIÓN",
        threeButton: true,
        subPathSearch: `${PATH_APP}/evaluation-performance-evaluator`,
        isSubmit: true,
        disabledSubmit: false,
        module: 'EVALUATION_EVALUATOR',
        subPath: `${PATH_APP}/evaluation-performance/evaluator/aplication/${idEvaluation}/type-evaluation`,
      });
     }else {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Evaluados",
        index: 12,
        threeButton: true,
        subPathSearch: `${PATH_APP}/evaluation-performance-evaluator`,
        idForm: 'typeEvaluation',
        textIsSubmit: "GENERAR APLICACIÓN",
        isSubmit: true,
        disabledSubmit: true,
        module: 'EVALUATION_EVALUATOR',
        subPath: `${PATH_APP}/evaluation-performance/evaluator/aplication/${idEvaluation}/type-evaluation`,
      });
     }
    }, [typesEvaluations?.users])
    
    useEffect(()=> {

      getRegional();
      getPosition();
      window.scrollTo(0,0)
      setInitialFilter(true)
      getAllEmployees()

      const typeslocal = localStorage.getItem('typesEvaluation')
      setJsonTypesType(JSON.parse(typeslocal));
    },[])

    useEffect(()=> {
      
          dispatch({
            type: 'ORGANIZATION_CHART',
            changeLeader: false,
            leaderToEdit: '',
            infoLeader: {},
            leaderId: '',
            subalterns: [],
            newSubalterns: [],
        })
       
      },[paginadorRequest])



    const getAllEmployees = async() => {

      const newData = filterOrganizationChart?.filter

         await getEmployeesEvaluated({
          identificationKeyword : newData?.idKeyword ?? null,
            nameKeyword: newData?.nameKeyword ?? null,
            positionName: newData?.positionName ?? null,
            regionalName: newData?.regionalName ?? null,
            evaluatorType: !newData?.evaluatorType ? `TEACHER,CHAIR_TEACHER` : newData?.evaluatorType,
            page: newData?.page ?? 0,
            size:  newData?.size ?? 10,
            
        },
        dispatch,
        )
        .then((res) => {
          if(res?.status === 204 && !filterOrganizationChart?.typeAction){
            dispatch({
              type: "SET_FILTER_ORGANIZATION_CHART",
              filter: {...filterOrganizationChart?.filter},
              isFilter: false,
            })
            setPaginadorResponse({
              data: [],
              totalRows: 0,
              pageSize: 0,
              pageNumber: 0,
            });
            return setMessage({
              open : true,
              description: 'No se encontró información relacionada a la búsqueda',
              handleClick : () => closeAndRefresh() ,
              oneButtons : true,
              textButtonSubmit: 'ACEPTAR',
              type : 'WARNING',
            })
          }else if(res?.data?.content?.length < 1 && !filterOrganizationChart?.typeAction){
            setPaginadorResponse({
              data: [],
              totalRows: 0,
              pageSize: 0,
              pageNumber: 0,
            });
            return setMessage({
              open : true,
              description: 'No se encontró información relacionada a la búsqueda',
              handleClick : () => closeModalWarning() ,
              oneButtons : true,
              textButtonSubmit: 'ACEPTAR',
              type : 'WARNING',
            })
          }else if ((res && res?.status === 200) || ( res?.status === 201)) {
    
            const data = res?.data;
            if (data?.content?.length > 0) {
               setPaginadorResponse({
                data: data?.content,
                totalRows: data?.totalElements,
                pageSize: data?.size,
                pageNumber: data?.number,
              });
              if(paginadorRequest?.page >=1 ){
                return dispatch({
                  type: "SET_TYPES_EVALUATIONS",
                  automatic: typesEvaluations?.automatic,
                  applicationTypes: typesEvaluations?.applicationTypes,
                  submitPercentage: typesEvaluations?.submitPercentage,
                  users: typesEvaluations?.users,
                  selectAll: false,
                  dataEvaluation: typesEvaluations?.dataEvaluation,
                  pages: typesEvaluations?.pages,
                  selectNewLeaders: typesEvaluations?.selectNewLeaders
                });
              }
    
              dispatch({
                type: "SET_FILTER_ORGANIZATION_CHART",
                filter: {...filterOrganizationChart?.filter},
                isFilter: false,
              })
              
            }
    
            
          } 
        })
        .catch( (err) => {
          if(err?.status === 400){
            setMessage({
              open : true,
              description: 'No se encontró información relacionada a la búsqueda',
              handleClick : () => closeModalWarning() ,
              oneButtons : true,
              textButtonSubmit: 'ACEPTAR',
              type : 'WARNING',
            })
            return setPaginadorResponse({
              data: [],
              totalRows: 0,
              pageSize: 0,
              pageNumber: 0,
            });
          }else if(err?.status > 399 && err?.status < 405  && !filterOrganizationChart?.typeAction){
            return setMessage({
              open : true,
              description: 'No se encontró información relacionada a la búsqueda',
              handleClick : () => closeModalWarning() ,
              oneButtons : true,
              textButtonSubmit: 'ACEPTAR',
              type : 'WARNING',
            })
          }else {
              errorSistem()
              return setPaginadorResponse({
                data: [],
                totalRows: 0,
                pageSize: 0,
                pageNumber: 0,
              });
            
          }
        })
  
      
      }


    useEffect(() => {
      if(filterOrganizationChart?.isFilter){
        getAllEmployees()
      }
    },[filterOrganizationChart?.isFilter])


      const dataFilter = () => {
        setPaginadorResponse({
          data: [],
          totalRows: 0,
          pageSize: 0,
          pageNumber: 1,
        });
        dispatch({
          type: "SET_FILTER_ORGANIZATION_CHART",
          filter: {evaluatorType: ['TEACHER', 'CHAIR_TEACHER'].map((x)=> x), page: 0, size: 10},
          isFilter: true,
        })
      }
    
      const changeAmountOfRecords = (event, siguiente) => {
        dispatch({
          type: "SET_FILTER_ORGANIZATION_CHART",
          filter: {...filterOrganizationChart?.filter, size: event?.target.value, page:0},
          isFilter: true,
        })
  
      };
    
      const changePage = (event, nuevaPagina) => {
       dispatch({
        type: "SET_FILTER_ORGANIZATION_CHART",
        filter: {...filterOrganizationChart?.filter, page: nuevaPagina, size: filterOrganizationChart?.filter?.size },
        isFilter: true,
      })
      window.scrollTo(0,0)
      };

      const errorSistem = () => {
        setMessage({...message, open: false})
        setClearFielForm(!clearFielForm);
        dispatch({
          type: "SET_FILTER_ORGANIZATION_CHART",
          filter: {evaluatorType: ['TEACHER', 'CHAIR_TEACHER'].map((x)=> x), page: 0, size: 10},
          isFilter: false,
        })
      }
  
      const closeModal = () => {
        setMessage({...message, open: false})
        dataFilter();
        setClearFielForm(!clearFielForm);
        clierFilterLeader()
      }
  
      const closeModalWarning = () => {
        setMessage({...message, open: false})
        dataFilter();
        setClearFielForm(!clearFielForm);
        dispatch({
          type: "SET_FILTER_ORGANIZATION_CHART",
          filter: {page: 0, size: 10},
          isFilter: false,
        })
      }

      const closeAndRefresh = () => {
        setMessage({...message, open: false})
        dataFilter();
        setClearFielForm(!clearFielForm);
        dispatch({
          type: "SET_FILTER_ORGANIZATION_CHART",
          filter: {evaluatorType: ['TEACHER', 'CHAIR_TEACHER'].map((x)=> x), page: 0, size: 10},
          isFilter: true,
        })
      }
  
  
    const clierFilterLeader = () => setClearAll(!clearAll)

      const getRegional = async() => {
        const result = await getRegionals();  
        setAllRegionals(result?.data?.length ? result?.data : [] )
      }

      const getPosition = async() => {
        const result = await getPositions();  
        setAllPositions(result?.data?.length ? result?.data : [] )
      }

      
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item lg={12} style={{marginBottom: '8%'}}>
       <Typography style={{color:'#2F71CB', fontSize: 18, marginBottom: '2%', fontWeight: 500}}>{jsonTypes?.dataEvaluation?.applicationName}</Typography>
       <Typography style={{fontSize: 14, fontWeight: 400, color: "#4B4B4B", marginBottom: 12}}>En este campo realizará la selección de los evaluados</Typography>
        <Paper style={{background: '#F5F5F5', borderRadius: '10px'}} elevation={3}>
          <Grid container >
          
            <Grid item lg={11} style={{margin: 'auto'}}>
              < Search 
                dataFilter={dataFilter}
                regionals={allRegionals} 
                positions={allPositions} 
                clearFielForm={clearFielForm}
                setMessage={setMessage}
                message={message}
                props={props}
              />
            </Grid>
            <Grid item container lg={12} style={{margin: 'auto'}} justifyContent="center">
              <Grid item lg={11}>
              {loadingTables?.active 
              ? (
                  <Box sx={{ display: 'flex', marginTop:'3%', paddingBottom: '2%', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                )
              : (
                  < Table 
                    data={paginadorResponse?.data} 
                    clearFields={closeModal} 
                    message={message}
                    setMessage={setMessage}
                    page={paginadorResponse?.pageNumber}
                  /> 
                )
                }
              </Grid>
              <Grid item lg={12}>
                {paginadorResponse?.totalRows !== 0 && !spinner?.active &&
                  <TablePagination
                      className={classes.table}
                      component="div"
                      rowsPerPageOptions={[5, 10, 15]}
                      count={paginadorResponse.totalRows}
                      rowsPerPage={filterOrganizationChart?.filter?.size ?? 10}
                      page={paginadorResponse.pageNumber ?? 0}
                      labelRowsPerPage='Registros por página'
                      onPageChange={changePage}
                      onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
                  />
                }
              </Grid>
            </Grid>
          </Grid>   
        </Paper>
            {typesEvaluations?.users?.length ? <Tabla message={message} setMessage={setMessage} /> : null}
      </Grid>
        
        
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        textButtonCancel = {message.textButtonCancel}
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        list = {message.list}
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
      <ModalActions/>
    </Grid>
  )
}

export default Index