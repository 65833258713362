import  React,{useState, useEffect} from 'react';
import { 
    FormControl, 
    Grid, 
    InputLabel, 
    OutlinedInput, 
    IconButton,
    Container,
    Typography,
    TextField,
    Switch,
    Popover
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useStateValue } from "../../context/store";
import { withStyles } from '@mui/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { styles } from '../styles'
import { REQUIRED_MESSAGE } from '../../constants';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createLabel } from '../../actions/label-actions';
import * as yup from "yup";

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.white,
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: 'green',
          borderColor: 'green',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.white}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.error.main,
    },
    checked: {},
  }))(Switch);

export default function AlertDialog(props) {

    const classes = styles();
  const [activeChecked, setActiveChecked] = useState(false)
  const [{ labelSearch, modalClose }, dispatch] = useStateValue();
  const [valuesYup, setValuesYup] = useState({})
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [valuesAnswer, setValuesAnswer] = useState([ { name: 'name1'}])
  const [codeLabel, setCodeLabel] = useState(props.codeInitial)
  const [initialValue, ] = useState(props.codeInitial)
  const [sumNumInitial, setSumNumInitial] = useState(props.codeInitial)
  const [valuesOptions, setValuesOptions ] = useState([])


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


  const schema = yup.object().shape(valuesYup)
  
  const { register, handleSubmit, control, trigger, unregister, clearErrors, formState:{errors}, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });


  useEffect(() => {
    setValuesOptions([ { name: 'name1', value: 1, code: 1, status: 'status1'},])
    setValuesYup({...valuesYup, 
      name1: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(2, 'Ingrese mínimo 2 caracteres')
      .max(20, 'Ingrese máximo 20 caracteres')
      .matches(
         /^(([A-Za-z0-9]))*$/g,
        "Ingrese un nombre válido"
      ),
      code1: yup
      .number()
      })
      clearErrors('name1')
      setValue(`code1`, props.codeInitial )
     
  }, [])

  const handleCloseModal = () => props.setMessage({...props.message, open:false})
  const submitAndCloseModal = () => {
    setDisabledSubmit(false)
    handleCloseModal()
  }
  const closeModalCreate = () => dispatch({
    type: "SET_MODAL",
    openCreateTag: false,
    openEdit: true,
  });


  const handleCloseModalCreate = () => {
    setValuesYup({ 
      name1: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(2, 'Ingrese mínimo 2 caracteres')
      .max(20, 'Ingrese máximo 20 caracteres')
      .matches(
        /^(([A-Za-z0-9]))*$/g,
        "Ingrese un nombre válido"
      ),
      code1: yup
      .number()
      })
    setValuesOptions([ { name: 'name1', value: 1, code: 1, status: 'status1'}])
    setValue('name1', '')
    clearErrors('name1')
    closeModalCreate()
  };

  const handleCloseModalAndReset = ()=> {
    setDisabledSubmit(false)
    handleCloseModal()
    if(props?.createInQuestion) {
      props?.refrehsTags()
     }
    closeModalCreate()
    if(labelSearch && !props?.createInQuestion ) {
      return  dispatch({
        type: "SET_FILTER_LABEL",
        filter: labelSearch?.filter,
        isFilter: true,
        typeAction: 'CREATE'
      });
    } else {
      return null
    }
   
  }


  const handleClickShowPassword = (e, code, st) => {
    if(valuesOptions.length === 1) {
      return null
    } else {
      unregister(e)
      unregister(st)
      unregister(`code${code}`)
        setCodeLabel(codeLabel -1)
        const result = valuesOptions.filter((x)=> x.name !== e)
        const newValues = [];
        let objectNew = {};
        for (let index = 0; index < result.length ; index++) {
         
          newValues.push({name: result[index].name, value: result[index].value, code: initialValue + index, status: 'Activo' })
          const fieldForYup =  {
            [result[index].name]: yup
            .string()
            .required(REQUIRED_MESSAGE)
            .min(2, 'Ingrese mínimo 2 caracteres')
            .max(20, 'Ingrese máximo 20 caracteres')
            .matches(
              /^(([A-Za-z0-9]))*$/g,
              "Ingrese un nombre válido"
            ),
            [`code${result[index].value}`]: yup
            .number(),
          }
          const newArray = Object.assign(objectNew, fieldForYup)
          objectNew = newArray
          
        }
        
        setValuesYup(objectNew)
        setValuesOptions(newValues)
      }
    }
          

  const addOption = () => {
     
    return <IconButton
    style={{marginTop:  20, left: "30%"}}
    aria-label="toggle password visibility"
    onClick={()=> {
     
        if(valuesOptions.length === 5) {
            return props.setMessage({
              open : true,
              description: 'Ha alcanzado el máximo de etiquetas para crear',
              handleClick : () => handleCloseModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
        } else {
          setSumNumInitial(sumNumInitial + 1)
          setCodeLabel(codeLabel +1 )
          clearErrors(`name${valuesOptions.length +1}`)
          setValue(`status${valuesOptions.length + 1}`, 'Activo');
          setValuesAnswer([...valuesAnswer, {name: `name${valuesAnswer.length + 1}`}])
          setValue(`code${sumNumInitial + 1}`, sumNumInitial + 1)
          setValuesOptions([...valuesOptions, {name: `name${valuesAnswer.length + 1}`, value: valuesAnswer.length +1, code: codeLabel +1, status: 'Activo'}])
            setValuesYup({...valuesYup, [`name${valuesAnswer.length +1}`]: yup
              .string()
              .required(REQUIRED_MESSAGE)
              .min(2, 'Ingrese mínimo 2 caracteres')
              .max(20, 'Ingrese máximo 20 caracteres')
              .matches(
                /^(([A-Za-z0-9]))*$/g,
                "Ingrese un nombre válido"
              ),
              [`code${valuesOptions.length +1}`]: yup
              .number()
          })
    
        }
    }}
    edge="end"
    >
    {<AddCircleIcon color="success"/>}
    </IconButton>
  }


  const onSubmit = (data) => {
    const keysData = Object.keys(valuesYup)
    const labels = [];

   const keysNamesLabels = keysData.filter((x)=> x.includes('name'))
   for (let index = 0; index < valuesOptions.length; index++) {
    labels.push({type: 'TAG', name: data?.[keysNamesLabels[index]], status: 'ACTIVE' })
   }
   const newData = {
       allQuestions: labels,
   }
   const createLabels = async() => {
    setDisabledSubmit(true)
     const result = await createLabel(newData?.allQuestions, dispatch)
     if (result && (result?.status === 200 || result?.status === 201)) {
      props.setMessage({
        open : true,
        title: '¡Proceso exitoso!',
        description: 'Información almacenada correctamente ',
        handleClick : () => handleCloseModalAndReset() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'SUCCESS',
      })
     }
     else if (result?.status === 400) {
      props.setMessage({
        open : true,
        description: result?.data?.message,
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }else if (result?.status > 399) {
      props.setMessage({
        open : true,
        description: 'Error al guardar la información',
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
     } else {
      props.setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
     }
   } 
  createLabels()
 }


  const toggleChecked = (name) => {
      const valuefieldChecked = activeChecked ? 'Activo' : 'Inactivo'
      setActiveChecked(!activeChecked)
      setValue(name,valuefieldChecked)
    }
  const handleOnchange = (e) => {
    const {name, value} = e.target;
    setValue(name, value.toUpperCase())
    trigger(name)
  }

  const inputOption = (x) => {

    return <React.Fragment >
    <Grid item lg={2}>
        <FormControl variant='outlined' fullWidth >
            <Controller 
            control={control}
            render={({field, fieldState})=> (
            <TextField
                {...register(`code${x.code}`)}
                type="number"
                style={{margin: 5}}
                inputRef={field.ref}
                className={classes.dropdown}
                fullWidth
                InputLabelProps={{shrink: true, notched: true}}
                disabled={true}
                // value={x?.code  }
                size="small"
                label="Código"
            />
            )}
            />
        </FormControl>
    </Grid>
    <Grid item lg={5} className={classes.display}>
         <ErrorOutlineIcon 
          style={{visibility: errors[`name${x.value}`] ? "initial": "hidden"}} 
          color="error" 
          fontSize="small"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        /> 
         <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>
        <Typography style={{fontSize: 12, margin: 10}}>{!!errors.hasOwnProperty(`name${x.value}`) && errors[`name${x.value}`].message}</Typography>
        </div>
      </Popover>
        <FormControl variant='outlined' fullWidth >
            <TextField
                {...register(`name${x.value}`)}
                type="text"
                style={{margin: 5}}
                InputLabelProps={{
                  shrink: watch(`name${x.value}`) ? true: false , 
                  notched: watch(`name${x.value}`) ? true: false
                }}
                className={classes.dropdown}
                fullWidth
                onChange={handleOnchange}
                size="small"
                label="Nombre de etiqueta"
                error={!!errors.hasOwnProperty(`name${x.value}`) && errors[`name${x.value}`].message}
                helperText={!!errors.hasOwnProperty(`name${x.value}`) && errors[`name${x.value}`].message}
                
            />
        </FormControl>
    </Grid>
    <Grid item lg={3} className={classes.display}>
    <ErrorOutlineIcon style={{visibility: errors[`status${x.value}`] ? "initial": "hidden"}} color="error" fontSize="small"/> 
        <FormControl fullWidth size="small">
            <InputLabel htmlFor="outlined-adornment-password" 
                shrink="true" 
                error={!!errors.hasOwnProperty(`status${x.value}`) && errors[`status${x.value}`].message}
            >
              Estado
            </InputLabel>
                <OutlinedInput
                {...register(`status${x.value}`)}
                disabled={true}
                type='text' 
                value='Activo'
                notched={true}
                shrink="true" 
                className={classes.dropdownMultiple}
                endAdornment={
                    <AntSwitch
                    disabled={true}
                    style={{ fontSize: 40, pointerEvents: 'auto' }}
                    checked={true}
                    onClick={(event) => { event.stopPropagation(); toggleChecked(`status${x.value}`) }}
                    onFocus={(event) => event.stopPropagation()}
                  />
                }
                label={`Opción ${x.value}`}
              
            />
        </FormControl>
    </Grid>
    {props?.createInQuestion ? null:
    <Grid item lg={2} >
        <IconButton
        style={{ marginTop: '7%'}}
            aria-label="toggle password visibility"
            onClick={() => handleClickShowPassword(`name${x.value}`, x.code, `status${x.value}`)}
            edge="end"
            >
                {<DeleteIcon/>}
        </IconButton>
    </Grid>
  }
            </React.Fragment>

}

 
  return (
    <div>
      <Dialog
        className={classes.widthModal}
        open={modalClose?.openCreateTag}
        // onClose={handleCloseModalCreate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
        <Typography id="alert-dialog-title" style={{fontSize: 24, fontWeight: 400}}>
          Crear etiqueta
        </Typography>
          <DialogContentText id="alert-dialog-description">
          <Container >
            <form  onSubmit={handleSubmit(onSubmit)} style={{marginTop: 37}}>
            <Grid container spacing={2} maxWidth='lg' style={{margin: '0px 30px 0px -40px'}}>
                {valuesOptions.map((x) =>(
                inputOption(x)
                
                ))}
            
                <Grid lg={1}>
                { props?.createInQuestion ? null : addOption()}
                </Grid>
                <Grid item lg={12} style={{display: 'flex', alignItems: 'flex-start', marginLeft: '70%', marginTop: props?.createInQuestion ? '3%': 0 }}>
                  <Button variant="contained"  
                    disableElevation 
                    onClick={() => handleCloseModalCreate()} 
                    style={{color: "#ffffff", background: '#C0C0C0'}}
                  >
                      CANCELAR
                  </Button>
                    &nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={disabledSubmit}
                    style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 5 }}
                  >
                      GUARDAR
                  </Button>
                </Grid>
            </Grid>  
            </form>
        </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        
        </DialogActions>
      </Dialog>
    </div>
  );
}
