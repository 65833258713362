import { makeStyles } from '@mui/styles';


export const useStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
          flex: "1 1 auto",
          margin: "25% 2% 0% 2%",
          minHeight: "80vh",
        },
        [theme.breakpoints.up('md')]: {
          width: "100%",
        },
        [theme.breakpoints.up('lg')]: {
          // width: "67%",
          margin: '10px 0% auto 0%'
        },
    },
    dropdown: {
      backgroundColor: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "8px",
      height: 40,
      fontSize: "14px",
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#dfe7fd',
      },

    },
    MuiIconButtonEdgeEnd: {
      order: "-1",
    },
   
    rootGrid: {
      flexGrow: 1,
    },
  
  }));