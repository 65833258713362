import React from 'react';
import { Modal } from '@mui/material';
import "./styles.css"

const LoadingPage = () => {


  return (
    <Modal open={true}  className='container_spinner'>
     <div className={`${"custom-loading-spinner"} ${ "show-loading"}`}> 
  <div className="loading-wrapper">
    <div className="loading-content">
      <div className="loading"></div>
    </div>
  </div>
  <div className="loading-wrapper loading-wrapper-2">
    <div className="loading-content">
      <div className="loading loading-2"></div>
    </div>
  </div>
  <div className="loading-wrapper loading-wrapper-3">
    <div className="loading-content">
      <div className="loading loading-3"></div>
    </div>
  </div>
  <h3>Cargando...</h3>
</div>
 </Modal>
  )
}

export default LoadingPage;
