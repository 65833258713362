import React, {useState, useEffect} from 'react'
import { 
     Grid,
     TextField, 
     InputLabel, 
     FormControl, 
     FormHelperText, 
     MenuItem,
     Button,
     Typography
} from '@mui/material'
import {styles} from './styles';
import { useStateValue } from "../../../../context/store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment"

const SearchLabel = (props) => {

    const classes = styles()
    const [{answerEvaluation, breadCrumb}, dispatch] = useStateValue();
    const [years, setYears] = useState([])
    const [dataForm, setDataForm] = useState({
        keyword: '',
        status: '',
        year: ''
    })
    
    const schema = yup.object().shape({
        keyword: dataForm?.keyword ? yup
        .string()
        .notRequired()
        .nullable(true)
        .max(100, 'Ingrese máximo 100 caracteres') : null, 
        status: yup
        .string()
        .notRequired()
        .nullable(true),
        year: yup
        .string()
        .notRequired()
        .nullable(true)
      
      });
    
      const { register, handleSubmit, clearErrors, trigger, formState:{errors},  setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });

      
      const handleClose = () => props.setMessage({...props.message, open: false})

      const onSubmit = (data) => {

          if(data?.keyword === '' && data?.status === ''  && dataForm?.year === ''){
            return  props?.setMessage({
                message: 'Ingrese información en algúno de los campos',
                open: true,
                severity: 'error',
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                handleClick : () => handleClose() ,
              })
          }
          if(
            data?.keyword !== '' ||
            data?.status !== '' ||
            dataForm?.year !== ''
        ){
            dispatch({
                type: "SET_FILTER_ANSWER_EVALUATION",
                filter: {
                    nameKeyword: data?.keyword,
                    status: data?.status,
                    evaluationPeriod: dataForm?.year,
                    page: 0,
                    size: answerEvaluation?.filter?.size ? answerEvaluation?.filter?.size : 10
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "SET_FILTER_ANSWER_EVALUATION",
                filter: {
                    evaluationPeriod: null,
                    nameKeyword: null,
                    status: null,
                  },
                  isFilter: true,
            })
        }
      }

      useEffect(() => {
        clear()
      }, [props?.clearFielForm])

      useEffect(() => {
       onlyClear()
      }, [props?.clearFields])

      const handleChange = (e) => {
        const {value, name} = e.target;
        setDataForm({...dataForm, [name]:value})
        if(name === 'keyword'){
            setValue(name, value)
            trigger(name)
        }
        setValue(name, value)
        trigger(name)
      }

    const clear = () => {
        setValue('keyword', '')
        setDataForm({...dataForm, status: '', year: ''})
        setValue('status', '')
        setValue('year', '')
        clearErrors('keyword')
        props.dataFilter();
        resetDispatch()
    }

    useEffect(() => {
        resetDispatch()
    },[props?.clearFields])

    const onlyClear = () => {
        setValue('keyword', '')
        setDataForm({...dataForm, status: '', year: ''})
        setValue('status', '')
        setValue('year', '')
        clearErrors('keyword')
        props.dataFilter();  
    }

   const resetDispatch =() => {
    return dispatch({
        type: "SET_FILTER_ANSWER_EVALUATION",
        filter: {
            evaluationPeriod: null,
            nameKeyword: null,
            status: null,
            page: 0,
            size: 10
          },
          isFilter: true,
          
    })
   }

    useEffect(() => {
        const yearInitial = (Number(moment(new Date()).format('yyy'))-10)
        const values = []
        for (let index = yearInitial; index < yearInitial+100; index++) {
        values.push({key: index, value: index})
        }
        setYears(values)

     
    }, [])

    const validateState = (x) => {
        if(x === 'PENDING_TO_ANSWER'){
            return 'Pendiente por responder'
        }else if (x === 'ANSWERED'){
            return 'Respondido'
        }else if (x === 'UNANSWERED'){
            return 'No resuelto'
        }
    }
    
    useEffect(()=> {

        if(breadCrumb?.module === "EVALUATION_EVALUATED" && answerEvaluation?.isFilter){
            setValue('keyword', answerEvaluation?.filter?.nameKeyword)
            setValue('evaluationYear', answerEvaluation?.filter?.evaluationYear)
            setDataForm({...dataForm, 
                status: answerEvaluation?.filter?.status, 
                year:answerEvaluation?.filter?.evaluationPeriod, 
                keyword:answerEvaluation?.filter?.nameKeyword
             })
            setValue('status', answerEvaluation?.filter?.status)
        }
     
    },[breadCrumb?.module === "EVALUATION_EVALUATED"])


      const validate =  watch('keyword') || dataForm?.status  || watch('year')|| dataForm?.year 
      const disabledButton = errors['keyword'] || !validate

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container spacing={2} style={{alignItems: 'flex-end'}}>
            <Grid item lg={6} md={12} sm={12} xs={12} style={{paddingBottom: '0.3%'}}>
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('keyword')}
                    className={classes.dropdownNameCuestionary}
                    fullWidth
                    autoComplete='off'
                    onChange={handleChange}
                    size="small"
                    placeholder="Palabra clave"
                    error={!!errors.hasOwnProperty("keyword") && errors["keyword"].message}
                    helperText={!!errors.hasOwnProperty("keyword") && errors["keyword"].message}
                  />
                </FormControl>
                </Grid>
                <Grid item lg={2} md={6} sm={6} xs={6} style={{display:'flex ', flexDirection: 'column' }}>
                    <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 6 }}>Periodo de evaluación</Typography>
                <FormControl fullWidth size="small">
                    <InputLabel  error={!!errors.hasOwnProperty("year") && errors["year"].message}></InputLabel>
                        <TextField
                        select
                        size="small"
                        {...register('year')}
                        onChange={handleChange}
                        value={dataForm?.year}
                        autoComplete='off'
                        label="Año"
                        InputLabelProps={{
                            className: classes.labelProps
                        }}
                        error={!!errors.hasOwnProperty("year") && errors["year"].message}
                        className={classes.dropdown}
                        >
                            {years?.map((x) => (
                                <MenuItem value={x?.value} key={x?.key}>{x?.value}</MenuItem>
                            ))}  
                           
                        </TextField>
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("year") &&
                            errors["year"].message}
                        </FormHelperText>
                    </FormControl>
                     
                </Grid>
            
                <Grid item lg={2} md={3} sm={6} xs={6} style={{display: 'flex', alignItems: 'center'}}>
                    <FormControl fullWidth size="small">
                    <InputLabel  error={!!errors.hasOwnProperty("status") && errors["status"].message}></InputLabel>
                        <TextField
                        select
                        size="small"
                        {...register('status')}
                        onChange={handleChange}
                        value={dataForm?.status}
                        label="Estado"
                        InputLabelProps={{
                            className: classes.labelProps
                        }}
                        error={!!errors.hasOwnProperty("status") && errors["status"].message}
                        className={classes.dropdown}
                        // style={{background: watch("status") && !errors["status"] ? '#E1E1E1' : '#ffffff'}}
                        >
                            {props?.dataSelect?.map((x, index)=> (
                                 <MenuItem value={x?.status} key={index}>{validateState(x?.status)}</MenuItem>
                            ))}
                        </TextField>
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("status") &&
                            errors["status"].message}
                        </FormHelperText>
                    </FormControl>
                    
                    </Grid>
               
                <Grid item xs={12} sm={12} md={12} lg={2} style={{display: 'flex',  alignItems: 'center', marginBottom: '0.4%'}}>
                    <Button 
                        variant="contained" 
                        type="submit" 
                        disabled={disabledButton}
                        disableElevation 
                        color="primary"
                    >
                        BUSCAR
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button 
                        variant="outlined"  
                        disableElevation 
                        onClick={clear}
                        style={{background:"#ffffff",}}
                    >
                      Limpiar
                    </Button>

                </Grid>
            </Grid>
         </form>
    )
}

export default SearchLabel
