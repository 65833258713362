import React, {useState, useEffect} from 'react';
import {
    Container,
    Typography,
    Grid
} from "@mui/material";
import {styles} from "./styles";
import { Box } from '@mui/system';

const Index = (props) => {
    
    const classes = styles();
    const [press, setPress] = useState({})

    useEffect(() => {
  
      const filterChecked = props?.valuesRanking?.find((x)=> x?.selected )
      setPress({press: `optionCheckbox${filterChecked?.position}`})
    }, [])
    

  const checkboxBigLeft = (num, value) => {
    return   <Box style={{display: 'flex', alignItems: 'center', flexDirection: props?.preview ? 'column' : null }}>
      <div className={classes.circulo}  name={`optionCheckbox${num}`} >
        <div  className={ press.press === `optionCheckbox${num}`? classes.circulo2 : null}></div>
      </div>
  </Box>
  }

  const checkboxBigStandard = (num, value) => {
    return   <Box style={{display: 'flex', alignItems: 'center', flexDirection: props?.preview ? 'column' : null}}>
    <div className={classes.circuloStand} name={`optionCheckbox${num}`} >
      <div  className={ press.press === `optionCheckbox${num}` ? classes.circuloStand2 : null}></div>
    </div>
  {props?.preview ? <Typography style={{fontSize: 14, color: 'rgb(142, 142, 142)', wordBreak: 'break-all'}}></Typography> : null}
  </Box>
  }

  const checkboxsmall = (num, value) => {
    return   <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: props?.preview ? 'column' : null}} maxWidth="sm">
  <div className={classes.circuloMed} name={`optionCheckbox${num}`} >
    <div  className={ press.press=== `optionCheckbox${num}` ? classes.circuloMed2 : null}></div>
  </div>
  </Box>
  }

  const checkboxBigRight = (num, value) => {
    return   <Box style={{display: 'flex', alignItems: 'center', flexDirection: props?.preview ? 'column' : null }}>
    <div className={classes.circulo} name={`optionCheckbox${num}`}>
    <div  className={ press.press === `optionCheckbox${num}` ? classes.circulo2 : null}></div>
  </div>
  </Box>
  }

  const checkbox = () => {
    

    if(props?.valuesRanking?.length === 5){
      return <Container alignItems="center" 
     style={{display: 'flex', margin: '25px auto', width: 700, alignItems: 'center'  }} 
     >
    <Grid lg={2}> <Typography style={{fontSize: 14, textAlign: 'end', color: "#8E8E8E"}}>{props?.valuesRanking[0]?.key}</Typography></Grid>
    <Grid lg={8} style={{display: 'flex', justifyContent: 'space-around'}}>
      {checkboxBigLeft(0, props?.valuesRanking[0]?.key)}
      {checkboxBigStandard(1, props?.valuesRanking[1]?.key)}
      {checkboxsmall(2, props?.valuesRanking[2]?.key)}
      {checkboxBigStandard(3, props?.valuesRanking[3]?.key)}
      {checkboxBigRight(4, props?.valuesRanking[4]?.key)}
   </Grid>
   <Grid lg={2}><Typography style={{fontSize: 14, color: "#8E8E8E", textAlign: 'start'}}>{props?.valuesRanking[4]?.key}</Typography></Grid>
   
   </Container >

    }
    if(props?.valuesRanking?.length === 7){
      return <Container alignItems="center" 
     style={{display: 'flex', margin: '25px auto', width: 700, alignItems: 'center'  }} 
     >
    <Grid lg={2}> <Typography style={{fontSize: 14, textAlign: 'end', color: "#8E8E8E"}}>{props?.valuesRanking[0]?.key}</Typography></Grid>
    <Grid lg={8} style={{display: 'flex', justifyContent: 'space-around'}}>
      {checkboxBigLeft(props?.valuesRanking[0]?.key)}
      {checkboxBigStandard(2, props?.valuesRanking[1]?.key)}
      {checkboxsmall(3, props?.valuesRanking[2]?.key)}
      {checkboxBigStandard(4, props?.valuesRanking[3]?.key)}
      {checkboxBigRight(7, props?.valuesRanking[4]?.key)}
   </Grid>
   <Grid lg={2}><Typography style={{fontSize: 14, color: "#8E8E8E", textAlign: 'start'}}>{props?.valuesRanking[6]?.key}</Typography></Grid>
   
   </Container >
      
    }
    if(props?.valuesRanking?.length === 9){
      return <Container alignItems="center" 
     style={{display: 'flex', margin: '25px auto', width: 700, alignItems: 'center'  }} 
     >
    <Grid lg={2}> <Typography style={{fontSize: 14, textAlign: 'end', color: "#8E8E8E"}}>{props?.valuesRanking[0]?.key}</Typography></Grid>
    <Grid lg={8} style={{display: 'flex', justifyContent: 'space-around'}}>
      {checkboxBigLeft(props?.valuesRanking[0]?.key)}
      {checkboxBigStandard(2, props?.valuesRanking[1]?.key)}
      {checkboxsmall(3, props?.valuesRanking[2]?.key)}
      {checkboxBigStandard(4, props?.valuesRanking[3]?.key)}
      {checkboxBigRight(8, props?.valuesRanking[4]?.key)}
   </Grid>
   <Grid lg={2}><Typography style={{fontSize: 14, color: "#8E8E8E", textAlign: 'start'}}>{props?.valuesRanking[8]?.key}</Typography></Grid>
   
   </Container >
    }
  
  }

  if(props?.valuesRanking?.length){
    return  checkbox()
  }else {
    return null
  }
 
};

export default Index;

