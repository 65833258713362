import React, { useState } from "react";
// import SecureRoute from "../seguridad/secure-route";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import { useStateValue } from "../../context/store";
import { makeStyles } from '@mui/styles';
import { CssBaseline, Grid } from "@mui/material";
import ModalActions from "../common/"
import Home from '../../Pages/home'
import Header from "../header/Header";
import SubHeader from "../header/subHeader";
import Labels from '../../Pages/label';
import Questions from '../../Pages/questions';
import CreateQuestionary from '../../Pages/questionary/createQuestionary';
import CreateQuestion from '../../Pages/questions/createQuestions'
import AplicationCuestionary from '../../Pages/questionary/aplicationQuestionnaire'
import LeftMenu from "../LeftMenu/LeftMenu";
import PerformanceEvaluation from "../../Pages/performanceEvaluation";
import PerformanceEvaluationEvaluated from "../../Pages/performanceEvaluation/evaluated"
import PerformanceForm from "../../Pages/createEvaluationPerformance";
import PerformanceCreate from "../../Pages/createEvaluationPerformance/relationShip";
import PerformanceEvaluationDetail from "../../Pages/performanceEvaluation/detail";
import PerformanceEvaluationEdit from "../../Pages/performanceEvaluation/edit";
import PerformanceEvaluationCopy from "../../Pages/performanceEvaluation/copyEvaluation";
import Competencies from "../../Pages/competencies";
import CompetenciesDetail from "../../Pages/competencies/detail";
import BehaviorCreate from "../../Pages/behavior/create";
import CreateCompetencie from "../../Pages/competencies/createCompetencies";
import FormAnswer from "../../Pages/visualizationQuestionnaire/form";
import DetaileAplication from '../../Pages/questionary/aplicationQuestionnaire/detailAplication';
import AnswerQuestionnaire from "../../Pages/visualizationQuestionnaire";
import NoMatchPage from "../../components/noMatchPage/NotMatchPageDashboard";
import AssessCuestionnaire from "../../Pages/assessCuestionnaire";
import Criteria from "../../Pages/criteriaEvaluation";
import AplicationEvaluation from "../../Pages/AplicationEvaluation";
import ManagementAplicationEvaluations from "../../Pages/managementAplications";
import EvaluatedsAplication from "../../Pages/managementAplications/individualResult/listEvaluateds";
import Pdf from "../../Pages/managementAplications/individualResult/listEvaluateds/totalEvaluated-PDF";
import ResultEvaluationsAdmin from "../../Pages/managementAplications/resultGeneral";
import ResultEvaluationsEvaluator from "../../Pages/managementAplications/individualResult/listEvaluators/resultEvaluator";
import DetailAplicationEvaluated from  "../../Pages/managementAplications/detail";
import ResultAplicationEvaluated from  "../../Pages/performanceEvaluation/evaluated/resultEvaluation";
import DetailAplicationUser from  "../../Pages/managementAplications/aplicatedUser";
import ResultEvaluation from  "../../Pages/performanceEvaluation/evaluated/resultEvaluation";
import AnswerEvaluationEvaluated from "../../Pages/performanceEvaluation/evaluated/answerEvaluation"
import CriteriaCreate from "../../Pages/criteriaEvaluation/create";
import Leaders from "../../Pages/evaluationType/listLeader";
import Organization from "../../Pages/evaluationType/listLeader"
import AplicateType from "../../Pages/evaluationType";
import TypesEvaluations from "../../Pages/evaluationType";
import TypesEvaluationsEvaluateds from "../../Pages/evaluationType/typesEvaluations";
import MainContent from "../MainContent/MainContent";
import Questionary from "../../Pages/questionary";
import SecureRoute from "../security/secureRoute"
import Behavior from "../../Pages/behavior";
import Footer from "../footer/Footer";



const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      flex: "1 1 auto",
      margin: "28% 3.5% 0% 3.5%",
      minHeight: "80vh",
    },
    [theme.breakpoints.up('md')]: {
      flex: "1 1 auto",
      margin: "15% 6% 0% 6%",
      minHeight: "80vh",
    },
    [theme.breakpoints.up('lg')]: {
      flex: "1 1 auto",
      margin: "12% 6% 0% 6%",
      minHeight: "80vh",
    },
   
  },
}));

const MainDashboard = (props) => {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const [{ breadCrumb }, ] = useStateValue();
  const [isOpen, setOpen] = useState(true);
  const [stateHome, setStateHome] = useState("");
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
     if (anchor) {
      setState("left", false);
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, left: open });
  };

  const handleDrawerClose = () => setOpen(false);
  const contenValueHome = (x) => setStateHome(x);


  return (
    <Grid className={classes.root}>
      <CssBaseline />
      <ModalActions/>
      <Header
        toggleDrawer={toggleDrawer}
        isOpen={isOpen}
        state={state}
        contenValueHome={contenValueHome}
        
      />
      {breadCrumb?.breadCrumbText === "Inicio" || null ? null : <SubHeader />}
      <LeftMenu
        handleDrawerClose={handleDrawerClose}
        toggleDrawer={toggleDrawer}
        isOpen={state}
        stateHome={stateHome}
      />
      <MainContent >
        <Grid id="pageContainer" className={classes.content}>
          <Switch>
          <SecureRoute
              exact
              path={`${path}/home`}
              component={Home}
              code='HOME'
            />
             <SecureRoute
              exact
              path={`${path}/labels`}
              component={Labels}
              code="LABEL"
            />
             <SecureRoute
              exact
              path={`${path}/questions`}
              component={Questions}
              code="QUESTION"
            />
             <SecureRoute
              exact
              path={`${path}/create-question`}
              component={CreateQuestion}
              code="CREATE_QUESTION"
            />
              <SecureRoute
              exact
              path={`${path}/questionary`}
              component={Questionary}
              code="QUESTIONARY"
            />
             <SecureRoute
              exact
              path={`${path}/questionary-create`}
              component={CreateQuestionary}
              code="QUESTIONARY_CREATE"
            />
            <SecureRoute
              exact 
              path={`${path}/aplication`}
              component={AplicationCuestionary}
              code="QUESTIONARY_APLICATION"
            />
             <SecureRoute
              exact 
              path={`${path}/aplication-detail`}
              component={DetaileAplication}
              code="DETAIL_APLICATION"
            />
             <SecureRoute
              exact 
              path={`${path}/assess-cuestionnaire`}
              component={AssessCuestionnaire}
              code="ASSESS_QUESTIONARY"
            />
             <SecureRoute
              exact 
              path={`${path}/answer-questionnaire`}
              component={AnswerQuestionnaire}
              code="ANSWER_QUESTIONARY"
            />
             <SecureRoute
              exact 
              path={`${path}/competencies`}
              component={Competencies}
              code="COMPETENCIES"
            />
            <SecureRoute
              exact 
              path={`${path}/competencies/detail`}
              component={CompetenciesDetail}
              code="COMPETENCIES"
            />
             <SecureRoute
              exact 
              path={`${path}/competencies-create`}
              component={CreateCompetencie}
              code="COMPETENCIES"
            />
             <SecureRoute
              exact 
              path={`${path}/behavior`}
              component={Behavior}
              code="BEHAVIORS"
            />
            <SecureRoute
              exact 
              path={`${path}/behavior-create`}
              component={BehaviorCreate}
              code="BEHAVIORS"
            />
            <SecureRoute
              exact 
              path={`${path}/behavior-edit/:id`}
              component={BehaviorCreate}
              code="BEHAVIORS" 
            />
            <SecureRoute
              exact 
              path={`${path}/evaluation-performance-evaluator`}
              component={PerformanceEvaluation}
              code="PERFORMANCE_EVALUATION" 
            />
             <SecureRoute
              exact 
              path={`${path}/evaluation-performance-evaluated`}
              component={PerformanceEvaluationEvaluated}
              code="PERFORMANCE_EVALUATION_EVALUATED" 
            />
             <SecureRoute
              exact 
              path={`${path}/evaluation-performance-evaluator/detail/:id`}
              component={PerformanceEvaluationDetail}
              code="PERFORMANCE_EVALUATION" 
            />
            <SecureRoute
              exact 
              path={`${path}/evaluation-performance-evaluator/edit/:id`}
              component={PerformanceEvaluationEdit}
              code="PERFORMANCE_EVALUATION" 
            />
             <SecureRoute
              exact 
              path={`${path}/evaluation-performance-evaluator/copy/:id`}
              component={PerformanceEvaluationCopy}
              code="PERFORMANCE_EVALUATION" 
            />
            <SecureRoute
              exact 
              path={`${path}/evaluation-performance`}
              component={PerformanceForm}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
            <SecureRoute
              exact 
              path={`${path}/evaluation-performance/create`}
              component={PerformanceCreate}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
            <SecureRoute
              exact 
              path={`${path}/criteria`}
              component={Criteria}
              code="CRITERIA" 
            />
            <SecureRoute
              exact 
              path={`${path}/evaluation-performance/evaluator/aplication/:id`}
              component={AplicationEvaluation}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
             <SecureRoute
              exact 
              path={`${path}/evaluation-performance/evaluator/aplication/:id/type-evaluation`}
              component={TypesEvaluations}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
            <SecureRoute
              exact 
              path={`${path}/evaluation-performance/evaluator/aplication/:id/type-evaluation/evaluateds`}
              component={TypesEvaluationsEvaluateds}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
            <SecureRoute
              exact 
              path={`${path}/evaluation-performance/evaluator/aplication-edit/:status/:id`}
              component={AplicationEvaluation}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
             <SecureRoute
              exact 
              path={`${path}/evaluation-performance/evaluator/aplication-edit/:status/:id/type-evaluation`}
              component={TypesEvaluations}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
            <SecureRoute
              exact 
              path={`${path}/evaluation-performance/evaluator/aplication-edit/:status/:id/type-evaluation/evaluateds`}
              component={TypesEvaluationsEvaluateds}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
             <SecureRoute
              exact 
              path={`${path}/evaluation-performance/evaluated/aplication/:id`}
              component={AnswerEvaluationEvaluated}
              code="PERFORMANCE_EVALUATION_EVALUATED" 
            />
            <SecureRoute
              exact 
              path={`${path}/evaluation-performance/evaluated/detail/:aplicationId/:userId`}
              component={DetailAplicationUser}
              code="PERFORMANCE_EVALUATION_EVALUATED" 
            />
             <SecureRoute
              exact 
              path={`${path}/evaluation-performance/evaluated/result/:aplicationId`}
              component={ResultAplicationEvaluated}
              code="PERFORMANCE_EVALUATION_EVALUATED" 
            />
            <SecureRoute
              exact 
              path={`${path}/criteria-create`}
              component={CriteriaCreate}
              code="CRITERIA" 
            />
             <SecureRoute
              exact 
              path={`${path}/answer-questionnaire/:id`}
              component={FormAnswer}
              code="ANSWER_QUESTIONARY_RESPONSE"
            />
             <SecureRoute
              exact 
              path={`${path}/management-aplication-evaluations`}
              component={ManagementAplicationEvaluations}
              code="MANAGEMENT_APLICATION_EVALUATIONS" 
            />
             <SecureRoute
              exact 
              path={`${path}/management-aplication-evaluateds/:applicationId/:evaluatedId?`}
              component={EvaluatedsAplication}
              code="MANAGEMENT_APLICATION_EVALUATIONS" 
            />
             <SecureRoute
              exact 
              path={`${path}/management-aplication-evaluateds/:applicationId/:evaluated/pdf`}
              component={Pdf}
              code="MANAGEMENT_APLICATION_EVALUATIONS" 
            />
            <SecureRoute
              exact 
              path={`${path}/management-aplication-evaluations/results/:aplicationId`}
              component={ResultEvaluationsAdmin}
              code="MANAGEMENT_APLICATION_EVALUATIONS" 
            />
            <SecureRoute
              exact 
              path={`${path}/management-aplication-evaluations/detail/:id`}
              component={DetailAplicationEvaluated}
              code="MANAGEMENT_APLICATION_EVALUATIONS"
            />
             <SecureRoute
              exact 
              path={`${path}/management-aplication-evaluations/detail/:aplicationId/:userId`}
              component={ResultEvaluation}
              code="MANAGEMENT_APLICATION_EVALUATIONS" 
            />
            <SecureRoute
              exact 
              path={`${path}/evaluation-performance/type`}
              component={AplicateType}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
             <SecureRoute
              exact 
              path={`${path}/evaluation-performance/type/organitations-leaders`}
              component={Leaders}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
             <SecureRoute
              exact 
              path={`${path}/management-aplication-evaluateds/:applicationId/:evaluatorId/:evaluatedId/:typeEvaluation`}
              component={ResultEvaluationsEvaluator}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
            <SecureRoute
              exact 
              path={`${path}/organization-chart`}
              component={Organization}
              code="PERFORMANCE_EVALUATION_CREATE" 
            />
            <Route component={NoMatchPage} />
          </Switch>
        </Grid>
        <Footer />
      </MainContent>
    </Grid>
  );
};

export default MainDashboard;
