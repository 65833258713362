import React, {useState, useEffect} from "react";
import { Button, FormControl, Grid, Modal, TextField, Typography, FormHelperText, Box } from "@mui/material";
import { useStateValue } from "../../../../context/store";
import {useStyles} from "./styles";
import * as yup  from "yup";
import { REQUIRED_MESSAGE } from '../../../../constants';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createSection, updateSesion, getSesion } from "../../../../actions/Sections-action";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const ModalConfirmSection = ({
  description,
  open,
  handleClick,
  handleCloseSection,
  title,
  oneButtons,
  textButtonSubmit,
  setMessage,
  message,
  values,
  edit,
  openEdit
}) => {
  const classes = useStyles();
  const [{relations}, dispatch] = useStateValue();
  const [modalStyle] = React.useState(getModalStyle);
  const [, setSelectedCompetencies] = useState([])
  const [numberSection, setNumberSection ] = useState(1);
  
  

  const schema = yup.object().shape({
    name: yup.string()
    .min(1, 'Ingrese mínimo 1 caracter')
    .max(100, 'Ingrese máximo 100 caracteres')
    .required(REQUIRED_MESSAGE),
    description: yup.string()
    .min(1, 'Ingrese mínimo 1 caracter')
    .max(100, 'Ingrese máximo 100 caracteres')
    .required(REQUIRED_MESSAGE)
})


const {register, handleSubmit, setValue, clearErrors, formState: {errors}} = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
})

  useEffect(() => {
    setSelectedCompetencies([])
  }, [])

  useEffect(() => {
   if(edit) {
    setValue('name', values?.name)
    setValue('description', values?.description)
   }
  }, [open])
  

  const closeModal = () => setMessage({...message, open:false})


  const handleCloseSuccess = (x, result) => {

    if(edit) {

      const differentsSesion = relations?.dataSections?.filter((x)=> x?.uuid !== values?.id)
      const findSesionEdit = relations?.dataSections?.find((x)=> x?.uuid === values?.id)

      const getSesions = async() => {
        const result = await getSesion(values?.id)
        if (result && (result?.status >= 200 && result?.status <= 204)) {

             openEdit();

          const newSectionEdit = {...findSesionEdit, name: result?.data?.name, description: result?.data?.description }
            differentsSesion.push(newSectionEdit)
            dispatch({
              type: 'SET_RELATIONS_EVALUATION',
              data: relations?.data,
              dataSections: differentsSesion,
              sectionSelected: relations?.sectionSelected,
              behaviorSelected: relations?.behaviorSelected,
              competencieSelected: relations?.competencieSelected,
              selectedQuestions: relations?.selectedQuestions
            })

          }
          else if (result?.status > 399 ) {
              setMessage({
              open : true,
              description: `Ocurrió un error procesando su solicitud, inténtelo nuevamente`,
              handleClick : () => closeModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          } else {
              setMessage({
              open : true,
              description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
              handleClick : () => closeModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          }
      }

      getSesions()
      handleClickOut();
    }else {
      let currentSections = []
      if(relations?.dataSections?.length){
  
       currentSections = [...relations?.dataSections]
  
      } else {
       currentSections = [];
      }
 
      currentSections?.push({...x, position: relations?.dataSections?.length ? relations?.dataSections?.length+1 : 1, uuid: result?.data.uuid, competences: []})
        dispatch({
            type: 'SET_RELATIONS_EVALUATION',
            data: relations?.data,
            dataSections: currentSections,
            sectionSelected: relations?.sectionSelected,
            behaviorSelected: relations?.behaviorSelected,
            competencieSelected: relations?.competencieSelected,
            selectedQuestions: relations?.selectedQuestions
        })
      handleClickOut();
    }
    
  }


  const onSubmit = (data) => {

    if(edit) {
      const updateSesions = async() => {
        const result = await updateSesion(data, values?.id)
        if (result && (result?.status >= 200 && result?.status <= 204)) {
           handleCloseSuccess(data, result) 
          }
          else if (result?.status > 399 ) {
              setMessage({
              open : true,
              description: `Ocurrió un error procesando su solicitud, inténtelo nuevamente`,
              handleClick : () => closeModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          } else {
              setMessage({
              open : true,
              description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
              handleClick : () => closeModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          }
      }
  
      updateSesions()
    } else {
      setNumberSection(numberSection +1)
    

      const createSections = async() => {
        const result = await createSection(data)
        if (result && (result?.status >= 200 && result?.status <= 204)) {
          return handleCloseSuccess(data, result)
           
          }
          else if (result?.status > 399 ) {
              setMessage({
              open : true,
              description: `Ocurrió un error procesando su solicitud, inténtelo nuevamente`,
              handleClick : () => closeModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          } else {
              setMessage({
              open : true,
              description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
              handleClick : () => closeModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          }
      }
  
      createSections()
    }
  
  }

  const clearModal = () => {
    clearErrors(['name', 'description']);
    setValue('name', '')
    setValue('description', '');
    closeModal();
  }

  const handleClickOut = () => { 
    clearModal(); 
    if(edit){
      openEdit()
    }else {
      handleCloseSection();
    }
  }


  const bodyModal = (
    <Box style={modalStyle} className={classes.paper}>
        <form id="formSection" onSubmit={handleSubmit(onSubmit)}>
            <Typography  className={classes.titleOpenSection} >{ edit ? 'Editar Sección' : 'Agregar sección'}</Typography>
            <Grid container>
                <Grid item lg={12} md={12}>
                    <FormControl variant='outlined' fullWidth >
                        <TextField
                            {...register('name')}
                            type="text"
                            style={{margin: 5}}
                            className={classes.dropdown}
                            fullWidth
                            placeholder="Nombre de la sección"
                            size="small"
                            label="Nombre sección"
                            error={!!errors.hasOwnProperty('name') && errors['name'].message}     
                        />
                        <FormHelperText style={{color:'#d70909'}}>
                            {
                                !!errors.hasOwnProperty("name") &&
                                errors["name"].message
                            }
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item lg={12} md={12}>
                    <FormControl variant='outlined' fullWidth >
                        <TextField
                            {...register('description')}
                            type="text"
                            style={{margin: 5}}
                            className={classes.dropdown}
                            fullWidth
                            placeholder="Descripción"
                            size="small"
                            label="Descripción"
                            error={!!errors.hasOwnProperty('description') && errors['description'].message}
                        />
                        <FormHelperText style={{color:'#d70909'}}>
                            {
                                !!errors.hasOwnProperty("description") &&
                                errors["description"].message
                            }
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item lg={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: errors['description'] && errors['name'] ? '0%' :'3%'}}>
                    <Button
                        className={ classes.cancel1 }
                        variant="outlined"
                        onClick={()=> handleClickOut()}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        form="formSection"
                        style={{ color: "#ffffff", background: '#2F71CB',  marginLeft: 5}}
                        >
                           {edit ? 'EDITAR' : 'AGREGAR'}
                    </Button>
                </Grid>
            </Grid>
        </form>
    
    </Box>
  );

  
  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
};

export default ModalConfirmSection;
