import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
   icons:  {
        fontSize: 50,
        color: '#646464'
      },
    date: {
        '& .css-u0soqy-MuiPickerStaticWrapper-root': {
            width: 'fit-content', 
            marginLeft: 'auto'
        },
        '& .css-15dbmtb-MuiPickersFadeTransitionGroup-root-MuiCalendarPicker-viewTransitionContainer': {
            height: '235px',
            overflowY: 'hidden',
        },
        '& .css-xelq0e-MuiPickerStaticWrapper-content': {
            borderRadius: 15,
            // boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
            minWidth: 280
        },
        '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
            width: 30,
            height: 30
        },
        '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
            width: 30,
            height: 30
        },
        '& .css-169iwlq-MuiCalendarPicker-root': {
            width: 270,
            margin: 'auto'
        },
        '& .css-ggw2de-MuiPickersDay-root': {
            width: 30,
            height: 30
        },
        '& .css-epd502': {
            width: 270,
        },
        '& .css-1brzq0m': {
            width: 270,
        },
        '& .css-4l7j15': {
            borderRadius: 15,
            // boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
            minWidth: 280
        },
        '& .css-ub1r1 ':{
            height: 30,
            width: 30
        },
        '& .css-mvmu1r': {
            margin: '0px 5%'
        },
        '& .css-1cnkspq': {
            minHeight: 200,
        },
        '& .css-10qkmc3': {
            width: 30
        },
        '& .css-z9mn4z': {
            width: 30
        },
        '& .css-f09ynp ': {
            maxHeight: 240
        }
    },
    containerButton: {
        borderRadius: 15,
        height: 160,
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '1px 0px 1px 4px rgba(0, 0, 0, 0.2)'
        }
    },
    containedCardIcon: {
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        padding: '5% 0px 0px 0px'
    },
    containedTitle: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        padding: '5% 5px 0px 5px'
    },
    titleButton: {
        fontSize: 20, 
        wordBreak: 'break-word', 
        textAlign: 'center'
    }
})