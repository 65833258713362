import { makeStyles } from '@mui/styles';


export const styles = makeStyles({
  dropdownMultiple: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
        '& .css-kx13b2-MuiInputBase-root-MuiOutlinedInput-root': {
          background: '#ffffff',
        },
        '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
          background: '#dfe7fd',
        },
    },
    error: {
      "&:invalid": {
        border: "red solid 2px"
      }
      
    },
    messageInformative: {
      fontSize: 14,
      margin: '2% 0px 0px 10%',
      color: '#7c7575'
    },
    question: {
      fontSize: 14,
      fontWeight: 400,
    },
    boxNumber: {
      marginRight: '10px',
      height: 35,
      backgroundColor: "#FFFFFF",
      color: '#bbb4b4',
      borderRadius: '5px',
      textAlign: 'center',
      border: '1px solid',
      opacity: [0.9, 0.8, 0.7],
      '&:hover': {
        backgroundColor: "#FFFFFF",
        opacity: [0.9, 0.8, 0.7],
        color: '#bbb4b4',
        textAlign: 'center',
        borderRadius: '5px',
        border: '1px solid',
      },
    }
})