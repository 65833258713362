import React, {useEffect} from 'react'
import { Grid, Typography, CardContent, Card } from '@mui/material'
import { useStateValue } from '../../../../../context/store'
import QuestionLinear from "./questionLinearScale";
import QuestionTex from "./questionText";
import {styles} from "./styles";
import _ from "lodash";

const Index = (props) => {
    const classes = styles();
    const [{ valorationAplication}, dispatch] = useStateValue()


    let number = 0; 
    let questionsOut = 0

    const loadData = () => {
        dispatch({
            type: "SET_VALORATE_APLICATION",
            disabledValors: valorationAplication?.disabledValors,
            data:  {competences :_.orderBy( props?.dataDetail?.performanceEvaluation?.competences,['position'],['asc'])?.map((x,index)=> (
                {
                    uuid: x?.competence?.uuid,  
                    value: '',  
                    position: index,
                    conducts: _.orderBy(x?.conducts, ['position'],['asc'])?.map((c, pos=index)=> (
                        {
                            uuid: c?.uuid,
                            position: pos,
                            value: '',
                            questions: c?.questions?.map((q, posQ=index)=> (
                                {
                                    uuid: q?.uuid,
                                    value: '',
                                    position: posQ,
                                    alternativeId: q?.questionAlternativeList[0]?.uuid
                                }
                            ))
                        }
                    ))       
                }
             ))}
         })
    };


    useEffect(() => {
       if(props?.disabled) {
        return null
       }else {
        return loadData()
       }
       
    }, [props?.dataDetail])
    

    const typeQuestion = (comp, beh, val) => {
        if(val?.answerAlternativeCode === 'ESL'){
            return  (
                <QuestionLinear 
                    competenceId={comp?.competence?.uuid}
                    behaviorId={beh?.uuid}
                    uuid={val?.uuid}
                    valuesRanking={val?.questionAlternativeList}
                    question={val?.question}
                    disabled={props?.disabled}
                /> 
            )
        }else if(val?.answerAlternativeCode === 'TEX'){
            return (
                <QuestionTex 
                    competenceId={comp?.competence?.uuid}
                    behaviorId={beh?.uuid}
                    uuid={val?.uuid}
                    question={val?.question}
                    disabled={props?.disabled}
                />
            )
        }
        
    }
    
  

    return (
     
        <Grid container spacing={2} justifyContent="center" >
            {_.orderBy(props?.dataDetail?.performanceEvaluation?.competences, ['position'],["asc"])?.map((c, )=> {

            return <Grid item lg={12} md={12} sm={12} xs={12}>
               
                <Card elevation={3} style={{ background:'rgb(239 239 239)', padding: '10px', marginBottom: '3%', borderRadius: 20}}>
            {_.orderBy(c?.conducts, ['position'],["asc"])?.map((b)=> {
            
                return <>
                {_.orderBy(b?.questions, ['position'],['asc'])?.map((x)=> {
                    
                    if(!c?.section){
                        questionsOut++
                    }
                    number++
                    
                    return <>
                     
                        <CardContent style={{background:  'rgb(239 239 239)' }}>
                    <Grid item container lg={12} md={12} sm={12} xs={12}>
                        <Grid item lg={12} sm={12} xs={12} style={{textAlign: 'center'}}>
                            <Typography className={classes.key} style={{ fontSize: 20, fontWeight: 400, background: '#B7C1F3'}}>{`Pregunta ${c?.section ? number : questionsOut}`} </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
                            <Typography className={classes.value} style={{ wordBreak: 'break-all', margin: ' 10px auto 20px auto'}}>{x?.question}</Typography>
                        </Grid>
                    </Grid> 
                    <Grid item={10}>
                        {typeQuestion(c, b, x)}
                    </Grid>
                    </CardContent> 
                    </>
                })}
                </>
            })}
            </Card>
           
        </Grid>
            })}
               
        </Grid>
    )
}

export default Index