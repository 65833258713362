import React, {useState, useEffect} from 'react'
import ListQuestions from '../form/listQuestions';
import Header from "./formResponseHeader"
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import ModalValorateAplication from '../../../components/common/modalValidateAnswer'
import { useStateValue } from "../../../context/store";
import { PATH_APP } from '../../../constants';

const Index = () => {
    const [, dispatch] = useStateValue()
    const [message, setMessage] = useState({
        description : '',
        textButtonSubmit : '',
        handleClick : () => {} ,
        handleClickOut : () => {},
        oneButtons : false,
        title: '',
        type : '',
        open : false
        })


      useEffect(() => {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Responder cuestionario",
        index: 6,
        subPath: `${PATH_APP}/answer-questionnaire`,
        isSubmit: true,
        idForm: 'formAplication',
        module: 'ANSWER_QUESTIONARY',
        textIsSubmit: "ENVIAR",
      });
    },[])


  return (
      <>
        <Header isAnswer={true} message={message} setMessage={setMessage}/>
         <ListQuestions 
            message={message}
            setMessage={setMessage}
        /> 
        <ModalConfirm 
            description = { message.description }
            textButtonSubmit = { message.textButtonSubmit }
            handleClick = { message.handleClick }
            handleClickOut = { message.handleClickOut }
            oneButtons = { message.oneButtons }
            title = { message.title }
            type = { message.type }
            open = { message.open }
        />
      <ModalValorateAplication />
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
      </>
   
  )
}

export default Index