import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
    subHeader: {
        color: '#8E8E8E',
        fontSize: 12
    },
    header: {
        color: '#8E8E8E',
        fontSize: 14
    },
    wrapper: {
        color: '#8E8E8E',
        fontSize: 14,
        fontWeight: 700
    },
    sizeCell:{
        padding: '0px 24px 0px 16px !important',
        "& .MuiTableCell-root": {
            padding: '0px 24px 0px 16px !important'
          },
    },
    iconErrorFieldLabel: {
        marginRight: '2%',
        marginBottom: '3%',
        fontSize: 'initial'
    },
    buttons: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        paddingBottom: '2%',
        marginTop: '1%'
    },
    table: {
        display: 'flex', 
        justifyContent: 'center', 
        color: '#8E8E8E',
        '& MuiList-root MuiList-padding MuiMenu-list css-6hp17o-MuiList-root-MuiMenu-list' :{
            display: 'flex',
            // '& .MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem':{
            //     display: 'flex'
            // },
        },
        '& .MuiTablePagination-actions': {
            flex: '0 0 auto',
          },
          '& .MuiTablePagination-selectRoot': {
            marginLeft: 'auto',
          },
        '& .MuiSelect-select':{
            color: 'gray',
            fontSize: 14
        },
        '& .MuiNativeSelect-select':{
            color: 'gray',
            fontSize: 14
        },
        '& .MuiIconButton-colorInherit': {
            padding: '0px !important'
        },
        '& .Mui-disabled': {
            padding: '0px !important'
        }
    }
  
})


