import HttpClient from '../services/HttpClient';
import {  ORGANIZATION_CHART, REGIONAL, POSITION, EMPLOYEE } from '../constants'

export const getRegionals = () => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${ORGANIZATION_CHART}${REGIONAL}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getPositions = () => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${ORGANIZATION_CHART}${POSITION}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getEmployees = (dataFilter, dispatch, typeEvaluator) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
       
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

        const keys = ['identificationKeyword', 'nameKeyword', 'positionName', 'regionalName' ];

        for (let index = 0; index < keys?.length; index++) {
            if(dataFilter[keys[index]] ){
                if(keys[index] === 'nameKeyword' && dataFilter[keys[index]]){
                  filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
                }else {
                  filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
                }
            } else {
                continue
            }
        }
        HttpClient.get(`${ORGANIZATION_CHART}${EMPLOYEE}${filters}&evaluatorType=TEACHER,CHAIR_TEACHER`).then(response => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            resolve(response);
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            if ( error?.response?.status === 400) {
                return dispatch({
                    type: "SET_MODAL_ACTION",
                    open: true,
                    icon:'WARNING',
                    message: 'Occurió un problema comuniquese con el administrador.'
                  })
               
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getEmployeesEvaluated = (dataFilter, dispatch, typeEvaluator) => {

    dispatch({ type: 'SET_ACTIVE_LOADING', active: true });
    return new Promise((resolve, reject) => {
       
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

        const keys = ['identificationKeyword', 'nameKeyword', 'positionName', 'regionalName', 'evaluatorType' ];

        for (let index = 0; index < keys?.length; index++) {
            if(dataFilter[keys[index]] ){
                if(keys[index] === 'nameKeyword' && dataFilter[keys[index]]){
                  filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
                }else {
                  filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
                }
            } else {
                continue
            }
        }
        HttpClient.get(`${ORGANIZATION_CHART}${EMPLOYEE}${filters}`).then(response => {
            dispatch({ type: 'SET_ACTIVE_LOADING', active: false });
            resolve(response);
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_LOADING', active: false });
            if ( error?.response?.status === 400) {
                return dispatch({
                    type: "SET_MODAL_ACTION",
                    open: true,
                    icon:'WARNING',
                    message: 'Occurió un problema comuniquese con el administrador.'
                  })
               
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getLeaders = (dataFilter, dispatch) => {
    return new Promise((resolve, reject) => {
       
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

        const keys = ['identificationKeyword', 'nameKeyword', 'positionName', 'regionalName' ];

        for (let index = 0; index < keys?.length; index++) {
            if(dataFilter[keys[index]] ){
                if(keys[index] === 'nameKeyword' && dataFilter[keys[index]]){
                  filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
                }else {
                  filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
                }
            } else {
                continue
            }
        }
        HttpClient.get(`${ORGANIZATION_CHART}${EMPLOYEE}${filters}&evaluatorType=TEACHER,CHAIR_TEACHER`).then(response => {
            resolve(response);
        }).catch(error => {
            if ( error?.response?.status === 400) {
                return dispatch({
                    type: "SET_MODAL_ACTION",
                    open: true,
                    icon:'WARNING',
                    message: 'Occurió un problema comuniquese con el administrador.'
                  })
               
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getEmployeesSelect = (dataFilter, dispatch) => {
    return new Promise((resolve, reject) => {
       
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

        const keys = ['identificationKeyword', 'nameKeyword', 'positionName', 'regionalName' ];

        for (let index = 0; index < keys?.length; index++) {
            if(dataFilter[keys[index]] ){
                if(keys[index] === 'nameKeyword' && dataFilter[keys[index]]){
                  filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
                }else {
                  filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
                }
            } else {
                continue
            }
        }
        HttpClient.get(`${ORGANIZATION_CHART}${EMPLOYEE}${filters}&evaluatorType=TEACHER,CHAIR_TEACHER`).then(response => {
            dispatch({
                type: 'SET_ACTIVE_LOADING',
                active: false
              })
            resolve(response);
        }).catch(error => {
            dispatch({
                type: 'SET_ACTIVE_LOADING',
                active: false
              })
            if (typeof error.response === 'undefined') {
                dispatch({
                    type: "SET_MODAL_ACTION",
                    open: true,
                    icon:'WARNING',
                    message: 'Occurió un problema comuniquese con el administrador.'
                  })
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getEmployeesLeaders = (dataFilter, dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_LOADING',
        active: true
      })
    return new Promise((resolve, reject) => {
       
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

        const keys = ['identificationKeyword', 'nameKeyword', 'positionName', 'regionalName' ];

        for (let index = 0; index < keys?.length; index++) {
            if(dataFilter[keys[index]] ){
                if(keys[index] === 'nameKeyword' && dataFilter[keys[index]]){
                  filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
                }else {
                  filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
                }
            } else {
                continue
            }
        }
        HttpClient.get(`${ORGANIZATION_CHART}${EMPLOYEE}${filters}`).then(response => {
            dispatch({
                type: 'SET_ACTIVE_LOADING',
                active: false
              })
            resolve(response);
        }).catch(error => {
            dispatch({
                type: 'SET_ACTIVE_LOADING',
                active: false
              })
            if (typeof error.response === 'undefined') {
                dispatch({
                    type: "SET_MODAL_ACTION",
                    open: true,
                    icon:'WARNING',
                    message: 'Occurió un problema comuniquese con el administrador.'
                  })
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const updateEmployee = (body) => {
    return new Promise((resolve, reject) => {
        HttpClient.patch(`${ORGANIZATION_CHART}${EMPLOYEE}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response)
        })
    })
}

