import React,{useState, useEffect} from 'react';
import {Grid,
    FormControl,
    TextField,
    
} from "@mui/material";
import { useStateValue } from "../../../../../context/store";
import {styles} from "./style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Index = (props) => {
    const classes = styles();
    const [{ breadCrumb, valorationQuestionnaire, valorateAplication, disabledQuestionary, dataAplication }, dispatch ] = useStateValue();
    const [validate, setValidate] = useState(false)
    const [valueQuestion, setValueQuestion] = useState()

    
    const schema = yup.object().shape({
     
      valueTotalQuestion: dataAplication?.data?.associatedQuestions?.length === 1 ? 
      yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,3}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
      .test("maxLenght", "Ingrese un valor entre 1 y 100", val => val <= 100 && val >= 1)
    : 
      yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,2}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
    })
    


    const { register, handleSubmit, control, trigger, unregister, clearErrors, formState:{errors}, setValue, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });
    
    const handleOnchange = (e)=> {
      const {name, value} = e.target;
     
      setValue(name, value)
      trigger(name)
      setValueQuestion(value)

      const findUuid= valorateAplication?.data.find((x)=> x?.uuid === `${props?.uuid}`)
      const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)

       // agregando nuevo valor aplicado a la pregunta y sus opciones cuando es manual
    
      dispatch({
        type: "SET_MODAL_VALORATE_APLICATION",
        icon:'WARNING',
        data: valorateAplication?.data ?  
        [...filterUuid, 
          {
            uuid: `${props?.uuid}`, 
            questionAlternativeList: [],
            valueApplied: Number(value)
          }
        ] 
          : [{ uuid: `${props?.uuid}`, 
          questionAlternativeList: [],
          valueApplied: Number(value)}],
      })
    }
    
    useEffect(()=> {
        if(valorationQuestionnaire?.typeValoration === 'automatic'){
        dispatch({
          type: "SET_VALUE_QUESTIONNAIRE",
          totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
          typeValoration: 'automatic',
          activeValoration: false
        });
      
        setValueQuestion(valorationQuestionnaire?.totalValueQuestionnaire ? (valorationQuestionnaire?.totalValueQuestionnaire)?.toFixed(2) : '')
        setValue('valueTotalQuestion', valorationQuestionnaire?.totalValueQuestionnaire ? (valorationQuestionnaire?.totalValueQuestionnaire)?.toFixed(2) : '')
        }else {
          dispatch({
            type: "SET_VALUE_QUESTIONNAIRE",
            totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
            typeValoration: 'manual',
            activeValoration: false
          });
          setValue('valueTotalQuestion', '')
          setValueQuestion('')
        } 
      },[valorationQuestionnaire?.activeValoration ])

      const onSubmit= () => {
        dispatch({
              type: "SET_MODAL_VALORATE_APLICATION",
              activeValorate: true,
              dataClear: valorateAplication?.data ?  valorateAplication?.data :[],
              data: valorateAplication?.data ?  valorateAplication?.data :[]
            })
      }

    return  <form id="formAplication" onSubmit={handleSubmit(onSubmit)}>
    <Grid container spacing={1} justifyContent="center" alignItems="center" style={{ display: 'flex', justifyContent: 'flex-start' }} >
            <Grid item lg={12} style={{display: 'flex'}}>
              <Grid item lg={10}>
                <FormControl variant='outlined' fullWidth size="small">
                  <TextField
                    disabled={true}
                    className={classes.dropdown}
                    fullWidth
                    multiline
                    maxRows={65}
                    value={props?.question}
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item lg={1} style={{display: 'flex', marginLeft: '3%'}}>
                <FormControl variant='outlined' fullWidth size="small">
                  <TextField
                   {...register('valueTotalQuestion')}
                    className={classes.dropdown}
                    fullWidth
                    size="small"
                    placeholder='Valor'
                    onChange={(e)=>handleOnchange(e)}
                    error={!!errors.hasOwnProperty("valueTotalQuestion") && errors["valueTotalQuestion"].message}
                    disabled={valorationQuestionnaire?.totalValueQuestionnaire || props?.isDetail || !valorationQuestionnaire?.typeValoration|| disabledQuestionary?.disabled}
                    value={props?.isDetail ? props?.valueQuestion : valueQuestion}
                  />
                </FormControl>
              </Grid>
            </Grid>
           <Grid lg={9.9} style={{margin: '15px 0px 10px 1%'}}>
                <FormControl fullWidth>
                  <>
                    <TextField
                     className={classes.dropdownMultiple}
                     multiline
                     placeholder="Respuesta de texto"
                     disabled={true}
                     rows={4}
                     InputLabelProps={{
                         shrink: true,
                         notched: true
                     }}
                     rowsMax={4}
                     label="Respuesta"
                    />
                    <div className={classes.accountantPreview}>0/500</div>
                    </>
                </FormControl>
            </Grid>
         </Grid>
         </form>
};

export default Index;
