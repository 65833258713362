export const initialState = {
    data: [],
    dataSections: [],
    sectionSelected: '',
    competencieSelected: '',
    behaviorSelected: '',
    backToForm: false,
    // isFullValue: false
  };
  
  const relationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_RELATIONS_EVALUATION":
        return {
          ...state,
          data: action.data,
          dataSections: action.dataSections,
          sectionSelected: action.sectionSelected,
          competencieSelected: action.competencieSelected,
          behaviorSelected: action.behaviorSelected,
          backToForm: action.backToForm
        //   isFullValue: action.isFullValue,
        };
      default:
        return state;
    }
  };
  
  export default relationsReducer;
  