export const initialState = {
    data: {},
  };
  
  const sendDataToPageReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SEND_DATA_TO_PAGE":
        return {
          ...state,
          data: action.data,
        };
      default:
        return state;
    }
  };
  
  export default sendDataToPageReducer;
  