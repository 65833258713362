import { makeStyles } from '@mui/styles';
import theme from "../../../../theme/theme";


export const styles = makeStyles({
    dropdownMultipleRanking: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
        [`& .css-1o6z5ng.Mui-disabled`]: {
          padding: '8.5px 8px !important',
          textAlign: 'end'
        },
        '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input' :{
          padding: '8.5px 8px !important',
          textAlign: 'end'
        }
      },
      dropdownMultiple: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
      },
})