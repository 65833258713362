import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TRASLATE from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
    footer: {
        textAlign: 'center',
        padding: theme.spacing(1, 2),
        flex: '0 1 40px'
    },
    copyright: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '10px',
        lineHeight: '22px',
        color: '#616161'
    },
    policy: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '9px',
        lineHeight: '18px',
        color: '#6B6C6F',
        mixBlendMode: 'normal',
        opacity: '0.5'
    }

}));

const Footer = () => {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <Grid>
                <Typography className={classes.copyright} >
                    {TRASLATE.FOOTER.COPY_RIGHT_MSG}
                </Typography>
                <Typography className={classes.policy}>
                    {TRASLATE.FOOTER.DATA_PROTECTION_POLICY_MSG}
                </Typography>
            </Grid>
        </footer>
    );
};

export default Footer;
