import React, {useEffect, useState} from 'react'
import { Grid, Typography, CardContent, Card, TextField, FormControl, FormHelperText, Paper } from '@mui/material'
import { useStateValue } from '../../../context/store'
import QuestionLinear from "../../../components/common/detailEvaluation/questionLinearScale";
import QuestionText from "../../../components/common/detailEvaluation/questionText";
import { PATH_APP } from '../../../constants';
import {styles} from "./styles";
import _ from "lodash";
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validWithRol } from '../../../utils/constants';
import { titleHeader } from './../../../constants';


const Index = (props) => {
  
    const classes = styles();

    const [{ valorationAplication, typesEvaluations, relations}, dispatch] = useStateValue()
    const [dataYup, setDataYup] = useState({})
    const [valuesForm, setValuesForm ]= useState({});


    const schema = yup.object().shape(dataYup)
    const {register, setValue, trigger, watch, formState:{errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onTouched',
        reValidateMode: 'onChange'
    });
   

    const typeslocal = localStorage.getItem('typesEvaluation')
    const jsonTypes =  JSON.parse(typeslocal)   

    useEffect(() => {
        let newObject = {}
        for (let index = 0; index < props?.dataDetail?.competences?.length; index++) {
          newObject = Object.assign(newObject, 
            {[props?.dataDetail?.competences[index]?.competence?.uuid]: 
                yup
                .string()
                .required('Requerido')
                .matches(
                /^[0-9]{1,3}(\.[0-9]{0,2})?$/g,
                "Ejm: 10.30"
                )
                .test("maxLenght", "Invalido", val => val <= 100 )
            } )
           
            for (let initial = 0; initial < props?.dataDetail?.competences[index]?.conducts?.length; initial++) {
               
                newObject = Object.assign(newObject, 
                    {[`${props?.dataDetail?.competences[index]?.competence?.uuid}${initial}`]: 
                        yup
                        .string()
                        .required('Requerido')
                        .matches(
                        /^[0-9]{1,3}(\.[0-9]{0,2})?$/g,
                        "Ejm: 10.30"
                        )
                        .test("maxLenght", "Invalido", val => val <= 100 )
                    } )
            }
           
        }
        dispatch({
            type: "SET_VALORATE_APLICATION",
            disabledValors: valorationAplication?.disabledValors,
            data:  {competences :props?.dataDetail?.competences?.map((x,index)=> (
                {
                    uuid: x?.competence?.uuid,  
                    value: '',  
                    position: index,
                    conducts:  x?.conducts?.map((c, pos=index)=> (
                        {
                            uuid: c?.uuid,
                            position: pos,
                            value: '',
                            questions: c?.questions?.map((q, posQ=index)=> (
                                {
                                    uuid: q?.uuid,
                                    value: '',
                                    position: posQ
                                }
                            ))
                        }
                    ))       
                }
             ))}
         })
       
         setDataYup(newObject)
    }, [])
    

    useEffect(()=> {

        if(dataYup){
            if(jsonTypes?.dataEvaluation?.competences?.length){
                for (let index = 0; index < props?.dataDetail?.competences?.length; index++) {
           
                  setValue(jsonTypes?.dataEvaluation?.competences[index]?.uuid, (Number(jsonTypes?.dataEvaluation?.competences[index]?.valueApplied)).toFixed(2) )
                  trigger(jsonTypes?.dataEvaluation?.competences[index]?.uuid)
                     for (let b = 0; b < jsonTypes?.dataEvaluation?.competences[index]?.conducts?.length; b++) {
                        
                       setValue(`${jsonTypes?.dataEvaluation?.competences[index]?.uuid}${b}`, (Number(jsonTypes?.dataEvaluation?.competences[index]?.conducts[b]?.valueApplied)).toFixed(2) )
                       trigger(`${jsonTypes?.dataEvaluation?.competences[index]?.uuid}${b}`)
    
                     }
                 }
    
                 dispatch({
                    type: "SET_VALORATE_APLICATION",
                    disabledValors: jsonTypes?.dataEvaluation?.automatic,
                    data:  {competences :jsonTypes?.dataEvaluation?.competences?.map((x,index)=> (
                        {
                            uuid: x?.uuid,  
                            value: x?.valueApplied,  
                            position: index,
                            conducts:  x?.conducts?.map((c, pos=index)=> (
                                {
                                    uuid: c?.uuid,
                                    position: pos,
                                    value: c?.valueApplied,
                                    questions: c?.questions?.map((q, posQ=index)=> (
                                        {
                                            uuid: q?.uuid,
                                            value: '',
                                            position: posQ
                                        }
                                    ))
                                }
                            ))       
                        }
                     ))}
                 })
           }
        }

    },[dataYup])


    useEffect(() => {
        const typeslocal = localStorage.getItem('typesEvaluation')
        const jsonTypes =  JSON.parse(typeslocal)
        if(typesEvaluations?.dataEvaluation?.competences?.length){
            dispatch({
                type: "SET_VALORATE_APLICATION",
                disabledValors: typesEvaluations?.dataEvaluation?.automatic,
                typeValoration: typesEvaluations?.dataEvaluation?.automatic ? 'Automatic' : 'Manual',
                data:  {competences :typesEvaluations?.dataEvaluation?.competences?.map((x,index)=> (
                    {
                        uuid: x?.uuid,  
                        value: x?.valueApplied,  
                        position: index,
                        conducts:  x?.conducts?.map((c, pos=index)=> (
                            {
                                uuid: c?.competence?.uuid,
                                position: pos,
                                value: c?.valueApplied,
                                questions: c?.questions?.map((q, posQ=index)=> (
                                    {
                                        uuid: q?.uuid,
                                        value: '',
                                        position: posQ
                                    }
                                ))
                            }
                        ))       
                    }
                 ))}
             })
             dispatch({
                type: "SET_TYPES_EVALUATIONS",
                automatic: typesEvaluations?.automatic,
                applicationTypes: typesEvaluations?.applicationTypes,
                submitPercentage: typesEvaluations?.submitPercentage,
                selectAll: typesEvaluations?.selectAll,
                users: typesEvaluations?.users,
                dataEvaluation: [],
                pages: typesEvaluations?.pages,
                selectNewLeaders: typesEvaluations?.selectNewLeaders
              });
        }else if(valorationAplication?.typeValoration === "Automatic"){
            for (let index = 0; index < props?.dataDetail?.competences?.length; index++) {
        
               setValue(valorationAplication?.data?.competences[index]?.uuid, (Number(valorationAplication?.data?.competences[index]?.value)).toFixed(2) )
               trigger(valorationAplication?.data?.competences[index]?.uuid)
                  for (let b = 0; b < valorationAplication?.data?.competences[index]?.conducts?.length; b++) {
                     
                    setValue(`${valorationAplication?.data?.competences[index]?.uuid}${b}`, (Number(valorationAplication?.data?.competences[index]?.conducts[b]?.value)).toFixed(2) )
                    trigger(`${valorationAplication?.data?.competences[index]?.uuid}${b}`)

                  }
              }
            if(jsonTypes?.dataEvaluation?.competences?.length){
                for (let index = 0; index < props?.dataDetail?.competences?.length; index++) {
           
                  setValue(jsonTypes?.dataEvaluation?.competences[index]?.uuid, (Number(jsonTypes?.dataEvaluation?.competences[index]?.valueApplied)).toFixed(2) )
                  trigger(jsonTypes?.dataEvaluation?.competences[index]?.uuid)
                     for (let b = 0; b < jsonTypes?.dataEvaluation?.competences[index]?.conducts?.length; b++) {
                        
                       setValue(`${jsonTypes?.dataEvaluation?.competences[index]?.uuid}${b}`, (Number(jsonTypes?.dataEvaluation?.competences[index]?.conducts[b]?.valueApplied)).toFixed(2) )
                       trigger(`${jsonTypes?.dataEvaluation?.competences[index]?.uuid}${b}`)
    
                     }
                 }
            }
        } else if(valorationAplication?.typeValoration === "Manual"){
            
            for (let index = 0; index < props?.dataDetail?.competences?.length; index++) {
        
                setValue(valorationAplication?.data?.competences[index]?.uuid, '')
              
                   for (let b = 0; b < valorationAplication?.data?.competences[index]?.conducts?.length; b++) {
                      
                     setValue(`${valorationAplication?.data?.competences[index]?.uuid}${b}`, '' )
                     
 
                   }
               }
            if(jsonTypes?.dataEvaluation?.competences?.length){
                for (let index = 0; index < props?.dataDetail?.competences?.length; index++) {
                  setValue(jsonTypes?.dataEvaluation?.competences[index]?.uuid, '' )

                     for (let b = 0; b < jsonTypes?.dataEvaluation?.competences[index]?.conducts?.length; b++) {
                       setValue(`${jsonTypes?.dataEvaluation?.competences[index]?.uuid}${b}`, '' )
    
                     }
                 }
            }
        } else {
            return null
        }

    }, [valorationAplication?.typeValoration])
    


    const handleOnchange = (e) => {
        const {name, value} = e.target;
        setValuesForm({...valuesForm, [name]: value})

        setValue(name, value)
        trigger(name)

       const findCompetence = valorationAplication?.data?.competences?.find((x)=> x?.uuid === name)
       let differentCompetence = valorationAplication?.data?.competences?.filter((x)=> x?.uuid !== name) 

        differentCompetence?.push({...findCompetence, value: value})
        dispatch({
            type: "SET_VALORATE_APLICATION",
            disabledValors: valorationAplication?.disabledValors,
            data: {competences : _.orderBy(differentCompetence, ['position'],["asc"])}
        })
    }
    const handleOnchangeBehavior = (e, id, com) => {
        const {name, value} = e.target;
        setValuesForm({...valuesForm, [name]: value})

        setValue(name, value)
        trigger(name)


        const findCompetence = valorationAplication?.data?.competences?.find((x)=> x?.uuid === com)
        let differentCompetence = valorationAplication?.data?.competences?.filter((x)=> x?.uuid !== com) 
        if(findCompetence) {
    
            const findBehavior = findCompetence?.conducts?.find((x)=> x?.uuid === id)
            const differentBehavior = findCompetence?.conducts?.filter((x)=> x?.uuid !== id)
    
            differentBehavior?.push({...findBehavior, value: value})
            const updateComptence = {...findCompetence, conducts: _.orderBy(differentBehavior, ['position'],["asc"])}
            differentCompetence.push(updateComptence)
    
    
            dispatch({
                type: "SET_VALORATE_APLICATION",
                disabledValors: valorationAplication?.disabledValors,
                data: {competences :_.orderBy(differentCompetence, ['position'],["asc"])}
            })
        }

   
    }


    const typeQuestion = (comp, beh, val) => {
        if(val?.answerAlternativeCode === 'ESL'){
            return  (
                <QuestionLinear 
                    competenceId={comp?.competence?.uuid}
                    behaviorId={beh?.uuid}
                    uuid={val?.uuid}
                    valuesRanking={val?.questionAlternativeList}
                    question={val?.question}
                /> 
            )
        }else if(val?.answerAlternativeCode === 'TEX'){
            return (
                <QuestionText 
                    competenceId={comp?.competence?.uuid}
                    behaviorId={beh?.uuid}
                    uuid={val?.uuid}
                    question={val?.question}
                />
            )
        }
        
    }

    return (
     
        <Grid container spacing={2} justifyContent="center" >
            {props?.dataDetail?.competences?.sort((a, b)=> Number(a?.position) - Number(b?.position) )?.map((c,index)=> (
                <Grid item lg={12}>
                <Card elevation={3} style={{ background:'rgb(239 239 239)', padding: '10px', marginBottom: '3%', borderRadius: 20}}>
                <CardContent style={{background:  'rgb(239 239 239)' }}>
                    <Grid item lg={12} md={12}>
                        <Typography style={{textAlign: 'center', fontSize: 20, fontWeight: 400, background: 'rgba(154, 187, 100, 0.46)'}}>{`Criterio ${index+1}`} </Typography>
                    </Grid>
                    <Grid item lg={12} md={12}>
                        <Typography style={{textAlign: 'justify', fontSize: 14, fontWeight: 400, color: '#8E8E8E', margin: ' 10px auto 20px auto'}}>{c?.competence?.name} </Typography>
                    </Grid>
                    <Grid item lg={12} style={{display: 'flex'}}>
                        <Typography style={{color: '#6E6E6E', fontSize: 20, fontWeight: 900, marginBottom: '1%', marginRight: 20}}>VALOR :</Typography>
                        <Grid item xs={12} sm={12} md={12} lg={1}>
                            <FormControl variant='outlined' fullWidth size="small">
                                <TextField
                                {...register(c?.competence?.uuid)}
                                name={c?.competence?.uuid}
                                className={classes.dropdown}
                                fullWidth
                                onChange={handleOnchange}
                                disabled={valorationAplication?.disabledValors}
                                InputLabelProps={{
                                    shrink: watch(c?.competence?.uuid) ? true : false,
                                    notched: watch(c?.competence?.uuid) ? true : false,
                                }}
                                size="small"
                                label="Valor"
                                error={!!errors.hasOwnProperty(c?.competence?.uuid) && errors[c?.competence?.uuid].message}
                                />
                            </FormControl>
                            <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                                {errors.hasOwnProperty(c?.competence?.uuid) &&
                                    errors[c?.competence?.uuid].message}
                            </FormHelperText> 
                        </Grid>
                    </Grid>
                {_.orderBy(c?.conducts, ['position'],["asc"])?.map((b, posB=index)=> (
                    <>
                    <Grid item lg={12} md={12} >
                        <Typography style={{textAlign: 'center', fontSize: 20, fontWeight: 400, background: 'rgba(245, 178, 117, 0.55)'}}>{`Aspecto ${posB+1}`} </Typography>
                    </Grid>
                    <Grid item lg={12} md={12}>
                        <Typography style={{textAlign: 'justify', fontSize: 14, fontWeight: 400, color: '#8E8E8E', margin: ' 10px auto 20px auto'}}>{b?.name} </Typography>
                    </Grid>
                    <Grid item lg={12} style={{display: 'flex'}}>
                        <Typography style={{color: '#6E6E6E', fontSize: 20, fontWeight: 900, marginBottom: '1%', marginRight: 20}}>VALOR :</Typography>
                        <Grid item xs={12} sm={12} md={12} lg={1}>
                            <FormControl variant='outlined' fullWidth size="small">
                                <TextField
                                {...register(`${c?.competence?.uuid}${posB}`)}
                                name={`${c?.competence?.uuid}${posB}`}
                                className={classes.dropdown}
                                fullWidth
                                disabled={valorationAplication?.disabledValors }
                                onChange={(e) => handleOnchangeBehavior(e,b?.uuid, c?.competence?.uuid)}
                                InputLabelProps={{
                                    shrink: watch(`${c?.competence?.uuid}${posB}`) ? true : false,
                                    notched: watch(`${c?.competence?.uuid}${posB}`) ? true : false,
                                }}
                                size="small"
                                label="Valor"
                                error={!!errors.hasOwnProperty(`${c?.competence?.uuid}${posB}`) && errors[`${c?.competence?.uuid}${posB}`].message}
                                />
                            </FormControl>
                            <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                                {errors.hasOwnProperty(`${c?.competence?.uuid}${posB}`) &&
                                    errors[`${c?.competence?.uuid}${posB}`].message}
                            </FormHelperText> 
                        </Grid>
                    </Grid>
                    {/* {_.orderBy(b?.questions, ['position'],["asc"])?.map((x, index)=> (
                        <>
                        <Grid item container lg={12} md={12} >
                            <Grid item lg={12} style={{textAlign: 'center'}}>
                                <Typography className={classes.key} style={{ fontSize: 20, fontWeight: 400, background: '#B7C1F3'}}>{`Pregunta ${index+1}`} </Typography>
                            </Grid>
                            <Grid item lg={12} style={{textAlign: 'center'}}>
                                <Typography className={classes.value} style={{ wordBreak: 'break-all', margin: ' 10px auto 20px auto'}}>{x?.question}</Typography>
                            </Grid>
                        </Grid> 
                        <Grid item={10} style={{marginBottom: 30}}>
                            {typeQuestion(c, b, x)}
                        </Grid>
                        </>
                    ))} */}
                </>
                ))}
                </CardContent> 
            </Card>
                </Grid>
            ))}
        </Grid>
    )
}

export default Index