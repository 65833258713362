import React, {useState, useEffect} from 'react'
import { 
    FormControl, 
    Grid, 
    InputLabel, 
    OutlinedInput, 
    InputAdornment, 
    IconButton,
    Checkbox ,
    TextField,
    FormHelperText,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { styles } from '../../../styles'
import { useStateValue } from "../../../../context/store";
import { PATH_APP, REQUIRED_MESSAGE } from '../../../../constants';
import { useForm } from "react-hook-form";
import ModalPreview from '../../preview';
import FieldAssess from './assessUniqueChoise';
import FieldAnswer from './answerUniqueChoise';
import { yupResolver } from "@hookform/resolvers/yup";
import QuestionQuestionary from "./questionQuestionary";
import { createQuestion, updateQuestion } from '../../../../actions/question-action';
import * as yup from "yup";

const Unique = (props) => {
    const classes = styles();
  const [{ questionSearch }, dispatch] = useStateValue();
    const [valuesOptions, setValuesOptions ] = useState([
        { name: 'valueOption1', value: 1},
        { name: 'valueOption2', value: 2},
    ])
    const [optionsEdit, setOptionsEdit] = useState([])
    const [checked, setChecked] = useState({})
    const [historyOptions, setHistoryOptions] = useState([])



      useEffect(() => {
        if(props.oneSubmit){
          return dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Gestionar preguntas",
            index: 3,
            idForm: 'formInitial4',
            path: `${PATH_APP}/create-question`,
            textIsSubmit: "CREAR PREGUNTA",
          });
         
        }if(props.isAssess || props?.isAnswer || props?.showDetail || props?.isEdit){
          return null
         }
        else if(props.isQuestionary){
          return dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Crear cuestionario",
            index: 4,
            isSubmit: true,
            subPath: `${PATH_APP}/questionary`,
            textIsSubmit: "GUARDAR",
            idForm: 'Newary'
          });
        }else {
          submitNotDisabled()
        }
        }, [])

        useEffect(() => {
          if(props?.mapQuestionQuestionary?.answerAlternativeCode === "ELU" ){
            const newData = [];
            for (let index = 0; index < props?.mapQuestionQuestionary?.questionAlternativeList?.length; index++) {
              
              newData.push( { name: `valueOption${index +1}`, value: index +1})
         
              for (let index = 0; index < newData.length; index++) {
               setValue(`valueOption${index +1}`, props?.mapQuestionQuestionary?.questionAlternativeList[index]?.key)
              }
            }
           setValuesOptions(newData)
          }
          else if (props?.dataQuestion?.answerAlternativeCode === 'ELU') {
            const newData = [];
            const history = []
            for (let index = 0; index < props?.dataQuestion?.questionAlternativeList.length; index++) {
              
              newData.push( { name: `valueOption${index +1}`, value: index +1, uuid:props?.dataQuestion?.questionAlternativeList[index]?.uuid, operationType: null})
              history.push( { name: `valueOption${index +1}`, value: index +1, uuid:props?.dataQuestion?.questionAlternativeList[index]?.uuid, operationType: null})
         
              for (let index = 0; index < newData.length; index++) {
               setValue(`valueOption${index +1}`, props?.dataQuestion?.questionAlternativeList[index]?.key)
              }
            }
           setValuesOptions(newData)
           setOptionsEdit(history)
           setHistoryOptions(history)
          }
         }, [])

    const findNameField = (name) => {
        const find = valuesOptions?.find((x)=> x.name === name)
        return find?.name === name
    }


    const schema = yup.object().shape({
      valueOption1: findNameField('valueOption1') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(1000, 'Ingrese máximo 1000 caracteres') :  null,
      valueOption2: findNameField('valueOption2') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(1000, 'Ingrese máximo 1000 caracteres') :  null,
      valueOption3: findNameField('valueOption3') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(1000, 'Ingrese máximo 1000 caracteres') :  null,
      valueOption4: findNameField('valueOption4') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(1000, 'Ingrese máximo 1000 caracteres') :  null,
      valueOption5: findNameField('valueOption5') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(1000, 'Ingrese máximo 1000 caracteres') :  null,
      valueOption6: findNameField('valueOption6') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(1000, 'Ingrese máximo 1000 caracteres') :  null,
      valueOption7: findNameField('valueOption7') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(1000, 'Ingrese máximo 1000 caracteres') :  null,
      valueOption8: findNameField('valueOption8') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(1000, 'Ingrese máximo 1000 caracteres') :  null,
      valueOption9: findNameField('valueOption9') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(1000, 'Ingrese máximo 1000 caracteres') :  null,
      valueOption10: findNameField('valueOption10') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(1000, 'Ingrese máximo 1000 caracteres') :  null,
    });
    
    
    const { register, handleSubmit,  unregister, formState:{errors}, setValue, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });

    const submitNotDisabled = () => {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Crear pregunta",
        index: 3,
        subPath: `${PATH_APP}/questions`,
        isSubmit: true,
        idForm: props.getDataCreate ? 'prueba' :'formInitial4',
        disabledSubmit: false,
      });
    }

    const disabledSubmit = () => {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Crear pregunta",
        index: 3,
        subPath: `${PATH_APP}/questions`,
        isSubmit: true,
        idForm: 'formInitial4',
        disabledSubmit: true,
      });
    }

    const resetList = () => {
      dispatch({
      type: "SET_FILTER_QUESTION",
      filter: questionSearch?.filter,
      isFilter: true,
      typeAction: 'EDIT'
    })
   } 

   useEffect(() => {
      setChecked({})
   }, [props?.preview])


   const handleResetForm = (data) => {
    handleCloseModal();
    props.clearFields()
    if(props?.getDataCreate )props?.getDataCreate(data)
  }

    const closeModal = () =>  props.setMessage({...props.message, open:false})

    const handleCloseModal = () => {
      submitNotDisabled()
      props.setMessage({...props.message, open:false})
    }
    
    const handleCloseModalAndReset = () => {
      closeModal();
      props.setOpenModal()
      resetList()

    }

    const handleCloseModalUpdate = () => {
      submitNotDisabled()
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Gestionar preguntas",
        index: 3,
        idForm: 'formInitial4', 
        path: `${PATH_APP}/create-question`,
        textIsSubmit: "CREAR PREGUNTA",
      });
      closeModal()
    }

    const handleClickShowPassword = (e, number, info) => {
      if(valuesOptions.length === 2) {
        return null
      } else {
      unregister(e)
      const valuesOptionsnew = []
      const result = valuesOptions.filter((x)=> x.name !== e)
      const findOptionEdit = optionsEdit.find((x)=> x.uuid === info?.uuid)
      const filterOptionEdit = optionsEdit.filter((x)=> x.uuid !== info?.uuid)
      if(info?.uuid){
        const filterDelete = historyOptions?.filter((x) => x.uuid === info?.uuid )
        const updateOption = 
          {
            value: 0, 
            uuid: findOptionEdit?.uuid,
            operationType: 'DELETE'
          }
          setOptionsEdit([...filterOptionEdit, updateOption])
          setHistoryOptions(filterDelete)

      }
      
      for (let index = 0; index < result.length ; index++) {
       
        const item = result[index]
        const currentValue = watch(item?.name)
        valuesOptionsnew.push(currentValue)
      }
      const newValues = [];
      const valuesChangeOptions = valuesOptionsnew
      
      for (let index = 0; index < valuesChangeOptions.length; index++) {
        setValue(`valueOption${index+1}`, valuesChangeOptions[index])
        newValues.push({name: `valueOption${index+1}`, value: index+1, uuid: result[index]?.uuid ? result[index]?.uuid : null, operationType: result[index]?.operationType})
      }
      setValuesOptions(newValues)
      setHistoryOptions(result)

    }
    } 

    const onSubmit = (data) => {

      const currentData = []
      const dataRegister = [];
      const questionAlternativeRequest = [];

      if(props?.dataQuestion){
        for (let index = 0; index < props?.dataQuestion?.questionAlternativeList.length; index++) {
  
         const result = optionsEdit.find((x) => x?.uuid === props?.dataQuestion?.questionAlternativeList[index]?.uuid)
       
          if(result){
              if(result?.operationType === null ){
                const filterFieldId = valuesOptions?.find((x) => x.uuid === result?.uuid)
                const filterInProps = props?.dataQuestion?.questionAlternativeList.find((x) => x.uuid === result?.uuid)
                if(watch(filterFieldId?.name) === filterInProps?.key) {
                  currentData.push({
                    key:  watch(filterFieldId?.name),
                    uuid: result?.uuid,
                    value: 0,
                    operationType: null
                  }) 
                }else {
                  currentData.push({
                    key:  watch(filterFieldId?.name),
                    uuid: result?.uuid,
                    value: 0,
                    operationType: 'UPDATE'
                  })
                   continue
                }
              } else {
                currentData.push(result)
                continue
              }          
          } else {
            continue
          }
      }
    } 

      const lengthUuid = valuesOptions.filter((x)=> x?.uuid)
   
      for (let index = props?.dataQuestion ? lengthUuid.length : 0; index < valuesOptions.length; index++) {
        questionAlternativeRequest.push(data[`valueOption${index +1}`]) 
      }
      for (let index = 0; index < (valuesOptions.length -lengthUuid.length); index++) {
        dataRegister.push( props?.dataQuestion ?
          {key: questionAlternativeRequest[index], value: 0, operationType: 'CREATE'}
          : {key: questionAlternativeRequest[index], value: 0}
          ) 
      }
      
      const newDataEdit = currentData.concat(dataRegister)
 
      if( !props?.dataInitial?.tagUuid || !props?.dataInitial?.question || (props?.errorsHeaderQuestion?.question || props?.errorsHeaderQuestion?.label)){
        return props?.valuesForm()
      } else{
        const newData = {...props?.dataInitial, questionAlternativeRequest: dataRegister}
        
        const createQuestions = async() => {

          disabledSubmit()
          const result = await  createQuestion(newData, dispatch) 
          if (result && (result?.status >= 200 && result?.status <= 204)) {
            props.setMessage({
              open : true,
              type : 'SUCCESS',
              oneButtons : true,
              title: '¡Proceso exitoso!',
              textButtonSubmit: 'ACEPTAR',
              handleClick : () => handleResetForm(result?.data) ,
              description: 'Información almacenada correctamente ',
            })
          }
          else if (result?.status > 399) {
            props.setMessage({
              open : true,
              description: 'Error al guardar la información',
              handleClick : () => handleCloseModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          } else {
            props.setMessage({
              open : true,
              description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
              handleClick : () => handleCloseModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          }
       }
       const updateQuestions = async() => {
        const newData = {...props?.dataInitial, questionAlternativeRequest: newDataEdit}
        const result = await  updateQuestion(newData, props?.dataQuestion?.uuid) 
        if (result && (result?.status >= 200 && result?.status <= 204)) {
            props.setMessage({
              open : true,
              type : 'SUCCESS',
              oneButtons : true,
              title: '¡Proceso exitoso!',
              textButtonSubmit: 'ACEPTAR',
              handleClick : () => handleCloseModalAndReset() ,
              description: 'Información almacenada correctamente ',
            })

          }else if (result?.status === 400) {
            props.setMessage({
              open : true,
              description: "La pregunta no se puede modificar porque está asociada a un cuestionario",
              handleClick : () => handleCloseModalUpdate() ,
              oneButtons : true,
              type : 'WARNING',
            })
          }
          else if (result?.status > 399) {
            props.setMessage({
              open : true,
              description: 'Error al guardar la información',
              handleClick : () => handleCloseModalUpdate() ,
              oneButtons : true,
              type : 'WARNING',
            })
          } else {
            props.setMessage({
              open : true,
              description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
              handleClick : () => handleCloseModalUpdate() ,
              oneButtons : true,
              type : 'WARNING',
            })
          }
       }
       props.dataQuestion ? updateQuestions() : createQuestions()
         
    }
  }


    const addOption = () => {
     
      if(valuesOptions.length === 10){
        return null
      } else  {
        return <IconButton
        aria-label="toggle password visibility"
        onClick={()=> {
                setValue(`valueOption${valuesOptions.length + 1}`, '')
                setValuesOptions([...valuesOptions, {name: `valueOption${valuesOptions.length + 1}`, value: valuesOptions.length +1, operationType: 'CREATE'}])
                setHistoryOptions([...historyOptions, { name: `valueOption${historyOptions?.length +1 }`, value: historyOptions?.length +1,  operationType: 'CREATE'}])
            }
        }
        edge="end"
        >
        {<AddCircleIcon color="success"/>}
        </IconButton>
      }
      }
    

     

      const inputOption = (x, data) => {
        return <Grid item lg={ props.isQuestionary || props?.showDetail || props?.isEdit? 11 : 5.8} style={{display: 'flex', alignItems: 'center'}}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password" 
                            error={!!errors.hasOwnProperty(`valueOption${x}`) && errors[`valueOption${x}`].message}
                            shrink={watch(`valueOption${x}`) !== '' ? true : false}
                        >
                            {`Opción ${x}`} 
                        </InputLabel>
                        <OutlinedInput
                            type='text'
                            disabled={props.isQuestionary || props?.showDetail || props?.isEdit}
                            {...register(`valueOption${x}`)}
                            className={classes.dropdownMultiple}
                            notched={ watch(`valueOption${x}`) !== '' ? true : false}
                            multiline
                            maxRows={14}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                disabled={props.isQuestionary || props?.showDetail || props?.isEdit}
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword(`valueOption${x}`, `valueOption${valuesOptions.length}`, data)}
                                edge="end"
                                >
                                {<DeleteIcon/>}
                                </IconButton>
                            </InputAdornment>
                            }
                            label={`Opción ${x}`}
                            error={!!errors.hasOwnProperty(`valueOption${x}`) && errors[`valueOption${x}`].message}
                            helperText={!!errors.hasOwnProperty(`valueOption${x}`) && errors[`valueOption${x}`].message}
                        />
                         <FormHelperText style={{color:'#d32f2f'}}>{errors.hasOwnProperty(`valueOption${x}`) &&
                                errors[`valueOption${x}`].message}
                        </FormHelperText> 
                    </FormControl>
                </Grid>

    }


   const handleClosePreview = () => props?.closePreview()
   const handleChecked = (e) => {
     const { name} = e.target;
     if(name){
       setChecked({name: name})
     }
   }
   const inputsPreview = () => {
   return <Grid container spacing={1}  maxWidth="lg" justifyContent="center" alignItems="center" style={{ margin: 'auto' }} >
     <Grid item lg={11}>
        <FormControl variant='outlined' fullWidth size="small">
          <TextField
          disabled={true}
          className={classes.dropdown}
          fullWidth
          value={props?.dataInitial?.question}
          size="small"
        />
        </FormControl>
      </Grid>
      <Grid container spacing={1} maxWidth="md" style={{margin: '5px 0px 10px -6%', width: '90%'}}>
      {valuesOptions.map((x, index) => {
       return <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', alignItems: 'center', marginBotton: 20}}>
                <div style={{marginRight:5}}>
                  <Checkbox
                    checked={checked?.name === `valueOption${index+1}`}
                    name={`valueOption${index+1}`}
                    indeterminate={checked?.checked?.name === `valueOption${index+1}`}
                    onChange={handleChecked}
                  />
                </div>
              <FormControl variant='outlined' fullWidth size="small">
                  <TextField
                  disabled={true}
                  className={classes.dropdownMultiple}
                  multiline
                  maxRows={14}
                  fullWidth
                  value={watch(`valueOption${index+1}`)}
                  size="small"
                />
              </FormControl>
        </Grid> 
       
      })}
      </Grid>
    </Grid>
  
  }

  
  if(props?.isAssess){
    return <FieldAssess 
             isDetail={props?.isDetail}
             valueQuestion={props?.mapQuestionAssess?.valueApplied}
             valuesOptions={props?.mapQuestionAssess?.questionAlternativeList}
             question={props?.mapQuestionAssess?.question}
             uuid={props?.mapQuestionAssess?.uuid}
           />
   }else if(props?.isQuestionary || props?.showDetail || props?.isEdit){
    return <QuestionQuestionary 
             valuesOptions={props?.mapQuestionQuestionary?.questionAlternativeList}
             question={props?.mapQuestionQuestionary?.question}
             uuid={props?.mapQuestionAssess?.uuid}
           />
   }
    else if(props?.isAnswer){
            return <FieldAnswer 
            position={props?.index}
            isDetail={props?.isDetail}
            valueQuestion={props?.mapQuestionAssess?.valueApplied}
            valuesOptions={props?.mapQuestionAssess?.questionAlternativeList}
            question={props?.mapQuestionAssess?.question}
            uuid={props?.mapQuestionAssess?.uuid}
          />
   } else {
    return (
      <form id={props.getDataCreate ? 'prueba':"formInitial4"} onSubmit={handleSubmit(onSubmit)} style={{marginTop: 37, width: 'inherit', marginLeft: '0.7%' }}> 
      <Grid container 
        spacing={1} 
        direction={props?.showDetail || props?.isQuestionary || props.isEdit? 'column' : 'row'} 
        alignItems="baseline" 
      >
          {valuesOptions.map((x) =>(
          inputOption(x.value, x)
          ))}
          <Grid item lg={0.4}>
          { props.isQuestionary || props?.showDetail || props?.isEdit? null : addOption()}
          </Grid>
      </Grid>  
      {props?.preview ? 
        < ModalPreview  
          open={props?.preview} 
          children={inputsPreview()}
          handleClose={handleClosePreview}
        /> 
        :  null
      }
        </form>
    )}
}

export default Unique
