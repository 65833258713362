import React from 'react'
import { TableRow, TableCell, TableContainer, Table, TableHead, TableBody, Paper, FormControl, TextField, MenuItem, Tooltip  } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { styles } from "./styles";
import _ from "lodash";
import { useStateValue } from './../../../../../../context/store';
import { typesEvaluators } from './../../../../../../constants';

const Index = (
        {   index, user, evaluationPeer, findSelecteType, openModalEdit, handleOnchange, 
            currentSubalterns, sectionId, setSectionId, setIdSelect, idSelect, saveUser, setCurrentSubalterns,
            setIdEdit, openModal, deleteUser
        }) => {
    const classes = styles();

    const [{typesEvaluations}, ] = useStateValue();
    
  return (
        <TableRow>
            <TableCell colSpan={5}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead >
                        <TableRow>
                            <TableCell colSpan={5} style={{textAlign: 'initial'}}>Evaluadores Institucionales</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableHead style={{background: 'rgb(223, 231, 253)'}}>
                            <TableRow>
                                <TableCell width={`30%`} size='small' >Nombre</TableCell>
                                <TableCell width={`15%`} size='small'>Cargo</TableCell>
                                <TableCell width={`25%`} size='small'>Programa</TableCell>
                                <TableCell width={`20%`} size='small'>Tipo de Evaluación</TableCell>
                                <TableCell width={`10%`} size='small'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        <TableRow>
                            { findSelecteType('SELF_EVALUATION') &&
                            <React.Fragment key={index}>
                                <TableCell size='medium' className={classes.subHeader}>
                                    {`${user?.name ? user?.name : ''} ${user?.surname ? user?.surname : '' }` }
                                </TableCell>
                                <TableCell size='medium' className={classes.subHeader}>
                                    {typesEvaluators[user?.typeEvaluator] ?? ''}
                                </TableCell>
                                <TableCell size='medium' className={classes.subHeader}>
                                    {user?.regional?.name}
                                </TableCell>
                                <TableCell size='medium' className={classes.subHeader}>
                                    Autoevaluación
                                </TableCell>
                                <TableCell size='medium' className={classes.subHeader}>
                                
                                </TableCell>
                            </React.Fragment>
                            }
                        </TableRow>
                            {/* {evaluationPeer?.length ? [evaluationPeer?.find((x)=> x?.evaluated?.uuid === user?.uuid)]?.map((r, i) => (
                                <React.Fragment key={i}>
                                    <TableCell size='small' width={`25%`} className={classes.subHeader}>
                                    {sectionId === index ?
                                    
                                        
                                            <FormControl variant='outlined' fullWidth size="small" >
                                                <TextField
                                                name='user'
                                                select
                                                fullWidth
                                                size='small'
                                                onChange={handleOnchange}
                                                className={classes.dropdown}
                                                label="Evaluador "
                                            >
                                                {currentSubalterns?.length ? currentSubalterns?.map((d)=> (
                                                    <MenuItem value={d?.uuid} key={d?.uuid}>
                                                    {`${d?.name ? d?.name : ''} ${d?.secondName ? d?.secondName : ''} ${d?.surname ? d?.surname :''} ${d?.secondSurname ? d?.secondSurname : ''}`}
                                                    </MenuItem>
                                                ))
                                                :
                                                <MenuItem disabled value="">
                                                    <em>No hay opciones</em>
                                                </MenuItem>
                                            }
                                                
                                            </TextField>
                                            </FormControl>
                                            :
                                            
                                            `${r?.evaluator?.name ? r?.evaluator?.name : ''} ${r?.evaluator?.secondName ? r?.evaluator?.secondName : ''} ${r?.evaluator?.surname ? r?.evaluator?.surname :''} ${r?.evaluator?.secondSurname ? r?.evaluator?.secondSurname : ''}`
                                        }
                                    </TableCell>
                                    <TableCell size='small' width={`20%`} className={classes.subHeader}>
                                        {r.evaluator?.position?.name}
                                    </TableCell>
                                    <TableCell size='small' width={`20%`} className={classes.subHeader}>
                                        {r.evaluator?.regional?.name}
                                    </TableCell>
                                    <TableCell size='small' width={`20%`} className={classes.subHeader}>
                                        Par
                                    </TableCell>
                                    <TableCell size='medium' className={classes.subHeader}>
                                        {r?.position?.name}
                                    </TableCell>
                                    <TableCell size='small' className={classes.subHeader}>
                                    { sectionId === index ? 
                                        <section style={{display: 'flex'}}>
                                            {!idSelect ? null :
                                            <div className={classes.dataTable}>
                                            <Tooltip title="Guardar" arrow>
                                                <SaveIcon fontSize="small" color='disabled' style={{ cursor: 'pointer'}} onClick={()=> saveUser(r?.evaluated?.uuid)}/>
                                            </Tooltip> 
                                            </div> 
                                            }
                                            <div className={classes.dataTable}>
                                            <Tooltip title="Cancelar" arrow>
                                                <ClearIcon fontSize="small" color='disabled'  style={{ cursor: 'pointer'}} onClick={()=> setSectionId('')}/>
                                            </Tooltip>
                                            </div> 
                                        </section>
                                        :  
                                        <div className={classes.dataTable}>
                                            <Tooltip title="Editar evaluador" arrow>
                                            <EditIcon fontSize="small" color='disabled'  style={{ cursor: 'pointer'}} onClick={()=> {
                            
                                                const typePeer = typesEvaluations?.applicationTypes?.find((x)=> x?.type === 'PEER_EVALUATION');
                                                const findPeerUser = _.find(typePeer?.employees, (e)=> e?.evaluated?.uuid === r?.evaluated?.uuid)
                                                const findDifferent = findPeerUser?.evaluated?.leader?.subalterns?.filter((d)=> d?.uuid !== r?.evaluated?.uuid)
                                                const deleteEvaluatorEqual = findDifferent?.filter((x)=> x?.uuid !== r?.evaluator?.uuid)

                                                setCurrentSubalterns(deleteEvaluatorEqual)
                                                setIdEdit(r?.evaluated?.uuid); setIdSelect(''); setSectionId(index)
                                                
                                                }}/>
                                            </Tooltip>
                                        </div>
                                        }
                                    </TableCell>
                                    </React.Fragment>
                            )): null} */}
                                <TableRow>
                                {findSelecteType('LEADER_EVALUATION') && typesEvaluations?.users?.find((x)=> x?.uuid === user?.uuid)['leader'] &&
                                    [typesEvaluations?.users?.find((x)=> x?.uuid === user?.uuid)]?.map((r) => (
                                <React.Fragment key={r?.uuid}>
                                    <TableCell width={`25%`} colSpan={1} className={`${classes.sizeCell} ${classes.subHeader}`}>
                                        {`${r?.leader?.name ? r?.leader?.name: ''} ${r?.leader?.surname ? r?.leader?.surname : '' }` }
                                    </TableCell>
                                    <TableCell width={`20%`} className={`${classes.sizeCell} ${classes.subHeader}`}>
                                        {typesEvaluators[r?.leader?.typeEvaluator]  ?? ''}
                                    </TableCell>
                                    <TableCell width={`20%`} className={`${classes.sizeCell} ${classes.subHeader}`}>
                                        {r?.leader?.regional?.name}
                                    </TableCell>
                                    <TableCell width={`20%`} className={` ${classes.subHeader}`}>
                                        Institucional 
                                    </TableCell>
                                    {/* <TableCell   style={{display: 'flex', alignItems: 'center'}} className={`${classes.sizeCell} ${classes.subHeader}`}>
                                        <Tooltip title="Editar">
                                            <EditIcon
                                                fontSize="small" color='disabled'
                                                onClick={()=> openModalEdit(r?.leader, r)} style={{cursor: 'pointer'}} 
                                            />
                                        </Tooltip>
                                        <Tooltip title="Agregar líder" arrow onClick={()=> openModal({evaluated: user?.uuid, evaluator: r?.leader?.uuid}, r?.newLeaders?.length ? r?.newLeaders : []) }>
                                            <AddCircleIcon color="success" style={{margin: 8}}/>
                                        </Tooltip>
                                    </TableCell> */}
                                    </React.Fragment>
                            ))}
                                </TableRow>
                                {[typesEvaluations?.users?.find((x)=> x?.uuid === user?.uuid)]?.map((r) => {
                                return r?.newLeaders?.map((n)=> (
                                <TableRow>
                                    <React.Fragment key={n?.uuid}>
                                    <TableCell colSpan={1} className={`${classes.sizeCell} ${classes.subHeader}`} >
                                        <p>{`${n?.name ? n?.name: ''} ${n?.surname ? n?.surname : '' }` }</p>
                                    </TableCell>
                                    <TableCell className={`${classes.sizeCell} ${classes.subHeader}`}>
                                        {n?.position?.name}
                                    </TableCell>
                                    <TableCell className={`${classes.sizeCell} ${classes.subHeader}`}>
                                        {n?.regional?.name}
                                    </TableCell>
                                    <TableCell className={`${classes.sizeCell} ${classes.subHeader}`} >
                                        Institucional
                                    </TableCell>
                                    <TableCell className={`${classes.sizeCell} ${classes.subHeader}`} >
                                        <Tooltip title="Eliminar" arrow>
                                        <DeleteIcon fontSize="small" color='disabled' onClick={()=> deleteUser(r?.uuid, n?.uuid)} style={{marginRight: '3%', cursor: 'pointer'}}/>
                                        </Tooltip>
                                    </TableCell>
                                    </React.Fragment>
                                
                                </TableRow>
                                ))
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableCell>
        </TableRow>
  )
}

export default Index