import React,{useState} from "react";
import { makeStyles } from "@mui/styles";
import TableLeaders  from "../index.js";
import { Button, Modal } from "@mui/material";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 1300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.24)",
    outline: "none",
    borderRadius: 15,
    padding: '10px 24px 15px',
  },
  cancel1: { 
     color: "#2F71CB", 
     marginBottom: 5, 
     borderColor: '#80808070',
     "&:hover": {
      borderColor: '#80808070',
    },
  },
  cancel: { 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5, 
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
 },
  text: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#4B4B4B",
    fontWeight: 400,
    textAlign: "center",
    margin: "25px 20px",
  },
  titleAlert: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#2C2C2C",
  },
  widthIcon: {
    fontSize: 70,
    color: '#2F71CB',
    marginTop: '3%'

  },
  confirmBanck :{ 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5,
    // width: 'fit-content' ,
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
  }
}));

const ModalConfirmBase = ({open, clear, handleClickOut, firsLeader  }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [confirm, setConfirm] = useState(false)

  const handleClick = ()=> setConfirm(!confirm)
  

  const bodyModal = (
    <div style={modalStyle} className={classes.paper}>
      <TableLeaders firsLeader={firsLeader} confirm={confirm} handleClickOut={handleClickOut} handleClick={handleClick}/>
      <div style={{ display: "flex", justifyContent:  "end", alignItems: 'center'}}>
      
      <Button
        style={{marginRight: 10, height: 'fit-content'}}
        // className={ classes.confirmBanck }
        variant="outlined"
        onClick={handleClickOut}
      >
        CANCELAR
      </Button>
      <Button
        className={ classes.cancel }
        variant="contained"
        onClick={handleClick}
      >
        ACEPTAR
      </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
};

export default ModalConfirmBase;
