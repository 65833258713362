import React, { useEffect } from "react";
import * as yup from "yup";
import { styles } from "../../../styles";
import { useForm } from "react-hook-form";
import { useStateValue } from "../../../../context/store";
import ModalPreview from "../../preview";
import FieldAssess from "./assessText";
import FieldAnswer from "./answerText";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, Grid, TextField, FormHelperText } from "@mui/material";
import {
  createQuestion,
  updateQuestion,
} from "../../../../actions/question-action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PATH_APP } from "../../../../constants";

const Index = (props) => {
  const classes = styles();
  const history = useHistory();

  const [{ questionSearch }, dispatch] = useStateValue();

  useEffect(() => {
    if (props?.oneSubmit) {
      return dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Gestionar preguntas",
        index: 3,
        idForm: "formInitial1",
        path: `${PATH_APP}/create-question`,
        textIsSubmit: "CREAR PREGUNTA",
      });
    }
    if (
      props?.isAssess ||
      props?.isAnswer ||
      props?.showDetail ||
      props?.isEdit
    ) {
      return null;
    } else if (props.isQuestionary) {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Crear cuestionario",
        index: 4,
        isSubmit: true,
        subPath: `${PATH_APP}/questionary`,
        textIsSubmit: "GUARDAR",
        idForm: "Newary",
      });
    } else {
      submitNotDisabled();
    }
  }, []);

  const schema = yup.object().shape({
    answer: null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const handleCloseModal = () => {
    closeModal();
    submitNotDisabled();
  };

  const handleCloseModalAndReset = () => {
    closeModal();
    props?.setOpenModal();
    resetList();
    props?.clearFields();
  };

  const handleClosePreview = () => props?.closePreview();

  const handleClose = () => {
    handleCloseModal();
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Crear pregunta",
      index: 3,
      subPath: `${PATH_APP}/questions`,
      isSubmit: true,
      idForm: "formInitial1",
      disabledSubmit: false,
    });
  };

  const handleCloseModalUpdate = () => {
    submitNotDisabled();
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar preguntas",
      index: 3,
      idForm: "formInitial1",
      path: `${PATH_APP}/create-question`,
      textIsSubmit: "CREAR PREGUNTA",
    });
    closeModal();
  };

  const handleReset = (data) => {
    props.clearFields();
    handleCloseModal();
    if (props?.getDataCreate) props?.getDataCreate(data);
  };

  const handleModal = () => history.push(`${PATH_APP}/questions`);

  const resetList = () => {
    dispatch({
      type: "SET_FILTER_QUESTION",
      filter: questionSearch?.filter,
      isFilter: true,
      typeAction: "EDIT",
    });
  };

  const disabledSubmit = () => {
    dispatch({
      index: 3,
      isSubmit: true,
      disabledSubmit: true,
      idForm: "formInitial1",
      type: "SET_BREADCRUMB",
      subPath: `${PATH_APP}/questions`,
      breadCrumbSubText: "Crear pregunta",
      breadCrumbText: "Autoevaluaciones",
    });
  };

  const submitNotDisabled = () => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Crear pregunta",
      index: 3,
      subPath: `${PATH_APP}/questions`,
      isSubmit: true,
      idForm: props.getDataCreate ? "prueba" : "formInitial1",
      disabledSubmit: false,
    });
  };

  const closeModal = () => props.setMessage({ ...props.message, open: false });

  const onSubmit = (data) => {
    if (
      !props?.dataInitial?.tagUuid ||
      !props?.dataInitial?.question ||
      props?.errorsHeaderQuestion?.question ||
      props?.errorsHeaderQuestion?.label
    ) {
      return props?.valuesForm();
    } else {
      const newData = { ...props?.dataInitial, questionAlternativeRequest: [{key: 'Text', position: 0, value: 0}] };
  
      const createQuestions = async () => {
        disabledSubmit();
        const result = await createQuestion(newData, dispatch);
        if (result && result?.status >= 200 && result?.status <= 201) {
          props.setMessage({
            open: true,
            title: "¡Proceso exitoso!",
            description: " ¿ Crear nueva pregunta ? ",
            handleClick: () => handleReset(result?.data),
            handleClickOut: () => handleModal(),
            textButtonSubmit: "ACEPTAR",
            textButtonCancel: "CANCELAR",
            type: "SUCCESS",
          });
        } else if (result?.status > 399) {
          props.setMessage({
            open: true,
            description: "Error al guardar la información",
            handleClick: () => handleClose(),
            oneButtons: true,
            type: "WARNING",
          });
        } else {
          props.setMessage({
            open: true,
            description:
              "Ocurrió un error procesando su solicitud, inténtelo nuevamente",
            handleClick: () => handleClose(),
            oneButtons: true,
            type: "WARNING",
          });
        }
      };
      const updateQuestions = async () => {

        const result = await updateQuestion(newData, props?.dataQuestion?.uuid);
        if (result && result?.status >= 200 && result?.status <= 204) {
          props.setMessage({
            open: true,
            title: "¡Proceso exitoso!",
            description: "Información almacenada correctamente ",
            handleClick: () => handleCloseModalAndReset(),
            oneButtons: true,
            textButtonSubmit: "ACEPTAR",
            type: "SUCCESS",
          });
        } else if (result?.status === 400) {
          props.setMessage({
            open: true,
            description:
              "La pregunta no se puede modificar porque está asociada a un cuestionario",
            handleClick: () => handleCloseModalUpdate(),
            oneButtons: true,
            type: "WARNING",
          });
        } else if (result?.status > 399) {
          props.setMessage({
            open: true,
            description: "Error al guardar la información",
            handleClick: () => handleCloseModalUpdate(),
            oneButtons: true,
            type: "WARNING",
          });
        } else {
          props.setMessage({
            open: true,
            description:
              "Ocurrió un error procesando su solicitud, inténtelo nuevamente",
            handleClick: () => handleCloseModalUpdate(),
            oneButtons: true,
            type: "WARNING",
          });
        }
      };
      props.dataQuestion ? updateQuestions() : createQuestions();
    }
  };

  const FormPreview = () => {
    return (
      <Grid item container lg={12} md={12} justifyContent="center">
        <FormControl fullWidth>
          <>
            <TextField
              className={classes.dropdownMultiple}
              multiline
              placeholder="Respuesta de texto"
              disabled={true}
              rows={4}
              InputLabelProps={{ shrink: true }}
              notched='true'
              rowsmax={4}
              {...register("answer")}
              label="Respuesta"
            />
            <div className={classes.accountantPreview}>{`${
              watch("answer")?.length ? watch("answer")?.length : 0
            }/3000`}</div>
          </>
        </FormControl>
      </Grid>
    );
  };


  if (props?.isAssess) {
    return (
      <FieldAssess
        isDetail={props?.isDetail}
        valueQuestion={props?.mapQuestionAssess?.valueApplied}
        question={props?.dataInitial?.question}
        uuid={props?.mapQuestionAssess?.uuid}
      />
    );
  } else if (props?.isAnswer) {
    return (
      <FieldAnswer
        position={props?.index}
        isDetail={props?.isDetail}
        valueQuestion={props?.mapQuestionAssess?.valueApplied}
        question={props?.dataInitial?.question}
        uuid={props?.mapQuestionAssess?.uuid}
      />
    );
  } else {
    return (
      <>
        {props?.preview ? (
          <ModalPreview
            open={props?.preview}
            children={FormPreview()}
            handleClose={handleClosePreview}
            question={props?.dataInitial?.question}            
          />
        ) : null}

        <form
          id={props.getDataCreate ? "prueba" : "formInitial1"}
          onSubmit={handleSubmit(onSubmit)}
          style={{ marginTop: 37, marginLeft: "0.7%", width: "inherit" }}
        >
          <Grid item lg={12}>
            <FormControl fullWidth>
              <>
                <TextField
                  className={classes.dropdownMultiple}
                  multiline
                  placeholder="Respuesta de texto"
                  disabled={true}
                  rows={4}
                  InputLabelProps={{ shrink: true }}
                  notched='true'
                  rowsmax={4}
                  {...register("answer")}
                  label="Respuesta"
                  error={
                    !!errors.hasOwnProperty("answer") &&
                    errors["answer"].message
                  }
                />
                <div className={classes.accountant}>{`${
                  watch("answer")?.length ? watch("answer")?.length : 0
                }/3000`}</div>
              </>
              <FormHelperText style={{ color: "red" }}>
                {errors.hasOwnProperty("answer") && errors["answer"].message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </form>
      </>
    );
  }
};

export default Index;
