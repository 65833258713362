import React,{useState, useEffect} from 'react'
import { FormControl, Grid, TextField, FormHelperText, Typography, Paper, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { PATH_APP, REQUIRED_MESSAGE } from '../../../../../../constants';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import {styles} from "./styles";
import {  answerEvaluationEvaluated} from "../../../../../../actions/Performance_Evaluation-action"

import * as yup from 'yup'
import { useStateValue } from '../../../../../../context/store';
import moment  from "moment"

const Index = (props) => {
    const classes = styles();
    const history = useHistory()
    const [evaluated, setEvaluated] = useState({});
    const [evaluator, setEvaluator] = useState({});
    const [type, setType] = useState();
    const [{answerEvaluation, valorationAplication}, dispatch] = useStateValue()
    const [valuesForm, setValuesForm ] = useState({
        status: 'ACTIVE',
        startDate: '',
        endDate: ''
    })
    const [currentDay, setCurrentDay] = useState()

    const schema = yup.object().shape({
        name: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .min(10, 'Ingrese mínimo 10 caracteres')
        .max(500, 'Ingrese máximo 500 caracteres'),
        description: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .min(10, 'Ingrese mínimo 10 caracteres')
        .max(1000, 'Ingrese máximo 1000 caracteres'),
        startDate: yup
        .date()
        .nullable(true),
        endDate: yup
        .date()
        .required('Este campo es obligatorio')
        .nullable(true),
        startDateAplied: yup
        .date()
        .required('Este campo es obligatorio')
        .typeError('Este campo es obligatorio'),
        endDateAplied: yup
        .date()
        .required('Este campo es obligatorio')
        .typeError('Este campo es obligatorio')
        .test("maxLenght", "Ingrese una superior o igual a la fecha desde", val =>  currentDay <= moment(val).format('YYYY-MM-DD')   ),
    })
    const {register, handleSubmit, setValue, clearErrors, watch, formState:{errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange'
    })

    const validateTitle = () => {
        if(type ==='SELF_EVALUATION'){
          return 'Autoevaluación'
        }else if(type ==='PEER_EVALUATION'){
          return 'Evaluación estudiantes'
        } else if(type ==='LEADER_EVALUATION'){
          return 'Evaluación institucional'
        }
      }

    const disabledSubmit = () => dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: validateTitle(props?.dataDetail?.applicationAnswerType?.type),
        index: 12,
        idForm: 'Evaluation',
        module: 'EVALUATION_EVALUATED',
        subPath: `${PATH_APP}/evaluation-performance-evaluated`,
        isSubmit: true,
        disabledSubmit: true,
        textIsSubmit: "ENVIAR",
      }); 

      const notDisaledSubmit = () => dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: validateTitle(props?.dataDetail?.applicationAnswerType?.type),
        index: 12,
        idForm: 'Evaluation',
        module: 'EVALUATION_EVALUATED',
        subPath: `${PATH_APP}/evaluation-performance-evaluated`,
        isSubmit: true,
        disabledSubmit: false,
        textIsSubmit: "ENVIAR",
      }); 



    const closeModal = () => {props?.setMessage({...props?.message, open:false}); notDisaledSubmit()}

    const handleCloseSuccess = () => {
        closeModal()
        history.push(`${PATH_APP}/evaluation-performance-evaluated`)
        dispatch({
            type: "SET_FILTER_ANSWER_EVALUATION",
            filter: answerEvaluation?.filter,
            isFilter: true,
            typeAction: 'GO_BACK'
          })
          dispatch({
            type: 'SET_RELATIONS_EVALUATION',
            data: []
        })
        dispatch({
          type: "SET_DATA_QUESTIONNAIRE",
          data: {}
      })
      dispatch({
        type: "SET_VALORATE_APLICATION",
        disabledValors: false,
        typeValoration: '',
        data: {}
    })
    }

  
    const onSubmit = (data) => {
    
        if (!valorationAplication?.data?.competences) {
            return props?.setMessage({
                open : true,
                description: `No se han contestado todas las preguntas`,
                handleClick : () => closeModal() ,
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                type : 'WARNING',
              })
        } else {
        for (let index = 0; index < props?.dataDetail?.performanceEvaluation?.competences?.length; index++) {
        
            for (let b = 0; b < valorationAplication?.data?.competences[index]?.conducts?.length; b++) {
               
                for (let q = 0; q < valorationAplication?.data?.competences[index]?.conducts[b]?.questions?.length; q++) {
                
                    if (!valorationAplication?.data?.competences[index]?.conducts[b]?.questions[q]?.value) {
                        return props?.setMessage({
                            open : true,
                            description: `Hay preguntas sin responder`,
                            handleClick : () => closeModal() ,
                            oneButtons : true,
                            textButtonSubmit: 'ACEPTAR',
                            type : 'WARNING',
                          })
                    } 
                }


        }
  
    }
        
    const newDataHeader = {
        competences :valorationAplication?.data?.competences?.map((x,index)=> (
            {
                uuid: x?.uuid,  
                conducts:  x?.conducts?.map((c, pos=index)=> (
                    {
                        uuid: c?.uuid,
                        questions: c?.questions?.map((q, ques=index)=> (
                            {
                                uuid: q?.uuid,
                                questionAlternative: {uuid: q?.value}
                            }
                            ))
                    }
                ))       
            }
         ))
      }
   
 
      const appliedEvauation = async()=> {
        disabledSubmit()
        const result = await answerEvaluationEvaluated(props?.id, newDataHeader) 
        if (result && (result?.status >= 200 && result?.status <= 204)) {
          props?.setMessage({
            open : true,
            title: '¡Proceso exitoso!',
            description: 'Información almacenada correctamente ',
            handleClick : () => handleCloseSuccess() ,
            textButtonSubmit: 'ACEPTAR',
            oneButtons : true,
            type : 'SUCCESS',
          })
          }
          else if (result?.status > 399) {
              props?.setMessage({
              open : true,
              description: result?.data?.message ? result?.data?.message : "Ocurrió un error procesando su solicitud, inténtelo nuevamente",
              handleClick : () => closeModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          } else {
              props?.setMessage({
              open : true,
              description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
              handleClick : () => closeModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          }
        }
        appliedEvauation()
    }
}

const setDataForm = () => {
    if(!props?.dataDetail?.expired){
        setValue('name', props?.dataDetail?.performanceEvaluation?.name)
        setValue('description', props?.dataDetail?.performanceEvaluation?.description)
        setValue('endDate',moment(props?.dataDetail?.performanceEvaluation?.endDate).format('YYYY-MM-DD'))
        setValue('startDate',moment(props?.dataDetail?.performanceEvaluation?.startDate).format('YYYY-MM-DD'))
        setValue('endDateAplied',moment(props?.dataDetail?.endDate).format('YYYY-MM-DD'))
        setValue('startDateAplied',moment(props?.dataDetail?.startDate).format('YYYY-MM-DD'))
        for (let index = 0; index < props?.dataDetail?.evaluatedList?.length; index++) {

            setEvaluated(props?.dataDetail?.evaluatedList[index]?.evaluated)

            for (let e = 0; e < props?.dataDetail?.evaluatedList[index]?.evaluatorList?.length; e++) {
                setEvaluator(props?.dataDetail?.evaluatedList[index]?.evaluatorList[e]?.evaluator); 
                setType(props?.dataDetail?.evaluatedList[index]?.evaluatorList[e]?.type)
            }

        }
        setValuesForm(
            {
                ...valuesForm, 
                startDate: moment(props?.dataDetail?.performanceEvaluation?.startDate).format('YYYY-MM-DD'),
                endDate: moment(props?.dataDetail?.performanceEvaluation?.endDate).format('YYYY-MM-DD')
            }
            )
    }else {
        setValue('endDate',moment(props?.dataDetail?.performanceEvaluation?.endDate).format('YYYY-MM-DD'))
        setValue('startDate',moment(props?.dataDetail?.performanceEvaluation?.startDate).format('YYYY-MM-DD'))
        setValue('endDateAplied',moment(props?.dataDetail?.endDate).format('YYYY-MM-DD'))
        setValue('startDateAplied',moment(props?.dataDetail?.startDate).format('YYYY-MM-DD'))
    }
}
    useEffect(() => {
        window.scrollTo(0,0)
       if(props?.dataDetail){
        setDataForm()
       }
        
    }, [props?.dataDetail])


    useEffect(() => {
        setCurrentDay(watch('startDateAplied'))
        setValue('name', props?.dataDetail?.performanceEvaluation?.name)
        setValue('description', props?.dataDetail?.performanceEvaluation?.description)
      }, [watch('startDateAplied')]);

    const handleChange = (e)=> {
        const {value, name} = e.target
        setValue(name, value)
        setValuesForm({...valuesForm, [name]:value})
        
    }


     const validateErros = () => {
        if(errors['startDateAplied'] ){
            return 20
        } else if(errors['endDateAplied'] && errors['endDateAplied'].message === 'Este campo es obligatorio' ){
            return 20
        }else if (errors['endDateAplied']){
            return 40
        }  else {
            return null
        }
     }
    
     const validateLabel = () => {
        if(type === 'SELF_EVALUATION'){
            return 'Autoevaluación '
        }
        else if(type === 'PEER_EVALUATION'){
            return 'Evaluación estudiantes '
        }
        else if(type === 'LEADER_EVALUATION'){
            return 'Evaluación institucional '
        }
        else if(type === 'CLIENT_EVALUATION'){
            return 'Evaluación cliente '
        }
      }    

     useEffect(()=> {
        if(watch('endDateAplied') && errors['endDateAplied']){
            setValue('endDateAplied', '')
            clearErrors('endDateAplied')
        }
       
     },[watch('startDateAplied')])

     
    if(watch('description')){
    return (
        <Paper elevation={3} style={{background: '#FAFAFA', borderRadius: 20, padding: 14, marginBottom: '3%'}}>
            <form id="Evaluation"  onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} maxWidth="inherit" alignItems="end" 
                    style={{ background: props?.isEdit ? 'rgb(239 239 239)': null}}
                >
                
                <Grid item spacing={1} lg={3.6} md={3.6} style={{display:'flex ', flexDirection: 'column', marginBottom: errors && validateErros()}}>
                        <div >
                            <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Periodo de evaluación</Typography>
                            <div style={{display:'flex ', flexDirection: 'row' }}>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                    <FormControl fullWidth size="small">
                                    <TextField 
                                        {...register('startDate')}
                                        size="small"
                                        type="date"
                                        label="Desde"
                                        disabled
                                        onChange={handleChange}
                                        // value={breadCrumb?.module === "APLICATION_CUESTIONARY" ? questionarySearch?.filter?.createdAtStartDate : null}
                                        className={classes.fieldDateTimeErrorSearch}
                                        minDate={new Date()}
                                        InputLabelProps={{
                                            shrink: true,
                                            notched: true
                                        }}
                                        error={!!errors.hasOwnProperty("startDate") && errors["startDate"].message }
                                    />
                                    <FormHelperText style={{color:'#d70909'}}>
                                        {!!errors.hasOwnProperty("startDate") &&
                                        errors["startDate"].message}
                                    </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                                <FormControl fullWidth size="small">
                                    <TextField 
                                        {...register('endDate')}
                                        size="small"
                                        type="date"
                                        label="Hasta"
                                        disabled
                                        onChange={handleChange}
                                        className={classes.fieldDateTimeErrorSearch}
                                        minDate={new Date()}
                                        InputLabelProps={{
                                            shrink: true,
                                            notched: true
                                        }}
                                        error={!!errors.hasOwnProperty("endDate") && errors["endDate"].message }
                                    />
                                    <FormHelperText style={{color:'#d70909'}}>
                                        {!!errors.hasOwnProperty("endDate") &&
                                        errors["endDate"].message}
                                    </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid item spacing={1} lg={5} md={3.6} style={{display:'flex ', flexDirection: 'column' }}>
                        <div >
                            <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Periodo de aplicación</Typography>
                            <div style={{display:'flex ', flexDirection: 'row' }}>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                    <FormControl fullWidth size="small">
                                    <TextField 
                                        {...register('startDateAplied')}
                                        size="small"
                                        type="date"
                                        disabled
                                        label="Desde"
                                        className={classes.fieldDateTimeErrorSearch}
                                        minDate={new Date()}
                                        InputLabelProps={{
                                            shrink: true,
                                            notched: true
                                        }}
                                        error={!!errors.hasOwnProperty("startDateAplied") && errors["startDateAplied"].message }
                                    />
                                    <FormHelperText style={{color:'#d70909'}}>
                                        {!!errors.hasOwnProperty("startDateAplied") &&
                                        errors["startDateAplied"].message}
                                    </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                                <FormControl fullWidth size="small">
                                    <TextField 
                                        {...register('endDateAplied')}
                                        size="small"
                                        type="date"
                                        disabled
                                        label="Hasta"
                                        className={classes.fieldDateTimeErrorSearch}
                                        minDate={new Date()}
                                        InputLabelProps={{
                                            shrink: true,
                                            notched: true
                                        }}
                                        error={!!errors.hasOwnProperty("endDateAplied") && errors["endDateAplied"].message }
                                    />
                                    <FormHelperText style={{color:'#d70909'}}>
                                        {!!errors.hasOwnProperty("endDateAplied") &&
                                        errors["endDateAplied"].message}
                                    </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} fullWidth>
                        <FormControl fullWidth variant='outlined' size='small'>
                            <TextField 
                                {...register('name')}
                                fullWidth
                                size='small'
                                label="Nombre de la evaluación"
                                placeholder='Nombre de la evaluación'
                                multiline
                                disabled
                                margin='none'
                                maxRows={15}
                                className={classes.dropdownMultiple}
                                error={!!errors.hasOwnProperty("name") && errors["name"].message}
                            />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("name") &&
                            errors["name"].message}
                        </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} fullWidth >
                        <FormControl fullWidth variant='outlined' size='small'>
                            <TextField 
                                {...register('description')}
                                fullWidth
                                size='small'
                                multiline
                                minRows={2}
                                maxRows={20}
                                disabled
                                label="Descripción"
                                placeholder='Descripción'
                                className={classes.dropdownMultiple}
                            />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("description") &&
                            errors["description"].message}
                        </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} container  spacing={2} alignItems="center">
                        <Grid item lg={3.5} md={4}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{fontSize: 12, fontWeight: 700, paddingRight: 10}}>Nombre evaluador:</Typography>
                                <Typography style={{fontSize: 14, color: '#8E8E8E'}}>
                                    {`${evaluator?.name ? evaluator?.name : ''} ${evaluator?.secondName ? evaluator?.secondName : ''} 
                                    ${evaluator?.surname ? evaluator?.surname : ''} ${evaluator?.secondSurname ? evaluator?.secondSurname : ''} `
                                    }
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg={3} md={4}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{fontSize: 12, fontWeight: 700, paddingRight: 10}}>Numero de identificación</Typography>
                                <Typography style={{fontSize: 14, color: '#8E8E8E'}}>
                                    {evaluator?.identificationNumber ? evaluator?.identificationNumber : ''} 
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg={2.5} md={4}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{fontSize: 12, fontWeight: 700, paddingRight: 10}}>Regional:</Typography>
                                <Typography style={{fontSize: 14, color: '#8E8E8E'}}>
                                    {evaluator?.regional?.name ? evaluator?.regional?.name : ''} 
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg={3} md={4}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{fontSize: 12, fontWeight: 700, paddingRight: 10}}>Cargo</Typography>
                                <Typography style={{fontSize: 14, color: '#8E8E8E'}}>
                                    {evaluator?.position?.name ? evaluator?.position?.name : ''}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} container  spacing={2} alignItems="center">
                        <Grid item lg={3.5} md={4}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{fontSize: 12, fontWeight: 700, paddingRight: 10}}>Nombre evaluado:</Typography>
                                <Typography style={{fontSize: 14, color: '#8E8E8E'}}>
                                    {`${evaluated?.name ? evaluated?.name : ''} ${evaluated?.secondName ? evaluated?.secondName : ''} 
                                    ${evaluated?.surname ? evaluated?.surname : ''} ${evaluated?.secondSurname ? evaluated?.secondSurname : ''} `
                                    }
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg={3} md={4}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{fontSize: 12, fontWeight: 700, paddingRight: 10}}>Numero de identificación</Typography>
                                <Typography style={{fontSize: 14, color: '#8E8E8E'}}>
                                    {evaluated?.identificationNumber ? evaluated?.identificationNumber : ''} 
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg={2.5} md={4}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{fontSize: 12, fontWeight: 700, paddingRight: 10}}>Regional:</Typography>
                                <Typography style={{fontSize: 14, color: '#8E8E8E'}}>
                                    {evaluated?.regional?.name ? evaluated?.regional?.name : ''} 
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg={3} md={4}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{fontSize: 12, fontWeight: 700, paddingRight: 10}}>Cargo</Typography>
                                <Typography style={{fontSize: 14, color: '#8E8E8E'}}>
                                    {evaluated?.position?.name ? evaluated?.position?.name : ''}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item container lg={12} style={{marginBottom: '1%'}}>
                    <FormGroup style={{display: 'flex', flexDirection: 'row'}}>
                        <Grid item container lg={4} alignItems="center" >
                            <Grid item lg={5}>
                                <FormControlLabel
                                    style={{width: 'max-content', fontSize: 14, color: '#8E8E8E'}}
                                    control={
                                    <Checkbox 
                                    checked={true} 
                                />
                                    }
                                    label={validateLabel()}
                                />
                            </Grid>
                                   
                                </Grid>
                        </FormGroup>
                        
                    </Grid>
                </Grid>
            </form>
        </Paper>
            )
    }else {
        return null
}
}
export default Index