export const initialState = {
    valor: '',
    disabled: true
  };
  
  const disabledQuestionaryReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_DISABLED_QUESTIONARY":
        return {
          ...state,
          valor: action.valor,
          disabled: action.disabled,
        };
      default:
        return state;
    }
  };
  
  export default disabledQuestionaryReducer;
  