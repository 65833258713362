export const initialState = {
    open: false,
    message: false,
    icon: false,
    data: [],
    dataClear: [],
    valuesQuestions: 0,
    activeValorate: false
  };
  
  const modalValorateAplicationReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_MODAL_VALORATE_APLICATION":
        return {
          ...state,
          open: action.open,
          message: action.message,
          icon: action.icon,
          data: action.data,
          dataClear: action.dataClear,
          valuesQuestions: action.valuesQuestions,
          activeValorate: action.activeValorate
        };
      default:
        return state;
    }
  };
  
  export default modalValorateAplicationReducer;
  