export const initialState = {
   
      idKeyword: null,
      nameKeyword: null,
      positionName: null,
      regionalName: null,
      leaderId: '',
      subalterns: [],
      editLeader: false,

  };
  
  const newleaderSearchReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_FILTER_NEW_LEADER":
        return {
          ...state,
          idKeyword: action.idKeyword,
          nameKeyword: action.nameKeyword,
          positionName: action.positionName,
          regionalName: action.regionalName,
          leaderId: action.leaderId,
          subalterns: action.subalterns,
          editLeader: action.editLeader
        };
      default:
        return state;
    }
  };
  
  export default newleaderSearchReducer;
  