export const initialState = {
    filter: {
      code: null,
      competencie: null,
      status: null,
      page: null,
      size: null
    },
    isFilter: false,
    typeAction: null
  };
  
  const searchCompetencieReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_FILTER_COMPETENCIE":
        return {
          ...state,
          filter: action.filter,
          isFilter: action.isFilter,
          typeAction: action.typeAction,
        };
      default:
        return state;
    }
  };
  
  export default searchCompetencieReducer;
  