import  React,{useState, useEffect} from 'react';
import { 
    FormControl, 
    Grid, 
    TextField,
} from '@mui/material';
import { useStateValue } from "../../../../context/store";
import { styles } from './styles'
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { createAnswerQuestionnaire} from "../../../../actions/AnswerQuestionnaire-action";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment  from "moment"
import { PATH_APP } from '../../../../constants';




export default function ModalConfirmAplication(props) {
    const classes = styles();
    const history = useHistory();
  const [{ dataAplication, valorateAplication, answerQuestionary }, dispatch] = useStateValue();
  const [checked, ] = useState({})
  const [questions, ] = useState( dataAplication?.data?.questionnaire?.associatedQuestions)

  const schema = yup.object().shape({
    referredTo:  yup
    .array()
    .typeError('Este campo es obligatorio')
    .min(1, 'Seleccione mínimo a un evaluado'),
    startDate: yup
    .date()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
    .nullable(true),
    endDate: yup
    .date()
    .required('Este campo es obligatorio')
    .nullable(true)
    .typeError('Este campo es obligatorio'),
    totalValueApplied: yup
    .string()
    .required('Este campo es requerido')
    .nullable(true),
  })
  const { register, handleSubmit,  clearErrors,  setValue, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });

    const userName= JSON.parse(localStorage.getItem('session'))

  useEffect(() => {

      if(props?.isAnswer){
    
        setValue('name',dataAplication?.data?.questionnaire?.name)
        setValue('endDate', dataAplication?.data?.applicationEndDate)
        setValue('startDate', dataAplication?.data?.applicationStartDate)
        setValue('totalValueApplied', dataAplication?.data?.applicationValue)
        setValue('description',dataAplication?.data?.questionnaire?.description)
          dispatch({
              type: "SET_VALUE_QUESTIONNAIRE",
              totalValueQuestionnaire: Number(watch('totalValueApplied'))/dataAplication?.data?.questionnaire?.associatedQuestions?.length,
              activeValoration: true
            });
            const valueForQuestions = Number(watch('totalValueApplied'))/dataAplication?.data?.questionnaire?.associatedQuestions?.length
            const nose = []
  
            for (let index = 0; index < dataAplication?.data?.questionnaire?.associatedQuestions?.length; index++) {
             
              const uuidQuestions = dataAplication?.data?.questionnaire?.associatedQuestions[index]?.uuid
              const findUuid= valorateAplication?.data?.find((x)=> x?.uuid ===uuidQuestions)
                      // agregando nuevo valor aplicado a la pregunta y sus opciones cuando es manual
                  
                  nose.push({
                      uuid: uuidQuestions,
                      valueApplied: Number(valueForQuestions),
                      questionAlternativeList: []
                  })
                      
                      const getAnswer = nose.find((x)=> x?.uuid === findUuid?.uuid)
                  for (let index = 0; index < findUuid?.questionAlternativeList.length; index++) {
                     
                      getAnswer?.questionAlternativeList.push({ 
                          uuid: findUuid?.questionAlternativeList[index]?.uuid, 
                          valueApplied: Number(watch('totalValueApplied')/dataAplication?.data?.questionnaire?.associatedQuestions?.length)/findUuid?.questionAlternativeList.length 
                      })
                   }
  
              }
      
              dispatch({
                      type: "SET_MODAL_VALORATE_APLICATION",
                      icon:'WARNING',
                      data: nose
                    })
             
      } else {
      clearErrors('name1')  
      setValue('name',dataAplication?.data?.name)
      setValue('type',dataAplication?.data?.type )
      setValue('status',dataAplication?.data?.status)
      setValue('description',dataAplication?.data?.description)
      }
  }, [])


 

  const notDisabledSubmit = () =>  dispatch({
    type: "SET_BREADCRUMB",
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Responder cuestionario",
    index: 6,
    subPath: `${PATH_APP}/answer-questionnaire`,
    isSubmit: true,
    idForm: 'formAplication',
    module: 'ANSWER_QUESTIONARY',
    disabledSubmit: false,
    textIsSubmit: "GUARDAR",
  });

  const disabledSubmit = () =>  dispatch({
    type: "SET_BREADCRUMB",
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Responder cuestionario",
    index: 6,
    subPath: `${PATH_APP}/answer-questionnaire`,
    isSubmit: true,
    idForm: 'formAplication',
    module: 'ANSWER_QUESTIONARY',
    disabledSubmit: true,
    textIsSubmit: "GUARDAR",
  });



  const handleCloseModalAndReset = () => {
    notDisabledSubmit()
    props?.setMessage({...props.message, open:false})
    history.push(`${PATH_APP}/answer-questionnaire`)
    dispatch({
      type: "FILTER_QUESTIONARY_ANSWER",
      filter: answerQuestionary?.filter,
      isFilter: true,
      typeAction: 'ANSWER_QUESTIONARY'
    })
  }

  const handleCloseModal = () =>  {
      props?.setMessage({...props.message, open:false}) 
    notDisabledSubmit()
  }


  const onSubmit = (data) => {

   
      const newDataHeader = {
          startDate: moment(data?.startDate).format('YYYY-MM-DD'),
          endDate: moment(data?.endDate).format('YYYY-MM-DD'),
          //se deja con toString hasta que el backend cambie para que reciba un array en ves un string
          referredToUserUuid : data?.referredTo,
          totalValueApplied: Number(data?.totalValueApplied),
          automatic: checked?.name === 'manual' ? false : true
        }
      
  
   
    dispatch({
        type: "SET_HEADER_APLICATION",
        data: newDataHeader
    })
    dispatch({
        type: "SET_MODAL_VALORATE_APLICATION",
        activeValorate: true,
        dataClear: valorateAplication?.data ?  valorateAplication?.data :[],
        data: valorateAplication?.data ?  valorateAplication?.data :[]
    })


    const uuids = []
    
    for (let index = 0; index < questions?.length; index++) {
     uuids.push({uuid: questions[index]?.uuid, code: questions[index]?.answerAlternativeCode})
    }

    for (let index = 0; index < questions?.length; index++) {
        const filterUuid = valorateAplication?.data?.find((x)=> x?.uuid ===  uuids[index]?.uuid)

        const isChecked = filterUuid?.questionAlternativeList?.filter((x)=> x?.valueApplied !== 0)
        const filterOfAnswer = dataAplication?.data?.questionnaire?.associatedQuestions?.find((x)=> x?.uuid === filterUuid?.uuid)
        let valueTotalCuestionnaire = 0
        const isAplicate = valorateAplication?.data
     
        for (let index = 0; index < questions?.length; index++) {
          valueTotalCuestionnaire =  isAplicate[index]?.valueApplied + valueTotalCuestionnaire
        }
       
      
        if(filterUuid && filterOfAnswer?.answerAlternativeCode === 'ELU'  ){
            if(!filterUuid?.questionAlternativeList.length){
              return dispatch({
                  type: "SET_MODAL_VALORATE_APLICATION",
                  open: true,
                  icon:'WARNING',
                  activeValorate: false,
                  dataClear: valorateAplication?.data,
                  message: `Selecciona una respuesta para la pregunta #${index+1}`,
                  data: valorateAplication?.data ? [...valorateAplication?.data] : [],
               })
            }else {
             continue
            }
          } 
          if(filterUuid && filterOfAnswer?.answerAlternativeCode === 'RAN'  ){
            if(!filterUuid?.questionAlternativeList.length){
              return dispatch({
                  type: "SET_MODAL_VALORATE_APLICATION",
                  open: true,
                  icon:'WARNING',
                  activeValorate: false,
                  dataClear: valorateAplication?.data,
                  message: `Selecciona un orden para la pregunta #${index+1}`,
                  data: valorateAplication?.data ? [...valorateAplication?.data] : [],
               })
            }else {
             continue
            }
          } 
          if(filterUuid && filterOfAnswer?.answerAlternativeCode === 'TEX'  ){
            if(!filterUuid?.questionAlternativeList.length){
              return dispatch({
                  type: "SET_MODAL_VALORATE_APLICATION",
                  open: true,
                  icon:'WARNING',
                  activeValorate: false,
                  dataClear: valorateAplication?.data,
                  message: `Ingresa una respuesta para la pregunta #${index+1}` ,
                  data: valorateAplication?.data ? [...valorateAplication?.data] : [],
               })
            }else {
             continue
            }
          } 
          if(filterUuid && filterOfAnswer?.answerAlternativeCode === 'ESL'  ){
            if(!filterUuid?.questionAlternativeList.length){
              return dispatch({
                  type: "SET_MODAL_VALORATE_APLICATION",
                  open: true,
                  icon:'WARNING',
                  activeValorate: false,
                  dataClear: valorateAplication?.data,
                  message: `Selecciona una respuesta para la pregunta #${index+1}`,
                  data: valorateAplication?.data ? [...valorateAplication?.data] : [],
               })
            }else {
             continue
            }
          } 
        if(filterUuid && filterOfAnswer?.answerAlternativeCode ===  'MUL'  ){
               if( isChecked.length ){
                   continue
               } else {
                return dispatch({
                  type: "SET_MODAL_VALORATE_APLICATION",
                  open: true,
                  icon:'WARNING',
                  activeValorate: false,
                  dataClear: valorateAplication?.data,
                  message: `Selecciona mínimo  1 opción para la pregunta #${index+1}`,
                  data: valorateAplication?.data ? [...valorateAplication?.data] : [],
                 })
               }
              
            } 
      

            }
           
            let newData = [...valorateAplication?.data]
           
              for (let index = 0; index < questions.length; index++) {
              const answerData = valorateAplication?.data?.find((x)=> x?.uuid ===  uuids[index]?.uuid)
              const filterDataEntry = dataAplication?.data?.questionnaire?.associatedQuestions?.find((x)=> x?.uuid === answerData?.uuid)
              let uuidsAnswer = []
    
            
              if(answerData && filterDataEntry?.answerAlternativeCode === 'ELU'){
        
                let questionsDifferent = newData?.filter((x)=> x?.uuid !==  answerData?.uuid)
                uuidsAnswer = answerData?.questionAlternativeList
               
                questionsDifferent.push({uuid: answerData?.uuid , questionAlternativeList: uuidsAnswer, answerAlternativeCode:'ELU' })
                newData = questionsDifferent
                
            }
            if(answerData && filterDataEntry?.answerAlternativeCode === 'MUL'){
              let questionsDifferent = newData?.filter((x)=> x?.uuid !==  answerData?.uuid)
              const isCheckeds = answerData?.questionAlternativeList
             
              questionsDifferent.push({uuid: answerData?.uuid , questionAlternativeList: isCheckeds, answerAlternativeCode:'MUL' })
                newData = questionsDifferent
              
            }else if(answerData && filterDataEntry?.answerAlternativeCode === 'TEX'){
              let questionsDifferent = newData?.filter((x)=> x?.uuid !==  answerData?.uuid)
              uuidsAnswer = answerData?.questionAlternativeList

              questionsDifferent.push({uuid: answerData?.uuid , questionAlternativeList: uuidsAnswer, answerAlternativeCode:'TEX' })
              newData = questionsDifferent
              
            }else if(answerData && filterDataEntry?.answerAlternativeCode === 'ESL'){
            let questionsDifferent = newData?.filter((x)=> x?.uuid !==  answerData?.uuid)
            const isCheckeds = answerData?.questionAlternativeList
           
              questionsDifferent.push({uuid: answerData?.uuid , questionAlternativeList: isCheckeds, answerAlternativeCode:'ESL' })
              newData = questionsDifferent

            }else if(answerData && filterDataEntry?.answerAlternativeCode === 'RAN'){

            let questionsDifferent = newData?.filter((x)=> x?.uuid !==  answerData?.uuid)
            const isCheckeds = answerData?.questionAlternativeList
           
              questionsDifferent.push({uuid: answerData?.uuid , questionAlternativeList: isCheckeds, answerAlternativeCode:'RAN' })
              newData = questionsDifferent
             
            }
          }
          
        const createAnswer = async() => { 
          disabledSubmit()
          const newDataQuestion = Object.assign({questions:newData})
          const result = await  createAnswerQuestionnaire(dataAplication?.data?.uuid, newDataQuestion) 
          if (result && (result?.status >= 200 && result?.status <= 204)) {
              props.setMessage({
                open : true,
                type : 'SUCCESS',
                oneButtons : true,
                title: '¡Proceso exitoso!',
                textButtonSubmit: 'ACEPTAR',
                handleClick : () => handleCloseModalAndReset() ,
                description: 'Información almacenada correctamente ',
              })
            }
            else if (result?.status > 399) {
              props.setMessage({
                open : true,
                description: 'Error al guardar la información',
                handleClick : () => handleCloseModal() ,
                oneButtons : true,
                type : 'WARNING',
              })
            } else {
              props.setMessage({
                open : true,
                description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
                handleClick : () => handleCloseModal() ,
                oneButtons : true,
                type : 'WARNING',
              })
            }
         }
         createAnswer() 

    
     }



  return (
    <div style={{marginTop: '-5%'}}>
      <form id="formAplication" onSubmit={handleSubmit(onSubmit)} style={{marginTop: 37}}>
        <Grid container spacing={3} maxWidth="lg" style={{display: 'flex'}} direction="row">
          <Grid item lg={6} >
            <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
              <TextField 
                  // {...register("name")}
                  fullWidth
                  size='small'
                  label="Nombre Usuario"
                  disabled={true}
                  className={classes.dropdownDisabled}
                  value={userName?.user?.username}
              />
            </FormControl>
          </Grid>
          <Grid item lg={6} >
            <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                <TextField 
                    {...register("name")}
                    fullWidth
                    size='small'
                    label="Nombre cuestionario"
                    disabled={true}
                    className={classes.dropdownDisabled}
                    value={dataAplication?.data?.name}
                />
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
              <TextField 
                  {...register(`description`)}
                  fullWidth 
                  placeholder='Descripción'
                  label="Descripción"
                  className={classes.dropdownMultilineDisabled}
                  multiline
                  rows={3}
                  disabled={true}
                  size='small'
                  value={dataAplication?.data?.description}
              />
            </FormControl>
          </Grid>
          <Grid item lg={6} style={{display: 'flex', flexDirection: 'column'}}>
            <Grid container >
              <Grid item lg={5.7} style={{marginRight: '2.5%'}}>
                <FormControl fullWidth size="small">
                    <TextField 
                        {...register('startDate')}
                        size="small"
                        type="date"
                        label="Desde"
                        disabled={ true }
                        // value={}
                        className={classes.fieldDateTime}
                        minDate={new Date()}
                        InputLabelProps={{
                            shrink: true,
                            notched: true
                        }}
                    />
                </FormControl>
              </Grid>
              <Grid item lg={5.7} style={{marginLeft: '2.5%'}}>
                <FormControl fullWidth size="small">
                    <TextField 
                        {...register('endDate')}
                        size="small"
                        type="date"
                        label="Hasta"
                        disabled={ true }
                        className={classes.fieldDateTime }
                        minDate={new Date()}
                        InputLabelProps={{
                            shrink: true,
                            notched: true
                        }}
                    />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item lg={5.5} style={{display: 'flex', marginTop: '2%'}}>
            <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                <TextField 
                    {...register("totalValueApplied")}
                    fullWidth
                    size='small'
                    label="Valor total del cuestionario"
                    // onChange={(e)=>handleChange(e)}
                    disabled={true}
                    className={ classes.dropdownDisabled}
                />
            </FormControl> 
          </Grid>
          </Grid>
        </Grid>  
    </form>
   
</div>
  );
}
