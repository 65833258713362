import React, { useState, useEffect } from 'react';
import { Typography, Grid, TextField, FormControl, IconButton, Tooltip, FormHelperText } from '@mui/material';
import moment from 'moment';
import GraphicPercentage from './graphicBarPercentage';
import GraphicHorizontal from './graphicBarHorizontal';
import EditIcon from '@mui/icons-material/Edit';
import Logo from '../../../../../images/Logo_autoevaluciones.png';
import { useStateValue } from '../../../../../context/store';
import { useParams } from 'react-router-dom';
import 'moment/locale/es';
import {
  getResultEvaluationEvaluated,
  updateObservation,
} from '../../../../../actions/ManagementAplicationEvaluations-actions';
import { styles } from './styles';
import { CancelOutlined, SaveOutlined } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PATH_APP } from '../../../../../constants';
import TableResultGroup from './tableResultGroup';
import CriteriaTable from './criteriaTable';
import DivColor from './divColor';
import QuestionsAnswers from './questionsAnswers';

const PreviewPdf = props => {
  const classes = styles();
  const params = useParams();
  const { applicationId, evaluated } = params;
  const [{ dataToPage }, dispatch] = useStateValue();
  const [showButton, setShowButton] = useState(false);
  const [, setSaveObserVation] = useState(false);
  const [dataGraphic, setDataGraphic] = useState([]);
  const [dataField, setDataField] = useState({
    observations: '',
  });
  const [namePdf, setNamePdf] = useState();
  const [message, setMessage] = useState({
    description: '',
    textButtonSubmit: '',
    textButtonCancel: '',
    handleClick: () => {},
    handleClickOut: () => {},
    oneButtons: false,
    buttonsTwo: false,
    title: '',
    type: '',
    open: false,
  });
  const [criteriaRegister, setCriteriaRegister] = useState([]);
  const [resultGroup, setResultGroup] = useState([]);
  const [askAndAnswer, setAskAndAnswer] = useState([]);
  const [currentData, setCurrentData] = useState({})

  useEffect(() => {
    dispatch({
      type: 'SET_BREADCRUMB',
      breadCrumbText: 'Autoevaluaciones',
      breadCrumbSubText: 'Evaluados',
      index: 13,
      // isSubmit: true,
      // textIsSubmit: 'DESCARGAR',
      module: 'DETAIL_APLICATION_EVALUATION',
      // idForm: 'pdf',
      subPath: `${PATH_APP}/management-aplication-evaluateds/${applicationId}`,
    });
    //   window.scrollTo(0,0)
  }, []);

  const schema = yup.object().shape({
    observations: yup
      .string()
      .max(1500, 'Ingrese máximo 1500 caracteres')
      .min(3, 'Ingrese mínimo 3 caracteres'),
  });

  const {
    register,
    setValue,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    reValidateMode: 'onChange',
  });



  useEffect(() => {
    if (dataToPage?.data?.notes?.length) {
      setDataField({
        ...dataField,
        observations: dataToPage?.data?.notes?.length ? dataToPage?.data?.notes : '',
      });
    }

  }, [dataToPage]);

  const closeModal = () => {
    setMessage({ ...message, open: false });
    dispatch({
      type: 'SET_BREADCRUMB',
      breadCrumbText: 'Autoevaluaciones',
      breadCrumbSubText: 'Evaluados',
      index: 13,
      // isSubmit: true,
      // disabledSubmit: false,
      // textIsSubmit: 'DESCARGAR',
      module: 'DETAIL_APLICATION_EVALUATION',
      // idForm: 'pdf',
      subPath: `${PATH_APP}/management-aplication-evaluateds/${applicationId}`,
    });
  };



  const validateLabel = name => {
    if (name === 'SELF_EVALUATION') {
      return 'Autoevaluación';
    } else if (name === 'PEER_EVALUATION') {
      return 'Estudiantes';
    } else if (name === 'LEADER_EVALUATION') {
      return 'Institucional';
    } else if (name === 'CLIENT_EVALUATION') {
      return 'Evaluación cliente';
    }
  };

  useEffect(() => {
    const data = dataToPage?.data?.performanceEvaluation?.competences;
    // setNamePdf(`${dataToPage?.data?.evaluated?.name} ${dataToPage?.data?.evaluated?.surname}`);
    const newData = [];
    for (let index = 0; index < data?.length; index++) {
      newData.push({
        nameCompetence: dataToPage?.data?.performanceEvaluation?.competences[index]?.competence?.name,
        competences: [
          {
            label: `Resultado`,
            type: 'esperado',
            value: dataToPage?.data?.performanceEvaluation?.competences[index]?.value,
            color: '#072641',
          },
          {
            label: `Resultado`,
            type: 'obtenido',
            value: dataToPage?.data?.performanceEvaluation?.competences[index]?.valueObtained,
            color: '#18B0BE',
          },
        ],
      });
    }
    setCriteriaRegister([
      {
          id: 1,
          title: 'Cirterio 1',
          result: [
              {
                  id: 1, appearance: 'Aspecto 1', selfAssessment: 4.70,
                  student: 3.80, institutional: 4.90, average: 3.71
              },
              {
                  id: 2, appearance: 'Aspecto 2', selfAssessment: 4.70,
                  student: 3.80, institutional: 4.90, average: 3.71
              },
              {
                  id: 3, appearance: 'Aspecto 3', selfAssessment: 4.70,
                  student: 3.80, institutional: 4.90, average: 3.71
              },
          ]
      },
      {
          id: 2,
          title: 'Cirterio 2',
          result: [
              {
                  id: 1, appearance: 'Aspecto 1', selfAssessment: 4.70,
                  student: 3.80, institutional: 4.90, average: 3.71
              },
              {
                  id: 2, appearance: 'Aspecto 2', selfAssessment: 4.70,
                  student: 3.80, institutional: 4.90, average: 3.71
              },
              {
                  id: 3, appearance: 'Aspecto 3', selfAssessment: 4.70,
                  student: 3.80, institutional: 4.90, average: 3.71
              },
          ]
      },
  ]);

  setAskAndAnswer([
    {
      ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. ',
      answer: [
        {
          type: 'Estudiante',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Autoevaluación',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Institucional',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Estudiante',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
      ],
    },
    {
      ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
      answer: [
        {
          type: 'Estudiante',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Autoevaluación',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Institucional',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
      ],
    },
    {
      ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. ',
      answer: [
        {
          type: 'Estudiante',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Autoevaluación',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Institucional',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Estudiante',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
      ],
    },
    {
      ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
      answer: [
        {
          type: 'Estudiante',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Autoevaluación',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Institucional',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
      ],
    },
    {
      ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. ',
      answer: [
        {
          type: 'Estudiante',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Autoevaluación',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Institucional',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Estudiante',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
      ],
    },
    {
      ask: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
      answer: [
        {
          type: 'Estudiante',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Autoevaluación',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
        {
          type: 'Institucional',
          answer:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ac purus quis maximus. Proin et purus posuere, accumsan tellus et, congue libero. Proin at volutpat tellus. Aliquam feugiat lorem non neque iaculis, in blandit erat pellentesque. Aenean at urna porta, laoreet eros sed, pharetra purus. Maecenas egestas convallis nulla vel cursus. Pellentesque at arcu eros. Proin non metus a diam commodo porttitor id in erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin aliquet, risus non convallis pulvinar, tortor dolor rhoncus nisi, bibendum congue velit mauris quis nunc. Sed in nisl aliquet, venenatis dui at, gravida mi. Morbi et mattis diam. Quisque mattis tristique congue.',
        },
      ],
    },
    
  ]);


  const dataTableGroup = []
    for (let index = 0; index < 57; index++) {
        
        dataTableGroup.push(
            {
                subject: `Maestría en Ambientes - ${index+1}`,
                selfAssessment: 4.90, student: 4.50,
                institutional: 3.75, definitive: 4.17, population: 30 / 40
            },
        )
    }

    setResultGroup(dataTableGroup);

    setDataGraphic(newData);
    setNamePdf(`${dataToPage?.data?.evaluated?.name} ${dataToPage?.data?.evaluated?.surname}`);

  }, [dataToPage]);

  const validateMargin = v => {
    if (v === 4 || v === 5) {
      if (dataToPage?.data?.applicationTypes?.length === 1) {
        return null;
      } else if (dataToPage?.data?.applicationTypes?.length === 2) {
        return '2%';
      } else if (dataToPage?.data?.applicationTypes?.length === 3) {
        return '9%';
      }
    }
  };

  const titleCompetence = v => {
    if (dataToPage?.data?.applicationTypes?.length === 1) {
      return '5%';
    } else if (dataToPage?.data?.applicationTypes?.length === 2) {
      return '7%';
    } else if (dataToPage?.data?.applicationTypes?.length === 3) {
      return '9%';
    }
  };

  const edit = () => {
    setShowButton(true);
    props?.showCopy(false);
    clearErrors('observations');
    return dispatch({
      type: 'SET_BREADCRUMB',
      breadCrumbText: 'Autoevaluaciones',
      breadCrumbSubText: 'Evaluados',
      index: 13,
      // isSubmit: true,
      // disabledSubmit: true,
      // textIsSubmit: 'DESCARGAR',
      module: 'DETAIL_APLICATION_EVALUATION',
      // idForm: 'pdf',
      subPath: `${PATH_APP}/management-aplication-evaluateds/${applicationId}`,
    });
  };

  const cancel = () => {
    setShowButton(false);
    props?.showCopy(true);
    setDataField({
      observations: dataToPage?.data?.notes?.length ? dataToPage?.data?.notes : '',
    });
    return dispatch({
      type: 'SET_BREADCRUMB',
      breadCrumbText: 'Autoevaluaciones',
      breadCrumbSubText: 'Evaluados',
      index: 13,
      // isSubmit: true,
      // disabledSubmit: false,
      // textIsSubmit: 'DESCARGAR',
      module: 'DETAIL_APLICATION_EVALUATION',
      // idForm: 'pdf',
      subPath: `${PATH_APP}/management-aplication-evaluateds/${applicationId}`,
    });
  };

  const closeSuccess = () => {
  
    setShowButton(false);
    setSaveObserVation(true);
    closeModal();
  };

  const save = async () => {
    if (errors?.observations?.message) {
      return null;
    } else {
      const data = { notes: dataField?.observations };
      const result = await updateObservation(data, applicationId, evaluated);

      if (result?.status >= 200 && result?.status <= 204) {
        dispatch({
          type: 'SET_BREADCRUMB',
          breadCrumbText: 'Autoevaluaciones',
          breadCrumbSubText: 'Evaluados',
          index: 13,
          // isSubmit: true,
          // disabledSubmit: false,
          // textIsSubmit: 'DESCARGAR',
          module: 'DETAIL_APLICATION_EVALUATION',
          // idForm: 'pdf',
          subPath: `${PATH_APP}/management-aplication-evaluateds/${applicationId}`,
        });
        return closeSuccess();
      }
      if (result?.status === 404) {
        return setMessage({
          open: true,
          description: 'La aplicación de modelo de evaluación no existe',
          handleClick: () => closeModal(),
          oneButtons: true,
          textButtonSubmit: 'ACEPTAR',
          type: 'WARNING',
        });
      } else {
        return setMessage({
          open: true,
          description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
          handleClick: () => closeModal(),
          oneButtons: true,
          textButtonSubmit: 'ACEPTAR',
          type: 'WARNING',
        });
      }
    }
  };

  const handleChange = e => {
    const { value } = e.target;
    setDataField({ ...dataField, observations: value });
    setValue('observations', value);
    trigger('observations');
  };


  if(!dataToPage?.data?.performanceEvaluation?.competences?.length){
    return null
  }else {
  return (
    <form style={{ backgroundColor: '#ffffff', zIndex: 1 }}>
      <Grid container id="page" justifyContent="center">
        <Grid container className="page1 page" justifyContent="center">
          <Grid item lg={12} md={12} style={{ paddingTop: '2%', background: 'rgb(161 213 229 / 61%)' }}>
            <div
              style={{
                textAlign: 'center',
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'flex',
                color: 'black',
              }}
            >
              <img src={Logo} className={classes.img} alt="Logo" />
              <h3
                style={{
                  textAlign: 'center',
                  margin: 'auto',
                  fontSize: '24px',
                  fontWeight: '900',
                  color: 'black',
                }}
              >
                RESULTADOS DE LA EVALUACIÓN
              </h3>
              <p style={{ fontSize: 16, fontWeight: 400, margin: 'auto' }}>
                {moment(new Date()).format('DD-MM-YYYY')}
              </p>
            </div>
          </Grid>
          <Grid item lg={10} style={{ margin: '3% 0px' }}>
            <Typography style={{ fontSize: 24, fontWeight: 400 }}>Información del colaborador</Typography>
          </Grid>
          <Grid item lg={10}>
            <table className={classes.table}>
              <tr>
                <td className={classes.titleInfo}>Nombre</td>
                <td className={classes.valueInfo}>
                  {`${namePdf}`}{' '}
                </td>
                <td className={classes.titleInfo}>Identificación</td>
                <td
                  className={classes.valueInfo}
                >{`${dataToPage?.data?.evaluated?.identificationNumber}`}</td>
              </tr>
              <tr style={{ border: '1px solid' }}>
                <td className={classes.titleInfo}>Programa</td>
                <td className={classes.valueInfo}>{dataToPage?.data?.evaluated?.regional?.name}</td>
                <td className={classes.titleInfo}>Cargo</td>
                <td className={classes.valueInfo}>{dataToPage?.data?.evaluated?.position?.name}</td>
              </tr>
            </table>
          </Grid>
          <Grid
            item
            lg={10}
            style={{
              marginBottom: dataGraphic?.length > 4 ? '4%' : '1%',
              marginTop: '3%',
            }}
          >
            <Typography style={{ fontSize: 24, fontWeight: 400 }}>Información de la evaluación</Typography>
          </Grid>
          <Grid item lg={10}>
            <table className={classes.table}>
              <tr>
                <td className={classes.titleInfo} style={{ width: '20%' }}>
                  Nombre
                </td>
                <td className={classes.valueInfo} style={{ textAlign: 'start' }}>
                  {dataToPage?.data?.performanceEvaluation?.name}{' '}
                </td>
              </tr>
            </table>
          </Grid>
          <Grid item lg={10} style={{ margin: '1% 0%' }}>
            <table className={classes.table}>
              <tr>
                <td className={classes.titleInfo} style={{ width: '20%' }}>
                  Descripción
                </td>
                <td className={classes.valueInfo} style={{ textAlign: 'start' }}>
                  {dataToPage?.data?.performanceEvaluation?.description}
                </td>
              </tr>
            </table>
          </Grid>
          <Grid item lg={10}>
            <table className={classes.table}>
              <tr>
                <td className={classes.titleInfo} style={{ width: '20%' }}>
                  Periodo de evaluación
                </td>
                <td className={classes.valueInfo} style={{ textAlign: 'start' }}>{`${moment(
                  dataToPage?.data?.performanceEvaluation?.startDate
                ).format('DD [de] MMMM [de] YYYY')} hasta ${moment(
                  dataToPage?.data?.performanceEvaluation?.endDate
                ).format('DD [de] MMMM [de] YYYY')}`}</td>
              </tr>
            </table>
          </Grid>
          <Grid item lg={10} style={{ margin: '1% 0%' }}>
            <table className={classes.table}>
              <tr>
                <td className={classes.titleInfo} style={{ width: '20%' }}>
                  Periodo de aplicación
                </td>
                <td className={classes.valueInfo} style={{ textAlign: 'start' }}>{`${moment(
                  dataToPage?.data?.performanceEvaluation?.startDate
                ).format('DD [de] MMMM [de] YYYY')} hasta ${moment(
                  dataToPage?.data?.performanceEvaluation?.endDate
                ).format('DD [de] MMMM [de] YYYY')}`}</td>
              </tr>
            </table>
          </Grid>
          <Grid item lg={10} style={{ marginBottom: '1%', marginTop: '3%' }}>
            <Typography style={{ fontSize: 20, fontWeight: 400, textAlign: 'start' }}>
              {`En seguida se describen los distintos tipos de evaluación utilizados (autoevaluación, evaluación entre pares y evaluación del líder), junto con el peso asignado a cada uno y los resultados obtenidos en cada evaluación.`}{' '}
            </Typography>
          </Grid>

          <Grid
            item
            lg={10}
            style={{
              marginBottom: '6%',
              marginTop: dataGraphic?.length > 4 ? '4%' : '1%',
            }}
          >
            <table className={classes.table}>
              <tr>
                <th className={classes.titleTablePercentagesBorder}>Tipo de evaluación</th>
                <th className={classes.titleTablePercentages}>Total obtenido</th>
                <th className={classes.titleTablePercentages}>Valoración</th>
              </tr>
              {dataToPage?.data?.applicationTypes?.map(x => (
                <tr>
                  <td className={classes.headerTable}>
                    {validateLabel(x?.type)}
                    <span className={classes.headerTableSpan}>{x?.percentage}</span>
                  </td>
                  <td className={classes.titleGraphic}>
                    {/* <GraphicPercentage value={x?.percentage} /> */}
                    <DivColor quantityDivisions={5} coloredDivisions={x?.totalObtained} />
                  </td>
                  <td className={classes.titleGraphicSpan}>
                    {/* <GraphicPercentage value={x?.totalObtainedInPercentage} /> */}
                    Bajo
                    {x?.level}
                    {/* <span className={classes.titleGraphicSpan}></span> */}
                  </td>
                </tr>
              ))}

              <tr>
                <td className={classes.headerTable}>
                  Rendimiento definitivo
                  <span className={classes.headerTableSpan}>
                    {dataToPage?.data?.performanceEvaluation?.totalResultPercentage}
                  </span>
                </td>
                <span className={classes.titleGraphicPerformance}>
                  <GraphicPercentage value={dataToPage?.data?.performanceEvaluation?.totalResultPercentage} />
                </span>
              </tr>
            </table>
          </Grid>
        </Grid>

        <Grid
          container
          className="page2 page"
          style={{ pageBreakInside: 'avoid', marginBottom: '7%' }}
          justifyContent="center"
        >
          <Grid item container lg={12} justifyContent="center">
            <Grid item lg={10} style={{ marginBottom: '4%', pageBreakBefore: 'always' }}>
              <Typography
                style={{
                  padding: '2rem 4rem',
                  background: 'rgb(161 213 229 / 61%)',
                  fontWeight: 900,
                  fontSize: 24,
                }}
              >
                TABLA DE RESULTADOS POR GRUPO
              </Typography>
            </Grid>
            <Grid item container lg={10}>
              <TableResultGroup resultGroup={resultGroup} />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          className="page3 page"
          style={{ pageBreakInside: 'avoid', marginBottom: '7%' }}
          justifyContent="center"
        >
          <Grid item container lg={12} justifyContent="center">
            <Grid item lg={10} style={{ marginBottom: '4%', pageBreakBefore: 'always' }}>
              <Typography
                style={{
                  padding: '2rem 4rem',
                  background: 'rgb(161 213 229 / 61%)',
                  fontWeight: 900,
                  fontSize: 24,
                }}
              >
                TABLA DE CRITERIOS
              </Typography>
            </Grid>
            <Grid item container lg={10}>
              <CriteriaTable criteriaRegister={criteriaRegister} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="page4 page" style={{ pageBreakInside: 'avoid' }} justifyContent="center">
          <Grid item container lg={12} justifyContent="center">
            <Grid item lg={10} style={{ marginBottom: '4%', pageBreakBefore: 'always' }}>
              <Typography
                style={{
                  padding: '2rem 4rem',
                  background: 'rgb(161 213 229 / 61%)',
                  fontWeight: 900,
                  fontSize: 24,
                }}
              >
                RESPUESTA A PREGUNTAS ABIERTAS
              </Typography>
            </Grid>
            <Grid item container lg={10}>
              <QuestionsAnswers askAndAnswer={askAndAnswer} />
            </Grid>
          </Grid>
        </Grid>
        <Grid className="page5 page" container justifyContent="center">
          <Grid item lg={10} style={{ marginTop: '1%', padding: 10 }}>
            <Grid item lg={12} style={{ display: 'flex', alignItems: 'center' }}>
              {showButton ? (
                <div style={{ display: 'flex' }}>
                  <Tooltip title="Guardar" arrow>
                    <IconButton
                      onClick={() => {
                        save();
                      }}
                    >
                      <SaveOutlined fontSize="small" color="success" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancelar" arrow>
                    <IconButton
                      onClick={() => {
                        cancel();
                      }}
                    >
                      <CancelOutlined fontSize="small" color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                <Tooltip title="Editar" arrow>
                  <IconButton
                    onClick={() => {
                      edit();
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <Typography style={{ fontSize: 20, fontWeight: 20, textAlign: 'initial' }}>
                Observaciones
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} style={{ marginTop: '3%' }}>
              {!showButton ? (
                <div
                  style={{
                    border: '1px solid #ede6e6',
                    background: '#ede6e6',
                    height: 300,
                    borderRadius: 15,
                  }}
                >
                  <Typography style={{ padding: 10 }}>{dataField?.observations}</Typography>
                </div>
              ) : (
                <>
                  <FormControl fullWidth>
                    <TextField
                      {...register('observations')}
                      fullWidth
                      type="text"
                      value={dataField?.observations}
                      disabled={!showButton}
                      onChange={e => handleChange(e)}
                      className={classes.textField}
                      name="observations"
                      minRows={12}
                      maxRows={15}
                      multiline
                      error={!!errors.hasOwnProperty('observations') && errors['observations'].message}
                    />
                  </FormControl>
                  <FormHelperText style={{ color: 'red', marginLeft: '3%' }}>
                    {errors.hasOwnProperty('observations') && errors['observations'].message}
                  </FormHelperText>
                </>
              )}
            </Grid>
            {/* Firmas */}
            <Grid
              item
              container
              lg={12}
              justifyContent="space-between"
              style={{ margin: '3% 0px', display: 'flex' }}
            >
              <Grid item lg={5}>
                <div
                  style={{
                    border: '1px solid #ede6e6',
                    background: '#ede6e6',
                    height: 100,
                    borderRadius: 15,
                    marginBottom: '1rem',
                  }}
                ></div>
                <Typography
                  style={{
                    fontWeight: 800,
                    fontSize: 20,
                    textAlign: 'initial',
                  }}
                >
                  Evaluado
                </Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    textAlign: 'initial',
                  }}
                >
                  Nombre
                </Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    textAlign: 'initial',
                  }}
                >
                  Identificación
                </Typography>
              </Grid>
              <Grid item lg={5}>
                <div
                  style={{
                    border: '1px solid #ede6e6',
                    background: '#ede6e6',
                    height: 100,
                    borderRadius: 15,
                    marginBottom: '1rem',
                  }}
                ></div>
                <Typography
                  style={{
                    fontWeight: 800,
                    fontSize: 20,
                    textAlign: 'initial',
                  }}
                >
                  Dir. Gestión del Talento Humano
                </Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    textAlign: 'initial',
                  }}
                >
                  Nombre
                </Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    textAlign: 'initial',
                  }}
                >
                  Identificación
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
  }
};

export default PreviewPdf;
