import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField,  
     FormControl, 
     Button,
     Autocomplete,
     Box,
} from '@mui/material'
import {styles} from './styles';
import { useStateValue } from "../../../../../context/store";
import { useForm, } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Index = (props) => {

    const classes = styles()
    const [, dispatch] = useStateValue();
    const [valuesForm, setValuesForm] = useState({
        regionalName: null,
        positionName: null,
    })
    
    const schema = yup.object().shape({
        positionName: yup
        .string(),
        regionalName:   yup
        .string()
      });
    
      
      const { register, handleSubmit, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });

      const handleClose = () => props.setMessage({...props.message, open: false})

      const onSubmit = (data) => {
    
          if(data?.regionalName === '' && data?.positionName === '' ){
            return  props?.setMessage({
                message: 'Ingrese información en algúno de los campos',
                open: true,
                severity: 'error',
                handleClose: () => handleClose()
              })
          }
          if(
            data?.regionalName !== '' ||
            data?.positionName !== '' 
        ){
            dispatch({
                type: "EVALUATEDS_APPLICATION_SEARCH",
                filter: {
                    regionalName: valuesForm?.regionalName?.uuid,
                    positionName: data?.positionName,
                    page: 0,
                    size: 10
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "EVALUATEDS_APPLICATION_SEARCH",
                filter: {
                    regionalName: null,
                    positionName: null,
                  },
                  isFilter: true,
            })
        }
      }

      useEffect(() => {
        clear()
      }, [props?.clearFielForm])

    const clear = () => {
        setValue('regionalName','')
        setValue('positionName', '')
        setValuesForm({...valuesForm, regionalName: null, positionName: null })
        props.clearFilter()
        
    }


      const validate = watch('regionalName') || watch('positionName')
      const disabledButton = !validate

  

      
    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <Autocomplete
                            {...register('regionalName')}
                            options={props?.regionals}
                            autoHighlight
                            className={classes.dropdown}
                            size="small"
                            clearText={null}
                            noOptionsText="No hay opciones"
                            getOptionLabel={(option) => option?.name}
                            onChange={(e, newValue) => {
                                setValue('regionalName', newValue?.name);
                                setValuesForm({...valuesForm, regionalName: newValue})
                            
                            }}
                            value={valuesForm?.regionalName}
                            renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                    {option?.name}
                            </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                {...register('regionalName')}
                                label="Programa"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                placeholder="Programa"
                                />
                            )}
                            
                        />
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={5} >
                    <FormControl variant='outlined' fullWidth size="small" >
                        <Autocomplete
                            {...register('positionName')}
                            id="country-select-demo"
                            options={props?.positions}
                            autoHighlight
                            clearText={null}
                            noOptionsText="No hay opciones"
                            className={classes.dropdown}
                            size="small"
                            getOptionLabel={(option) => option?.name }
                            onChange={(e, newValue) => {
                                setValue('positionName', newValue);
                                setValuesForm({...valuesForm, positionName: newValue})
                                // setUserLeader(newValue)
                            
                            }}
                            renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                    {option?.name}
                            </Box>
                            )}
                            value={valuesForm?.positionName}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                {...register('positionName')}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                placeholder="cargo"
                                label="cargo"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={2} style={{display: 'flex',  alignItems: 'center', marginBottom: '0.5%'}}>
                    <Button 
                        variant="contained" 
                        type="submit" 
                        disabled={disabledButton}
                        disableElevation 
                        color="primary"
                    >
                        BUSCAR
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button 
                        variant="outlined"  
                        disableElevation 
                        onClick={clear}
                        style={{background: "#ffffff"}}
                    >
                        LIMPIAR
                    </Button>

                </Grid>
            </Grid>
         </form>
    )
}

export default Index
