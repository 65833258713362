import React, { useState, useEffect } from "react";
import * as IdKey from "short-uuid";
import {
  Typography,
  Tooltip,
  Grid,
  Button,
  Checkbox
} from "@mui/material";
import { useStateValue } from "../../../../context/store";
import {styles} from "./styles"
import { updateEmployee } from "../../../../actions/OrganizationChart-action";

export default function DetailedAccordion({
  setMessage,
  message,
  data,
  clearFields,
  page,
  leader,
  currentsLeaders,
  setScroll,
}) {
  const classes = styles();

  const id = IdKey.generate();
  const [{ organization }, dispatch] = useStateValue();
  const [checkeds, setCheckeds] = useState([]);
  const [selectAll, setSelectAll] = useState(false)
  const [disbledSubmit, setDisabledSubmit]= useState(false)
  const [numberPosition, setNumberPosition] = useState(0);
  const [allChecked, setAllChecked] = useState(false);



  useEffect(() => {

    if(organization?.leaderId && organization?.newSubalterns?.length){
      const newCheckeds = []
      for (let index = 0; index < organization?.newSubalterns?.length; index++) {
        newCheckeds.push({employeeId: organization?.newSubalterns[index]?.uuid})
      }

      setCheckeds(newCheckeds);
    }else {
      setCheckeds([])
    }

  }, [organization?.leaderId || data])

  

  const closeModal = () => {setMessage({...message, open:false}); setDisabledSubmit(false)}
  const closeAndReset = () => {
    dispatch({
      type: 'ORGANIZATION_CHART',
      changeLeader: false,
      infoLeader: {},
      newSubalterns: [],
      leaderId: ''
    })
    clearFields()
    setDisabledSubmit(false)
  }
  


const validateChecked = (user) => {

  const result =  checkeds?.find((x) =>x?.employeeId === user )
 return result ? true : false
}

const handleChangeChecked = (event) => {
  const copy= [...checkeds]
  const {name} = event.target;
  const findUser = data?.find((x) => x?.uuid === name)
  const validateExists = validateChecked(findUser?.uuid)

  if(checkeds?.length){
    if(validateExists) {

      setCheckeds(checkeds?.filter((x)=> x?.employeeId !== name));
      const newSubal =  organization?.newSubalterns?.filter((x)=> x?.uuid !== name);

      return dispatch({
        type: 'ORGANIZATION_CHART',
        changeLeader: organization?.changeLeader,
        editLeader: organization?.editLeader,
        leaderToEdit: organization?.leaderToEdit,
        infoLeader: organization?.infoLeader,
        leaderId: organization?.leaderId,
        subalterns: organization?.subalterns,
        newSubalterns: newSubal,
        page: organization?.page
      })
   }else {
      const currentSubalterns = [...organization?.newSubalterns]
      currentSubalterns?.push(findUser);

      setNumberPosition(numberPosition+1)
      copy.push({employeeId:  name}) 
      setCheckeds(copy);
      
      return dispatch({
        type: 'ORGANIZATION_CHART',
        changeLeader: organization?.changeLeader,
        editLeader: organization?.editLeader,
        leaderToEdit: organization?.leaderToEdit,
        infoLeader: organization?.infoLeader,
        leaderId: organization?.leaderId,
        subalterns: organization?.subalterns,
        newSubalterns: currentSubalterns,
        page: organization?.page
      })
  }
 } else {

  const currentSubalterns = [...organization?.newSubalterns]
  currentSubalterns?.push(findUser);
  
  const copyCurrentUsers = [...organization?.subalterns]
  const newUser = data?.find((x)=> x?.uuid === name);
  copyCurrentUsers?.push(newUser)
  setNumberPosition(numberPosition+1)
  copy.push(
    { 
      employeeId: name, 
    }
  )
  setCheckeds(copy);
  return dispatch({
    type: 'ORGANIZATION_CHART',
    changeLeader: organization?.changeLeader,
    editLeader: organization?.editLeader,
    leaderToEdit: organization?.leaderToEdit,
    infoLeader: organization?.infoLeader,
    leaderId: organization?.leaderId,
    subalterns: organization?.subalterns,
    newSubalterns: currentSubalterns,
    page: organization?.page
})
 

 }
};

  const createRelation = () => {
 
    const notChange = [];
    const deleteSubalterns = [];
    const newSubalterns = []

    for (let index = 0; index < checkeds?.length; index++) {
      const findNewSubalter = organization?.subalterns?.find((x)=> x?.uuid === checkeds[index]?.employeeId)

      if(!findNewSubalter){
        newSubalterns?.push({employeeId: checkeds[index]?.employeeId})
      }
    }

    for (let index = 0; index < organization?.subalterns?.length; index++) {
      const findSubalter = checkeds?.find((x)=> x?.employeeId === organization?.subalterns[index]?.uuid)

      if(findSubalter){
        notChange.push(findSubalter)
      } else if(!findSubalter){
        deleteSubalterns?.push({employeeId: organization?.subalterns[index]?.uuid})
      }
    }
    
    let newDataCreate = {}
    if(newSubalterns?.length && deleteSubalterns?.length){
      newDataCreate = {
        operationType: "CREATE",
        leaderId: organization?.leaderId,
        subalterns: newSubalterns
      }
      const deleteSubal = async() => {
        const result = await updateEmployee({
          operationType: "DELETE",
          leaderId: organization?.leaderId,
          subalterns: deleteSubalterns
        })
        if (result && (result?.status === 200 || result?.status <= 204)) {
          return  update()
        }
        else if (result?.status === 400 ) {
          setMessage({
            open : true,
            description: result?.data?.message,
            handleClick : () => closeModal() ,
            oneButtons : true,
            type : 'WARNING',
          })
        } else {
          setMessage({
            open : true,
            description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
            handleClick : () => closeModal() ,
            oneButtons : true,
            type : 'WARNING',
          })
        }
      }
      deleteSubal()  
    }else if(newSubalterns?.length && !deleteSubalterns?.length){
      newDataCreate = {
        operationType: "CREATE",
        leaderId: organization?.leaderId,
        subalterns: newSubalterns
      }
    } else if(!newSubalterns?.length && deleteSubalterns?.length){
      newDataCreate = {
        operationType: "DELETE",
        leaderId: organization?.leaderId,
        subalterns: deleteSubalterns
      }
    }
    setDisabledSubmit(true)
    

    

  const update = async() => {
    const result = await updateEmployee(newDataCreate)
    if (result && (result?.status === 200 || result?.status <= 204)) {
      return  setMessage({
        open : true,
        type : 'SUCCESS',
        oneButtons : true,
        title: '¡Proceso exitoso!',
        textButtonSubmit: 'ACEPTAR',
        handleClick : () => closeAndReset(),
        description: 'Información almacenada correctamente ',
          })
    }
    else if (result?.status === 400 ) {
      setMessage({
        open : true,
        description: result?.data?.message,
        handleClick : () => closeModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    } else {
      setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => closeModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }
  }
  update()
  }

  useEffect(() => {
    if(organization?.changeLeader){
        const filterLeader = organization?.newSubalterns?.find((x)=> x?.person?.documentNumber === organization?.leaderId)
        if(filterLeader?.collaborators?.length){
          setCheckeds(filterLeader?.collaborators)
     
        }else {
          setCheckeds([...checkeds])

        }
        dispatch({
          type: 'ORGANIZATION_CHART',
          changeLeader: false,
          editLeader: organization?.editLeader,
          leaderToEdit: organization?.leaderToEdit,
          infoLeader: organization?.infoLeader,
          leaderId: organization?.leaderId,
          newSubalterns: organization?.newSubalterns,
          page: organization?.page
      })
    }

  }, [organization?.changeLeader])


  const cancelEdit = () =>  {
    const newCheckeds = []

    for (let index = 0; index < organization?.subalterns?.length; index++) {
      newCheckeds.push({employeeId: organization?.subalterns[index]?.uuid})
    }
      setCheckeds(newCheckeds)
}

const validatedLeader = (u) => {
  const findUserSelected = checkeds?.find((x)=> x?.employeeId === u) 
  const findUserCurrentData = data?.find((x)=> x?.uuid === u) 

  if(findUserSelected) {
    return false
  } else if(!findUserSelected && findUserCurrentData?.leader && findUserCurrentData?.leader?.uuid !== organization?.leaderId){
    return true
  }
}

const closeModalCancel = () => {setSelectAll(false); setMessage({...message, open:false})}

const confirm = () => {

  const newChecked = [];
  setMessage({...message, open:false}); setSelectAll(true)
  const differentsToLeaders = data?.filter((x)=> !x?.leader)

  for (let index = 0; index < organization?.subalterns?.length; index++) {
    differentsToLeaders.push(organization?.subalterns[index])
  } 

  for (let index = 0; index < differentsToLeaders?.length; index++) {
    newChecked.push({employeeId: differentsToLeaders[index].uuid})
  }

  setCheckeds(newChecked)
      dispatch({
        type: 'ORGANIZATION_CHART',
        changeLeader: organization?.changeLeader,
        editLeader: organization?.editLeader,
        leaderToEdit: organization?.leaderToEdit,
        infoLeader: organization?.infoLeader,
        leaderId: organization?.leaderId,
        subalterns: organization?.subalterns,
        newSubalterns: organization?.newSubalterns,
        page: page
    })
}

  const checkedAll = () => {
    
    if(allChecked) {
      setCheckeds([])
      setSelectAll(false);
      dispatch({
        type: 'ORGANIZATION_CHART',
        changeLeader: organization?.changeLeader,
        editLeader: organization?.editLeader,
        leaderToEdit: organization?.leaderToEdit,
        infoLeader: organization?.infoLeader,
        leaderId: organization?.leaderId,
        subalterns: organization?.subalterns,
        newSubalterns: organization?.newSubalterns,
        page: null
    })
    }else {
      return setMessage({
        open : true,
        description: 'Se seleccionarán los usuarios de la página actual que tienen líder asignado, al pasar de página se deseleccionarán.',
        handleClick : () => confirm() ,
        handleClickOut : () => closeModalCancel() ,
        buttonsTwo: true,
        textButtonCancel: 'CANCELAR',
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })

      
    }
  }
  

  useEffect(() => {
   if(organization?.page === page){
    const newChecked = [];
    setMessage({...message, open:false}); setSelectAll(true)
    const differentsToLeaders = data?.filter((x)=> !x?.leader)
  
    for (let index = 0; index < organization?.newSubalterns?.length; index++) {
      differentsToLeaders?.push(organization?.newSubalterns[index])
    } 
  
    for (let index = 0; index < differentsToLeaders?.length; index++) {
      newChecked.push({employeeId: differentsToLeaders[index].uuid})
    }
  
    setCheckeds(newChecked)
    return setAllChecked(true)
   }else {
    setSelectAll(false)
   }

  }, [organization?.page])


if(data?.length) {
  return (
    <div className={classes.root}>
      <Grid item container lg={12} direction="row" style={{marginTop: '2%'}} className={classes.root} cellspacing="0">
        <div className={classes.titlesTable}>
          <span style={{ flexBasis: '8%'}}>
            <Tooltip title="Todas" arrow placement="top" >
              <Checkbox
                size="small"
                disabled={!organization?.leaderId}
                onChange={checkedAll}
                checked={selectAll}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Tooltip>
          </span>
          <Typography style={{fontSize: 12, flexBasis: '23.5%'}}>Nombre</Typography>
          <Typography style={{fontSize: 12, flexBasis: '25%'}}>Identificación</Typography>
          <Typography style={{fontSize: 12, flexBasis: '26%'}}> Cargo</Typography>
          <Typography style={{fontSize: 12}}> Programa</Typography>
        </div>
        {data?.map((data, idColumn=id) => ( 
          <React.Fragment  key={idColumn}>
        <Grid container style={{marginBottom: '-10px'}}>

          <Grid item lg={3.5} xs={2} className={classes.columnOneTitle} 
              style={{   borderTopLeftRadius: idColumn === 0 ? 10 : 0, 
                        borderBottomLeftRadius: idColumn === data?.length -1 ? 10 : 0, display: 'flex', alignItems: 'center' }}
              >
                {validatedLeader(data?.uuid) ?
                  <Tooltip title="Este usuario ya posee un líder asignado" arrow placement="top" >
                    <span style={{ flexBasis: '8%'}}>
                      <Checkbox
                        size="small"
                        checked={validateChecked(data?.uuid)}
                        name={data?.uuid}
                        disabled={validatedLeader(data?.uuid) }
                        onChange={handleChangeChecked}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </span> 
                  </Tooltip>
                  :
                  <Checkbox
                    size="small"
                    checked={validateChecked(data?.uuid)}
                    name={data?.uuid}
                    disabled={validatedLeader(data?.uuid) || data?.uuid === organization?.leaderId}
                    onChange={handleChangeChecked}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />

              }
                
            <Typography className={classes.heading} style={{marginLeft: '10%'}}>
              <b>{`${data?.name ? data?.name : ''} ${data?.secondName ? data?.secondName : ''} ${data?.surname ? data?.surname :''} ${data?.secondSurname ? data?.secondSurname :''}`}</b>
            </Typography>

          </Grid>
          <Grid item lg={3} xs={2} className={classes.columnOneTitle} >
            <Typography className={classes.heading} style={{marginLeft: '10%',  marginTop: '2%'}}>
              {data?.identificationNumber}
            </Typography>
          </Grid>
          <Grid item lg={3.5} xs={2} className={classes.columnOneTitle} >
            <Typography className={classes.heading} style={{marginLeft: '10%',  marginTop: '2%'}}>
              {data?.position?.name}
            </Typography>
          </Grid>
          <Grid item lg={2} xs={2} className={classes.columnOneTitle} style={{  background: '#FFFFFF', 
            borderTopRightRadius: idColumn === 0 ? 10 : 0, 
            borderBottomRightRadius: idColumn === data?.length -1 ? 10 : 0}} >
            <Typography className={classes.heading} style={{marginRight: '10%',  marginTop: '2%'}}>
              {data?.regional?.name}
            </Typography>
          </Grid>
        </Grid>
     </React.Fragment>
        ))}
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.buttons}>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
         {
          organization?.leaderId && organization?.subalterns?.length ?
          <Button 
            variant="outlined" 
            style={{borderColor: 'darkgrey', color: 'darkgrey'}}
            onClick={() => cancelEdit()} 
            disableElevation 
          >
             DESHACER 
          </Button> : null
         } 
         &nbsp;&nbsp;&nbsp;&nbsp; 
          <Button 
              variant="contained" 
              disabled={!organization?.leaderId || disbledSubmit}
              onClick={() => createRelation()} 
              disableElevation 
              color="primary"

          >
             {organization?.leaderId && organization?.subalterns?.length ? 'ACTUALIZAR' : 'GUARDAR'} 
          </Button>

        </Grid>           
      </Grid>
      
    </div>
  );
      }else {
        return null;
      }
}
