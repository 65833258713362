import React from 'react'
import { Grid, FormControl, TextField } from '@mui/material';
import {styles} from "./styles";

const Index = (props) => {

    const classes = styles();
    


  return (
    <form>
    <Grid item container lg={12} md={12} justifyContent="center">
    <FormControl fullWidth>
          <>
          <TextField
            name='answer'
            className={classes.dropdown}
            multiline
            disabled
            placeholder="Respuesta "
            rows={4}
            InputLabelProps={{
              shrink: true,
              notched: true,
            }}
            rowsMax={4}
            label="Respuesta"
          
        />
        <div className={classes.accountantPreview}>
         0/1000</div>
      </>
    </FormControl>
  </Grid>
    </form>
  )
}

export default Index