import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
    dropdown: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
        '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
          background: '#dfe7fd',
        },
    },
    circulo :{
        width: 80,
        height: 80,
        borderColor:' #8e8e8e',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial'
      },
      circulo2 :{
        width: 60,
        height: 60,
        borderColor:' #8e8e8e',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial',
        margin: 7
      },
      circuloMed :{
        width: 30,
        height: 30,
        borderColor:' #8e8e8e',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial'
      },
      circuloMed2 :{
        width: 10,
        height: 10,
        borderColor:' #8e8e8e',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial',
        margin: 7
      },
      circuloStand :{
        width: 60,
        height: 60,
        borderColor:' #8e8e8e',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial'
      },
      circuloStand2 :{
        width: 40,
        height: 40,
        borderColor:' #8e8e8e',
        borderStyle: 'solid',
        borderRadius: '50%',
        position: 'initial',
        margin: 7
      },
})