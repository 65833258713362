import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../../../context/store';
import Header from "./header";
import { getByIdAplicationEvaluation } from "../../../../actions/ManagementAplicationEvaluations-actions"
import Evaluation from "./evaluation"
import ModalConfirm from "../../../../utils/form/modalConfirmBase";
import { PATH_APP } from '../../../../constants';
import { showMessageWithOutAction } from '../../../../utils/constants';

const Index = (props) => {
    const {aplicationId} = props?.match?.params
    const idEvaluation = aplicationId
    const [dataDetail, setDataDetail] = useState({})
    const [, dispatch] = useStateValue();
    const [message, setMessage] = useState({
      description : '',
      textButtonSubmit : '',
      handleClick : () => {} ,
      handleClickOut : () => {},
      oneButtons : false,
      title: '',
      list: [],
      type : '',
      open : false
    })

  
    useEffect(()=> {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Resultado de la evaluación",
        index: 13,
        module: 'EVALUATION_EVALUATED',
        subPath: `${PATH_APP}/evaluation-performance-evaluated`,
      }); 
          getEvaluation()
    },[])

    const getEvaluation = async() => {
      await getByIdAplicationEvaluation(aplicationId, dispatch)
      .then((res) => {
        setDataDetail(res?.data)
      })
      .catch((err) => {
         showMessageWithOutAction(dispatch, `${err?.data?.message ?? 'Ocurrió un error al obtener los resultados'}`, `${PATH_APP}/evaluation-performance-evaluated`,)
      })
      

  }

  

  return (
    <React.Fragment>
        {dataDetail &&
              <>
                <Header id={idEvaluation} dataDetail={dataDetail} message={message} setMessage={setMessage}/>
                <Evaluation dataDetail={dataDetail}/>
              </>
        }
        <ModalConfirm 
          description = { message.description }
          textButtonSubmit = { message.textButtonSubmit }
          handleClick = { message.handleClick }
          handleClickOut = { message.handleClickOut }
          oneButtons = { message.oneButtons }
          title = { message.title }
          list = {message.list}
          type = { message.type }
          open = { message.open }
        />
      
    </React.Fragment>
  )
}

export default Index