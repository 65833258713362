import React,{useState, useEffect} from 'react';
import { Checkbox, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { styles } from "./styles";
import QuestionsList from "../questionsList";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useStateValue } from '../../../../../../context/store';
import _ from "lodash";

const Index = (props) => {
    const classes = styles();
    const [{relations}, dispatch] = useStateValue()
    const [checkeds, setCheckeds] = useState([])
    const [display, setDisplay] = useState([])
    const [showText, setShowText] = useState([])

    const showQuestion = (data, id) => {
    const result = showText.find((x)=> x?.id === id)
    if(result?.show) {  
        return data 
    } else {
        return `${data.substring(0, 150)}` 
    }
    }
      
    const allShow = (id) => {
        const result = showText.find((x)=> x?.id === id)
        if(result?.show) {
        setShowText([{show: false, id: id}])
        }else {
        setShowText([{show: true, id: id}])
        }
    }

    const showOptions = (x) => {
        const findUuid = display?.find((f)=> f === x)
        const differentUuid = display?.filter((f)=> f !== x)

        if(findUuid) {
            setDisplay(differentUuid)
        } else {
            let currentData = [...display]
            currentData.push(x)

            setDisplay(currentData)
        }
    }
    
    const validateShowText = (id) => {
        const result = showText.find((x)=> x?.id === id)
        return result?.show ? true : false
    }

    const validateChecked = (data) => {
        const result =  checkeds?.find((x) =>x?.uuid === data?.uuid )
       return result ? true : false
    }


    const handleChangeChecked = (name) => {
        const copy= []
        const findBehavior = props?.data?.find((x) => x.uuid === name)
        const currenSectionSelected = relations?.data?.find((x)=> x?.uuid === props?.idCompetence)
        copy.push(
            {
              uuid: findBehavior.uuid, 
              value: findBehavior.value, 
              code: findBehavior.code,  
            }
          )
           dispatch({
            type: "SET_RELATIONS_EVALUATION",
            data: relations?.data,
            dataSections: relations?.dataSections,
            sectionSelected: currenSectionSelected? currenSectionSelected?.section : '',
            competencieSelected: props?.idCompetence,
            behaviorSelected: name
        })
     
          setCheckeds(copy);
            props?.openQuestions()
    };

    const deleteBehavior = (b) => {

        let differentCompetences = relations?.data?.filter((x)=> x?.uuid !== props?.idCompetence)
        let findCompetence = relations?.data?.find((x)=> x?.uuid === props?.idCompetence)
        let differentBehavior = _.filter(findCompetence?.behaviors, (o)=> o?.uuid !== b)
        
        const updateCompetence = {...findCompetence, behaviors: _.orderBy(differentBehavior, ['position'], ['asc']) }
         differentCompetences.push(updateCompetence)

        const orderCompetencies = _.orderBy(differentCompetences, ['position'], ['asc']) 

        dispatch({
            type: 'SET_RELATIONS_EVALUATION',
            data: orderCompetencies,
            dataSections: relations?.dataSections,
            sectionSelected: relations?.sectionSelected,
            competencieSelected: relations?.competencieSelected,
            behaviorSelected: b === relations?.behaviorSelected ?'' : relations?.behaviorSelected
        })
    }   

    useEffect(()=> { 
        if(props?.idCompetence === relations?.competencieSelected){
            const filterCompetencie = relations?.data?.find((x)=> x?.uuid === relations?.competencieSelected)
            const findBehavior = filterCompetencie?.behaviors?.find((x)=> x?.uuid === relations?.behaviorSelected)
            setCheckeds([findBehavior])
        }else {
            setCheckeds([])
        }
    },[relations?.competencieSelected])
    
    
    useEffect(()=> {
        if(!props?.messageQuestions?.open){
            setCheckeds([])
        }
    },[props?.messageQuestions])

  return (
        <React.Fragment>
            { props?.data?.length ? props?.data?.map((x, index)=> (

                <Grid item container lg={12} className={classes.behaviors}>
                    <Grid item container lg={5} className={!x?.questions?.length && classes.containerBehavior}>
                        <Grid item lg={12} style={{paddingLeft: 8}}>
                            <Typography component={'span'}  className={classes.heading} >
                                {x?.name?.length > 150 ? showQuestion(x?.name, index) : x?.name}{x?.name?.length > 150 && 
                                <Typography variant="caption"
                                    className={classes.expandText}
                                    onClick={()=> allShow(index)}>{validateShowText(index) ? "...ver menos" : "...ver más" }</Typography>}
                            </Typography >
                        </Grid>
                        {(props?.modelDetail || props?.isCopy) ? null :
                            <Grid item lg={12} style={{display: 'flex', justifyContent: 'center'}}> 
                                <Tooltip title="Añadir preguntas" style={{padding: 0}} arrow>
                                    <AddCircleIcon 
                                        color='success'
                                        onClick={() => handleChangeChecked(x?.uuid)}
                                    />
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                    <Grid item container lg={7}  style={{height: '100%'}} >
                        <QuestionsList 
                            data={x?.questions} 
                            behaviorId={x?.uuid} 
                            competenceId={props?.idCompetence}
                            modelDetail={props?.modelDetail}
                            typesEvaluators={props?.typesEvaluators}
                            isCopy={props?.isCopy}
                        />
                    </Grid>
                    
                </Grid>
               

            )) : <Grid item container lg={12} className={classes.behaviors}></Grid>}
        </React.Fragment>
  )
}

export default Index;