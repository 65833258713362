import React from 'react'
import { Grid } from '@mui/material'
import NotFoundImg from "../../images/Search.png";

const Index = ({notFound}) => {

  if(!notFound) return null;
  else return (
    <Grid item lg={12} style={{display: 'grid', placeItems: 'center', marginTop: '3%'}}>
        <img src={NotFoundImg} alt="imagen_Login" width="25%" height="70%"/>
    </Grid>
  )
}

export default Index