import React, { useEffect, useState } from 'react'
import { 
  FormControl, 
  Grid, 
  Select, 
  TextField, 
  InputLabel, 
  MenuItem,  
  FormHelperText, 
  Typography,
  Button,
} from '@mui/material'
import { useStateValue } from "../../context/store";
import {styles} from '../styles';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SelectAllOutlinedIcon from '@mui/icons-material/SelectAllOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import Ranking from './typeQuestions/ranking/index';
import Multiple from './typeQuestions/multipleChoise/index';
import Unique from './typeQuestions/uniqueChoice/index';
import Text from './typeQuestions/text/index';
import Linear from './typeQuestions/linearScale/index';
import Snackbar from '../../utils/snackbars/SnackBars';
import { PATH_APP, REQUIRED_MESSAGE } from '../../constants';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalConfirm from "../../utils/form/modalConfirmBase";
import { getTagsCode, getALLTags} from '../../actions/label-actions';
import ModalCreateTag  from '../label/modalCreate';
import * as yup from "yup";



const Index = (props) => {

  const classes = styles();
  const [{modalClose }, dispatch] = useStateValue();
  const [labels, setLabels] = useState([]);
  const [codeInitial, setCodeInitial] = useState(0);
  const [openPreview, setOpenPreview] = useState(false)
  const [createTag, setCreateTag] = useState(false)
  const [messageSnackbar, ] = useState({
    message: '',
    open: false,
    severity: '',
    handleClose: () => {}
  })
  const [valuesFiled, setValuesField] = useState({
    label: '',
    answerAlternativeCode: '' 
  })
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    textButtonCancel: '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
})

const itemLocal =  JSON.parse(localStorage.getItem('session'));
const permiss =  itemLocal?.permissions;


  useEffect(() => {
    if(!props?.editQuestion && ! props?.showDetail && !props?.isEdit && !props?.oneSubmit){
      window.scrollTo(0,0)
    }
    getAllLabels()
    setValue('answerAlternativeCode', '')
    LabelsLength()
    if(props?.dataQuestion ) {

      setValue('question', props?.dataQuestion?.question)
      setValue('label', props?.dataQuestion?.tag?.uuid)
      setValue('answerAlternativeCode', props?.dataQuestion?.answerAlternativeCode)
      setValuesField({...valuesFiled, 
        answerAlternativeCode: props?.dataQuestion?.answerAlternativeCode, 
        label: props?.dataQuestion?.tag?.uuid})

    } 
    else if(  
     props?.isQuestionary || 
      props?.isAssess || 
      props?.isAnswer || 
      props?.showDetail || 
      props?.isEdit 
     ){
      return null
    }else if( props?.notDispatch){
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Crear cuestionario",
        index: 4,
        isSubmit: true,
        subPath: `${PATH_APP}/questionary`,
        textIsSubmit: "GUARDAR",
        disabledSubmit: true,
        idForm: 'Newary',
        module: "MANAGE_QUESTIONNAIRE"
    
      });
    } else {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Crear pregunta",
        index: 3,
        module: 'SET_FILTER_QUESTION',
        subPath: `${PATH_APP}/questions`,
        isSubmit: true,
        idForm: 'formInitial',
        disabledSubmit: valuesForm,
      });
    }
    if(!valuesFiled?.answerAlternativeCode && watch('question')){
      window.scrollTo(0,0)
     
    }
  }, [])

  const disabledSubmitQuestion = () => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Crear pregunta",
      index: 3,
      module: 'SET_FILTER_QUESTION',
      subPath: `${PATH_APP}/questions`,
      isSubmit: true,
      idForm: 'formInitial',
      disabledSubmit: true,
    });
  }

  

  useEffect(() => {
    getAllLabels()
    LabelsLength()
  }, [!modalClose?.openCreateTag])

  useEffect(() => {
  if(props.mapQuestionQuestionary){
      setValue('question', props?.mapQuestionQuestionary?.question)
      setValue('label', props?.mapQuestionQuestionary?.tag?.uuid)
      setValue('answerAlternativeCode', props?.mapQuestionQuestionary?.answerAlternativeCode)
      setValuesField({...valuesFiled, 
        answerAlternativeCode: props?.mapQuestionQuestionary?.answerAlternativeCode, 
        label: props?.mapQuestionQuestionary?.tag?.uuid})
  }
  }, [props.mapQuestionQuestionary])
  
  useEffect(()=> {
    if(props?.mapQuestionAssess){
      setValue('question', props?.mapQuestionAssess?.question)
      setValue('label', props?.mapQuestionAssess?.tag?.uuid)
      setValue('answerAlternativeCode', props?.mapQuestionAssess?.answerAlternativeCode)
      setValuesField({...valuesFiled, 
        answerAlternativeCode: props?.mapQuestionAssess?.answerAlternativeCode, 
        label: props?.mapQuestionAssess?.tag?.uuid})
    }
  },[props?.isAssess || props?.isAnswer])
  const schema = yup.object().shape({
    label: valuesFiled.label ? null : yup.string().required(REQUIRED_MESSAGE).typeError(REQUIRED_MESSAGE),
    question: yup.string().required(REQUIRED_MESSAGE).min(6, 'Ingrese mínimo 6 caracteres').max(5000, 'Ingrese máximo 5000 caracteres'),
    answerAlternativeCode: valuesFiled.answerAlternativeCode ? null :yup.string().required(REQUIRED_MESSAGE),
  });

  const refrehsTags = () => setCreateTag(!createTag)
  

  const LabelsLength = async() => {
    const result = await getTagsCode()
      setCodeInitial(result?.data?.code ? result?.data?.code :0 )
  }

  const closePreview = () => setOpenPreview(false)

  const { register, control, trigger, formState:{errors}, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const getAllLabels = async() => {
    const result = await getALLTags({status: 'ACTIVE'}, {orderBy: 'name'});
    setLabels(result?.data?.content ?result?.data?.content  : [] )
  }

  const valueOption = valuesFiled.answerAlternativeCode;
  const errorsquestion = errors?.question ? true :  false
  const valuesForm = watch('answerAlternativeCode') ? false : true

  const getValuesQuestion = async() => await trigger(['question','label'])
  const clearFields = () => {
    setValue('question', '')
    setValue('answerAlternativeCode', '')
    setValue('label', '')
    setValuesField({...valuesFiled, label: null, answerAlternativeCode: undefined})
    window.scrollTo(0,0)
    
  }

  const handleOnchange = (e) => {
    const {name, value} = e.target

    setValuesField({...valuesFiled, [name]:value})
  }

  useEffect(() => {
   if(!valuesFiled?.answerAlternativeCode && !props?.editQuestion){
    disabledSubmitQuestion()
   }
  }, [valuesFiled?.answerAlternativeCode ])
  
  

  const openModalTag = () => dispatch({
    type: "SET_MODAL",
    openCreateTag: true,
    openEdit: true,
    code: codeInitial
  });

 

const fieldLabel = () => {
  if( valuesFiled.answerAlternativeCode){
    return <Grid container item spacing={2} lg={12} md={12} style={{marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Grid item lg={6} md={6}>
      <FormControl fullWidth size="small">
          <InputLabel  error={valuesFiled.label ? null : !!errors.hasOwnProperty("label") && errors["label"].message}>Seleccione una etiqueta</InputLabel>
          <Controller 
          control={control}
          name='label'
            render={({field, fieldState})=> (
              <Select
              {...register('label')}
              name='label'
              value={valuesFiled?.label}
              onChange={(e) => field.onChange(handleOnchange(e))}
              label="Selecciona una etiqueta"
              className={classes.dropdown}
              error={valuesFiled.label ? null : !!errors.hasOwnProperty("label") && errors["label"].message}
            >
              {!labels?[]:labels?.map((x)=> (
                  <MenuItem 
                    value={x?.uuid} 
                    key={x?.uuid}> 
                    {x?.name}
                  </MenuItem>
              ))}
            </Select>
            )}
          /> 
          <FormHelperText style={{color:'red'}}>
          {valuesFiled.label ? null : errors.hasOwnProperty("label") &&
            errors["label"].message}
        </FormHelperText> 
        </FormControl>
      </Grid>
      
      <Grid item lg={2} md={3}>
        {props?.dataQuestion ? null :
          <Button 
            color="primary" 
            variant="contained"
            onClick={() => openModalTag()}
            style={{width: '-webkit-fill-available'}}
            >
              CREAR ETIQUETA
            </Button>
    }
      </Grid>
    </Grid>
   
  } else {
    return null
  }
  
}


const validateProp = (x) => {
if( props.isQuestionary || props?.showDetail || props?.isEdit) {
  return <Typography style={{wordBreak: 'break-all'}}>{watch('question')}</Typography>
} else if (props?.isAssess){ 
  return null
}else {
  return <>
    <FormControl variant='outlined' fullWidth size="small">
      <Controller 
      control={control}
      render={({field, fieldState})=> (
        <TextField
        {...register('question')}
        inputRef={field.ref}
        className={classes.dropdownQuestion}
        fullWidth
        multiline
        maxRows={65}
        disabled={props?.disabledCreate || props.isQuestionary || props?.showDetail || props?.isEdit }
        InputLabelProps={{ shrink: watch('question') ? true : false }}
        notched={ watch('question') ? 'true' : 'false'}
        value={props?.isAssess ? null : field.value}
        size="small"
        label="Pregunta"
        error={!!errors.hasOwnProperty("question")}
      />
      )}
      />
    </FormControl>
    <FormHelperText style={{color:'#d90707', marginLeft: 14}}>
      {errors.hasOwnProperty("question") &&
        errors["question"].message}
    </FormHelperText> 
</>
}
}
const visualizar = (watch('question') && !errors['question']) && valuesFiled?.answerAlternativeCode && !props?.isQuestionary && !props?.showDetail && !props?.isEdit 

  const formInitialOptions = () => {
    if(props?.isAnswer || props?.isAssess){
      return <>
              { changeOption()}
            </>
    }else {
      return (
      
        <Grid 
          item 
          container  
          direction="row"  
          spacing={1} 
          style={{alignItems: 'flex-start', 
          display: 'flex', 
          marginTop: '1%',
          visibility: props?.isAssess || props?.isAnswer ? 'hidden' : null,
        
          }}
        >
              <Grid item xs={12} 
                  sm={ props.isQuestionary || props?.showDetail || props?.isEdit ? 12 : 6} 
                  md={ props.isQuestionary || props?.showDetail || props?.isEdit ? 12 : 6} 
                  lg={ props.isQuestionary || props?.showDetail || props?.isEdit ? 12 : 6} 
              >
                  
                 { validateProp(props.isQuestionary, props?.showDetail, props?.isEdit) }
               </Grid>
               {props.isQuestionary || props?.showDetail || props?.isEdit ? null :
               <Grid item xs={12} sm={3.5} md={4} lg={4} style={{display: 'flex'}}>
                 <FormControl fullWidth size="small">
                   <InputLabel  error={errors.hasOwnProperty("answerAlternativeCode") && !valuesFiled.answerAlternativeCode}>
                     Seleccione el tipo de respuesta  
                   </InputLabel>
                   <Controller 
                       control={control}
                       name='answerAlternativeCode'
                       render={({field, fieldState})=> (
                       <Select
                       {...register('answerAlternativeCode')}
                       name='answerAlternativeCode'
                       value={valuesFiled.answerAlternativeCode}
                       onChange={(e)=> field.onChange(handleOnchange(e))}
                       defaultValue=''
                       displayEmpty
                       disabled={props?.dataQuestion || props?.disabledCreate || props?.showDetail || props?.isEdit }
                       style={{ background:  valuesFiled.answerAlternativeCode ? '#E1E1E1': '#ffffff',  borderRadius: 8}}
                       label="Seleccione el tipo de respuesta  "
                       error={errors.hasOwnProperty("answerAlternativeCode") && !valuesFiled.answerAlternativeCode}
                     >
                        <MenuItem value='TEX' key={1}><div className={classes.menuItemAnswer}><TextSnippetIcon color="disabled"/><Typography className={classes.marginLeft}></Typography> Pregunta tipo texto</div></MenuItem>
                        {permiss?.includes('QUESTIONARY') && <MenuItem value='ELU' key={4}><div className={classes.menuItemAnswer}><SelectAllOutlinedIcon color="disabled"/><Typography className={classes.marginLeft}>Elección única</Typography></div></MenuItem>}
                        {permiss?.includes('QUESTIONARY') && <MenuItem value='MUL' key={5}><div className={classes.menuItemAnswer}><RadioButtonCheckedOutlinedIcon color="disabled"/><Typography className={classes.marginLeft}>Elección múltiple</Typography></div></MenuItem>}
                        <MenuItem value='ESL' key={6}><div className={classes.menuItemAnswer}><KeyboardArrowDownOutlinedIcon color="disabled"/><Typography className={classes.marginLeft}>Escala lineal</Typography></div></MenuItem>
                        {permiss?.includes('QUESTIONARY') && <MenuItem value='RAN' key={7}><div className={classes.menuItemAnswer}><GridOnOutlinedIcon color="disabled"/><Typography className={classes.marginLeft}>Ranking</Typography ></div></MenuItem>}
 
 
                     </Select>
                     )}
                   />
                   <FormHelperText style={{color:'#d90707'}}>
                   {(errors.hasOwnProperty("answerAlternativeCode") && !valuesFiled.answerAlternativeCode) &&
                     errors["answerAlternativeCode"].message}
                 </FormHelperText> 
                 </FormControl>
               </Grid>
               }
               {!props?.isQuestionary && !props?.showDetail && !props?.isEdit &&  !props?.isAssess & !props?.isAnswer ?
                 <Grid item lg={2} md={2} sm={2}>
                   <Button 
                   style={{background: !visualizar ? '#e0e0e0': '#2F71CB', color: !visualizar ? 'rgb(128 128 128 / 28%)': 'white', width: '-webkit-fill-available' }}
                   color="primary" 
                   variant="contained" 
                   disabled={!visualizar}
                   onClick={() => setOpenPreview(!openPreview)}
                   >
                     Previsualizar
                   </Button>
                 </Grid>
                 : null
               }
        
         {changeOption()}
         {props?.disabledCreate || props.mapQuestionQuestionary ? null : 
         <>
         {props?.isAssess || props?.isAnswer? null : fieldLabel()}
         {modalClose?.openCreateTag ?  <ModalCreateTag 
           codeInitial={(codeInitial+1)} 
           setMessage={setMessage}
           message={message}
           createInQuestion={true}
           refrehsTags={refrehsTags}
         />
         : null}
         </>
         }
        </Grid>
         
      
     )
    }
   
}


const changeOption = (f) => {

  switch (valueOption) {
    case 'TEX': return  <Text 
                            dataInitial={{question:watch('question'), answerAlternativeCode: watch('answerAlternativeCode'), tagUuid: valuesFiled?.label}} 
                            message={message}
                            preview={openPreview}
                            index={props?.index}
                            isEdit={props?.isEdit}
                            setMessage={setMessage}
                            clearFields={clearFields}
                            isAssess={props?.isAssess}
                            isAnswer={props?.isAnswer}
                            isDetail={props?.isDetail}
                            closePreview={closePreview}
                            oneSubmit={props?.oneSubmit}
                            errorsHeaderQuestion={errors}
                            showDetail={props?.showDetail}
                            errorQuestion={errorsquestion} 
                            valuesForm={getValuesQuestion}
                            notDispatch={props.notDispatch}
                            setOpenModal={props.setOpenModal}
                            dataQuestion={props?.dataQuestion}
                            isQuestionary={props.isQuestionary}
                            getDataCreate={props.getDataCreate}
                            isEditQuestion={props?.editQuestion}
                            disabledQuestionary={props?.disabledCreate}
                            mapQuestionAssess={props?.mapQuestionAssess}
                            mapQuestionQuestionary={props.mapQuestionQuestionary}
                            />

    case 'ELU':  return <Unique 
                          dataInitial={{question:watch('question'), answerAlternativeCode: watch('answerAlternativeCode'), tagUuid: valuesFiled?.label}} 
                          preview={openPreview}
                          index={props?.index}
                          isEdit={props?.isEdit}
                          closePreview={closePreview}
                          message={message}
                          setMessage={setMessage}
                          clearFields={clearFields}
                          isAssess={props?.isAssess}
                          isAnswer={props?.isAnswer}
                          isDetail={props?.isDetail}
                          oneSubmit={props.oneSubmit}
                          errorsHeaderQuestion={errors}
                          showDetail={props?.showDetail}
                          errorQuestion={errorsquestion} 
                          valuesForm={getValuesQuestion}
                          notDispatch={props.notDispatch}
                          setOpenModal={props.setOpenModal}
                          dataQuestion={props?.dataQuestion}
                          isQuestionary={props.isQuestionary}
                          getDataCreate={props.getDataCreate}
                          disabledQuestionary={props?.disabledCreate}
                          mapQuestionAssess={props?.mapQuestionAssess}
                          dispatchQuestionary={props?.dispatchQuestionary}
                          mapQuestionQuestionary={props.mapQuestionQuestionary}
                        />
    break;
    case 'MUL':  return <Multiple 
                          dataInitial={{question:watch('question'), answerAlternativeCode: watch('answerAlternativeCode'), tagUuid: valuesFiled?.label }} 
                          message={message}
                          preview={openPreview}
                          index={props?.index}
                          isEdit={props?.isEdit}
                          setMessage={setMessage}
                          clearFields={clearFields}
                          isAssess={props?.isAssess}
                          isAnswer={props?.isAnswer}
                          isDetail={props?.isDetail}
                          closePreview={closePreview}
                          oneSubmit={props.oneSubmit}
                          errorsHeaderQuestion={errors}
                          showDetail={props?.showDetail}
                          errorQuestion={errorsquestion} 
                          valuesForm={getValuesQuestion}
                          notDispatch={props.notDispatch}
                          setOpenModal={props.setOpenModal}
                          dataQuestion={props?.dataQuestion}
                          isQuestionary={props.isQuestionary}
                          getDataCreate={props.getDataCreate}
                          disabledQuestionary={props?.disabledCreate}
                          mapQuestionAssess={props?.mapQuestionAssess}
                          dispatchQuestionary={props?.dispatchQuestionary}
                          mapQuestionQuestionary={props.mapQuestionQuestionary}
                        />
    break;
    case 'ESL':  return <Linear 
                          dataInitial={{question:watch('question'), answerAlternativeCode: watch('answerAlternativeCode'), tagUuid: valuesFiled?.label}} 
                          message={message}
                          preview={openPreview}
                          index={props?.index}
                          isEdit={props?.isEdit}
                          setMessage={setMessage}
                          clearFields={clearFields}
                          isAssess={props?.isAssess}
                          isAnswer={props?.isAnswer}
                          isDetail={props?.isDetail}
                          closePreview={closePreview}
                          oneSubmit={props.oneSubmit}
                          errorsHeaderQuestion={errors}
                          showDetail={props?.showDetail}
                          errorQuestion={errorsquestion} 
                          valuesForm={getValuesQuestion}
                          notDispatch={props.notDispatch}
                          setOpenModal={props.setOpenModal}
                          dataQuestion={props?.dataQuestion}
                          isQuestionary={props.isQuestionary}
                          getDataCreate={props.getDataCreate}
                          isEditQuestion={props?.editQuestion}
                          disabledQuestionary={props?.disabledCreate}
                          mapQuestionAssess={props?.mapQuestionAssess}
                          dispatchQuestionary={props?.dispatchQuestionary}
                          mapQuestionQuestionary={props.mapQuestionQuestionary}
                        />
    case 'RAN':  return <Ranking 
                          dataInitial={{question:watch('question'), answerAlternativeCode: watch('answerAlternativeCode'), tagUuid: valuesFiled?.label}} 
                          message={message}
                          preview={openPreview}
                          index={props?.index}
                          isEdit={props?.isEdit}
                          closePreview={closePreview}
                          isAssess={props?.isAssess}
                          isAnswer={props?.isAnswer}
                          isDetail={props?.isDetail}
                          setMessage={setMessage}
                          clearFields={clearFields}
                          oneSubmit={props.oneSubmit}
                          errorsHeaderQuestion={errors}
                          showDetail={props?.showDetail}
                          errorQuestion={errorsquestion} 
                          valuesForm={getValuesQuestion}
                          notDispatch={props.notDispatch}
                          setOpenModal={props.setOpenModal}
                          dataQuestion={props?.dataQuestion}
                          isQuestionary={props.isQuestionary}
                          getDataCreate={props.getDataCreate}
                          disabledQuestionary={props?.disabledCreate}
                          mapQuestionAssess={props?.mapQuestionAssess}
                          dispatchQuestionary={props?.dispatchQuestionary}
                          mapQuestionQuestionary={props.mapQuestionQuestionary}
                        />
    break;
    default:
      break;
  }
};

return (
  <React.Fragment>  
    {formInitialOptions()}
    < Snackbar 
        open={messageSnackbar.open}
        message={messageSnackbar.message} 
        severity={messageSnackbar.severity} 
        handleClose={messageSnackbar.handleClose}
      />
       <ModalConfirm 
          description = { message.description }
          textButtonSubmit = { message.textButtonSubmit }
          textButtonCancel = { message.textButtonCancel }
          handleClick = { message.handleClick }
          handleClickOut = { message.handleClickOut }
          oneButtons = { message.oneButtons }
          title = { message.title }
          type = { message.type }
          open = { message.open }
        />
  </React.Fragment>
)

}

export default Index
