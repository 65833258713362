export const initialState = {
    operation: null,
  };
  
  const typeOperationReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_TYPE_OPERATION":
        return {
          ...state,
          operation: action.operation,
        };
      default:
        return state;
    }
  };
  
  export default typeOperationReducer;
  