import { makeStyles, withStyles } from '@mui/styles';

export const styles = makeStyles({
    expandText :{ 
        textDecoration: 'underline',
        cursor: 'pointer',
        marginLeft: 5,
        color: '#3300ff'
       },
    modalBank: {
        [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
          maxWidth: 900,
          minWidth: 900,
          borderRadius: 16
      },
      [`& .jss59 .css-uhb5lp`] : {
        maxWidth: 900,
        minWidth: 900,
        borderRadius: 16
      },
      [`& .css-uhb5lp`]: {
        borderRadius: 12,
        maxWidth: 900,
        minWidth: 900,
      }
      },
    titleModalBank: {
        fontWeight: 400,
        textAlign: 'center',
        fontSize: 16,
        fontFamily: 'Roboto',
        letterSpacing: '0.25px'
      },
      wrapperSelect: {
        border: '1px solid gray',
        cursor: 'pointer',
        borderRadius: 10,
        marginBottom: 8,
        display: 'flex', 
        background: 'rgba(245, 178, 117, 0.55)',
        wordBreak: 'break-word', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        marginLeft: '2%'
      },
      objectsSelect: {
        display: 'flex',
        position: 'relative', 
        alignItems: 'flex-start', 
      },
    behaviors: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid gray',
      background: 'rgba(245, 178, 117, 0.55)',
      marginBottom: 5,
    },
    containerBehavior: {
      borderRight:'1px solid gray',
    },
    colorBehaviors: {
      background: 'rgba(245, 178, 117, 0.55)',
    },
    questions: {
      border: '0.5px solid gray',
      borderTop: 0,
    },
    checkbox :{
      border: '1px solid gray',
      borderTop: 0,
    
    }
})


