import HttpClient from '../services/HttpClient';
import { PERFORMANCE, APLICATION, EVALUATION_EVALUATED, PERFORMANCEV2, STATES, ORGANIZATION_CHART, EVALUATOR_TYPE } from '../constants'



export const getTypesEvaluators = (dispatch) => {
    // dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise ((resolve,reject) => {
        HttpClient.get(`${ORGANIZATION_CHART}${EVALUATOR_TYPE}`).then(response => {
            resolve(response);
            // dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            // dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            resolve(error.response);
        })
    })
}

export const createPerformance = (body, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        HttpClient.post(`${PERFORMANCE}`, body).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            resolve(error.response);
        });
    });
} 

export const getFilterEvaluationPerformance = (dataFilter, order, dispatch) => {

    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}&orderBy=${order?.orderBy}`

        const keys = ['status', 'nameKeyword', 'createdAtStartDate', 'createdAtEndDate', 'evaluationYear'  ];

        for (let index = 0; index < keys?.length; index++) {
            if(dataFilter[keys[index]] ){
                if(keys[index] === 'nameKeyword' && dataFilter[keys[index]]){
                  filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
                }else {
                  filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
                }
            } else {
                continue
            }
        }

        HttpClient.get(`${PERFORMANCE}${filters}`).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getByIdEvaluation = (id, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise ((resolve,reject) => {
        HttpClient.get(`${PERFORMANCE}/${id}`).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            if(typeof error?.response === 'undefined'){
                console.log('A network error occurred. ')
                reject({error : 'technical_error', error_description: 'Ocurrió un problema comuniquese con el administrador.'})
            }
            if(error?.response?.status === 500){
                dispatch({
                  type: "SET_MODAL_ACTION",
                  open: true,
                  icon:'WARNING',
                  message: 'Occurió un problema con el servidor para mostrar la evaluación por favor vuelva a intentar.'
                })
              }  
            reject(error.response);
            resolve(error.response);
        })
    })
}

export const updateEvaluation = (id,body, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true })
    return new Promise((resolve, reject) => {
        HttpClient.patch(`${PERFORMANCE}/${id}`, body).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            resolve(error.response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        })
    })
}

export const createAplication = (id, body, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true })
    return new Promise((resolve, reject) => {
        HttpClient.post(`${PERFORMANCEV2}/${id}${APLICATION}`, body).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            resolve(error.response);
        });
    });
}


export const getFilterEvaluationPerformanceEvaluated = (dataFilter, order, dispatch) => {

    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true })
    return new Promise((resolve, reject) => {
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}&orderBy=${order?.orderBy}`

        const keys = ['status', 'nameKeyword', 'evaluationYear'  ];

        for (let index = 0; index < keys?.length; index++) {
            if(dataFilter[keys[index]] ){
                if(keys[index] === 'nameKeyword' && dataFilter[keys[index]]){
                  filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
                }else {
                  filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
                }
            } else {
                continue
            }
        }

        HttpClient.get(`${EVALUATION_EVALUATED}${filters}`).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false })
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false })
            reject(error.response);
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
          
        });
    });
}

export const getByIdEvaluationEvaluated = (id, dispatch) => {

    return new Promise ((resolve,reject) => {
        HttpClient.get(`${EVALUATION_EVALUATED}/${id}`).then(response => {
            resolve(response);

        }).catch(error => {

            if(typeof error.response === 'undefined'){
                console.log('A network error occurred. ')
                reject({error : 'technical_error', error_description: 'Ocurrió un problema comuniquese con el administrador.'})
            }
            reject(error.response);
            resolve(error.response);
        })
    })
}

export const answerEvaluationEvaluated = (id, body, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        HttpClient.post(`${EVALUATION_EVALUATED}/${id}`, body).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            resolve(error.response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        });
    });
}

export const getStatesEvaluator = () => {
    return new Promise ((resolve,reject) => {
        HttpClient.get(`${EVALUATION_EVALUATED}${STATES}`).then(response => {
            resolve(response)
        }).catch(error => {
            if(typeof error.response === 'undefined'){
                console.log('A network error occurred. ')
                reject({error : 'technical_error', error_description: 'Ocurrió un problema comuniquese con el administrador.'})
            }
            reject(error.response);
            resolve(error.response);
        })
    })
}