import React, {useEffect, useState} from 'react';
import * as XLSX from 'xlsx';
import {  writeFileXLSX } from 'xlsx';
import { useStateValue } from './../../../../context/store';
import moment from "moment"
import { PATH_APP } from '../../../../constants';

 
const Test = ({getHtml, action, setAction}) =>   {

    const [{dataToPage, detailEvaluation}, dispatch]= useStateValue();
    const [dataExcel, setDataExcel] = useState([]);

    useEffect(() => {
        if(action){
            
            setAction(false)
            dispatch({
                type: "SET_BREADCRUMB",
                breadCrumbText: "Autoevaluaciones",
                breadCrumbSubText: "Resultado General",
                index: 13,
                module: 'DETAIL_APLICATION_EVALUATION',
                disabledDowload: false,
                dowload: false,
                textDowload: 'Descargar archivo',
                subPath: `${PATH_APP}/management-aplication-evaluations`,
              });
            function ExportToExcel(fn, dl) {
                var elt = document.getElementById('table-to-xls');
                var wb = XLSX.utils.table_to_book(elt, { sheet: "resultados" });
                return dl ?
                    XLSX.write(wb, { bookType: 'xls', bookSST: true, type: 'base64' }) :
                    writeFileXLSX(wb, "ReporteGeneral.xlsx");
            }
            
            ExportToExcel()
        }
    }, [action])

    useEffect(()=> {

        const dataEx = []
        for (let index = 0; index < dataToPage?.data?.evaluationApplicationTypes?.length; index++) {

            if( dataToPage?.data?.evaluationApplicationTypes[index]?.type === 'LEADER_EVALUATION'){

                const newDataLeader = [];
                for (let ev = 0; ev < dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults?.length; ev++) {
                    
                    for (let evtr = 0; evtr < dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluatorResults?.length; evtr++) {
                        
                        newDataLeader.push({
                            evaluated:  { 
                                name: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluated?.name,
                                surname: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluated?.surname,
                                type: 'Cc',
                                identificationNumber: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluated?.identificationNumber,
                                position: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluated?.position?.name,
                                regional: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluated?.regional?.name
                            },
                            evaluator: [{
                                name: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluatorResults[evtr]?.evaluator?.name,
                                surname: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluatorResults[evtr]?.evaluator?.surname,
                                type: 'Cc',
                                identificationNumber: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluatorResults[evtr]?.evaluator?.identificationNumber,
                                position: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluatorResults[evtr]?.evaluator?.position?.name,
                                regional: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluatorResults[evtr]?.evaluator?.regional?.name,
                                typeEvaluation: dataToPage?.data?.evaluationApplicationTypes[index]?.type,
                                value: dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults[ev]?.evaluatorResults[evtr]?.totalResultPercentage
                            }]
                        })
                        
                    }
                    
                }
                dataEx.push(newDataLeader)
            }else {
                dataEx.push(dataToPage?.data?.evaluationApplicationTypes[index]?.evaluatedResults?.map((evtd)=> ({
                    evaluated:  { 
                        name: evtd?.evaluated?.name,
                        surname: evtd?.evaluated?.surname,
                        type: 'Cc',
                        identificationNumber: evtd?.evaluated?.identificationNumber,
                        position: evtd?.evaluated?.position?.name,
                        regional: evtd?.evaluated?.regional?.name
                    },
                    evaluator: evtd?.evaluatorResults?.map((tor)=> (
                        {
                            name: tor?.evaluator?.name,
                            surname: tor?.evaluator?.surname,
                            type: 'Cc',
                            identificationNumber: tor?.evaluator?.identificationNumber,
                            position: tor?.evaluator?.position?.name,
                            regional: tor?.evaluator?.regional?.name,
                            typeEvaluation: dataToPage?.data?.evaluationApplicationTypes[index]?.type,
                            value: tor?.totalResultPercentage
                        }
                    ))
                    
                 }))
                )
            }   
                

        }

        setDataExcel(dataEx)

    },[dataToPage])
    

        return (

            <div style={{display: 'none'}}>
               
                <table id="table-to-xls" style={{borderCollapse: 'collapse'}}>
                    <thead>
                        <tr >
                            <th colspan="15" style={{background: '#a3d9d4', borderTop: 'groove',borderLeft: 'groove', borderBottom: 'groove' }}>
                            </th>
                        </tr>
                        <tr  >
                            <th colspan="6" style={{height: 15, border: '0.1px'}}>{detailEvaluation?.data?.performanceEvaluation?.name}</th>
                            <th colspan="9" style={{height: 15, border: '0.1px'}}>Periodo de evaluación: {`${moment(dataToPage?.data?.evaluationApplication?.performanceEvaluation?.startDate).format("DD/MM/YYYY")} - ${moment(dataToPage?.data?.evaluationApplication?.performanceEvaluation?.endDate).format("DD/MM/YYYY")}` }</th>
                        </tr>
                        <tr  >
                            <th colspan="15" style={{height: 15, border: '0.1px'}}></th>
                        </tr>
                        <tr >
                            <th colspan="6" style={{textAlign: 'center', background: '#a3d9d4', border: '1px solid'}}>Evaluado</th>
                            <th colspan="6" style={{textAlign: 'center', background: '#a3d9d4', border: '1px solid'}}>Evaluador</th>
                            <th colspan="3" style={{textAlign: 'center', background: '#a3d9d4', border: '1px solid'}}>Total tipo evaluación</th>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid'}}>Nombre</td>
                            <td style={{border: '1px solid'}}>Apellido</td>
                            <td style={{border: '1px solid'}}>Tipo de identificación</td>
                            <td style={{border: '1px solid'}}>Identificación</td>
                            <td style={{border: '1px solid'}}>Cargo</td>
                            <td style={{border: '1px solid'}}>Regional</td>
                            <td style={{border: '1px solid'}}>Nombre</td>
                            <td style={{border: '1px solid'}}>Apellido</td>
                            <td style={{border: '1px solid'}}>Tipo de identificación</td>
                            <td style={{border: '1px solid'}}>Identificación</td>
                            <td style={{border: '1px solid'}}>Cargo</td>
                            <td style={{border: '1px solid'}}>Regional</td>
                            <td style={{border: '1px solid'}}>Auto</td>
                            <td style={{border: '1px solid'}}>Par</td>
                            <td style={{border: '1px solid'}}>Lider</td>
                        </tr>
                    </thead>
                    <tbody>
                        {dataExcel?.map((x)=> {
                            return <>
                                {x?.map((evtd)=> {
                                    return <tr>
                                        <td>{`${evtd?.evaluated?.name}`}</td>
                                        <td>{`${evtd?.evaluated?.surname}`}</td>
                                        <td>Cc</td>
                                        <td>{`${evtd?.evaluated?.identificationNumber}`}</td>
                                        <td>{`${evtd?.evaluated?.position}`}</td>
                                        <td style={{borderRight: '1px solid'}}>{`${evtd?.evaluated?.regional}`}</td>
                                        {evtd?.evaluator?.map((tor)=> {
                                            return <>
                                                <td>{`${tor?.name}`}</td>
                                                <td>{`${tor?.surname}`}</td>
                                                <td>Cc</td>
                                                <td>{`${tor?.identificationNumber}`}</td>
                                                <td>{`${tor?.position}`}</td>
                                                <td style={{borderRight: '1px solid'}}>{`${tor?.regional}`}</td>
                                                <td>{tor?.typeEvaluation === 'SELF_EVALUATION' ? tor?.value : ''}</td>
                                                <td>{tor?.typeEvaluation === 'PEER_EVALUATION' ? tor?.value : ''}</td>
                                                <td style={{borderRight: '1px solid'}}>{tor?.typeEvaluation === 'LEADER_EVALUATION' ? tor?.value : ''}</td>
                                            </>
                                              
                                    
                                        })}
                                    </tr>

                                })}
                            
                        
                           
                        </>
                        })}
                    </tbody>
                </table>
 
            </div>
        );
}
 
export default Test