import React, {useState} from 'react';
import {
    Grid,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel
} from "@mui/material";
import { useStateValue } from '../../../../../../context/store';
import { styles } from "./styles";
import _ from "lodash"

const Index = (props) => {
    
  const classes = styles();
  const [{valorationAplication}, dispatch] = useStateValue();
  const [press, setPress] = useState({})

    const handleOnclick = (x, value) => {
      setPress({press: x})

      const findCompetence = valorationAplication?.data?.competences?.find((x)=> x?.uuid === props?.competenceId)
      let differentCompetence = valorationAplication?.data?.competences?.filter((x)=> x?.uuid !== props?.competenceId) 
      if(findCompetence) {
        
          const findBehavior = findCompetence?.conducts?.find((x)=> x?.uuid === props?.behaviorId)
          const differentBehavior = findCompetence?.conducts?.filter((x)=> x?.uuid !== props?.behaviorId)
          const findQuestion = findBehavior?.questions?.find((x)=> x?.uuid === props?.uuid)
          let differentQuestions = findBehavior?.questions?.filter((x)=> x?.uuid !== props?.uuid)

          const updateQuestion = {...findQuestion, value: value, typeQuestion: 'ESL'}
          differentQuestions.push(updateQuestion)
          const updateBehavior = {...findBehavior, questions: _.orderBy(differentQuestions, ['position'],["asc"])}


          differentBehavior?.push(updateBehavior)
          const updateCompetence = {...findCompetence, conducts: _.orderBy(differentBehavior, ['position'],["asc"]) }
          differentCompetence.push(updateCompetence)
  
  
          dispatch({
              type: "SET_VALORATE_APLICATION",
              disabledValors: valorationAplication?.disabledValors,
              data: {competences : _.orderBy(differentCompetence, ['position'],["asc"])}
          })
    }
  }


  const checkbox = () => {
    
    if(props?.valuesRanking?.length){

      const lastPosition = props?.valuesRanking.slice(0)[0];
      const otherPositions = props?.valuesRanking?.slice(1,props?.valuesRanking?.length);
      lastPosition && otherPositions?.push(lastPosition) 

     return <Grid item container  lg={12} md={12} sm={12} xs={12} justifyContent="center">
        <FormControl >
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue="top"
          >
            {otherPositions?.map( (x, index) => (
              <FormControlLabel
                value={`description${index+1}`}
                control={<Radio  
                  className={classes.radioButton}
                  />}
                
                // style={{marginLeft: index === 3 ? 50 : ''}}
                className={classes.labelCheckbox}
                label={x?.key}
                disabled={props?.disabled}
                labelPlacement="top"
                onClick={() => {handleOnclick(`optionCheckbox${index}`, x?.uuid)}
                }
              />
            ))}
           
          </RadioGroup>
        </FormControl>      
      </Grid>
    }
  
  }

  if(props?.valuesRanking?.length){
    return  checkbox()
  }else {
    return null
  }
 
};

export default Index;

