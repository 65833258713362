import React,{useState, useEffect} from 'react'
import { 
    FormControl,
    Grid,
    TextField,  
    FormHelperText, 
    Typography 
    } from '@mui/material'
import { REQUIRED_MESSAGE } from '../../../../constants';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {styles} from "./styles";
import {getAllUsers} from "../../../../actions/AplicationQuestionary-action";

import * as yup from 'yup'
import moment  from "moment"

const Index = (props) => {
    const classes = styles();
  

    const [, setUsers] = useState([])
    const [, setEvaluateds] = useState([])
    const [, setChecked] = useState({})
    const [valuesForm, setValuesForm ] = useState({
        status: 'ACTIVE',
        startDate: '',
        endDate: ''
    })
    const [currentDay, ] = useState()
    const [, setValidate] = useState(false)
    const [, setNames] = useState([]);
    

    const schema = yup.object().shape({
        name: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .min(10, 'Ingrese mínimo 10 caracteres')
        .max(500, 'Ingrese máximo 500 caracteres'),
        user: yup
        .string()
        .required(REQUIRED_MESSAGE),
        referredTo:  yup
        .array()
        .typeError('Este campo es obligatorio')
        .min(1, 'Seleccione mínimo a un evaluador'),
        addressedTo:  yup
        .array()
        .typeError('Este campo es obligatorio')
        .min(1, 'Seleccione mínimo a un evaluador'),
        description: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .min(10, 'Ingrese mínimo 10 caracteres')
        .max(1000, 'Ingrese máximo 1000 caracteres'),
        startDate: yup
        .date()
        .nullable(true),
        endDate: yup
        .date()
        .required('Este campo es obligatorio')
        .nullable(true),
        startDateAplied: yup
        .date()
        .min(moment(new Date()).format('YYYY-MM-DD'),'Ingrese mínimo la fecha actual' )
        .required('Este campo es obligatorio')
        .typeError('Este campo es obligatorio'),
        endDateAplied: yup
        .date()
        .required('Este campo es obligatorio')
        .typeError('Este campo es obligatorio')
        .test("maxLenght", "Ingrese una superior o igual a la fecha desde", val =>  currentDay <= moment(val).format('YYYY-MM-DD')   ),
    })
    const {register, setValue, trigger, clearErrors, watch, formState:{errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange'
    })

    useEffect(()=> {
        getUsers()
    },[])
   
    useEffect(() => {
        // if(names?.length){
            window.scrollTo(0,0)
            setValue('name', props?.dataDetail?.performanceEvaluation?.name)
            setValue('user',`${props?.dataDetail?.performanceEvaluation?.creatorUser?.name} ${props?.dataDetail?.performanceEvaluation?.creatorUser?.surname}` )
            setValue('description', props?.dataDetail?.performanceEvaluation?.description)
            setValue('referredTo', props?.dataDetail?.performanceEvaluation?.creatorUser?.username)
            setValue('endDate',moment(props?.dataDetail?.endDate).format('YYYY-MM-DD'))
            setValue('startDate',moment(props?.dataDetail?.startDate).format('YYYY-MM-DD'))
            setValue('endDateAplied',moment(props?.dataDetail?.performanceEvaluation?.endDate).format('YYYY-MM-DD'))
            setValue('startDateAplied',moment(props?.dataDetail?.performanceEvaluation?.startDate).format('YYYY-MM-DD'))

            setValuesForm(
                {
                    ...valuesForm, 
                    startDate: moment(props?.dataDetail?.performanceEvaluation?.startDate).format('YYYY-MM-DD'),
                    endDate: moment(props?.dataDetail?.performanceEvaluation?.endDate).format('YYYY-MM-DD')
                }
                )
                setChecked({name: props?.dataDetail?.automatic === true ? 'Automatic' : 'Manual'})
            // setUsersDispatch(props?.dataDetail?.performanceEvaluation?.referredTo?.map((x)=>x))setEvaluatedsDispatch
            setUsersDispatch(props?.dataDetail?.performanceEvaluation?.creatorUser?.username)
            setEvaluatedsDispatch(props?.dataDetail?.referredToUsers?.map((x)=> x?.username))
        // }
           
    }, [])



    const getUsers = async() => {
        const result = await getAllUsers();
        setNames(result?.data )
    }
  
   
    const setUsersDispatch = (event) => {
        const name="referredTo"
        setUsers(
        typeof event === 'string' ? event.split(',') : event,
        );
        setValue(name,typeof event === 'string' ? event.split(',') : event, )
        setValidate(true)
        trigger('referredTo')
        
    };

    const setEvaluatedsDispatch = (event) => {

            const name="addressedTo"
            setEvaluateds(
            typeof event === 'string' ? event.split(',') : event,
            );
            setValue(name,typeof event === 'string' ? event.split(',') : event, )
            setValidate(true)
            trigger('addressedTo')
        
        };


    const handleChange = (e)=> {
        const {value, name} = e.target
        setValue(name, value)
        setValuesForm({...valuesForm, [name]:value})
        
    }


     const validateErros = () => {
        if(errors['startDateAplied'] ){
            return 20
        } else if(errors['endDateAplied'] && errors['endDateAplied'].message === 'Este campo es obligatorio' ){
            return 20
        }else if (errors['endDateAplied']){
            return 40
        }  else {
            return null
        }
     }

     useEffect(()=> {
        if(watch('endDateAplied') && errors['endDateAplied']){
            setValue('endDateAplied', '')
            clearErrors('endDateAplied')
        }
       
     },[watch('startDateAplied')])


  return (
        <Grid container spacing={1} maxWidth="inherit" alignItems="end" 
                style={{ background: props?.isEdit ? 'rgb(239 239 239)': null}}
            >
               
               <Grid item spacing={1} lg={3.6} style={{display:'flex ', flexDirection: 'column', marginBottom: errors && validateErros()}}>
                    <div >
                        <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Periodo de evaluación</Typography>
                        <div style={{display:'flex ', flexDirection: 'row' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('startDate')}
                                    size="small"
                                    type="date"
                                    label="Desde"
                                    disabled
                                    onChange={handleChange}
                                    // value={breadCrumb?.module === "APLICATION_CUESTIONARY" ? questionarySearch?.filter?.createdAtStartDate : null}
                                    className={classes.fieldDateTimeErrorSearch}
                                    minDate={new Date()}
                                    InputLabelProps={{
                                        shrink: true,
                                        notched: true
                                    }}
                                    error={!!errors.hasOwnProperty("startDate") && errors["startDate"].message }
                                />
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("startDate") &&
                                    errors["startDate"].message}
                                </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                            <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('endDate')}
                                    size="small"
                                    type="date"
                                    label="Hasta"
                                    disabled
                                    onChange={handleChange}
                                    className={classes.fieldDateTimeErrorSearch}
                                    minDate={new Date()}
                                    InputLabelProps={{
                                        shrink: true,
                                        notched: true
                                    }}
                                    error={!!errors.hasOwnProperty("endDate") && errors["endDate"].message }
                                />
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("endDate") &&
                                    errors["endDate"].message}
                                </FormHelperText>
                                </FormControl>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                    
                    <Grid item lg={3.4} md={6} fullWidth style={{marginBottom: errors && validateErros() }}>
                        <FormControl fullWidth variant='outlined' size='small'>
                            <TextField 
                                {...register('user')}
                                fullWidth
                                size='small'
                                disabled
                                label="Usuario creador"
                                placeholder='Usuario creador'
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                    notched: true
                                }}
                                margin='none'
                                maxRows={15}
                                className={classes.dropdownMultiple}
                                error={!!errors.hasOwnProperty("user") && errors["user"].message}
                            />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("user") &&
                            errors["user"].message}
                        </FormHelperText>
                        </FormControl>
                    </Grid> 
                    <Grid item spacing={1} lg={5} style={{display:'flex ', flexDirection: 'column' }}>
                    <div >
                        <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Periodo de aplicación</Typography>
                        <div style={{display:'flex ', flexDirection: 'row' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('startDateAplied')}
                                    size="small"
                                    type="date"
                                    label="Desde"
                                    disabled
                                    inputProps={{min: moment(new Date()).format('YYYY-MM-DD')}}
                                    className={classes.fieldDateTimeErrorSearch}
                                    InputLabelProps={{
                                        shrink: true,
                                        notched: true
                                    }}
                                    error={!!errors.hasOwnProperty("startDateAplied") && errors["startDateAplied"].message }
                                />
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("startDateAplied") &&
                                    errors["startDateAplied"].message}
                                </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                            <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('endDateAplied')}
                                    size="small"
                                    type="date"
                                    label="Hasta"
                                    disabled
                                    className={classes.fieldDateTimeErrorSearch}
                                    inputProps={{min: moment(new Date()).format('YYYY-MM-DD')}}
                                    minDate={new Date()}
                                    min={new Date()}
                                    InputLabelProps={{
                                        shrink: true,
                                        notched: true
                                    }}
                                    error={!!errors.hasOwnProperty("endDateAplied") && errors["endDateAplied"].message }
                                />
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("endDateAplied") &&
                                    errors["endDateAplied"].message}
                                </FormHelperText>
                                </FormControl>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                    <Grid item lg={12} md={12} fullWidth>
                        <FormControl fullWidth variant='outlined' size='small'>
                            <TextField 
                                {...register('name')}
                                fullWidth
                                size='small'
                                label="Nombre de la evaluación"
                                placeholder='Nombre de la evaluación'
                                multiline
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                    notched: true
                                }}
                                margin='none'
                                maxRows={15}
                                className={classes.dropdownMultiple}
                                error={!!errors.hasOwnProperty("name") && errors["name"].message}
                            />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("name") &&
                            errors["name"].message}
                        </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item lg={12} md={12} fullWidth style={{marginBottom: '4%'}}>
                    <FormControl fullWidth variant='outlined' size='small'>
                        <TextField 
                            {...register('description')}
                            fullWidth
                            size='small'
                            multiline
                            minRows={2}
                            maxRows={20}
                            disabled
                            InputLabelProps={{
                                shrink: true,
                                notched: true
                            }}
                            label="Descripción"
                            placeholder='Descripción'
                            className={classes.dropdownMultiple}
                            error={!!errors.hasOwnProperty("description") && errors["description"].message}
                        />
                    <FormHelperText style={{color:'#d70909'}}>
                        {!!errors.hasOwnProperty("description") &&
                        errors["description"].message}
                    </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        )
}

export default Index