import React from "react";
import * as IdKey from "short-uuid";
import {
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import {styles} from "./styles"
import moment from "moment"
import { useHistory } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PATH_APP, typesEvaluation } from "../../../../constants";
import BarChartIcon from '@mui/icons-material/BarChart';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

export default function DetailedAccordion({
  data,
}) {
  const history = useHistory();
  const classes = styles();
  const id = IdKey.generate();


  const titleTooltip = (data) => {
    if(data?.expired && data?.status === 'ANSWERED'){
      return "La evaluación ya fue contestada";
    }else if(data?.expired && data?.status === 'PENDING_TO_ANSWER'){
      return "La fecha límite para responder esta evaluación ha expirado";
    }else if(!data?.expired){
      return "Responder";
    }
  }
if(data?.length) {
  return (
    <div className={classes.root}>
      <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"15%"} className={classes.wrapper}>
                  Periodo de aplicación
                </TableCell>
                <TableCell align="left" width={"18%"} className={classes.wrapper}>
                  Tipo de evaluación
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Programa
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Evaluado
                </TableCell>
                <TableCell align="left" width={"22%"} className={classes.wrapper}>
                  Nombre de aplicación
                </TableCell>
                <TableCell align="left" width={"5%"} className={classes.wrapper}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, idColumn = id) => (
                <TableRow key={idColumn}>
                  <TableCell align="left" width={"15%"} className={classes.header}
                    style={{
                      borderTopLeftRadius: idColumn === 0 ? 10 : 0,
                      borderBottomLeftRadius: idColumn === (data?.length - 1) ? 10 : 0
                    }}>
                      <Typography style={{ fontSize: 13.5, fontWeight: 400 }} >
                        {`${moment(row?.startDate).format('DD/MM/YYYY')} - ${moment(row?.endDate).format('DD/MM/YYYY')}` }
                      </Typography>
                  </TableCell>
                  <TableCell align="left" width={"18%"} className={classes.header}>
                    <Typography style={{ fontSize: 13.5, fontWeight: 400 }} >
                      {typesEvaluation[row?.type] ?? '' }
                    </Typography>
                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    {row.programName}
                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    <Typography style={{ fontSize: 13.5, fontWeight: 400 }} >
                      {`${row?.evaluatedName ?? ''} ${row?.evaluatedSurname ?? ''}` }
                    </Typography>
                  </TableCell>
                  <TableCell align="left" width={"22%"} className={classes.header}>
                    <Typography className={classes.heading} >{row?.applicationName ?? ''}</Typography>
                  </TableCell>
                  <TableCell align="left" width={"5%"} 
                    style={{
                      borderTopRightRadius: idColumn === 0 ? 10 : 0,
                      borderBottomRightRadius: idColumn === (data?.length - 1) ? 10 : 0
                    }} >
                      <div className={classes.column} >
                        <Tooltip title={titleTooltip(row)} arrow>
                          <span>
                            <IconButton 
                              disabled={row?.expired ? true : false}
                              onClick={() => history.push(`${PATH_APP}/evaluation-performance/evaluated/aplication/${row?.performanceEvaluationToAnswerId}`)}
                            >
                              <QuestionAnswerIcon color={ row?.expired ? "disabled" : "primary"}/>
                            </IconButton>
                          </span>
                        </Tooltip>
                  
                        {/* &nbsp;&nbsp;
                        <Tooltip title={row?.status !== "ANSWERED" || row?.status === "PENDING_TO_ANSWER" ? "Sin Resultado" : " Ver Resultado"} arrow>
                          <span>
                          <IconButton 
                              disabled={row?.status !== "ANSWERED" || row?.status === "PENDING_TO_ANSWER" ? true : false}
                              onClick={() => history.push(`${PATH_APP}/evaluation-performance/evaluated/result/${row?.performanceEvaluationToAnswerId}`)}
                            >
                              <BarChartIcon color={ row?.status !== "ANSWERED" || row?.status === "PENDING_TO_ANSWER" ? 'disabled' : "success"}/>
                            </IconButton>
                          </span>
                        </Tooltip> */}
                      </div>
                      
          
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
      }else {
        return null;
      }
}
