import React, { useState, useEffect } from "react";
import { Tooltip, Checkbox } from "@mui/material";
import { useStateValue } from "../../../../context/store";
import { styles } from "./styles";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { typesEvaluators } from "../../../../constants";

export default function DetailedAccordion({ setMessage, message, data, page }) {


  const classes = styles();
  const [{ organization, typesEvaluations }, dispatch] = useStateValue();
  const [checkeds, setCheckeds] = useState([]);


  const closeModal = (exists) => {
    setMessage({ ...message, open: false });
    const identificacion = [];
    const totalPages = typesEvaluations?.pages?.length
      ? [...typesEvaluations?.pages, page]
      : [page];

    const users = data?.filter((x) => x?.leader?.uuid);
    const newCheckedUsers = typesEvaluations?.users?.length
      ? typesEvaluations?.users
      : [];

    for (let index = 0; index < users?.length; index++) {
      const validateUser = newCheckedUsers?.find(
        (x) => x?.uuid === users[index]?.uuid
      );

      if (validateUser) {
        continue;
      } else {
        newCheckedUsers?.push({
          ...users[index],
          pos: index + 1,
          subalterns: users[index]?.subalterns?.length ? users[index]?.subalterns : [],
          students: users[index]?.subalterns?.length ? users[index]?.subalterns?.filter((x)=> x?.position?.uuid === users[index]?.position?.uuid)
          ?.filter((d) => d?.regional?.uuid === users[index]?.regional?.uuid) : [],
          });
      }

      identificacion.push(users[index]?.subalterns?.length 
        && users[index]?.subalterns?.filter((x)=> x?.position?.name === users[index]?.position?.name)?.map((l)=> l?.identificationNumber))
    }

    setCheckeds(newCheckedUsers);
    dispatch({
      type: "SET_TYPES_EVALUATIONS",
      automatic: typesEvaluations?.automatic,
      applicationTypes: typesEvaluations?.applicationTypes,
      submitPercentage: typesEvaluations?.submitPercentage,
      users: newCheckedUsers,
      selectAll: true,
      dataEvaluation: typesEvaluations?.dataEvaluation,
      pages: totalPages,
      selectNewLeaders: typesEvaluations?.selectNewLeaders,
    });
  };

  const validateChecked = (user) => {
    const result = checkeds?.find((x) => x?.uuid === user);
    return result ? true : false;
  };

  const handleChangeChecked = (event, info) => {
    const copy = checkeds?.length ? [...checkeds] : [];
    const { name } = event.target;
    const findUser = data?.find((x) => x?.uuid === name);
    const validateExists = validateChecked(findUser?.uuid);

    // si hay usuarios seleccionados en la tabla
    if (checkeds?.length) {
      // Validamos si el usuario(validateExists) existe 
      if (validateExists) {
        let updatePage = [];
        const differentsType = typesEvaluations?.applicationTypes?.filter(
          (x) => x?.type !== "PEER_EVALUATION"
        );
        const findType = typesEvaluations?.applicationTypes?.find(
          (x) => x?.type === "PEER_EVALUATION"
        );
        const differentUsers = findType?.employees?.filter(
          (x) => x?.evaluated?.uuid !== name
        );
        const findPage = typesEvaluations?.pages?.find((x) => x === page);

        if (findPage) {
          updatePage = typesEvaluations?.pages?.filter((x) => x !== page);
        }

        if (findType) {
          differentsType.push({
            ...findType,
            employees: _.orderBy(differentUsers, ["pos"], ["asc"]),
          });
        }

        setCheckeds(checkeds?.filter((x) => x?.uuid !== name));
        dispatch({
          type: "SET_TYPES_EVALUATIONS",
          automatic: typesEvaluations?.automatic,
          applicationTypes: differentsType,
          selectAll: typesEvaluations?.selectAll,
          submitPercentage: typesEvaluations?.submitPercentage,
          users: checkeds?.filter((x) => x?.uuid !== name),
          dataEvaluation: typesEvaluations?.dataEvaluation,
          pages: updatePage,
          selectNewLeaders: typesEvaluations?.selectNewLeaders,
        });
      } else {
        copy.push({
          ...info,
          pos: checkeds?.length + 1,
          subalterns: info?.subalterns?.length ? info?.subalterns : [],
          students: info?.subalterns?.length ? info?.subalterns?.filter((x)=> x?.position?.uuid === info?.position?.uuid)
          ?.filter((d) => d?.regional?.uuid === info?.regional?.uuid) : []
        });
        setCheckeds(copy);
        dispatch({
          type: "SET_TYPES_EVALUATIONS",
          automatic: typesEvaluations?.automatic,
          applicationTypes: typesEvaluations?.applicationTypes,
          submitPercentage: typesEvaluations?.submitPercentage,
          users: copy,
          selectAll: typesEvaluations?.selectAll,
          dataEvaluation: typesEvaluations?.dataEvaluation,
          pages: typesEvaluations?.pages,
          selectNewLeaders: typesEvaluations?.selectNewLeaders,
        });
      }
    } else {
      copy?.push({
        ...info,
        pos: checkeds?.length + 1,
        subalterns: info?.subalterns?.length ? info?.subalterns : [],
        students: info?.subalterns?.length ? info?.subalterns?.filter((x)=> x?.position?.uuid === info?.position?.uuid)
        ?.filter((d) => d?.regional?.uuid === info?.regional?.uuid) : []
      });

      setCheckeds(copy);
      dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: typesEvaluations?.automatic,
        applicationTypes: typesEvaluations?.applicationTypes,
        submitPercentage: typesEvaluations?.submitPercentage,
        users: copy,
        selectAll: typesEvaluations?.selectAll,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders,
      });
    }
  };


  useEffect(() => {
    if (typesEvaluations?.users?.length) {
      setCheckeds(typesEvaluations?.users?.length ? typesEvaluations?.users : []);
      dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: typesEvaluations?.automatic,
        applicationTypes: typesEvaluations?.applicationTypes,
        submitPercentage: typesEvaluations?.submitPercentage,
        users: typesEvaluations?.users,
        selectAll: typesEvaluations?.selectAll,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders,
      });
    } else if (typesEvaluations?.users?.length) {
      setCheckeds(
        typesEvaluations?.users?.length ? typesEvaluations?.users : []
      );
    }
  }, []);

  useEffect(()=> {

    let validateExists;
    if(data?.length){
      for(let index = 0; index < data?.length; index++){
        validateExists = checkeds?.find((x)=> x?.uuid === data[index]?.uuid) ? true : false;
  
        if(!validateExists) return 
        else continue;
      }
    }

    if(validateExists){
      return dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: typesEvaluations?.automatic,
        applicationTypes: typesEvaluations?.applicationTypes,
        submitPercentage: typesEvaluations?.submitPercentage,
        users: typesEvaluations?.users,
        selectAll: true,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages?.length ? [...typesEvaluations?.pages, page] : [page],
        selectNewLeaders: typesEvaluations?.selectNewLeaders,
      });
    }
   
  },[data])

  useEffect(() => {
    if (organization?.changeLeader) {
      const filterLeader = organization?.newSubalterns?.find(
        (x) => x?.person?.documentNumber === organization?.leaderId
      );

      if (filterLeader?.collaborators?.length) {
        setCheckeds(
          filterLeader?.collaborators?.length ? filterLeader?.collaborators : []
        );
      } else {
        setCheckeds([]);
      }

      dispatch({
        type: "ORGANIZATION_CHART",
        changeLeader: false,
        leaderToEdit: organization?.leaderToEdit,
        infoLeader: organization?.infoLeader,
        leaderId: organization?.leaderId,
        newSubalterns: organization?.newSubalterns,
        page: organization?.page,
      });
    }
  }, [organization?.changeLeader]);

  useEffect(() => {
    setCheckeds(typesEvaluations?.users?.length ? typesEvaluations?.users : []);
  }, [typesEvaluations?.users]);

  const closeModalCancel = () => setMessage({ ...message, open: false });

  const checkedAll = () => {
    const findPage = typesEvaluations?.pages?.includes(page);

    if (findPage) {
      let updatePage = [];
      let newCheckedUsers = typesEvaluations?.users;
      const findPage = typesEvaluations?.pages?.find((x) => x === page);
      const findType = typesEvaluations?.applicationTypes?.find(
        (x) => x?.type === "PEER_EVALUATION"
      );
      const differentsTypes = typesEvaluations?.applicationTypes?.filter(
        (x) => x?.type !== "PEER_EVALUATION"
      );
      let newArrUsers = findType?.employees;
      const users = data?.filter((x) => x?.leader?.uuid);

      if (findPage) {
        updatePage = typesEvaluations?.pages?.filter((x) => x !== page);
      }

      for (let index = 0; index < users?.length; index++) {
        newCheckedUsers = newCheckedUsers?.filter(
          (x) => x?.uuid !== users[index]?.uuid
        );
        newArrUsers = newArrUsers?.filter(
          (x) => x?.evaluated?.uuid !== users[index]?.uuid
        );
      }

      setCheckeds(newCheckedUsers);
      // localStorage.setItem('typesEvaluation', JSON.stringify({...jsonTypes, users: newCheckedUsers}))
      differentsTypes.push({ ...findType, employees: newArrUsers });
      return dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: typesEvaluations?.automatic,
        applicationTypes: differentsTypes,
        submitPercentage: typesEvaluations?.submitPercentage,
        users: newCheckedUsers,
        selectAll: false,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: updatePage,
        selectNewLeaders: typesEvaluations?.selectNewLeaders,
      });
    } else {
     
      return setMessage({
        open: true,
        description:
          "Se seleccionarán los usuarios de la página actual que tienen líder asignado",
        handleClick: () => closeModal(),
        handleClickOut: () => closeModalCancel(),
        buttonsTwo: true,
        textButtonCancel: "CANCELAR",
        textButtonSubmit: "ACEPTAR",
        type: "WARNING",
      });
    }
  };

  const validatePage = () => {
    const findPage = typesEvaluations?.pages?.includes(page);
    return findPage ? true : false;
  };

  const validateDisabled = (x) => {
    return (
      <Checkbox
        size="small"
        checked={validateChecked(x?.uuid)}
        name={x?.uuid}
        color="primary"
        className={classes.checkbox}
        onChange={(e) => handleChangeChecked(e, x)}
        inputProps={{ "aria-label": "controlled" }}
        // disabled={!relations?.behaviorSelected}
      />
    );
  };


  if (data?.length) {
    return (
      <div>
       <Paper elevation={0} style={{background: 'inherit'}}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width={"10%"} align="left">
                  <Tooltip title="Todas" arrow placement="top">
                    <Checkbox
                      size="small"
                      name="checkAll"
                      className={classes.checkbox}
                      onChange={() => checkedAll()}
                      checked={validatePage()}
                      inputProps={{ "aria-label": "controlled" }}
                      // disabled={!relations?.behaviorSelected}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell width={"15%"} className={classes.wrapper}>Nombre</TableCell>
                <TableCell align="left" width={"15%"} className={classes.wrapper}>
                  Identificación
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Cargo
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Programa
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Asignatura
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.uuid}>
                  <TableCell align="left" width={"10%"} className={classes.header}>
                    {validateDisabled(row)}
                  </TableCell>
                  <TableCell component="th" scope="row" width={"15%"} className={classes.header}>
                    {`${row?.name ? row?.name : ""} ${
                      row?.secondName ? row?.secondName : ""
                    } ${row?.surname ? row?.surname : ""} ${
                      row?.secondSurname ? row?.secondSurname : ""
                    }`}
                  </TableCell>
                  <TableCell align="left" width={"15%"} className={classes.header}>
                    {row?.identificationNumber}
                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    {typesEvaluators[row?.typeEvaluator] ?? ""}
                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    {row?.regional?.name}
                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    {row?.position?.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
      </div>
    );
  } else {
    return null;
  }
}
