import React, {useState, useEffect} from "react";
import { makeStyles } from "@mui/styles";
import { Button, Modal } from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import SearchQuestions from "../../../questions/search"
import { useStateValue } from "../../../../context/store";
import ModalConfirm from "../../../../utils/form/modalConfirmBase";
import {  getALLTags } from '../../../../actions/label-actions';
import {  getfilterQuestions, } from '../../../../actions/question-action';
import ListQuestions from "./listQuestions";
import _ from "lodash";
import { PATH_APP } from "../../../../constants";
import { showMessageWithOutAction } from "../../../../utils/constants";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: '90%',
    height: 490,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.24)",
    outline: "none",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
  },
  cancel1: { 
     color: "#2F71CB", 
     marginBottom: 5, 
     borderColor: '#80808070',
     "&:hover": {
      borderColor: '#80808070',
    },
  },
  cancel: { 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5, 
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
 },
  text: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#4B4B4B",
    fontWeight: 400,
    textAlign: "center",
    margin: "25px 20px",
  },
  titleAlert: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#2C2C2C",
  },
  widthIcon: {
    fontSize: 70,
    color: '#2F71CB',
    marginTop: '3%'

  },
  confirmBanck :{ 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5,
    // width: 'fit-content' ,
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
  }
}));

const ModalConfirmBase = ({
  description,
  open,
  handleClick,
  handleClickOut,
  title,
  oneButtons,
  textButtonSubmit,
}) => {
  const classes = useStyles();
  const [{typeOperation, questionSearch, relations, loadingTables}, dispatch] = useStateValue()
  const [modalStyle] = React.useState(getModalStyle);
  const [labels, seLabels] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false)
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [clearFielForm, setClearFielForm]= useState(false)

  const [paginadorResponse, setPaginadorResponse] = useState({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
  })


  const initialState = () => {
    return dispatch({
          type: "SET_FILTER_QUESTION",
          filter: {...questionSearch?.filter, page: 0, size: 5},
          isFilter: false,
        })
  }

  const refresTable = () => {
    return dispatch({
      type: "SET_FILTER_QUESTION",
      filter: { page: 0, size: 5},
      isFilter: false,
    })
  }


  useEffect(()=> {
    if(open){
      closeModalAndClear();
      getAllQuestions()
      getAllLabels();
    }
  },[open])
  

  useEffect(() => {
    if(questionSearch?.isFilter){
      getAllQuestions();
    }
  },[questionSearch?.isFilter])

  const getAllLabels = async() => {
    const result = await getALLTags({status: 'ACTIVE'}, {orderBy: 'name'}, dispatch);
    seLabels(result?.data?.content ? result?.data?.content : [])
  }

  

  const closeModalAndClear = () => setClearFielForm(!clearFielForm)
  const closeModal = () => {setMessage({...message, open:false}); refresTable(); closeModalAndClear()}
  const dataFilter = () => setPaginadorResponse({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });

  const getAllQuestions = async() => {

     await getfilterQuestions(
      {...questionSearch?.filter,
        page: questionSearch?.filter?.page ?? 0,
        size: questionSearch?.filter?.size ?? 5
      }, {orderBy: 'createdAt'}, dispatch)
    .then( 
      (res) => {
        if(res?.status === 204 && !questionSearch?.typeAction){
          setMessage({
            open : true,
            description: 'No se encontró información relacionada a la búsqueda',
            handleClick : () => closeModal() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
         }
         if(res?.data?.content?.length < 1 && !questionSearch?.typeAction){
          setPaginadorResponse({
            data: [],
            totalRows: 0,
            pageSize: 0,
            pageNumber: 0,
          });
          return setMessage({
            open : true,
            description: 'No se encontró información relacionada a la búsqueda',
            handleClick : () => closeModal() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
         }
        if ((res && res.status === 200 )|| res.status === 201) {
          const data = res?.data;
          if (data?.content?.length > 0) {
            setPaginadorResponse({
              data: data?.content,
              totalRows: data?.totalElements,
              pageSize: data?.size,
              pageNumber: data?.number,
            });
          }
          return initialState()
        } 
      }
    )
    .catch( 
      (err) => {
        setPaginadorResponse({
          data: [],
          totalRows: 0,
          pageSize: 0,
          pageNumber: 0,
        });
        showMessageWithOutAction(dispatch, 'Ocurrió un error en el servicio de búsqueda.')
        closeModalAndClear()
      }
    )
  
  }


const addQuestions = () => {
 if(typeOperation?.operation !== "EDIT"){
  dispatch({
    type: "SET_BREADCRUMB",
    isSubmit: true,
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Crear modelo de evaluación",
    module: "EVALUATION_PERFORMANCE",
    index: 10,
    idForm: 'submitRelations',
    textIsSubmit: "CREAR",
    disabledSubmit: false,
    subPath: `${PATH_APP}/evaluation-performance`
  });
 } 
  handleClick()
  let validateCompetencie =  relations?.data.find((x)=> x?.uuid === relations?.competencieSelected)
  let differentsCompetencies =  relations?.data.filter((x)=> x?.uuid !== relations?.competencieSelected)

  let differentsBehaviors = validateCompetencie?.behaviors?.filter((x)=> x?.uuid !== relations?.behaviorSelected)


  if(validateCompetencie){
      let getBehavior = validateCompetencie?.behaviors?.find((x)=> x?.uuid === relations?.behaviorSelected)

      if(!getBehavior?.questions?.length){
       const newQuestion = {...getBehavior, questions: selectedQuestions}
         differentsBehaviors.push(newQuestion)
         const newData = {...validateCompetencie, behaviors: _.orderBy(differentsBehaviors, ['position'],['asc'])}
           differentsCompetencies.push(newData)
         return dispatch({
                   type: 'SET_RELATIONS_EVALUATION',
                   data: _.orderBy(differentsCompetencies, ['position'],['asc']),
                   dataSections: relations?.dataSections,
                   sectionSelected: relations?.sectionSelected,
                   behaviorSelected: relations?.behaviorSelected,
                   competencieSelected: relations?.competencieSelected,
               
               })
   } else {
 
           const updateQuestionsofBehavior = {...getBehavior, questions: selectedQuestions}
           differentsBehaviors.push(updateQuestionsofBehavior)
           const updateCompetencie = {...validateCompetencie, behaviors: _.orderBy(differentsBehaviors, ['position'],['asc'])}
           differentsCompetencies.push(updateCompetencie)

           return dispatch({
               type: 'SET_RELATIONS_EVALUATION',
               data: _.orderBy(differentsCompetencies, ['position'],['asc']),
               dataSections: relations?.dataSections,
               sectionSelected: relations?.sectionSelected,
               behaviorSelected: relations?.behaviorSelected,
               competencieSelected: relations?.competencieSelected,
               
           })
       }
  }
  
}

  const changePage = (event, nuevaPagina) => {
    dispatch({
      type: "SET_FILTER_QUESTION",
      filter: {...questionSearch?.filter, page: nuevaPagina, size: questionSearch?.filter?.size },
      isFilter: true,
    })
  };

  const changeAmountOfRecords = (event, siguiente) => {
    dispatch({
      type: "SET_FILTER_QUESTION",
      filter: {...questionSearch?.filter, size: event?.target.value, page:0},
      isFilter: true,
    })
};

const getQuestions = (x) => setSelectedQuestions(x);
const disabledSave = (x) => setDisabledButton(x);
  

  const bodyModal = (
    <div style={modalStyle} className={classes.paper}>
      <SearchQuestions 
        labels={[...labels]}
        clearFilter={dataFilter}
        clearFielForm={clearFielForm}
        indexQuestion={true}
      />
       <ListQuestions data={[...paginadorResponse?.data]} getQuestions={getQuestions} disabledSave={disabledSave}/>
      {paginadorResponse?.totalRows !== 0 && !loadingTables?.active &&
        <TablePagination
          style={{  display:"flex", justifyContent: 'center', color: '#747578' }}
          component="div"
          rowsPerPageOptions={[5]}
          count={paginadorResponse.totalRows}
          rowsPerPage={questionSearch?.filter?.size ?? 5 }
          page={paginadorResponse.pageNumber ?? 0}
          labelRowsPerPage='Registros por página'
          onPageChange={changePage}
          onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
        />
      }
      <div style={{ display: "flex", justifyContent: "center", alignItems: 'baseline', flexDirection: null}}>
        <React.Fragment >
          <Button
            className={ classes.cancel1 }
            variant="outlined"
            onClick={handleClick}
          >
            CANCELAR
          </Button>

          <Button
            type="submit"
            variant="contained"
            disabled={disabledButton}
            color={!disabledButton ? "primary" : "info"}
            onClick={()=> addQuestions()}
            style={{ marginBottom: 5, marginLeft: 5, marginTop: 20 }}
            >
                AGREGAR
          </Button>
        </React.Fragment>
      </div>
    </div>
  );

  
  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
    </div>
  );
};

export default ModalConfirmBase;
