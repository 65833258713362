import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  FormControl,
  Grid,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { useStateValue } from "../../context/store";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import FormHelperText from "@mui/material/FormHelperText";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LoginContainer from "./loginContainer";
import {loginUser, getRoleUser} from  "../../actions/login-action"
import Snackbar from '../../utils/snackbars/SnackBars';
import { useForm } from "react-hook-form";
import LogoZabud from "../../images/logo_principal.png"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { styles } from "./styles";
import { PATH_APP } from "../../constants";

const Login = (props) => {
  const classes = styles();

  const [messageSnackbar, setMessageSnackbar] = useState({
    message: '',
    open: false,
    severity: '',
    handleClose: () => {}
  })
  const [, dispatch] = useStateValue()
  const [usuario, setUsuario] = useState({
    username: "",
    password: "",
    showPassword: false,
  });


  const schema = yup.object().shape({
    username: yup
      .string()
      .nullable()
      .required("El usuario es obligatorio"),
    password: yup
    .string()
    .required("La contraseña es obligatoria")
    .min(6, 'Ingrese mínimo 6 caracteres')
    .max(16, 'Ingrese máximo 16 caracteres')
    .matches(
      /^(([a-zA-Z-0-9]+([!#%$]+)*)|(".+"))$/,
      "Ingrese una contraseña válida"
    ),
  });

  const { register, handleSubmit, formState: {errors} } = useForm({
    resolver: yupResolver(schema),

    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(()=> {
    dispatch({
      type: 'LOGIN_SESSION',
    })
    dispatch({
      type: 'LOGIN_TOKEN',
      token: ''
    })
  },[])

  const handleClickShowPassword = () => {
    setUsuario({ ...usuario, showPassword: !usuario.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleCloseSnackbar = () => setMessageSnackbar({...messageSnackbar, open: false})


  const onSubmit = (data) => {
    const postCredentialsUser = async() => {
      const result = await loginUser(data, dispatch)
      if(result?.status >= 200 && result?.status <= 204){
        const getRoles = async() => {

          const result = await getRoleUser(dispatch)
          if(result?.status >= 200 && result?.status <= 204){
            
            return props.history.push(`${PATH_APP}/home`);
          }
          else {
            setMessageSnackbar({
                  message: 'Usuario y/o contraseña incorrectos',
                  open: true,
                  severity: 'error',
                  handleClose: () => handleCloseSnackbar()
                })
          }
        }
        getRoles()
      }
      else if (result?.status === 400){
        setMessageSnackbar({
              message: 'Usuario inactivo',
              open: true,
              severity: 'error',
              handleClose: () => handleCloseSnackbar()
            })
      }else if (result?.status === 401){
        setMessageSnackbar({
              message: 'Usuario y/o contraseña incorrectos',
              open: true,
              severity: 'error',
              handleClose: () => handleCloseSnackbar()
            })
      }else {
        setMessageSnackbar({
          message: 'Surgió un error, inténtelo nuevamente o contacte al administrador.',
          open: true,
          severity: 'error',
          handleClose: () => handleCloseSnackbar()
        })
      }
    }
    postCredentialsUser()
  }

  const showPasswordIcon = () => {
    if (usuario.showPassword) {
      return (
        <Visibility
          className={classes.cursorPointer}
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        ></Visibility>
      );
    } else {
      return (
        <VisibilityOff
          className={classes.cursorPointer}
          aria-label="toggle password VisibilityOff"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        ></VisibilityOff>
      );
    }
  };

  const LoginComponent = () => (
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Grid item container lg={12} justifyContent="center" alignItems="center" flexDirection="column">
              <Grid item lg={10} md={10} sm={10} style={{marginBottom: '3%'}}>
                <img src={LogoZabud} alt="logo_Zabud" width="auto" height="50px" />
              </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                  <Typography style={{fontSize: 34, fontWeight: 400, textAlign: 'center', paddingBottom: '3%'}}>Sistema de Autoevaluación</Typography>
                </Grid>
              <Grid item  md={6} lg={6} sm={10} xs={10} style={{width: '100%'}}>
                <FormControl className={classes.formControl}>
                  <TextField
                    {...register("username")}
                    variant="outlined"
                    className={classes.mt10}
                    label="Usuario"
                    placeholder="Usuario"
                    margin="normal"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"
                        className={classes.iconLogin}
                        >
                          <PersonOutlineOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={errors.hasOwnProperty("username") && errors["username"].message}
                  />
                  <FormHelperText style={{ color: "#f44336", marginTop: -4 }}>
                    {errors.hasOwnProperty("username") &&
                      errors["username"].message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item  md={6} lg={6} sm={10} xs={10} style={{width: '100%'}}>
                <FormControl className={classes.formControl} variant="outlined">
                  <TextField
                    type={usuario.showPassword ? "text" : "password"}
                    // onChange={addValuesToMemory}
                    className={classes.mt10}
                    {...register("password")}
                    variant="outlined"
                    label="Contraseña"
                    size="small"
                    placeholder="Contraseña"
                    name="password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"
                        className={classes.iconLogin}
                        >
                          {showPasswordIcon()}
                        </InputAdornment>
                      ),
                    }}
                    error={errors.hasOwnProperty("password") && errors["password"].message}
                  />
                  <FormHelperText style={{ color: "#f44336" }}>
                    {errors.hasOwnProperty("password") &&
                      errors["password"].message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item  container spacing={2} justifyContent="center" style={{ marginTop: "5%" }}>
                <Grid item md={4} lg={4} sm={6} xs={6}>
                  <Button
                    // disabled={!errorsForm}
                    className={classes.submit}
                    variant="contained"
                    disableElevation
                    type="submit"
                    fullWidth
                  >
                    Entrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            < Snackbar 
                severity={messageSnackbar.severity} 
                message={messageSnackbar.message} 
                open={messageSnackbar.open}
                handleClose={messageSnackbar.handleClose}
            />
          </form>
  );

  return (
      <LoginContainer>{LoginComponent()}</LoginContainer>
  );
};

export default withRouter(Login);
