import React,{useState, useEffect} from 'react'
import { Grid, Typography, Button, CardContent, Card, CardActions } from '@mui/material';
import Relations from "./relations";
import ModalCompetencies from "./modalCompetencies";
import ModalQuestions from "./modalQuestions";
import { useStateValue } from "../../../context/store";
import ModalConfirm from '../../../utils/form/modalConfirmBase';
import { useForm } from "react-hook-form";
import { createPerformance, getTypesEvaluators } from "../../../actions/Performance_Evaluation-action";
import { useHistory } from "react-router-dom";
import ModalError from "../../../components/common"
import CustomizedButtonMenu from "./relations/components/button-menu"
import ModalReviewRol from "./relations/components/modal-review-rol"
import { PATH_APP } from '../../../constants';

const Index = (props) => {
    const history = useHistory();

    const [{relations, evaluationPerformanceSearch, dataAplication}, dispatch] = useStateValue();
    const [typesEvaluators, setTypesEvaluators] = useState([]);
    const [message, setMessage] = useState({
        description : '',
        textButtonSubmit : '',
        handleClick : () => {} ,
        handleClickOut : () => {},
        oneButtons : false,
        title: '',
        type : '',
        open : false
      })
  const [messageValidation, setMessageValidation] = useState({
    description: '',
    textButtonSubmit: '',
    handleClick: () => { },
    handleClickOut: () => { },
    oneButtons: false,
    title: '',
    type: '',
    open: false
  })
      const [messageCompetencies, setMessageCompetencies] = useState({
        description : '',
        textButtonSubmit : '',
        oneButtons : () => {} ,
        handleClick : () => {} ,
        handleClickOut : () => {},
        title: '',
        open : false
      })

      const [messageQuestions, setMessageQuestions] = useState({
        oneButtons : () => {} ,
        handleClick : () => {} ,
        handleClickOut : () => {},
        open : false
      })
    
  const [messageModalReviewRol, setMessageModalReviewRol] = useState({
    description: '',
    textButtonSubmit: '',
    currentData: null,
    oneButtons: () => { },
    handleClick: () => { },
    handleClickOut: () => { },
    title: '',
    open: false
  })

   
    const { handleSubmit,  } = useForm({
        mode: "all",
        reValidateMode: "onChange",
      })

  const closeModal = () => setMessage({...message, open:false})
  const closeModalValidation = () => setMessageValidation({ ...messageValidation, open: false });
  const backToFormInitial = () =>  history.push(`${PATH_APP}/evaluation-performance`);
  

  const titleHeader = (title) => {
    switch (title) {
      case 'Dir.Prog':
        return "Dr_Prg";
   
      case 'Coor':
        return 'Cor';
    
      case 'Doc':
        return 'Doc'
      
        case 'Est':
        return 'Est'

      default: return title
    }
  }

  const getTypes = async() => {
    const result = await getTypesEvaluators(dispatch);
    if(result?.data?.length) return setTypesEvaluators(result?.data?.map((x)=> ({id: x?.uuid, value: titleHeader(x?.abbreviation), name: x?.name})))
    return setMessage({
      open : true,
      description: 'Se presento un error en el servicio de tipos de evaluadores',
      handleClick : () => backToFormInitial() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
  })
  }
   useEffect(() => {
    if(relations?.backToForm){
      dispatch({
        type: 'SET_RELATIONS_EVALUATION',
        dataSections: relations?.dataSections,
        sectionSelected: relations?.sectionSelected,
        data: relations?.data,
        behaviorSelected: relations?.behaviorSelected,
        competencieSelected: relations?.competencieSelected,
        backToForm: false
    })
    }else {
      return null
    }
   }, [relations?.backToForm])
   
   

   useEffect(() => {
    if(props?.isEdit || props?.isCopy){
      return dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Gestionar modelo de evaluación",
        index: 12,
        module: 'EVALUATION_EVALUATOR',
        idForm: 'submitRelations',
        textIsSubmit: props?.isCopy ? "CREAR COPIA" : "EDITAR",
        isSubmit: true,
        subPath: props?.isCopy ? `${PATH_APP}/evaluation-performance-evaluator/detail/${props?.idEvaluation}` : `${PATH_APP}/evaluation-performance-evaluator`
      });
    }else {
      dispatch({
        type: "SET_BREADCRUMB",
        isSubmit: true,
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Crear modelo de evaluación",
        module: "EVALUATION_PERFORMANCE",
        index: 12,
        idForm: 'submitRelations',
        textIsSubmit: "CREAR",
        disabledSubmit: false,
        subPath: `${PATH_APP}/evaluation-performance`
      })
    }
   }, [relations?.data?.length])
   

   useEffect(() => {
    getTypes();
   },[])
   const showButtonBack = () => dispatch({
    type: "SET_BREADCRUMB",
    isSubmit: true,
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Crear modelo de evaluación",
    module: "EVALUATION_PERFORMANCE",
    index: 10,
    idForm: 'submitRelations',
    textIsSubmit: "CREAR",
    disabledSubmit: false,
    subPath: `${PATH_APP}/evaluation-performance`
  })

  const DisabledSubmitCreate = () => dispatch({
    type: "SET_BREADCRUMB",
    isSubmit: true,
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Crear modelo de evaluación",
    module: "EVALUATION_PERFORMANCE",
    index: 10,
    idForm: 'submitRelations',
    textIsSubmit: "CREAR",
    disabledSubmit: true,
    subPath: `${PATH_APP}/evaluation-performance`
  })


    const handleCloseModal = () => {setMessage({...message, open: false}); showButtonBack()}

    const handleCloseSuccess = () => {
        dispatch({
          type: "SET_RELATIONS_EVALUATION",
          dataSections: [],
          sectionSelected: '',
          data: [],
          competencieSelected: ''
        })
        dispatch({
          type: "SET_DATA_QUESTIONNAIRE",
          data: ''
      })
      history.push( `${PATH_APP}/evaluation-performance-evaluator`); 
        dispatch({
        type: "SET_FILTER_EVALUATION_PERFORMANCE",
        filter: evaluationPerformanceSearch?.filter,
        isFilter: true,
        typeAction: 'GO_BACK'
      })
        handleCloseModal()
        showButtonBack()
      }
    
      
    const onSubmit = () => {
      
        let behaviorsWithOut = 0;
        let questionsWithOutRol = 0;
        if(!dataAplication?.data?.description){
          return null
        } else {


      const newDataForm = {
        description: dataAplication?.data?.description, 
        status: dataAplication?.data?.status, 
        name:dataAplication?.data?.name
      }

      
      const validateBehaviors = relations?.data?.find((x)=> !x?.behaviors?.length )
  
        if(!relations?.data?.length){
            return setMessage({
                open : true,
                description: 'No hay ninguna acción de relación',
                handleClick : () => closeModal() ,
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                type : 'WARNING',
            })
        } else if(validateBehaviors || !relations?.data?.length) {
            return setMessage({
                        open : true,
                        description: 'Un/@s criterios no tienen aspectos asociados ',
                        handleClick : () => closeModal() ,
                        oneButtons : true,
                        textButtonSubmit: 'ACEPTAR',
                        type : 'WARNING',
                    })
        }else {
  
        for (let index = 0; index < relations?.data?.length; index++) {
          const behaviors = relations?.data[index]?.behaviors
  
          for (let index = 0; index < behaviors?.length; index++) {
            const question = behaviors[index]?.questions
  
            if(question?.length >= 1){
              for (let posQ = 0; posQ < question?.length; posQ++) {
                if(!question[posQ]?.rols){
                    questionsWithOutRol = questionsWithOutRol+1
                }
              }
         
              continue
            } else {
              behaviorsWithOut = behaviorsWithOut+1
              continue
            }

          } 
        }
  
        if(behaviorsWithOut >= 1){
          return dispatch({
            type: 'SET_MODAL_ACTION',
            open: true,
            message: 'Algún/os aspecto/s no tiene/n relacionada ninguna pregunta',
            icon: 'WARNING'
          })
        }else if(questionsWithOutRol >= 1) {
          return setMessage({
                      open : true,
                      description: 'Pregunta/s sin asignación de almenos un tipo de evaluación',
                      handleClick : () => closeModal() ,
                      oneButtons : true,
                      textButtonSubmit: 'ACEPTAR',
                      type : 'WARNING',
                  })
        }else {
          const copyData = [...relations?.data]
          let competences = []
          for (let index = 0; index < copyData?.length; index++) {
              competences.push(
                {
                  uuid : copyData[index]?.uuid, 
                  position: copyData[index]?.position,
                  domainLevel: copyData[index]?.domainLevel,
                  conducts: copyData[index]?.behaviors?.map((x, index)=>(
                    {uuid: x?.uuid,
                      position: index, 
                      questions: x?.questions?.map((f, index)=> (
                        {
                          position: index,
                          uuid: f?.uuid,
                          evaluators: f?.rols?.map((r)=> r)
                        }
                      ))
                    }))
                })
            
            }
  
            const newData = {...newDataForm, competences: [...competences]}

            for (let index = 0; index < relations?.dataSections?.length; index++) {
             if(relations?.dataSections[index]?.competences?.length){
              continue
             } else {
              return setMessage({
                open : true,
                description: "Verifique que cada una de las secciones contenga relaciones asociadas",
                handleClick : () => closeModal() ,
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                type : 'WARNING',
            })
             }
              
            }
          

        const savePerformance = async()=> {
          DisabledSubmitCreate()
          const result = await createPerformance(newData, dispatch) 
          if (result && (result?.status >= 200 && result?.status <= 204)) {
            setMessage({
              open : true,
              title: '¡Proceso exitoso!',
              description: 'Información almacenada correctamente ',
              handleClick : () => handleCloseSuccess() ,
              textButtonSubmit: 'ACEPTAR',
              oneButtons : true,
              type : 'SUCCESS',
            })
            }
            else if (result?.status > 399) {
              setMessage({
                open : true,
                description: result?.data?.message || 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
                handleClick : () => handleCloseModal() ,
                oneButtons : true,
                type : 'WARNING',
              })
            } else {
              setMessage({
                open : true,
                description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
                handleClick : () => handleCloseModal() ,
                oneButtons : true,
                type : 'WARNING',
              })
            }
          }
          savePerformance()
        }
      }
    }
      }

      const clear = () => dispatch({
        type: 'SET_RELATIONS_EVALUATION',
        data: [],
        dataSections: [],
        sectionSelected: '',
        behaviorSelected: '',
        competencieSelected: '',
        backToForm: false
    })

    const refreshDispatch = () => {
      return dispatch({
        type: "SET_FILTER_QUESTION",
        filter: {page: 0, size: 5},
        isFilter: false,
      })
    }
    
    const closeModalCompetencie = () => {setMessageCompetencies({...messageCompetencies, open: false}); showButtonBack()}
    
    const modalCompetencie = () => setMessageCompetencies(
        {...messageCompetencies, open: true,
            oneButtons: true,
            handleClick: () => closeModalCompetencie()
        }
        )
  
    const closeModalQuestions = () => {setMessageQuestions({...messageQuestions, open: false}); refreshDispatch()}
    const openModalQuestions = () => setMessageQuestions(
        {...messageQuestions, open: true,
            oneButtons: true,
            handleClick: () => closeModalQuestions()
        }
        )
      
  const closeModalReviewRol = () => {
    setMessageModalReviewRol({ ...messageModalReviewRol, currentData: null, open: false })
  }

  const validWithRol = (data, rol) => {
    return data?.map(value => {
      const behaviors = value?.behaviors?.map(beha => {
        const questions = beha?.questions
          ?.filter(quest => quest.rols?.includes(rol))
          .map(quest => ({ ...quest, question: quest?.value }))
          .filter(Boolean); // Elimina valores nulos o indefinidos

        return questions?.length ? { ...beha, questions } : null;
      }).filter(Boolean);

      return behaviors?.length ? { competence: value, conducts: behaviors, position: value?.position?.toString() } : null;
    }).filter(Boolean);
  };

  
 
  const handleDataFromButtonMenu = (idRol) => {
    let roleName = typesEvaluators?.find(item => item.id === idRol)?.name;
    const dataByRol = idRol ? {competences: validWithRol(relations?.data, idRol), roleName} : [];
  
    if (!dataByRol?.competences?.length) {
      setMessageValidation({
        open: true,
        description: "No se han relacionado preguntas para el rol",
        handleClick: closeModalValidation,
        oneButtons: true,
        textButtonSubmit: 'ACEPTAR',
        type: 'WARNING',
      });
    } else {
      setMessageModalReviewRol({
        ...messageModalReviewRol,
        currentData: {performanceEvaluation: dataByRol},
        open: true,
        oneButtons: true,
        handleClick: closeModalReviewRol,
      });
    }
  };


    return (
        <Grid container spacing={2} direction="row">
           
            <Grid item lg={12} md={12}>
            <Card elevation={3} style={{ background:'rgb(239 239 239)', padding: '10px'}}>
                <CardContent style={{background: props?.isEdit || props?.isCopy ? 'rgb(239 239 239)' : '#ffffff'}}>
                  {props?.children}
                    <Relations 
                        data={relations?.data}
                        sections={relations?.dataSections}
                        message={message}
                        setMessage={setMessage}
                        isEdit={props?.isEdit}
                        isCopy={props?.isCopy}
                        currentData={props?.currentData}
                        openQuestions={openModalQuestions}
                        messageQuestions={messageQuestions}
                        typesEvaluators={typesEvaluators}
                    />
                </CardContent> 
                <CardActions >
                {props?.isCopy 
                  ? null 
                  : <Grid item container lg={12} md={12} justifyContent='flex-start' style={{display: 'flex', alignItems: 'baseline'}}>
                      <Grid item lg={1}>
                          <Typography>
                              Agregar :
                          </Typography>
                      </Grid>
                      <Grid item lg={2} md={2}>
                      <Button
                              variant="contained"
                              onClick={()=> modalCompetencie()}
                              style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 5 }}
                          >
                              CRITERIOS
                          </Button>
                      </Grid>
                      <Grid item lg={7} md={7}>
                        <CustomizedButtonMenu onDataSend={handleDataFromButtonMenu} typesEvaluators={typesEvaluators}/>
                      </Grid>
                      <Grid item lg={2} md={2} style={{display:'flex', justifyContent: 'flex-end'}}>
                      <Button
                              type="submit"
                              variant="contained"
                              onClick={()=> clear()}
                              disabled={!relations?.data?.length }
                              style={{ color: "#ffffff", background: !relations?.data?.length ? "#8E8E8E" : '#2F71CB', marginBottom: 5, marginLeft: 5 }}
                          >
                              LIMPIAR TODO
                          </Button>
                      </Grid>
                    </Grid>
                }
                </CardActions>
            </Card>                
            </Grid>
            <form id="submitRelations" onSubmit={handleSubmit(onSubmit)}>
            </form>
            <ModalConfirm 
                description = { message.description }
                textButtonSubmit = { message.textButtonSubmit }
                handleClick = { message.handleClick }
                handleClickOut = { message.handleClickOut }
                oneButtons = { message.oneButtons }
                title = { message.title }
                type = { message.type }
                open = { message.open }
            />
            <ModalConfirm 
                description = { message.description }
                textButtonSubmit = { message.textButtonSubmit }
                handleClick = { message.handleClick }
                handleClickOut = { message.handleClickOut }
                oneButtons = { message.oneButtons }
                title = { message.title }
                type = { message.type }
                open = { message.open }
            />
            <ModalCompetencies 
                description = { messageCompetencies.description }
                textButtonSubmit = { messageCompetencies.textButtonSubmit }
                handleClick = { messageCompetencies.handleClick }
                handleClickOut = { messageCompetencies.handleClickOut }
                title = { messageCompetencies.title }
                oneButtons = { messageCompetencies.oneButtons }
                open = { messageCompetencies.open }
            />
                <ModalQuestions 
                handleClick = { messageQuestions.handleClick }
                handleClickOut = { messageQuestions.handleClickOut }
                oneButtons = { messageQuestions.oneButtons }
                open = { messageQuestions.open }
            />
        
        <ModalReviewRol
          handleClick={messageModalReviewRol.handleClick}
          handleClickOut={messageModalReviewRol.handleClickOut}
          oneButtons={messageModalReviewRol.oneButtons}
          open={messageModalReviewRol.open}
          currentData= {messageModalReviewRol.currentData}
        />
        <ModalConfirm 
                description = { messageValidation.description }
                textButtonSubmit = { messageValidation.textButtonSubmit }
                handleClick = { messageValidation.handleClick }
                handleClickOut = { messageValidation.handleClickOut }
                oneButtons = { messageValidation.oneButtons }
                title = { messageValidation.title }
                type = { messageValidation.type }
                open = { messageValidation.open }
            />
             < ModalError />
        </Grid>
    )
}

export default Index