import React from 'react';
import { 
  Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {styles} from '../styles';
import { useStateValue } from "../../context/store";
import CreateQuestionary from "./createQuestionary"
import { PATH_APP } from '../../constants';

export default function EditQuestionary(props) {
    const classes = styles();
    const [, dispatch] = useStateValue();
    

  const handleCloseModalCreate = () =>{
    props.changeStateModalDetail()
    if(!props?.detailAplication){
      dispatch({
         type: "SET_BREADCRUMB",
         breadCrumbText: "Autoevaluaciones",
         breadCrumbSubText: "Gestionar cuestionarios",
         index: 4,
         path: `${PATH_APP}/questionary-create`,
         textIsSubmit: "CREAR CUESTIONARIO",
       });
    } else {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Gestionar aplicación de cuestionarios",
        index: 5,
        
      });
    }
  }


  return (
    <div >
      <Dialog
       className={classes.widthModalEditQuestionary}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleEditQuestionary}>
          DETALLE CUESTIONARIO
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           < CreateQuestionary showDetail={true} dataDetail={props?.dataDetail} detailAplication={props?.detailAplication}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display:'flex', justifyContent: 'center'}}>
        <Button variant="outlined"  
          disableElevation 
          onClick={handleCloseModalCreate} 
          style={{color: "#6D9FFF", marginBottom: 5, borderColor: '#80808070'}}
        >
            VOLVER
        </Button>
          &nbsp;&nbsp;
        </DialogActions>
      </Dialog>
    </div>
  );
}
