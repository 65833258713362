import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Tooltip, IconButton } from "@mui/material";
import { styles } from "./styles";

export default function AlertDialog({open, children, handleClose}) {

    const classes = styles();

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.wrapper}
      >
        <DialogTitle id="alert-dialog-title" className={classes.title}>
            Organigrama 
        </DialogTitle>
        <DialogContent className={classes.content}>
            {children}
        </DialogContent>
        <DialogActions>
            <Tooltip title="Salir" placement='top' arrow>
                <IconButton onClick={handleClose}>
                    <ExitToAppIcon color="action"/>
                </IconButton>
            </Tooltip>
        </DialogActions>
      </Dialog>
  );
}