import React from "react";
import { useStateValue } from "../../../context/store";
import { Route, Redirect } from "react-router-dom";

function SecureRoute({ allPaths, code, component: Component, ...rest }) {

  const [{ sessionUser }] = useStateValue();
  const state = sessionUser || JSON.parse(localStorage.getItem("session"));

const findCodePermissions =   state?.permissions?.find(x => x === code)

  return (
    <Route
      {...rest}
      render={(props) => {
        
          if(sessionUser?.authenticated && !findCodePermissions  ){
            return <Redirect to="/pages/forbidden" />
          }
          if (state?.authenticated && state?.permissions?.length) {
            return <Component {...props} {...rest} />;
          } else if (!state?.authenticated) {
            return <Redirect to="/auth/login" />
        

          }else{
          return <Redirect to="/pages/notFound" />
          }
        
      }
      }
    />
  );
}


export default SecureRoute;
