import React, {useState, useEffect} from "react";
import { makeStyles } from "@mui/styles";
import { Button, Modal } from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import SearchCompetencies from "../../../competencies/search"
import { useStateValue } from "../../../../context/store";
import ModalConfirm from "../../../../utils/form/modalConfirmBase"
import ListCompetencies from "./listCompetencies"
import { getCompetencies as getCompetenciesService } from '../../../../actions/Competencies-actions';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: '90%',
    height: 490,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.24)",
    outline: "none",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
  },
  cancel1: { 
     color: "#2F71CB", 
     marginBottom: 5, 
     borderColor: '#80808070',
     "&:hover": {
      borderColor: '#80808070',
    },
  },
  cancel: { 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5, 
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
 },
  text: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#4B4B4B",
    fontWeight: 400,
    textAlign: "center",
    margin: "25px 20px",
  },
  titleAlert: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#2C2C2C",
  },
  widthIcon: {
    fontSize: 70,
    color: '#2F71CB',
    marginTop: '3%'

  },
  confirmBanck :{ 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5,
    // width: 'fit-content' ,
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
  }
}));

const ModalConfirmBase = ({
  description,
  open,
  handleClick,
  handleClickOut,
  title,
  oneButtons,
  textButtonSubmit,
}) => {
  const classes = useStyles();
  const [{competencieSearch, relations, loadingTables}, dispatch] = useStateValue()
  const [modalStyle] = React.useState(getModalStyle);
  const [clearFielForm, setClearFielForm]= useState(false)
  const [selectedCompetencies, setSelectedCompetencies] = useState([])
  const [data, setData] = useState({
    code : null,
    competencie: null,
    status: null
  })
  const [paginadorResponse, setPaginadorResponse] = useState({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  const [paginadorRequest, setPaginadorRequest] = useState({
    page: 0,
    size: 10,
  });
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
  })

  useEffect( () => {
    if(open){
        getAllCompetencies();
    }
  },[paginadorRequest])



  const closeModalAndClear = () => setClearFielForm(!clearFielForm)
  const closeModal = () => {setMessage({...message, open:false}); closeModalAndClear()}
  const dataFilter = () => setPaginadorResponse({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });

 
  const getAllCompetencies = async() => {

    const result = await getCompetenciesService({
        code : data.code === null ? null : data?.code,
        name: data.name === null ? null : data?.name,
        status: "ACTIVE",
        page: paginadorRequest?.page === null ? 0 : paginadorRequest?.page,
        size:  paginadorRequest?.size === null ? 10 : paginadorRequest?.size,
        
    },
    {orderBy: 'createdAt'},
    dispatch
    );

   if(result?.status === 204 && !competencieSearch?.typeAction){
    setMessage({
      open : true,
      description: 'No se encontró información relacionada a la búsqueda',
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
   }
   if(result?.data?.content?.length < 1 && !competencieSearch?.typeAction){
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
    return setMessage({
      open : true,
      description: 'No se encontró información relacionada a la búsqueda',
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
   }
  if ((result && result?.status === 200) || result?.status === 201) {
    const data = result?.data;
    if (data?.content?.length > 0) {
      setPaginadorResponse({
        data: data?.content,
        totalRows: data?.totalElements,
        pageSize: data?.size,
        pageNumber: data?.number,
      });
    }
    
  } else {
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
  }
  }

  const addCompetencies = ()=> {
    const newData = []
    const newSections = [];
    if(relations?.dataSections?.length && relations?.sectionSelected){
    
      let findSection = relations?.dataSections.find((x)=> x?.uuid === relations?.sectionSelected)
      const differentsSesion = relations?.dataSections.filter((x)=> x?.uuid !== relations?.sectionSelected)
    
      for (let index = 0; index < selectedCompetencies?.length; index++) {

        const validateCompetencie =  relations?.data?.find((f)=> f?.uuid === selectedCompetencies[index]?.uuid )

        if(validateCompetencie ) {
          newData.push(validateCompetencie)
          continue
        } else {
          // busco por nivel de dominio 1 para pasarle los comportamientos "aspectos" de ese nivel de dominio
          const getBehaviors = selectedCompetencies[index]?.domainLevelList.find(x => x.number === 1)?.conductList
          newData.push({...selectedCompetencies[index], domainLevel: 1, behaviors: getBehaviors, section: relations?.sectionSelected, into: true, position: index })
          newSections.push({...selectedCompetencies[index], section: relations?.sectionSelected, into: true, position: index })
        }

      }
      const newSection = {...findSection, competences: newSections}
      differentsSesion.push(newSection)
    dispatch({
      type: 'SET_RELATIONS_EVALUATION',
      data: newData,
      dataSections: differentsSesion,
      sectionSelected: relations?.sectionSelected,
      behaviorSelected: relations?.behaviorSelected,
      competencieSelected: relations?.competencieSelected,
      selectedQuestions: relations?.selectedQuestions
  })
    } else {
      let copyRelations = [...relations?.data]
      for (let index = 0; index < selectedCompetencies?.length; index++) {
        const validateCompetencie =  copyRelations.find((f)=> f?.uuid === selectedCompetencies[index]?.uuid )
       if(validateCompetencie) {
         newData.push(validateCompetencie)
         continue
       } else {
        // busco por nivel de dominio 1 para pasarle los comportamientos "aspectos" de ese nivel de dominio
        const getBehaviors = selectedCompetencies[index]?.domainLevelList.find(x => x.number === 1)?.conductList
        //agregamos la posición a cada comportamiento "aspecto"
        for (let index = 0; index < getBehaviors?.length; index++) {
          getBehaviors[index].position = index
        }
        newData.push({...selectedCompetencies[index], domainLevel: 1, behaviors: getBehaviors, into: false})
       }
        
      }
      dispatch({
        type: 'SET_RELATIONS_EVALUATION',
        data: newData,
        dataSections: relations?.dataSections,
        sectionSelected: relations?.sectionSelected,
        behaviorSelected: relations?.behaviorSelected,
        competencieSelected: relations?.competencieSelected,
        selectedQuestions: relations?.selectedQuestions
    })
    }
   
    handleClick()
    
  }


  useEffect(()=> {
    if(competencieSearch &&
      competencieSearch?.isFilter) {
        const newData = competencieSearch?.filter
        data.code = newData?.code;
        data.name = newData?.competencie
        data.status = 'ACTIVE';
        paginadorRequest.page = newData?.page;
        paginadorRequest.size = newData?.size
        dispatch({
          type: "SET_FILTER_COMPETENCIE",
          filter: competencieSearch?.filter,
          isFilter: false,
        })
        getAllCompetencies()
    }
  },[competencieSearch?.isFilter])

  useEffect(() => {
    setSelectedCompetencies([])
  }, [])
  

  const getCompetencies = (x) => setSelectedCompetencies(x) 

  const getAllActive = () => {
    return dispatch({
      type: "SET_FILTER_COMPETENCIE",
      filter: {
          competencie: null ,
          code: null,
          status: 'ACTIVE',
          page: 0,
          size: 10
        },
        isFilter: true,
  })
  }

  const changePage = (event, nuevaPagina) => {
       
    setPaginadorRequest({
      ...paginadorRequest,
      page: nuevaPagina,
    });
    dispatch({
      type: "SET_FILTER_COMPETENCIE",
      filter: {
          competencie: competencieSearch?.filter?.competencie ,
          code: competencieSearch?.filter?.code,
          status:  "ACTIVE",
          page: nuevaPagina,
          size: competencieSearch?.filter?.size
        },
        isFilter: false,
  })
  };

  const changeAmountOfRecords = (event, siguiente) => {
    setPaginadorRequest({
      ...paginadorRequest,
      size: event.target.value,
      page: 0
    });
    dispatch({
      type: "SET_FILTER_COMPETENCIE",
      filter: {
          competencie: competencieSearch?.filter?.competencie ,
          code: competencieSearch?.filter?.code,
          status:  "ACTIVE",
          page: 0,
          size: event.target.value
        },
        isFilter: false,
    })
  };

  const bodyModal = (
    <div style={modalStyle} className={classes.paper}>
      <SearchCompetencies 
         dataFilter={dataFilter}
         clearFielForm={clearFielForm}
         modalGetAll={true}
         getAllCompetencies={getAllActive}
      />
       <ListCompetencies data={[...paginadorResponse?.data]} getCompetencies={getCompetencies}/>
       {paginadorResponse?.totalRows !== 0 && !loadingTables?.active &&
        <TablePagination
            style={{ display:"flex", color: '#747578', justifyContent: "center" }}
            component="div"
            rowsPerPageOptions={[5, 10, 15]}
            count={paginadorResponse.totalRows}
            rowsPerPage={competencieSearch?.filter?.size}
            page={competencieSearch?.filter?.page}
            labelRowsPerPage='Registros por página'
            onPageChange={changePage}
            onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
        />
      }
      <div style={{ display: "flex", justifyContent: "center", alignItems: 'baseline', flexDirection: null}}>
        <React.Fragment >
          <Button
            className={ classes.cancel1 }
            variant="outlined"
            onClick={handleClick}
          >
            CANCELAR
          </Button>

          <Button
            type="submit"
            variant="contained"
            onClick={()=> addCompetencies()}
            style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 5, marginTop: 20 }}
            >
                ASOCIAR
          </Button>
        </React.Fragment>
      </div>
    </div>
  );

  
  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
    </div>
  );
};

export default ModalConfirmBase;
