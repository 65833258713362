import React, {useState, useEffect} from 'react';
import { useStateValue } from '../../../../context/store';
import FilterLeader from "./search";
import { getRegionals, getPositions } from '../../../../actions/OrganizationChart-action';



const Index = (props) => {

    const [, dispatch] = useStateValue();
    const [allRegionals, setAllRegionals] = useState([]);
    const [allPositions, setAllPositions] = useState([]);

    useEffect(()=> {
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Definición de organigrama",
          index: 14,
        });
        getRegional();
        getPosition();
      },[])

  useEffect(()=> {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Definición de organigrama",
      index: 14,
    });
  },[])




      const getRegional = async() => {
        const result = await getRegionals();  
        setAllRegionals(result?.data?.length ? result?.data : [] )
      }

      const getPosition = async() => {
        const result = await getPositions();  
        setAllPositions(result?.data?.length ? result?.data : [] )
      }

   
  return (
    <>
      < FilterLeader 
        clearFielForm={props?.clearFielForm} 
        setMessage={props?.setMessage} 
        message={props?.message}
        regionals={allRegionals} 
        positions={allPositions}
      /> 
    </>
  )
}

export default Index