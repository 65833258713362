import React, {useEffect, useState} from 'react'
import { useStateValue } from '../../../context/store'
import { getByIdEvaluation } from "../../../actions/Performance_Evaluation-action"
import Header from "./header";
import Evaluation from "./evaluation";
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import { PATH_APP } from '../../../constants';

const Index = (props) => {
    const {id} = props?.match?.params
    const idEvaluation = id
    const [, dispatch] = useStateValue()
    const [dataDetail, setDataDetail] = useState({});
    const [message, setMessage] = useState({
        description : '',
        textButtonSubmit : '',
        handleClick : () => {} ,
        handleClickOut : () => {},
        oneButtons : false,
        title: '',
        list: [],
        type : '',
        open : false
      })


    useEffect(() => {
        dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Gestionar modelo de evaluación",
            index: 12,
            module: 'EVALUATION_EVALUATOR',
            subPath: `${PATH_APP}/evaluation-performance-evaluator`,
          });
          window.scrollTo(0,0)
          getEvaluation()
    }, [])
    

    const closeModal = () => setMessage({...message, open: false})
    const getEvaluation = async() => {
        const result = await getByIdEvaluation(idEvaluation, dispatch)
        if(result?.data?.uuid ){
          setDataDetail(result?.data)
        }else if(result?.status > 399 && result?.status < 405  ){
          setMessage({
            open : true,
            description: 'No se encontró información relacionada a la búsqueda',
            handleClick : () => closeModal() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
        }else if(result?.status > 399 && result?.status >= 500  ){
          setMessage({
            open : true,
            description: 'Occurió un problema comuniquese con el administrador.',
            handleClick : () => closeModal() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
        }else {
          return setMessage({
            open : true,
            description: 'Occurió un problema comuniquese con el administrador.',
            handleClick : () => closeModal() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
        }
  
    }
   

    
    return (
        <React.Fragment>
            {dataDetail?.name ? 
            <>
            <Header id={idEvaluation} dataDetail={dataDetail} message={message} setMessage={setMessage} props={props}/>
            <Evaluation dataDetail={dataDetail}/>
            </>
            : null 
            }
            <ModalConfirm 
                description = { message.description }
                textButtonSubmit = { message.textButtonSubmit }
                handleClick = { message.handleClick }
                handleClickOut = { message.handleClickOut }
                oneButtons = { message.oneButtons }
                title = { message.title }
                list = {message.list}
                type = { message.type }
                open = { message.open }
            />
            
        </React.Fragment>
        )
}

export default Index