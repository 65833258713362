import {  makeStyles } from "@mui/styles";

export const styles = makeStyles((theme) => ({
   key: {
    fontWeight: 700,
    fontSize: 12,
    fontFamily: 'Roboto',
    color: '#2C2C2C',
    minWidth: 'fit-content'
   },
   value: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#8E8E8E'
   },
   dropdown: {
      backgroundColor: "#ffffff",
      boxSizing: "border-box",
      borderRadius: "8px",
      fontSize: "14px",
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#ffffff',
      },
    },
  }));