import { makeStyles } from "@mui/styles";

export const styles = makeStyles((theme) => ({
    MuiIconButtonEdgeEnd: {
      order: "-1",
    },
    root: {
      [theme.breakpoints.down('md')]: {
          flex: "1 1 auto",
          margin: "25% 2% 0% 2%",
          minHeight: "80vh",
        },
        [theme.breakpoints.up('md')]: {
          width: "100%",
        },
        [theme.breakpoints.up('lg')]: {
          width: "100%",
          margin: '10px 0% 0.5% 0%'
        },
    },
    rootGrid: {
      flexGrow: 1,
    },
    heading: {
      fontSize: theme.typography.pxToRem(12),
      color: '#8E8E8E',
      wordBreak: 'break-word'
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: "bottom",
      height: 20,
      width: 20,
    },
    iconEvent: {
      color: "#646464",
      cursor: "pointer",
      height: 18,
      width: 18,
      pointerEvents: 'auto',
      '&:hover':{
        color: "#2F71CB",
      }
    },
    column: {
      flexBasis: "24.2%",
      padding: '1.5% 0px',
      background: '#FFFFFF', 
      textAlign: 'center'
    },
    columnDescription: {
      flexBasis: "50.6%",
      padding: '1.5% 0px',
      background: '#FFFFFF', 
      textAlign: 'center'
    },
    columnDescriptionStatus: {
      flexBasis: "50.6%",
      padding: '1.5% 0px',
      background: '#FFFFFF', 
      textAlign: 'end'
    },
    columnOneTitle:{
      padding: '1% 0px',
      background: '#FFFFFF',
    },
    columnEvent: {
      paddingLeft: "20px",
      flexBasis: "33.33%",
    },
    titlesTable: {
      fontSize: 12, 
      display: 'flex', 
      color: '#8E8E8E', 
      width: '100%', 
      // marginLeft: '5.5%', 
      position:'relative', 
      marginBottom: -8,
      alignItems: 'baseline'
    },
    buttons: {
      margin:'0%',
      display: 'flex',
      // justifyContent: 'center',
      background: '#ffffff',
      paddingBottom: '2%',
      paddingTop: '2%'
    },
    checkboxDisabled: {
      padding: '0px !important',
    },
    checkbox: {
      color: 'gray ',
      padding: '12px !important',
      '& .MuiCheckbox-root':{
        color: '#1976d2'
      }
    }
  }));