export const initialState = {
    automatic: null,
    selectAll: false,
    applicationTypes: [],
    submitPercentage: false,
    users: [],
    dataEvaluation: {},
    pages: [],
    selectNewLeaders: []
  };
  
  const typesEvaluationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_TYPES_EVALUATIONS":
        return {
          ...state,
          automatic: action.automatic,
          selectAll: action.selectAll,
          applicationTypes: action.applicationTypes,
          submitPercentage: action.submitPercentage,
          users: action.users,
          dataEvaluation: action.dataEvaluation,
          pages: action.pages,
          selectNewLeaders: action.selectNewLeaders
        };
      default:
        return state;
    }
  };
  
  export default typesEvaluationsReducer;
  