import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
    fieldDateTimeErrorSearch: {
        background: '#ffffff',
        borderRadius: 20,
        '& .css-ly45cz-MuiInputBase-root-MuiOutlinedInput-root':{
          background: '#dfe7fd',
        },
        '& .MuiOutlinedInput-notchedOutline':{
        borderRadius: 15,
  
        },
        '& .css-i8lz65':{
          background: '#ffffff',
          borderRadius: 20
        },
        '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':{
          background: '#ffffff',
          color: '#545353',
          borderRadius: 20,
          fontSize: 14
          
        }
      },
    dropdownNameCuestionary: {
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "14px",
    '& ::placeholder': {
        fontSize: 14
    },
    [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#dfe7fd',
    },

    },
    dropdown: {
      backgroundColor: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "8px",
      // height: 40,
      fontSize: "14px",
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#dfe7fd',
      },
      '& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
        zIndex: '0 !important'
      }
      
    },
})


