import React, {useState, useEffect} from 'react';
import { 
    Grid,
    FormControl,
    TextField,
    Container,
    Typography
} from "@mui/material";
import { useStateValue } from "../../../../../context/store";
import {styles} from './styles'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Index = (props) => {
    const classes = styles();
    const [characters, updateCharacters] = useState(props?.valuesOptions);
    const [validate, setValidate] = useState(false)
    const [{valorationQuestionnaire, valorateAplication, disabledQuestionary }, dispatch ] = useStateValue();
    const [valueQuestion, setValueQuestion] = useState()

   
    function handleOnDragEnd(result) {

        if (!result.destination) return;
        const items = Array.from(characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updateCharacters(items);
        const findUuid= valorateAplication?.data.find((x)=> x?.uuid === `${props?.uuid}`)
        const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)

          const move = []
          for (let index = 0; index < characters?.length; index++) {
            move.push(
                {
                  uuid: items[index]?.uuid, 
                  answer:  index+1 ,
                }
              )
          
           }
           dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList: move,
              }
            ] 
              : [{
                uuid: `${props?.uuid}`, 
                questionAlternativeList: move,
              }],
          })
      }

      const schema = yup.object().shape({
     
        valueTotalQuestion: yup
        .string()
        .required('Requerido')
        .matches(
          /^[0-9]{1,2}(\.[0-9]{0,2})?$/g,
          "Ejm: 10.30"
        )
    })

      const { register, handleSubmit, control, trigger, unregister, clearErrors, formState:{errors}, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onTouched",
        reValidateMode: "onChange",
      });

      const handleOnchange = (e)=> {
        const {name, value} = e.target;
       
        setValue(name, value)
        setValueQuestion(value)
        trigger(name)
  
        const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)
        const newAnswers = []
          for (let index = 0; index < characters.length; index++) {
                newAnswers.push({uuid: characters[index]?.uuid, valueApplied: Number(value)})
            }
           
        
            filterUuid.push({uuid: `${props?.uuid}` , questionAlternativeList: newAnswers, valueApplied: Number(value) })
           
            dispatch({
              type: "SET_MODAL_VALORATE_APLICATION",
              icon:'WARNING',
              data: filterUuid
            })
      }

      useEffect(()=> {
        setValue('valueTotalQuestion', Number((valorationQuestionnaire?.totalValueQuestionnaire)) ? Number((valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
        setValueQuestion(Number((valorationQuestionnaire?.totalValueQuestionnaire)) ? Number((valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
        if(valorationQuestionnaire?.typeValoration === 'automatic'){
        dispatch({
          type: "SET_VALUE_QUESTIONNAIRE",
          totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
          typeValoration: 'automatic',
          activeValoration: false
        });
        const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)
       
        const newAnswers = []
          for (let index = 0; index < characters.length; index++) {
                newAnswers.push({uuid: characters[index]?.uuid, valueApplied: valorationQuestionnaire?.totalValueQuestionnaire})
            }
           
            filterUuid.push({uuid: `${props?.uuid}` , questionAlternativeList: newAnswers, valueApplied: valorationQuestionnaire?.totalValueQuestionnaire })
           
            dispatch({
              type: "SET_MODAL_VALORATE_APLICATION",
              icon:'WARNING',
              data: filterUuid
            })
        }else {
          dispatch({
            type: "SET_VALUE_QUESTIONNAIRE",
            totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
            typeValoration: 'manual',
            activeValoration: false
          });
        }
      
      },[valorationQuestionnaire?.activeValoration ])

      const onSubmit= () => {
        dispatch({
              type: "SET_MODAL_VALORATE_APLICATION",
              activeValorate: true,
              dataClear: valorateAplication?.data ?  valorateAplication?.data :[],
              data: valorateAplication?.data ?  valorateAplication?.data :[]
            })
      }



    return  <form id="formAplication" onSubmit={handleSubmit(onSubmit)}>
   <Grid container spacing={1}  maxWidth="lg" justifyContent="center" alignItems="center" style={{ flexDirection: 'column', alignItems: 'inherit', marginLeft: "4%" }} >
     <Grid item lg={10} style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{display: 'flex', alignItems: "center", marginBottom: 15}}>
              <Grid item lg={0.4} style={{color: '#6E6E6E'}}>{props?.position}</Grid>
              <Grid item lg={11.6}>
                <Typography className={classes.question}>{props?.question}</Typography>
              </Grid>
          </div> 
          <div>
          <Grid item lg={1} md={2} style={{display: 'flex', marginLeft: '3.6%', marginBottom: 15}}>
            <FormControl variant='outlined'  size="small">
              <TextField
              {...register('valueTotalQuestion')}
                className={classes.dropdown}
                
                size="small"
                placeholder='Valor'
                disabled={true}
                onChange={(e)=>handleOnchange(e)}
                error={!!errors.hasOwnProperty("valueTotalQuestion") && errors["valueTotalQuestion"].message}
                value={ props?.valueQuestion }
              />
            </FormControl>
          </Grid>
          </div>
        </Grid>
       <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters" >
            {(provided) => (
              <ul className="characters" {...provided.droppableProps} ref={provided.innerRef} style={{flex: 'auto', width: 'inherit', marginLeft: '' }}>
                {characters.map(({key, value}, index, idRank) => {
                  
                  return (
                    <Draggable key={key} draggableId={ props?.isDetail ? 0 : key } index={index} >
                      {(provided) => (
                      <div style={{marginLeft: index+1 === 10 ? -15 :-5, display: 'flex', alignItems: 'baseline'}}>
                            <p style={{color: '#bdbdbd'}}>{index+1}</p>
                         <Container ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Grid item lg={5} style={{display: 'flex', marginBottom: '2%', border: '1px solid #bdbdbd', background: '#ffffff', borderRadius: 10}} >
                              <FormControl variant='outlined' fullWidth size="small">
                              <Typography  style={{padding: '10px 50px', color: '#c5bfbf'}}>{key}</Typography >
                              </FormControl>
                          </Grid> 
                        </Container>
                       
                       </div >
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
     </Grid>
     </form>
};

export default Index;
