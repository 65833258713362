import { makeStyles } from '@mui/styles';

export const styles = makeStyles((theme) => ({
    MuiIconButtonEdgeEnd: {
      order: "-1",
    },
    root: {
      [theme.breakpoints.down('md')]: {
          flex: "1 1 auto",
          margin: "25% 2% 0% 2%",
          minHeight: "80vh",
        },
        [theme.breakpoints.up('md')]: {
          width: "100%",
        },
        [theme.breakpoints.up('lg')]: {
          width: "100%",
          margin: '25px 0% auto 0%'
        },
    },
    rootGrid: {
      flexGrow: 1,
    },
    heading: {
      fontSize: theme.typography.pxToRem(14),
      color: '#8E8E8E',
      wordBreak: 'break-word'
    },
    headingTitle: {
      fontSize: 12,
      fontWeight: 700,
      color: '#8E8E8E',
      wordBreak: 'break-word',
      margin: 'auto'
    },
    headingDate: {
      fontSize: theme.typography.pxToRem(14),
      color: '#8E8E8E',
      wordBreak: 'break-word',
      marginLeft: 8
    },
    headingScreenplay: {
      fontSize: theme.typography.pxToRem(14),
      color: '#8E8E8E',
      wordBreak: 'break-word',
      fontWeight: 700
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: "bottom",
      height: 20,
      width: 20,
    },
    iconEvent: {
      color: "#646464",
      cursor: "pointer",
      height: 18,
      width: 18,
      pointerEvents: 'auto',
      '&:hover':{
        color: "#2F71CB",
      }
    },
    column: {
      flexBasis: "24.2%",
      padding: '1.5% 0px',
      background: '#FFFFFF', 
      textAlign: 'center'
    },
    columnDescription: {
      padding: '1.5% 10px',
      background: '#FFFFFF', 
      textAlign: 'initial'
    },
    columnDate: {
      padding: '1.5% 20px',
      background: '#FFFFFF', 
      textAlign: 'start'
    },
    columnDescriptionStatus: {
      flexBasis: "50.6%",
      padding: '1.5% 0px',
      background: '#FFFFFF', 
      textAlign: 'center'
    },
    columnOneTitle:{
      flexBasis: "33.6%",
      padding: '1.5% 0px',
      background: '#FFFFFF',
      display: 'flex', 
      justifyContent: 'space-evenly',
      color: '#8E8E8E', 
      fontWeight: 400,
      fontSize: 14
    },
    columnEvent: {
      paddingLeft: "20px",
      flexBasis: "33.33%",
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    titlesTable: {
      fontSize: 12, 
      display: 'flex', 
      color: '#8E8E8E', 
      width: '100%', 
      marginLeft: '2%', 
      position:'relative', 
      marginBottom: -8
    },
    expandText :{ 
      textDecoration: 'underline',
      cursor: 'pointer',
      marginLeft: 5,
      color: '#3300ff'
     },
     columnEventDetail: {
      paddingLeft: '1%',
      flexBasis: "33.33%",
    },
    columnEventDetailIndex: {
      paddingLeft: '1%',
      flexBasis: "33.33%",
    },
    header: {
      color: "#8E8E8E",
      fontSize: 14,
    },
    wrapper: {
      color: "#8E8E8E",
      fontSize: 14,
      fontWeight: 700,
    },
    table: {
       "& MuiTable-root": {
         backgroundColor: 'inherit'
       },
      "& .MuiTableCell-root": {
        backgroundColor: 'inherit',
        borderBottom: 'inherit'
      },
      "& .MuiTableCell-head": {
        paddingBottom: 0,
      },
      "& .MuiTableBody-root": {
        backgroundColor: 'white',
        borderRadius: '50rem !important'
  
      },
    },
  }));

