export const initialState = {
    filter: {
        createdAtStartDate: null,
        createdAtEndDate: null,
        evaluationNameKeyword: null,
        page: null,
        size: null
    },
    isFilter: false,
    typeAction: null
  };
  
  const managementAplicationEvaluationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_FILTER_MANAGEMENT_APLICATION_EVALUATION":
        return {
          ...state,
          filter: action.filter,
          isFilter: action.isFilter,
          typeAction: action.typeAction,
          page: action.page,
          size: action.size
        };
      default:
        return state;
    }
  };
  
  export default managementAplicationEvaluationsReducer;
  