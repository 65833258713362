import HttpClient from '../services/HttpClient';
import {  LABEL, QUESTIONARY, MULTIPLE, TYPE, STATUS } from '../constants'


export const getTypesQuestionnaire = () => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${QUESTIONARY}${TYPE}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getStatusQuestionnaire = () => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${STATUS}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const createQuestionnaire = (body, dispatch) => {
    return new Promise((resolve, reject) => {
        HttpClient.post(`${QUESTIONARY}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    });
}

export const createLabel = (body, dispatch) => {
    return new Promise((resolve, reject) => {
        HttpClient.post(`${LABEL}${MULTIPLE}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    });
}

export const updateQuestionary = (id,body) => {
   
    return new Promise((resolve, reject) => {
        HttpClient.patch(`${QUESTIONARY}/${id}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response)
        })
    })
}

export const deleteQuestionary = (id, dispatch) => {
    return new Promise((resolve, reject) => {
      HttpClient.delete(`${QUESTIONARY}/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve(error.response);
        });
    });
  };

export const getfilterQuestionarys = (dataFilter) => {
    return new Promise((resolve, reject) => {

        let filters = '';
        if (dataFilter?.status && dataFilter?.nameKeyword && dataFilter?.createdAtStartDate && dataFilter?.createdAtEndDate) {
            filters = `?createdAtStartDate=${dataFilter.createdAtStartDate}&createdAtEndDate=${dataFilter.createdAtEndDate}&nameKeyword=${encodeURIComponent(dataFilter?.nameKeyword)}&status=${dataFilter.status}` ;
        }
        else if (dataFilter?.createdAtStartDate && dataFilter?.createdAtEndDate && dataFilter?.status) {
            filters = `?createdAtStartDate=${dataFilter?.createdAtStartDate}&createdAtEndDate=${dataFilter.createdAtEndDate}&status=${dataFilter.status}`
        }

        else if (dataFilter?.createdAtStartDate && dataFilter?.createdAtEndDate && dataFilter?.nameKeyword) {
            filters = `?createdAtStartDate=${dataFilter?.createdAtStartDate}&createdAtEndDate=${dataFilter.createdAtEndDate}&nameKeyword=${encodeURIComponent(dataFilter?.nameKeyword)}`
        }
        else if (dataFilter?.createdAtEndDate && dataFilter?.nameKeyword && dataFilter?.status ) {
            filters = `?createdAtEndDate=${dataFilter?.createdAtEndDate}&nameKeyword=${encodeURIComponent(dataFilter?.nameKeyword)}&status=${dataFilter.status}`
        }
        else if (dataFilter?.createdAtStartDate && dataFilter?.nameKeyword && dataFilter?.status ) {
            filters = `?createdAtStartDate=${dataFilter?.createdAtStartDate}&nameKeyword=${encodeURIComponent(dataFilter?.nameKeyword)}&status=${dataFilter.status}`
        }
        else if (dataFilter?.createdAtStartDate && dataFilter?.createdAtEndDate ) {
            filters = `?createdAtStartDate=${dataFilter?.createdAtStartDate}&createdAtEndDate=${dataFilter.createdAtEndDate}`
        }
        else if (dataFilter?.createdAtStartDate && dataFilter?.nameKeyword ) {
            filters = `?createdAtStartDate=${dataFilter?.createdAtStartDate}&nameKeyword=${encodeURIComponent(dataFilter?.nameKeyword)}`
        }
        else if (dataFilter?.createdAtStartDate && dataFilter?.status ) {
            filters = `?createdAtStartDate=${dataFilter?.createdAtStartDate}&status=${dataFilter.status}`
        }
        else if (dataFilter?.createdAtEndDate && dataFilter?.nameKeyword && dataFilter?.status ) {
            filters = `?createdAtEndDate=${dataFilter?.createdAtEndDate}&nameKeyword=${encodeURIComponent(dataFilter?.nameKeyword)}&status=${dataFilter.status}`
        }
        else if (dataFilter?.createdAtEndDate && dataFilter?.nameKeyword ) {
            filters = `?createdAtEndDate=${dataFilter?.createdAtEndDate}&nameKeyword=${encodeURIComponent(dataFilter?.nameKeyword)}`
        }
        else if (dataFilter?.createdAtEndDate && dataFilter?.status ) {
            filters = `?createdAtEndDate=${dataFilter?.createdAtEndDate}&status=${dataFilter.status}`
        }

        else if (dataFilter?.nameKeyword && dataFilter?.status ) {
            filters = `?nameKeyword=${encodeURIComponent(dataFilter?.nameKeyword)}&status=${dataFilter.status}`;
        }  

        else if (dataFilter?.createdAtStartDate ) {
            filters = `?createdAtStartDate=${dataFilter?.createdAtStartDate}`
        }
        else if (dataFilter?.createdAtEndDate ) {
            filters = `?createdAtEndDate=${dataFilter?.createdAtEndDate}`;
        } 
        else if (dataFilter?.nameKeyword ) {
            filters = `?nameKeyword=${encodeURIComponent(dataFilter?.nameKeyword)}`;
        }   
        else if (dataFilter?.status ) {
            filters = `?status=${dataFilter?.status}`;
        }
        else{
            filters= null
        }
        HttpClient.get(`${QUESTIONARY}/${filters}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}