import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField, 
     IconButton,
     Box,
     Autocomplete,
     FormControl,
     Tooltip,
    OutlinedInput,
    InputAdornment
} from '@mui/material'
import { useStateValue } from "../../../../../../../context/store";
import CloseIcon from '@mui/icons-material/Close';
import {styles} from './styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AsyncPaginate } from "react-select-async-paginate";
import { useForm } from "react-hook-form";
import {getEmployeesSelect } from '../../../../../../../actions/OrganizationChart-action';
import Select from "react-select";
import ModalEditLeader from "../../modalEditLeader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SearchCompetencies = (props) => {


    const classes = styles()
    const [{organization, newleader, typesEvaluations}, dispatch] = useStateValue();
    const [notData, setNotData] = useState(false);
    const [page, setPage] = useState(null);
    const [data, setData] = useState({
        idKeyword: null,
        nameKeyword: null,
        positionName: null,
        regionalName: null,
        })
    const [openEdit, setOpenEdit] = useState(
        {
            open: false,
            handleClose: () => {},
        }
    )
   
    const schema = yup.object().shape({

        idKeyword: yup
        .string(),
        nameKeyword: yup
        .string(),
        positionName: yup
        .string(),
        regionalName:   yup
        .string()
        
      });
    
  
      
      const { register, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });
      

      useEffect(() => {
        setNotData(false);
      }, [notData])
      
    useEffect(() => {
        clear()
    }, [props?.clearFielForm])
    


    const clear = () => {
        setNotData(!notData)
        setPage(null);
        setValue('idKeyword', '')
     setData(
        {
            idKeyword: null,
            nameKeyword: null,
            positionName: null,
            regionalName: null, 
        }
        )
    }

    const loadOptions = async (pag) => {

        const result = await getEmployeesSelect({
            identificationKeyword : data?.idKeyword === null ? null : data?.idKeyword,
            nameKeyword: data?.nameKeyword === null ? null : data?.nameKeyword,
            positionName: data?.positionName === null ? null : data?.positionName?.name,
            regionalName: data?.regionalName === null ? null : data?.regionalName?.name,
            page: !page ? 0 : page,
            size:  20 === null ? null : 20,
            
        },
        dispatch,
        );

        if(result?.status === 204 ){
                setPage(null);
                 return {
                    options: [],
                    hasMore: false,
                };
        } if(result?.status > 204 && result?.status <= 401 ){
            setPage(null);
             return {
                options: [],
                hasMore: false,
            };
    }else {

            setPage(page+1)

            let newData = [...result?.data?.content];

            const findEvaluated = typesEvaluations?.users?.find((x)=> x?.uuid === props?.currentLeader?.uuid )
             newData = newData?.filter((x)=> x?.uuid !== props?.currentLeader?.leader?.uuid);
             newData = newData?.filter((x)=> x?.uuid !== props?.currentLeader?.uuid);
             // busco el tipo de evaluacion
             const typePeer = typesEvaluations?.applicationTypes?.find((x)=> x?.type === 'PEER_EVALUATION');
             //busco el evaluado y su par
             const findPeerUser = typePeer?.employees?.find((x)=> x?.evaluated?.uuid === props?.currentLeader?.uuid)
             // busco el evaluador y lo quito de la lista
             newData = newData?.filter((x)=> x?.uuid !== findPeerUser?.evaluator?.uuid);

            for (let index = 0; index < findEvaluated?.newLeaders?.length; index++) {
               newData = newData?.filter((x)=> x?.uuid !== findEvaluated?.newLeaders[index]?.uuid)
            }

            const dataOptions = newData?.map((
                { 
                    uuid, 
                    name, 
                    secondName, 
                    surname, 
                    secondSurname, 
                    subalterns, 
                    identificationNumber, 
                    position,
                    regional,
                },x) => (
                {
                    label: `${name ? name : ''} ${secondName ? secondName : ''} ${surname ? surname :''} ${secondSurname ? secondSurname :''}`,
                    value: uuid,
                    subalterns: subalterns?.length ? subalterns : [],
                    identificationNumber: identificationNumber,
                    name: name, 
                    position: position,
                    regional: regional,
                    uuid: uuid,
                    secondSurname: secondSurname,
                    surname: surname
                }
                ));

            return {
                options: dataOptions,
                hasMore: result?.data?.content?.length > 1 ? true : false,
            };
        }
               
                
      };


        const blur = (e) => {
            const {value} = e.target;
            setNotData(true)
            setPage(null)
            setData({...data, idKeyword: value, })
            dispatch({
                type: 'SET_FILTER_NEW_LEADER',
                idKeyword: newleader?.idKeyword,
                nameKeyword: newleader?.nameKeyword,
                positionName: newleader?.positionName,
                regionalName: newleader?.regionalName,
                leaderId: newleader?.value,
                subalterns: newleader?.subalterns,
                editLeader: newleader?.editLeader
            })
        }

        const onchangeSelect = (option) => {
            dispatch({
                type: 'SET_FILTER_NEW_LEADER',
                idKeyword: newleader?.idKeyword,
                nameKeyword: newleader?.nameKeyword,
                positionName: newleader?.positionName,
                regionalName: newleader?.regionalName,
                leaderId: option,
                subalterns: option?.subalterns,
                editLeader: newleader?.editLeader
            })
           
        }


    const openModalEdit = () => {
        setOpenEdit(
            {
                open: !openEdit,
            }
        )
    }       


    return (
         <form  style={{marginTop: '2%'}}>
            <Grid container  spacing={2}>
                <Grid item lg={props?.edit ? 3 : 2.5}>
                <FormControl variant='outlined' fullWidth size="small">
                    <Autocomplete
                        {...register('regionalName')}
                        options={props?.regionals}
                        autoHighlight
                        className={classes.dropdown}
                        size="small"
                        clearText="Limpiar"
                        openText={null}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => {
                            setValue('regionalName', newValue);
                            setNotData(true)
                            setData({...data, regionalName: newValue})
                            setPage(null);
                            dispatch({
                                type: 'SET_FILTER_NEW_LEADER',
                                idKeyword: newleader?.idKeyword,
                                nameKeyword: newleader?.nameKeyword,
                                positionName: newleader?.position,
                                regionalName: newValue?.value,
                                leaderId: newleader?.leaderId,
                                subalterns: newleader?.subalterns,
                                editLeader: newleader?.editLeader
                            })
                        }}
                        value={data?.regionalName}
                        renderOption={(props, option) => (
                        <Box component="li" {...props}>
                                {option.name}
                        </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            {...register('regionalName')}
                            placeholder="Programa"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', 
                            }}
                            />
                        )}
                        
                    />
                </FormControl>
            </Grid>
            <Grid item lg={ props?.edit ? 3 : 3.5}>
                <FormControl variant='outlined' fullWidth size="small">
                    <Autocomplete
                        {...register('positionName')}
                        id="country-select-demo"
                        options={props?.positions}
                        autoHighlight
                        className={classes.dropdown}
                        size="small"
                        getOptionLabel={(option) => option?.name }
                        value={data?.positionName}
                        clearText="Limpiar"
                        openText={null}
                        onChange={(e, newValue) => {
                            setValue('positionName', newValue);
                            setNotData(true)
                            setData({...data, positionName: newValue})
                            setPage(null)
                            dispatch({
                                type: 'SET_FILTER_NEW_LEADER',
                                idKeyword: newleader?.idKeyword,
                                nameKeyword: newleader?.nameKeyword,
                                positionName: newValue?.value,
                                regionalName: newleader?.regionalName,
                                leaderId: newleader?.leaderId,
                                subalterns: newleader?.subalterns,
                                editLeader: newleader?.editLeader
                            })
                          }}
                        renderOption={(props, option) => (
                        <Box component="li" {...props}>
                                {option?.name}
                        </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            {...register('positionName')}
                            placeholder="Cargo"
                            // label="cargo"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            
        <Grid item lg={ 2}>
                <FormControl variant='outlined' fullWidth size="small">
                    {/* <InputLabel htmlFor="outlined-adornment-password" >Password</InputLabel> */}
                        <OutlinedInput
                            {...register('idKeyword')}
                            type='text' 
                            className={classes.dropdown}
                            placeholder="Identificación"
                            clearText="Limpiar"
                            // shrink={watch('idKeyword') ? true : false} 
                            notched={false}
                            onBlur={(e)=>blur(e)}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setValue('idKeyword', '');
                                    setNotData(true)
                                    setData({...data, idKeyword: ''})
                                    setPage(null)
                                }}
                                edge="end"
                                >
                                { watch('idKeyword') ? <CloseIcon style={{fontSize: 17}}/> : null }
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Password"
                        />
                </FormControl>
            </Grid>
        <Grid item lg={props?.edit ? 4 :3}>
            <FormControl variant='outlined' fullWidth size="small">
                {!notData ?  <AsyncPaginate
                                loadOptions={loadOptions}
                                placeholder="Seleccione el líder"
                                onChange={onchangeSelect}
                                cacheUniq={data}
                                onInputChange={(e)=> {setPage(null); setData({...data, nameKeyword: e})} }
                                loadingMessage={() => 'Cargando...'}
                                noOptionsMessage={() => 'No hay coincidencias'}
                            />
                        : 
                            <Select
                                placeholder="Seleccione el Líder"
                                options={[]}
                                isClearable
                            /> 
                            
                }
            </FormControl>
        </Grid>
        { props?.edit ? null :<Grid item lg={1} xs={2} style={{paddingTop: '2%'}}>
            <div className={classes.columnEvent} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
              &nbsp;&nbsp;
              <Tooltip title="Limpiar" arrow >
                  <DeleteIcon
                    onClick={() => clear()}
                    color='action'
                    className={classes.iconEvent}
                  />
                </Tooltip>
              &nbsp;&nbsp;
              {
                organization?.subalterns?.length ?
                <Tooltip title="Editar" arrow >
                    <EditIcon
                    onClick={() => openModalEdit()}
                    color='action'
                    className={classes.iconEvent}
                    />
              </Tooltip>
              : null
              } 
            </div>
          </Grid>
        }          
    </Grid>
        <ModalEditLeader 
            open={openEdit?.open} 
            data={
                {name: organization?.infoLeader, 
                regional: data?.regionalName, 
                userId: data?.idKeyword, 
                position: data?.positionName
                }}
            regionals={props?.regionals} 
            positions={props?.positions}  
            handleClose={openModalEdit} 
        />
         
        </form>
    )
}

export default SearchCompetencies
