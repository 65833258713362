import { makeStyles } from '@mui/styles';

export const styles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
            margin: '0%', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 400
        },
        [theme.breakpoints.up('md')]: {
            margin: '0%', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 400
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0%', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 400
        },
    },
  }));

