import React from 'react';
import { 
  Grid, 
  Button,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {styles} from '../styles';


export default function EditQuestionary(props) {
    const classes = styles();
    
  return (
    <div >
      <Dialog
       className={classes.widthModalDetail}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <DialogTitle id="alert-dialog-title" style={{fontSize: 14, textAlign: 'center', color: '#4B4B4B', marginTop: 35}}>
          VER DETALLES
        </DialogTitle>
        <DialogContent style={{margin: '10px 50px'}}>
          <DialogContentText id="alert-dialog-description">
           <Grid container justifyContent="center" alignItems="center">   
          
             <Grid item lg={4} style={{textAlign: 'center'}}><Typography style={{marginBottom: 26, fontSize: 14, color: '#919191'}}>Código</Typography><Typography>{props?.data?.code}</Typography></Grid>
             <Grid item lg={4} style={{textAlign: 'center'}}><Typography style={{marginBottom: 26, fontSize: 14, color: '#919191'}}>Nombre</Typography><Typography>{props?.data?.description}</Typography></Grid>
             <Grid item lg={4} style={{textAlign: 'center'}}><Typography style={{marginBottom: 26, fontSize: 14, color: '#919191'}}>Estado</Typography><Typography>{props?.data?.status}</Typography></Grid>
            <Button 
            variant="outlined"
              style={{ color: "#6D9FFF", marginBottom: 5, borderColor: '#80808070', marginTop: 24 }}
              onClick={()=> props.handleCloseDetail()}
            >
                VOLVER
            </Button>
              &nbsp;&nbsp;
          </Grid>    
          </DialogContentText>
        </DialogContent>
        <DialogActions >
        </DialogActions>
      </Dialog>
    </div>
  );
}
