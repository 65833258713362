import React, { useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  Select,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useStateValue } from "../../../context/store";
import { styles } from "./styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  createCompetencie,
  getCompetenciesCode,
} from "../../../actions/Competencies-actions";
import { PATH_APP, REQUIRED_MESSAGE } from "../../../constants";
import { useForm } from "react-hook-form";
import { Scrollbars } from "react-custom-scrollbars";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import ModalBehaviors from "../modalBehaviors";
import _ from "lodash";
import * as yup from "yup";

export default function CreateCompetencie(props) {
  const classes = styles();
  const [{ competencieSearch }, dispatch] = useStateValue();
  const [domainLevels, setDomainLevels] = useState([]);
  const [item, setItem] = useState();
  const [open, setOpen] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const [message, setMessage] = useState({
    description: "",
    textButtonSubmit: "",
    handleClick: () => {},
    handleClickOut: () => {},
    oneButtons: false,
    title: "",
    type: "",
    open: false,
  });

  useEffect(() => {
    notDisabledButton();
    getCompetencies();
    window.scrollTo(0, 0);
    setDomainLevels([{ domainLevel: 1, behaviors: [] }]);
  }, []);

  const disabledButton = () => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar criterios",
      index: 8,
      isSubmit: true,
      subPath: `${PATH_APP}/competencies`,
      idForm: "formCompetencie",
      module: "MANAGE_COMPETENCIES",
      textIsSubmit: "CREAR",
      disabledSubmit: true,
    });
  };

  const notDisabledButton = () => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar criterios",
      index: 8,
      isSubmit: true,
      subPath: `${PATH_APP}/competencies`,
      idForm: "formCompetencie",
      module: "MANAGE_COMPETENCIES",
      textIsSubmit: "CREAR",
      disabledSubmit: false,
    });
  };
  const getCompetencies = async () => {
    const result = await getCompetenciesCode();
    setValue("code", result?.data?.code + 1);
  };

  const schema = yup.object().shape({
    description: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(3, "Ingrese mínimo 3 caracteres")
      .max(1000, "Ingrese máximo 1000 caracteres"),
    name: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(3, "Ingrese mínimo 3 caracteres")
      .max(300, "Ingrese máximo 300 caracteres"),
    status: yup.string().required(REQUIRED_MESSAGE),
  });

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const handleCloseModal = () => setMessage({ message, open: false });
  const closeModalBehaviors = () => setOpen(false);
  const submitAndCloseModal = () => {
    setDisabledSubmit(false);
    handleCloseModal();
    notDisabledButton();
  };
  const openModalBehaviors = (x) => {
    setItem(x);
    setOpen(true);
  };

  const changeDomainLevels = (x) => setDomainLevels(x);

  const handleCloseModalAndReset = () => {
    setDisabledSubmit(false);
    handleCloseModal();
    notDisabledButton();
    props?.history?.push(`${PATH_APP}/competencies`);

    if (competencieSearch) {
      return dispatch({
        type: "SET_FILTER_COMPETENCIE",
        filter: competencieSearch?.filter,
        isFilter: true,
        typeAction: "CREATE",
      });
    } else {
      return null;
    }
  };

  const onSubmit = (data) => {
    const dataLevels = [];
    for (let index = 0; index < domainLevels?.length; index++) {
      if (!domainLevels[index]?.behaviors?.length) {
        return setMessage({
          open: true,
          description: `Debe agregar al menos 1 aspecto al nivel de dominio`,
          handleClick: () => handleCloseModal(),
          oneButtons: true,
          textButtonSubmit: "ACEPTAR",
          type: "WARNING",
        });
      } else {
        const newBehaviors = [];
        for (let b = 0; b < domainLevels[index]?.behaviors?.length; b++) {
          newBehaviors?.push({ uuid: domainLevels[index]?.behaviors[b]?.uuid });
        }
        dataLevels?.push({
          number: domainLevels[index]?.domainLevel,
          conductList: newBehaviors,
        });
        continue;
      }
    }

    const newCompetence = { ...data, domainLevelList: dataLevels };

    disabledButton();
    const createCompetencies = async () => {
      setDisabledSubmit(true);
      const result = await createCompetencie(newCompetence, dispatch);
      if (result && (result?.status === 200 || result?.status === 201)) {
        setMessage({
          open: true,
          title: "¡Proceso exitoso!",
          description: "Información almacenada correctamente ",
          handleClick: () => handleCloseModalAndReset(),
          oneButtons: true,
          textButtonSubmit: "ACEPTAR",
          type: "SUCCESS",
        });
      } else if (result?.status === 400) {
        setMessage({
          open: true,
          description: "Error al guardar la información",
          handleClick: () => submitAndCloseModal(),
          oneButtons: true,
          type: "WARNING",
        });
      } else if (result?.status > 399) {
        setMessage({
          open: true,
          description: "Error al guardar la información",
          handleClick: () => submitAndCloseModal(),
          oneButtons: true,
          type: "WARNING",
        });
      } else {
        setMessage({
          open: true,
          description:
            "Ocurrió un error procesando su solicitud, inténtelo nuevamente",
          handleClick: () => submitAndCloseModal(),
          oneButtons: true,
          type: "WARNING",
        });
      }
    };
    createCompetencies();
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    trigger(name);
  };

  return (
    <>
      <form id="formCompetencie" onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent="center"
          spacing={3}
          style={{ marginLeft: "-3%" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={7.5}
            style={{ display: "flex" }}
          >
            <ErrorOutlineIcon
              color="error"
              style={{ visibility: errors[`name`] ? "initial" : "hidden" }}
              fontSize="small"
              className={classes.iconError}
            />
            <FormControl variant="outlined" fullWidth size="small">
              <TextField
                {...register("name")}
                className={classes.dropdown}
                fullWidth
                type="text"
                InputLabelProps={{
                  shrink: watch("name") ? true : false,
                  notched: watch("name") ? true : false,
                }}
                multiline
                maxRows={4}
                size="small"
                label="Criterio"
                error={
                  !!errors.hasOwnProperty("name") && errors["name"].message
                }
              />
              <FormHelperText style={{ color: "#d32f2f" }}>
                {errors.hasOwnProperty("name") && errors["name"].message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <FormControl variant="outlined" fullWidth size="small">
              <TextField
                {...register("code")}
                className={classes.dropdown}
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: watch("code") ? true : false,
                  notched: watch("code") ? true : false,
                }}
                size="small"
                label="Código"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={2.5}
            style={{ display: "flex" }}
          >
            <ErrorOutlineIcon
              color="error"
              style={{ visibility: errors[`status`] ? "initial" : "hidden" }}
              fontSize="small"
              className={classes.iconErrorSelect}
            />
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel
                error={
                  !!errors.hasOwnProperty("status") && errors["status"].message
                }
              >
                Estado
              </InputLabel>
              <Select
                {...register("status")}
                name="status"
                onChange={handleOnchange}
                shrink={true}
                className={classes.dropdown}
                label="Estado "
                error={
                  !!errors.hasOwnProperty("status") && errors["status"].message
                }
              >
                <MenuItem value="ACTIVE" key={1}>
                  Activo
                </MenuItem>
                <MenuItem value="INACTIVE" key={2}>
                  Inactivo
                </MenuItem>
              </Select>
              <FormHelperText style={{ color: "#d32f2f" }}>
                {errors.hasOwnProperty("status") && errors["status"].message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{ display: "flex" }}>
              <ErrorOutlineIcon
                color="error"
                style={{
                  visibility: errors[`description`] ? "initial" : "hidden",
                }}
                fontSize="small"
                className={classes.iconErroDescription}
              />
              <FormControl variant="outlined" fullWidth size="small">
                <>
                  <TextField
                    {...register("description")}
                    className={classes.dropdownMultiple}
                    fullWidth
                    type="text"
                    InputLabelProps={{
                      shrink: watch("description") ? true : false,
                      notched: watch("description") ? true : false,
                    }}
                    multiline
                    maxRows={12}
                    rows={7}
                    size="small"
                    label="Descripción"
                    error={
                      !!errors.hasOwnProperty("description") &&
                      errors["description"].message
                    }
                  />
                  <div className={classes.accountant}>{`${
                    watch("description")?.length
                      ? watch("description")?.length
                      : 0
                  }/1000`}</div>
                </>
              </FormControl>
            </div>
            <FormHelperText style={{ color: "#d32f2f", marginLeft: "4%" }}>
              {errors.hasOwnProperty("description") &&
                errors["description"].message}
            </FormHelperText>
          </Grid>
          <Grid item container lg={12} md={12} style={{ marginLeft: "3%" }}>
            {domainLevels?.map((x) => (
              <Grid item container lg={12}>
                <Grid
                  item
                  lg={7}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5%",
                    marginBottom: "1%",
                  }}
                >
                  <Typography
                    style={{ color: "#4B4B4B", fontSize: 14 }}
                  >{`Nivel de dominio`}</Typography>
                  <IconButton
                    onClick={() => openModalBehaviors(x?.domainLevel)}
                  >
                    <Tooltip
                      title={
                        x?.behaviors?.length
                          ? "Editar relación de aspecto"
                          : "Agregar relación de aspecto"
                      }
                      arrow
                    >
                      {x?.behaviors?.length ? (
                        <EditIcon fontSize="small" />
                      ) : (
                        <AddCircleIcon color="success" />
                      )}
                    </Tooltip>
                  </IconButton>
                </Grid>
                <Grid item lg={6}>
                  <Typography
                    style={{
                      paddingLeft: "15%",
                      color: "#8E8E8E",
                      fontSize: 10,
                      marginBottom: "1%",
                    }}
                  >
                    Código
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <Typography
                    style={{
                      color: "#8E8E8E",
                      fontSize: 10,
                      marginBottom: "1%",
                    }}
                  >
                    Aspecto
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12}>
                  <Paper
                    elevation={2}
                    style={{
                      borderRadius: 10,
                      paddingBottom: 15,
                      paddingTop: 10,
                    }}
                  >
                    <Scrollbars
                      style={{
                        width: "100%",
                        height: "150px",
                        backgroundColor: "#ffffff",
                        borderRadius: 10,
                      }}
                    >
                      <table style={{ paddingTop: 8, paddingBottom: 8 }}>
                        {x?.behaviors?.map((b) => (
                          <tr style={{ height: 30 }}>
                            <td
                              style={{
                                width: "30%",
                                color: "#8E8E8E",
                                fontSize: 14,
                                paddingLeft: "8%",
                                verticalAlign: "baseline",
                              }}
                            >
                              {b?.code}
                            </td>
                            <td
                              style={{
                                width: "65%",
                                color: "#8E8E8E",
                                fontSize: 14,
                                wordBreak: "break-word",
                              }}
                            >
                              {b?.name}
                            </td>
                          </tr>
                        ))}
                      </table>
                    </Scrollbars>
                  </Paper>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <ModalConfirm
          description={message.description}
          textButtonSubmit={message.textButtonSubmit}
          handleClick={message.handleClick}
          handleClickOut={message.handleClickOut}
          oneButtons={message.oneButtons}
          title={message.title}
          type={message.type}
          open={message.open}
        />
      </form>
      <ModalBehaviors
        open={open}
        handleClickOut={closeModalBehaviors}
        item={item}
        levens={domainLevels}
        onChangeLevels={changeDomainLevels}
      />
    </>
  );
}
