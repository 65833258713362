import React, {useState, useEffect} from 'react'
import ModalConfirm from "../../utils/form/modalConfirmBase";
import { useStateValue } from '../../context/store';
import TableQuestionnaires from "./tableQuestionnaires"
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import {getAllStatus, getfilterAnswerQuestionnaires} from "../../actions/AnswerQuestionnaire-action"


import Search from "./search";

const Index = () => {
    const [{ answerQuestionary, loadingTables }, dispatch] = useStateValue();
    const [listStatus, setListStatus] = useState([])
    const [clearFielForm, setClearFielForm]= useState(false)
    const [data] = useState({
        effectiveStartDate : null,
        effectiveEndDate: null,
        questionnaireNameKeyword: null,
        status: null
      })
    const [paginadorResponse, setPaginadorResponse] = useState({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
    });
    const [paginadorRequest, setPaginadorRequest] = useState({
        page: 0,
        size: 10,
    });
    const [message, setMessage] = useState({
        description : '',
        textButtonSubmit : '',
        handleClick : () => {} ,
        handleClickOut : () => {},
        oneButtons : false,
        title: '',
        type : '',
        open : false
        })

    useEffect(()=> {
      window.scrollTo(0,0)
      if(!answerQuestionary?.isFilter){
        dispatch({
          type: "FILTER_QUESTIONARY_ANSWER",
          filter: {},
          isFilter: false,
        })
      }
    },[])
    const closeModalAndClear = () => setClearFielForm(!clearFielForm)
    useEffect(()=> {
        dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Responder cuestionario",
            index: 6,
            module: 'ANSWER_QUESTIONARY'
           
          });
          if(paginadorResponse?.data?.length){
            getAllQuestionnaires()
          }
          statusQuestionnaire()
    },[paginadorRequest])

    const statusQuestionnaire = async() => {
       const result = await getAllStatus();
       setListStatus(result?.data)
    }

    const closeModal = () => {
        setMessage({...message, open: false}); 
        closeModalAndClear();
    }
    
      const getAllQuestionnaires = async() => {
       
        const result = await getfilterAnswerQuestionnaires({
          effectiveStartDate : data?.effectiveStartDate === null ? null : data?.effectiveStartDate,
          effectiveEndDate: data?.effectiveEndDate === null ? null : data?.effectiveEndDate,
          questionnaireNameKeyword: data?.questionnaireNameKeyword === null ? null : data?.questionnaireNameKeyword,
          status: data?.status === null ? null : data?.status,
          page: paginadorRequest?.page === null ? null : paginadorRequest?.page,
          size:  paginadorRequest?.size === null ? null : paginadorRequest?.size,
            
        }, dispatch);
        
        
        if(result?.data?.length < 1 && !answerQuestionary?.typeAction){
          setPaginadorResponse({
            data: [],
            totalRows: 0,
            pageSize: 0,
            pageNumber: 0,
          });
          return setMessage({
            open : true,
            description: 'No se encontró información relacionada a la búsqueda',
            handleClick : () => closeModal() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
         }
        if ((result && result.status === 200) || (result.status === 201)) {
          const data = result?.data;
          if (data?.content?.length > 0) {
            setPaginadorResponse({
              data: data?.content,
              totalRows: data?.totalElements,
              pageSize: data?.size,
              pageNumber: data?.number,
            });
          }
          
        } 
        else {
          setPaginadorResponse({
            data: [],
            totalRows: 0,
            pageSize: 0,
            pageNumber: 0,
          });
        }
      
      
      }
    
      if(answerQuestionary &&
        answerQuestionary?.isFilter) {
          const newData = answerQuestionary?.filter
          data.effectiveStartDate = newData?.effectiveStartDate;
          data.effectiveEndDate = newData?.effectiveEndDate;
          data.questionnaireNameKeyword = newData?.nameKeyword
          data.status = newData?.status;
          paginadorRequest.page = newData?.page;
          paginadorRequest.size = newData?.size
          getAllQuestionnaires( )
          dispatch({
            type: "FILTER_QUESTIONARY_ANSWER",
            filter: answerQuestionary?.filter,
            isFilter: false,
          })
      }
    
      const changePage = (event, nuevaPagina) => {
       
        setPaginadorRequest({
          ...paginadorRequest,
          page: nuevaPagina,
      
         
        });
      };
    
      const changeAmountOfRecords = (event, siguiente) => {
        setPaginadorRequest({
          ...paginadorRequest,
          size: event.target.value,
          page: 0
        });
      };
    
      const clearFilter = () => {
        setPaginadorResponse({
          data: [],
          totalRows: 0,
          pageSize: 0,
          pageNumber: 0,
        })
      }

  return (
      <>
        <Search 
            message={message}
            setMessage={setMessage}
            clearFilter={clearFilter}
            listStatus={listStatus}
            clearFielForm={clearFielForm}
        />
         {loadingTables?.active ?
            <Box sx={{ display: 'flex', marginTop:'15%', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
            : 
            
            < TableQuestionnaires  
                dataQuestionary={ paginadorResponse?.data}
                // dataQuestionary={dataAnswer}

                />
            
            }
        {paginadorResponse?.totalRows !== 0 && !loadingTables?.active &&
            <TablePagination
                style={{ marginLeft: "25%", display:"flex", color: '#747578' }}
                component="div"
                rowsPerPageOptions={[5, 10, 15]}
                count={paginadorResponse.totalRows}
                rowsPerPage={paginadorRequest.size}
                page={paginadorRequest.page}
                labelRowsPerPage='Registros por página'
                onPageChange={changePage}
                onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
            />
        }
       <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
      </>
  )
}

export default Index