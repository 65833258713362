import sessionUserReducer from "./sessionUserReducer";
import breadCrumbsReducer from "./breadCrumbsReducer";
import labelSearchReducer from "./labelSearchReducer";
import modalCloseReducer from "./modalReducer";
import questionSearchReducer from "./questionSearchReducer";
import questionarySearchReducer from './questionarySearchReducer';
import modalActionReducer from "./ModalActionsReducer";
import searchCompetencieReducer from "./CompetencieSearchReducer";
import dataAplicationReducer from "./DataAplicationReducer";
import headerAplicationReducer from "./HeaderAplicationReducer";
import AplicationSearchReducer from "./AplicationSearchReducer";
import modalValorateAplicationReducer from "./ModalValorateAplicationReducer";
import ValorationQuestionnaireReducer from './ValorationQuestionnaireReducer';
import answerQuestionarySearchReducer from "./AnswerQuestionarySearchReducer";
import loadingTablesReducer from "./LoadingReducer";
import disabledQuestionaryReducer from "./DisabledQuestionaryReducer";
import tokenSessionReducer from "./TokenSessionReducer";
import searchCriteriaReducer from "./CriteriaSearchReducer"
import searchBehaviorReducer from "./BehaviorSearchReducer";
import relationsReducer from "./RelationsReducer";
import detailEvaluationReducer from "./DetailEvaluationReducer"
import evaluationPerformanceSearchReducer from "./EvaluationPerformanceReducer";
import typeOperationReducer from "./TypeOperationReducer";
import valorationAplicationReducer from "./ValorationAplicationReducer";
import answerEvaluationSearchReducer from "./AnswerEvaluationSearchReducer";
import managementAplicationEvaluationsReducer from "./ManagementAplicationEvaluationReducer";
import organizationChartReducer from "./OrganizationChart";
import OrganizationChartSearchReducer from "./OrganizationChartFilterReducer";
import leaderSearchReducer from "./SearchLeaderReducer";
import newleaderSearchReducer from "./SearchNewLeaderReducer";
import resetPageReducer from "./resetPagesReducer";
import typesEvaluationsReducer from "./TypesEvaluationsReducer";
import addNewLeadersSeachReducer from "./AddNewLeadersSearchReducer";
import evaluatedsAplicationSearchReducer from "./EvaluatedsAplicationSearchReducer";
import sendDataToPageReducer from "./SendDataToPageReducer";
import searchGraphicReducer from "./SearchGraphicReducer";
import spinnerReducer from "./SpinnerReducer";
import answerEvaluatorReducer from "./AnswerEvaluatorReducer";


export const mainReducer = (
  {
    sessionUser,
    tokenSession,
    breadCrumb,
    labelSearch,
    modalClose,
    questionSearch,
    questionarySearch,
    modalAction,
    dataAplication,
    aplicationQuestionary,
    valorateAplication,
    valorationQuestionnaire,
    headerAplication,
    loadingTables,
    answerQuestionary,
    disabledQuestionary,
    competencieSearch,
    behaviorSearch,
    relations,
    criteriaSearch,
    detailEvaluation,
    evaluationPerformanceSearch,
    typeOperation,
    valorationAplication,
    answerEvaluation,
    managementAplicationEvaluation,
    organization,
    filterOrganizationChart,
    searchLeader,
    newLeader,
    resetPage,
    typesEvaluations,
    addNewLeaders,
    evaluatedsSearch,
    dataToPage,
    searchGraphic,
    spinner,
    answerEvaluator
  },
  action
) => {
  return {
    sessionUser: sessionUserReducer(sessionUser, action),
    tokenSession: tokenSessionReducer(tokenSession, action),
    breadCrumb: breadCrumbsReducer(breadCrumb, action),
    labelSearch: labelSearchReducer(labelSearch, action),
    modalClose: modalCloseReducer(modalClose, action),
    questionSearch: questionSearchReducer(questionSearch, action),
    questionarySearch: questionarySearchReducer(questionarySearch, action),
    modalAction: modalActionReducer(modalAction, action),
    dataAplication: dataAplicationReducer(dataAplication, action),
    aplicationQuestionary: AplicationSearchReducer(aplicationQuestionary, action),
    valorateAplication: modalValorateAplicationReducer(valorateAplication, action),
    valorationQuestionnaire: ValorationQuestionnaireReducer(valorationQuestionnaire, action),
    headerAplication: headerAplicationReducer(headerAplication, action),
    loadingTables: loadingTablesReducer(loadingTables, action),
    disabledQuestionary: disabledQuestionaryReducer(disabledQuestionary, action),
    answerQuestionary: answerQuestionarySearchReducer(answerQuestionary, action),
    competencieSearch: searchCompetencieReducer(competencieSearch, action),
    behaviorSearch: searchBehaviorReducer(behaviorSearch, action),
    relations: relationsReducer(relations, action),
    criteriaSearch: searchCriteriaReducer(criteriaSearch, action),
    detailEvaluation:  detailEvaluationReducer(detailEvaluation, action),
    evaluationPerformanceSearch: evaluationPerformanceSearchReducer(evaluationPerformanceSearch, action),
    typeOperation: typeOperationReducer(typeOperation, action),
    valorationAplication: valorationAplicationReducer(valorationAplication, action),
    answerEvaluation: answerEvaluationSearchReducer(answerEvaluation, action),
    managementAplicationEvaluation: managementAplicationEvaluationsReducer(managementAplicationEvaluation, action), 
    organization: organizationChartReducer(organization, action),
    filterOrganizationChart: OrganizationChartSearchReducer(filterOrganizationChart, action),
    searchLeader: leaderSearchReducer(searchLeader, action),
    newleader: newleaderSearchReducer(newLeader, action),
    resetPage: resetPageReducer(resetPage, action),
    typesEvaluations: typesEvaluationsReducer(typesEvaluations, action),
    addNewLeaders: addNewLeadersSeachReducer(addNewLeaders, action),
    evaluatedsSearch: evaluatedsAplicationSearchReducer(evaluatedsSearch, action),
    dataToPage: sendDataToPageReducer(dataToPage, action),
    searchGraphic: searchGraphicReducer(searchGraphic, action),
    spinner: spinnerReducer( spinner, action),
    answerEvaluator: answerEvaluatorReducer( answerEvaluator, action)
  };
};
