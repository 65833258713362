import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField, 
     Button,
     Box,
     Autocomplete,
     FormControl
} from '@mui/material'
import { useStateValue } from "../../../../../../context/store";
import {styles} from './styles';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SearchCompetencies = (props) => {

    const classes = styles()
    const [{}, dispatch] = useStateValue();
    const [valuesForm, setValuesForm] = useState({
        regionalName: null,
        positionName: null,
    })

  
    const schema = yup.object().shape({

        idKeyword: yup
        .string()
        .max(15, 'Ingrese máximo 15 caracteres'),
        nameKeyword: yup
        .string()
        .max(40, 'Ingrese máximo 40 caracteres'),
        positionName: yup
        .string(),
        regionalName:   yup
        .string()
        
      });
    
      
      const { register, handleSubmit, setValue, clearErrors, watch, formState:{errors} } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });
      
      const onSubmit = (data) => {

        if(
            data?.idKeyword !== '' ||
            data.nameKeyword !== '' ||
            data?.positionName !== '' ||
            data?.regionalName !== ''
        ){
            dispatch({
                type: "ADD_NEW_LEADERS",
                filter: {
                    nameKeyword: data?.nameKeyword,
                    idKeyword: data?.idKeyword,
                    positionName: data?.positionName,
                    regionalName: data?.regionalName,
                    page: 0,
                    size: 5
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "ADD_NEW_LEADERS",
                filter: {
                    nameKeyword: null,
                    idKeyword: null,
                    positionName: null,
                    regionalName: null,
                  },
                  isFilter: true,
            })
        }
      }

      const clear = () => {
        setValue('idKeyword', '')
        setValue('nameKeyword', '')
        setValue('position', '')
        setValue('area', '')
        setValue('regionalName', "")
        setValue('positionName', "")
        clearErrors(['nameKeyword', 'idKeyword'])
        setValuesForm({...valuesForm, regionalName: null, nameKeyword: '' , positionName: null, area: '', regional: ''})
      }

      useEffect(() => {
        clear()
      }, [props?.clearFielForm])

      
      const clearForm = () => {
        clear()
            props.dataFilter()
        }

        const validate = (watch('nameKeyword') || watch('idKeyword') || watch('regionalName') || watch('positionName') || watch('regional') )
        const disabledButton = !validate || errors['name'] || errors['nameKeyword'] || errors['idKeyword'];


    return (
         <form onSubmit={handleSubmit(onSubmit)} style={{margin: '2% 0%'}}>
            <Grid container  spacing={2} alignItems="center">
                <Grid item lg={3}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <Autocomplete
                            {...register('regionalName')}
                            options={props?.regionals}
                            autoHighlight
                            className={classes.dropdown}
                            size="small"
                            openText='Abrir'
                            closeText='Cerrar'
                            clearText='Borrar'
                            noOptionsText="No hay opciones"
                            getOptionLabel={(option) => option?.name}
                            onChange={(e, newValue) => {
                                setValue('regionalName', newValue?.name);
                                setValuesForm({...valuesForm, regionalName: newValue})
                            
                            }}
                            value={valuesForm?.regionalName}
                            renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                    {option?.name}
                            </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                {...register('regionalName')}
                                label="Programa"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                placeholder="Programa"
                                />
                            )}
                            
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={3}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <Autocomplete
                            {...register('positionName')}
                            id="country-select-demo"
                            options={props?.positions}
                            autoHighlight
                            openText='Abrir'
                            closeText='Cerrar'
                            clearText='Borrar'
                            noOptionsText="No hay opciones"
                            className={classes.dropdown}
                            size="small"
                            getOptionLabel={(option) => option?.name }
                            onChange={(e, newValue) => {
                                setValue('positionName', newValue);
                                setValuesForm({...valuesForm, positionName: newValue})
                                // setUserLeader(newValue)
                            
                            }}
                            renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                    {option?.name}
                            </Box>
                            )}
                            value={valuesForm?.positionName}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                {...register('positionName')}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                placeholder="cargo"
                                label="cargo"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={2}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <TextField
                            {...register('idKeyword')}
                            label="Identificación"
                            className={classes.dropdown}
                            size="small"
                            inputProps={{
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder="Identificación"
                            helperText={!!errors.hasOwnProperty("idKeyword") && errors["idKeyword"].message}
                            error={!!errors.hasOwnProperty("idKeyword") && errors["idKeyword"].message}
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={2}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <TextField
                            {...register('nameKeyword')}
                            label="Nombre"
                            className={classes.dropdown}
                            size="small"
                            inputProps={{
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder="Nombre"
                            helperText={!!errors.hasOwnProperty("nameKeyword") && errors["nameKeyword"].message}
                            error={!!errors.hasOwnProperty("nameKeyword") && errors["nameKeyword"].message}
                        />
                    </FormControl>
                </Grid>           
                <Grid item xs={12} sm={12} md={12} lg={2} className={classes.buttons}>
                    <Button 
                        variant="contained" 
                        type="submit" 
                        disableElevation 
                        disabled={!validate}
                        color="primary"
                    >
                        BUSCAR
                    </Button>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 
                    <Button 
                        variant="outlined" 
                        onClick={clearForm} 
                        disableElevation 
                        style={{background: "#ffffff"}}
                    >
                        Limpiar
                    </Button>

                </Grid>
            </Grid>
        
        </form>
    )
}

export default SearchCompetencies
