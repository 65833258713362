import React from "react";
import { makeStyles } from "@mui/styles";
import Warning from "../../images/icons/warning.svg";
import Question from "../../images/icons/question.svg";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Scrollbars } from 'react-custom-scrollbars';
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Button, Modal } from "@mui/material";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.24)",
    outline: "none",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
  },
  cancel1: { 
     color: "#2F71CB", 
     marginBottom: 5, 
     borderColor: '#80808070',
     "&:hover": {
      borderColor: '#80808070',
    },
  },
  cancel: { 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5, 
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
 },
  text: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#4B4B4B",
    fontWeight: 400,
    textAlign: "center",
    overflowWrap: 'anywhere',
    margin: "25px 5px",
  },
  textList: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#4B4B4B",
    fontWeight: 400,
    textAlign: "initial",
    wordBreak: 'break-all',
    listStyleType: "auto",
    margin: '10px 25px 10px 10px'
  },
  titleAlert: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#2C2C2C",
  },
  widthIcon: {
    fontSize: 70,
    color: '#2F71CB',
    marginTop: '3%'

  },
  confirmBanck :{ 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5,
    // width: 'fit-content' ,
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
  }
}));

const ModalConfirmBase = ({
  description,
  open,
  handleClick,
  handleClickOut,
  type,
  title,
  oneButtons,
  threeButtons,
  textButtonSubmit,
  handleClickThree,
  textButtonCancel,
  list
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const iconsAlert = (type) => {
    if (type === "WARNING")
    return <img src={Warning} alt="" style={{ width: "25%" }} />;
    if (type === "QUESTION")
      return <img src={Question} alt="" style={{ width: "25%" }} />;
    if (type === "SUCCESS")
      return (
        <CheckCircleOutlineOutlinedIcon
          style={{ width: "30%", color: "green", height: "30%" }}
        />
      );
    if (type === "DELETE")
      return <DeleteOutlineIcon style={{ width: "30%", color: "#2F71CB", height: "30%" }} />;
  };

  const buttonsOne = (textButtonSubmit ) => {
    return (
      <Button
        className={textButtonSubmit ?  classes.cancel : classes.cancel1}
        variant={textButtonSubmit ? "contained" : "outlined"  }
        onClick={handleClick}
      >
        {textButtonSubmit ? textButtonSubmit : "VOLVER"}
      </Button>
    );
  }

  const buttonsTwo = () => {
    return (
      <React.Fragment >
        <Button
          className={ classes.cancel1 }
          variant="outlined"
          onClick={handleClickOut}
        >
          {textButtonCancel ? textButtonCancel : 'VOLVER'}
        </Button>

        <Button
          className={classes.cancel}
          variant="contained"
          onClick={handleClick}
        >
          {textButtonSubmit ? textButtonSubmit : "ACEPTAR"}
        </Button>
      </React.Fragment>
    );
  }

  const buttonsThree = (handleClickOut, textButtonSubmit) => {
    return (
      <React.Fragment style={{display: 'flex', flexDirection: 'column'}}>
        
        <Button
          className={classes.confirmBanck}
          variant="contained"
          onClick={handleClickThree}
        >
          {textButtonSubmit ? textButtonSubmit : "BANCO DE PREGUNTAS"}
        </Button>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
        <Button
          className={classes.cancel}
          variant="contained"
          onClick={handleClick}
        >
          {textButtonSubmit ? textButtonSubmit : "CREAR PREGUNTA"}
        </Button>
        <Button
          className={ classes.cancel1 }
          variant="outlined"
          onClick={handleClickOut}
          >
          CANCELAR
        </Button>
          </div>
      </React.Fragment>
    );
  }

  const renderButtons = (
    oneButtons,
    textButtonSubmit,
    textButtonCancel,
    handleClick,
    handleClickOut,
    threeButtons,
    handleClickThree,
  ) => {
    if (oneButtons) {
      return buttonsOne(textButtonSubmit, handleClick)
    } else if (threeButtons){
      return buttonsThree(handleClickOut, textButtonSubmit)
    }else {
      return buttonsTwo(handleClickOut, textButtonSubmit, textButtonCancel, handleClick)
    }
  };

  const bodyModal = (
    <div style={modalStyle} className={classes.paper}>
      {iconsAlert(type)}
      {title ? <h1 className={classes.titleAlert}> {title} </h1> : null}
      <p className={classes.text}>{description}</p>
      {list ?   <Scrollbars style={{ width: 300, height: list?.length > 5 ? 250 : 100 , marginBottom: 10, marginLeft: '5%' }}  autoHeightMax={100}>
      <ul >
        {list?.map((x)=> <li><p className={classes.textList}>{x}</p></li> )}
      </ul>
      </Scrollbars> : null}
      <div style={{ display: threeButtons ? null : "flex", justifyContent: threeButtons ? null : "space-evenly", flexDirection: threeButtons ? 'column': null}}>
        {renderButtons(
          oneButtons,
          textButtonSubmit,
          handleClick,
          handleClickOut,
          threeButtons,
          handleClickThree,
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={open ?? false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
};

export default ModalConfirmBase;
