import React,{useEffect, useState} from 'react'
import { useStateValue } from '../../context/store';
import Search from "./search";
import Table from "./table";
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import ModalConfirm from "../../utils/form/modalConfirmBase";
import {getFilterEvaluationPerformance} from "../../actions/Performance_Evaluation-action"
import { PATH_APP } from '../../constants';

const Index = (props) => {
  const [{spinner, evaluationPerformanceSearch}, dispatch] = useStateValue();
  const [clearFielForm, setClearFielForm]= useState(false);
  const [data, ] = useState({
    createdAtStartDate: null,  
    createdAtEndDate: null,
    nameKeyword: null,
    status: null,
    evaluationYear: null
   })
  const [paginadorResponse, setPaginadorResponse] = useState({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  const [paginadorRequest, setPaginadorRequest] = useState({
    page: 0,
    size: 10,
  });
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
  })

    useEffect(() => {

        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Gestionar modelo de evaluación",
          index: 12,
          module: 'EVALUATION_EVALUATOR',
          path: `${PATH_APP}/evaluation-performance`,
          textIsSubmit: "CREAR",
        });

          getAllEvaluationPerformance()
        window.scrollTo(0,0)
        
        dispatch({
          type: "SET_TYPES_EVALUATIONS",
          automatic: null,
          applicationTypes: [],
          submitPercentage: false,
          users: [],
          dataEvaluation: {},
          pages: [],
          selectNewLeaders: []
        });
      }, [paginadorRequest])

      useEffect(() => {
        window.scrollTo(0,0)
        localStorage.removeItem('typesEvaluation');
        dispatch({
          type: "SET_FILTER_ORGANIZATION_CHART",
          filter: {page: 0, size: 10},
          isFilter: false,
        })
      }, [])
   
      const closeModalAndClear = () => setClearFielForm(!clearFielForm)
      const closeModal = () => {setMessage({...message, open:false}); closeModalAndClear()}
      const dataFilter = () => setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });

  const notFoundData = (message) => {
    return setMessage({
      open : true,
      description: message,
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
  };
    
      const getAllEvaluationPerformance = async() => {
        dispatch({
          type: "SET_FILTER_EVALUATION_PERFORMANCE",
          filter: {
              createdAtStartDate: evaluationPerformanceSearch?.filter?.createdAtStartDate,
              createdAtEndDate: evaluationPerformanceSearch?.filter?.createdAtEndDate,
              nameKeyword: evaluationPerformanceSearch?.filter?.nameKeyword,
              status: evaluationPerformanceSearch?.filter?.status,
              evaluationYear: evaluationPerformanceSearch?.filter?.evaluationYear,
              page: !evaluationPerformanceSearch?.filter?.page ? 0 : evaluationPerformanceSearch?.filter?.page,
              size: !evaluationPerformanceSearch?.filter?.size ? 10 : evaluationPerformanceSearch?.filter?.size
            },
            isFilter: false,
        })
        const result = await getFilterEvaluationPerformance({
          createdAtStartDate : evaluationPerformanceSearch?.filter?.createdAtStartDate === null ? null : evaluationPerformanceSearch?.filter?.createdAtStartDate,
          evaluationYear : evaluationPerformanceSearch?.filter?.evaluationYear === null ? null : evaluationPerformanceSearch?.filter?.evaluationYear,
          createdAtEndDate: evaluationPerformanceSearch?.filter?.createdAtEndDate === null ? null : evaluationPerformanceSearch?.filter?.createdAtEndDate,
          nameKeyword: evaluationPerformanceSearch?.filter?.nameKeyword === null ? null : evaluationPerformanceSearch?.filter?.nameKeyword,
          status: evaluationPerformanceSearch?.filter?.status === null ? null : evaluationPerformanceSearch?.filter?.status,
          page: !evaluationPerformanceSearch?.filter?.page ? 0 :  evaluationPerformanceSearch?.filter?.page,
          size:  !evaluationPerformanceSearch?.filter?.size ? 10 : evaluationPerformanceSearch?.filter?.size,
            
        },
        {orderBy: 'createdAt'},
        dispatch
        );
       if(result?.status === 204 && !evaluationPerformanceSearch?.typeAction){
          notFoundData('No se encontró información relacionada a la búsqueda');
       }
       if(result?.data?.content?.length < 1 && !evaluationPerformanceSearch?.typeAction){
        dataFilter()
        return notFoundData('No se encontró información relacionada a la búsqueda');
       }
      if ((result && result.status === 200) || (result.status === 201)) {
        const data = result?.data;
        if (data?.content?.length > 0) {
          setPaginadorResponse({
            data: data?.content,
            totalRows: data?.totalElements,
            pageSize: data?.size,
            pageNumber: data?.number,
          });
        }
        
      } else {
        dataFilter()
      }
      }
    

    
      useEffect(()=> {
        if(evaluationPerformanceSearch &&
          evaluationPerformanceSearch?.isFilter) {
            const newData = evaluationPerformanceSearch?.filter
            data.createdAtStartDate = newData?.createdAtStartDate
            data.createdAtEndDate = newData?.createdAtEndDate;
            data.nameKeyword = newData?.nameKeyword
            data.evaluationYear = newData?.evaluationYear;
            data.status = newData?.status;
            paginadorRequest.page = newData?.page;
            paginadorRequest.size = newData?.size
            dispatch({
              type: "SET_FILTER_EVALUATION_PERFORMANCE",
              filter: evaluationPerformanceSearch?.filter,
              isFilter: false,
            })
            getAllEvaluationPerformance()
        }
      },[evaluationPerformanceSearch?.isFilter])
      
    
      const changePage = (event, nuevaPagina) => {
           
        setPaginadorRequest({
          ...paginadorRequest,
          page: nuevaPagina,
        });
        dispatch({
          type: "SET_FILTER_EVALUATION_PERFORMANCE",
          filter: {
              createdAtStartDate: evaluationPerformanceSearch?.filter?.createdAtStartDate,
              createdAtEndDate: evaluationPerformanceSearch?.filter?.createdAtEndDate,
              nameKeyword: evaluationPerformanceSearch?.filter?.nameKeyword,
              status: evaluationPerformanceSearch?.filter?.status,
              evaluationYear: evaluationPerformanceSearch?.filter?.evaluationYear,
              page: nuevaPagina,
              size: evaluationPerformanceSearch?.filter?.size
            },
            isFilter: true,
        })
      };
    
      const changeAmountOfRecords = (event, siguiente) => {
        setPaginadorRequest({
          ...paginadorRequest,
          size: event.target.value,
          page: 0
        });
        dispatch({
          type: "SET_FILTER_EVALUATION_PERFORMANCE",
          filter: {
              createdAtStartDate: evaluationPerformanceSearch?.filter?.createdAtStartDate,
              createdAtEndDate: evaluationPerformanceSearch?.filter?.createdAtEndDate,
              nameKeyword: evaluationPerformanceSearch?.filter?.nameKeyword,
              status: evaluationPerformanceSearch?.filter?.status,
              evaluationYear: evaluationPerformanceSearch?.filter?.evaluationYear,
              page: 0,
              size: event.target.value
            },
            isFilter: true,
        })
      };
      



  return (
    <>
      <Search 
        dataFilter={dataFilter}
        clearFielForm={clearFielForm}
        setMessage={setMessage}
        message={message}
        />
   
   {!spinner?.active &&
        <Table 
        data={paginadorResponse?.data}
        message={message}
        setMessage={setMessage}
      />
    }

    {paginadorResponse?.totalRows !== 0 && !spinner?.active &&
      <TablePagination
          style={{ justifyContent: 'center', display:"flex", color: '#747578' }}
          component="div"
          rowsPerPageOptions={[5, 10, 15]}
          count={paginadorResponse.totalRows}
          rowsPerPage={evaluationPerformanceSearch?.filter?.size}
          page={evaluationPerformanceSearch?.filter?.page}
          labelRowsPerPage='Registros por página'
          onPageChange={changePage}
          onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
      />
    }
    <ModalConfirm 
      description = { message.description }
      textButtonSubmit = { message.textButtonSubmit }
      handleClick = { message.handleClick }
      handleClickOut = { message.handleClickOut }
      oneButtons = { message.oneButtons }
      title = { message.title }
      type = { message.type }
      open = { message.open }
    />
  </>
  )
}

export default Index