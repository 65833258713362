import React,{useState, useEffect} from "react";
import { Box } from "@mui/material";
import { useStateValue } from './../../../../../context/store';
import CircularProgress from '@mui/material/CircularProgress';
import 'moment/locale/es';
import { useParams } from "react-router-dom";
import ModalConfirm from "../../../../../utils/form/modalConfirmBase";
import { getResultEvaluationEvaluated } from "../../../../../actions/ManagementAplicationEvaluations-actions";
import ViewPdf from "./original";
import CopyPdf from "./dowloadPdf";
import { PATH_APP } from "../../../../../constants";


const ModalConfirmBase = (props) => {
    const params = useParams();
  const [{dataToPage }, dispatch] = useStateValue();
  const {applicationId, evaluated} = params;
  const [, setValidForm] = useState(true)
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    textButtonCancel: '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    buttonsTwo: false,
    title: '',
    type : '',
    open : false
    })


    useEffect(() => {
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Evaluados",
          index: 13,
          // isSubmit: true,
          // textIsSubmit: 'DESCARGAR',
          module: 'DETAIL_APLICATION_EVALUATION',
          // idForm: 'pdf',
          subPath: `${PATH_APP}/management-aplication-evaluateds/${applicationId}`,
        });
        getDataPdf()
      // window.scrollTo(0,0)
      }, []);

    const closeModal = () => setMessage({...message, open: false});

    const getDataPdf = () => {
        const dowload = async() => {
            const result = await getResultEvaluationEvaluated( applicationId, evaluated, dispatch);
            if(result?.status >= 200 && result?.status <= 204){
                   return dispatch({
                     type: 'SEND_DATA_TO_PAGE',
                     data: {...dataToPage?.data, ...result?.data}
                   })
                 }
                 if(result?.status === 404){
                   return  setMessage({
                     open : true,
                     description: 'La aplicación de modelo de evaluación no existe',
                     handleClick : () => closeModal() ,
                     oneButtons : true,
                     textButtonSubmit: 'ACEPTAR',
                     type : 'WARNING',
                   })
                 }
                 else {
                   return  setMessage({
                     open : true,
                     description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
                     handleClick : () => closeModal() ,
                     oneButtons : true,
                     textButtonSubmit: 'ACEPTAR',
                     type : 'WARNING',
                   })
                 }
                 
               }
         dispatch({
           type: 'SET_MODAL',
           open: true,
         })
     
         dowload()
     };


     const getValidForm = (x) => setValidForm(x);

  if(!dataToPage?.data?.applicationTypes?.length){
    return      <Box sx={{ display: 'flex', marginTop:'15%', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
  }else{
  return (
            <>
                < ViewPdf showCopy={getValidForm}/>
              < CopyPdf dataToPage={dataToPage}/> 
                
                <ModalConfirm 
                    description = { message.description }
                    textButtonSubmit = { message.textButtonSubmit }
                    textButtonCancel = {message.textButtonCancel}
                    handleClick = { message.handleClick }
                    handleClickOut = { message.handleClickOut }
                    oneButtons = { message.oneButtons }
                    title = { message.title }
                    type = { message.type }
                    open = { message.open }
                />
            </>
    );
  }
};

export default ModalConfirmBase;
