import React from 'react';
import { DecompositionTreeGraph } from '@ant-design/graphs';


const DemoOrganizationGraph = (props) => {


    const nodeCfg = {
        autoWidth: true,
        animate: true,
        style: (node) => {
          return node.value === 'Estudiante'
            ? {
                fill: '#91d5ff',
                stroke: '#91d5ff',
               
              }
            : { };
        },
        label: {
            fontSize: 12, 
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
            whiteSpace: 'nowrap', 
        },
      }

    const config = {
        data: props?.data,
        markerCfg: (cfg) => {
          const { children } = cfg;
          return {
            show: children?.length,
          };
        },
        behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
      };


if(props?.data){
  return (
    <DecompositionTreeGraph {...config} nodeCfg={nodeCfg}/>
  )
    }else{
        return null
  }
};

export default DemoOrganizationGraph;