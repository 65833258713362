import React,{useState, useEffect} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Box, Checkbox, Typography } from '@mui/material';
import { styles } from "./styles";
import { useStateValue } from '../../../../context/store';
import _ from "lodash";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Index = (props) => {
    const classes = styles();
    const [{relations}, dispatch] = useStateValue()
    const [checkeds, setCheckeds] = useState([])
    const [showText, setShowText] = useState([])
    const [numberPosition, setNumberPosition] = useState(1)
    const [copyCheckeds, setCopyCheckeds] = useState([])

    const showQuestion = (data, id) => {
    const result = showText?.find((x)=> x?.id === id)
    if(result?.show) {  
        return data 
    } else {
        return `${data?.substring(0, 200)}` 
    }
    }
      
    const allShow = (id) => {
        const result = showText?.find((x)=> x?.id === id)
        if(result?.show) {
        setShowText([{show: false, id: id}])
        }else {
        setShowText([{show: true, id: id}])
        }
    }
    
    const validateShowText = (id) => {
        const result = showText?.find((x)=> x?.id === id)
        return result?.show ? true : false
    }

    const validateChecked = (data) => {
        const result =  checkeds?.find((x) =>x?.uuid === data?.uuid )
       return result ? true : false
    }

    const handleChangeChecked = (event) => {
        const copy= checkeds?.length ? [...checkeds] : []
        const {name, value} = event.target;
        const filterQuestions = props?.data?.find((x) => x.uuid === name)
        const validateExists = validateChecked(filterQuestions)
   
       if(validateExists) {
           setCheckeds(checkeds?.filter((x)=> x.uuid !== name))
           
        }else {
            setNumberPosition(numberPosition+1)
          copy.push(
            {
              uuid: filterQuestions?.uuid, 
              name: filterQuestions?.name, 
              code: filterQuestions?.code, 
              position: numberPosition+1
            }
          )
          setCheckeds(copy);
          setCopyCheckeds(copy)
       } 

      
    };
    

    useEffect(() => {
      const findLeven = props?.levens?.find((x)=> x?.domainLevel === props?.item)
      setCheckeds(findLeven?.behaviors)
  }, [])


    useEffect(() => {
     props?.getBehaviors(checkeds)
    }, [checkeds])
    
   

  return (
    <div >
    <TableContainer component={Paper} style={{ width: '100%', border: '1px solid #d9d7d7', borderRadius: 10 }}>
      <Scrollbars style={{height: 270, paddingTop: '10px !important'}}>
      {props?.data?.length ? <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead >
        <TableRow >
          <TableCell size="small" padding="none" style={{borderBottom: 'none'}}></TableCell>
          <TableCell align="center" size="small" padding="none"  style={{borderBottom: 'none'}}>Código</TableCell>
          <TableCell align="center" size="small" padding="none"  style={{borderBottom: 'none'}}>Aspecto</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props?.data?.map((x, index) => (
          <TableRow
            key={x.uuid}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            style={{verticalAlign: 'Initial'}}
          >
            <TableCell align="right" component="th" scope="row" size="small" padding="none" style={{borderBottom: 'none'}}>
              <Checkbox
                  checked={validateChecked(x)}
                  name={x?.uuid}
                  onChange={handleChangeChecked}
                  inputProps={{ 'aria-label': 'controlled' }}
                  // disabled={!relations?.behaviorSelected}
              />
            </TableCell >
            <TableCell align="center" size="small" padding="none" style={{borderBottom: 'none'}}>{x.code}</TableCell>
            <TableCell align="left" size="small" padding="none" style={{borderBottom: 'none', padding: '0px 2%', width: '70%'}}>
              <Typography component={'span'} style={{marginTop: '1.5%', marginRight: 10}} className={classes.heading} >
                  {x.name?.length > 200 ? showQuestion(x.name, index) : x.name}{x.name?.length > 200 && 
                  <Typography variant="caption"
                      className={classes.expandText}
                      onClick={()=> allShow(index)}>{validateShowText(index) ? "...ver menos" : "...ver más" }
                  </Typography>}
              </Typography>
              </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table> : null}
      </Scrollbars> 
  </TableContainer>
  </div>
  )
}

export default Index