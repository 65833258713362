import React, { useState } from "react";
import * as IdKey from "short-uuid";
import { Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ModalDelete from '../../../utils/form/modalConfirmBase'
import { useStateValue } from "../../../context/store";
import {styles} from "./styles"
import { useHistory } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PATH_APP } from "../../../constants";

export default function DetailedAccordion({
  setMessage,
  message,
  data,
  setScroll,
}) {
  const classes = styles();
  const history = useHistory()
  const id = IdKey.generate();
  const [, dispatch] = useStateValue();
  const [messageDelete, ] = useState({
    open: false,
    type: '',
    description: '',
    handleClickOut: () => {},
    handleClick: () => {}
  })

  




const editBehavior = (x) => {
  history.push(`${PATH_APP}/behavior-edit/${x?.uuid}`)
  dispatch({
    type: 'SET_DATA_QUESTIONNAIRE',
    data: x
  })
}


if(data?.length) {
  return (
    <div className={classes.root}>
      <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"37%"} className={classes.wrapper}>
                  Código
                </TableCell>
                <TableCell align="left" width={"43%"} className={classes.wrapper}>
                Aspecto
                </TableCell>
                <TableCell align="left" width={"13%"} className={classes.wrapper}>
                  Estado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index, idColumn = id) => (
                <TableRow key={index}>
                  <TableCell align="left" width={"37%"} className={classes.header}>
                    {row?.code}
                  </TableCell>
                  <TableCell align="left" width={"43%"} className={classes.header}>
                    {row?.name}                  </TableCell>
                  <TableCell align="left" width={"13%"} className={classes.header}>
                    {row?.status === 'ACTIVE' ? 'Activo' : 'Inactivo'}
                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    <Tooltip title="Editar" arrow >
                      <EditIcon
                        onClick={() => editBehavior(row)}
                        style={{ marginLeft: '55%' }}
                        className={classes.iconEvent}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
        <ModalDelete 
        open={messageDelete.open}
        type={messageDelete.type}
        description={messageDelete.description}
        handleClick={messageDelete.handleClick}
        handleClickOut={messageDelete.handleClickOut}
      />
    </div>
  );
      }else {
        return null;
      }
}
