import { makeStyles } from '@mui/styles';
import theme from "../theme/theme";


export const styles = makeStyles({
    paper: {
      marginTop: 8,
      display: "flex",
      flexDirection: "column",
      marginLeft: "25px"
    },
    dropdownQuestion: {
      backgroundColor: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "8px",
      fontSize: "14px",
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#dfe7fd',
      },
      '& .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input': {
        fontSize: 'smaller'
      }
  
    },
  
    buttonsBanks: {
      [theme.breakpoints.up("lg", "md")]: {
        display: 'flex',
       justifyContent: 'space-evenly',
      },
    },
    largeButtonsBanks: {
      [theme.breakpoints.up("lg", "md")]: {
       display: 'flex',
       justifyContent: 'space-evenly',
       marginRight: '-7%'
      },
    },
    buttonBank: {
      [theme.breakpoints.down("md")]: {
        fontSize:12, marginRight: 20
      },
      [theme.breakpoints.down("lg")]: {
        fontSize:12, marginRight: 20
      },
      [theme.breakpoints.up("lg")]: {
        fontSize:12,  background: 'white'
      },
    },
    iconErrorWithoutFlex: {
      marginRight: '2%',
      fontSize: 'initial'
    },
    iconErrorModal: {
      marginRight: '0.5%',
      fontSize: 'initial',
    },
    popover: {
      pointerEvents: 'none',
    },
    titleEditQuestion: {
      fontSize: 15,
      fontWeight: 400,
      margin: '3% 0px 0% 4%',
      textAlign: 'center'
    },
    titleCreteQuestion: {
      fontSize: 15,
      fontWeight: 400,
      margin: '0% 0px 0% 4%',
      textAlign: 'center'
    },
    titleEditQuestionary: {
      fontSize: 15,
      fontWeight: 400,
      margin: '2% 0px 2% 4%',
      textAlign: 'center'
    },
    titleModalBank: {
      fontWeight: 400,
      textAlign: 'center',
      fontSize: 16,
      fontFamily: 'Roboto',
      letterSpacing: '0.25px'
    },
    fieldDateTime: {
      '& .css-ly45cz-MuiInputBase-root-MuiOutlinedInput-root':{
        background: '#dfe7fd',
      },
      '& .css-i8lz65':{ 
        background: '#ffffff !important',
        borderRadius: 20
        },
      '& .MuiOutlinedInput-notchedOutline':{
      borderRadius: 15,

      },
      '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':{
        background: '#E1E1E1',
        color: '#8E8E8E',
        borderRadius: 20,
      },
      '& .css-1o6z5ng ': {
        background: '#E1E1E1',
        color: '#8E8E8E',
        borderRadius: 9,
      }
    },
    fieldDateTimeError: {
      '& .css-ly45cz-MuiInputBase-root-MuiOutlinedInput-root':{
        background: '#dfe7fd',
      },
      '& .MuiOutlinedInput-notchedOutline':{
      borderRadius: 15,

      },
      '& .css-i8lz65':{
        background: '#ffffff',
        borderRadius: 20
      },
      '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':{
        background: '#ffffff',
        color: '#545353',
        borderRadius: 20,
        
      }
    },
    fieldDateTimeErrorSearch: {
      '& .css-ly45cz-MuiInputBase-root-MuiOutlinedInput-root':{
        background: '#dfe7fd',
      },
      '& .MuiOutlinedInput-notchedOutline':{
      borderRadius: 15,

      },
      '& .css-i8lz65':{
        background: '#ffffff',
        borderRadius: 20
      },
      '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':{
        background: '#ffffff',
        color: '#545353',
        borderRadius: 20,
        fontSize: 14
        
      }
    },
    fieldDateTimeBackground: {
      '& .css-ly45cz-MuiInputBase-root-MuiOutlinedInput-root':{
        background: '#ffffff',
      },
      '& .MuiOutlinedInput-notchedOutline':{
      borderRadius: 16,

      },
      '& .css-i8lz65':{
        background: '#E1E1E1',
        borderRadius: 20
      },
      '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':{
        background: '#E1E1E1',
        color: '#545353',
        borderRadius: 20,
        
      }
    },
    fieldDateTimeModal: {
      '& .css-ly45cz-MuiInputBase-root-MuiOutlinedInput-root':{
        background: '#ffffff',
      },
      '& .MuiOutlinedInput-notchedOutline':{
      borderRadius: 15,

      },
      '& .css-i8lz65':{
        background: '#ffffff',
        borderRadius: 20
      },
      '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':{
        background: '#ffffff',
        color: '#545353',
        borderRadius: 20,
        
      }
    },
    iconEvent: {
      color: "#646464",
      cursor: "pointer",
      fontSize: 'smaller',
      // '& .css-78trlr-MuiButtonBase-root-MuiIconButton-root': {
      //   fontSize: 'large'
      // },
      '&:hover':{
        color: "#2F71CB",
      }
    },
    iconEventCancel: {
      cursor: "pointer",
      height: 20
    },
    display: {
      display: 'flex',
      alignItems: 'center'
    },
    iconErrorStatus: {
      marginBottom: '9%',
      marginRight: '2%',
      fontSize: 'initial'
    },
    iconError: {
      marginRight: '2%',
      marginTop: '3%',
      fontSize: 'initial'
    },
    iconErrorFielLabel: {
      marginRight: '2%',
      marginBottom: '5%',
      fontSize: 'initial'
    },
    iconErrorFieldLabel: {
      marginRight: '2%',
      marginBottom: '3%',
      fontSize: 'initial'
    },
    iconErrorFieldLabelOne: {
      marginRight: '1%',
      marginBottom: '3%',
      fontSize: 'initial'
    },
    iconErrorFieldQuestionary: {
      marginRight: '2%',
      marginBottom: '8%',
      fontSize: 'initial'
    },
    iconErrorfieldSmall: {
      marginTop: '1.5%',
      marginRight: '-2%',
      fontSize: 'initial'
    },
    iconErrorScalate: {
      marginBottom: '25%',
      marginRight: '2%',
      fontSize: 'initial'
    },
    borderBottomEditQestionari :{
      '& .css-12zg9sq' :{
        fontSize: 'xx-large'
      },
      '& .css-12zg9sq::before' :{
        borderBottom: 'none',
        content: "none"
      },
      '& .css-12zg9sq::after' :{
        borderBottom: 'none',
        content: "none"
      },
      '& .css-hngi1w-MuiInputBase-root-MuiInput-root:before': {
        borderBottom: 'none',
        content: "none"
      },
      '& .css-hngi1w-MuiInputBase-root-MuiInput-root:after': {
        borderBottom: 'none',
        content: "none"
      },
      '& .css-hngi1w-MuiInputBase-root-MuiInput-root:hover': {
        borderBottom: 'none',
        content: "none"
      },
      '& .css-hngi1w-MuiInputBase-root-MuiInput-root' : {fontSize: 'xx-large'}
    },
    borderBottom: {
      '& .css-12zg9sq' :{
        color: '#8E8E8E'
      },
      '& .css-12zg9sq::before' :{
        borderBottom: 'none',
        content: "none"
      },
      '& .css-12zg9sq::after' :{
        borderBottom: 'none',
        content: "none"
      },
      '& .css-hngi1w-MuiInputBase-root-MuiInput-root:before': {
        borderBottom: 'none',
        content: "none"
      },
      '& .css-hngi1w-MuiInputBase-root-MuiInput-root:after': {
        borderBottom: 'none',
        content: "none"
      },
      '& .css-hngi1w-MuiInputBase-root-MuiInput-root:hover': {
        borderBottom: 'none',
        content: "none"
      },
      '& .css-hngi1w-MuiInputBase-root-MuiInput-root' : {color: '#8E8E8E'}
    },
    widthModalDetail: {
      [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
        maxWidth: 800,
        minWidth: 600,
        borderRadius: 16
    },
    [`& .css-uhb5lp`] : {
      maxWidth: 800,
        minWidth: 600,
        borderRadius: 16
    }
    },
    widthModalEdit: {
      [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
        maxWidth: 1200,
        minWidth: 1200,
        borderRadius: 16
    }, 
    [`& .jss59 .css-uhb5lp`] : {
      maxWidth: 1200,
      minWidth: 1200,
      borderRadius: 16
    },
    [`& .css-uhb5lp`] : {
      maxWidth: '1100px !important',
        minWidth: 1200,
        borderRadius: 16
    }
    },
    modalPreview: {
      [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
        maxWidth: 800,
        minWidth: 800,
        borderRadius: 16
    }, 
    [`& .jss59 .css-uhb5lp`] : {
      maxWidth: 800,
        minWidth: 800,
      borderRadius: 16
    },
    [`& .css-uhb5lp`] : {
      maxWidth: '800px !important',
        minWidth: 800,
        borderRadius: 16
    }
    },
    modalPreviewScale: {
      [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
        maxWidth: 1200,
        minWidth: 1200,
        borderRadius: 16
    }, 
    [`& .jss59 .css-uhb5lp`] : {
      maxWidth: 1200,
        minWidth: 1200,
      borderRadius: 16
    },
    [`& .css-uhb5lp`] : {
      maxWidth: '1100px !important',
        minWidth: 1200,
        borderRadius: 16
    }
    },
    modalBank: {
      [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
        maxWidth: 900,
        minWidth: 900,
        borderRadius: 16
    },
    [`& .jss59 .css-uhb5lp`] : {
      maxWidth: 900,
      minWidth: 900,
      borderRadius: 16
    },
    [`& .css-uhb5lp`]: {
      borderRadius: 12,
      maxWidth: 900,
      minWidth: 900,
    }
    },
    widthModal: {
      [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
        maxWidth: 800,
        minWidth: 800,
        borderRadius: 16
    },
    [`& .css-uhb5lp`] : {
      maxWidth: 800,
        minWidth: 800,
        borderRadius: 16
    }
    },
    widthModalEditQuestionary: {
      [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
        maxWidth: 1300,
        minWidth: 1300,
        borderRadius: 16
    },
    [`& .css-uhb5lp`] : {
      maxWidth: 1300,
        minWidth: 1300,
        borderRadius: 16
    }
    },
    menuItemAnswer: {
      display: 'flex',
      alignItems: 'center',
    },
    marginLeft: {
      marginLeft: 10
    },
    accountant: {
      position: 'absolute',
      left: '96%',
      fontSize: 'small',
      color: 'gray',
      top: '80%'
    },
    accountantPreview: {
      position: 'absolute',
      left: '93%',
      fontSize: 'small',
      color: 'gray',
      top: '80%'
    },
    dropdown: {
      backgroundColor: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "8px",
      height: 40,
      fontSize: "14px",
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#dfe7fd',
      },
  
    },
    dropdownNameCuestionary: {
      backgroundColor: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "8px",
      fontSize: "14px",
      '& ::placeholder': {
        fontSize: 14
      },
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#dfe7fd',
      },
  
    },
    labelProps: {
      fontSize: 14
    },
    dropdownMultiline: {
      backgroundColor: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "8px",
      height: 40,
      fontSize: "14px",
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
      [`& .css-2laola-MuiInputBase-root-MuiOutlinedInput-root`]: {
        backgroundColor: "#FFFFFF",
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#dfe7fd',
      },
      [`& .css-ktmv9s `] :{
        backgroundColor: "#FFFFFF",
      }
  
    },

    dropdownMultilineDisabled: {
      backgroundColor: "rgb(225, 225, 225)",
      boxSizing: "border-box",
      borderRadius: "8px",
      fontSize: "14px",
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
      [`& .css-2laola-MuiInputBase-root-MuiOutlinedInput-root`]: {
        backgroundColor: "rgb(225, 225, 225)",
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#dfe7fd',
      },
      [`& .css-ktmv9s `] :{
        backgroundColor: "rgb(225, 225, 225)",
      }
  
    },
    dropdownMultiple: {
      backgroundColor: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "8px",
      fontSize: "14px",
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
    },
    dropdownMultipleRanking: {
      backgroundColor: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "8px",
      fontSize: "14px",
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
      [`& .css-1o6z5ng.Mui-disabled`]: {
        padding: '8.5px 8px !important',
        textAlign: 'end'
      },
      '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input' :{
        padding: '8.5px 8px !important',
        textAlign: 'end'
      }
    },
    submit: {
      width: "100%",
      background: '#3451BE',
      color: 'white',
      padding: "10px",
      marginBottom: '20px',
      borderRadius: 20,
      '&:hover': {
        color: 'white',
        background: '#3451bebf'
      }
    },
    submitRegister: {
      width: "100%",
      background: '#6F83CC',
      color: 'white',
      padding: "10px",
      marginBottom: '20px',
      borderRadius: 20,
      '&:hover': {
        color: 'white',
        background: '#6F83CC'
      }
    },
    buttonBack : {
      width: "100%",
      background: '#6F83CC',
      color: 'white',
      padding: "10px",
      marginBottom: '20px',
      borderRadius: 20,
      '&:hover': {
        color: 'white',
        background: '#3451BE'
      }
    },
    error: {
      "&.MuiFormHelperText-root.Mui-error": {
        color: "red"
      }
    },
    titleOne: {
      fontFamily: "Roboto",
      fontStyle: "Medium",
      fontSize: "24px",
      lineHeight: "28px",
      align: "left",
      verticalAlign: "top",
      letterSpacing: "-0.06px",
      textAlign: "center",
      weight: 700,
    },
    formControl: {
      width: "100%"
    },
    mt10: {
      backgroundColor: '#ffffff',
      marginTop: "15px",
      borderRadius: 20,
      [`& fieldset `]: {
        borderRadius: 20,
        fontSize: 14,
      },
      [`& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input `]: {
        borderRadius: 20,
        fontSize: 14,
        backgroundColor: "#ffffff"
      },
    },
    container: {
      display: 'flex',
    },
    iconLogin: {
      color: 'black',
    },
    mt40: {
      display: 'flex',
      alignItems: 'center', 
      justifyContent: 'space-between',
    },
    regetPassword: {
       marginBottom: '15%',
       marginRight: 'auto',
       marginLeft: 'auto'
    },
    policy: {
      alignItems: 'center', 
    
  },
  papper: {
      width: '700px',
      alignContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      top: 'auto',
      margin: 'auto',
      marginTop: '15%',
  },
  footer: {
      alignContent: 'left',
      flexDirection: 'column',
      margin: 'auto',
      paddingLeft: '20px',
  },
  imgGradientRegister: {
      height: 'inherit',
      width: '50%', 
      marginBottom: '20%',
  },
  imgGradientLogin: {
    height: 'inherit',
    width: '50%', 
},
  textGradient: {
      textAlign: 'center',
      color: 'white',
      fontSize: 15,
      marginTop: '20%',
  },
  containerFormLogin:{
      position: 'sticky',
      margin: ' 5% 10% '
  },
  containerCardRegister:{
    position: 'sticky',
    margin: ' 5% 3% '
},
  containerFormRegister:{
    position: 'absolute',
    right: '10%',
    top: 0
},

  forgot: {
    fontSize: 18,
    fontFamily: 'Roboto',
    color: '#000000',
    fontWeight: 'normal',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  register: {
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center'
  },
  redirectRegister: {
    color: '#099F9F',
    fontSize: 11,
    fontWeight: 'bold',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  textRegister: {
    color: 'black',
    fontSize: 11,
    margin: '5px 0px 0px 0px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    
  },
  menuItem: {
    "&:hover": {
      borderLeft: "4px solid #098B8B",
    },
  },
  cursorPointer: {
    cursor: "pointer"
  },
  borderImageLogo: {
    
  },
  classContainerLogos: {
    flexDirection: 'row',
     alignItems: 'center',
     margin: '3% 0px',
    //  width: '60%'
  },
  classContainerLogosRegister: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: '3% 0px',
    width: '60%'
  },
  classCardLogos: {
    display: 'flex',
    flexDirection: 'column'
  },

  titleOneRecovery: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "-0.06px",
    color: '#3451BE',
    textAlign: "center"
},
titleTwo: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "-0.05px",
    textAlign: "center"
},

  stylesTypography: {
    color: '#3451BE',
    fontSize: 35,
    fontWeight: 700,
    fontFamily: "Roboto",
    textAlign: "center"
  },
  stylesTypographyRestorePassword: {
    color: '#3451BE',
    fontSize: 30,
    fontWeight: 700,
    fontFamily: "Roboto",
    textAlign: "center",
    marginBottom: '5%'
  },
  fontSize14: {
    fontSize: 14,
    textAlign: "center"
  },
   
  marginMessageSuccess : {
    marginBottom: '15%'
  },
  submitRestorePassword: {
    width: "100%",
    background: '#3451BE',
    color: 'white',
    padding: "10px",
    fontSize: 13,
    marginBottom: '20px',
    borderRadius: 20,
    '&:hover': {
      color: 'white',
      background: '#3451bebf'
    }
  },
  buttonBackRestorePassword : {
    width: "100%",
    background: '#6F83CC',
    color: 'white',
    padding: "10px",
    fontSize: 13,
    marginBottom: '20px',
    borderRadius: 20,
    '&:hover': {
      color: 'white',
      background: '#3451BE'
    }
  },

  });

  