import React,{useState, useEffect} from 'react';
import {Grid,
    FormControl,
    TextField,
    Typography,
    FormHelperText
} from "@mui/material";
import { useStateValue } from "../../../../../context/store";
import {styles} from "./style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PATH_APP } from '../../../../../constants';

const Index = (props) => {
    const classes = styles();
    const [{ valorationQuestionnaire, valorateAplication }, dispatch ] = useStateValue();
    const [, setValueQuestion] = useState()


    const schema = yup.object().shape({
     text: yup
     .string()
     .required("Este campo es requerido")
     .min(10, 'Ingrese mínimo 10 caracteres')
     .max(500, 'Ingrese máximo 500 caracteres'),
      valueTotalQuestion: yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,2}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
    })
    
    const { register, handleSubmit, trigger, formState:{errors}, setValue, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });
    
    useEffect(() => {
      if(errors['text'] && (watch('text') || !watch('text'))){
        return dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Responder cuestionario",
          index: 6,
          subPath: `${PATH_APP}/answer-questionnaire`,
          isSubmit: true,
          idForm: 'formAplication',
          module: 'ANSWER_QUESTIONARY',
          disabledSubmit: true,
          textIsSubmit: "ENVIAR",
        });
      }
     else{
       
      return dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Responder cuestionario",
        index: 6,
        subPath: `${PATH_APP}/answer-questionnaire`,
        isSubmit: true,
        idForm: 'formAplication',
        module: 'ANSWER_QUESTIONARY',
        disabledSubmit: false,
        textIsSubmit: "ENVIAR",
      });
     }
    }, [errors['text']])
    
  
    const handleOnchangeText = (e) => {
      const {name, value} = e.target;

      setValue(name, value)
      trigger(name)
      setValueQuestion(value)
    }

    const handleOnchange = (e)=> {
      const {name, value} = e.target;
  

      if(value === ''){
        setValue(name, value)
        setValueQuestion(value)
        return null
      }
      else {
        setValue(name, value)
        setValueQuestion(value)
  
        const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)
  
         // agregando nuevo valor aplicado a la pregunta y sus opciones cuando es manual
      
        dispatch({
          type: "SET_MODAL_VALORATE_APLICATION",
          icon:'WARNING',
          data: valorateAplication?.data ?  
          [...filterUuid, 
            {
              uuid: `${props?.uuid}`, 
              questionAlternativeList: [{answer: value}],
             
            }
          ] 
            : [{ uuid: `${props?.uuid}`, 
            questionAlternativeList: [{answer: value}],
           }],
        })
      }
    
    }
    
    useEffect(()=> {
        if(valorationQuestionnaire?.typeValoration === 'automatic'){
        dispatch({
          type: "SET_VALUE_QUESTIONNAIRE",
          totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
          typeValoration: '',
          activeValoration: false
        });
        }else {
          dispatch({
            type: "SET_VALUE_QUESTIONNAIRE",
            totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
            typeValoration: '',
            activeValoration: false
          });
        }
        setValue('valueTotalQuestion', valorationQuestionnaire?.totalValueQuestionnaire ? (valorationQuestionnaire?.totalValueQuestionnaire).toFixed(2) : '')
        setValueQuestion(valorationQuestionnaire?.totalValueQuestionnaire ? (valorationQuestionnaire?.totalValueQuestionnaire).toFixed(2) : '')
      },[valorationQuestionnaire?.activeValoration ])

      const onSubmit= () => {
        dispatch({
              type: "SET_MODAL_VALORATE_APLICATION",
              activeValorate: true,
              dataClear: valorateAplication?.data ?  valorateAplication?.data :[],
              data: valorateAplication?.data ?  valorateAplication?.data :[]
            })
      }

    return  <form id="formAplication" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} justifyContent="center" direction="column"  style={{ margin: 'auto', display: 'flex', justifyContent: 'flex-start', marginLeft: '3.5%' }} >
      <Grid item lg={10} style={{display: 'flex', flexDirection: 'column',  marginBottom: 20}}>
          <div style={{display: 'flex', alignItems: "center"}}>
              <Grid item lg={0.4} style={{color: '#6E6E6E'}}>{props?.position}</Grid>
              <Grid item lg={11.6}>
                <Typography className={classes.question}>{props?.question}</Typography>
              </Grid>
          </div>
          <div>
          <Grid item lg={1} md={2} style={{display: 'flex', marginLeft: '3%'}}>
            <FormControl variant='outlined'  size="small">
              <TextField
              {...register('valueTotalQuestion')}
                className={classes.dropdown}
                
                size="small"
                placeholder='Valor'
                disabled={true}
                error={!!errors.hasOwnProperty("valueTotalQuestion") && errors["valueTotalQuestion"].message}
                value={ props?.valueQuestion }
              />
            </FormControl>
          </Grid>
          </div>
        </Grid>
           <Grid lg={11.5} style={{margin: '15px 0px 10px 3.5%'}}>
                <FormControl fullWidth>
                  <>
                    <TextField
                     {...register('text')}
                     className={classes.dropdownMultiple}
                     multiline
                     placeholder="Respuesta de texto"
                    //  disabled={true}
                     rows={4}
                     InputLabelProps={{
                         shrink: true,
                         notched: true
                     }}
                     onChange={(e)=>handleOnchangeText(e)}
                     onBlur={(e)=>handleOnchange(e)}
                     rowsMax={4}
                     label="Respuesta"
                     error={!!errors.hasOwnProperty("text") && errors["text"].message}
                    />
                    <div className={classes.accountantPreview}>{`${watch('text')?.length ? watch('text')?.length : 0}/500`}</div>
                    </>
                </FormControl>
                <FormHelperText style={{color:'#d70909', marginLeft: '1%'}}>
                            {!!errors.hasOwnProperty("text") &&
                            errors["text"].message }
                </FormHelperText> 
            </Grid>
         </Grid>
         </form>
};

export default Index;
