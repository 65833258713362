import React from 'react'
import ListRelations from "./listRelations"
import { Scrollbars } from 'react-custom-scrollbars';
import { Grid, Typography } from '@mui/material';

const Index = (props) => {

  const scrollModel = () => {
    if(props?.modelDetail){
      return  <ListRelations 
                data={props?.data}
                isEdit={props?.isEdit}
                isCopy={props?.isCopy}
                sections={props?.sections}
                message={props?.message}
                setMessage={props?.setMessage}
                modelDetail={props?.modelDetail}
                currentData={props?.currentData}
                openQuestions={props?.openQuestions}
                typesEvaluators={props?.typesEvaluators}
                messageQuestions={props?.messageQuestions}
              />

    }else {
      return  <Scrollbars style={{height: 350, paddingTop: '10px !important'}}>
                <ListRelations 
                  data={props?.data}
                  isEdit={props?.isEdit}
                  isCopy={props?.isCopy}
                  sections={props?.sections}
                  message={props?.message}
                  setMessage={props?.setMessage}
                  currentData={props?.currentData}
                  openQuestions={props?.openQuestions}
                  typesEvaluators={props?.typesEvaluators}
                  messageQuestions={props?.messageQuestions}
                />
            </Scrollbars>
    }
  }

  return (
    <>
      {props?.data?.length ? <Grid item container lg={12} style={{position: 'sticky', background: props?.isEdit || props?.modelDetail || props?.isCopy ? '' :'#ffffff', marginBottom: 20}}>
        <Grid item lg={2.5} style={{textAlign: 'center', fontWeight: 500}}> Criterio</Grid>
        <Grid item container lg={9.5} style={{textAlign: 'center'}}>
          <>
            <Grid item lg={4} style={{textAlign: 'center', fontWeight: 500}}> Aspecto</Grid>
            <Grid item container lg={8} style={{textAlign: 'center'}}>
                <Grid item lg={9.4} style={{textAlign: 'center', fontWeight: 500}}> Preguntas</Grid>
                {/* <Grid item lg={2} style={{textAlign: 'center'}}>Posición</Grid> */}
                <Grid item container lg={2.6} justifyContent="space-between">
                  <Grid lg={12}>
                    <Typography style={{textAlign: 'center', fontWeight: 500}}>T. evaluación</Typography>
                  </Grid>
                <Grid item container justifyContent="space-between" lg={12}>
                  {props?.typesEvaluators?.map((x)=> (
                    <Grid item key={x?.uuid}  lg={12/props?.typesEvaluators?.length} style={{textAlign: 'center', fontWeight: 700, fontSize: 12}}>{x?.value}</Grid>
                  ))}
                </Grid>
                </Grid>
            </Grid>
          </>
        </Grid>
        </Grid>
      : null}
        {scrollModel()}
        </>
  )
}

export default Index