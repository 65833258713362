import { saveSession, loadSession  } from '../../validators/localStorage';
export const initialState = loadSession() || {
    user: {
        email: ''
    },
    authenticated: false,
    token:'',
    permissions: [],
}

const sessionUserReducer = (state = initialState, action) => {
    if (action.type === "LOGIN_SESSION" || action.type === "LOGOUT") {
        saveSession({
            ...state,
            token: action?.token, 
            user: action?.user,
            authenticated: action?.authenticated,
            permissions: action?.permissions,

        });
    }
    switch (action.type) {
        case "LOGIN_SESSION":
            return {
                ...state,
                token: action?.token, 
                user: action?.user,
                authenticated: action?.authenticated,
                permissions: action?.permissions,

            };
        case "LOGOUT":
            return {
                ...state,
                token: action.session,
                authenticated: action.authenticated,
                username: action.username
            };

        default:
            return state;
    }


};

export default sessionUserReducer;