import {  makeStyles } from "@mui/styles";

export const styles = makeStyles((theme) => ({
   key: {
    fontWeight: 700,
    fontSize: 12,
    fontFamily: 'Roboto',
    color: '#2C2C2C',
    minWidth: 'fit-content'
   },
   value: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#8E8E8E'
   },
   dropdown: {
      backgroundColor: "#ffffff",
      boxSizing: "border-box",
      borderRadius: "8px",
      fontSize: "14px",
      '& .MuiOutlinedInput-input' :{
        height: "15px !important "
      },
      [`& fieldset`]: {
        borderRadius: 8,
        fontSize: 14,
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#ffffff',
      },
    },
    dropdownDomainGreen: {
      backgroundColor: "#ffffff",
      boxSizing: "border-box",
      borderRadius: "8px",
      fontSize: "14px",
      '& .MuiOutlinedInput-input' :{
        height: "15px !important "
      },
      '& fieldset': {
        borderColor: 'green !important',
        borderWidth: 2,
        borderRadius: 8,
        fontSize: 14,
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#ffffff',
      },
    },
    dropdownDomainRed: {
      backgroundColor: "#ffffff",
      boxSizing: "border-box",
      borderRadius: "8px",
      fontSize: "14px",
      marginBottom: '2%',
      '& .MuiOutlinedInput-input' :{
        height: "15px !important "
      },
      '& fieldset': {
        borderColor: '#D63434 !important',
        borderWidth: 2,
        borderRadius: 8,
        fontSize: 14,
      },
      '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
        background: '#ffffff',
      },
    },
    totalEvaluation: {
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center'
    },
    titleLabels: {
      color: '#6E6E6E', 
      fontSize: 20, 
      fontWeight: 900, 
      marginRight: 20
    },
    titleLabelsTotal: {
      color: '#ffffff', 
      fontSize: 20, 
      fontWeight: 900, 
      marginRight: 20
    }
  }));