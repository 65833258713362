import React, { useEffect, useState } from 'react'
import { useStateValue } from "../../context/store";
import Header from './formHeader';
import ListQuestions from "./listQuestions";
import ModalValorateAplication from '../../components/common/modalValidateAplication'
import ModalConfirm from "../../utils/form/modalConfirmBase";
import { PATH_APP } from '../../constants';


const Index = (props) => {
  const [, dispatch] = useStateValue();
 
  
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
    })


  useEffect(() => {
    if(props?.isDetail){
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Detalle aplicación de cuestionario",
        index: 5,
        subPath: `${PATH_APP}/aplication`,
        idForm: 'formAplication',
        module: 'DETAIL_APLICATION',
        textIsSubmit: "GUARDAR",
      });
    }else {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Aplicar cuestionario",
        index: 5,
        subPath: `${PATH_APP}/questionary`,
        isSubmit: true,
        idForm: 'formAplication',
        module: 'MANAGE_QUESTIONNAIRE',
        textIsSubmit: "GUARDAR",
      });
    }

  }, [])


    return (
        <div >
            <Header 
                isDetail={props?.isDetail}
                message={message}
                setMessage={setMessage}
            />
             <ListQuestions 
                isDetail={props?.isDetail}
                message={message}
                setMessage={setMessage}
             /> 
            <ModalConfirm 
              description = { message.description }
              textButtonSubmit = { message.textButtonSubmit }
              handleClick = { message.handleClick }
              handleClickOut = { message.handleClickOut }
              oneButtons = { message.oneButtons }
              title = { message.title }
              type = { message.type }
              open = { message.open }
            />
             <ModalValorateAplication/>
        </div>
    )
}

export default Index
