import React, { useState } from "react";
import * as IdKey from "short-uuid";
import {
  Typography,
  Tooltip,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ModalDelete from '../../../utils/form/modalConfirmBase'
import { styles } from "./styles";
import EditCriteria from "../edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function DetailedAccordion({
  setMessage,
  message,
  data,
  setScroll,
}) {
  const classes = styles();
  const id = IdKey.generate();
  const [openEdit, setOpenEdit] = useState(false);
  const [showText, setShowText] = useState([])
  const [dataCompetencie, setDataCompetencie] = useState({})
  const [messageDelete, ] = useState({
    open: false,
    type: '',
    description: '',
    handleClickOut: () => {},
    handleClick: () => {}
  })

  
  const closeEdit = () => setOpenEdit(false)

const allShow = (id) => {
  const result = showText.find((x)=> x?.id === id)
  if(result?.show) {
    setShowText([{show: false, id: id}])
  }else {
   setShowText([{show: true, id: id}])
  }
 }

 const validateShowText = (id) => {
  const result = showText.find((x)=> x?.id === id)
   return result?.show ? true : false
 }

 const showQuestion = (data, id) => {
  const result = showText.find((x)=> x?.id === id)
  if(result?.show) {  
    return data 
  } else {
    return `${data.substring(0, 400)}` 
  }
}


if(data?.length) {
  return (
    <div className={classes.root}>
         <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"15%"} className={classes.wrapper}>
                  Código
                </TableCell>
                <TableCell align="left" width={"40%"} className={classes.wrapper}>
                Escala de valoración
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                Rango                
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Estado
                </TableCell>
                <TableCell align="left" width={"5%"} className={classes.wrapper}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, idColumn = id) => (
                <TableRow key={idColumn}>
                  <TableCell align="left" width={"15%"} className={classes.header}
                    style={{
                      borderTopLeftRadius: idColumn === 0 ? 10 : 0,
                      borderBottomLeftRadius: idColumn === row?.length - 1 ? 10 : 0
                    }}>
                    {row?.code}
                  </TableCell>
                  <TableCell align="left" width={"40%"} className={classes.header}>
                  {row?.name?.length > 300 ? showQuestion(row?.name, idColumn) : row?.name}{row?.name?.length > 300 && 
                <Typography variant="caption"
                  className={classes.expandText}
                  onClick={()=> allShow(idColumn)}>{validateShowText(idColumn) ? "...ver menos" : "...ver más" }</Typography>}               </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    {row?.rangeFrom}   -  {row?.rangeTo}
                  </TableCell> 
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    {row?.status === 'ACTIVE' ? 'Activo' : 'Inactivo'}
                  </TableCell>
                  <TableCell align="left" width={"5%"} className={classes.header}
                    style={{
                      borderTopRightRadius: idColumn === 0 ? 10 : 0,
                      borderBottomRightRadius: idColumn === row?.length - 1 ? 10 : 0
                    }} >
                         <Tooltip title="Editar" arrow >
                  <EditIcon
                    onClick={() => {setOpenEdit(true); setDataCompetencie(row)}}
                    className={classes.iconEvent}
                  />
                </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
           <ModalDelete 
            open={messageDelete.open}
            type={messageDelete.type}
            description={messageDelete.description}
            handleClick={messageDelete.handleClick}
            handleClickOut={messageDelete.handleClickOut}
          />
          <EditCriteria
            setMessage={setMessage}
            message={message}
            closeEdit={closeEdit}
            open={openEdit}
            data={dataCompetencie}

          />
    </div>
  );
      }else {
        return null;
      }
}
