import { makeStyles } from '@mui/styles';

export const styles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 1200,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.24)',
    outline: 'none',
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
  },
  cancel: {
    color: '#2F71CB',
    marginTop: '2%',
    marginBottom: 5,
    borderColor: '#80808070',
    '&:hover': {
      borderColor: '#80808070',
    },
  },
  confirm: {
    color: '#ffffff',
    background: '#2F71CB',
    marginBottom: 5,
    marginRight: '1%',
    marginTop: '2%',
    '&:hover': {
      color: '#ffffff',
      background: '#2F71CB',
    },
  },
  text: {
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#4B4B4B',
    fontWeight: 400,
    textAlign: 'center',
    margin: '25px 20px',
  },
  titleAlert: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#2C2C2C',
  },
  widthIcon: {
    fontSize: 70,
    color: '#2F71CB',
    marginTop: '3%',
  },
  confirmBanck: {
    color: '#ffffff',
    background: '#2F71CB',
    marginBottom: 5,
    '&:hover': {
      color: '#ffffff',
      background: '#2F71CB',
    },
  },
  scroll: {
    width: '100%',
    height: '450px',
    backgroundColor: '#ffffff',
    borderRadius: 10,
  },
  img: {
    width: '30%',
    paddingBottom: 15,
    margin: 0,
    paddingLeft: '8%',
  },
  titleInfo: {
    background: 'rgb(161 213 229 / 61%)',
    border: '1px solid',
    margin: 0,
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
  },
  valueInfo: {
    border: '1px solid',
    margin: 0,
    paddingLeft: '1%',
    fontSize: '20px',
    fontWeight: 500,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  titleTablePercentagesBorder: {
    border: '1px solid white',
    background: 'rgb(161 213 229 / 61%)',
    margin: 0,
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
    padding: '0.8rem 0',
  },
  titleTablePercentages: {
    background: 'rgb(161 213 229 / 61%)',
    margin: 0,
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
    padding: '0.8rem 0',
  },
  headerTable: {
    background: 'rgb(161 213 229 / 61%)',
    border: '1px solid white',
    width: '20%',
    margin: 0,
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
  },
  headerTableSpan: {
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    display: 'block',
  },
  titleGraphic: {
    width: '60%',
    height: 90,
    padding: '0px 2%',
  },
  titleGraphicSpan: {
    height: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 500,
  },
  titleGraphicPerformance: {
    width: '130%',
    height: '90px',
    padding: '0px 2%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(197, 230, 239, 0.55)',
  },

  sections: {
    // -pdf-frame-content: headerContent; /* headerContent is the #id of the element */
    top: '1cm',
    marginLeft: '1cm',
    marginRight: '1cm',
    height: '1cm',
  },
  textField: {
    background: 'rgb(237, 230, 230) !important',
    borderRadius: '15px !important',
    '& .MuiInputBase-root': {
      borderRadius: '15px !important',
    },
  },
  pagebreak: {
    pageBreakBefore: 'always',
  },
  textContainer: {
    pageBreakInside: 'avoid',
    breakInside: 'avoid',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    whiteSpace: 'normal',
  },
  pdfPage: {
    pageBreakInside: 'avoid',
  },
}));
