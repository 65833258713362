import React,{useState, useEffect} from 'react'
import { FormControl, Grid,  Checkbox,
    FormGroup,FormControlLabel, TextField,  FormHelperText, Typography } from '@mui/material'
import { PATH_APP, REQUIRED_MESSAGE, titleHeader, typesEvaluation } from '../../../constants';
import CustomizedButtonMenu from "../../createEvaluationPerformance/relationShip/relations/components/button-menu";
import ModalReviewRol from "../../createEvaluationPerformance/relationShip/relations/components/modal-review-rol";
import { getTypesEvaluators } from './../../../actions/Performance_Evaluation-action';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import {styles} from "./styles";
import {getAllUsers} from "../../../actions/AplicationQuestionary-action";
import Loading from "../../../utils/form/loading";


import * as yup from 'yup'
import { useStateValue } from '../../../context/store';
import moment  from "moment";
import _ from "lodash";
import { pushDataRelations, validWithRol } from '../../../utils/constants';


const Index = (props) => {
    const classes = styles();
    const history = useHistory();
    const params = useParams();
    
    const {status} = params;
    const pathEditApply = `-edit/${status}`;
    const [{typesEvaluations, valorationAplication, relations}, dispatch] = useStateValue();
    const [checked, setChecked] = useState({});
    const [loading, setLoading] = useState(false);
    const [valuesForm, setValuesForm ] = useState({
        status: 'APPLIED',
        startDate: '',
        endDate: ''
    })
    const [currentDay, setCurrentDay] = useState();
    const [startDatePeriod, setStartDatePeriod] = useState(); 
    const [endDatePeriod, setEndDatePeriod] = useState(); 
    const [startDateAplied, setStartDateAplied] = useState(); 
    const [, setListEvaluateds] = useState([]);
    const [typesEvaluators, setTypesEvaluators] = useState([]);
    const [typesEvaluation, setTypesEvaluation] = useState([]);

    const typeslocal = localStorage.getItem('typesEvaluation')
    const jsonTypes =  JSON.parse(typeslocal)   
    

    const schema = yup.object().shape({
        name: yup
            .string()
            .required(REQUIRED_MESSAGE)
            .min(10, 'Ingrese mínimo 10 caracteres')
            .max(500, 'Ingrese máximo 500 caracteres'),
        user: yup
            .string()
            .required(REQUIRED_MESSAGE),
        description: yup
            .string()
            .required(REQUIRED_MESSAGE)
            .min(10, 'Ingrese mínimo 10 caracteres')
            .max(1000, 'Ingrese máximo 1000 caracteres'),
        startDatePeriod: yup
            .date()
            .min(moment(new Date()).subtract(1, 'year').format('YYYY/MM/DD'), `Fecha mínima ${moment(new Date()).subtract(1, 'year').format('YYYY/MM/DD')}`)
            .required(REQUIRED_MESSAGE)
            .typeError(REQUIRED_MESSAGE)
            .test(
                "maxLenght",
                `Fecha máxima ${moment().add(1, 'year').format('YYYY/MM/DD')}`,
                val => moment(val).isSameOrBefore(moment().add(1, 'year'), 'day')
            ),
        endDatePeriod: yup
            .date()
            .required(REQUIRED_MESSAGE)
            .typeError(REQUIRED_MESSAGE)
            .test("min", `Ingrese una superior a la fecha de evaluación `, val => moment(startDatePeriod).format('YYYY-MM-DD') < moment(val).format('YYYY-MM-DD'))
            .test(
                "maxLenght",
                `Fecha máxima ${moment(startDatePeriod).add(1, 'year').format('YYYY/MM/DD')}`,
                val => moment(startDatePeriod).isSameOrAfter(moment()) ? moment(val).isSameOrBefore(moment(startDatePeriod).add(1, 'year'), 'day') : moment(val).isSameOrBefore(moment().add(1, 'year'), 'day')
            ),
        startDateAplied: yup
            .date()
            .test("min", `Ingrese mínimo la fecha de evaluación inicial`, val => moment(startDatePeriod).format('YYYY-MM-DD') <= moment(val).format('YYYY-MM-DD'))
            .required(REQUIRED_MESSAGE)
            .typeError(REQUIRED_MESSAGE)
            .test("maxLenght", `Fecha maxima ${moment(endDatePeriod).format('YYYY-MM-DD')}`, val => moment(val).format('YYYY-MM-DD') <= endDatePeriod),
        endDateAplied: yup
            .date()
            .test("min", `Ingrese mínimo una fecha mayor a la inicial de aplicacion`, val => moment(startDateAplied).add(1, 'day').format('YYYY-MM-DD') <= moment(val).format('YYYY-MM-DD'))
            .required(REQUIRED_MESSAGE)
            .typeError(REQUIRED_MESSAGE)
            .test(
                "maxLenght",
                `Fecha máxima ${moment(startDateAplied).add(1, 'year').format('YYYY/MM/DD')}`,
                val => moment(startDateAplied).isSameOrAfter(moment()) ? moment(val).isSameOrBefore(moment(startDateAplied).add(1, 'year'), 'day'): moment(val).isSameOrBefore(moment().add(1, 'year'), 'day')
            ),
        applicationName: yup
            .string()
            .max(150, 'Ingrese máximo 150 caracteres')
            .required(REQUIRED_MESSAGE),
    })
    const {register, handleSubmit, setValue,  clearErrors, watch, trigger, formState:{errors }, getValues } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        reValidateMode: 'onChange'
    })

      const notDisaledSubmit = () => dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Aplicar modelo de evaluación",
        index: 12,
        idForm: 'Evaluation',
        module: 'EVALUATION_EVALUATOR',
        subPath: `${PATH_APP}/evaluation-performance-evaluator`,
        isSubmit: true,
        disabledSubmit: false,
        textIsSubmit: "CONTINUAR",
      }); 

    
      const [messageModalReviewRol, setMessageModalReviewRol] = useState({
        description: '',
        textButtonSubmit: '',
        currentData: null,
        oneButtons: () => { },
        handleClick: () => { },
        handleClickOut: () => { },
        title: '',
        open: false
      })

      const backToFormInitial = () =>  history.push(`${PATH_APP}/evaluation-performance-evaluator`);
      const getTypes = async() => {
          const result = await getTypesEvaluators(dispatch);
          if(result?.data?.length) {
              setTypesEvaluation(result?.data?.map((x)=> ({...x, id: x?.uuid })))
              setTypesEvaluators(result?.data?.map((x)=> ({id: x?.uuid, value: titleHeader(x?.abbreviation)})))
          }else {
              return props?.setMessage({
                  open : true,
                  description: 'Se presento un error en el servicio de tipos de evaluadores',
                  handleClick : () => backToFormInitial() ,
                  oneButtons : true,
                  textButtonSubmit: 'ACEPTAR',
                  type : 'WARNING',
              })
          }
          
        }
  
  
      
      const closeModalReviewRol = () => setMessageModalReviewRol({ ...messageModalReviewRol, currentData: null, open: false })
      const closeModalValidation = () => props?.setMessage({ ...props?.message, open: false });
       
        const handleDataFromButtonMenu = (idRol) => {
  
          let roleName = typesEvaluation?.find(item => item.id === idRol)?.name;
          const dataByRol = idRol ? {competences: validWithRol(relations?.data, idRol), roleName} : [];
        
          if (!dataByRol?.competences?.length) {
            props?.setMessage({
              open: true,
              description: "No se han relacionado preguntas para el rol",
              handleClick: closeModalValidation,
              oneButtons: true,
              textButtonSubmit: 'ACEPTAR',
              type: 'WARNING',
            });
          } else {
            setMessageModalReviewRol({
              ...messageModalReviewRol,
              currentData: {performanceEvaluation: dataByRol},
              open: true,
              oneButtons: true,
              handleClick: closeModalReviewRol,
            });
          }
        };

    const closeModal = () => {props?.setMessage({...props?.message, open:false}); notDisaledSubmit()}

  
    const onSubmit = (data) => {

          let valueCompetence = 0;
          let valueBehavior = 0;

          if(!checked?.name ){
            return props?.setMessage({
                open : true,
                description: `Seleccione el tipo de valoración que desea, manual o automático`,
                handleClick : () => closeModal() ,
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                type : 'WARNING',
              })
            }


        for (let index = 0; index < (status === "APPLIED" ? props?.dataDetail?.performanceEvaluation?.competences?.length:props?.dataDetail?.competences?.length); index++) {
   
            if(!valorationAplication?.data?.competences[index]?.value ){
            
                return props?.setMessage({
                    open : true,
                    description: `Criterio #${valorationAplication?.data?.competences[index]?.position +1} sin evaluar`,
                    handleClick : () => closeModal() ,
                    oneButtons : true,
                    textButtonSubmit: 'ACEPTAR',
                    type : 'WARNING',
                  })
        
        } 
            for (let b = 0; b < valorationAplication?.data?.competences[index]?.conducts?.length; b++) {
         
                if (!valorationAplication?.data?.competences[index]?.conducts[b]?.value) {
                    return props?.setMessage({
                        open : true,
                        description: `Aspecto #${b+1} del criterio #${index+1} sin evaluar`,
                        handleClick : () => closeModal() ,
                        oneButtons : true,
                        textButtonSubmit: 'ACEPTAR',
                        type : 'WARNING',
                      })
                }


        }

        valueCompetence += Number(valorationAplication?.data?.competences[index]?.value) 
        

        for (let b = 0; b < valorationAplication?.data?.competences[index]?.conducts?.length; b++) {
            valueBehavior += Number(valorationAplication?.data?.competences[index]?.conducts[b]?.value)
        }


        if( Math.round(valueBehavior) !== 100) {
          
            return props?.setMessage({
                open : true,
                description: `La suma de los aspectos del criterio #${index+1} debe ser igual a 100`,
                handleClick : () => closeModal() ,
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                type : 'WARNING',
              })
        }else {
            valueBehavior = 0;
            continue
        }

  
    }


    if(Math.round(valueCompetence) !== 100) {
        return props?.setMessage({
            open : true,
            description: `La suma de los criterios debe ser igual a 100`,
            handleClick : () => closeModal() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
    }

    const newDataHeader = {
        startDate: moment(data?.startDateAplied).format('YYYY-MM-DD'),
        endDate: moment(data?.endDateAplied).format('YYYY-MM-DD'),
        startDatePeriod: moment(data?.startDatePeriod).format('YYYY-MM-DD'),
        endDatePeriod: moment(data?.endDatePeriod).format('YYYY-MM-DD'),
        applicationName: data?.applicationName,
        automatic: valorationAplication?.typeValoration === "Automatic" ? true : false,
        competences :valorationAplication?.data?.competences?.map((x,index)=> (
            {
                uuid: x?.uuid,  
                valueApplied: Number(x?.value).toFixed(2),  
                conducts:  x?.conducts?.map((c, pos=index)=> (
                    {
                        uuid: c?.uuid,
                        valueApplied: Number(c?.value).toFixed(2),
                    }
                ))       
            }
         ))
      }

      dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: jsonTypes?.automatic,
        applicationTypes: typesEvaluations?.applicationTypes,
        submitPercentage: typesEvaluations?.submitPercentage,
        selectAll: typesEvaluations?.selectAll,
        users: typesEvaluations?.users,
        dataEvaluation: newDataHeader,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders
      });
      
      history.push(`${PATH_APP}/evaluation-performance/evaluator/aplication${status==='APPLIED' ? pathEditApply : `/${props?.id}/type-evaluation` }`)

      if(jsonTypes?.users?.length){
        const dataLocalStorage = {
            automatic: jsonTypes?.automatic,
            applicationTypes: jsonTypes?.applicationTypes ?? [],
            submitPercentage: jsonTypes?.submitPercentage,
            selectAll: jsonTypes?.selectAll,
            users: jsonTypes?.users,
            dataEvaluation: newDataHeader,
            pages: jsonTypes?.pages,
            selectNewLeaders: jsonTypes?.selectNewLeaders
          }
          localStorage.setItem('typesEvaluation', JSON.stringify(dataLocalStorage))
      }else {

          const dataLocalStorage = {
            automatic: jsonTypes?.automatic,
            applicationTypes: jsonTypes?.applicationTypes ?? [],
            submitPercentage: typesEvaluations?.submitPercentage,
            selectAll: typesEvaluations?.selectAll,
            users: typesEvaluations?.users,
            dataEvaluation: newDataHeader,
            pages: typesEvaluations?.pages,
            selectNewLeaders: typesEvaluations?.selectNewLeaders
          }
          localStorage.setItem('typesEvaluation', JSON.stringify(dataLocalStorage))
      }
}



    useEffect(() => {
        window.scrollTo(0,0)
        getTypes();
        pushDataRelations(props?.dataDetail, dispatch);
        if(props?.dataDetail?.name){
            getEvaluteds()
            setValue('name', props?.dataDetail?.name)
            setValue('user', props?.dataDetail?.creatorUser?.surname)
            setValue('description', props?.dataDetail?.description)
            setValue('endDate',moment(props?.dataDetail?.endDate).format('YYYY-MM-DD'))
            setValue('startDate',moment(props?.dataDetail?.startDate).format('YYYY-MM-DD'))
            setValuesForm(
                {
                    ...valuesForm, 
                    startDate: moment(props?.dataDetail?.startDate).format('YYYY-MM-DD'),
                    endDate: moment(props?.dataDetail?.endDate).format('YYYY-MM-DD')
                }
                )

           
        }

        if(status === 'APPLIED'){
            setChecked({name: props?.dataDetail?.automatic ? 'Automatic' : 'Manual'})
            setValue('endDateAplied',moment(props?.dataDetail?.endDate).format('YYYY-MM-DD'))
            // setValue('startDateAplied',moment(props?.dataDetail?.startDate).format('YYYY-MM-DD'));
            const data = props?.dataDetail?.performanceEvaluation
            getEvaluteds()
            setValue('name', data?.name)
            setValue('user', data?.creatorUser?.surname)
            setValue('description', data?.description)
            setValue('endDate',moment(data?.endDate).format('YYYY-MM-DD'))
            setValue('startDate',moment(data?.startDate).format('YYYY-MM-DD'))
            setValuesForm(
                {
                    ...valuesForm, 
                    startDate: moment(data?.startDate).format('YYYY-MM-DD'),
                    endDate: moment(data?.endDate).format('YYYY-MM-DD')
                }
                )
            
            const newDataHeader = {
                startDate: moment(props?.dataDetail?.performanceEvaluation?.startDate).format('YYYY-MM-DD'),
                endDate: moment(props?.dataDetail?.performanceEvaluation?.endDate).format('YYYY-MM-DD'),
                automatic: props?.dataDetail?.automatic === "Automatic" ? true : false,
                competences : props?.dataDetail?.performanceEvaluation?.competences?.map((x,index)=> (
                    {
                        uuid: x?.uuid,  
                        valueApplied: Number(x?.value).toFixed(2),  
                        conducts:  x?.conducts?.map((c, pos=index)=> (
                            {
                                uuid: c?.uuid,
                                valueApplied: Number(c?.value).toFixed(2),
                            }
                        ))       
                    }
                    ))
                }

            const dataLocalStorage = {
                automatic: props?.dataDetail?.automatic,
                applicationTypes: props?.dataDetail?.applicationTypes?.map((x, index)=> ({type: x?.type , label: typesEvaluation[x?.type], position:index, percentage: x?.percentage})),
                submitPercentage: typesEvaluations?.submitPercentage,
                selectAll: false,
                users: typesEvaluations?.users,
                dataEvaluation: newDataHeader,
                pages: [],
                selectNewLeaders: []
                }
                localStorage.setItem('typesEvaluation', JSON.stringify(dataLocalStorage))

        }
     
    }, [])

    useEffect(() => {
        const typeslocal = localStorage.getItem('typesEvaluation')
        const jsonTypes =  JSON.parse(typeslocal)
        if(jsonTypes?.dataEvaluation?.competences?.length){

            setChecked({name: jsonTypes?.dataEvaluation?.automatic ? 'Automatic' : 'Manual'})
            setValue('endDateAplied',moment(jsonTypes?.dataEvaluation?.endDate).format('YYYY-MM-DD'))
            setValue('startDateAplied',moment(jsonTypes?.dataEvaluation?.startDate).format('YYYY-MM-DD'));
            setValue('startDatePeriod',moment(jsonTypes?.dataEvaluation?.startDatePeriod).format('YYYY-MM-DD'))
            setValue('endDatePeriod',moment(jsonTypes?.dataEvaluation?.endDatePeriod).format('YYYY-MM-DD'))
            setValue('applicationName', jsonTypes?.dataEvaluation?.applicationName)

        }
       
        if(status === 'APPLIED'){
            setChecked({name: props?.dataDetail?.automatic ? 'Automatic' : 'Manual'})
            setValue('endDateAplied',moment(props?.dataDetail?.endDate).format('YYYY-MM-DD'))
            // setValue('startDateAplied',moment(props?.dataDetail?.startDate).format('YYYY-MM-DD')) 
        }
        dispatch({
            type: "SET_VALORATE_APLICATION",
            disabledValors: true,
            data: {competences : _.orderBy(props?.dataDetail?.performanceEvaluation?.competences, ['position'],["asc"])}
        })
    
      }, [])

      const handleChangeDate = (e) => {
        const {value, name} = e.target;
        setValue(name, moment(value).format('YYYY-MM-DD'));
        const fieldsDate = ['endDatePeriod', 'startDateAplied', 'endDateAplied']
        if(getValues()?.endDatePeriod){
            for (let index = 0; index < fieldsDate?.length; index++) {
                setValue(fieldsDate[index], '')
                
            }
            clearErrors(fieldsDate)
        }
    }


    useEffect(() => {
        setCurrentDay(watch(' '))
    }, [watch('startDateAplied')]);

    useEffect(() => {
        if (watch('endDatePeriod')) {
            trigger('endDatePeriod')
        }

    }, [watch('endDatePeriod')]);

    useEffect(() => {
        setStartDatePeriod(watch('startDatePeriod'))
    }, [watch('startDatePeriod')]);

    useEffect(() => {
        setEndDatePeriod(watch('endDatePeriod'))
    }, [watch('endDatePeriod')]);

    useEffect(() => {
        setStartDateAplied(watch('startDateAplied'))
    }, [watch('startDateAplied')]);

    const getEvaluteds = async() => {
        const result = await getAllUsers({rol: "EVALUATED"});
        setListEvaluateds(result?.data ? result?.data : [])
    }


    const handleChange = (e)=> {
        const {value, name} = e.target
        setValue(name, value)
        setValuesForm({...valuesForm, [name]:value})
        
    }

    useEffect(() => {
        setTimeout(()=> {
            if(checked?.name === 'Automatic'){
                let compe = 0;
                let beha = 0;
    
                for (let index = 0; index < (status === "APPLIED" ? props?.dataDetail?.performanceEvaluation?.competences?.length:props?.dataDetail?.competences?.length); index++) {
                  compe++
                    for (let b = 0; b < valorationAplication?.data?.competences[index]?.conducts?.length; b++) {
                        beha++
                    }
                }
    
            dispatch({
                type: "SET_VALORATE_APLICATION",
                disabledValors: true,
                typeValoration: "Automatic",
                data:  {competences :valorationAplication?.data?.competences?.map((x,index)=> (
                    {
                        uuid: x?.uuid,  
                        value: `${100/compe}`,  
                        position: index,
                        conducts:  x?.conducts?.map((c, pos=index)=> (
                            {
                                uuid: c?.uuid,
                                position: pos,
                                value: `${100/x?.conducts?.length}`,
                                questions: c?.questions?.map((q, posQ=index)=> (
                                    {
                                        uuid: q?.uuid,
                                        value: q?.value ? q?.value : '',
                                        position: posQ
                                    }
                                ))
                            }
                        ))       
                    }
                 ))}
             })
             setLoading(false)
            }else if(checked?.name === 'Manual') {
                
                dispatch({
                    type: "SET_VALORATE_APLICATION",
                    disabledValors: false,
                    typeValoration: 'Manual',
                    data:  {competences :valorationAplication?.data?.competences?.map((x,index)=> (
                        {
                            uuid: x?.uuid,  
                            value: '',  
                            position: index,
                            conducts:  x?.conducts?.map((c, pos=index)=> (
                                {
                                    uuid: c?.uuid,
                                    position: pos,
                                    value: '',
                                    questions: c?.questions?.map((q, posQ=index)=> (
                                        {
                                            uuid: q?.uuid,
                                            value: q?.value ? q?.value : '',
                                            position: posQ
                                        }
                                    ))
                                }
                            ))       
                        }
                     ))}
                })
                setLoading(false)
            }

        },1000)
    }, [checked?.name])
    

    const handleChangeValor = (e) => {
        if(status === 'APPLIED') return
        const {name} = e.target
        setChecked({name: name })
        setLoading(true)

    }

     const validateErros = () => {
        const errorsPeriod = errors['startDatePeriod'] || errors['endDatePeriod'];
        const errorsAplied = errors['startDateAplied'] || errors['endDateAplied']
        if(errorsPeriod){
            return 20
        }
        if(errorsAplied ){
            return 20
        }  else {
            return null
        }
     }

     useEffect(()=> {
        if(watch('endDateAplied') && errors['endDateAplied']){
            setValue('endDateAplied', '')
            clearErrors('endDateAplied')
        }
       
     },[watch('startDateAplied')])

  return (
        <>
            <form id="Evaluation"  onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} maxWidth="inherit" alignItems="flex-start" 
                    style={{ background: props?.isEdit ? 'rgb(239 239 239)': null}}
                >
                
                    <Grid item lg={3.6} style={{display:'flex ', flexDirection: 'column', marginBottom: validateErros()}}>
                            <div >
                                <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Periodo de evaluación</Typography>
                                <div style={{display:'flex ', flexDirection: 'row' }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                        <FormControl fullWidth size="small">
                                        <TextField 
                                            {...register('startDatePeriod')}
                                            size="small"
                                            type="date"
                                            label="Desde"
                                            inputProps={{
                                                min: moment(new Date()).subtract(1, 'year').format('YYYY-MM-DD'),
                                                max: moment(new Date()).add(1, 'year').format('YYYY-MM-DD')
                                            }}
                                            onChange={(e)=> handleChangeDate(e)}
                                            className={classes.fieldDateTime}
                                            notched="true"
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.hasOwnProperty("startDatePeriod") && errors["startDatePeriod"].message }
                                        />
                                        <FormHelperText style={{color:'#d70909'}}>
                                            {!!errors.hasOwnProperty("startDatePeriod") &&
                                            errors["startDatePeriod"].message}
                                        </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                                    <FormControl fullWidth size="small">
                                        <TextField 
                                            {...register('endDatePeriod')}
                                            size="small"
                                            type="date"
                                            label="Hasta"
                                            inputProps={{
                                                min: moment(watch('startDatePeriod')).add(1, 'day').format('YYYY-MM-DD'),
                                                max: moment(new Date(watch('startDatePeriod'))).add(1, 'year').add(1, 'day').format('YYYY-MM-DD')
                                            }}
                                            disabled={!getValues()?.startDatePeriod || errors['startDatePeriod']}
                                            onChange={handleChange}
                                            className={classes.fieldDateTime}
                                            notched="true"
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.hasOwnProperty("endDatePeriod") && errors["endDatePeriod"].message }
                                        />
                                        <FormHelperText style={{color:'#d70909'}}>
                                            {!!errors.hasOwnProperty("endDatePeriod") &&
                                            errors["endDatePeriod"].message}
                                        </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </div>
                            </div>
                    </Grid>
                    
                    <Grid item lg={3.4} md={6} fullWidth style={{marginBottom: errors && validateErros(), marginTop: '2%' }}>
                        <FormControl fullWidth variant='outlined' size='small'>
                            <TextField 
                                {...register('user')}
                                fullWidth
                                size='small'
                                disabled
                                label="Usuario creador"
                                placeholder='Usuario creador'
                                multiline
                                margin='none'
                                maxRows={15}
                                className={classes.dropdownMultiple}
                                error={!!errors.hasOwnProperty("user") && errors["user"].message}
                            />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("user") &&
                            errors["user"].message}
                        </FormHelperText>
                        </FormControl>
                    </Grid> 
                    <Grid item lg={5} style={{display:'flex ', flexDirection: 'column', marginBottom: validateErros() }}>
                            <div >
                                <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Periodo de aplicación</Typography>
                                <div style={{display:'flex ', flexDirection: 'row' }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                        <FormControl fullWidth size="small">
                                        <TextField 
                                            {...register('startDateAplied')}
                                            size="small"
                                            type="date"
                                            label="Desde"
                                            disabled={!getValues()?.endDatePeriod || errors['endDatePeriod']}
                                            className={classes.fieldDateTime}
                                            notched="true"
                                            inputProps={{
                                                min: moment(new Date(watch('startDatePeriod'))).add(1, 'day').format('YYYY-MM-DD'),
                                                max: moment(new Date(watch('endDatePeriod'))).add(1, 'day').format('YYYY-MM-DD')
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.hasOwnProperty("startDateAplied") && errors["startDateAplied"].message }
                                        />
                                        <FormHelperText style={{color:'#d70909'}}>
                                            {!!errors.hasOwnProperty("startDateAplied") &&
                                            errors["startDateAplied"].message}
                                        </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                                    <FormControl fullWidth size="small" >
                                        <TextField 
                                            {...register('endDateAplied')}
                                            size="small"
                                            type="date"
                                            label="Hasta"
                                            disabled={!getValues()?.startDateAplied || errors['startDatePeriod'] || !getValues()?.endDatePeriod || errors['endDatePeriod'] || !getValues()?.startDateAplied || errors['startDateAplied']}
                                            className={classes.fieldDateTime}
                                            notched="true"
                                            inputProps={{
                                                min: moment(new Date(watch('startDateAplied'))).add(2, 'days').format('YYYY-MM-DD'),
                                                max: moment(new Date(watch('startDateAplied'))).add(1, 'year').add(1, 'day').format('YYYY-MM-DD')
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.hasOwnProperty("endDateAplied") && errors["endDateAplied"].message }
                                        />
                                        <FormHelperText style={{color:'#d70909'}}>
                                            {!!errors.hasOwnProperty("endDateAplied") &&
                                            errors["endDateAplied"].message}
                                        </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </div>
                            </div>
                    </Grid>
                    <Grid item lg={12} md={12} fullWidth>
                        <FormControl fullWidth variant='outlined' size='small'>
                            <TextField 
                                {...register('applicationName')}
                                fullWidth
                                size='small'
                                label="Nombre de la aplicación "
                                placeholder='Nombre de la aplicación'
                                multiline
                                margin='none'
                                maxRows={15}
                                className={classes.dropdownNotDisabled}
                                error={!!errors.hasOwnProperty("applicationName") && errors["applicationName"].message}
                            />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("applicationName") &&
                            errors["applicationName"].message}
                        </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} fullWidth>
                        <FormControl fullWidth variant='outlined' size='small'>
                            <TextField 
                                {...register('name')}
                                fullWidth
                                size='small'
                                label="Nombre de la evaluación"
                                placeholder='Nombre de la evaluación'
                                multiline
                                disabled
                                margin='none'
                                maxRows={15}
                                className={classes.dropdownMultiple}
                                error={!!errors.hasOwnProperty("name") && errors["name"].message}
                            />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("name") &&
                            errors["name"].message}
                        </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} fullWidth style={{marginBottom: props?.isEdit ?'4%': null}}>
                        <FormControl fullWidth variant='outlined' size='small'>
                            <TextField 
                                {...register('description')}
                                fullWidth
                                size='small'
                                multiline
                                minRows={2}
                                maxRows={20}
                                disabled
                                label="Descripción"
                                placeholder='Descripción'
                                className={classes.dropdownMultiple}
                                error={!!errors.hasOwnProperty("description") && errors["description"].message}
                            />
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("description") &&
                            errors["description"].message}
                        </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} style={{display: 'flex', alignItems: 'center', }}>
                        <Typography style={{fontSize: 12, fontWeight: 700, margin: '3% 2% 3% 1%'}}>valorar :</Typography>
                        <FormGroup >
                            <FormControlLabel
                                style={{width: 'max-content', fontSize: 14, color: '#6E6E6E'}}
                                control={
                                <Checkbox 
                                checked={checked?.name === "Manual"} onChange={handleChangeValor} name="Manual" 
                                />
                                }
                                label="Manual "
                            />
                        </FormGroup>
                            <FormGroup >
                            <FormControlLabel
                                style={{width: 'max-content', color: '#6E6E6E'}}
                                control={
                                <Checkbox 
                                checked={checked?.name  === "Automatic"} onChange={handleChangeValor} name="Automatic" 
                                />
                                }
                                label="Automático"
                            />
                            </FormGroup>
                    </Grid>
                </Grid>
            
            </form>
            <Grid item container lg={12} md={12} justifyContent="flex-start" style={{margin: '0% 0% 2% 0%'}}>
                <CustomizedButtonMenu onDataSend={handleDataFromButtonMenu} typesEvaluators={typesEvaluation}/>
            </Grid>
            <Loading active={loading}/>
            <ModalReviewRol
                handleClick={messageModalReviewRol.handleClick}
                handleClickOut={messageModalReviewRol.handleClickOut}
                oneButtons={messageModalReviewRol.oneButtons}
                open={messageModalReviewRol.open}
                currentData= {messageModalReviewRol.currentData}
            />
        </>
        )
}

export default Index