import React, { useState } from "react";
import {
  AccordionSummary,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ModalDelete from '../../utils/form/modalConfirmBase'
import DeleteIcon from '@mui/icons-material/Delete';
import { withStyles, makeStyles } from "@mui/styles";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShowDetail from "./detailQuestionary";
import { useStateValue } from "../../context/store";
import { useHistory } from "react-router-dom";
import {deleteQuestionary} from '../../actions/questionary-actions';
import EditQuestionary from './editQuestionary';
import moment from 'moment';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PATH_APP } from "../../constants";

const useStyles = makeStyles((theme) => ({
  MuiIconButtonEdgeEnd: {
    order: "-1",
  },
  root: {
    [theme.breakpoints.down('md')]: {
        flex: "1 1 auto",
        margin: "25% 2% 0% 2%",
        minHeight: "80vh",
        borderRadius: 10
      },
      [theme.breakpoints.up('md')]: {
        width: "90%",
        borderRadius: 10

      },
      [theme.breakpoints.up('lg')]: {
        margin: '24px 0% auto 2%',
        borderRadius: 10

      },
  },
  rootGrid: {
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    color: '#8E8E8E'
  },
  secondaryHeading: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  iconEvent: {
    color: "#646464",
    cursor: "pointer",
    height: 18,
    width: 18,
    pointerEvents: 'auto',
    '&:hover':{
      color: "#2F71CB",
    }
  },
  details: {
    alignItems: "center",
    backgroundColor: "#EEFBFF",
    pointerEvents: 'auto'
  },
  column: {
    flexBasis: "39.6%",
    padding: '1.5% 0px',
    background: '#FFFFFF', 
    textAlign: 'center'
  },
  columnOneTitle:{
    flexBasis: "39.6%",
    padding: '1.5% 0px',
    background: '#FFFFFF',
    
  },
  columnEvent: {
    paddingLeft: "20px",
    flexBasis: "33.33%",
    display: 'flex',
    justifyContent: 'end',
    marginRight: '5%',
    alignItems: 'center'
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  titlesTable: {
    fontSize: 12, 
    display: 'flex', 
    color: '#8E8E8E', 
    width: '100%', 
    marginLeft: '1%', 
    position:'relative', 
    marginBottom: -8
  },
  header: {
    color: "#8E8E8E",
    fontSize: 14,
  },
  wrapper: {
    color: "#8E8E8E",
    fontSize: 14,
    fontWeight: 700,
  },
  table: {
      "& MuiTable-root": {
        backgroundColor: 'inherit'
      },
    "& .MuiTableCell-root": {
      backgroundColor: 'inherit',
      borderBottom: 'inherit'
    },
    "& .MuiTableCell-head": {
      paddingBottom: 0,
    },
    "& .MuiTableBody-root": {
      backgroundColor: 'white',
      borderRadius: '50rem !important'

    },
  },
}));

export default function DetailedAccordion({
  dataQuestionary,
  onView,
  onEdit,
  onDelete,
  onApprove,
  onPublish,
  onClose,
  onReject,
  showEdit,
  showDelete,
  maestras,
  permissionsRequisition,
  permissionsConstans
}) {

  const classes = useStyles();
  const history = useHistory();
  const [{ questionarySearch, dataAplication }, dispatch] = useStateValue();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState([])
  const [dataEdit, setDataEdit] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [messageDelete, setMessageDelete] = useState({
    open: false,
    type: '',
    description: '',
    title: '',
    textButtonSubmit: '',
    handleClickOut: () => {},
    handleClick: () => {},
    oneButtons: false
  })

  const closeDelete = () => setMessageDelete({...messageDelete, open:false})

  const changeStateModal = () => setOpenModal(!openModal)
  const changeStateModalDetail = () => setOpenDetail(!openDetail)
  const changeOpenEdit = () => {
    dispatch({
      type: "SET_MODAL",
      openEdit: true
    })
  } 
  const confirmOpenDelete = (x) => {
    setMessageDelete({
      open: true,
      description: '¿Está seguro de eliminar el cuestionario?',
      type: 'WARNING',
      handleClickOut: () => closeDelete(),
      handleClick: () => deleteQuestionarys(x)
    })
  }
  const FlippedOrderExpansionPanelSummary = withStyles({
    root: {
      flexDirection: "row-reverse",
    },
  })(AccordionSummary);
  FlippedOrderExpansionPanelSummary.defaultProps = {
    IconButtonProps: { edge: "start" },
  };

 
  const handleCloseModalEdit = () => {
    closeDelete()
    dispatch({
      type: "SET_FILTER_QUESTIONARY",
      filter: questionarySearch?.filter,
      isFilter: true,
      typeAction: 'DELETE'
    })
  }

  const deleteQuestionarys = async(x) => {
    closeDelete();
    const result = await deleteQuestionary(x?.uuid)
    if (result && (result?.status === 200 || result?.status <= 204)) {
      return  setMessageDelete({
        open : true,
        type : 'SUCCESS',
        oneButtons : true,
        title: '¡Proceso exitoso!',
        textButtonSubmit: 'ACEPTAR',
        handleClick : () => handleCloseModalEdit(),
        description: 'Información eliminada correctamente ',
          })
     }
     else if (result?.status === 400) {
      setMessageDelete({
        open : true,
        description: result?.data?.message,
        handleClick : () => closeDelete() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }else if (result?.status > 399) {
      setMessageDelete({
        open : true,
        description: 'Error al guardar la información',
        handleClick : () => closeDelete() ,
        oneButtons : true,
        type : 'WARNING',
      })
     } else {
      setMessageDelete({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => closeDelete() ,
        oneButtons : true,
        type : 'WARNING',
      })
     }
  }

  const getDataAplication = (x) =>{
    dispatch({
      type: "SET_DATA_QUESTIONNAIRE",
      data: x,
      
    })
    ;
    if(dataAplication?.data){
      history.push(`${PATH_APP}/assess-cuestionnaire`);
    }else {
      return null
    }
  }
  const closeEdit = () => setOpenEdit(!openEdit)
const validateStatus = (x) => {
  if(x === 'ACTIVE'){
    return 'Activo'
  } else if (x === 'INACTIVE'){
    return 'Inactivo'
  } else if (x === 'APPLIED'){
    return 'Aplicado'
  }
}

  if(dataQuestionary.length){
  return (
    <React.Fragment>
      <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"29%"} className={classes.wrapper}>
                  Fecha de creación
                </TableCell>
                <TableCell align="left" width={"27%"} className={classes.wrapper}>
                  Cuestionario
                </TableCell>
                <TableCell align="left" width={"22%"} className={classes.wrapper}>
                  Estado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataQuestionary.map((x, index) => (
                <TableRow key={index}>
                  <TableCell align="left" width={"29%"} className={classes.header}
                    style={{
                      background: '#FFFFFF', borderTopLeftRadius: index === 0 ? 10 : 0,
                      borderBottomLeftRadius: index === dataQuestionary.length - 1 ? 10 : 0
                    }}>
                    <Typography className={classes.heading} style={{ marginLeft: '10%' }}>
                      {moment(x?.createdAt).format('DD/MM/YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" width={"27%"} className={classes.header}>
                    <Typography className={classes.heading} >
                      {x?.name}
                    </Typography>             </TableCell>
                  <TableCell align="left" width={"22%"} className={classes.header}>
                    <Typography className={classes.heading} >
                      {validateStatus(x?.status)}
                    </Typography>                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}
                    style={{
                      background: '#FFFFFF', borderTopRightRadius: index === 0 ? 10 : 0,
                      borderBottomRightRadius: index === dataQuestionary.length - 1 ? 10 : 0,
                    }}  >
                    <div className={classes.columnEvent} >
                      <Tooltip title="Ver detalle" arrow placement="bottom">
                        <VisibilityIcon
                          onClick={() => { setOpenDetail(!openDetail); setDataDetail(x) }}
                          className={classes.iconEvent} />
                      </Tooltip>
                      &nbsp;&nbsp;
                      {x?.status === "APPLIED" ? null :

                        <Tooltip title="Editar" arrow >
                          <EditIcon
                            onClick={() => { setOpenEdit(true); changeOpenEdit(); setDataEdit(x) }}
                            className={classes.iconEvent}
                          />
                        </Tooltip>
                      }
                      &nbsp;&nbsp;
                      {x?.status === "APPLIED" ? null :
                        <Tooltip title="Eliminar" arrow >
                          <DeleteIcon
                            onClick={() => confirmOpenDelete(x)}
                            className={classes.iconEvent}
                          />
                        </Tooltip>
                      }
                      <Button
                        variant="contained"
                        disableElevation
                        disabled={x?.status === 'ACTIVE' || x?.status === 'APPLIED' ? false : true}
                        onClick={() => getDataAplication(x)}
                        style={{
                          background: x?.status === 'ACTIVE' || x?.status === 'APPLIED' ? '#2F71CB' : '#BEBEBE',
                          color: '#ffffff', marginLeft: '3%'
                        }}
                      >
                        APLICAR
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    {/* <Grid container direction="row"  className={classes.root} cellspacing="0">
     <div className={classes.titlesTable}>
       <Typography style={{fontSize: 12, flexBasis: '29%'}}>Fecha de creación</Typography>
       <Typography style={{fontSize: 12, flexBasis: '27%'}}>Cuestionario</Typography>
       <Typography style={{fontSize: 12, flexBasis: '22%'}}>Estado</Typography>
      </div>
      {dataQuestionary.map((x, index) => ( 
        <React.Fragment key={index}>
      <Grid container  style={{  pointerEvents: 'auto', borderRadius: 10,  }}  id={id}>
        <Grid item lg={2} xs={2.5} className={classes.columnOneTitle} 
            id={id} 
            style={{  background: '#FFFFFF', borderTopLeftRadius: index === 0 ? 10 : 0, 
                      borderBottomLeftRadius: index === dataQuestionary.length -1 ? 10 : 0 }}
            >
          <Typography className={classes.heading} style={{marginLeft: '10%'}}>
            {moment(x?.createdAt).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item lg={4} xs={3} className={classes.column} > 
          <Typography className={classes.heading} >
            {x?.name}
          </Typography>
        </Grid>
        <Grid item lg={2} xs={2} className={classes.column} >  
          <Typography className={classes.heading} >
            {validateStatus(x?.status) }
          </Typography>
        </Grid>
        <Grid item lg={3.8} xs={4} 
          style={{  background: '#FFFFFF', borderTopRightRadius: index === 0 ? 10 : 0, 
                    borderBottomRightRadius: index === dataQuestionary.length -1 ? 10 : 0,
                  }} 
          className={classes.column}
          >
          <div className={classes.columnEvent} >
            <Tooltip title="Ver detalle" arrow placement="bottom">
              <VisibilityIcon 
                onClick={()=> {setOpenDetail(!openDetail); setDataDetail(x)}}
                className={classes.iconEvent} />
            </Tooltip>
            &nbsp;&nbsp;
            {x?.status === "APPLIED" ? null :
            
              <Tooltip title="Editar" arrow >
                  <EditIcon
                    onClick={() => {setOpenEdit(true); changeOpenEdit(); setDataEdit(x)}}
                    className={classes.iconEvent}
                  />
              </Tooltip>
            }
            &nbsp;&nbsp;
            {x?.status === "APPLIED" ? null : 
              <Tooltip title="Eliminar" arrow >
                  <DeleteIcon
                    onClick={() => confirmOpenDelete(x)}
                    className={classes.iconEvent}
                  />
                </Tooltip>
            }
              <Button 
                variant="contained"
                disableElevation
                disabled={x?.status === 'ACTIVE' || x?.status === 'APPLIED' ? false : true}
                onClick={()=> getDataAplication(x)}
                style={{ background: x?.status === 'ACTIVE' || x?.status === 'APPLIED'? '#2F71CB' : '#BEBEBE' , 
                    color: '#ffffff', marginLeft: '3%'
                  }}
              >
            APLICAR
          </Button>
          </div>
        </Grid>
      </Grid>
     </React.Fragment>
        ))}
        </Grid> */}
        {openEdit ? 
          <EditQuestionary 
            open={openModal}
            setOpenModal={changeStateModal}
            closeEdit={closeEdit}
            dataEdit={dataEdit}
          /> 
        : null}
        {openDetail ? 
          <ShowDetail 
            open={openDetail}
            changeStateModalDetail={changeStateModalDetail}
            closeEdit={closeEdit}
            dataDetail={dataDetail}
          /> 
        : null}
         <ModalDelete 
            open={messageDelete.open}
            type={messageDelete.type}
            title={messageDelete.title}
            description={messageDelete.description}
            textButtonSubmit={messageDelete.textButtonSubmit}
            oneButtons={messageDelete.oneButtons}
            handleClick={messageDelete.handleClick}
            handleClickOut={messageDelete.handleClickOut}
          />
    </React.Fragment>
  );
  }else {
    return null;
  }
}
