import React, {useEffect, useState} from 'react'
import { useStateValue } from '../../context/store';
import Table from "./table"
import TablePagination from '@mui/material/TablePagination';
import { getfilterBehaviors} from "../../actions/Behavior-actions"
import {getLengthComtencies} from "../../actions/Competencies-actions"
import ModalConfirm from "../../utils/form/modalConfirmBase";
import Search from "./search";
import { PATH_APP } from '../../constants';

const Index = () => {
 const [{behaviorSearch, spinner, }, dispatch] = useStateValue()
 const [listCompetencies, setListCompetencies] = useState([])
 const [clearFielForm, setClearFielForm]= useState(false);
 const [resetError, setResetError] = useState(false);

  const [paginadorResponse, setPaginadorResponse] = useState({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
 
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
  })

  useEffect(()=> {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar aspectos",
      index: 9,
      path: `${PATH_APP}/behavior-create`,
      textIsSubmit: "Crear aspecto",
      module: "MANAGE_BEHAVIOR"
    });
    getAllBehaviors()
    allCompetencies()
  },[])


  useEffect(() => {
    if(behaviorSearch?.isFilter){
      getAllBehaviors()
    }
  },[behaviorSearch?.isFilter])

  const allCompetencies = async() => {
    const result = await getLengthComtencies(dispatch);
    setListCompetencies(result?.data?.content ? result?.data?.content : [])
  };

  const loadData = () => {
    return dispatch({
      type: "SET_FILTER_BEHAVIOR",
      filter: {page: 0, size: 10},
      isFilter: true,
    })
  }

  const changeStateDispatch = () => {
    return dispatch({
      type: "SET_FILTER_BEHAVIOR",
      filter: {...behaviorSearch?.filter},
      isFilter: false,
    })
  }


 const closeModalAndClear = () => setClearFielForm(!clearFielForm);
 const closeError = () => setResetError(!resetError);
  
  const closeModal = () => {
    setMessage({...message, open:false}); 
    closeError();
  }
  
  const notFoundAction = () => {
    closeModalAndClear();
    changeStateDispatch();
    setMessage({...message, open:false}); 
  }
  
  const dataFilter = () =>{
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
    return loadData()
  }

  const resetTable= () => {
    return setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
  }
  
  const notFoundData = () => {
    return  setMessage({
      open : true,
      description: 'No se encontró información relacionada a la búsqueda',
      handleClick : () => notFoundAction() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
  }

  const errorSistem = () => {
    return  setMessage({
      open : true,
      description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
  }


  const getAllBehaviors = async() => {
    try{

      const result = await getfilterBehaviors({
        code : behaviorSearch?.filter?.code ?? null,
        name: behaviorSearch?.filter?.question ?? null,
        tagName: behaviorSearch?.filter?.tagUuid ?? null,
        status: behaviorSearch?.filter?.status ?? null,
        page: behaviorSearch?.filter?.page  ?? 0,
        size:  behaviorSearch?.filter?.size  ?? 10,
        
    },
    dispatch,
    {orderBy: 'createdAt'},
    )

    if(result?.status === 204 ){
      resetTable()
      return notFoundData()
     }
     if(result?.status === 400){
      return notFoundData()
     }
     if(result?.status > 399 && result?.status < 405  && !behaviorSearch?.typeAction){
      return notFoundData()
     }
    
    if ((result && result?.status === 200) || result.status === 201) {
      const data = result?.data;
      if (data?.content?.length > 0) {
        setPaginadorResponse({
          data: data?.content,
          totalRows: data?.totalElements,
          pageSize: data?.size,
          pageNumber: data?.number,
        });
      }
      return changeStateDispatch()
    }
    }catch{
      return errorSistem()
    }
  }

 

  const changePage = (event, nuevaPagina) => {
    dispatch({
      type: "SET_FILTER_BEHAVIOR",
      filter: {page: nuevaPagina, size: 0},
      isFilter: true,
    })
  };

  const changeAmountOfRecords = (event, siguiente) => {
    dispatch({
      type: "SET_FILTER_BEHAVIOR",
      filter: {page: 0, size: event?.target.value},
      isFilter: true,
    })
  };
  
  return (
    <>
      <Search 
        competencies={listCompetencies}
        clearFielForm={clearFielForm}
        dataFilter={dataFilter}
        resetTable={resetTable}
        resetError={resetError}
      />
       {!spinner?.active &&
        <Table 
          data={paginadorResponse?.data}
          message={message}
          setMessage={setMessage}
        />
      }
      {paginadorResponse?.totalRows !== 0 && !spinner?.active &&
        <TablePagination
            style={{ display:"flex", justifyContent: 'center', color: '#747578' }}
            component="div"
            rowsPerPageOptions={[5, 10, 15]}
            count={paginadorResponse.totalRows}
            rowsPerPage={behaviorSearch?.filter?.size ?? 10}
            page={behaviorSearch?.filter?.page ?? 0}
            labelRowsPerPage='Registros por página'
            onPageChange={changePage}
            onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
        />
      }
       <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
    </>
  )
}

export default Index