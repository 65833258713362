import React,{useState, useEffect} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Checkbox, Typography} from '@mui/material';
import { styles } from "./styles";
import { useStateValue } from '../../../../../context/store';
import _ from "lodash"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Index = (props) => {
    const classes = styles();
    const [{relations}, ] = useStateValue();
    const [checkeds, setCheckeds] = useState([])
    const [showText, setShowText] = useState([])
    const [numberPosition, setNumberPosition] = useState(1)

    const showQuestion = (data, id) => {
    const result = showText.find((x)=> x?.id === id)
    if(result?.show) {  
        return data 
    } else {
        return `${data.substring(0, 150)}` 
    }
    }
      
    const allShow = (id) => {
        const result = showText.find((x)=> x?.id === id)
        if(result?.show) {
        setShowText([{show: false, id: id}])
        }else {
        setShowText([{show: true, id: id}])
        }
    }
    
    const validateShowText = (id) => {
        const result = showText.find((x)=> x?.id === id)
        return result?.show ? true : false
    }

    const validateChecked = (data) => {
        const result =  checkeds?.find((x) =>x?.uuid === data )
       return result ? true : false
    }

    const handleChangeChecked = (event) => {
        
        let copy= [...checkeds]
        const {name, value} = event.target;
        const filterQuestions = props?.data?.find((x) => x.uuid === name)
        const validateExists = validateChecked(name)
       if(validateExists) {
         setCheckeds(checkeds.filter((x)=> x.uuid !== name));

        }else {
        setNumberPosition(numberPosition+1)
          copy.push(
            {
              uuid: filterQuestions.uuid, 
              name: filterQuestions.name, 
              code: filterQuestions.code,
              domainLevelList: filterQuestions?.domainLevelList,
              domainLevel: ''
            }
          )
          for (let index = 0; index < copy?.length; index++) {
            copy[index].position = index
          }
          setCheckeds(copy);
       } 

    };


    useEffect(() => {
        setCheckeds(relations?.data)
    }, [])
    
    useEffect(() => {
        const filterPositions = relations.data?.map((x) => x?.position)
        const bigger = Math.max(...filterPositions)
        if(filterPositions?.length){
            setNumberPosition(bigger)
        }else {
             return null
        }
    }, [props?.data?.length])

  
    useEffect(() => {
      props?.getCompetencies(checkeds)
    }, [checkeds])

  return (
      <div >
      <TableContainer component={Paper} style={{ width: '100%', border: '1px solid #d9d7d7', borderRadius: 10 }}>
        <Scrollbars style={{height: 270, paddingTop: '10px !important'}}>
        {props?.data?.length ? <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead >
          <TableRow >
            <TableCell size="small" padding="none" style={{borderBottom: 'none'}}></TableCell>
            <TableCell align="center" size="small" padding="none"  style={{borderBottom: 'none'}}>Código</TableCell>
            <TableCell align="center" size="small" padding="none"  style={{borderBottom: 'none'}}>Criterio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data?.map((x, index) => (
            <TableRow
              key={x.uuid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{verticalAlign: 'Initial'}}
            >
              <TableCell align="right" component="th" scope="row" size="small" padding="none" style={{borderBottom: 'none'}}>
                <Checkbox
                    checked={validateChecked(x?.uuid)}
                    name={x?.uuid}
                    onChange={handleChangeChecked}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
              </TableCell >
              <TableCell align="center" size="small" padding="none" style={{borderBottom: 'none'}}>{x.code}</TableCell>
              <TableCell align="left" size="small" padding="none" style={{borderBottom: 'none', padding: '0px 2%', width: '70%'}}>
                <Typography component={'span'} style={{marginTop: '1.5%', marginRight: 10}} className={classes.heading} >
                    {x.name?.length > 100 ? showQuestion(x.name, index) : x.name}{x.name?.length > 100 && 
                    <Typography variant="caption"
                        className={classes.expandText}
                        onClick={()=> allShow(index)}>{validateShowText(index) ? "...ver menos" : "...ver más" }
                    </Typography>}
                </Typography>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> : null}
        </Scrollbars> 
    </TableContainer>
    </div>
  )
}

export default Index