import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../context/store';
import Header from "./header";
import { getByIdEvaluation } from "../../actions/Performance_Evaluation-action"
import Evaluation from "./evaluation";
import ModalConfirm from "../../utils/form/modalConfirmBase";
import { PATH_APP } from '../../constants';
import EditAplication from "../../components/common/detailEvaluation/evaluationWithPonderation";
import { useHistory} from "react-router-dom";
import { dataEditModel } from './DataEditApply';

const Index = (props) => {
    const {id, status} = props?.match?.params
    const history = useHistory();

    const idEvaluation = id
    const [dataDetail, setDataDetail] = useState({})
    const [, dispatch] = useStateValue();
    const [message, setMessage] = useState({
      description : '',
      textButtonSubmit : '',
      handleClick : () => {} ,
      handleClickOut : () => {},
      oneButtons : false,
      title: '',
      list: [],
      type : '',
      open : false
    })

    const closeModal = () => {
      setMessage({...message, open: false}); 
      history?.pushState(`${PATH_APP}/evaluation-performance-evaluator`) 
    }
    const returnHeader = () => {
        return dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: `${status === "ACTIVE" ? 'Editar aplicación de ' : 'Aplicar '} modelo de evaluación`,
          index: 12,
          idForm: 'Evaluation',
          module: 'EVALUATION_EVALUATOR',
          subPath: `${PATH_APP}/evaluation-performance-evaluator`,
          isSubmit: true,
          textIsSubmit:  "CONTINUAR",
        });
 
      }
    
    useEffect(()=> {
     
      getEvaluation()
      dispatch({
        type: "SET_FILTER_ORGANIZATION_CHART",
        filter: {page: 0, size: 10},
        isFilter: false,
      })
    },[])


    const getEvaluation = async() => {
      const result = await getByIdEvaluation(idEvaluation, dispatch)

      if(result?.status >= 200 && result?.status <= 204 ){
        setDataDetail(result?.data);
        returnHeader()
        return;
      }else if(result?.status > 399 && result?.status < 405  ){
        return setMessage({
          open : true,
          description: 'No se encontró información relacionada a la búsqueda',
          handleClick : () => closeModal() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      }else if(result?.status > 399 && result?.status >= 500  ){
        return setMessage({
          open : true,
          description: 'Occurió un problema comuniquese con el administrador.',
          handleClick : () => closeModal() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      }else {
        return setMessage({
          open : true,
          description: 'Occurió un problema comuniquese con el administrador.',
          handleClick : () => closeModal() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      }

  }


  return (
    <React.Fragment>
      {dataDetail?.name ? 
      <>
        <Header id={idEvaluation} dataDetail={ status === 'APPLIED' ? dataEditModel : dataDetail } message={message} setMessage={setMessage}/>
        {status === 'APPLIED' 
          ? <EditAplication dataDetail={dataEditModel}/>
          : <Evaluation dataDetail={dataDetail} setMessage={setMessage} message={message}/>
        }
        </>
       : null 
       }
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        list = {message.list}
        type = { message.type }
        open = { message.open }
      />
      
    </React.Fragment>
  )
}

export default Index