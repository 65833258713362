import React from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    outline: "none",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
  },
  cancel1: { 
     color: "#2F71CB", 
     marginBottom: 5, 
     borderColor: '#80808070',
     "&:hover": {
      borderColor: '#80808070',
    },
  },
  cancel: { 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5, 
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
 },
  text: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#4B4B4B",
    fontWeight: 400,
    textAlign: "center",
    margin: "25px 20px",
  },
  titleAlert: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#2C2C2C",
  },
  widthIcon: {
    fontSize: 70,
    color: '#2F71CB',
    marginTop: '3%'

  },
  confirmBanck :{ 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5,
    // width: 'fit-content' ,
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
  }
}));

const ModalConfirmBase = ({
  active,
  
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const bodyModal = (
    <div style={modalStyle} className={classes.paper}>
      <Box sx={{ display: 'flex', marginTop:'15%', justifyContent: 'center' }}>
              <CircularProgress color="inherit"/>
      </Box>
    </div>
  );

  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={active ?? false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
};

export default ModalConfirmBase;
