import HttpClient from '../services/HttpClient';
import {  MAX, CONDUCT } from '../constants'

const type = "CONDUCT"
export const getBehaviorCode = () => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${CONDUCT}${MAX}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getLengthBehaviors = (order) => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${CONDUCT}?type=${type}&pageNumber=0&pageSize=10&totalElements=true&status=ACTIVE&orderBy=${order?.orderBy}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const createBehavior = (body, dispatch) => {
    return new Promise((resolve, reject) => {
        HttpClient.post(`${CONDUCT}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response);
        });
    });
}

export const updateBehavior = (body, id) => {
    return new Promise((resolve, reject) => {
        HttpClient.patch(`${CONDUCT}/${id}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response)
        })
    })
}

export const deleteBehavior = (id) => {
    return new Promise((resolve, reject) => {
      HttpClient.delete(`${CONDUCT}/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve(error.response);
        });
    });
  };

  export const getfilterBehaviors = (dataFilter, dispatch, order) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true })
    return new Promise((resolve, reject) => {
       
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}&orderBy=${order?.orderBy}`

        const keys = ['name', 'status', 'code' ];

        for (let index = 0; index < keys?.length; index++) {
            if(dataFilter[keys[index]] ){
                if(keys[index] === 'name' && dataFilter[keys[index]]){
                  filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
                }else {
                  filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
                }
            } else {
                continue
            }
        }
        HttpClient.get(`${CONDUCT}${filters}`).then(response => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false })
            resolve(response);
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false })
            if (error.response === undefined) {
                dispatch({
                    type: "SET_MODAL_ACTION",
                    open: true,
                    icon:'WARNING',
                    message: 'Occurió un problema comuniquese con el administrador.'
                  })
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}
