import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
  dropdown: {
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "8px",
    // height: 40,
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
      background: '#dfe7fd',
    },
    '& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected': {
        background: 'rgba(25, 118, 210, 0.08)'
    },
    '& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root': {
        background: '#ffffff'
    }
  },
  focusVisible: {
    backgroundColor: 'rgba(25, 118, 210, 0.08) !important'
  },
  iconErrorFieldLabel: {
    marginRight: '2%',
    marginBottom: '3%',
    fontSize: 'initial'
  },
  buttons: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'end'
},
  container_buttons: {
    display: 'flex',
    alignItems: 'flex-start', 
    marginBottom: 5
  }
})


