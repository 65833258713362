import  React,{useState, useEffect} from 'react';
import { 
    FormControl, 
    Grid, 
    MenuItem, 
    OutlinedInput, 
    FormControlLabel,
    Checkbox,
    FormGroup,
    TextField,
    Select,
    FormHelperText,
    Box,
    InputLabel,
    Chip 
} from '@mui/material';
import { useStateValue } from "../../../context/store";
import { useTheme } from '@mui/material/styles';
import { styles } from './styles'
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {createAplication, getAllUsers} from "../../../actions/AplicationQuestionary-action";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash"
import moment  from "moment"
import { PATH_APP } from '../../../constants';



export default function ModalConfirmAplication(props) {
    const theme = useTheme();
    const classes = styles();
    const history = useHistory();
  const [{ dataAplication, valorateAplication, questionarySearch }, dispatch] = useStateValue();
  const [checked, setChecked] = useState({})
  const [currentDay, setCurrentDay] = useState()
  const [questions, setQuestions] = useState(props?.isDetail ?  dataAplication?.data?.questionnaire?.associatedQuestions : dataAplication?.data?.associatedQuestions)
  const [good, setGood] = useState(false)
  const [validate, setValidate] = useState(false)
  const [valuesYup, setValuesYup] = useState({})
  const [users, setUsers] = useState([])
  const [valuesOptions, setValuesOptions ] = useState([])

  const [names, setNames] = useState(null);


  const schema = yup.object().shape({
    referredTo:  yup
    .array()
    .typeError('Este campo es obligatorio')
    .min(1, 'Seleccione mínimo a un evaluado'),
    startDate: yup
    .date()
    .min(moment(new Date()).format('YYYY-MM-DD'),'Ingrese mínimo la fecha actual' )
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
    .nullable(true),
    endDate: yup
    .date()
    .required('Este campo es obligatorio')
    .nullable(true)
    .typeError('Este campo es obligatorio')
    .test("maxLenght", "Ingrese una superior o igual a la fecha desde", val =>  currentDay <= moment(val).format('YYYY-MM-DD')   ),
    totalValueApplied: yup
    .string()
    .required('Este campo es requerido')
    .matches(/^[0-9]{1,3}$/, 'Ingrese un número válido')
    .test("maxLenght", "Ingrese un valor entre 1 y 100", val => val <= 100 && val >= 1)
    .nullable(true),
  })
  const { register, handleSubmit, trigger,  clearErrors, formState:{errors}, setValue, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });

  useEffect(() => {

      if(props?.isDetail){
        setValue('name',dataAplication?.data?.questionnaire?.name)
        setValue('type',dataAplication?.data?.questionnaire?.type)
        setValue('endDate',moment(dataAplication?.data?.endDate).format('YYYY-MM-DD'))
        setValue('startDate',moment(dataAplication?.data?.startDate).format('YYYY-MM-DD'))
        setValue('totalValueApplied', dataAplication?.data?.totalValueApplied)
        setValue('status',dataAplication?.data?.questionnaire?.status) 
          handleChangeReferent(dataAplication?.data?.referredTo?.map((x)=>x.username))
        setValue('referredTo', dataAplication?.data?.referredTo)
        setValue('description',dataAplication?.data?.questionnaire?.description)
        setChecked({ name: dataAplication?.data?.automatic ? "automatic" : 'manual'})
      } else {
        setValuesOptions([ { name: 'name1', value: 1, code: 1, status: 'status1'},])
    setValuesYup({...valuesYup, 
      name1: yup
      .string()
      .min(2, 'Ingrese mínimo 2 caracteres')
      .max(20, 'Ingrese máximo 20 caracteres')
      .matches(
         /^(([A-Za-z0-9]))*$/g,
        "Ingrese un nombre válido"
      ),
      code1: yup
      .number()
      })
      clearErrors('name1')  
      setValue('name',dataAplication?.data?.name)
      setValue('type',dataAplication?.data?.type )
      setValue('status',dataAplication?.data?.status)
      setValue('description',dataAplication?.data?.description)
      }
      getUsers()
  }, [])

  const getUsers = async() => {
      const result = await getAllUsers({rol: 'EVALUATED'});
      setNames(result?.data ? result?.data : [])
   
  }



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const notDisabledSubmit = () =>  dispatch({
    type: "SET_BREADCRUMB",
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Aplicar cuestionario",
    index: 5,
    subPath: `${PATH_APP}/questionary`,
    isSubmit: true,
    idForm: 'formAplication',
    module: 'MANAGE_QUESTIONNAIRE',
    disabledSubmit: false,
    textIsSubmit: "GUARDAR",
  });

  const disabledSubmit = () =>  dispatch({
    type: "SET_BREADCRUMB",
    breadCrumbText: "Autoevaluaciones",
    breadCrumbSubText: "Aplicar cuestionario",
    index: 5,
    subPath:  `${PATH_APP}/questionary`,
    isSubmit: true,
    idForm: 'formAplication',
    module: 'MANAGE_QUESTIONNAIRE',
    disabledSubmit: true,
    textIsSubmit: "GUARDAR",
  });

  useEffect(() => {
    setCurrentDay(moment(watch('startDate')).format('YYYY-MM-DD'))
  }, [watch('startDate')]);
  


  const handleChange = (e) => {
    const {name, value} = e.target;
    trigger(name)
    const regex = /^[0-9]{1,3}$/
    if(value && regex.test(value) && value >= 1 && value <= 100 ) {
        dispatch({
            type: "SET_DISABLED_QUESTIONARY",
            valor: value,
            disabled: false
        });
    }else {
        dispatch({
            type: "SET_DISABLED_QUESTIONARY",
            valor: value,
            disabled: true
        });
    }

   if(checked?.name === "automatic"){
    dispatch({
        type: "SET_VALUE_QUESTIONNAIRE",
        totalValueQuestionnaire: value/dataAplication?.data?.associatedQuestions?.length,
        typeValoration: 'automatic',
        activeValoration: true
      });
      notDisabledSubmit()
   
    const valueForQuestions = value/dataAplication?.data?.associatedQuestions?.length
    const nose = []
    let newValues = []

    for (let index = 0; index < dataAplication?.data?.associatedQuestions?.length; index++) {
     
      const uuidQuestions = dataAplication?.data?.associatedQuestions[index]?.uuid
      const findUuid= valorateAplication?.data?.find((x)=> x?.uuid ===uuidQuestions)
              // agregando nuevo valor aplicado a la pregunta y sus opciones cuando es manual
          
          nose.push({
              uuid: uuidQuestions,
              valueApplied: Number(valueForQuestions),
              questionAlternativeList: []
          })
              
          if(findUuid && findUuid?.questionAlternativeList.length){
              const getAnswer = nose.find((x)=> x?.uuid === findUuid?.uuid)
              const object = []
          for (let index = 0; index < findUuid?.questionAlternativeList.length; index++) {
             
              getAnswer?.questionAlternativeList.push({ 
                  uuid: findUuid?.questionAlternativeList[index]?.uuid, 
                  valueApplied: Number(value/dataAplication?.data?.associatedQuestions?.length)/findUuid?.questionAlternativeList.length 
              })
           }

              const filterDifferent =  nose.filter((x)=> x?.uuid !== findUuid?.uuid)
              newValues = [...filterDifferent, ...object]

         } else {
             continue
         }
      }
      dispatch({
        type: "SET_MODAL_VALORATE_APLICATION",
        icon:'WARNING',
        data: nose
      })
   }else if (checked?.name === "manual"){
    dispatch({
        type: "SET_VALUE_QUESTIONNAIRE",
        totalValueQuestionnaire: '',
        typeValoration: 'manual',
        activeValoration: true,
      });
   }else {
    dispatch({
        type: "SET_VALUE_QUESTIONNAIRE",
        totalValueQuestionnaire: '',
        typeValoration: '',
        activeValoration: false,
      });
   }
   
  
        setValue(name, value)
        trigger(name)

  }

  const handleChangeReferent = (event) => {

    if(props?.isDetail){
      const name="referredTo"
      setUsers(
        typeof event === 'string' ? event.split(',') : event,
      );
      setValue(name,typeof event === 'string' ? event.split(',') : event, )
      setValidate(true)
      trigger('referredTo')
    } else {
  
      const {
        target: { value, name },
      } = event;
      setUsers(
        typeof value === 'string' ? value.split(',') : value,
      );
      setValue(name,typeof value === 'string' ? value.split(',') : value, )
      setValidate(true)
      trigger('referredTo')
    }
  
  };

  

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  useEffect(()=> {
      if(validate){
          trigger('referredTo')
      }
  },[watch('referredTo')])


  const addValorQuestions = (name) => {
   
    dispatch({
      type: "SET_VALUE_QUESTIONNAIRE",
      totalValueQuestionnaire: watch('totalValueApplied')/dataAplication?.data?.associatedQuestions?.length,
      typeValoration: name,
      activeValoration: true
    });
    const valueForQuestions = watch('totalValueApplied')/dataAplication?.data?.associatedQuestions?.length
    const nose = []
    let newValues = []

    for (let index = 0; index < dataAplication?.data?.associatedQuestions?.length; index++) {
     
      const uuidQuestions = dataAplication?.data?.associatedQuestions[index]?.uuid
      const findUuid= valorateAplication?.data?.find((x)=> x?.uuid ===uuidQuestions)
              // agregando nuevo valor aplicado a la pregunta y sus opciones cuando es manual
          
          nose.push({
              uuid: uuidQuestions,
              valueApplied: Number(valueForQuestions),
              questionAlternativeList: []
          })
              
          if(findUuid && findUuid?.questionAlternativeList.length){
              const getAnswer = nose.find((x)=> x?.uuid === findUuid?.uuid)
              const object = []
          for (let index = 0; index < findUuid?.questionAlternativeList.length; index++) {
             
              getAnswer?.questionAlternativeList.push({ 
                  uuid: findUuid?.questionAlternativeList[index]?.uuid, 
                  valueApplied: Number(watch('totalValueApplied')/dataAplication?.data?.associatedQuestions?.length)/findUuid?.questionAlternativeList.length 
              })
           }

              const filterDifferent =  nose.filter((x)=> x?.uuid !== findUuid?.uuid)
              newValues = [...filterDifferent, ...object]

         } else {
             continue
         }
      }

      dispatch({
              type: "SET_MODAL_VALORATE_APLICATION",
              icon:'WARNING',
              data: nose
            })
  }

  
  const handleChecked = (e) => {
    const {name, } = e.target;
    setChecked({name: name, })
    if(name === 'automatic'){
      addValorQuestions(name)    
    } else {
        const valueForQuestions = ''
        const nose = []
  
            for (let index = 0; index < dataAplication?.data?.associatedQuestions?.length; index++) {
              
              const uuidQuestions = dataAplication?.data?.associatedQuestions[index]?.uuid
              const findUuid= valorateAplication?.data.find((x)=> x?.uuid ===uuidQuestions)
              
                      // agregando nuevo valor aplicado a la pregunta y sus opciones cuando es manual
                  
                  nose.push({
                      uuid: uuidQuestions,
                      valueApplied: valueForQuestions,
                      questionAlternativeList: []
                  })
                      
                  if(findUuid && findUuid?.questionAlternativeList.length){
                      const getAnswer = nose.find((x)=> x?.uuid === findUuid?.uuid)
                  for (let index = 0; index < findUuid?.questionAlternativeList.length; index++) {
                      
                      getAnswer?.questionAlternativeList.push({ 
                          uuid: findUuid?.questionAlternativeList[index]?.uuid, 
                          valueApplied: '' 
                      })
                    }
  
            }
        }
    
            dispatch({
                    type: "SET_MODAL_VALORATE_APLICATION",
                    icon:'WARNING',
                    data: nose,
                  })
        dispatch({
            type: "SET_VALUE_QUESTIONNAIRE",
            totalValueQuestionnaire: '',
            typeValoration: name,
            // activeValoration: true
          });
    }
  }


  const handleCloseModalAndReset = () => {
    props?.setMessage({...props.message, open:false})
    history.push(`${PATH_APP}/questionary`)
    dispatch({
      type: "SET_FILTER_QUESTIONARY",
      filter: questionarySearch?.filter,
      isFilter: true,
      typeAction: 'APLICATION'
    })
  }

  const handleCloseModal = () =>  {
      props?.setMessage({...props.message, open:false}) 
    notDisabledSubmit()
  }

  const validateSatatus = (x) => {
    if(x === 'ACTIVE'){
        return 'Activo'
      } else if (x === 'INACTIVE'){
        return 'Incativo'
      } else if (x === 'APPLIED'){
        return 'Aplicado'
      }
  }


  const onSubmit = (data) => {
      disabledSubmit()

      const newUsers = []
      for (let index = 0; index < data?.referredTo.length; index++) {
       const user = names.find((x)=> x?.username === data?.referredTo[index])
        newUsers.push({uuid: user?.uuid})
      }
   
      const newDataHeader = {
          startDate: moment(data?.startDate).format('YYYY-MM-DD'),
          endDate: moment(data?.endDate).format('YYYY-MM-DD'),
          referredToUsers : newUsers,
          totalValueApplied: Number(data?.totalValueApplied),
          automatic: checked?.name === 'manual' ? false : true
        }
   

 if(data?.endDate < moment(new Date()).format('YYYY-MM-DD')){
     return  dispatch({
        type: "SET_MODAL_VALORATE_APLICATION",
        open: true,
        icon:'WARNING',
        activeValorate: false,
        message: 'La fecha final no puede ser menor a la fecha inicial',
        data: valorateAplication?.data ? [...valorateAplication?.data] : [],
     })
 }else {
     
    dispatch({
        type: "SET_HEADER_APLICATION",
        data: newDataHeader
    })
    dispatch({
        type: "SET_MODAL_VALORATE_APLICATION",
        activeValorate: true,
        dataClear: valorateAplication?.data ?  valorateAplication?.data :[],
        data: valorateAplication?.data ?  valorateAplication?.data :[]
    })


    const uuids = []
    
    for (let index = 0; index < questions?.length; index++) {
     uuids.push({uuid: questions[index]?.uuid, code: questions[index]?.answerAlternativeCode})
    }

    for (let index = 0; index < questions?.length; index++) {
        const filterUuid = valorateAplication?.data?.find((x)=> x?.uuid ===  uuids[index]?.uuid)
        const isAplicates = valorateAplication
        const isChecked = filterUuid?.questionAlternativeList?.filter((x)=> x?.valueApplied !== 0)
        const filterOfAnswer = dataAplication?.data?.associatedQuestions?.find((x)=> x?.uuid === filterUuid?.uuid)
        let valueTotalCuestionnaire = 0
        const isAplicate = valorateAplication?.data
       
        for (let index = 0; index < questions?.length; index++) {
          valueTotalCuestionnaire =  isAplicate[index]?.valueApplied + valueTotalCuestionnaire
        }

        for (let index = 0; index < questions?.length; index++) {
        
            if(!valorateAplication?.data[index]?.valueApplied ){
         
                   setGood(false)
                   return dispatch({
                       type: "SET_MODAL_VALORATE_APLICATION",
                       open: true,
                       icon:'WARNING',
                       activeValorate: false,
                       dataClear: valorateAplication?.data,
                       message: 'Hay preguntas sin ser valoradas',
                       data: valorateAplication?.data ? [...valorateAplication?.data] : [],
                   })
               }
               continue
        }
       
        if(Math.round(valueTotalCuestionnaire) !== Number(data?.totalValueApplied)){
            setGood(false)
            return dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            open: true,
            icon:'WARNING',
            activeValorate: false,
            dataClear: valorateAplication?.data,
            message: 'El valor total y el valor de las preguntas no coinciden',
            data: valorateAplication?.data ? [...valorateAplication?.data] : [],
        })
       
        }
        if(filterUuid && filterOfAnswer?.answerAlternativeCode === 'ELU'  ){
            if(!filterUuid?.questionAlternativeList.length){
              return dispatch({
                  type: "SET_MODAL_VALORATE_APLICATION",
                  open: true,
                  icon:'WARNING',
                  activeValorate: false,
                  dataClear: valorateAplication?.data,
                  message: 'Hay preguntas sin su respuesta',
                  data: valorateAplication?.data ? [...valorateAplication?.data] : [],
               })
            }else {
             continue
            }
          } 
        if(filterUuid && filterOfAnswer?.answerAlternativeCode ===  'MUL'  ){
               if( isChecked.length >= 2){
                   continue
               } else {
                setGood(false)
                return dispatch({
                  type: "SET_MODAL_VALORATE_APLICATION",
                  open: true,
                  icon:'WARNING',
                  activeValorate: false,
                  dataClear: valorateAplication?.data,
                  message: `Selecciona más de 1 respuesta para la pregunta de seleccion multiple`,
                  data: valorateAplication?.data ? [...valorateAplication?.data] : [],
                 })
               }
              
            } 
      

            }
           
            let newData = [...valorateAplication?.data]
         
              for (let index = 0; index < questions.length; index++) {
              const answerData = valorateAplication?.data?.find((x)=> x?.uuid ===  uuids[index]?.uuid)
              const filterDataEntry = dataAplication?.data?.associatedQuestions?.find((x)=> x?.uuid === answerData?.uuid)
              let uuidsAnswer = []
    
            if(answerData && filterDataEntry?.answerAlternativeCode === 'ELU'){
        
              let questionsDifferent = newData?.filter((x)=> x?.uuid !==  answerData?.uuid)
              uuidsAnswer = answerData?.questionAlternativeList
              let different = filterDataEntry?.questionAlternativeList
        
              for (let index = 0; index < answerData?.questionAlternativeList.length; index++) {
               different = _.filter(different, (o)=> o?.uuid !== uuidsAnswer[index]?.uuid) 
              }
        
              for (let index = 0; index < different.length; index++) {
                uuidsAnswer.push({uuid: different[index]?.uuid, valueApplied: 0})
              }
           
              questionsDifferent.push({uuid: answerData?.uuid , questionAlternativeList: uuidsAnswer, valueApplied:answerData?.valueApplied })
              newData = questionsDifferent
              
          }
          if(answerData && filterDataEntry?.answerAlternativeCode === 'MUL'){
            let questionsDifferent = newData?.filter((x)=> x?.uuid !==  answerData?.uuid)

            const isCheckeds = answerData?.questionAlternativeList
           
            let selectCheckeds = [...filterDataEntry?.questionAlternativeList]
            for (let index = 0; index < answerData?.questionAlternativeList.length; index++) {
               
                selectCheckeds = selectCheckeds.filter((x)=> x?.uuid !== answerData?.questionAlternativeList[index]?.uuid)
            }
            for (let index = 0; index < selectCheckeds.length; index++) {
               isCheckeds.push({uuid: selectCheckeds[index]?.uuid, valueApplied: 0})
            }
            questionsDifferent.push({uuid: answerData?.uuid , questionAlternativeList: isCheckeds, valueApplied:answerData?.valueApplied })
            newData = questionsDifferent
            
        }else if(answerData && filterDataEntry?.answerAlternativeCode === 'TEX'){
        
            let questionsDifferent = newData?.filter((x)=> x?.uuid !==  answerData?.uuid)
            questionsDifferent.push({uuid: filterDataEntry?.uuid , questionAlternativeList: [], valueApplied:answerData?.valueApplied })
            newData = questionsDifferent
            
        }else if(answerData && filterDataEntry?.answerAlternativeCode === 'ESL'){
            const allAnswers = filterDataEntry?.questionAlternativeList
            let questionsDifferent = newData?.filter((x)=> x?.uuid !==  answerData?.uuid)
            const newAnswers = []
            
            for (let index = 0; index < allAnswers.length; index++) {
                newAnswers.push({uuid: allAnswers[index]?.uuid, valueApplied: answerData?.valueApplied})
            }

            questionsDifferent.push({uuid: filterDataEntry?.uuid , questionAlternativeList: newAnswers, valueApplied:answerData?.valueApplied })
            newData = questionsDifferent
            
        }else if(answerData && filterDataEntry?.answerAlternativeCode === 'RAN'){
           
            const allAnswers = filterDataEntry?.questionAlternativeList
            let questionsDifferent = newData?.filter((x)=> x?.uuid !==  answerData?.uuid)
            uuidsAnswer = answerData?.questionAlternativeList
            const newAnswers = []
         
            for (let index = 0; index < allAnswers.length; index++) {
                newAnswers.push({uuid: allAnswers[index]?.uuid, valueApplied: answerData?.valueApplied})
            }
            questionsDifferent.push({uuid: filterDataEntry?.uuid , questionAlternativeList: newAnswers, valueApplied:answerData?.valueApplied })
            newData = questionsDifferent
           
          }
        }

        const createApply = async() => { 
          const newDataQuestion = Object.assign({associatedQuestions:newData}, newDataHeader)
          const result = await  createAplication(dataAplication?.data?.uuid, newDataQuestion) 
          if (result && (result?.status >= 200 && result?.status <= 204)) {
              props.setMessage({
                open : true,
                type : 'SUCCESS',
                oneButtons : true,
                title: '¡Proceso exitoso!',
                textButtonSubmit: 'ACEPTAR',
                handleClick : () => handleCloseModalAndReset() ,
                description: 'Información almacenada correctamente ',
              })
            }
            else if (result?.status > 399) {
              props.setMessage({
                open : true,
                description: 'Error al guardar la información',
                handleClick : () => handleCloseModal() ,
                oneButtons : true,
                type : 'WARNING',
              })
            } else {
              props.setMessage({
                open : true,
                description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
                handleClick : () => handleCloseModal() ,
                oneButtons : true,
                type : 'WARNING',
              })
            }
         }
         createApply() 
 }
    
     }


  return (
    <div style={{marginTop: '-5%'}}>
        <form id="formAplication" onSubmit={handleSubmit(onSubmit)} style={{marginTop: 37}}>
            <Grid container spacing={3}  style={{display: 'flex'}} direction="row">
                <Grid item lg={6} >
                    <div style={{display: 'flex'}}>
                        <Grid item lg={6} style={{marginRight: '5%'}}>
                            <Grid container spacing={2} direction="column">
                                <Grid item lg={3} >
                                    <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                                        <TextField 
                                            {...register("name")}
                                            fullWidth
                                            size='small'
                                            label="Nombre cuestionario"
                                            disabled={true}
                                            multiline
                                            maxRows={4}
                                            className={classes.dropdownDisabled}
                                            value={dataAplication?.data?.name}
                                            error={!!errors.hasOwnProperty("name") && errors["name"].message}
                                            helperText={!!errors.hasOwnProperty("name") && 
                                            <p style={{fontSize: 12, margin: 'auto 2px'}}>{ errors["name"].message}</p>}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={12} style={{marginTop: '2%'}}>
                                    <FormControl fullWidth size="small">
                                        <TextField 
                                            {...register('startDate')}
                                            size="small"
                                            type="date"
                                            label="Desde"
                                            disabled={props?.isDetail ? true : false}
                                            className={props?.isDetail ? classes.fieldDateTime : classes.fieldDateTimeErrorSearch}
                                            minDate={new Date()}
                                            InputLabelProps={{
                                                shrink: true,
                                                notched: true
                                            }}
                                            error={!!errors.hasOwnProperty("startDate") && errors["startDate"].message }
                                        />
                                        <FormHelperText style={{color:'#d70909'}}>
                                            {!!errors.hasOwnProperty("startDate") &&
                                            errors["startDate"].message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6}>
                            <Grid container spacing={2} direction="column">
                                <Grid item lg={3} >
                                    <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                                        <TextField 
                                            {...register("type")}
                                            fullWidth
                                            size='small'
                                            label="Tipo de cuestionario"
                                            disabled={true}
                                            className={classes.dropdownDisabled}
                                            value={watch('type') === "EVALUATIVE" ? "Evaluativo" : 'No evaluativo'}
                                            error={!!errors.hasOwnProperty("type") && errors["type"].message}
                                            helperText={!!errors.hasOwnProperty("type") && 
                                            <p style={{fontSize: 12, margin: 'auto 2px'}}>{ errors["type"].message}</p>}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={12} style={{marginTop: '2%'}}>
                                    <FormControl fullWidth size="small">
                                        <TextField 
                                            {...register('endDate')}
                                            size="small"
                                            type="date"
                                            label="Hasta"
                                            disabled={props?.isDetail ? true : false}
                                            className={props?.isDetail ? classes.fieldDateTime : classes.fieldDateTimeErrorSearch}
                                            minDate={new Date()}
                                            InputLabelProps={{
                                                shrink: true,
                                                notched: true
                                            }}
                                            error={!!errors.hasOwnProperty("endDate") && errors["endDate"].message }
                                        />
                                        <FormHelperText style={{color:'#d70909'}}>
                                            {!!errors.hasOwnProperty("endDate") &&
                                            errors["endDate"].message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid item lg={12} style={{display: 'flex', marginTop: '2%'}}>
                        <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                            <TextField 
                                {...register("totalValueApplied")}
                                fullWidth
                                size='small'
                                label="Valor total del cuestionario"
                                onChange={(e)=>handleChange(e)}
                                disabled={props?.isDetail ? true : false }
                                className={props?.isDetail ? classes.fieldDateTime : classes.dropdown}
                                error={!!errors.hasOwnProperty("totalValueApplied") && errors["totalValueApplied"].message}
                                helperText={!!errors.hasOwnProperty("totalValueApplied") && 
                                <p style={{fontSize: 12, margin: 'auto 2px'}}>{ errors["totalValueApplied"].message}</p>}
                            />
                        </FormControl>
                        <Grid item lg={12}  style={{display: 'flex', marginLeft: '3%'}}>
                        <FormGroup >
                            <FormControlLabel
                                disabled={props?.isDetail || !watch('totalValueApplied') || errors['totalValueApplied']? true : false }
                                style={{width: 'max-content', fontSize: 14, color: '#6E6E6E'}}
                                control={
                                <Checkbox 
                                disabled={watch('totalValueApplied') && !errors['totalValueApplied'] ? false : true}
                                checked={checked?.name === "manual"} onChange={handleChecked} name="manual" 
                                />
                                }
                                label="Valor manual "
                            />
                        </FormGroup>
                         <FormGroup >
                            <FormControlLabel
                                disabled={props?.isDetail || !watch('totalValueApplied') || errors['totalValueApplied']? true : false }
                                style={{width: 'max-content', color: '#6E6E6E'}}
                                control={
                                <Checkbox 
                                disabled={watch('totalValueApplied') && !errors['totalValueApplied'] ? false : true}
                                checked={checked?.name === "automatic"} onChange={handleChecked} name="automatic" 
                                />
                                }
                                label="Valor automático"
                            />
                         </FormGroup>
                    </Grid>
                    </Grid>
                    
                </Grid>
                <Grid item lg={6}>
                    <Grid container spacing={2} direction="row">
                        <Grid item lg={4}>
                            <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                                <TextField 
                                    {...register(`status`)}
                                    fullWidth 
                                    size='small'
                                    label="Estado"
                                    disabled={true}
                                    className={classes.dropdownDisabled}
                                    value={validateSatatus(watch('status'))}
                                    error={!!errors.hasOwnProperty("status") && errors["status"].message}
                                    helperText={!!errors.hasOwnProperty("status") && 
                                    <p style={{fontSize: 12, margin: 'auto 2px'}}>{ errors["status"].message}</p>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={8}>
                            <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                                <TextField 
                                    {...register(`description`)}
                                    fullWidth 
                                    placeholder='Descripción'
                                    label="Descripción"
                                    className={classes.dropdownMultilineDisabled}
                                    multiline
                                    maxRows={15}
                                    disabled={true}
                                    size='small'
                                    value={dataAplication?.data?.description}
                                    error={!!errors.hasOwnProperty("description") && errors["description"].message}
                                    helperText={!!errors.hasOwnProperty("description") && 
                                    <p style={{fontSize: 12, margin: 'auto 2px'}}>{ errors["description"].message}</p>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={12} style={{marginTop: '2%'}}>
                            <FormControl fullwidth variant='outlined' style={{width: '-webkit-fill-available'}}>
                            <InputLabel id="demo-multiple-name-label">Dirijido a: </InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                disabled={props?.isDetail ? true : false}
                                {...register(`referredTo`)}
                                className={props?.isDetail ? classes.dropdownMultilineDisabled : classes.dropdownMultiline}
                                value={users}
                                onChange={handleChangeReferent}
                                input={<OutlinedInput id="select-multiple-chip" label="Dirijido a: " />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                                error={!!errors.hasOwnProperty("referredTo") && errors["referredTo"].message}
                                >
                                {names?.map((name) => (
                                    <MenuItem
                                    key={name?.username}
                                    value={name?.username}
                                    style={getStyles(name?.username, users, theme)}
                                    >
                                    {name?.username}
                                    </MenuItem>
                                ))}
                                </Select>
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("referredTo") &&
                                    errors["referredTo"].message}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>  
        </form>
       
    </div>
  );
}
