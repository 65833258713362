import HttpClient from '../services/HttpClient';
import {  LABEL, MULTIPLE, MAX } from '../constants'

export const getALLTags = (status, orderby) => {
    let filters = `?type=TAG&totalElements=true&pageNumber=0&pageSize=10&status=${status?.status}&orderBy=${orderby?.orderBy}`
    return new Promise((resolve, reject) => {
        HttpClient.get(`${LABEL}${filters}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getTagsCode = () => {
    return new Promise((resolve, reject) => {
        HttpClient.get(`${LABEL}${MAX}`).then(response => {
            resolve(response);
        }).catch(error => {
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const createLabel = (body, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        HttpClient.post(`${LABEL}${MULTIPLE}`, body).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            resolve(error.response);
        });
    });
}

export const updateLabel = (id,body, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        HttpClient.patch(`${LABEL}/${id}`, body).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            resolve(error.response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        })
    })
}

export const deleteLabel = (id, body) => {
    return new Promise((resolve, reject) => {
      HttpClient.delete(`${LABEL}/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve(error.response);
        });
    });
  };

  export const getTags = (dataFilter, order, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        let filters = `?type=TAG&pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

        const keys = ['status', 'name', 'code' ];

        for (let index = 0; index < keys?.length; index++) {
            if(dataFilter[keys[index]] ){
                if(keys[index] === 'name' && dataFilter[keys[index]]){
                  filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
                }else {
                  filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
                }
            } else {
                continue
            }
          
        }

        HttpClient.get(`${LABEL}${filters}&orderBy=${order?.orderBy}`).then(response => {
            resolve(response);
            dispatch({
                type: 'SET_ACTIVE_SPINNER',
                active: false
              })
        }).catch(error => {
            dispatch({
                type: 'SET_ACTIVE_SPINNER',
                active: false
              })
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error);
        });
    });
}