import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField, 
     Select, 
     InputLabel, 
     FormControl, 
     FormHelperText, 
     MenuItem,
     Button
} from '@mui/material'
import {useStyles} from './styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStateValue } from '../../../context/store';

const SearQuestion = (props) => {

    const classes = useStyles()
  const [{breadCrumb, questionSearch}, dispatch] = useStateValue();
    const [dataForm, setDataForm] = useState({
        label: '',
        keyword: '',
        answerAlternativeCode: ''
    })
    
    const schema = yup.object().shape({
        labe: dataForm.label !== '' ? yup
        .string()
        .nullable()
        .matches(  
            /^([0-9]{0,100})$/,
           "Código no válido") : yup.mixed().nullable(),
        keyword: dataForm.keyword !== '' ? yup
        .string()
        .nullable(true)
        .max(100, 'Ingrese máximo 100 caracteres') : yup.mixed().nullable(),
        answerAlternativeCode: yup
        .string()
        .nullable(true)
        .notRequired(true)
      });
    
      const { register, handleSubmit, trigger, formState:{errors}, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });
      

      const onSubmit = (data) => {
        
          if(
            data?.keyword !== '' ||
            dataForm?.label !== '' ||
            data?.answerAlternativeCode !== ''
          ){
            dispatch({
               type: "SET_FILTER_QUESTION",     
              filter: {
                question: data?.keyword,
                tagName: dataForm?.label,
                answerAlternativeCode: data?.answerAlternativeCode,
                page: 0,
                size:  props?.indexQuestion ? 5 : 10
              },
              isFilter: true
            })
          } else {
            
          }
      }

      const clear = () => {
        setValue('keyword', '')
        setValue('label', '');
        setValue('answerAlternativeCode', null);
        setDataForm({...dataForm, label: '', answerAlternativeCode: ''})
        dispatch({
          type: "SET_FILTER_QUESTION",     
         filter: {
           question: null,
           tagName: null,
           page: 0,
           size: props?.indexQuestion ? 5 :10
         },
         isFilter: props?.indexQuestion ? true :  false
       })
     
      }

     
    
      useEffect(() => {
          clear()
      }, [props?.clearFielForm])

      useEffect(()=> {
        if(breadCrumb?.module === "SET_FILTER_QUESTION" ){
            setValue('label',questionSearch?.filter?.tagName)
            setValue('keyword', questionSearch?.filter?.question)
            setDataForm({
                            ...dataForm, label: questionSearch?.filter?.tagName, 
                            answerAlternativeCode: questionSearch?.filter?.answerAlternativeCode
                        })
        }
    },[breadCrumb?.module === "SET_FILTER_QUESTION"])

      const handleChange = (e) => {
        const {value, name} = e.target;
        setDataForm({...dataForm, [name]:value})
        setValue(name, value)
        trigger(name)
      }

      const validate = dataForm?.label || watch('keyword') || dataForm?.answerAlternativeCode

    return (
        <form id="searchQuestion" onSubmit={handleSubmit(onSubmit)} >
            <Grid container spacing={props?.isDisabledClear? 1: 2} alignItems="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={props?.isDisabledClear? 4 : 5}>
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('keyword')}
                    className={classes.dropdown}
                    fullWidth
                    InputLabelProps={{ shrink: watch('keyword')? true : false }}
                    notched={watch('keyword')? 'true' : 'false'}
                    onChange={handleChange}
                    size="small"
                    label="Palabra clave"
                    error={!!errors.hasOwnProperty("keyword") && errors["keyword"].message}
                    helperText={!!errors.hasOwnProperty("keyword") && errors["keyword"].message}
                  />
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={ props?.isDisabledClear? 4: 2} style={{display: 'flex'}}>
                <ErrorOutlineIcon color="error"  style={{visibility: errors[`label`] ? "initial": "hidden"}} fontSize="small" className={classes.iconErrorFieldLabel}/> 
                <FormControl fullWidth size="small">
                    <InputLabel  error={!!errors.hasOwnProperty("label") && errors["label"].message}>{props?.isDisabledClear? "Tipo" : "Tipo"}</InputLabel>
                    <Select
                    {...register('answerAlternativeCode')}
                    name='answerAlternativeCode'
                    onChange={handleChange}
                    value={dataForm?.answerAlternativeCode}
                    label="Tipo"
                    className={classes.dropdown}
                    error={!!errors.hasOwnProperty("answerAlternativeCode") && errors["answerAlternativeCode"].message}
                    >
                        <MenuItem value="TEX" key={1}>
                          Texto
                        </MenuItem>
                        <MenuItem value="ESL" key={2}>
                          Escala Lineal
                        </MenuItem>
                      
                    </Select>
                    <FormHelperText style={{color:'red'}}>
                    {errors.hasOwnProperty("answerAlternativeCode") &&
                    errors["answerAlternativeCode"].message}
                </FormHelperText> 
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={ props?.isDisabledClear? 4: 3} style={{display: 'flex'}}>
                <ErrorOutlineIcon color="error"  style={{visibility: errors[`label`] ? "initial": "hidden"}} fontSize="small" className={classes.iconErrorFieldLabel}/> 
                <FormControl fullWidth size="small">
                    <InputLabel  error={!!errors.hasOwnProperty("label") && errors["label"].message}>{props?.isDisabledClear? "Etiqueta" : "Seleccione una etiqueta"}</InputLabel>
                    <Select
                    {...register('label')}
                    name='label'
                    onChange={handleChange}
                    value={dataForm?.label}
                    label={props?.isDisabledClear? "Etiqueta" :"Selecciona una etiqueta"}
                    className={classes.dropdown}
                    error={!!errors.hasOwnProperty("label") && errors["label"].message}
                    >
                      { props?.labels?.map((x) => (
                        <MenuItem 
                        value={x?.name} 
                        key={x?.uuid}
                        >
                          {x?.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{color:'red'}}>
                    {errors.hasOwnProperty("label") &&
                    errors["label"].message}
                </FormHelperText> 
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} style={{display: 'flex',  alignItems: 'center'}}>
                    <Button 
                      variant="contained" 
                      form="searchQuestion"
                      type="submit" 
                      disableElevation 
                      style={{color: "#ffffff", background: validate ? "#2F71CB" :'#C0C0C0', marginRight: '8%'}}
                    >
                      BUSCAR
                    </Button>
                     
                    
                    <Button 
                      variant="outlined"  
                      disableElevation 
                      onClick={()=> clear()}
                      style={{background:"#ffffff"}}
                    >
                      {props?.isDisabledClear? 'RESTABLECER' : 'LIMPIAR'}
                      
                    </Button>
                    
                </Grid>
            </Grid>
         </form>
    )
}

export default SearQuestion
