import React, {useEffect, useState} from 'react'
import { Grid, FormControl, TextField, FormHelperText } from '@mui/material';
import {styles} from "./styles";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useStateValue } from './../../../../../../context/store';

const Index = (props) => {

    const classes = styles();
    const [{valorationAplication}, dispatch] = useStateValue();

    const schema = yup.object().shape({
        answer: yup.string().required('Campo requerido').max(1000, 'Ingrese máximo 1000 caracteres'),
      });
    
      const {
        control,
        setValue,
        trigger,
        formState: { errors,  },
        watch,
      } = useForm({
        resolver: yupResolver(schema),
        mode: "onTouched",
        reValidateMode: "onChange",
      });


    const handleBlur = (e) => {
      const {value} = e.target;
    
        setValue('answer', value?.trimEnd());
        if(value.trimEnd()?.length <= 1000 ){
          const valueError = value.trimEnd()?.length <= 1000 && !errors['answer']

          const findCompetence = valorationAplication?.data?.competences?.find((x)=> x?.uuid === props?.competenceId)
          let differentCompetence = valorationAplication?.data?.competences?.filter((x)=> x?.uuid !== props?.competenceId) 
          if(findCompetence) {

            const findBehavior = findCompetence?.conducts?.find((x)=> x?.uuid === props?.behaviorId)
            const differentBehavior = findCompetence?.conducts?.filter((x)=> x?.uuid !== props?.behaviorId)
            const findQuestion = findBehavior?.questions?.find((x)=> x?.uuid === props?.uuid)
            let differentQuestions = findBehavior?.questions?.filter((x)=> x?.uuid !== props?.uuid)

            const updateQuestion = {...findQuestion, value: [value], typeQuestion: 'TEX', alternativeId: findQuestion?.alternativeId, error: !valueError }
            differentQuestions.push(updateQuestion)
            const updateBehavior = {...findBehavior, questions: _.orderBy(differentQuestions, ['position'],["asc"])}
  
  
            differentBehavior?.push(updateBehavior)
            const updateCompetence = {...findCompetence, conducts: _.orderBy(differentBehavior, ['position'],["asc"]) }
            differentCompetence.push(updateCompetence)
    
    
            dispatch({
                type: "SET_VALORATE_APLICATION",
                disabledValors: valorationAplication?.disabledValors,
                data: {competences : _.orderBy(differentCompetence, ['position'],["asc"])}
            })
      }
        }else if(value.trimEnd()?.length > 1000){

          const valueError = value.trimEnd()?.length > 1000 
          const findCompetence = valorationAplication?.data?.competences?.find((x)=> x?.uuid === props?.competenceId)
          let differentCompetence = valorationAplication?.data?.competences?.filter((x)=> x?.uuid !== props?.competenceId) 
          if(findCompetence) {
  
            const findBehavior = findCompetence?.conducts?.find((x)=> x?.uuid === props?.behaviorId)
            const differentBehavior = findCompetence?.conducts?.filter((x)=> x?.uuid !== props?.behaviorId)
            const findQuestion = findBehavior?.questions?.find((x)=> x?.uuid === props?.uuid)
            let differentQuestions = findBehavior?.questions?.filter((x)=> x?.uuid !== props?.uuid)

            const updateQuestion = {...findQuestion, value: [value], typeQuestion: 'TEX', alternativeId: findQuestion?.alternativeId, error: valueError }
            differentQuestions.push(updateQuestion)
            const updateBehavior = {...findBehavior, questions: _.orderBy(differentQuestions, ['position'],["asc"])}
  
  
            differentBehavior?.push(updateBehavior)
            const updateCompetence = {...findCompetence, conducts: _.orderBy(differentBehavior, ['position'],["asc"]) }
            differentCompetence.push(updateCompetence)
    
    
            dispatch({
                type: "SET_VALORATE_APLICATION",
                disabledValors: valorationAplication?.disabledValors,
                data: {competences : _.orderBy(differentCompetence, ['position'],["asc"])}
            })
          }
        }
        
    }

    const sendErrors = (error) => {

      const findCompetence = valorationAplication?.data?.competences?.find((x)=> x?.uuid === props?.competenceId)
      let differentCompetence = valorationAplication?.data?.competences?.filter((x)=> x?.uuid !== props?.competenceId) 
      if(findCompetence) {
        
          const findBehavior = findCompetence?.conducts?.find((x)=> x?.uuid === props?.behaviorId)
          const differentBehavior = findCompetence?.conducts?.filter((x)=> x?.uuid !== props?.behaviorId)
          const findQuestion = findBehavior?.questions?.find((x)=> x?.uuid === props?.uuid)
          let differentQuestions = findBehavior?.questions?.filter((x)=> x?.uuid !== props?.uuid)

          const updateQuestion = {...findQuestion, error: error}
          differentQuestions?.push(updateQuestion)
          const updateBehavior = {...findBehavior, questions: _.orderBy(differentQuestions, ['position'],["asc"])}


          differentBehavior?.push(updateBehavior)
          const updateCompetence = {...findCompetence, conducts: _.orderBy(differentBehavior, ['position'],["asc"]) }
          differentCompetence.push(updateCompetence)
  
  
          dispatch({
              type: "SET_VALORATE_APLICATION",
              disabledValors: valorationAplication?.disabledValors,
              data: {competences : _.orderBy(differentCompetence, ['position'],["asc"])}
          })
    }
  }

  useEffect(()=> {
    if(watch('answer')){
        let value =  watch('answer')?.replace(/\s{2,}/g, ' ');
         if (value?.charAt(0) === ' ') {
           value = value.slice(1);
         }
        setValue('answer', value);
        trigger('answer')
        
    }

  },[watch('answer')])

    useEffect(()=> {

      if(!props?.disabled || props?.disabled === undefined){
        if(errors.hasOwnProperty("answer") ){
          sendErrors(true)
        }else {
          sendErrors(false)
        }
      }
      
    },[errors])


  return (
    <form>
    <Grid item container lg={12} md={12} justifyContent="center">
    <FormControl fullWidth>
      
      <Controller
        name='answer'
        control={control}
        render={({field})=> (
          <>
          <TextField
            {...field}
            input={field.ref}
            name='answer'
            className={classes.dropdown}
            multiline
            value={field.value}
            placeholder="Respuesta "
            disabled={props?.disabled}
            rows={4}
            onBlur={(e)=>field.onBlur(handleBlur(e))}
            InputLabelProps={{
              shrink: true,
              notched: true,
            }}
            rowsMax={4}
            label="Respuesta"
            error={!!errors.hasOwnProperty("answer") && errors["answer"].message}
        />
        <div className={ errors.hasOwnProperty("answer") ? classes.accountantPreviewErro : classes.accountantPreview}>{`${
          watch("answer")?.length ? watch("answer")?.length : 0
        }/1000`}</div>
        <FormHelperText style={{color:'#d70909'}}>
            {!!errors.hasOwnProperty("answer") &&
            errors["answer"].message}
        </FormHelperText>
      </>
        )}
      />
        
        
    </FormControl>
  </Grid>
    </form>
  )
}

export default Index