import { makeStyles, withStyles } from '@mui/styles';

export const styles = makeStyles({
    expandText :{ 
        textDecoration: 'underline',
        cursor: 'pointer',
        marginLeft: 5,
        color: '#3300ff'
       },
    modalBank: {
        [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
          maxWidth: 900,
          minWidth: 900,
          borderRadius: 16
      },
      [`& .jss59 .css-uhb5lp`] : {
        maxWidth: 900,
        minWidth: 900,
        borderRadius: 16
      },
      [`& .css-uhb5lp`]: {
        borderRadius: 12,
        maxWidth: 900,
        minWidth: 900,
      }
      },
    titleModalBank: {
        fontWeight: 400,
        textAlign: 'center',
        fontSize: 16,
        fontFamily: 'Roboto',
        letterSpacing: '0.25px'
      },
      wrapperSelect: {
        border: '1px solid gray',
        cursor: 'pointer',
        borderRadius: 10,
        marginBottom: 8,
        display: 'flex',
        background: 'rgba(154, 187, 100, 0.46)', 
        wordBreak: 'break-word', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        margin: '0px 0px 0px 1.5%'
      },
      objectsSelect: {
        display: 'flex',
        position: 'relative', 
        alignItems: 'flex-start', 
      },
      selectLevel: {
        borderRadius: 10,
        background: '#ffffff',
        [`& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input`] :{
          padding: '3.5px 25px'
        }
      },
})


