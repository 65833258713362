import {  makeStyles } from "@mui/styles";

export const styles = makeStyles((theme) => ({
    dropdown: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
        '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
          background: '#dfe7fd',
        },
        '& .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root': {
            padding: '20px 14px'
        }
    },
      dropdownMultiple: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
    },
    accountantPreview: {
        position: 'absolute',
        right: '1%',
        fontSize: 'small',
        color: 'gray',
        top: '85%'
      },
    accountantPreviewErro :{
      position: 'absolute',
      right: '1%',
      fontSize: 'small',
      color: 'gray',
      top: '74%'
    }
  }));