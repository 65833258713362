import React, {useEffect, useState} from 'react'
import { useStateValue } from '../../../../context/store';
import { Grid } from "@material-ui/core";
import ModalConfirm from '../../../../utils/form/modalConfirmBase';
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { getTypesEvaluators } from './../../../../actions/Performance_Evaluation-action';
import ModelEvaluation from "../../../createEvaluationPerformance/relationShip/relations";
import { PATH_APP } from '../../../../constants';
import { titleHeader } from './../../../../constants';
import CustomizedButtonMenu from "../../../createEvaluationPerformance/relationShip/relations/components/button-menu";
import ModalReviewRol from "../../../createEvaluationPerformance/relationShip/relations/components/modal-review-rol";
import { pushDataRelations } from './../../../../utils/constants';


const Index = (props) => {
    const history = useHistory();

    const [{ relations}, dispatch] = useStateValue()
    const [typesEvaluators, setTypesEvaluators] = useState([]);
    const [typesEvaluation, setTypesEvaluation] = useState([]);
    const [message, setMessage] = useState({
        description : '',
        textButtonSubmit : '',
        handleClick : () => {} ,
        handleClickOut : () => {},
        oneButtons : false,
        title: '',
        type : '',
        open : false
      })
 

    const backToFormInitial = () =>  history.push(`${PATH_APP}/evaluation-performance-evaluator`);
    const getTypes = async() => {
        const result = await getTypesEvaluators(dispatch);
        if(result?.data?.length) {
            setTypesEvaluation(result?.data?.map((x)=> ({...x, id: x?.uuid })))
            setTypesEvaluators(result?.data?.map((x)=> ({id: x?.uuid, value: titleHeader(x?.abbreviation)})))
        }else {
            return setMessage({
                open : true,
                description: 'Se presento un error en el servicio de tipos de evaluadores',
                handleClick : () => backToFormInitial() ,
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                type : 'WARNING',
            })
        }
        
      }


    useEffect(() => {
         getTypes();
         pushDataRelations(props?.dataDetail, dispatch);
    }, []);
    

    return (
        <>

            <ModelEvaluation 
                data={relations?.data}
                sections={relations?.dataSections}
                message={props?.message}
                setMessage={props?.setMessage}
                isEdit={props?.isEdit}
                isCopy={props?.isCopy}
                currentData={props?.currentData}
                typesEvaluators={typesEvaluators}
                modelDetail={true}
            />
            
        </>
    )
}

export default Index