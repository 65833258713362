export const initialState = {
   reset: false,
  };
  
  const resetPageReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_RESET_PAGE":
        return {
          ...state,
          reset: action.reset,
        };
      default:
        return state;
    }
  };
  
  export default resetPageReducer;
  