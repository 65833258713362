import React, { useEffect, useState } from 'react'
import { useStateValue } from "../../../context/store";
import DetailedAccordion from './tableAplicationCuestionary/index';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import SearchAplicationQuestionary from './search';
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import {getfilterAplications} from '../../../actions/AplicationQuestionary-action'


const Index = () => {
  const [{ aplicationQuestionary, loadingTables }, dispatch] = useStateValue();
  const [clearFielForm, setClearFielForm]= useState(false);
  const [data, ] = useState({
    createdAtStartDate : null,
    createdAtEndDate: null,
    questionnaireNameKeyword: null,
   
  })
  const [paginadorResponse, setPaginadorResponse] = useState({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  const [paginadorRequest, setPaginadorRequest] = useState({
    page: 0,
    size: 10,
  });
  
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
    })

    const closeModalAndClear = () => setClearFielForm(!clearFielForm)


  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar aplicación de cuestionarios",
      index: 5,
      module: "DETAIL_APLICATION"
    });
    if(paginadorResponse?.data?.length){
    getAllQuestionnaires()
  }
  window.scrollTo(0,0)
  }, [paginadorRequest])


  const closeModal = () => {
    setMessage({...message, open: false}); 
    closeModalAndClear();
}

  const getAllQuestionnaires = async() => {
 
    const result = await getfilterAplications({
      createdAtStartDate : data.code === null ? null : data?.createdAtStartDate,
      createdAtEndDate: data.name === null ? null : data?.createdAtEndDate,
      questionnaireNameKeyword: data.description === null ? null : data?.questionnaireNameKeyword,
      page: paginadorRequest?.page === null ? null : paginadorRequest?.page,
      size:  paginadorRequest?.size === null ? null : paginadorRequest?.size,
        
    }, dispatch);
    
    
    if(result?.data?.length < 1 && !aplicationQuestionary?.typeAction){
      setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });
      return setMessage({
        open : true,
        description: 'No se encontró información relacionada a la búsqueda',
        handleClick : () => closeModal() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })
     }
    if ((result && result.status === 200) || (result.status === 201)) {
      const data = result?.data;
      if (data?.content?.length > 0) {
        setPaginadorResponse({
          data: data?.content,
          totalRows: data?.totalElements,
          pageSize: data?.size,
          pageNumber: data?.number,
        });
      }
      
    } 
    else {
      setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });
    }
  
  
  }

  if(aplicationQuestionary &&
    aplicationQuestionary?.isFilter) {

      const newData = aplicationQuestionary?.filter
      data.createdAtStartDate = newData?.createdAtStartDate;
      data.createdAtEndDate = newData?.createdAtEndDate;
      data.questionnaireNameKeyword = newData?.questionnaireNameKeyword;
      paginadorRequest.page = newData?.page;
      paginadorRequest.size = newData?.size
      getAllQuestionnaires( )
      dispatch({
        type: "SET_FILTER_APLICATION",
        filter: aplicationQuestionary?.filter,
        isFilter: false,
      })
     
  }

  const changePage = (event, nuevaPagina) => {
   
    setPaginadorRequest({
      ...paginadorRequest,
      page: nuevaPagina,
  
     
    });
  };

  const changeAmountOfRecords = (event, siguiente) => {
    setPaginadorRequest({
      ...paginadorRequest,
      size: event.target.value,
      page: 0
    });
  };

  const clearFilter = () => {
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    })
  }
    return (
        <div >
            < SearchAplicationQuestionary 
              message={message}
              setMessage={setMessage}
              clearFilter={clearFilter}
              clearFielForm={clearFielForm}
            />
            {loadingTables?.active ?
                <Box sx={{ display: 'flex', marginTop:'15%', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
                : 
               
                < DetailedAccordion  
                  dataQuestionary={ paginadorResponse?.data}

                  />
              
              }
            
            <ModalConfirm 
              description = { message.description }
              textButtonSubmit = { message.textButtonSubmit }
              handleClick = { message.handleClick }
              handleClickOut = { message.handleClickOut }
              oneButtons = { message.oneButtons }
              title = { message.title }
              type = { message.type }
              open = { message.open }
            />
             {paginadorResponse?.totalRows !== 0 && !loadingTables?.active &&
                <TablePagination
                  style={{display:"flex", color: '#747578', justifyContent: 'center' }}
                  component="div"
                  rowsPerPageOptions={[5, 10, 15]}
                  count={paginadorResponse.totalRows}
                  rowsPerPage={paginadorRequest.size}
                  page={paginadorRequest.page}
                  labelRowsPerPage='Registros por página'
                  onPageChange={changePage}
                  onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
                />
             }
             
        </div>
    )
}

export default Index
