import React from "react";
import {  Typography, Button, Grid } from "@mui/material";
import { withRouter } from "react-router-dom";
import { PATH_APP } from "../../constants";

const style = {
  paper: {
    flexDirection: "column",
},
  leftside: {
    backgroundColor: "#004B93",
    width: "100%",
    height: "100%",
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    align: "center",
  },
  submit: {
    marginLeft: '45%',
    margin: '5% 45% 12%'
}
};

const NoMatchPage = (props) => {
  const gotoLoginBtn = (e) => {
    e.preventDefault();
    props.history.push(`${PATH_APP}/home`);
  };

  return (
      <Grid container spacing={0} style={{display: 'initial'}}>
      <div style={style.paper}>
          <Typography
              component="h1"
              variant="h5"
              style={{ color: "#124387", paddingTop: '10%', background: '#dfe7fd', textAlign: 'center'}}
          >
              <b>La sección a la que trata de acceder no existe.</b>
          </Typography>
          <Button
              type="button"
              variant="contained"
              color="primary"
              style={style.submit}
              onClick={gotoLoginBtn}
          >
              Ir al Home
          </Button>
      </div>

    </Grid>
  );
};

export default withRouter(NoMatchPage);
