import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField, 
     Select, 
     InputLabel, 
     FormControl, 
     FormHelperText, 
     MenuItem,
     Button,
} from '@mui/material'
import { useStateValue } from "../../../context/store";
import {styles} from './styles';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Index = (props) => {


    const classes = styles()
    const [{labelSearch, criteriaSearch, breadCrumb}, dispatch] = useStateValue();
    const [valuesForm, setValuesForm] = useState({
        status: '',
        criteria: '',
        code: ''
    })


    const schema = yup.object().shape({
        code: valuesForm?.code ? yup
        .string()
        .max(6, "Ingrese máximo 6 caracteres")
        .matches(  
            /^([0-9]{0,6})$/,
           "Código no válido") : null,
        criteria: valuesForm?.criteria ? yup.string()
            .notRequired()
            .nullable(true)
            .max(100, 'Ingrese máximo 100 caracteres') : null,
        status: null
      });
    
      
      const { register, handleSubmit, setValue, trigger, unregister, watch, formState:{errors} } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });
      

      const onSubmit = (data) => {
        if(
            data?.criteria !== '' ||
            data?.code !== '' ||
            valuesForm.status !== ''
        ){
            dispatch({
                type: "SET_FILTER_CRITERIA",
                filter: {
                    criteria: data?.criteria ,
                    code: valuesForm?.code,
                    status:  valuesForm.status,
                    page: 0,
                    size: criteriaSearch?.filter?.size ? criteriaSearch?.filter?.size : 10
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "SET_FILTER_CRITERIA",
                filter: {
                    criteria: null,
                    status: null,
                    code: null
                  },
                  isFilter: true,
            })
        }
      }

      const clear = () => {
        setValue('criteria', '')
        setValue('code', '')
        unregister(['code','criteria', 'status'])
        setValue('status', '')
        setValuesForm({...valuesForm, status: '', criteria: '', code: ''})
      }

      useEffect(() => {
        clearForm()
      }, [props?.clearFielForm])

      useEffect(() => {
        clearStateError()
      }, [props?.resetError])
      
    const clearForm = () => {
        clear()
        resetDispatch()
        props.dataFilter()
    };
    
    const clearStateError = () => {
        clear();
        resetDispatch();
        props?.resetTable()
    }

    const resetDispatch = () => {
        dispatch({
            type: "SET_FILTER_CRITERIA",
            filter: {
                criteria: null,
                status: null,
                code: null,
                page: 0,
                size: 10
              },
              isFilter: false,
          })
    }
        
        if(labelSearch?.typeAction === 'CLEAR'){ 
             clear()
        }


        const handleOnchange = (e) => {
            const {name, value} = e.target;
            setValuesForm({...valuesForm, [name]:value})
            setValue(name, value)
            trigger(name)
        }


        useEffect(()=> {
            if(breadCrumb?.module === "CRITERIA_EVALUATION" && criteriaSearch?.isFilter){
                setValue('criteria',criteriaSearch?.filter?.criteria)
                setValue('status', criteriaSearch?.filter?.status)
                setValue('code', criteriaSearch?.filter?.code)
                setValuesForm({
                                ...valuesForm, status: criteriaSearch?.filter?.status,
                                code: criteriaSearch?.filter?.code,
                                criteria: criteriaSearch?.filter?.criteria
                            })
                setValue('status', criteriaSearch?.filter?.status)
            }
         
        },[breadCrumb?.module === "CRITERIA_EVALUATION"])

        const validate = valuesForm.status || watch('code') || valuesForm?.criteria

    return (
         <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container spacing={2} style={{marginBottom: 20, display: 'flex', alignItems: 'flex-start'}}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('criteria')}
                    className={classes.dropdown}
                    fullWidth
                    type="text"
                    onChange={handleOnchange}
                    InputLabelProps={{ shrink: valuesForm?.criteria? true : false }}
                    notched={valuesForm?.criteria? 'true' : 'false'}
                    multiline
                    maxRows={4}
                    size="small"
                    label="Escala de valoración"
                    error={!!errors.hasOwnProperty("criteria") && errors["criteria"].message}
                  />
                </FormControl>
                <FormHelperText style={{color:'red', marginLeft: '1%'}}>
                    {errors.hasOwnProperty("criteria") &&
                        errors["criteria"].message}
                </FormHelperText> 
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register("code")}
                    name="code"
                    className={classes.dropdown}
                    fullWidth
                    onChange={handleOnchange}
                    InputLabelProps={{ shrink: valuesForm?.code ? true : false }}
                    notched={valuesForm?.code? 'true' : 'false'}
                    size="small"
                    label="Código"
                    inputProps={{
                        autocomplete: 'off'
                    }}
                    error={!!errors.hasOwnProperty("code") }
                    />
                </FormControl>
                <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                    {errors.hasOwnProperty("code") &&
                        errors["code"].message}
                </FormHelperText> 
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} >
                <FormControl variant='outlined' fullWidth size="small">
                    <InputLabel error={!!errors.hasOwnProperty("status") && errors["status"].message}>Estado</InputLabel>
                        <Select
                        {...register('status')}
                        name='status'
                        value={valuesForm.status}
                        onChange={handleOnchange}
                        status="Estado"
                        className={classes.dropdown}
                        label="Estado "
                        error={!!errors.hasOwnProperty("status") && errors["status"].message}
                    >
                        <MenuItem value='ACTIVE' key={1}>Activo</MenuItem>
                        <MenuItem value='INACTIVE' key={2}>Inactivo</MenuItem>
                    </Select>
                    <FormHelperText style={{color:'red'}}>
                    {errors.hasOwnProperty("status") &&
                        errors["status"].message}
                    </FormHelperText> 
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} className={classes.buttons}>
                    <Button 
                    variant="contained" 
                    type="submit" 
                    disableElevation 
                    disabled={!validate}
                    color="primary"
                    >
                        BUSCAR
                    </Button>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 
                    <Button 
                    variant="outlined" 
                    onClick={clearForm} 
                    disableElevation 
                    style={{background: "#ffffff"}}
                    >
                         Limpiar
                    </Button>

                </Grid>
            </Grid>
        </form>
    )
}

export default Index
