import axios from 'axios';
import { APP_URL } from '../constants'


export const http = axios.create({
  baseURL: APP_URL,
  withCredentials: false, // This is the default
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
})

http.interceptors.request.use(
  
  async function (config) {
    const token = 'Basic ' + (localStorage.getItem('token_app') || '')
    config.headers['Authorization'] = token
    return config
  },
  
  error => {
    return Promise.reject(error)
  }
)


http.interceptors.response.use(
  (response)=> {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)



  export default http;