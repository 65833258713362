export const menuDataResponse = {
  menus: [
        {
          id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d50",
          title: "Organigrama",
          code: "MANAGEMENT_APLICATION_EVALUATIONS",
          index: 14,
          breadCrumbText: "Autoevaluaciones",
          icon: "badge",
          url: "/organization-chart",
        },
        {
          id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d45",
          title: "Etiquetas",
          code: "LABEL",
          index: 2,
          breadCrumbText: "Autoevaluaciones",
          icon: "forum",
          url: "/labels",
        },
        {
          id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d46",
          title: "Preguntas",
          code: "QUESTION",
          index: 3,
          breadCrumbText: "Autoevaluaciones",
          icon: "assignment_turned_in",
          url: "/questions",
        },
        {
          id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d46",
          title: "Gestionar cuestionarios",
          code: "QUESTIONARY",
          index: 4,
          breadCrumbText: "Autoevaluaciones",
          icon: "web_asset",
          url: "/questionary",
        },
        {
          id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d46",
          title: "Aplicación de cuestionario",
          code: "QUESTIONARY_APLICATION",
          index: 5,
          breadCrumbText: "Autoevaluaciones",
          icon: "article",
          url: "/aplication",
        },
        {
          id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d46",
          title: "Cuestionarios",
          code: "ANSWER_QUESTIONARY",
          index: 6,
          breadCrumbText: "Autoevaluaciones",
          icon: "workspace_premium",
          url: "/answer-questionnaire",
        },
        {
          id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d49",
          title: "Gestionar modelo de evaluación",
          code: "PERFORMANCE_EVALUATION_EVALUATED",
          index: 12,
          breadCrumbText: "Autoevaluaciones",
          icon: "badge",
          url: "/evaluation-performance-evaluated",
        },
        {
          id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d48",
          title: "Modelo de evaluación",
          code: "PERFORMANCE_EVALUATION",
          index: 10,
          breadCrumbText: "Autoevaluaciones",
          icon: "inventory",
          url: "/evaluation-performance",
          subMenus: [
            {
              id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d49",
              title: "Escala de valoración",
              code: "CRITERIA",
              index: 11,
              breadCrumbText: "Autoevaluaciones",
              icon: "badge",
              url: "/criteria",
            },
            {
              id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d47",
              title: "Aspectos",
              code: "BEHAVIORS",
              index: 9,
              breadCrumbText: "Autoevaluaciones",
              icon: "badge",
              url: "/behavior",
            },
            {
              id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d45",
              title: "Criterios",
              code: "COMPETENCIES",
              index: 8,
              breadCrumbText: "Autoevaluaciones",
              icon: "badge",
              url: "/competencies",
            },
            {
              id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d49",
              title: "Modelos de evaluación",
              code: "PERFORMANCE_EVALUATION",
              index: 12,
              breadCrumbText: "Autoevaluaciones",
              icon: "badge",
              url: "/evaluation-performance-evaluator",
            },
          ],
        },
        {
          id: "menu_seg_818d9c3e_90be_4081_8db5_d7dce58768d49",
          title: "Aplicaciones de evaluación",
          code: "MANAGEMENT_APLICATION_EVALUATIONS",
          index: 13,
          breadCrumbText: "Autoevaluaciones",
          icon: "badge",
          url: "/management-aplication-evaluations",
        },
       
  ],
};
