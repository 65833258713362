import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
    flexGrow: 1,
  },
  toolbar: {
    flex: "0 1 auto",
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
}));

const MainContent = ({ children }) => {
  const classes = useStyles();

  return (
    <main className={classes.content} id="mainContentComponent">
      <div id="mainContentToolbar" className={classes.toolbar} />
      {children}
    </main>
  );
};

export default MainContent;
