import { makeStyles } from '@mui/styles';
import theme from "../../../../../theme/theme";


export const styles = makeStyles({
    dropdown: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        height: 40,
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
        '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
          background: '#dfe7fd',
        },
    },
      dropdownMultiple: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
    },
    accountantPreview: {
        position: 'absolute',
        left: '93%',
        fontSize: 'small',
        color: 'gray',
        top: '80%'
      },
    question: {
      fontSize: 14,
      fontWeight: 400
    }
})