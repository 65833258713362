import React, { useEffect, useState } from 'react'
import { useStateValue } from "../../context/store";
import { Grid, Typography, TextField, Paper } from '@mui/material';
import { menuDataResponse } from '../../components/LeftMenu/jsonMenu';
import 'dayjs/locale/es';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Home from "../../images/ImgHome.png";
import Icon from '@mui/material/Icon';
import moment from "moment";
import {styles} from "./styles";
import { PATH_APP } from '../../constants';

const Index = (props) => {
  const classes = styles();
  const [, dispatch] = useStateValue();
  const [ buttons, setButtons ]= useState([])

  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Inicio",
      index: 0,
    });
  }, [])


  const validateTime = () => {

   const currentTime = moment(new Date()).format('LT')
   const currentHour = Number(currentTime.substring(0, currentTime.indexOf(':')))
    if(currentHour > 18 ){
      return 'Buenas noches,';
    }else if (currentHour > 12 && currentHour <= 18) {
      return 'Buenas tardes,'
    }else {
      return 'Buenos días,'
    }
  }

  const farewell = () => {

    const currentTime = moment(new Date())?.format('LT')
    const currentHour = Number(currentTime.substring(0, currentTime.indexOf(':')))
 
     if(currentHour > 18){
       return '¡Ten una excelente noche!';
     }else if (currentHour > 12 && currentHour <= 18) {
       return '¡Ten una excelente tarde!';
     }else {
       return '¡Ten un excelente día!';
     }
   }

   useEffect(()=> {

    let permisions = []
    for (let index = 0; index < menuDataResponse?.menus?.length; index++) {
      if(menuDataResponse?.menus[index]?.subMenus){

        for (let sub = 0; sub < menuDataResponse?.menus[index]?.subMenus?.length; sub++) {
          permisions.push(menuDataResponse?.menus[index]?.subMenus[sub])
          
        }
      }else {
        permisions?.push(menuDataResponse?.menus[index])
      }
      
    }

    setButtons(permisions)
   },[])

  const name = JSON.parse(localStorage?.getItem('session'))

    return (
       <Grid container spacing={1} justifyContent="space-between" style={{marginTop: '-8%'}}>
        <Grid item lg={8}>
          <Paper elevation={3} style={{borderRadius: 10, display: 'flex', padding: 10}}>
    
                <Grid item container alignItems="center">
                  <Grid item container lg={6.5}>
                      <Grid container justifyContent="center" style={{paddingLeft: '5%'}}>
                        <Grid item lg={12} md={11} style={{display: 'flex', alignItems: 'center'}}>
                          <Typography style={{fontSize: 24, color: '#003D51'}}>{validateTime()}</Typography>
                          <Typography style={{fontSize: 24, color: '#7EBDCE', marginLeft: 10}}>{name?.user?.name}</Typography>
                        </Grid>
                        <Grid item lg={11}>
                          <Typography style={{fontSize: 24, color: '#003D51'}}>{farewell()}</Typography>
                        </Grid>
                      </Grid>
                  </Grid>
                  <Grid item lg={5.5}>
                    <img src={Home} alt="home" style={{width: 300, height: 260}}/>
                  </Grid>
                </Grid>
     
          </Paper>
        </Grid>

        <Grid item lg={3}>
          <Paper elevation={3} style={{borderRadius: 10}}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" >
              <StaticDatePicker
                className={classes.date}
                style={{width: 'fit-content', marginLeft: 'auto', }}
                displayStaticWrapperAs="desktop"
                openTo="day"
                readOnly={true}
                disablePast={true}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Paper>
       
        </Grid>
        <Grid item container spacing={3} lg={12} style={{marginTop: '5%', borderRadius: 15}}>
          {buttons?.map((x)=> (
            name?.permissions?.includes(x?.code) ? <Grid item lg={2} onClick={()=> props?.history?.push(`${PATH_APP}${x?.url}`)}>
              <Paper elevation={3} className={classes.containerButton} style={{borderRadius: 20}}>
                <section className={classes.containedCardIcon}>
                  <Icon className={classes.icons}>{ x?.icon }</Icon>
                </section >
                <section className={classes.containedTitle} >
                  <Typography className={classes.titleButton}>{x?.title}</Typography>
                </section>
              </Paper>
            </Grid>
            : null
            
              
          ))}
        </Grid>
       </Grid>
    )
}

export default Index
