import React,{useState, useEffect} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Checkbox, Typography } from '@mui/material';
import { styles } from "./styles";
import { useStateValue } from '../../../../../context/store';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { typesQuestions } from '../../../../../constants';
import _ from 'lodash';

const Index = (props) => {
    const classes = styles();
    const [{relations},] = useStateValue()
    const [checkeds, setCheckeds] = useState([])
    const [showText, setShowText] = useState([]);
    const [initialCheckeds, setInitialCheckeds] = useState();
    const [numberPosition, setNumberPosition] = useState(1);
    const [questions, setQuestions] = useState([]);

    useEffect( () => {
      setQuestions(props?.data);
    },[props?.data]);

    const showQuestion = (data, id) => {
      const result = showText.find((x)=> x?.id === id)
      if(result?.show) {  
          return data 
      } else {
          return `${data.substring(0, 100)}` 
      }
    }
      
    const allShow = (id) => {
        const result = showText.find((x)=> x?.id === id)
        if(result?.show) {
        setShowText([{show: false, id: id}])
        }else {
        setShowText([{show: true, id: id}])
        }
    }
    
    const validateShowText = (id) => {
        const result = showText.find((x)=> x?.id === id)
        return result?.show ? true : false
    }

    const validateChecked = (data) => {
        const result =  checkeds?.find((x) =>x?.uuid === data?.uuid )
       return result ? true : false
    }

    const handleChangeChecked = (event) => {
      const copy= [...checkeds]
      const {name, } = event.target;
     
        const filterQuestions = props?.data?.find((x) => x?.uuid === name)
        const validateExists = validateChecked(filterQuestions)
       if(validateExists) {
  
         setCheckeds(checkeds.filter((x)=> x.uuid !== name))
         
        }else {
        setNumberPosition(numberPosition+1)
          copy.push(
            {
              answerAlternativeCode: filterQuestions?.answerAlternativeCode,
              questionAlternativeList: filterQuestions?.questionAlternativeList,
              uuid: filterQuestions.uuid, 
              value: filterQuestions.question, 
              code: filterQuestions.tag?.name,
              position: numberPosition +1
            }
          )
          setCheckeds(copy);
       } 
    };

    useEffect(() => {
      const validateCompetence = relations?.data?.find((x)=> x?.uuid === relations?.competencieSelected) 
      const validateBehavior = validateCompetence?.behaviors?.find((x) => x?.uuid === relations?.behaviorSelected)
      const posQuestions = validateBehavior?.questions?.map((q) => q?.position);
      
      if(validateBehavior?.questions?.length){
        const bigger = Math.max(...posQuestions)
        setCheckeds(validateBehavior?.questions)
        setInitialCheckeds(validateBehavior?.questions)
        setNumberPosition(bigger)
      } else {
        setCheckeds([])
      }
    }, [])
    
    useEffect(() => {
      props?.getQuestions(checkeds)
  
      const customizer = (value1, value2, key) => {
          if (key === 'position') {
              return true; 
          }
      };
      const result = _.isEqualWith(_.orderBy( checkeds, 'value'), _.orderBy( initialCheckeds, 'value'), customizer);
      props?.disabledSave(result)
    
    }, [checkeds])
    
  return (
      <div >
      <TableContainer component={Paper} style={{ width: '100%', border: '1px solid #d9d7d7', borderRadius: 10, marginTop: '3%' }}>
        <Scrollbars style={{height: 270, paddingTop: '10px !important'}}>
        {questions?.length ? <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead >
          <TableRow >
            <TableCell size="small" padding="none" style={{borderBottom: 'none'}}></TableCell>
            <TableCell align="center" size="small" padding="none"  style={{borderBottom: 'none'}}>Etiqueta</TableCell>
            <TableCell align="center" size="small" padding="none"  style={{borderBottom: 'none'}}>Tipo</TableCell>
            <TableCell align="center" size="small" padding="none"  style={{borderBottom: 'none'}}>Pregunta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions?.map((x, index) => (
            <TableRow
              key={x.uuid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{verticalAlign: 'Initial'}}
            >
              <TableCell align="center" component="th" scope="row" size="small" padding="none" style={{borderBottom: 'none'}}>
                <Checkbox
                    checked={validateChecked(x)}
                    name={x?.uuid}
                    onChange={handleChangeChecked}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
              </TableCell >
              <TableCell align="center" size="small" padding="none" style={{borderBottom: 'none', width: '15%'}}>{x.tag?.name}</TableCell>
              <TableCell align="center" size="small" padding="none" style={{borderBottom: 'none', width: '15%'}}>{typesQuestions[x?.answerAlternativeCode]}</TableCell>
              <TableCell align="left" size="small" padding="none" style={{borderBottom: 'none', padding: '0px 2%', width: '60%'}}>
                <Typography component={'span'} style={{marginTop: '1.5%', marginRight: 10}} className={classes.heading} >
                    {x.question?.length > 100 ? showQuestion(x?.question, index) : x?.question}{x?.question?.length > 100 && 
                    <Typography variant="caption"
                        className={classes.expandText}
                        onClick={()=> allShow(index)}>{validateShowText(index) ? "...ver menos" : "...ver más" }
                    </Typography>}
                </Typography>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> : null}
        </Scrollbars> 
    </TableContainer>
    </div>
  )
}

export default Index