import React, { useState } from "react";
import * as IdKey from "short-uuid";
import { Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalDelete from '../../../utils/form/modalConfirmBase'
import {  makeStyles } from "@mui/styles";
import EditCompetencie from "../editCompetencies";
import { useStateValue } from "../../../context/store";
import { useHistory } from "react-router-dom"
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PATH_APP } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  MuiIconButtonEdgeEnd: {
    order: "-1",
  },
  root: {
    [theme.breakpoints.down('md')]: {
        flex: "1 1 auto",
        margin: "25% 2% 0% 2%",
        minHeight: "80vh",
      },
      [theme.breakpoints.up('md')]: {
        width: "100%",
      },
      [theme.breakpoints.up('lg')]: {
        // width: "67%",
        margin: '10px 0% auto 0%'
      },
  },
  rootGrid: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    color: '#8E8E8E',
    wordBreak: 'break-word'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  iconEvent: {
    color: "#646464",
    cursor: "pointer",
    height: 18,
    width: 18,
    pointerEvents: 'auto',
    '&:hover':{
      color: "#2F71CB",
    }
  },
  column: {
    flexBasis: "24.2%",
    padding: '1.5% 0px',
    background: '#FFFFFF', 
    textAlign: 'center'
  },
  columnDescription: {
    flexBasis: "50.6%",
    padding: '1.5% 0px',
    background: '#FFFFFF', 
    textAlign: 'center'
  },
  columnDescriptionStatus: {
    flexBasis: "50.6%",
    padding: '1.5% 0px',
    background: '#FFFFFF', 
    textAlign: 'end'
  },
  columnOneTitle:{
    flexBasis: "33.6%",
    padding: '1.5% 0px',
    background: '#FFFFFF',
  },
  columnEvent: {
    paddingLeft: "20px",
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  titlesTable: {
    fontSize: 12, 
    display: 'flex', 
    color: '#8E8E8E', 
    width: '100%', 
    marginLeft: '1%', 
    position:'relative', 
    marginBottom: -8
  },
  header: {
    color: "#8E8E8E",
    fontSize: 14,
  },
  wrapper: {
    color: "#8E8E8E",
    fontSize: 14,
    fontWeight: 700,
  },
  table: {
      "& MuiTable-root": {
        backgroundColor: 'inherit'
      },
    "& .MuiTableCell-root": {
      backgroundColor: 'inherit',
      borderBottom: 'inherit'
    },
    "& .MuiTableCell-head": {
      paddingBottom: 0,
    },
    "& .MuiTableBody-root": {
      backgroundColor: 'white',
      borderRadius: '50rem !important'

    },
  },
}));

export default function DetailedAccordion({
  setMessage,
  message,
  data,
  setScroll,
}) {
  const classes = useStyles();
  const id = IdKey.generate();
  const history = useHistory();
  const [, dispatch] = useStateValue();
  const [openEdit, setOpenEdit] = useState(false);
  const [dataCompetencie, setDataCompetencie] = useState({})
  const [messageDelete, ] = useState({
    open: false,
    type: '',
    description: '',
    handleClickOut: () => {},
    handleClick: () => {}
  })

  
  const closeEdit = () => setOpenEdit(false)

  


  const changePage = (x) => {
    dispatch({
      type: "SEND_DATA_TO_PAGE",
      data: x
    });
    history.push(`${PATH_APP}/competencies/detail`)
  }

if(data?.length) {
  return (
    <div className={classes.root}>
      <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"30%"} className={classes.wrapper}>
                  Código
                </TableCell>
                <TableCell align="left" width={"33%"} className={classes.wrapper}>
                  Criterio
                </TableCell>
                <TableCell align="left" width={"13%"} className={classes.wrapper}>
                  Estado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, idColumn = id) => (
                <TableRow key={idColumn}>
                  <TableCell align="left" width={"30%"} className={classes.header}
                    style={{
                      borderTopLeftRadius: idColumn === 0 ? 10 : 0,
                      borderBottomLeftRadius: idColumn === row?.length - 1 ? 10 : 0
                    }}>
                    {row?.code}
                  </TableCell>
                  <TableCell align="left" width={"33%"} className={classes.header}>
                    {row?.name}                  </TableCell>
                  <TableCell align="left" width={"13%"} className={classes.header}>
                    {row?.status === 'ACTIVE' ? 'Activo' : 'Inactivo'}
                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}
                    style={{
                      borderTopRightRadius: idColumn === 0 ? 10 : 0,
                      borderBottomRightRadius: idColumn === row?.length - 1 ? 10 : 0
                    }} >
                    <Tooltip title="Ver detalle" arrow >
                      <VisibilityIcon
                        // onClick={() => {setOpenEdit(true); setOpenModal(!openModal); }}
                        onClick={() => changePage(row)}
                        style={{ marginLeft: '55%' }}
                        className={classes.iconEvent}
                      />
                    </Tooltip>
                    &nbsp;&nbsp;
                    <Tooltip title="Editar" arrow >
                      <EditIcon
                        // onClick={() => {setOpenEdit(true); setOpenModal(!openModal); }}
                        onClick={() => { setOpenEdit(true); setDataCompetencie(row) }}
                        className={classes.iconEvent}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
           <ModalDelete 
            open={messageDelete.open}
            type={messageDelete.type}
            description={messageDelete.description}
            handleClick={messageDelete.handleClick}
            handleClickOut={messageDelete.handleClickOut}
          />
          <EditCompetencie 
            setMessage={setMessage}
            message={message}
            closeEdit={closeEdit}
            open={openEdit}
            data={dataCompetencie}

          />
    </div>
  );
      }else {
        return null;
      }
}
