export const initialState = {
    filter: {
        nameKeyword: null,
        idKeyword: null,
        positionName: null,
        regionalName: null,
        evaluatorType: 'TEACHER',
        page: 0,
        size: 10
    },
    isFilter: false,
    typeAction: null
  };
  
  const OrganizationChartSearchReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_FILTER_ORGANIZATION_CHART":
        return {
          ...state,
          filter: action.filter,
          isFilter: action.isFilter,
          typeAction: action.typeAction,
        };
      default:
        return state;
    }
  };
  
  export default OrganizationChartSearchReducer;
  