import React, {useState, useEffect} from 'react'
import { 
    FormControl, 
    Grid, 
    InputLabel, 
    OutlinedInput, 
    InputAdornment, 
    IconButton,
    Typography,
    Container,
    FormHelperText,
    TextField
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '../../../../utils/snackbars/SnackBars';
import { useStateValue } from "../../../../context/store";
import { PATH_APP, REQUIRED_MESSAGE } from '../../../../constants';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FieldAssess from './assessRanking';
import FieldAnswer from './answerRanking';
import { createQuestion, updateQuestion } from '../../../../actions/question-action';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { styles } from './styles';
import {QuestionQuestionary} from "./questionQuestionary"
import ModalPreview from '../../preview';
import * as yup from "yup";

const Index = (props) => {
    const classes = styles();
    const [{ questionSearch }, dispatch] = useStateValue();
    const [optionsEdit, setOptionsEdit] = useState([])
    const [historyOptions, setHistoryOptions] = useState([])
    const [initialOptions, ] = useState([1,2])
    const [valuesOptions, setValuesOptions ] = useState([
      { name: 'valueOption1', value: 1},
      { name: 'valueOption2', value: 2},
    ])
    const [characters, updateCharacters] = useState();
    const [messageSnackbar, ] = useState({
      message: '',
      open: false,
      severity: '',
        handleClose: () => {}
      })
    const [values, setValues] = React.useState([
        { name: 'numberOption1', value: 0},
        { name: 'numberOption2', value: 0},
    ])

    useEffect(() => {
      if(props.oneSubmit){
        return dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Gestionar preguntas",
          index: 3,
          idForm: 'formInitial7',
          path: `${PATH_APP}/create-question`,
          textIsSubmit: "CREAR PREGUNTA",
        });
        
      }if(props?.isAssess || props?.isAnswer || props?.showDetail || props?.isEdit){
        return null
      }
      else if(props.isQuestionary){
        return dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Crear cuestionario",
          index: 4,
          isSubmit: true,
          subPath: `${PATH_APP}/questionary`,
          textIsSubmit: "GUARDAR",
          idForm: 'Newary'
        }); 
      }
      else {
        submitNotDisabled();
      }
      }, [])

      useEffect(() => {
        if(props?.mapQuestionQuestionary?.answerAlternativeCode === "RAN" ){
          const newData = [];
          for (let index = 0; index < props?.mapQuestionQuestionary?.questionAlternativeList.length; index++) {
            
            newData.push( { name: `valueOption${index +1}`, value: index +1})
       
            for (let index = 0; index < newData.length; index++) {
             setValue(`valueOption${index +1}`, props?.mapQuestionQuestionary?.questionAlternativeList[index]?.key)
            }
          }
         setValuesOptions(newData)
        }
        if(props?.dataQuestion?.answerAlternativeCode === 'RAN'){
          const newData = [];
          const history = []
          for (let index = 0; index < props?.dataQuestion?.questionAlternativeList.length; index++) {
            
            newData.push( { name: `valueOption${index +1}`, value: index +1, uuid:props?.dataQuestion?.questionAlternativeList[index]?.uuid, operationType: null})
            history.push( { name: `valueOption${index +1}`, value: index +1, uuid:props?.dataQuestion?.questionAlternativeList[index]?.uuid, operationType: null})

       
            for (let index = 0; index < newData.length; index++) {
             setValue(`valueOption${index +1}`, props?.dataQuestion?.questionAlternativeList[index]?.key)
            }
          }
          setValuesOptions(newData)
          setOptionsEdit(history)
          setHistoryOptions(history)
        }
       }, [])


       useEffect(() => {
        updateCharacters(valuesOptions)
       }, [valuesOptions])
      const submitNotDisabled = () => {
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Crear pregunta",
          index: 3,
          subPath: `${PATH_APP}/questions`,
          isSubmit: true,
          idForm: props.getDataCreate ? 'prueba' :'formInitial7',
          disabledSubmit: false,
        });
      }

      const disabledSubmit = () => {
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Crear pregunta",
          index: 3,
          subPath: `${PATH_APP}/questions`,
          isSubmit: true,
          idForm: 'formInitial7',
          disabledSubmit: true,
        });
      }

      const handleCloseModalUpdate = () => {
        submitNotDisabled()
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Gestionar preguntas",
          index: 3,
          idForm: 'formInitial7', 
          path: `${PATH_APP}/create-question`,
          textIsSubmit: "CREAR PREGUNTA",
        });
        closeModal()
      }

      useEffect(() => {
       setValue('numberOption1', 1)
       setValue('numberOption2', 2)

      }, [])

      const findNameField = (name) => {
        const find = valuesOptions?.find((x)=> x.name === name)
        return find?.name === name
    }
    const finValueField = (name) => {
        const find = values?.find((x)=> x.name === name)
        return find?.name === name
    }

      const schema = yup.object().shape({
        valueOption1: findNameField('valueOption1') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(100, 'Ingrese máximo 100 caracteres') :  null,
        valueOption2: findNameField('valueOption2') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(100, 'Ingrese máximo 100 caracteres') :  null,
        valueOption3: findNameField('valueOption3') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(100, 'Ingrese máximo 100 caracteres') :  null,
        valueOption4: findNameField('valueOption4') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(100, 'Ingrese máximo 100 caracteres') :  null,
        valueOption5: findNameField('valueOption5') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(100, 'Ingrese máximo 100 caracteres') :  null,
        valueOption6: findNameField('valueOption6') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(100, 'Ingrese máximo 100 caracteres') :  null,
        valueOption7: findNameField('valueOption7') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(100, 'Ingrese máximo 100 caracteres') :  null,
        valueOption8: findNameField('valueOption8') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(100, 'Ingrese máximo 100 caracteres') :  null,
        valueOption9: findNameField('valueOption9') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(100, 'Ingrese máximo 100 caracteres') :  null,
        valueOption10: findNameField('valueOption10') ? yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(100, 'Ingrese máximo 100 caracteres') :  null,
        numberOption1: finValueField('numberOption1') ? yup.number().nullable().required().default(1).min(1) : null,
        numberOption2: finValueField('numberOption2') ? yup.number().nullable().required().default(2) : null,
        numberOption3: finValueField('numberOption3') ? yup.number().nullable().required().default(3) : null,
        numberOption4: finValueField('numberOption4') ? yup.number().nullable().required().default(4) : null,
        numberOption5:   finValueField('numberOption5') ? yup.number().nullable().required().default(5) : null,
        numberOption6: finValueField('numberOption6') ? yup.number().nullable().required().default(6) : null,
        numberOption7: finValueField('numberOption7') ? yup.number().nullable().required().default(7) : null,
        numberOption8: finValueField('numberOption8') ? yup.number().nullable().required().default(8) : null,
        numberOption9: finValueField('numberOption9') ? yup.number().nullable().required().default(9) : null,
        numberOption10: finValueField('numberOption10') ? yup.number().nullable().required().default(10) : null,                                                                
      });
      
      const { register, handleSubmit, control, unregister, formState:{errors},  setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });
    
  
      const addOption = () => {
        if(valuesOptions.length === 10){
          return null
        } else {
          return <IconButton
                style={{marginTop:  15}}
                aria-label="toggle password visibility"
                onClick={()=> {
                        setValue(`valueOption${valuesOptions.length + 1}`, '')
                        setValue(`numberOption${valuesOptions.length + 1}`, `${valuesOptions.length + 1}`)
                        setValuesOptions([...valuesOptions, {name: `valueOption${valuesOptions.length + 1}`, value: valuesOptions.length +1,  operationType: 'CREATE'}])
                        setValues([...values, {name: `numberOption${initialOptions.length + 1}`, value: 0}])
                        setHistoryOptions([...historyOptions, { name: `valueOption${historyOptions?.length +1 }`, value: historyOptions?.length +1,  operationType: 'CREATE'}])

                        // setInitialOptions( [...initialOptions,newArray +1])
                }
            }
                edge="end"
                >
                {<AddCircleIcon color="success"/>}
                </IconButton>
        }
        
      }
        
      const boxForValue = (x) => {
        return <div>
          <Grid item xs={4} md={8} lg={valuesOptions.length === 10? 5 : 5} style={{margin: '0px 15px'}}>
          <FormControl >
          <Controller 
            control={control}
            render={({field, fieldState})=> (
              <TextField
              {...register(`numberOption${x}`)}
              inputRef={field.ref}
              disabled
              type="number"
              className={classes.dropdownMultipleRanking}
              fullWidth
              defaultValue=""
              value={x}
              size="small"
              inputProps={{maxlength: initialOptions.length === 10 ? 2 : 1}}

              // label="Pregunta"
              error={!!errors.hasOwnProperty(`numberOption${x}`) && errors[`numberOption${x}`].message}
              helperText={!!errors.hasOwnProperty(`numberOption${x}`) && errors[`numberOption${x}`].message}
            />
            )}
            />
          </FormControl> 
          </Grid>
      </div>
      }

      const clear = () => {
        for (let index = 0; index < valuesOptions.length; index++) {
          setValue(`valueOption${index +1}`, '')
         }
      }
      const closeModal = () =>  props.setMessage({...props.message, open:false})

      const handleCloseModal = () => {
        closeModal()
        submitNotDisabled()
      }

      const handleResetForm = (data) => {
        handleCloseModal();
        props.clearFields()
        clear()
        if(props?.getDataCreate )props?.getDataCreate(data)
      }
      const handleCloseModalAndReset = () => {
        closeModal();
        props.setOpenModal()
        resetList()
      }

      const resetList = () => {
        dispatch({
        type: "SET_FILTER_QUESTION",
        filter: questionSearch?.filter,
        isFilter: true,
        typeAction: 'EDIT'
      })
     } 
      

        const onSubmit = (data) => {

          const dataRegister = []
          const currentData = []
          if(props?.dataQuestion){
            for (let index = 0; index < props?.dataQuestion?.questionAlternativeList.length; index++) {
      
             const result = optionsEdit.find((x) => x?.uuid === props?.dataQuestion?.questionAlternativeList[index]?.uuid)
           
              if(result){
                  if(result?.operationType === null ){
                    const filterFieldId = valuesOptions?.find((x) => x.uuid === result?.uuid)
                    const filterInProps = props?.dataQuestion?.questionAlternativeList.find((x) => x.uuid === result?.uuid)
                    if(watch(filterFieldId?.name) === filterInProps?.key) {
                      currentData.push({
                        key:  watch(filterFieldId?.name),
                        uuid: result?.uuid,
                        value: 0,
                        operationType: null
                      }) 
                    }else {
                      currentData.push({
                        key:  watch(filterFieldId?.name),
                        uuid: result?.uuid,
                        value: 0,
                        operationType: 'UPDATE'
                      })
                       continue
                    }
                  } else {
                    currentData.push(result)
                    continue
                  }          
              } else {
                continue
              }
          }
        } 

        const lengthUuid = valuesOptions.filter((x)=> x?.uuid)

        if( !props?.dataInitial?.tagUuid || !props?.dataInitial?.question || (props?.errorsHeaderQuestion?.question || props?.errorsHeaderQuestion?.label)){
          return props?.valuesForm()
        } else{
            const questionAlternativeRequest = [];
            
            for (let index = props?.dataQuestion ? lengthUuid.length : 0; index < valuesOptions.length; index++) {
          
              questionAlternativeRequest.push(data[`valueOption${index +1}`]) 
            }
            for (let index = 0; index < (valuesOptions.length -lengthUuid.length); index++) {
              dataRegister.push( props?.dataQuestion ?
                {key: questionAlternativeRequest[index], value: 0, operationType: 'CREATE'}
                : {key: questionAlternativeRequest[index], value: 0}
                ) 
            }

            const newDataEdit = currentData.concat(dataRegister)
             
            const createQuestions = async() => {
              disabledSubmit()
              const newData = {...props?.dataInitial, questionAlternativeRequest: dataRegister}
              const result = await createQuestion(newData, dispatch)
              if (result && (result?.status >= 200 && result?.status <= 201)) {
                
                props.setMessage({
                  open : true,
                  type : 'SUCCESS',
                  oneButtons : true,
                  title: '¡Proceso exitoso!',
                  textButtonSubmit: 'ACEPTAR',
                  handleClick : () => handleResetForm(result?.data) ,
                  description: 'Información almacenada correctamente ',
                })
              }
              else if (result?.status > 399) {
                props.setMessage({
                  open : true,
                  description: 'Error al guardar la información',
                  handleClick : () => handleCloseModal() ,
                  oneButtons : true,
                  type : 'WARNING',
                })
              } else {
                props.setMessage({
                  open : true,
                  description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
                  handleClick : () => handleCloseModal() ,
                  oneButtons : true,
                  type : 'WARNING',
                })
              }
           }
           const updateQuestions = async() => {
            const newData = {...props?.dataInitial, questionAlternativeRequest: newDataEdit}
            const result = await  updateQuestion(newData, props?.dataQuestion?.uuid) 
            if (result?.status >= 200 && result?.status <= 204) {
                props.setMessage({
                  open : true,
                  type : 'SUCCESS',
                  oneButtons : true,
                  title: '¡Proceso exitoso!',
                  textButtonSubmit: 'ACEPTAR',
                  handleClick : () => handleCloseModalAndReset() ,
                  description: 'Información almacenada correctamente ',
                })
              }else if (result?.status === 400) {
                props.setMessage({
                  open : true,
                  description: "La pregunta no se puede modificar porque está asociada a un cuestionario",
                  handleClick : () => handleCloseModalUpdate() ,
                  oneButtons : true,
                  type : 'WARNING',
                })
              }
              else if (result?.status > 399) {
                props.setMessage({
                  open : true,
                  description: 'Error al guardar la información',
                  handleClick : () => handleCloseModalUpdate() ,
                  oneButtons : true,
                  type : 'WARNING',
                })
              } else {
                props.setMessage({
                  open : true,
                  description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
                  handleClick : () => handleCloseModalUpdate() ,
                  oneButtons : true,
                  type : 'WARNING',
                })
              }
           }
           props.dataQuestion ? updateQuestions() : createQuestions()
        }
        } 


    const handleClickShowPassword = (e, number, info ) => {

      if(valuesOptions.length === 2) {
        return null
      } else {
      unregister(e)
      unregister(`numberOption${number}`)
    
      const valuesOptionsnew = []
      const result = valuesOptions.filter((x)=> x.name !== e)
      const findOptionEdit = optionsEdit.find((x)=> x.uuid === info?.uuid)
      const filterOptionEdit = optionsEdit.filter((x)=> x.uuid !== info?.uuid)
      if(info?.uuid){
        const filterDelete = historyOptions?.filter((x) => x.uuid === info?.uuid )
        const updateOption = 
          {
            value: 0, 
            uuid: findOptionEdit?.uuid,
            operationType: 'DELETE'
          }
          setOptionsEdit([...filterOptionEdit, updateOption])
          setHistoryOptions(filterDelete)

      }
      for (let index = 0; index < result.length ; index++) {
       
        const item = result[index]
        const currentValue = watch(item?.name)
        valuesOptionsnew.push(currentValue)
      }
      const newValues = [];
      const valuesChangeOptions = valuesOptionsnew
      
      for (let index = 0; index < valuesChangeOptions.length; index++) {
        setValue(`valueOption${index+1}`, valuesChangeOptions[index])
        newValues.push({name: `valueOption${index+1}`, value: index+1, uuid: result[index]?.uuid ? result[index]?.uuid : null, operationType: result[index]?.operationType })
      }
      unregister(`valueOption${valuesOptions.length}`)
      unregister(`numberOption${valuesOptions.length}`)
      setValuesOptions(newValues)
      setHistoryOptions(result)
    }
  }

 
    
      const inputOption = (x) => {
        return <>
       
            <Grid item md={8} lg={props.isQuestionary|| props?.showDetail || props?.isEdit? 8 :8.5}  style={{display:'flex'}}>
            <Grid item md={2} lg={3} style={{ marginRight: '-50px', display: 'flex',}}>{boxForValue(x.value)}</Grid>
            <ErrorOutlineIcon color="error"  style={{visibility: errors[`valueOption${x.value}`] ? "initial": "hidden"}} fontSize="small" className={classes.iconErrorfieldSmall}/> 
                <FormControl fullWidth size="small" style={{ marginLeft: 15}}>
                    <InputLabel htmlFor="outlined-adornment-password" 
                        error={!!errors.hasOwnProperty(`valueOption${x.value}`) && errors[`valueOption${x.value}`].message}
                    >
                        {`Opción ${x.value}`} 
                    </InputLabel>
                    <OutlinedInput
                        disabled={props?.isQuestionary|| props?.showDetail|| props?.isEdit}
                        {...register(`valueOption${x.value}`)}
                        className={classes.dropdownMultiple}
                        
                        endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            disabled={props?.isQuestionary|| props?.showDetail || props?.isEdit}
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword(`valueOption${x.value}`, x.value, x)}
                            edge="end"
                            >
                            {<DeleteIcon/>}
                        </IconButton>
                        </InputAdornment>
                        }
                        label={`Opción ${x.value}`}
                        error={!!errors.hasOwnProperty(`valueOption${x.value}`) && errors[`valueOption${x.value}`].message}
                        helperText={!!errors.hasOwnProperty(`valueOption${x.value}`) && errors[`valueOption${x.value}`].message}
                    />
                        <FormHelperText style={{color:'#d32f2f'}}>{errors.hasOwnProperty(`valueOption${x.value}`) &&
                            errors[`valueOption${x.value}`].message}
                        </FormHelperText> 
                </FormControl>
            </Grid>
            <br/>
        </>
    }


    const handleClosePreview = () => props?.closePreview()
   
    const inputsPreview = () => {

      
      function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updateCharacters(items);
      }


    return <Grid container spacing={1}  maxWidth="lg" justifyContent="center" alignItems="center" style={{ margin: 'auto' }} >
      <Grid item lg={10}>
         <FormControl variant='outlined' fullWidth size="small">
           <TextField
           disabled={true}
           className={classes.dropdown}
           fullWidth
           value={props?.dataInitial?.question}
           size="small"
         />
         </FormControl>
       </Grid>
       <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters" >
            {(provided) => (
              <ul className="characters" {...provided.droppableProps} ref={provided.innerRef} style={{flex: 'auto', marginLeft: '2%'}}>
                {characters.map(({name, value}, index, idRank) => {
                  return (
                    <Draggable key={name} draggableId={name} index={index} >
                      {(provided) => (
                      
                         <Container >
                          <div  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} 
                            fullWidth
                            value={watch(name)}
                            disabled
                           
                          >
                            <div style={{display: 'flex', alignItems: 'center'}}>
                            <p>{index+1}</p>
                             <Typography  style={{padding: '10px 50px'}}>{watch(name)}</Typography >
                            </div>
                          </div>
                        </Container>
                       
                       
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
     </Grid>
   
   }

   if(props?.isAssess){
    return <FieldAssess 
             isDetail={props?.isDetail}
             valueQuestion={props?.mapQuestionAssess?.valueApplied}
             valuesOptions={props?.mapQuestionAssess?.questionAlternativeList}
             question={props?.mapQuestionAssess?.question}
             uuid={props?.mapQuestionAssess?.uuid}
           />
   } else if(props?.isAnswer){
            return <FieldAnswer 
            position={props?.index}
            isDetail={props?.isDetail}
            valueQuestion={props?.mapQuestionAssess?.valueApplied}
            valuesOptions={props?.mapQuestionAssess?.questionAlternativeList}
            question={props?.mapQuestionAssess?.question}
            uuid={props?.mapQuestionAssess?.uuid}
          />
   } else if (props?.isQuestionary){
    return <QuestionQuestionary  
      mapQuestionQuestionary={props?.mapQuestionQuestionary}
      dataQuestion={props?.dataQuestion}
    />
  }else {
    return (
      <>
      {props?.preview ? 
      < ModalPreview  
        open={props?.preview} 
        children={inputsPreview()}
        handleClose={handleClosePreview}
      /> 
            :  null
      }
        <Container >
            <form id={props.getDataCreate ? 'prueba':"formInitial7"} onSubmit={handleSubmit(onSubmit)} style={{marginTop: 37, marginLeft: '1%'}}>
            <Grid container spacing={2} maxWidth='md' style={{margin: '0px 30px 0px -40px', justifyContent: 'flex-start'}} >
                {valuesOptions.map((x) =>(
                inputOption(x)
                ))} 
            <Grid sm={1} md={1} lg={1}>
                {props?.isQuestionary|| props?.showDetail || props?.isEdit? null : addOption()}
            </Grid>
            </Grid>  
            </form>
            < Snackbar 
                severity={messageSnackbar.severity} 
                message={messageSnackbar.message} 
                open={messageSnackbar.open}
                handleClose={messageSnackbar.handleClose}
            />
        </Container>
    </>
    )}
}

export default Index
