import React, { useState } from "react";
import * as IdKey from "short-uuid";
import {
  AccordionSummary,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableHead,
  TableContainer, 
  Paper,
  TableCell, 
  TableRow,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { withStyles, makeStyles } from "@mui/styles";
import ModalDelete from '../../utils/form/modalConfirmBase'
import EditLabel from './editLabel';
import DetailLabel from './detailLabel';

const useStyles = makeStyles((theme) => ({
  MuiIconButtonEdgeEnd: {
    order: "-1",
  },
  root: {
    [theme.breakpoints.down('md')]: {
        flex: "1 1 auto",
        margin: "25% 2% 0% 2%",
        minHeight: "80vh",
        background: '#FFFFFF',
        borderRadius: 10
      },
      [theme.breakpoints.up('md')]: {
        width: "66%",
        background: '#FFFFFF',
        borderRadius: 10

      },
      [theme.breakpoints.up('lg')]: {
        // width: "67%",
        margin: '10px 0% auto 0%',
        background: '#FFFFFF',
        borderRadius: 10

      },
  },
  rootGrid: {
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    color: '#8E8E8E'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  iconEvent: {
    color: "#646464",
    cursor: "pointer",
    height: 18,
    width: 18,
    pointerEvents: 'auto',
    '&:hover':{
      color: "#2F71CB",
    }
  },
  details: {
    alignItems: "center",
    backgroundColor: "#EEFBFF",
    pointerEvents: 'auto'
  },
  column: {
    flexBasis: "39.6%",
    padding: '1.5% 0px',
    background: '#FFFFFF', 
    textAlign: 'center'
  },
  columnOneTitle:{
    flexBasis: "39.6%",
    padding: '1% 0px',
    background: '#FFFFFF',
    
  },
  columnEvent: {
    paddingLeft: "20px",
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  titlesTable: {
    fontSize: 12, 
    display: 'flex', 
    color: '#8E8E8E', 
    width: '100%', 
    marginLeft: '1%', 
    position:'relative', 
    marginBottom: -18
  },
  header: {
    color: "#8E8E8E",
    fontSize: 14,
  },
  wrapper: {
    color: "#8E8E8E",
    fontSize: 14,
    fontWeight: 700,
  },
  table: {
      "& MuiTable-root": {
        backgroundColor: 'inherit'
      },
    "& .MuiTableCell-root": {
      backgroundColor: 'inherit',
      borderBottom: 'inherit'
    },
    "& .MuiTableCell-head": {
      paddingBottom: 0,
    },
    "& .MuiTableBody-root": {
      backgroundColor: 'white',
      borderRadius: '50rem !important'

    },
  },
}));

export default function DetailedAccordion({
  listData,
  message,
  setMessage,
  handleCloseSnackbar,
}) {
  const classes = useStyles();
  const id = IdKey.generate();
  const [idField, setIdField] = useState([])
  const [openEdit, setOpenEdit] = useState(true);
  const [messageDelete, ] = useState({
    open: false,
    handleClose: () => {}
  })
  const [messageDetail, setMessageDetail] = useState({
    open: false,
    data: {},
  })

  const handleCloseDetail = () => setMessageDetail({...messageDetail, open:false})

  const FlippedOrderExpansionPanelSummary = withStyles({
    root: {
      flexDirection: "row-reverse",
    },
  })(AccordionSummary);
  FlippedOrderExpansionPanelSummary.defaultProps = {
    IconButtonProps: { edge: "start" },
  };
  
  
  const validateOpen = (index) => idField.includes(index)
  const closeDelete = (num) => {
    const result = idField.filter((x) => x !== num)
    setIdField(result)
    setOpenEdit(!openEdit)
  }
  
  const closeEdit = () => {validateOpen(); }


  if (listData.length) {
    return (
    <React.Fragment>
    <Paper elevation={0} style={{ background: 'inherit' }}>
      <TableContainer >
        <Table sx={{ maxWidth: 860 }} stickyHeader aria-label="caption table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Código
              </TableCell>
              <TableCell align="left" width={"40%"} className={classes.wrapper}>
                  Etiqueta
              </TableCell>
              <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Estado
              </TableCell>
              <TableCell align="left" width={"20%"} className={classes.wrapper}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listData?.map((data, idColumn = id) => (
              <React.Fragment   key={idColumn}>
              {idField?.includes(idColumn) ?
                <TableRow key={idColumn}>
                  <TableCell colSpan={4}  style={{
                        borderTopLeftRadius: idColumn === 0 ? 10 : 0,
                        borderTopRightRadius: idColumn === 0 ? 10 : 0,
                        borderBottomLeftRadius: idColumn === (data?.length - 1) ? 10 : 0
                        }}
                  >
                    <EditLabel 
                        data={data}
                        index={idColumn}
                        message={message}
                        closeEdit={closeEdit}
                        setIdField={setIdField}
                        setMessage={setMessage}
                        closeDelete={closeDelete}
                        handleCloseSnackbar={handleCloseSnackbar}
                    />
                  </TableCell>
                </TableRow>
                   :
                  <TableRow key={idColumn}>
                    <TableCell align="left" width={"20%"} className={classes.header}
                        style={{
                        borderTopLeftRadius: idColumn === 0 ? 10 : 0,
                        borderBottomLeftRadius: idColumn === (listData?.length - 1) ? 10 : 0
                        }}>
                        <Typography className={classes.heading} style={{marginLeft: '5%'}}>
                        {data?.code}
                        </Typography>

                    </TableCell>
                    <TableCell align="left" width={"40%"} className={classes.header}>
                        <Typography className={classes.heading} >
                            {data?.name}
                        </Typography>                
                    </TableCell>
                    <TableCell align="left" width={"20%"} className={classes.header}>
                        <Typography className={classes.heading} >
                            {data?.status === "ACTIVE" ? "Activo" : "Inactivo"}
                        </Typography>                
                    </TableCell>
                    <TableCell align="left" width={"20%"} className={classes.header}
                        style={{
                        borderTopRightRadius: idColumn === 0 ? 10 : 0,
                        borderBottomRightRadius: idColumn === (listData?.length - 1) ? 10 : 0
                        }}  >
                        <div className={classes.columnEvent} >
                        &nbsp;&nbsp;
                        <Tooltip title="Editar" arrow >
                            <EditIcon
                                onClick={() => setIdField( [idColumn])}
                                className={classes.iconEvent}
                            />
                            </Tooltip>
                        &nbsp;&nbsp; 
                        </div>
                    </TableCell>
                  </TableRow>
              }
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Paper>
      <ModalDelete 
            open={messageDelete.open}
            type={messageDelete.type}
            description={messageDelete.description}
            handleClick={messageDelete.handleClick}
            handleClickOut={messageDelete.handleClickOut}
          />
      <DetailLabel 
         open={messageDetail.open}
         data={messageDetail.data}
         handleCloseDetail={handleCloseDetail}
      />
      </React.Fragment>
  )
      } else return null
}
