import React, { useEffect, useState } from 'react'
import { useStateValue } from "../../context/store";
import DetailedAccordion from './tableLabel';
import ModalCreate from './modalCreate';
import SearchLabel from './search';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import ModalErrorAction from "../../components/common";
import { getTags, getTagsCode } from '../../actions/label-actions';
import ModalConfirm from "../../utils/form/modalConfirmBase";

const Index = () => {
  const [{ labelSearch, modalClose, loadingTables }, dispatch] = useStateValue();
  const [codeMajor, setCodeMajor] = useState(0)
  const [clearFielForm, setClearFielForm]= useState(false)
  const [duplicateData, ] = useState([])
  const [data, ] = useState({
    code : null,
    description: null,
    name: null,
    status: null
  })
  const [paginadorResponse, setPaginadorResponse] = useState({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  const [paginadorRequest, setPaginadorRequest] = useState({
    page: 0,
    size: 10,
  });
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
})

  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      openModal: true,
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar etiquetas",
      index: 2,
      textIsSubmit: "CREAR ETIQUETA",
    });
    getAllLabels()
    
    window.scrollTo(0,0)
  }, [paginadorRequest])

  useEffect(() => {
    LabelsLength()
  }, [modalClose?.openCreateTag])

  const resetData = () => {
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    })
  }
const closeModalAndClear = () => setClearFielForm(!clearFielForm)
const dataFilter = () => getAllLabels();
const closeModal = () => {setMessage({...message, open:false}); closeModalAndClear(); resetData()}


  const getAllLabels = async() => {

    dispatch({
      type: "SET_FILTER_LABEL",
      filter: {
          code: labelSearch?.filter?.code,
          description: labelSearch?.filter?.description,
          status: labelSearch?.filter?.status,
          name: labelSearch?.filter?.name,
          page: paginadorRequest?.page,
          size: paginadorRequest?.size
        
        },
        isFilter: false,
    })
    const result = await getTags({
        code : data.code === null ? null : data?.code,
        name: data.name === null ? null : data?.name,
        status: data.status === null ? null : data?.status,
        description: data.description === null ? null : data?.description,
        page: paginadorRequest?.page === null ? null : paginadorRequest?.page,
        size:  paginadorRequest?.size === null ? null : paginadorRequest?.size,
        
    },
    {orderBy: 'createdAt'},
    dispatch
    );

    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
   if(result?.status === 204 && !labelSearch?.typeAction){

    return setMessage({
      open : true,
      description: 'No se encontró información relacionada a la búsqueda',
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
   }
   if(result?.data?.content?.length < 1 && !labelSearch?.typeAction){

    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
    return setMessage({
      open : true,
      description: 'No se encontró información relacionada a la búsqueda',
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
   }
  if ((result && result.status === 200) || result.status === 201) {

    const data = result?.data;
    if (data?.content?.length > 0) {
      setPaginadorResponse({
        data: data?.content,
        totalRows: data?.totalElements,
        pageSize: data?.size,
        pageNumber: data?.number,
      });
    }
    
  } else {
    setMessage({
      open : true,
      description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
  }
  }

  const LabelsLength = async() => {
    const result = await getTagsCode()
      setCodeMajor(result?.data?.code ? result?.data?.code :0 )

  }


  if(labelSearch &&
    labelSearch?.isFilter) {
  
      const newData = labelSearch?.filter
  
      data.code = newData?.code;
      data.description = newData?.description;
      data.name = newData?.name
      data.status = newData?.status;
      paginadorRequest.page = newData?.page;
      paginadorRequest.size = newData?.size
      getAllLabels( )
      dispatch({
        type: "SET_FILTER_LABEL",
        filter: labelSearch?.filter,
        isFilter: false,
      })
  }

  const changePage = (event, nuevaPagina) => {
       
    setPaginadorRequest({
      ...paginadorRequest,
      page: nuevaPagina,
  
     
    });
  };

  const changeAmountOfRecords = (event, siguiente) => {
    setPaginadorRequest({
      ...paginadorRequest,
      size: event.target.value,
      page: 0
    });
  };

    return (
        <div >
            < SearchLabel 
                data={duplicateData}
                dataFilter={dataFilter}
                message={message}
                setMessage={setMessage}
                clearFielForm={clearFielForm}
                />
                {loadingTables?.active ?
                    <Box sx={{ display: 'flex', marginTop:'15%', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                    : 
                    < DetailedAccordion 
                      listData={[...paginadorResponse?.data]}
                      message={message}
                      setMessage={setMessage}
                    />
                }
               {paginadorResponse?.totalRows !== 0 && !loadingTables?.active &&
                  <TablePagination
                      style={{ marginLeft: "12%", display:"flex", color: '#747578' }}
                      component="div"
                      rowsPerPageOptions={[5, 10, 15]}
                      count={paginadorResponse.totalRows}
                      rowsPerPage={paginadorRequest.size}
                      page={paginadorRequest.page}
                      labelRowsPerPage='Registros por página'
                      onPageChange={changePage}
                      onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
                  />
              }
             
              {modalClose?.openCreateTag === true ? 
              <ModalCreate  
                codeInitial={codeMajor +1}
                message={message}
                setMessage={setMessage}
              /> 
              : null}

            <ModalConfirm 
              description = { message.description }
              textButtonSubmit = { message.textButtonSubmit }
              handleClick = { message.handleClick }
              handleClickOut = { message.handleClickOut }
              oneButtons = { message.oneButtons }
              title = { message.title }
              type = { message.type }
              open = { message.open }
            />
            <ModalErrorAction />
        </div>
    )
}

export default Index
