import React,{useState, useEffect} from 'react';
import {
  Container,
  Grid
} from "@mui/material";
import Question from "../../questions/createQuestions"
import { useStateValue } from "../../../context/store";
import { useHistory } from "react-router-dom";
import _ from 'lodash'

const ListQuestions = (props) => {
  const history = useHistory();
  const [{ dataAplication}, dispatch] = useStateValue();
  const [questions, setQuestions] = useState([])
  

  useEffect(()=> {
    if(!props?.isDetail ){
      orderQuestions(dataAplication?.data?.associatedQuestions)
    }else {
      orderQuestions(dataAplication?.data?.questionnaire?.associatedQuestions)
    }
  },[])

  const orderQuestions = (x) => {
    const data = x
    const result = _.orderBy(data, ['position'], ['asc']);
    setQuestions(result)
  }

  if(questions?.length){
    return  <>
    {questions?.map((x, index)=> {
      return <Grid item lg={11} style={{marginTop: '2%'}}>
                <Question 
                    mapQuestionAssess={x} 
                    isAssess={true}
                    isDetail={props?.isDetail}
                />
              </Grid>
    })}
    
  </>
  }else {
    return null
  }
    
 
};

export default ListQuestions;
