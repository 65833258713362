import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../../context/store';
import Header from "./header";
import { getByIdUserAplicationEvaluation } from "../../../actions/ManagementAplicationEvaluations-actions"
import Evaluation from "./evaluation";
import CircularProgress from '@mui/material/CircularProgress';
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import { Box } from '@mui/material';
import { PATH_APP } from '../../../constants';

const Index = (props) => {
    const {aplicationId} = props?.match?.params
    const {userId} = props?.match?.params
   
    const idEvaluation = aplicationId
    const [dataDetail, setDataDetail] = useState({})
    const [{ managementAplicationEvaluation}, dispatch] = useStateValue();
    const [message, setMessage] = useState({
      description : '',
      textButtonSubmit : '',
      handleClick : () => {} ,
      handleClickOut : () => {},
      oneButtons : false,
      title: '',
      list: [],
      type : '',
      open : false
    })

  
    useEffect(()=> {
  
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Detalle aplicación de evaluación",
        index: 13,
        module: 'DETAIL_APLICATION_EVALUATION',
        subPath: `${PATH_APP}/management-aplication-evaluations`,
      }); 
          getEvaluation()
    },[])

    const closeModal = () => {
      dispatch({
        type: "SET_FILTER_MANAGEMENT_APLICATION_EVALUATION",
        filter: managementAplicationEvaluation?.filter,
        isFilter: true,
        typeAction: 'GO_BACK'
      })
      props?.history.push(`${PATH_APP}/management-aplication-evaluations`)
    }
    const getEvaluation = async() => {
       await getByIdUserAplicationEvaluation(aplicationId, userId, dispatch).then((response)=> {
        setDataDetail(response?.data)
      }).catch((error)=> {
        if(error?.status === 400){
          return  setMessage({
            open : true,
            description: 'El usuario aún no ha resuelto la modelo de evaluación',
            handleClick : () => closeModal() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
        }
        else {
          return  setMessage({
            open : true,
            description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
            handleClick : () => closeModal() ,
            oneButtons : true,
            textButtonSubmit: 'ACEPTAR',
            type : 'WARNING',
          })
        }
      })
  }


  return (
    <React.Fragment>
       {!dataDetail?.performanceEvaluation?.competences?.length ?
                <Box sx={{ display: 'flex', marginTop:'15%', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
                :
              <>
                <Header id={idEvaluation} dataDetail={dataDetail} message={message} setMessage={setMessage}/>
                <Evaluation dataDetail={dataDetail}/>
              </>
              
             }
            <ModalConfirm 
              description = { message.description }
              textButtonSubmit = { message.textButtonSubmit }
              handleClick = { message.handleClick }
              handleClickOut = { message.handleClickOut }
              oneButtons = { message.oneButtons }
              title = { message.title }
              list = {message.list}
              type = { message.type }
              open = { message.open }
            />
      
    </React.Fragment>
  )
}

export default Index