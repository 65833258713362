import { makeStyles, withStyles } from '@mui/styles';

export const styles = makeStyles({
  dropdown: {
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
      background: '#dfe7fd',
    },
  },
  widthModal: {
    [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
      maxWidth: 1200,
      minWidth: 1000,
      borderRadius: 16
  },
  [`& .css-uhb5lp`] : {
    maxWidth: 1200,
      minWidth: 1000,
      borderRadius: 16
  }
  },
  iconError: {
    marginRight: '1%',
    marginTop: '1%',
    fontSize: 'initial'
  },
  iconErrorCode: {
    marginRight: '4%',
    marginTop: '4%',
    fontSize: 'initial'
  },
  iconErroDescription: {
    marginRight: '1%',
    marginTop: '1%',
    fontSize: 'initial'
  },
  dropdownMultiple: {
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
      background: '#dfe7fd',
    },
  },
  actions: {
    marginTop: '2%',
    display: 'flex',
    justifyContent: 'flex-start !important' 
  },
})


