import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField,  
     FormControl, 
     FormHelperText, 
     Button,
     Typography
} from '@mui/material'
import {styles} from './styles';
import { useStateValue } from "../../../context/store";
import { useForm, } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Index = (props) => {

    const classes = styles()
    const [{managementAplicationEvaluation, breadCrumb}, dispatch] = useStateValue();
    const [dataForm, setDataForm] = useState({
        label: '',
        keyword: '',
    })
    
    const schema = yup.object().shape({
        keyword: dataForm?.keyword ? yup
        .string()
        .notRequired()
        .nullable(true)
        .transform((value) =>
            encodeURIComponent(value)
        ) 
        .max(100, 'Ingrese máximo 100 caracteres') : null,
        from: yup
        .string()
        .notRequired()
        .nullable(true),
        until: yup
        .string()
        .notRequired()
        .nullable(true), 
      });
    
      
      const { register, handleSubmit, clearErrors, trigger, formState:{errors}, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });

      const handleClose = () => props.setMessage({...props.message, open: false})

      const onSubmit = (data) => {
          if(data.from === '' && data.keyword === ''  && data?.until === ''){
            return  props?.setMessage({
                message: 'Ingrese información en algúno de los campos',
                open: true,
                severity: 'error',
                handleClose: () => handleClose()
              })
          }
          if(watch('until') && watch('from') && (data.until < data?.from ) ){
            return  props?.setMessage({
                open : true,
                description: 'La fecha final no puede ser menor que la fecha inicial',
                handleClick : () => handleClose() ,
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                type : 'WARNING',
              })
          }
          if(
            data?.from !== '' ||
            data?.until !== '' ||
            data?.keyword !== '' 
        ){
            dispatch({
                type: "SET_FILTER_MANAGEMENT_APLICATION_EVALUATION",
                filter: {
                    createdAtStartDate: data?.from,
                    createdAtEndDate: data?.until,
                    nameKeyword: data?.keyword,
                    page: 0,
                    size: managementAplicationEvaluation?.filter?.size ? managementAplicationEvaluation?.filter?.size : 10
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "SET_FILTER_MANAGEMENT_APLICATION_EVALUATION",
                filter: {
                    createdAtStartDate: null,
                    createdAtEndDate: null,
                    nameKeyword: null,
                  },
                  isFilter: true,
            })
        }
      }

      useEffect(() => {
        clear()
      }, [props?.clearFielForm])


      const handleChange = (e) => {
        const {value, name} = e.target;
        setDataForm({...dataForm, [name]:value})
        if(name === 'keyword'){
            setValue(name, value)
            trigger(name)
        }
      }

    const clear = () => {
        setValue('from','')
        setValue('until', '')
        setValue('keyword', '')
        clearErrors('keyword')
        props.clearFilter()
    }

    useEffect(()=> {
        if(breadCrumb?.module === "DETAIL_APLICATION_EVALUATION" && managementAplicationEvaluation?.isFilter){
            setValue('from',managementAplicationEvaluation?.filter?.createdAtStartDate)
            setValue('until', managementAplicationEvaluation?.filter?.createdAtEndDate)
            setValue('keyword', managementAplicationEvaluation?.filter?.nameKeyword)
        }
     
    },[breadCrumb?.module === "DETAIL_APLICATION_EVALUATION"])

      const validate = watch('from') || watch('keyword') ||  watch('until') 
      const disabledButton = errors['keyword'] || !validate

      
    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container spacing={2} style={{alignItems: 'flex-end', marginTop: '-3%'}}>
                <Grid item container spacing={1} lg={4} style={{display:'flex ', flexDirection: 'column' }}>
                    <div >
                        <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Fecha de creación</Typography>
                        <div style={{display:'flex ', flexDirection: 'row' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('from')}
                                    size="small"
                                    type="date"
                                    label="Desde"
                                    className={classes.fieldDateTimeErrorSearch} 
                                    shrink="true"    
                                    autoComplete='off'                           
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={!!errors.hasOwnProperty("from") && errors["from"].message }
                                />
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("from") &&
                                    errors["from"].message}
                                </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                            <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('until')}
                                    size="small"
                                    type="date"
                                    label="Hasta"
                                    className={classes.fieldDateTimeErrorSearch}
                                    notched="true"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={!!errors.hasOwnProperty("until") && errors["until"].message }
                                />
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("until") &&
                                    errors["until"].message}
                                </FormHelperText>
                                </FormControl>
                            </Grid>
                        </div>
                </div>
               
                </Grid>
            
                <Grid item xs={12} sm={12} md={12} lg={5.8}>
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('keyword')}
                    className={classes.dropdownNameCuestionary}
                    fullWidth
                    onChange={handleChange}
                    size="small"
                    autoComplete='off'
                    placeholder="Nombre"
                    error={!!errors.hasOwnProperty("keyword") && errors["keyword"].message}
                    helperText={!!errors.hasOwnProperty("keyword") && errors["keyword"].message}
                  />
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} style={{display: 'flex',  alignItems: 'center', marginLeft: '1%', marginBottom: '0.3%'}}>
                    <Button 
                        variant="contained" 
                        type="submit" 
                        disabled={disabledButton}
                        disableElevation 
                        color="primary"
                    >
                        BUSCAR
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button 
                        variant="outlined"  
                        disableElevation 
                        onClick={clear}
                        style={{background: "#ffffff"}}
                    >
                        LIMPIAR
                    </Button>

                </Grid>
            </Grid>
         </form>
    )
}

export default Index
