import React, {useState, useEffect} from 'react';
import {
    Grid,
    FormControl,
    TextField,
    Container,
    Typography
} from "@mui/material";
import { useStateValue } from "../../../../../context/store";
import {styles} from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Index = (props) => {
    
    const classes = styles();
    const [{ valorationQuestionnaire,disabledQuestionary,valorateAplication, dataAplication }, dispatch ] = useStateValue();
    const [press, setPress] = useState({})
    const [valueQuestion, setValueQuestion] = useState()


    const schema = yup.object().shape({
     
      valueTotalQuestion: dataAplication?.data?.associatedQuestions?.length === 1 ? 
      yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,3}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
      .test("maxLenght", "Ingrese un valor entre 1 y 100", val => val <= 100 && val >= 1)
    : 
      yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,2}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
    })

    const { register, trigger, formState:{errors}, setValue, } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });

    const handleOnchange = (e)=> {
      const {name, value} = e.target;
     
      setValue(name, value)
      trigger(name)
      setValueQuestion(value)
    
      const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)

       // agregando nuevo valor aplicado a la pregunta y sus opciones cuando es manual
    
      dispatch({
        type: "SET_MODAL_VALORATE_APLICATION",
        icon:'WARNING',
        data: valorateAplication?.data ? 
        [...filterUuid, 
          {
            uuid: `${props?.uuid}`, 
            questionAlternativeList: [],
            valueApplied: Number(value)
          }
        ] 
          : [{ uuid: `${props?.uuid}`, 
          questionAlternativeList: [],
          valueApplied: Number(value)}],
      })
    }

    
   useEffect(()=> {
        if(valorationQuestionnaire?.typeValoration === 'automatic'){
        dispatch({
          type: "SET_VALUE_QUESTIONNAIRE",
          totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
          typeValoration: 'automatic',
          activeValoration: false
        });
        }else {
          dispatch({
            type: "SET_VALUE_QUESTIONNAIRE",
            totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
            typeValoration: 'manual',
            activeValoration: false
          });
        }
        setValue('valueTotalQuestion', Number((valorationQuestionnaire?.totalValueQuestionnaire)) ? Number((valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
        setValueQuestion(Number((valorationQuestionnaire?.totalValueQuestionnaire)) ? Number((valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
      },[valorationQuestionnaire?.activeValoration ])



    const handleOnclick = (x) => setPress({press: x})

  const checkboxBigLeft = (value) => {
    return   <Container style={{display: 'flex', alignItems: 'center', flexDirection: props?.preview ? 'column' : null }}>
    {props?.preview ? null : <span style={{marginRight: 10, fontSize: 14, color: "#8E8E8E", width: 'max-content'}}>{value}</span>}
  <div className={classes.circulo} name="optionCheckbox1" onClick={() => {
    if(press.press === `optionCheckbox1`){
     return handleOnclick(null)
    }
    handleOnclick("optionCheckbox1")}
  }
    >
    <div  className={ press.press === "optionCheckbox1" ? classes.circulo2 : null}></div>
  </div>
  {/* {props?.preview ? <Typography style={{fontSize: 14, color: 'rgb(142, 142, 142)', wordBreak: 'break-all'}}>{watch(`description1`)}</Typography> : null} */}
  </Container>
  }

  const checkboxBigStandard = (num) => {
    return   <Container style={{display: 'flex', alignItems: 'center', flexDirection: props?.preview ? 'column' : null}}>
  <div className={classes.circuloStand} name={`optionCheckbox${num}`} onClick={() => {
    if(press.press === `optionCheckbox${num}`){
      return handleOnclick(null)
    }
    handleOnclick(`optionCheckbox${num}`)}
  }
  >
    <div  className={ press.press === `optionCheckbox${num}` ? classes.circuloStand2 : null}></div>
  </div>
  {props?.preview ? <Typography style={{fontSize: 14, color: 'rgb(142, 142, 142)', wordBreak: 'break-all'}}></Typography> : null}
  </Container>
  }

  const checkboxsmall = (num) => {
    return   <Container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: props?.preview ? 'column' : null}} maxWidth="sm">
    {/* <span style={{marginRight: 10, fontSize: 14, color: "#8E8E8E"}}>minitmo</span> */}
  <div className={classes.circuloMed} name={`optionCheckbox${num}`} onClick={() => {
    if(press.press === `optionCheckbox${num}`){
      return handleOnclick(null)
    }
    handleOnclick(`optionCheckbox${num}`)}
  }
    >
    <div  className={ press.press=== `optionCheckbox${num}` ? classes.circuloMed2 : null}></div>
  </div>
  {/* {props?.preview ? <Typography style={{fontSize: 14, color: 'rgb(142, 142, 142)', wordBreak: 'break-all'}}></Typography> : null} */}
  </Container>
  }

  const checkboxBigRight = (num, value) => {
    return   <Container style={{display: 'flex', alignItems: 'center', flexDirection: props?.preview ? 'column' : null }}>
    <div className={classes.circulo} name={`optionCheckbox${num}`} onClick={() => {
      if(press.press === `optionCheckbox${num}`){
        return  handleOnclick(null)
      }
      handleOnclick(`optionCheckbox${num}`)} 
    }
    >
    <div  className={ press.press === `optionCheckbox${num}` ? classes.circulo2 : null}></div>
  </div>
    {!props?.preview ? 
    <span style={{marginLeft: 10, fontSize: 14, color: "#8E8E8E", width: 'max-content'}}>{value}</span> 
    : 
    null
    // <Typography style={{fontSize: 14, color: 'rgb(142, 142, 142)', wordBreak: 'break-all'}}>{watch(`description${num}`)}</Typography> 
     } 
  </Container>
  }

  
  const checkbox = () => {
    
    
    if(props?.valuesRanking?.length === 3){
     
      return <Container 
        style={{display: 'flex', margin: 25, width: 600, alignItems: props?.preview ? 'baseline' : null }} >
      {checkboxBigLeft(props?.valuesRanking[0]?.key)}
      {checkboxsmall(2)}
      {checkboxBigRight(3, props?.valuesRanking[2]?.key)}
      </Container >
    }
    if(props?.valuesRanking?.length === 5){
      return <Container 
        style={{display: 'flex', margin: '20px auto', alignItems: props?.preview ? 'baseline' : null}} maxWidth="sm">
      {checkboxBigLeft(props?.valuesRanking[0]?.key)}
      {checkboxBigStandard(2)}
      {checkboxsmall(3)}
      {checkboxBigStandard(4)}
      {checkboxBigRight(5, props?.valuesRanking[4]?.key)}
      </Container >
    }
    if(props?.valuesRanking?.length === 7){
      return <Container 
        style={{display: 'flex', margin: '25px -45px', alignItems: props?.preview ? 'baseline' : null}}>
      {checkboxBigLeft(props?.valuesRanking[0]?.key)}
      {checkboxBigStandard(2)}
      {checkboxBigStandard(3)}
      {checkboxsmall(4)}
      {checkboxBigStandard(5)}
      {checkboxBigStandard(6)}
      {checkboxBigRight(7, props?.valuesRanking[6]?.key)}
      </Container>
    }
    if(props?.valuesRanking?.length === 9){
      return <Container 
        style={{display: 'flex', margin: '25px -45px', marginLeft: props?.preview ? 0 :-40, alignItems: props?.preview ? 'baseline' : null}}>
      {checkboxBigLeft(props?.valuesRanking[0]?.key)}
      {checkboxBigStandard(2)}
      {checkboxBigStandard(3)}
      {checkboxBigStandard(4)}
      {checkboxsmall(5)}
      {checkboxBigStandard(6)}
      {checkboxBigStandard(7)}
      {checkboxBigStandard(8)}
      {checkboxBigRight(9, props?.valuesRanking[8]?.key)}
      </Container>
     
    }
  
  }


    return  <Grid item container lg={12} style={{ display: 'flex' }}>

    <Grid item lg={10}>
        <FormControl variant='outlined' fullWidth size="small">
            <TextField
            disabled={true}
            className={classes.dropdown}
            fullWidth
            multiline
            maxRows={65}
            value={props?.question}
            size="small"
            />
       </FormControl>
    </Grid>
    <Grid item lg={1} style={{display: 'flex', marginLeft: '3%'}}>
        <FormControl variant='outlined' fullWidth size="small">
        <TextField
          {...register('valueTotalQuestion')}
          className={classes.dropdown}
          fullWidth
          size="small"
          placeholder='Valor'
          onChange={(e)=>handleOnchange(e)}
          error={!!errors.hasOwnProperty("valueTotalQuestion") && errors["valueTotalQuestion"].message}
          disabled={valorationQuestionnaire?.totalValueQuestionnaire || props?.isDetail || !valorationQuestionnaire?.typeValoration|| disabledQuestionary?.disabled}
          value={props?.isDetail ? props?.valueQuestion :valueQuestion}
        />
        </FormControl>
    </Grid>
    {checkbox()}
    </Grid>
};

export default Index;
