import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField, 
     InputLabel, 
     FormControl, 
     FormHelperText, 
     MenuItem,
     Button,
     Typography
} from '@mui/material'
import {styles} from './styles';
import { useStateValue } from "../../../context/store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment"

const SearchLabel = (props) => {

    const classes = styles()
    const [{evaluationPerformanceSearch, breadCrumb}, dispatch] = useStateValue();
    const [, setYears] = useState([])
    const [dataForm, setDataForm] = useState({
        keyword: '',
        status: '',
        year: ''
    })
    
    const schema = yup.object().shape({
        keyword: dataForm?.keyword ? yup
        .string()
        .notRequired()
        .nullable(true)
        .max(100, 'Ingrese máximo 100 caracteres') : null,
        from: yup
        .string()
        .notRequired()
        .nullable(true),
        until: yup
        .string()
        .notRequired()
        .nullable(true), 
        status: yup
        .string()
        .notRequired()
        .nullable(true),
        year: yup
        .string()
        .notRequired()
        .nullable(true)
      
      });
    
      const { register, handleSubmit,clearErrors, trigger, formState:{errors}, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });

      
      const handleClose = () => props.setMessage({...props.message, open: false})

      const onSubmit = (data) => {

          if(data.from === '' && data.keyword === '' && data.status === '' && data?.until === '' && dataForm?.year === ''){
            return  props?.setMessage({
                message: 'Ingrese información en algúno de los campos',
                open: true,
                severity: 'error',
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                handleClick : () => handleClose() ,
              })
          }
          if(watch('until') && watch('from') && (data.until < data?.from )){
            return  props?.setMessage({
                open : true,
                description: 'La fecha final no puede ser menor que la fecha inicial',
                handleClick : () => handleClose() ,
                oneButtons : true,
                textButtonSubmit: 'ACEPTAR',
                type : 'WARNING',
              })
          }
          if(
            data?.from !== '' ||
            data?.until !== '' ||
            data?.keyword !== '' ||
            data?.status !== '' ||
            dataForm?.year !== ''
        ){
            dispatch({
                type: "SET_FILTER_EVALUATION_PERFORMANCE",
                filter: {
                    createdAtStartDate: data?.from,
                    createdAtEndDate: data?.until,
                    nameKeyword: data?.keyword,
                    status: data?.status,
                    evaluationYear: dataForm?.year,
                    page: 0,
                    size: evaluationPerformanceSearch?.filter?.size ? evaluationPerformanceSearch?.filter?.size : 10
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "SET_FILTER_EVALUATION_PERFORMANCE",
                filter: {
                    createdAtStartDate: null,
                    createdAtEndDate: null,
                    nameKeyword: null,
                    status: null,
                  },
                  isFilter: true,
            })
        }
      }

      useEffect(() => {
        clear()
      }, [props?.clearFielForm])


      const handleChange = (e) => {
        const {value, name} = e.target;
        setDataForm({...dataForm, [name]:value})
        if(name === 'keyword'){
            setValue(name, value)
            trigger(name)
        }
        setValue(name, value)
        trigger(name)
      }

    const clear = () => {
        setValue('from','')
        setValue('until', '')
        setValue('keyword', '')
        setDataForm({...dataForm, status: '', year: ''})
        setValue('status', '')
        setValue('year', '')
        clearErrors('keyword')
        props.dataFilter()
        dispatch({
            type: "SET_FILTER_EVALUATION_PERFORMANCE",
            filter: {
                createdAtStartDate: null,
                createdAtEndDate: null,
                nameKeyword: null,
                status: null,
                page: 0,
                size: 10
              },
              isFilter: true,
        })
    }

    useEffect(() => {
        const yearInitial = (Number(moment(new Date()).format('yyy'))-10)
        const values = []
     for (let index = yearInitial; index < yearInitial+100; index++) {
       values.push({key: index, value: index})
     }
     setYears(values)
    }, [])
    
    useEffect(()=> {
        if(breadCrumb?.module === "EVALUATION_EVALUATOR" && evaluationPerformanceSearch?.isFilter){
            setValue('from',evaluationPerformanceSearch?.filter?.createdAtStartDate)
            setValue('until', evaluationPerformanceSearch?.filter?.createdAtEndDate)
            setValue('keyword', evaluationPerformanceSearch?.filter?.nameKeyword)
            setValue('evaluationYear', evaluationPerformanceSearch?.filter?.evaluationYear)
            setDataForm({...dataForm, 
                status: evaluationPerformanceSearch?.filter?.status, 
                year:evaluationPerformanceSearch?.filter?.evaluationYear , 
                keyword:evaluationPerformanceSearch?.filter?.nameKeyword
             })
            setValue('status', evaluationPerformanceSearch?.filter?.status)
        }
     
    },[breadCrumb?.module === "EVALUATION_EVALUATOR"])



      const validate = watch('from') || watch('keyword') || dataForm?.status || watch('until') || watch('year')
      const disabledButton = errors['keyword'] || !validate

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container spacing={2} style={{alignItems: 'flex-end'}}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('keyword')}
                    className={classes.dropdownNameCuestionary}
                    fullWidth
                    onChange={handleChange}
                    size="small"
                    autoComplete="off"
                    placeholder="Palabra clave"
                    error={!!errors.hasOwnProperty("keyword") && errors["keyword"].message}
                    helperText={!!errors.hasOwnProperty("keyword") && errors["keyword"].message}
                  />
                </FormControl>
                <FormHelperText style={{color:'#d70909'}}>
                    {!!errors.hasOwnProperty("from") &&
                    errors["from"].message}
                </FormHelperText>
                </Grid>
                <Grid item container spacing={1} lg={4} style={{display:'flex ', flexDirection: 'column' }}>
                    <div >
                        <Typography style={{fontSize: 12, color:'#8E8E8E', textAlign: 'center', paddingBottom: 10 }}>Fecha de creación</Typography>
                        <div style={{display:'flex ', flexDirection: 'row' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection:'row', marginRight: 5}}>
                                <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('from')}
                                    size="small"
                                    type="date"
                                    label="Desde"
                                    onChange={handleChange}
                                    className={classes.fieldDateTimeErrorSearch}
                                    mindate={new Date()}
                                    InputLabelProps={{ shrink: true }}
                                    notched="true"
                                    error={!!errors.hasOwnProperty("from") && errors["from"].message }
                                />
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("from") &&
                                    errors["from"].message}
                                </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', marginLeft: 5}}>
                            <FormControl fullWidth size="small">
                                <TextField 
                                    {...register('until')}
                                    size="small"
                                    type="date"
                                    label="Hasta"
                                    onChange={handleChange}
                                    className={classes.fieldDateTimeErrorSearch}
                                    mindate={new Date()}
                                    InputLabelProps={{ shrink: true }}
                                    notched="true"
                                    error={!!errors.hasOwnProperty("until") && errors["until"].message }
                                />
                                <FormHelperText style={{color:'#d70909'}}>
                                    {!!errors.hasOwnProperty("until") &&
                                    errors["until"].message}
                                </FormHelperText>
                                </FormControl>
                            </Grid>
                        </div>
                </div>
                </Grid>
            
                <Grid item md={3} lg={2} style={{display: 'flex', alignItems: 'center'}}>
                    <FormControl fullWidth size="small">
                    <InputLabel  error={!!errors.hasOwnProperty("status") && errors["status"].message}></InputLabel>
                        <TextField
                        select
                        size="small"
                        {...register('status')}
                        onChange={handleChange}
                        value={dataForm.status}
                        label="Estado"
                        InputLabelProps={{
                            className: classes.labelProps
                        }}
                        error={!!errors.hasOwnProperty("status") && errors["status"].message}
                        className={classes.dropdown}
                        >
                            <MenuItem value='ACTIVE' key={1}>Activo</MenuItem>
                            <MenuItem value='INACTIVE' key={2}>Inactivo</MenuItem>
                            <MenuItem value='APPLIED' key={1}>Aplicado</MenuItem>
                        </TextField>
                        <FormHelperText style={{color:'#d70909'}}>
                            {!!errors.hasOwnProperty("status") &&
                            errors["status"].message}
                        </FormHelperText>
                    </FormControl>
                    
                    </Grid>
               
                <Grid item xs={12} sm={12} md={12} lg={2} style={{display: 'flex',  alignItems: 'center', marginBottom: '0.5%'}}>
                    <Button 
                        variant="contained" 
                        type="submit" 
                        disabled={disabledButton}
                        disableElevation 
                        color="primary"
                    >
                        BUSCAR
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button 
                        variant="outlined"  
                        disableElevation 
                        onClick={clear}
                        style={{background: "#ffffff",}}
                    >
                      Limpiar
                    </Button>

                </Grid>
            </Grid>
         </form>
    )
}

export default SearchLabel
