import HttpClient from '../services/HttpClient';
import {  LABEL, MAX , CRITERIA_EVALUATION} from '../constants'

export const getLengthComtencies = (dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        HttpClient.get(`${LABEL}?type=COMPETENCE&pageNumber=0&pageSize=10&totalElements=true&status=ACTIVE&orderBy=name`).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getCriteriaCode = (dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        HttpClient.get(`${CRITERIA_EVALUATION}${MAX}`).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const createCriteria = (body, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        HttpClient.post(`${CRITERIA_EVALUATION}`, body).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            resolve(error.response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        });
    });
}

export const updateCriteria = (id,body) => {
    return new Promise((resolve, reject) => {
        HttpClient.patch(`${CRITERIA_EVALUATION}/${id}`, body).then(response => {
            resolve(response);
        }).catch(error => {
            resolve(error.response)
        })
    })
}

export const deleteCompetencie = (id, body) => {
    return new Promise((resolve, reject) => {
      HttpClient.delete(`${LABEL}/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve(error.response);
        });
    });
  };


export const getFilterCriteria = (dataFilter, order, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

        const keys = ['status', 'name', 'code' ];

        for (let index = 0; index < keys?.length; index++) {
            if(dataFilter[keys[index]] ){
                if(keys[index] === 'name' && dataFilter[keys[index]]){
                  filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
                }else {
                  filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
                }
            } else {
                continue
            }
        }

        HttpClient.get(`${CRITERIA_EVALUATION}${filters}&orderBy=${order?.orderBy}`).then(response => {
            resolve(response);
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
            dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}