export const initialState = {
    changeLeader: false,
    editLeader: false,
    leaderId: '',
    subalterns: [],
    leaderToEdit: {},
    infoLeader: {},
    newSubalterns: [],
    dataDiagram: {},
    page: null,
  };
  
  const organizationChartReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ORGANIZATION_CHART":
        return {
          ...state,
            changeLeader: action.changeLeader,
            editLeader: action.editLeader,
            leaderId: action.leaderId,
            subalterns: action.subalterns,
            leaderToEdit: action.leaderToEdit,
            infoLeader: action.infoLeader,
            newSubalterns: action.newSubalterns,
            dataDiagram: action.dataDiagram,
            page: action.page
        };
      default:
        return state;
    }
  };
  
  export default organizationChartReducer;
  