import React from "react";
import { makeStyles } from "@mui/styles";
import Warning from "../../../../images/icons/warning.svg";
import { Button, Modal } from "@mui/material";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.24)",
    outline: "none",
    borderRadius: 15,
    padding: theme.spacing(2, 4, 3),
  },
  cancel1: { 
      width: '70%',
     color: "#2F71CB",
     marginBottom: '3%',  
     fontSize: 12,
     borderColor: '#80808070',
     "&:hover": {
      borderColor: '#80808070',
    },
  },
  cancel: { 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5, 
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
 },
  text: {
    fontSize: 14,
    fontFamily: "Roboto",
    color: "#4B4B4B",
    fontWeight: 400,
    textAlign: "center",
    margin: "25px 20px",
  },
  titleAlert: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#2C2C2C",
  },
  widthIcon: {
    fontSize: 70,
    color: '#2F71CB',
    marginTop: '3%'

  },
  confirmBanck :{ 
    color: "#ffffff", 
    background: '#2F71CB',
    marginBottom: 5,
    // width: 'fit-content' ,
    "&:hover": {
      color: "#ffffff", 
      background: '#2F71CB',
   },
  }
}));

const ModalConfirmBase = ({
  description,
  open,
  handleClick,
  handleClickOut,
  handleClose,
  title,
  id,
  textButtonSubmit,
  handleClickThree
}) => {
  
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const iconsAlert = () => {
    return <img src={Warning} alt="" style={{ width: "25%" }} />;
  };

  const buttonsTwo = (handleClickOut, handleClick, textButtonSubmit) => {
    return (
      <React.Fragment >
        <Button
          className={ classes.cancel1 }
          variant="outlined"
          onClick={handleClickOut}
        >
          REPORTE CUESTIONARIO
        </Button>

        <Button
          className={classes.cancel1}
          variant="outlined"
          onClick={handleClick}
        >
           REPORTE POR PREGUNTA
        </Button>
      </React.Fragment>
    );
  }



  const bodyModal = (
    <div style={modalStyle} className={classes.paper}>
      {iconsAlert()}
      {title ? <h1 className={classes.titleAlert}> {title} </h1> : null}
      <p className={classes.text}>¿Qué tipo de reporte desea generar?</p>
      <div style={{ display: "flex", justifyContent: "space-evenly", flexDirection: 'column',  alignItems: 'center',}}>
        {buttonsTwo(
          handleClickOut,
          handleClick,
          textButtonSubmit,
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        style={{ textAlign: "center" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
};

export default ModalConfirmBase;
