import React from 'react';
import { useStateValue } from '../../context/store';
import { Modal } from '@mui/material';
import "./styles.css"

export const Spinner = () => {

  const [{ spinner },  ] = useStateValue();

  return (
    <Modal open={spinner?.active ?? false}  className='container_spinner'>
     <div className={`${"custom-loading-spinner"} ${spinner?.active ? "show-loading" : null }`}> 
  <div className="loading-wrapper">
    <div className="loading-content">
      <div className="loading"></div>
    </div>
  </div>
  <div className="loading-wrapper loading-wrapper-2">
    <div className="loading-content">
      <div className="loading loading-2"></div>
    </div>
  </div>
  <div className="loading-wrapper loading-wrapper-3">
    <div className="loading-content">
      <div className="loading loading-3"></div>
    </div>
  </div>
  <h3>Cargando...</h3>
</div>
 </Modal>
  )
}

export default Spinner;
