import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField, 
     Select, 
     InputLabel, 
     FormControl, 
     FormHelperText, 
     MenuItem,
     Button,
} from '@mui/material'
import { useStateValue } from "../../../context/store";
import {styles} from './styles';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SearchLabel = (props) => {


    const classes = styles()
    const [{labelSearch}, dispatch] = useStateValue();
    const [valuesForm, setValuesForm] = useState({
        status: '',
        name: ''
    })


    const schema = yup.object().shape({
        code: yup
        .string()
        .nullable()
        .max(6, "Ingrese máximo 6 caracteres")
        .matches(  
            /^([0-9]{0,6})$/,
           "Código no válido"),
        name:  yup.string()
            .notRequired()
            .nullable(true)
            .max(20, 'Ingrese máximo 20 caracteres')
            .matches(
                /^(([a-zA-Z0-9]))*$/g,
                "Ingrese un nombre válido"
                )
            .transform((value)=> value.toUpperCase()),
            // .matches(
            //     /^(?=.{0,20}$)(([A-ZÀ-ÿa-z0-9](|\(|\))?))+$/,
            //     "Ingrese una descripción valida"
            //   ),
        status: null
      });
    
      
      const { register, handleSubmit, setValue, clearErrors, trigger, watch, formState:{errors} } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });
      

      const onSubmit = (data) => {
          if(watch('name') === ''){
            clearErrors('name')
          }
        if(
            data?.code !== '' ||
            data?.name !== '' ||
            valuesForm.status !== ''
        ){
            dispatch({
                type: "SET_FILTER_LABEL",
                filter: {
                    code: data?.code ? parseInt(data?.code) :data?.code,
                    name: data?.name,
                    status:  valuesForm.status,
                    description: null,
                    page: 0,
                    size: 10
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "SET_FILTER_LABEL",
                filter: {
                    code: null,
                    description: null,
                    status: null,
                    name: null
                  },
                  isFilter: true,
            })
        }
      }

      const clear = (isFilter) => {
        setValue('code', '')
        setValue('name', '')
        setValue('status', '')
        setValuesForm({...valuesForm, status: ''})
        clearErrors('code')
        dispatch({
            type: "SET_FILTER_LABEL",
            filter: {
                code: null,
                description: null,
                status: null,
                name: null,
                page: 0,
                size: 10
              
              },
              isFilter: isFilter ?? true,
          })
      }

      useEffect(() => {
        clear(false)
      }, [props?.clearFielForm])

      
      const clearForm = () => {
        clear()
          dispatch({
              type: "SET_FILTER_LABEL",
              filter: {
                  code: null,
                  description: null,
                  status: null,
                  name: null,
                  page: 0,
                  size: 10
                
                },
                isFilter: true,
            })
            props.dataFilter()
        }
        
        if(labelSearch?.typeAction === 'CLEAR'){ 
             clear()
        }


        const handleOnchange = (e) => {
            const {name, value} = e.target;
            setValuesForm({...valuesForm, [name]:value})
            trigger(name)
            if(name === 'name'){
                setValue(name, value.toUpperCase())
            trigger(name)

            }
        }

        const validate = valuesForm.status || watch('name') || watch('code')

    return (
         <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container spacing={2} style={{marginBottom: 20, display: 'flex', alignItems: 'flex-start'}}>
                <Grid item xs={12} sm={12} md={12} lg={2.5}>
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('code')}
                    className={classes.dropdown}
                    fullWidth
                    type="text"
                    InputLabelProps={{ shrink: watch('code')? true : false }}
                    notched={watch('code')? 'true' : 'false'}
                    size="small"
                    label="Código"
                    error={!!errors.hasOwnProperty("code")}
                    
                  />
                   <FormHelperText style={{color:'red'}}>
                        {errors.hasOwnProperty("code") &&
                        errors["code"].message}
                    </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5.5}>
                <FormControl variant='outlined' fullWidth size="small">
                  
                    <TextField
                    {...register('name')}
                    className={classes.dropdown}
                    fullWidth
                    onChange={handleOnchange}
                    InputLabelProps={{ shrink: watch('name') ? true : false }}
                    notched={ watch('name')? 'true' : 'false'}
                    size="small"
                    label="Nombre de etiqueta"
                    error={!!errors.hasOwnProperty("name")}
                    />
                    <FormHelperText style={{color:'red'}}>
                        {errors.hasOwnProperty("name") &&
                        errors["name"].message}
                    </FormHelperText> 
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} >
                <FormControl variant='outlined' fullWidth size="small">
                    <InputLabel error={!!errors.hasOwnProperty("status") && errors["status"].message}>Estado</InputLabel>
                        <Select
                        {...register('status')}
                        name='status'
                        value={valuesForm?.status}
                        onChange={handleOnchange}
                        shrink="true"
                        status="Estado"
                        className={classes.dropdown}
                        label="Estado "
                    >
                        <MenuItem focusVisibleClassName={classes.focusVisible} value='ACTIVE' key={1}>Activo</MenuItem>
                        <MenuItem value='INACTIVE' key={2}>Inactivo</MenuItem>
                    </Select>
                    <FormHelperText style={{color:'red'}}>
                        {errors.hasOwnProperty("status") &&
                        errors["status"].message}
                    </FormHelperText> 
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} className={classes.container_buttons}>
                    <Button 
                    variant="contained" 
                    type="submit" 
                    disableElevation 
                    disabled={!validate}
                    color='primary'
                    // style={{color: "#ffffff", background: !disabledButton ? "#2F71CB": '#C0C0C0'}}
                    >
                        BUSCAR
                    </Button>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 
                    <Button 
                    variant="outlined" 
                    onClick={()=> clearForm()} 
                    disableElevation 
                    style={{background:"#ffffff"}}
                    >
                        LIMPIAR
                    </Button>

                </Grid>

            </Grid>
        </form>
    )
}

export default SearchLabel
