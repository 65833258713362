export const initialState = {
    filter: {
        nameKeyword: null,
        idKeyword: null,
        positionName: null,
        regionalName: null,
        page: null,
        size: null
    },
    isFilter: false,
    typeAction: null
  };
  
  const addNewLeadersSeachReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_NEW_LEADERS":
        return {
            ...state,
            filter: action.filter,
            isFilter: action.isFilter,
            typeAction: action.typeAction,
          };
      default:
        return state;
    }
  };
  
  export default addNewLeadersSeachReducer;
  