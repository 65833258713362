import React, { useEffect, useState } from 'react'
import { useStateValue } from '../../../context/store';
import { getByIdAplicationEvaluationGeneral, getfilterDataGraphics} from "../../../actions/ManagementAplicationEvaluations-actions"
import GraphicPie from "./graphicPie";
import GraphicPieCriteria from "./graphicPieCriteria";
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ModalActions from "../../../components/common"
import ModalText from '../../../components/common/modalOnlyText';
import { Grid, Paper, Typography } from '@mui/material';
import { getRegionals, getPositions} from '../../../actions/OrganizationChart-action';
import Excel from "./generalExcel";
import Search from "./search";
import _ from "lodash";
import { PATH_APP, typesEvaluation } from '../../../constants';
import GraphicBar from './graphicBar';


const Index = (props) => {
    const {aplicationId} = props?.match?.params
    const idEvaluation = aplicationId

    const [{loadingTables, breadCrumb, searchGraphic}, dispatch] = useStateValue();
    const [dataDetail, setDataDetail] = useState({})
    const [answerEvaluation, setAnswerEvaluation] = useState({});
    const [criterias, setCriterias] = useState([]);
    const [graphicBars, setGraphicBars] = useState([]);
    const [, setAverageGeneralEvaluation] = useState({})
    const [dataTable, setDataTable] = useState([]);
    const [action, setAction] = useState(false);
    const [typesEvaluations, setTypesEvaluations] = useState([]);
    const [allRegionals, setAllRegionals] = useState([]);
    const [allPositions, setAllPositions] = useState([]);
    const [showGraphic, setShowGraphic] = useState(false);
    const [marginEvalutions, setMarginEvaluations] = useState(0);
    const [detailText, setDetailText] = useState({
      title : '',
      description : '',
      handleClose : () => {} ,
      handleOpen : () => {},
      open : false
    })
    const [message, setMessage] = useState({
      description : '',
      textButtonSubmit : '',
      handleClick : () => {} ,
      handleClickOut : () => {},
      oneButtons : false,
      title: '',
      list: [],
      type : '',
      open : false
    })


    
    const handleOpen = (d) => setDetailText({...detailText, open: true, title: 'DESCRIPCIÓN', description: d});
    const handleClose = () => setDetailText({...detailText, open: false});
    const getRegional = async() => {
      const result = await getRegionals();  
      setAllRegionals(result?.data?.length ? result?.data : [] )
    }
    const getPosition = async() => {
      const result = await getPositions();  
      setAllPositions(result?.data?.length ? result?.data : [] )
    }
  
    useEffect(()=> {
      getEvaluation();
      getRegional();
      getPosition();
      defaultBreadCrumb()
    },[])

    const closeModal = () => setMessage({...message, open: false})

    const defaultBreadCrumb = ()=> dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Resultado General",
      index: 13,
      module: 'DETAIL_APLICATION_EVALUATION',
      subPath: `${PATH_APP}/management-aplication-evaluations`,
    }); 

    const dowloadNotActiveButton = () => dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Resultado General",
      index: 13,
      module: 'DETAIL_APLICATION_EVALUATION',
      // disabledDowload: true,
      // dowload: false,
      // textDowload: 'Descargar archivo',
      subPath: `${PATH_APP}/management-aplication-evaluations`,
    }); 
    const dowloadActiveButton = () => dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Resultado General",
      index: 13,
      module: 'DETAIL_APLICATION_EVALUATION',
      // disabledDowload: false,
      // dowload: false,
      // textDowload: 'Descargar archivo',
      subPath: `${PATH_APP}/management-aplication-evaluations`,
    }); 


    useEffect(()=> {

      if(breadCrumb?.dowload){
        const reportAplication = () => {
          setAction(true)
        }
        reportAplication()
      }
    },[breadCrumb])

    const getGraphic = async(datafilter) => {
      const result = await getfilterDataGraphics(datafilter, idEvaluation, dispatch)
      if(result?.status >= 200 && result?.status <= 204){
        dowloadActiveButton()
        return setDataDetail(result?.data)
      }
      if(result?.status === 404){
        dowloadNotActiveButton()
        return  setMessage({
          open : true,
          description: 'La aplicación de modelo de evaluación no existe',
          handleClick : () => closeModal() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      }
      else {
        dowloadNotActiveButton()
        return  setMessage({
          open : true,
          description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
          handleClick : () => closeModal() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      }
      
  }

  if(searchGraphic &&
    searchGraphic?.isFilter) {

      getGraphic(searchGraphic?.filter)
      dispatch({
        type: "SET_FILTER_GRAPHIC",
        filter: searchGraphic?.filter,
        isFilter: false,
      })
     
  }


    const getEvaluation = async() => {
      const result = await getByIdAplicationEvaluationGeneral(idEvaluation, dispatch)
      if(result?.status >= 200 && result?.status <= 204){
        setDataDetail(result?.data)
        dowloadActiveButton()
        return dispatch({
          type: 'SEND_DATA_TO_PAGE',
          data: {...result?.data}
        })
      }
      if(result?.status === 404){
        defaultBreadCrumb()
        return  setMessage({
          open : true,
          description: 'La aplicación de modelo de evaluación no existe',
          handleClick : () => closeModal() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      }
      else {
        defaultBreadCrumb()
        return  setMessage({
          open : true,
          description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
          handleClick : () => closeModal() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      }
      
  }

  const validateTitle = (v) => {
    if(v ==='SELF_EVALUATION'){
      return 'Autoevaluación'
    }else if(v ==='PEER_EVALUATION'){
      return 'Evaluación estudiantes'
    } else if(v ==='LEADER_EVALUATION'){
      return 'Evaluación institucional'
    }
  }


  const hexadecimalesColors =['#89A771', '#4682B4', '#B990E3', '#FFD700', '#FF7F50', '#98FB98', '#18B0BE', '#E3BF95', '#B45336', '#009400',
  '#E6E6FA', '#0000FF',  '#7FFFD4','#B18904','#9400D3','#827C96','#FF0000', '#E0FFFF','#343233', '#FF1493',]

  const colorRandom = () => {

    const hexadecimales =['#89A771', '#4682B4', '#B990E3', '#FFD700', '#FF7F50', '#98FB98', '#18B0BE', '#E3BF95', '#B45336', '#009400',
    '#E6E6FA', '#0000FF',  '#7FFFD4','#B18904','#9400D3','#827C96','#FF0000', '#E0FFFF','#343233', '#FF1493',]
   
      let random = Math.random();
      random = random * 20 + 0;
      const random1 = Math.trunc(random);

    return hexadecimales[random1]

  }


  useEffect(()=> {
    
    const newTypes = []
    const averages = [];
    const newCriterias = [];
    const newAnswerEvaluation = [];
    const newBars = [];
    let valueCero = 0;
    let countCriteriaCero = 0;

    const resultColors = [];
    if(dataDetail?.evaluationApplicationTypes?.length){
      setMarginEvaluations(dataDetail?.totalGeneralResult?.evaluationsApplied)
    for (let index = 0; index < dataDetail?.evaluationApplicationTypes[0]?.criteriaEvaluationResultList?.length; index++) {
      resultColors.push(colorRandom())
    }
   
    for (let index = 0; index < dataDetail?.evaluationApplicationTypes?.length; index++) {

      newAnswerEvaluation.push(
        {
          title: `Evaluaciones contestadas ${validateTitle(dataDetail?.evaluationApplicationTypes[index]?.type)}`,
          data: [
            {
              value: dataDetail?.evaluationApplicationTypes[index]?.numberOfEvaluationsAnswered === 0 ? null : dataDetail?.evaluationApplicationTypes[index]?.numberOfEvaluationsAnswered , 
              color: dataDetail?.evaluationApplicationTypes[index]?.numberOfEvaluationsAnswered === 0 ? '' : '#AFB6E5'
            },
            {
              value: (dataDetail?.evaluationApplicationTypes[index]?.totalNumberOfEvaluations - dataDetail?.evaluationApplicationTypes[index]?.numberOfEvaluationsAnswered) === 0 ? null : (dataDetail?.evaluationApplicationTypes[index]?.totalNumberOfEvaluations - dataDetail?.evaluationApplicationTypes[index]?.numberOfEvaluationsAnswered),
              color: (dataDetail?.evaluationApplicationTypes[index]?.totalNumberOfEvaluations - dataDetail?.evaluationApplicationTypes[index]?.numberOfEvaluationsAnswered) === 0 ? '' : '#836DF3'
            }
          ],
          dataLegend:[
            {
              value: dataDetail?.evaluationApplicationTypes[index]?.numberOfEvaluationsAnswered, 
              color: '#836DF3',
              title: 'Contestadas'
            },
            {
              value: dataDetail?.evaluationApplicationTypes[index]?.totalNumberOfEvaluations - dataDetail?.evaluationApplicationTypes[index]?.numberOfEvaluationsAnswered,
              color: '#AFB6E5',
              title: 'No contestadas'
          },
          
          ]
         
        }
        
      )        
        averages.push(
          {
              type: `${validateTitle(dataDetail?.evaluationApplicationTypes[index]?.type)}`,
              data: [
                  {
                      value: dataDetail?.evaluationApplicationTypes[index]?.competenceAveragePercentage,
                      color: '#4C7F98',
                  },
                  {
                    value: dataDetail?.evaluationApplicationTypes[index]?.conductAveragePercentage,
                    color: '#6DADCD',
                  },
                  {
                    value: dataDetail?.evaluationApplicationTypes[index]?.totalAveragePercentage,
                    color: '#4638A3',
                  },
                  {
                    value: 100,
                    color: null,
                  },
              ]             
          }
      );


      const newDataCriterias = []
      for (let vl = 0; vl < dataDetail?.evaluationApplicationTypes[index]?.criteriaEvaluationResultList?.length; vl++) {
        valueCero =  dataDetail?.evaluationApplicationTypes[index]?.criteriaEvaluationResultList[vl]?.percentage + valueCero
        countCriteriaCero = dataDetail?.evaluationApplicationTypes[index]?.criteriaEvaluationResultList[vl]?.percentage === 0 ? countCriteriaCero+1 : countCriteriaCero

        newDataCriterias.push(
          {
            title: dataDetail?.evaluationApplicationTypes[index]?.type,
            type: dataDetail?.evaluationApplicationTypes[index]?.criteriaEvaluationResultList[vl]?.criteriaEvaluation?.name,
            rangeTo: dataDetail?.evaluationApplicationTypes[index]?.criteriaEvaluationResultList[vl]?.criteriaEvaluation?.rangeTo,
            rangeFrom: dataDetail?.evaluationApplicationTypes[index]?.criteriaEvaluationResultList[vl]?.criteriaEvaluation?.rangeFrom,
            description: dataDetail?.evaluationApplicationTypes[index]?.criteriaEvaluationResultList[vl]?.criteriaEvaluation?.description,
            value: dataDetail?.evaluationApplicationTypes[index]?.criteriaEvaluationResultList[vl]?.percentage,
            color: hexadecimalesColors[vl],
            label: dataDetail?.evaluationApplicationTypes[index]?.criteriaEvaluationResultList[vl]?.percentage
          }
        )
      }
      setDataTable(_.orderBy(newDataCriterias, ['rangeFrom'],['asc']))
      newCriterias.push(
        {
            type: `Escala de valoración alcanzados por los colaboradores  -  ${validateTitle(newDataCriterias[index]?.title)}`,
            data: newDataCriterias?.filter((x)=> x?.value !== 0)?.map((x, pos=index)=> (
              {
                type: x?.type,
                rangeTo: x?.rangeTo,
                rangeFrom: x?.rangeFrom,
                description: x?.description,
                value: x?.value,
                color: x?.color,
                label: x?.value
              }
            )) 
           
        }
      );
      
        newBars.push(
          {
            totalEvaluations:  dataDetail?.evaluationApplicationTypes[index]?.totalNumberOfEvaluations,
            type: `${typesEvaluation[dataDetail?.evaluationApplicationTypes[index]?.type]}`,
            label: 'Respondidas',
            total: dataDetail?.evaluationApplicationTypes[index]?.numberOfEvaluationsAnswered ,
            color: '#246515'
          },
          {
            totalEvaluations: dataDetail?.evaluationApplicationTypes[index]?.totalNumberOfEvaluations,
            type: `${typesEvaluation[dataDetail?.evaluationApplicationTypes[index]?.type]}`,
            label: 'Sin responder',
            total: dataDetail?.evaluationApplicationTypes[index]?.totalNumberOfEvaluations - dataDetail?.evaluationApplicationTypes[index]?.numberOfEvaluationsAnswered,
            color: '#e72828'
          }
        )
        
      
     
        
      valueCero = 0;
      countCriteriaCero = 0;
       
    }

     newBars.push(
          {
            totalEvaluations:  dataDetail?.totalGeneralResult?.evaluationsApplied,
            type: `Total evaluaciones`,
            label: 'Respondidas',
            total: dataDetail?.totalGeneralResult?.evaluationsAnswered,
            color: '#246515'
          },
          {
            totalEvaluations: dataDetail?.totalGeneralResult?.evaluationsApplied,
            type: `Total evaluaciones`,
            label: 'Sin responder',
            total: dataDetail?.totalGeneralResult?.evaluationsApplied - dataDetail?.totalGeneralResult?.evaluationsAnswered,
            color: '#e72828'
          }
        )

    newAnswerEvaluation.push(
      {
        title: `Evaluaciones contestadas - Total`,
        data: [
          {
            value: dataDetail?.totalGeneralResult?.evaluationsAnswered === 0 ? null : dataDetail?.totalGeneralResult?.evaluationsAnswered , 
            color: dataDetail?.totalGeneralResult?.evaluationsAnswered === 0 ? '' : '#AFB6E5'
          },
          {
            value: (dataDetail?.totalGeneralResult?.evaluationsApplied - dataDetail?.totalGeneralResult?.evaluationsAnswered) === 0 ? null : (dataDetail?.totalGeneralResult?.evaluationsApplied - dataDetail?.totalGeneralResult?.evaluationsAnswered),
            color: (dataDetail?.totalGeneralResult?.evaluationsApplied - dataDetail?.totalGeneralResult?.evaluationsAnswered) === 0 ? '' : '#836DF3'
          }
        ],
        dataLegend:[
          {
            value: dataDetail?.totalGeneralResult?.evaluationsAnswered, 
            color: '#836DF3',
            title: 'Contestadas'
          },
          {
            value: dataDetail?.totalGeneralResult?.evaluationsApplied - dataDetail?.totalGeneralResult?.evaluationsAnswered,
            color: '#AFB6E5',
            title: 'No contestadas'
        },
        
        ]
       
      }
    ) 

    newCriterias.push(
      {
          type: `Escala de valoración alcanzados por los colaboradores  -  Total`,
          data: dataDetail?.totalGeneralResult?.criteriaEvaluationList?.filter((x)=> x?.value !== 0)?.map((x, index)=> (
            {
              type: x?.criteriaEvaluation?.type,
              rangeTo: x?.criteriaEvaluation?.rangeTo,
              rangeFrom: x?.criteriaEvaluation?.rangeFrom,
              description: x?.criteriaEvaluation?.description,
              value: x?.percentage,
              color: hexadecimalesColors[index],
              label: x?.percentage
            }
          )) 
        
      }
    );

    


    setAnswerEvaluation(newAnswerEvaluation)
    setAverageGeneralEvaluation(averages)
    setTypesEvaluations(newTypes)
    setCriterias(newCriterias)
    setGraphicBars(newBars);
    setShowGraphic(true)
  }
  },[dataDetail])

  

  return (
    <React.Fragment>
            {/* <Search 
              regionals={allRegionals} 
              positions={allPositions} 
              setMessage={setMessage} 
              message={message}
              refresh={getEvaluation}
            /> */}
          {!loadingTables?.active &&
              <>
               
                 {/* {!showGraphic ? null : 
               <>
                <Grid container  spacing={3} justifyContent={criterias?.length <= 3 ? "center" :"space-between"} style={{marginBottom: '3%'}}>
                  <Grid item lg={12}>
                    <Typography style={{textAlign: 'start', color: '#4B4B4B', fontSize: 16, fontWeight: 700, margin: '3% 0%'}}>Resultado promedio obtenido de evaluaciones contestadas</Typography>       
                  </Grid>
                    {criterias?.map((x)=> (
                      <Grid item lg={criterias?.length <= 3 ? 4 : 6} style={{display: 'flex', justifyContent: 'center'}}>
                        <Grid item lg={criterias?.length <= 3 ? 10 : 7}>
                          <Paper  elevation={3}>
                            <Typography style={{fontSize: 15, color: '#4B4B4B', fontWeight: 400, textAlign: 'center', paddingTop: '3%'}}>{x?.type}</Typography>
                            <GraphicPieCriteria
                                colors={defaultColors} 
                                title={x?.type}
                                dataTable={dataTable}
                                data={x?.data} 
                                position={'center'}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
                <Grid item container lg={10} style={{margin: 'auto', marginBottom: '2%'}}>
                  <Paper elevation={0} style={{borderRadius: 20, width: '100%'}}>
                    <Grid item container lg={12} style={{margin: '3% 0%',}} alignItems="center">
                    {dataTable?.map((x)=> (
                      <>
                      <Grid item lg={3.2}></Grid>
                      <Grid item lg={0.3} style={{height: 25, width: 20 , background: x?.color}}></Grid>
                      <Grid item lg={2.5}>
                        <Typography style={{color: '#4B4B4B', fontSize: 16, fontWeight: 400, paddingLeft: '5%', textAlign: 'start'}}>{x?.type}</Typography>
                      </Grid>
                      <Grid item lg={1.5}>
                        <Typography style={{color: '#4B4B4B', fontSize: 16, fontWeight: 400}}>{`${x?.rangeFrom} - ${x?.rangeTo}`}</Typography>
                      </Grid>
                      <Grid item lg={4.2}>
                        <RemoveRedEyeIcon color='disabled' fontSize='small' style={{cursor: 'pointer'}} onClick={() => handleOpen(x?.description)}/>
                      </Grid>
                      </>
                    ))}
                    </Grid>
                  </Paper>
                </Grid>
              </>
              } */}
              {/* {!answerEvaluation?.length ? null :
                  <Grid container spacing={3} justifyContent={answerEvaluation?.length <= 3 ? "center" :"space-between"} style={{marginBottom: '3%'}}>
                    {answerEvaluation?.map((x)=> (
                        <Grid item lg={answerEvaluation?.length <= 3 ? 4 : 6} style={{display: 'flex', justifyContent: 'center'}}>
                        <Grid item lg={answerEvaluation?.length <= 3 ? 10 : 7}>
                          <Paper  elevation={3}>
                            <Typography style={{fontSize: 16, color: '#4B4B4B', fontWeight: 400, textAlign: 'center', paddingTop: '3%'}}>{x?.title}</Typography>
                            <GraphicPie 
                              colors={['#836DF3','#AFB6E5']} 
                              data={x?.data} 
                              dataLegend={x?.dataLegend} 
                              position={'center'}
                            />
                          </Paper>
                        </Grid>
                    </Grid>
                    ))}
                  </Grid>
                } */}
                {/* <Grid container  spacing={3} justifyContent={typesEvaluations?.length <= 3 ? "center" :"space-between"} style={{marginBottom: '3%'}}>       
                    {typesEvaluations?.map((x)=> (
                       <Grid item lg={typesEvaluations?.length <= 3 ? 4 : 6} style={{display: 'flex', justifyContent: 'center'}}>
                        <Grid item lg={typesEvaluations?.length <= 3 ? 10 : 7}>
                          <Paper  elevation={3}>
                            <Typography style={{fontSize: 15, color: '#4B4B4B', fontWeight: 400, textAlign: 'center', paddingTop: '3%'}}>{x?.type}</Typography>
                            <GraphicPie 
                                colors={['#AFB6E5','#836DF3']} 
                                title={x?.type}
                                data={x?.data} 
                                dataLegend={x?.dataLegend} 
                                position={'center'}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                    ))}
                </Grid> */}
                 <Grid container  spacing={3} justifyContent= "center" style={{margin: '3% 0%'}}>       
                
                       <Grid item lg={10} style={{display: 'flex', justifyContent: 'center'}}>
                        <Grid item lg={10}>
                          <Paper  elevation={3} style={{paddingLeft: 20}}>
                            <Typography style={{fontSize: 15, color: '#4B4B4B', fontWeight: 700, textAlign: 'center', padding: '5% 0%'}}>Evaluaciones contestadas vs no contestadas</Typography>
                            <GraphicBar
                              
                              colors={['#246515','#e72828']} 
                              title={'cualquier Tipoooo'}
                              data={graphicBars} 
                              position={'center'}
                              totalEval={marginEvalutions}
                          />

                          </Paper>
                        </Grid>
                      </Grid>
                </Grid>

            </>
             }
             <Excel action={action} setAction={setAction}/>
            <ModalConfirm 
              description = { message.description }
              textButtonSubmit = { message.textButtonSubmit }
              handleClick = { message.handleClick }
              handleClickOut = { message.handleClickOut }
              oneButtons = { message.oneButtons }
              title = { message.title }
              list = {message.list}
              type = { message.type }
              open = { message.open }
            />
            <ModalText 
              open={ detailText.open}
              title={ detailText.title}
              handleOpen={ detailText.handleOpen}
              description={ detailText.description}
              handleClose={ handleClose}
            />
            <ModalActions/>
      
    </React.Fragment>
  )
}

export default Index