import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
  dropdown: {
    backgroundColor: "#E1E1E1",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
      background: '#dfe7fd',
    },
  },
  dropdownDate: {
    backgroundColor: "#ffffff",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
      background: '#ffffff',
    },
  },
  widthModal: {
    [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
      maxWidth: 1200,
      minWidth: 1000,
      borderRadius: 16
  },
  [`& .css-uhb5lp`] : {
    maxWidth: 1200,
      minWidth: 1000,
      borderRadius: 16
  }
  },
  iconError: {
    marginRight: '1%',
    marginTop: '1%',
    fontSize: 'initial'
  },
  iconErrorCode: {
    marginRight: '4%',
    marginTop: '4%',
    fontSize: 'initial'
  },
  iconErroDescription: {
    marginRight: '1%',
    marginTop: '1%',
    fontSize: 'initial'
  },
  dropdownMultiple: {
    backgroundColor: "#E1E1E1",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },
    '& .css-1in441m>span' :{
      paddingRight: '20px !important'
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
      background: '#dfe7fd',
    },
  },
  actions: {
    marginTop: '2%',
    display: 'flex',
    justifyContent: 'flex-start !important' 
  },
  fieldDateTimeErrorSearch: {
    background: '#E1E1E1',
    borderRadius: 10,
    
    '& .css-ly45cz-MuiInputBase-root-MuiOutlinedInput-root':{
      background: '#dfe7fd',
    },
    '& .MuiOutlinedInput-notchedOutline':{
    borderRadius: 10,

    },
    '& .css-i8lz65':{
      background: '#E1E1E1',
      borderRadius: 10
    },
    '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':{
      background: '#E1E1E1',
      color: '#545353',
      borderRadius: 10,
      fontSize: 14
      
    }
  },
  fieldDateTime: {
    background: '#ffffff',
    borderRadius: 10,
    
    '& .css-ly45cz-MuiInputBase-root-MuiOutlinedInput-root':{
      background: '#dfe7fd',
    },
    '& .MuiOutlinedInput-notchedOutline':{
    borderRadius: 10,

    },
    '& .css-i8lz65':{
      background: '#ffffff',
      borderRadius: 10
    },
    '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':{
      background: '#ffffff',
      color: '#545353',
      borderRadius: 10,
      fontSize: 14
      
    }
  },
  titleDate: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
  },
  dropdownMultilineEvaluators: {
    backgroundColor: "#E1E1E1",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },
    '& .css-1in441m>span' :{
      paddingRight: '20px !important'
    },
    [`& .css-2laola-MuiInputBase-root-MuiOutlinedInput-root`]: {
      backgroundColor: "#FFFFFF",
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
      background: '#dfe7fd',
    },
    [`& .css-ktmv9s `] :{
      backgroundColor: "#FFFFFF",
    }
  },
  dropdownMultilineEvaluated: {
    backgroundColor: "#ffffff",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },
    '& .css-1in441m>span' :{
      paddingRight: '20px !important'
    },
    [`& .css-2laola-MuiInputBase-root-MuiOutlinedInput-root`]: {
      backgroundColor: "#FFFFFF",
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
      background: '#dfe7fd',
    },
    [`& .css-ktmv9s `] :{
      backgroundColor: "#FFFFFF",
    }
  },
})


