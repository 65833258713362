import React from 'react';
import { Container, Grid } from '@mui/material'
import Question from '../../questions/createQuestions';

const Index = (props) => {
  return (
    <Container>
        {props?.valuesAnswer.map((x, index) => (       
            <Grid item container style={{display: 'flex', alignItems: 'baseline'}} >
                <Grid item lg={11.5} >
                    <Question 
                        mapQuestionQuestionary={x.createQuestion} 
                        setOpenModal={props.setOpenModal} 
                        isQuestionary={props?.questionary}
                        showDetail={props?.showDetail}
                        isEdit = {props?.edit}
                    />
                </Grid>
            </Grid>
        ))}   
    </Container>
  )
}

export default Index