import React,{ useEffect, useState } from 'react';
import {
    Button,
    Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {styles} from './styles';
import Checkbox from '@mui/material/Checkbox';
import { Scrollbars } from "react-custom-scrollbars";
import SearchQuestion from '../../../../Pages/questions/search'

export default function AlertDialog(props) {
    const classes = styles()
    const [checkeds, setCheckeds] = useState([])
    const [showText, setShowText] = useState([])

  const validateChecked = (data) => {

     const result =  checkeds?.find((x) =>x?.id === data?.uuid )
    return result ? true : false

  }

  const handleChangeChecked = (event) => {
      const copy= [...checkeds]
      const { name } = event.target;
      const filterQuestions = props?.data?.find((x) => x.uuid === name)
      const validateExists = validateChecked(filterQuestions)
 
     if(validateExists) {

       setCheckeds(checkeds.filter((x)=> x.id !== name))
       
      }else {
        copy.push(
          {
            id: filterQuestions.uuid, 
            value: filterQuestions.question, 
            code: filterQuestions.answerAlternativeCode, 
            createQuestion: filterQuestions
          }
        )
        setCheckeds(copy);
     } 
  };

  
  const resetModalBank = () => {
   props.clearList()
    props.modalBank()
   
  }
  
  const submitData = ()=> {
    props.getData(checkeds)
    resetModalBank();
   
}



useEffect(() => {
  
  let currentEdit = []
   for (let index = 0; index < props?.dataEdit?.length; index++) {
    currentEdit.push(
      {
        id: props?.dataEdit[index]?.createQuestion?.uuid, 
        value: props?.dataEdit[index]?.question, 
        code: props?.dataEdit[index]?.createQuestion?.answerAlternativeCode, 
        createQuestion: props?.dataEdit[index]?.createQuestion
      }
    )
   }
  

    if(props?.arrayDeletes){
      for (let index = 0; index < props?.arrayDeletes?.length; index++) {
      const result = currentEdit.filter((x)=> x?.id !== props?.arrayDeletes[index]?.createQuestion?.uuid)
      currentEdit=result
      }
      setCheckeds(currentEdit)
  }
}, [props?.open])

const showQuestion = (data, id) => {
  const result = showText.find((x)=> x?.id === id)
  if(result?.show) {  
    return data 
  } else {
    return `${data.substring(0, 400)}` 
  }
}

  const allShow = (id) => {
   const result = showText.find((x)=> x?.id === id)
   if(result?.show) {
     setShowText([{show: false, id: id}])
   }else {
    setShowText([{show: true, id: id}])
   }
  }

  const validateShowText = (id) => {
   const result = showText.find((x)=> x?.id === id)
    return result?.show ? true : false
  }


  return (
    <div >
      <Dialog
       className={classes.modalBank}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" 
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleModalBank}>
         BANCO DE PREGUNTAS
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{marginTop: 10}}>
          <SearchQuestion isDisabledClear={true} labels={props.labels} clearList={props.clearList}/>
         <Scrollbars style={{height: 150, margin: '20px 0px'}}>
              {props?.data?.map((x, index)=> (
                  <React.Fragment   key={x?.uuid}>
                      <div style={{display: 'flex', position: 'relative', alignItems: 'flex-start'}} >
                        <Checkbox
                            checked={validateChecked(x)}
                            name={x?.uuid}
                            onChange={handleChangeChecked}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                         <Typography component={'span'} style={{marginTop: '1.5%'}} className={classes.heading} >
                          {x.question?.length > 300 ? showQuestion(x.question, index) : x.question}{x.question?.length > 300 && 
                            <Typography variant="caption"
                              className={classes.expandText}
                              onClick={()=> allShow(index)}>{validateShowText(index) ? "...ver menos" : "...ver más" }</Typography>}
                        </Typography>
                      </div>
                  </React.Fragment>
              ))}
              </Scrollbars>
            <React.Fragment >
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button variant="outlined"  
                  disableElevation 
                  onClick={()=>resetModalBank()}
                  style={{ color: "#6D9FFF", marginBottom: 5, borderColor: '#80808070' }} 
               
                >
                VOLVER
                </Button>
                    &nbsp;&nbsp;
                <Button
                  onClick={() => submitData()}
                  variant="contained"
                  style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 5 }}
                >
                    ACEPTAR
                </Button>
                </div>
               </React.Fragment>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
        </DialogActions>
      </Dialog>
    </div>
  );
}
