export const initialState = {
    open: false,
    message: false,
    icon: false,
    textButton: '',
    redirect: ''
  };
  
  const modalActionReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_MODAL_ACTION":
        return {
          ...state,
          open: action.open,
          message: action.message,
          icon: action.icon,
          textButton: action.textButton,
          redirect: action.redirect
        };
      default:
        return state;
    }
  };
  
  export default modalActionReducer;
  