export const initialState = {
    filter: {
        regionalName : null,
        positionName: null,
        page: null,
        size: null
    },
    isFilter: false,
    typeAction: null
  };
  
  const evaluatedsAplicationSearchReducer = (state = initialState, action) => {
    switch (action.type) {
      case "EVALUATEDS_APPLICATION_SEARCH":
        return {
          ...state,
          filter: action.filter,
          isFilter: action.isFilter,
          typeAction: action.typeAction,
          page: action.page,
          size: action.size
        };
      default:
        return state;
    }
  };
  
  export default evaluatedsAplicationSearchReducer;
  