import React, {useEffect, useState} from 'react'
import { useStateValue } from '../../context/store';
import Table from "./table"
import Search from "./search";
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import { getFilterCriteria } from '../../actions/CriteriaEvaluation-action';
import ModalConfirm from "../../utils/form/modalConfirmBase";
import { PATH_APP } from '../../constants';

const Index = () => {
 const [{criteriaSearch, loadingTables}, dispatch] = useStateValue()
 const [clearFielForm, setClearFielForm]= useState(false)
 
 const [data, ] = useState({
  code : null,
  name: null,
  status: null
})
const [paginadorResponse, setPaginadorResponse] = useState({
  data: [],
  totalRows: 0,
  pageSize: 0,
  pageNumber: 0,
});
const [resetError, setResetError] = useState(false);
const [message, setMessage] = useState({
  description : '',
  textButtonSubmit : '',
  handleClick : () => {} ,
  handleClickOut : () => {},
  oneButtons : false,
  title: '',
  type : '',
  open : false
})


  useEffect(()=> {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar escala de valoración",
      index: 11,
      path: `${PATH_APP}/criteria-create`,
      textIsSubmit: "CREAR",
      module: "CRITERIA_EVALUATION",
    });
    getAllCriterias()
    window.scrollTo(0,0)
  },[])

  useEffect(() =>{
    if(criteriaSearch?.isFilter){
      getAllCriterias()
    }
  },[criteriaSearch?.isFilter])

  const resetTable= () => {
    return setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
  }
  
  const resetDispatch = () => {
    return dispatch({
      type: "SET_FILTER_CRITERIA",
      filter: {page: 0, size:10},
      isFilter: true,
    })
  }

  const changeStateDispatch = () => {
    return dispatch({
      type: "SET_FILTER_CRITERIA",
      filter: {...criteriaSearch?.filter},
      isFilter: false,
    })
  };

  const notFound = () => {
    changeStateDispatch();
    return setMessage({
      open : true,
      description: 'No se encontró información relacionada a la búsqueda',
      handleClick : () => notFoundActions() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
  };

  const notFoundActions = () => {
    setMessage({...message, open:false});
    closeModalAndClear();
    dataFilter()
  }

  const closeModalAndClear = () => setClearFielForm(!clearFielForm);
  const closeError = () => setResetError(!resetError);

  const closeModal = () => {
    setMessage({...message, open:false}); 
    closeError();
  }

  const dataFilter = () => {
      resetDispatch()
      setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
  }

  const errorSistem = () => {
    return  setMessage({
      open : true,
      description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
  }


  const getAllCriterias = async() => {
  
    const result = await getFilterCriteria({
        code : criteriaSearch?.filter?.code  ?? null ,
        name: criteriaSearch?.filter?.criteria  ?? null,
        status: criteriaSearch?.filter?.status  ?? null,
        page: criteriaSearch?.filter?.page  ?? 0,
        size:  criteriaSearch?.filter?.size  ?? 10,
        
    },
    {orderBy: 'createdAt'},
    dispatch
    );
   if(result?.status === 204 && !criteriaSearch?.typeAction){
    return notFound();
   }
   if(result?.data?.content?.length < 1 && !criteriaSearch?.typeAction){
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
    return notFound()
   }
  if ((result && result.status === 200 )|| result.status === 201) {
    const data = result?.data;
    if (data?.content?.length > 0) {
      setPaginadorResponse({
        data: data?.content,
        totalRows: data?.totalElements,
        pageSize: data?.size,
        pageNumber: data?.number,
      });
    }
    return changeStateDispatch()
    
  } else {
    errorSistem()
    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize: 0,
      pageNumber: 0,
    });
  }
  }


  const changePage = (event, nuevaPagina) => {
    return dispatch({
      type: "SET_FILTER_CRITERIA",
      filter: {...criteriaSearch?.filter, page: nuevaPagina},
      isFilter: true,
    })
  };

  const changeAmountOfRecords = (event, siguiente) => {
    return dispatch({
      type: "SET_FILTER_CRITERIA",
      filter: {...criteriaSearch?.filter, page: 0, size: event?.target.value},
      isFilter: true,
    })
  };
  

  return (
    <>
      <Search 
        dataFilter={dataFilter}
        clearFielForm={clearFielForm}
        resetTable={resetTable}
        resetError={resetError}
        />
     
     
      {loadingTables?.active ?
        <Box sx={{ display: 'flex', marginTop:'15%', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
          : 
        <Table 
          data={paginadorResponse?.data}
          message={message}
          setMessage={setMessage}
        />
      }
      {paginadorResponse?.totalRows !== 0 && !loadingTables?.active &&
        <TablePagination
            style={{ display: "flex", justifyContent: 'center', color: '#747578' }}
            component="div"
            rowsPerPageOptions={[5, 10, 15]}
            count={paginadorResponse.totalRows}
            rowsPerPage={criteriaSearch?.filter?.size ?? 10}
            page={criteriaSearch?.filter?.page ?? 0}
            labelRowsPerPage='Registros por página'
            onPageChange={changePage}
            onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
        />
      }
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
    </>
  )
}

export default Index