import { saveToken, loadState  } from '../../validators/localStorage';
export const initialState = loadState() || {
    token:'',
}

const tokenSessionReducer = (state = initialState, action) => {
    if (action.type === "LOGIN_TOKEN" || action.type === "LOGOUT") {
        saveToken({
            ...state,
            token: action?.token, 
        });
    }
    switch (action.type) {
        case "LOGIN_TOKEN":
            return {
                ...state,
                token: action?.token, 
            };
        case "LOGOUT":
            return {
                ...state,
                token: action.session,
            };

        default:
            return state;
    }


};

export default tokenSessionReducer;