import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
    wrapper: {
        [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
          maxWidth: 1000,
          minWidth: 1000,
          maxHeight: 600,
          borderRadius: 16
      },
      [`& .jss59 .css-uhb5lp`] : {
        maxWidth: 1000,
        minWidth: 1000,
        maxHeight: 600,
        borderRadius: 16
      },
      [`& .css-uhb5lp`]: {
        borderRadius: 12,
        maxWidth: 1000,
        maxHeight: 600,
        minWidth: 1000,
      }
      },
    title: {
        textAlign: 'center',
        fontSize: 22,
        fontWeight: 700,
        background: '#dfe7fd'
    },
    content: {
        overflowY: 'hidden',
    },
 
})


