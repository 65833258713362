import React, { useState, useEffect } from "react";
import * as IdKey from "short-uuid";
import { Button } from "@mui/material";
import {styles} from "./styles";
import { useParams, useHistory} from "react-router-dom";
import { useStateValue } from './../../../../../context/store';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModalEvaluators from "../../listEvaluators"
import { PATH_APP, typesEvaluators } from "../../../../../constants";
import { evaluatorsByEvaluated } from "../../../../../actions/ManagementAplicationEvaluations-actions";
import { showMessageWithOutAction } from "../../../../../utils/constants";

export default function DetailedAccordion({
  setMessage,
  message,
  data,
}) {
  const classes = styles();
  const id = IdKey.generate();
  const params = useParams();
  const history = useHistory();
  const {applicationId, evaluatedId} = params

  const [, dispatch]= useStateValue()
  const [openEvaluators, setOpenEvaluators] = useState(false);
  const [currentEvaluators, setCurrentEvaluators] = useState([]);
  const [, setEvaluated] = useState();


  const handleClickOut = () => {setOpenEvaluators(false); setCurrentEvaluators([]); history.push(`${PATH_APP}/management-aplication-evaluateds/${applicationId}`)}
  const showEvaluators = async(applicationId, identification, program) => {

    await evaluatorsByEvaluated(applicationId, identification, program, dispatch)
    .then( (res) => {
      if(res?.status === 204){
        return showMessageWithOutAction(dispatch, "No existen evaluadores para este evaluado") 
      }else {
        setCurrentEvaluators(res?.data)

        setOpenEvaluators(true)
        history.push(`${PATH_APP}/management-aplication-evaluateds/${applicationId}/${identification}`)
      }
    })
    .catch((err) => {
      return showMessageWithOutAction(dispatch, "Ocurrio un error al obtener el listado de evaluadores") 
    })
  }

 

  // useEffect(()=> {
  //   if(evaluatedId){
  //     const filterEvaluators = data?.find((x)=> x?.evaluated?.uuid === evaluatedId)
  //     setCurrentEvaluators(filterEvaluators?.evaluatorList)
  //     setOpenEvaluators(true)
  //   }
  // },[data,evaluatedId])

  const descargar = (ev) => {

    return dispatch({
      type: 'SEND_DATA_TO_PAGE',
      data: {evaluated: ev}
    })
  
};


if(data?.length) {

  return (
    <div className={classes.root}>
      {/* TODO: PENDIENTE REVISION VISUAL */}
      <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"30%"} className={classes.wrapper}>
                  Nombre del evaluado
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Programa
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Cargo
                </TableCell>
                <TableCell align="left" width={"30%"} className={classes.wrapper}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, idColumn = id) => (
                <TableRow key={idColumn}>
                  <TableCell align="left" width={"30%"} className={classes.header}
                    style={{
                      borderTopLeftRadius: idColumn === 0 ? 10 : 0,
                      borderBottomLeftRadius: idColumn === row?.length - 1 ? 10 : 0
                    }}>
                    {`${row?.name ? row?.name : ''} ${row?.secondName ? row?.secondName : ''} ${row?.surname ? row?.surname : ''} ${row?.secondSurname ? row?.secondSurname : ''}`}

                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    {row?.regionalName}
                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    {typesEvaluators[row?.typeEvaluator]}
                  </TableCell>
                  <TableCell align="left" width={"30%"} className={classes.header}
                    style={{
                      borderTopRightRadius: idColumn === 0 ? 10 : 0,
                      borderBottomRightRadius: idColumn === row?.length - 1 ? 10 : 0
                    }} >
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      onClick={() => { showEvaluators(row?.performanceEvaluationApplicationId, row?.identificationNumber, row?.programId); }}
                    >
                      VER EVALUADOR
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      disabled={!row?.resultAlready}
                      onClick={() => { descargar(row?.evaluated); history.push(`${PATH_APP}/management-aplication-evaluateds/${applicationId}/${row?.evaluated?.uuid}/pdf`) }}
                    >
                      RESULTADO
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {evaluatedId ? <ModalEvaluators 
        open={openEvaluators}
        handleClick={handleClickOut}
        evaluators={currentEvaluators}
        idEvaluated={evaluatedId}
        setMessage={setMessage}
        message={message}
      /> : null } 

    </div>
  );
      }else {
        return null;
      }
}
