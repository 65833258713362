import React from 'react';
import {Grid,
    FormControl,
    TextField,
} from "@mui/material";
import {styles} from "./styles"

const Index = (props) => {
    const classes = styles();

        
    return  <Grid container spacing={1} justifyContent="center" direction="column"  style={{ margin: '1.5% auto', display: 'flex', justifyContent: 'flex-start' }} >
     {props?.valuesOptions?.map((x, index) => { 
      return  <Grid item xs={12} sm={12} md={12} lg={9} style={{display: 'flex', alignItems: 'center', marginBotton: 20}}>
            <FormControl variant='outlined' fullWidth size="small">
                <TextField
                disabled={true}
                className={classes.dropdown}
                fullWidth
                multiline
                maxRows={14}
                value={x?.key}
                size="small"
              />
            </FormControl>
       </Grid> 

     })}
   </Grid>
};

export default Index;
