import React,{useEffect} from 'react'
import { useStateValue } from '../../context/store'
import FormHeader from "./formHeader"
import { PATH_APP } from '../../constants';

const Index = (props) => {
    const [, dispatch] = useStateValue();

    useEffect(() => {
      dispatch({
        type: "SET_BREADCRUMB",
        isSubmit: true,
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Crear modelo de evaluación",
        module: "EVALUATION_EVALUATOR",
        idForm: 'submitRelations',
        subPath: `${PATH_APP}/evaluation-performance-evaluator`,
        index: 12,
        textIsSubmit: "CREAR",
        disabledSubmit: true,
      });
        
        window.scrollTo(0,0)
      }, [])
  return (
    <FormHeader props={props}/>
  
  )
}

export default Index