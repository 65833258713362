import { createTheme } from "@mui/material/styles";
import { esES } from '@mui/material/locale';

const theme = createTheme({
  palette: {
    background: {
      default: "#E0F2F7"
    },
    primary: {
      light: "#63a4fff",
      main: "#12B3F9",
      dark: "#124387",
      contrastText: "#DFEAFF",
    },
    warning : {
      main: "#FFDD81",
      light : "#ff9800",
      dark : "#f57c00"
    },
  },
},esES);
// 
export default theme;
