import React, {useState, useEffect} from 'react'
import {
     Grid,
     TextField, 
     Select, 
     InputLabel, 
     FormControl, 
     FormHelperText, 
     MenuItem,
     Button,
} from '@mui/material'
import { useStateValue } from "../../../context/store";
import {styles} from './styles';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SearchCompetencies = (props) => {


    const classes = styles()
    const [{behaviorSearch, breadCrumb}, dispatch] = useStateValue();
    const [valuesForm, setValuesForm] = useState({
        status: '',
        code: '',
        behavior: ''
    })


    const schema = yup.object().shape({
        behavior: valuesForm?.behavior ? yup
        .string()
        .max(2000, 'Ingrese máximo 2000 caracteres') : null,
        code: valuesForm?.code ? yup
        .string()
        .max(6, "Ingrese máximo 6 caracteres")
        .matches(  
            /^([0-9]{0,6})$/,
           "Código no válido") : null,
        status: null
      });
    
      
      const { register, handleSubmit, setValue, trigger, unregister, watch, formState:{errors,} } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });
      

      const onSubmit = (data) => {

        if(
            data?.code !== '' ||
            valuesForm.status !== '' ||
            data?.behavior !== ''
        ){
            dispatch({
                type: "SET_FILTER_BEHAVIOR",
                filter: {
                    code: data?.code,
                    status: valuesForm.status,
                    question: data?.behavior,
                    page: 0,
                    size: behaviorSearch?.filter?.size ? behaviorSearch?.filter?.size : 10
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "SET_FILTER_BEHAVIOR",
                filter: {
                    question: null,
                    status: null,
                    code: null,
                 
                  },
                  isFilter: true,
            })
        }
      }

      const clear = () => {
        setValue('behavior', '')
        setValue('code', '')
        unregister(['code', 'status'])
        setValue('status', '')
        setValuesForm({...valuesForm, status: '', code: '', behavior: ''})
      }

      useEffect(() => {
        clearForm()
      }, [props?.clearFielForm]);

      useEffect(() => {
        clearFieldsError()
      }, [props?.resetError])

    const clearFieldsError = () => { 
        clear();
        resetDispatch();
        props?.resetTable()
    }

    const resetDispatch = () => {
        return dispatch({
            type: "SET_FILTER_BEHAVIOR",
            filter: {
                behavior: null,
                status: null,
                code: null,
                page: 0,
                size: 10
              },
              isFilter: false,
          })
    };

    const clearForm = () => {
        clear();
        resetDispatch();
        props.dataFilter();
    }
        


        const handleOnchange = (e) => {
            const {name, value} = e.target;
            setValuesForm({...valuesForm, [name]:value})
            setValue(name, value)
            trigger(name)
        }

        useEffect(()=> {
            if(breadCrumb?.module === "MANAGE_BEHAVIOR" ){
                setValuesForm({
                    ...valuesForm,
                    code: behaviorSearch?.filter?.code,
                    status: behaviorSearch?.filter?.status,
                    behavior: behaviorSearch?.filter?.question,
                })
                setValue('status', behaviorSearch?.filter?.status)
                setValue('behavior', behaviorSearch?.filter?.question)
                setValue('code', behaviorSearch?.filter?.code)
                setValue('status', behaviorSearch?.filter?.status)
            }

        },[breadCrumb?.module === "MANAGE_BEHAVIOR"])

        const validate = valuesForm.status || watch('code') || valuesForm?.behavior

    
    return (
         <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container spacing={2} style={{marginBottom: 20, display: 'flex', alignItems: 'flex-start'}}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <TextField
                        {...register('behavior')}
                        className={classes.dropdown}
                        fullWidth
                        type="text"
                        onChange={handleOnchange}
                        InputLabelProps={{
                            shrink: valuesForm?.behavior?.toString() ? true : false,
                            notched: valuesForm?.behavior?.toString() ? 'true' : 'false',
                        }}
                        multiline
                        maxRows={20}
                        size="small"
                        label="Aspecto"
                        error={!!errors.hasOwnProperty("behavior") && errors["behavior"].message}
                    />
                    </FormControl>
                <FormHelperText style={{color:'red', marginLeft: '1%'}}>
                    {errors.hasOwnProperty("behavior") &&
                        errors["behavior"].message}
                </FormHelperText> 
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register("code")}
                    name="code"
                    className={classes.dropdown}
                    fullWidth
                    onChange={handleOnchange}
                    InputLabelProps={{
                        shrink: valuesForm?.code?.toString() ? true : false,
                        notched: valuesForm?.code?.toString() ? 'true' : 'false',
                    }}
                    size="small"
                    label="Código"
                    error={!!errors.hasOwnProperty("code") && errors["code"].message}
                    />
                </FormControl>
                <FormHelperText style={{color:'red', marginLeft: '4%'}}>
                    {errors.hasOwnProperty("code") &&
                        errors["code"].message}
                </FormHelperText> 
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} >
                <FormControl variant='outlined' fullWidth size="small">
                    <InputLabel error={!!errors.hasOwnProperty("status") && errors["status"].message}>Estado</InputLabel>
                        <Select
                        {...register('status')}
                        name='status'
                        value={valuesForm.status}
                        onChange={handleOnchange}
                        shrink='true'
                        status="Estado"
                        className={classes.dropdown}
                        label="Estado "
                        error={!!errors.hasOwnProperty("status") && errors["status"].message}
                    >
                        <MenuItem value='ACTIVE' key={1}>Activo</MenuItem>
                        <MenuItem value='INACTIVE' key={2}>Inactivo</MenuItem>
                    </Select>
                    <FormHelperText style={{color:'red'}}>
                    {errors.hasOwnProperty("status") &&
                        errors["status"].message}
                    </FormHelperText> 
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} className={classes.buttons}>
                    <Button 
                    variant="contained" 
                    type="submit" 
                    disableElevation 
                    disabled={!validate}
                    color="primary"
                    >
                        BUSCAR
                    </Button>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 
                    <Button 
                    variant="outlined" 
                    onClick={() =>clearForm()} 
                    disableElevation 
                    style={{background: "#ffffff"}}
                    >
                         Limpiar
                    </Button>

                </Grid>
            </Grid>
        </form>
    )
}

export default SearchCompetencies
