import  React,{useState, useEffect} from 'react';
import { 
    FormControl, 
    Grid, 
    Select,
    FormHelperText,
    InputLabel, 
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useStateValue } from "../../../context/store";
import { styles } from './styles'
import { createCriteria, getCriteriaCode } from '../../../actions/CriteriaEvaluation-action';
import { PATH_APP, REQUIRED_MESSAGE } from '../../../constants';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import * as yup from "yup";


export default function Index(props) {

    const classes = styles();
  const [{ criteriaSearch }, dispatch] = useStateValue();
  const [valuesForm, setValuesForm] = useState({
    rangeFrom: '',
    rangeTo: ''
  })
  const [, setDisabledSubmit] = useState(false)


  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
  })
 

  useEffect(()=> {
    notDisabledButton()
    getCompetencies()
    window.scrollTo(0,0)
  },[])
  
  const disabledButton = () => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar escala de valoración",
      index: 11,
      subPath: `${PATH_APP}/criteria`,
      textIsSubmit: "CREAR",
      module: "CRITERIA_EVALUATION",
      isSubmit: true,
      disabledSubmit: true,
      idForm: 'formCriteria',
    });
  }

  const notDisabledButton = () => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar escala de valoración",
      index: 11,
      subPath: `${PATH_APP}/criteria`,
      textIsSubmit: "CREAR",
      module: "CRITERIA_EVALUATION",
      isSubmit: true,
      disabledSubmit: false,
      idForm: 'formCriteria',
    });
  }
  const getCompetencies = async() => {
    const result = await getCriteriaCode(dispatch)
    setValue('code', result?.data?.code +1)
  }


  const schema = yup.object().shape({
    description: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(3, 'Ingrese mínimo 3 caracteres')
    .max(1000, 'Ingrese máximo 1000 caracteres'),
    name:  yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(1, "Ingrese mínimo 1 caractere")
    .max(2000, 'Ingrese máximo 2000 caracteres'),
    status: yup
    .string()
    .required(REQUIRED_MESSAGE),
    rangeFrom: yup
    .string()
    .required('Este campo es requerido')
    .matches(
      /^[0-9]{1,2}(\.[0-9]{0,1})?$/g,
      "Número inválido"
    )
    .test("maxLenght", "Ingrese un valor entre 0 y 100", val => val <= 100 && val >= 0)
    .nullable(true),
    rangeTo: yup
    .string()  
    .required('Este campo es requerido')
    .matches(
      /^[0-9]{1,3}(\.[0-9]{0,1})?$/g,
      "Número inválido"
    )
    .test( "maxLenght", '', val =>  Number(val) > Number(valuesForm?.rangeFrom) && Number(val) <= 100)
    .nullable(true),
  })
  
  const { register, handleSubmit, trigger, formState:{errors}, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });


  const handleCloseModal = () => setMessage({message, open:false})
  const submitAndCloseModal = () => { 
    setDisabledSubmit(false)
    handleCloseModal()
    notDisabledButton()
  }
 

  const handleCloseModalAndReset = ()=> {
    setDisabledSubmit(false)
    handleCloseModal()
    notDisabledButton()
    props?.history?.push(`${PATH_APP}/criteria`)
  
    if(criteriaSearch  ) {
      return  dispatch({
        type: "SET_FILTER_CRITERIA",
        filter: criteriaSearch?.filter,
        isFilter: true,
        typeAction: 'CREATE'
      });
    } else {
      return null
    }
   
  }


  const onSubmit = (data) => {
    disabledButton()
   const postCriteria = async() => {
    setDisabledSubmit(true)
     const result = await createCriteria(data, dispatch)
     if (result && (result?.status === 200 || result?.status === 201)) {
      setMessage({
        open : true,
        title: '¡Proceso exitoso!',
        description: 'Información almacenada correctamente ',
        handleClick : () => handleCloseModalAndReset() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'SUCCESS',
      })
     }
     else if (result?.status === 400) {
      setMessage({
        open : true,
        description: result?.data?.message ? "Existe un escala de valoración que incluye el mismo rango": 'Error al guardar la información',
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }else if (result?.status > 399) {
      setMessage({
        open : true,
        description: result?.data?.message ? "Existe un escala de valoración que incluye el mismo rango": 'Error al guardar la información',
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
     } else {
      setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => submitAndCloseModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
     }
   } 
  postCriteria()
 }

  const handleOnchange = (e) => {
    const {name, value} = e.target;
    setValuesForm({...valuesForm, [name]: value})
    setValue(name, value)
    trigger(name)
  }

  useEffect(() => {
    if( watch('rangeTo') !== '' && Number(watch('rangeTo')) <= Number(watch('rangeFrom')) ){
      setValue('rangeTo', watch('rangeTo'))
      trigger('rangeTo')
    } if(watch('rangeTo') !== '') {
      setValue('rangeTo', watch('rangeTo'))
      trigger('rangeTo')
    }
  }, [watch('rangeFrom') ])


  
  useEffect(() => {
    if( Number(watch('rangeTo')) > 100 ){
      setValue('rangeTo', watch('rangeTo'))
      trigger('rangeTo')
    }
  }, [watch('rangeTo')])

  const valuesForTotal = () => {
    if(watch('rangeTo') <= Number(valuesForm?.rangeFrom) ){
      return "Ingrese un valor mayor"
    }
    if(watch('rangeTo')  > 100 ){
      return "Ingrese un valor menor a 100"
    }
  }
  

 
  return (
        <form id="formCriteria" onSubmit={handleSubmit(onSubmit)}>
          <Grid container  alignItems="flex-start" spacing={3}>
            <Typography id="alert-dialog-title" style={{fontSize: 14, fontWeight: 500, marginLeft: '2%', marginTop: '1%', marginBottom: '-1%'}}>
              Crear escala de valoración
            </Typography>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{display: errors[`name`] ? 'flex' : null}}>
              {errors[`name`] ? <ErrorOutlineIcon color="error"  style={{visibility: errors[`name`] ? "initial": "hidden"}} fontSize="small" className={classes.iconError}/> : null}
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('name')}
                    className={classes.dropdown}
                    fullWidth
                    type="text"

                    InputLabelProps={{
                        shrink: watch('name')? true : false,
                        notched: watch('name')? true : false,
                    }}
                    multiline
                    maxRows={20}
                    size="small"
                    label="Escala de valoración"
                    error={!!errors.hasOwnProperty("name") && errors["name"].message}
                />
                 <FormHelperText style={{color:'#d32f2f'}}>
                    {errors.hasOwnProperty("name") &&
                      errors["name"].message}
                  </FormHelperText> 
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <FormControl variant='outlined' fullWidth size="small">
                  <TextField
                  {...register('code')}
                  className={classes.dropdown}
                  fullWidth
                  disabled
                  notched={watch('code') ? "true" : 'false'}
                  InputLabelProps={{ shrink: watch('code') ? true : false, }}
                  size="small"
                  label="Código"
                  />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2.5} style={{display: errors[`rangeFrom`] ? 'flex' : null}}>
              {errors[`rangeFrom`] ? <ErrorOutlineIcon color="error"  style={{visibility: errors[`rangeFrom`] ? "initial": "hidden"}} fontSize="small" className={classes.iconErrorRange}/> : null}
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('rangeFrom')}
                    className={classes.dropdown}
                    fullWidth
                    type="text"
                    onChange={handleOnchange}
                    InputLabelProps={{
                        shrink: watch('rangeFrom')? true : false,
                        notched: watch('rangeFrom')? true : false,
                    }}
                    size="small"
                    label="Desde"
                    error={!!errors.hasOwnProperty("rangeFrom") && errors["rangeFrom"].message}
                />
                 <FormHelperText style={{color:'#d32f2f'}}>
                    {errors.hasOwnProperty("rangeFrom") &&
                      errors["rangeFrom"].message}
                  </FormHelperText> 
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2.5} style={{display: errors[`rangeTo`] ? 'flex' : null}}>
              {errors[`rangeTo`] ? <ErrorOutlineIcon color="error"  style={{visibility: errors[`rangeTo`] ? "initial": "hidden"}} fontSize="small" className={classes.iconErrorRange}/> : null}
                <FormControl variant='outlined' fullWidth size="small">
                    <TextField
                    {...register('rangeTo')}
                    className={classes.dropdown}
                    fullWidth
                    onChange={handleOnchange}
                    type="text"
                    InputLabelProps={{
                        shrink: watch('rangeTo') ? true : false,
                        notched: watch('rangeTo') ? true : false,
                    }}
                    size="small"
                    label="Hasta"
                    error={!!errors.hasOwnProperty("rangeTo") }
                />
                 <FormHelperText style={{color:'#d32f2f'}}>
                    {errors.hasOwnProperty("rangeTo") &&
                     valuesForTotal()}
                  </FormHelperText> 
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2.5} style={{display: errors[`status`] ? 'flex' : null}}>
             {errors[`status`] ? <ErrorOutlineIcon color="error"  style={{visibility: errors[`status`] ? "initial": "hidden"}} fontSize="small" className={classes.iconErrorSelect}/> : null}
              <FormControl variant='outlined' fullWidth size="small">
                <InputLabel error={!!errors.hasOwnProperty("status") && errors["status"].message}>Estado</InputLabel>
                    <Select
                    {...register('status')}
                    name='status'
                    onChange={handleOnchange}
                    shrink={true}
                    className={classes.dropdown}
                    label="Estado "
                    error={!!errors.hasOwnProperty("status") && errors["status"].message}
                >
                    <MenuItem value='ACTIVE' key={1}>Activo</MenuItem>
                    <MenuItem value='INACTIVE' key={2}>Inactivo</MenuItem>
                </Select>
                  <FormHelperText style={{color:'#d32f2f'}}>
                    {errors.hasOwnProperty("status") &&
                      errors["status"].message}
                  </FormHelperText> 
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <div style={{display: 'flex'}}>
            { errors[`description`] ? 
                <ErrorOutlineIcon 
                  color="error" 
                  style={{visibility: errors[`description`] ? "initial": "hidden"}} 
                  fontSize="small" 
                  className={classes.iconErroDescription}
                /> : null 
            }
                <FormControl variant='outlined' fullWidth size="small">
                  <>
                    <TextField
                    {...register('description')}
                    className={classes.dropdownMultiple}
                    fullWidth
                    type="text"
                    InputLabelProps={{
                        shrink: watch('description')? true : false,
                        notched: watch('description')? true : false,
                    }}
                    multiline
                    maxRows={12}
                    // minRows={7}
                    rows={7}
                    size="small"
                    label="Descripción"
                    error={!!errors.hasOwnProperty("description") && errors["description"].message}
                />
                 <div className={classes.accountant}>{`${watch('description')?.length ? watch('description')?.length : 0}/1000`}</div>
                 </>
                </FormControl>
                </div>
                <FormHelperText style={{color:'#d32f2f', marginLeft: '2%'}}>
                    {errors.hasOwnProperty("description") &&
                        errors["description"].message}
                </FormHelperText> 
            </Grid>
          </Grid>
          <ModalConfirm 
            description = { message.description }
            textButtonSubmit = { message.textButtonSubmit }
            handleClick = { message.handleClick }
            handleClickOut = { message.handleClickOut }
            oneButtons = { message.oneButtons }
            title = { message.title }
            type = { message.type }
            open = { message.open }
          />
        </form>
  );
}
