import React,{useState, useEffect} from 'react';
import { Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { styles } from "./styles";
import DeleteIcon from '@mui/icons-material/Delete';
import { useStateValue } from '../../../../../../context/store';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import _ from "lodash"

const Index = (props) => {
    const classes = styles();
    const [{relations}, dispatch] = useStateValue()
    const [, setCheckeds] = useState([])
    const [showText, setShowText] = useState([])

    const showQuestion = (data, id) => {
    const result = showText.find((x)=> x?.id === id)
    if(result?.show) {  
        return data 
    } else {
        return `${data.substring(0, 150)}` 
    }
    }
      
    const allShow = (id) => {
        const result = showText.find((x)=> x?.id === id)
        if(result?.show) {
        setShowText([{show: false, id: id}])
        }else {
        setShowText([{show: true, id: id}])
        }
    }
    
    const validateShowText = (id) => {
        const result = showText.find((x)=> x?.id === id)
        return result?.show ? true : false
    }


    useEffect(()=> { 
        if(props?.idCompetencie === relations?.competencieSelected){
            return null
        }else {
            setCheckeds([])
        }
    },[relations?.competencieSelected])

    const deleteQuestions = (q) => {

        let differentCompetences = relations?.data?.filter((x)=> x?.uuid !== props?.competenceId)
        let findCompetence = relations?.data?.find((x)=> x?.uuid === props?.competenceId)
        let differentBehavior = _.filter(findCompetence?.behaviors, (o)=> o?.uuid !== props?.behaviorId)
        let findBehavior = findCompetence?.behaviors.find((x)=> x?.uuid === props?.behaviorId)
        const differentQuestions = findBehavior?.questions?.filter((x)=> x?.uuid !== q)
        
        const updateBehavior = {...findBehavior, questions: _.orderBy(differentQuestions, ['position'], ['asc'])}
        differentBehavior.push(updateBehavior)
        const updateCompetence = {...findCompetence, behaviors: _.orderBy(differentBehavior, ['position'], ['asc']) }
         differentCompetences.push(updateCompetence)

        const orderCompetencies = _.orderBy(differentCompetences, ['position'], ['asc']) 
        dispatch({
            type: 'SET_RELATIONS_EVALUATION',
            data: orderCompetencies,
            dataSections: relations?.dataSections,
            sectionSelected: relations?.sectionSelected,
            competencieSelected: relations?.competencieSelected,
            behaviorSelected: relations?.behaviorSelected
        })
    }   

    const addRol = (q,rol) => {
        if(props?.modelDetail || props?.isCopy) return;
        let differentCompetences = relations?.data?.filter((x)=> x?.uuid !== props?.competenceId)
        let findCompetence = relations?.data?.find((x)=> x?.uuid === props?.competenceId)
        let differentBehavior = _.filter(findCompetence?.behaviors, (o)=> o?.uuid !== props?.behaviorId)
        let findBehavior = findCompetence?.behaviors.find((x)=> x?.uuid === props?.behaviorId)
        const differentQuestions = findBehavior?.questions?.filter((x)=> x?.uuid !== q)
        let findQuestion = findBehavior?.questions?.find((x)=> x?.uuid === q)
        if(findQuestion?.rols?.length){
          const exisRol = findQuestion?.rols?.find((x) => x === rol)
          if(exisRol){
            const exisRol = findQuestion?.rols?.filter((x) => x !== rol)
            findQuestion.rols = exisRol
          }else {
            findQuestion?.rols?.push(rol)
          }
        }else {
            findQuestion.rols = [rol]
        }
        differentQuestions.push(findQuestion)
        const updateBehavior = {...findBehavior, questions: _.orderBy(differentQuestions, ['position'], ['asc'])}
        differentBehavior.push(updateBehavior)
        const updateCompetence = {...findCompetence, behaviors: _.orderBy(differentBehavior, ['position'], ['asc']) }
         differentCompetences.push(updateCompetence)

        const orderCompetencies = _.orderBy(differentCompetences, ['position'], ['asc']) 
        dispatch({
            type: 'SET_RELATIONS_EVALUATION',
            data: orderCompetencies,
            dataSections: relations?.dataSections,
            sectionSelected: relations?.sectionSelected,
            competencieSelected: relations?.competencieSelected,
            behaviorSelected: relations?.behaviorSelected
        })
    }   


  return (
        <>
            {props?.data?.length ? _.orderBy(props?.data, ['position'], ['asc'])?.map((x, index)=> (
                <>
                    <Grid item container lg={9} className={classes.questions } style={{borderBottom: props?.data?.length === index+1 && '0px'}}>
                        <Grid item container lg={10}>
                            <Typography component={'span'} style={{margin: '10px 0px 0px 10px'}}className={classes.heading} >
                                {x?.value?.length > 150 ? showQuestion(x?.value, index) :  x?.value}{x?.value?.length > 150 && 
                                <Typography variant="caption"
                                    className={classes.expandText}
                                    onClick={()=> allShow(index)}>{validateShowText(index) ? "...ver menos" : "...ver más" }</Typography>}
                            </Typography >
                        </Grid>
                        {props?.modelDetail || props?.isCopy 
                            ? null 
                            : <Grid item lg={2} style={{ textAlign: 'end'}}>
                                <Tooltip title="Eliminar pregunta" style={{padding: 0}} arrow>
                                    <IconButton>
                                        <DeleteIcon onClick={()=> deleteQuestions(x?.uuid)}/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }   
                    </Grid>
                    {/* <Grid item lg={2} className={classes.checkbox}  style={{borderBottom: props?.data?.length === index+1 && '0px'}}>{props?.data?.length }  {index}</Grid> */}
                    <Grid item container lg={3} flexDirection="row" justifyContent="space-between">
                        {props?.typesEvaluators?.map((t)=> (
                            <Grid item lg={12/props?.typesEvaluators?.length} 
                            className={classes.checkbox}  
                            style={{borderBottom: props?.data?.length === index+1 && '0px'}}
                            onClick={()=> addRol(x?.uuid, t?.id)}
                        >
                            {x?.rols?.includes(t?.id) && <DoneAllIcon color='success'/>}
                        </Grid>
                        ))}
                    </Grid>
                </>
            )) : <>
                <Grid item lg={9} className={classes.containerquestion } ></Grid>
                {/* <Grid item lg={2} className={classes.containerquestion }>{props?.data?.length }</Grid> */}
                <Grid item container lg={3} flexDirection="row" justifyContent="space-between">
                {props?.typesEvaluators?.map((t)=> (
                           <Grid item lg={12/props?.typesEvaluators?.length} className={classes.containerquestion }></Grid>
                ))}
                </Grid>
            </>
            }
        </>
  )
}

export default Index
