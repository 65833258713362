import React,{useEffect, useState} from 'react'
import { useStateValue } from '../../../context/store';
import Search from "./search";
import Table from "./table";
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import {Box, Grid} from '@mui/material';
import ModalErrorAction from "../../../components/common";
import ModalConfirm from "../../../utils/form/modalConfirmBase";
import NotFoundImg from "../../../images/Search.png";
import { getFilterEvaluationPerformanceEvaluated, getStatesEvaluator } from "../../../actions/Performance_Evaluation-action";

const Index = (props) => {
  const [{loadingTables, answerEvaluation}, dispatch] = useStateValue();
  const [clearFielForm, setClearFielForm]= useState(false);
  const [clearInError, setClearInError]= useState(false);
  const [dataSelect, setDataSelect] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [data, ] = useState({
    nameKeyword: null,
    status: null,
    evaluationPeriod: null
   })
  const [paginadorResponse, setPaginadorResponse] = useState({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  const [paginadorRequest, setPaginadorRequest] = useState({
    page: 0,
    size: 10,
  });
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
  })

    useEffect(() => {

        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Gestionar modelo de evaluación",
          index: 12,
          module: 'EVALUATION_EVALUATED',
        });
          getAllEvaluationPerformance()
        window.scrollTo(0,0)
          
      }, [paginadorRequest])

      useEffect(() => {
        window.scrollTo(0,0)
        getStatusSelect()
      }, [])
   
      const closeModalAndClear = () => setClearFielForm(!clearFielForm)
      const closeModal = () => {setMessage({...message, open:false}); closeModalAndClear()}
      const dataFilter = () => setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });

      const notFoundData = (message) => {
        return setMessage({
          open : true,
          description: message,
          handleClick : () => closeModal() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      };

      const clearFields = () => setClearInError(!clearInError)

      const errorSistem = (message) => {
        return setMessage({
          open : true,
          description: message,
          handleClick : () => {setMessage({...message, open:false}); clearFields()},
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      };
    
      const getAllEvaluationPerformance = async() => {
        setNotFound(false);
        dispatch({
          type: "SET_FILTER_ANSWER_EVALUATION",
          filter: {
              nameKeyword: answerEvaluation?.filter?.nameKeyword,
              status: answerEvaluation?.filter?.status,
              evaluationPeriod: answerEvaluation?.filter?.evaluationPeriod,
              page: !answerEvaluation?.filter?.page ? 0  : answerEvaluation?.filter?.page,
              size: !answerEvaluation?.filter?.size ? 10  : answerEvaluation?.filter?.size
            },
            isFilter: false,
        })
           await getFilterEvaluationPerformanceEvaluated({
          evaluationYear : answerEvaluation?.filter?.evaluationPeriod === null ? null : answerEvaluation?.filter?.evaluationPeriod,
          nameKeyword: answerEvaluation?.filter?.nameKeyword === null ? null : answerEvaluation?.filter?.nameKeyword,
          status: answerEvaluation?.filter?.status === null ? null : answerEvaluation?.filter?.status,
          page: !answerEvaluation?.filter?.page ? 0  : answerEvaluation?.filter?.page,
          size:  !answerEvaluation?.filter?.size ? 10  : answerEvaluation?.filter?.size,
            
        },
        {orderBy: 'createdAt'},
        dispatch
        ).then((result) => {

          if(result?.status === 204 ){
      
            return errorSistem('No se encontró información relacionada a la búsqueda');
           }
           if(result?.status === 400 ){
            return notFoundData('No se encontró información relacionada a la búsqueda');
           }
           if(result?.data?.content?.length < 1 && result?.status === 200){
            setNotFound(true);
            return setPaginadorResponse({
              data: [],
              totalRows: 0,
              pageSize: 0,
              pageNumber: 0,
            });
           }
          if ((result && result.status === 200) || (result.status === 201)) {
            const data = result?.data;
            if (data?.content?.length > 0) {
              setPaginadorResponse({
                data: data?.content,
                totalRows: data?.totalElements,
                pageSize: data?.size,
                pageNumber: data?.number,
              });
            }
            
          }
        }).catch((error)=> {

          setPaginadorResponse({
            data: [],
            totalRows: 0,
            pageSize: 0,
            pageNumber: 0,
          });
          return errorSistem('Occurió un problema con el servidor por favor vuelva a intentar.');

        })

      }

    const getStatusSelect = async() => {
      const result = await getStatesEvaluator()
      return setDataSelect(result?.data?.length ? result?.data :[])
    }
    

    
      useEffect(()=> {
        if(answerEvaluation &&
          answerEvaluation?.isFilter) {
            const newData = answerEvaluation?.filter
            data.nameKeyword = newData?.nameKeyword
            data.evaluationPeriod = newData?.evaluationPeriod;
            data.status = newData?.status;
            paginadorRequest.page = newData?.page;
            paginadorRequest.size = newData?.size
            dispatch({
              type: "SET_FILTER_ANSWER_EVALUATION",
              filter: answerEvaluation?.filter,
              isFilter: false,
            })
            getAllEvaluationPerformance()
        }
      },[answerEvaluation?.isFilter])
      
    
      const changePage = (event, nuevaPagina) => {
           
        setPaginadorRequest({
          ...paginadorRequest,
          page: nuevaPagina,
        });
        dispatch({
          type: "SET_FILTER_ANSWER_EVALUATION",
          filter: {
              nameKeyword: answerEvaluation?.filter?.nameKeyword,
              status: answerEvaluation?.filter?.status,
              evaluationPeriod: answerEvaluation?.filter?.evaluationPeriod,
              page: nuevaPagina,
              size: answerEvaluation?.filter?.size
            },
            isFilter: true,
        })
      };
    
      const changeAmountOfRecords = (event, siguiente) => {
        setPaginadorRequest({
          ...paginadorRequest,
          size: event.target.value,
          page: 0
        });

        dispatch({
          type: "SET_FILTER_ANSWER_EVALUATION",
          filter: {
              nameKeyword: answerEvaluation?.filter?.nameKeyword,
              status: answerEvaluation?.filter?.status,
              evaluationPeriod: answerEvaluation?.filter?.evaluationPeriod,
              page: 0,
              size: event.target.value
            },
            isFilter: true,
        })
      };
  

  return (
    <Grid container >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Search 
          dataFilter={dataFilter}
          clearFielForm={clearFielForm}
          setMessage={setMessage}
          message={message}
          dataSelect={dataSelect}
          clearFields={clearInError}
          />
      </Grid>
   
    {loadingTables?.active ?
      <Box sx={{ display: 'flex', marginTop:'15%', justifyContent: 'center' }}>
          <CircularProgress />
      </Box>
        : 
        <Table 
        data={paginadorResponse?.data}
        message={message}
        setMessage={setMessage}
      />
    }
    { notFound && (
        <Grid item lg={12} style={{display: 'grid', placeItems: 'center', marginTop: '3%'}}>
            <img src={NotFoundImg} alt="imagen_Login" width="25%" height="70%"/>
        </Grid>
      )
    }
    
    {paginadorResponse?.totalRows !== 0 && !loadingTables?.active &&
      <Grid lg={12} md={12} sm={12} xs={12}>
        <TablePagination
          style={{ justifyContent: 'center', display:"flex", color: '#747578' }}
          component="div"
          rowsPerPageOptions={[5, 10, 15]}
          count={paginadorResponse.totalRows}
          rowsPerPage={answerEvaluation?.filter?.size}
          page={answerEvaluation?.filter?.page}
          labelRowsPerPage='Registros por página'
          onPageChange={changePage}
          onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
        />
      </Grid>
      
    }
    <ModalConfirm 
      description = { message.description }
      textButtonSubmit = { message.textButtonSubmit }
      handleClick = { message.handleClick }
      handleClickOut = { message.handleClickOut }
      oneButtons = { message.oneButtons }
      title = { message.title }
      type = { message.type }
      open = { message.open }
    />
   
    <ModalErrorAction clear={closeModalAndClear}/>
  </Grid>
  )
}

export default Index