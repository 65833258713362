import React, { useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  Select,
  FormHelperText,
  InputLabel,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useStateValue } from "../../../context/store";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { styles } from "./styles";
import { REQUIRED_MESSAGE } from "../../../constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateCompetencie } from "../../../actions/Competencies-actions";
import * as yup from "yup";

export default function EditCompetencie(props) {
  const classes = styles();
  const [{ competencieSearch }, dispatch] = useStateValue();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [valuesForm, setValuesForm] = useState({
    status: "",
  });

  const schema = yup.object().shape({
    description: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(3, "Ingrese mínimo 3 caracteres")
      .max(1000, "Ingrese máximo 1000 caracteres"),
    name: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(3, "Ingrese mínimo 3 caracteres")
      .max(300, "Ingrese máximo 300 caracteres"),
    status: yup.string().required(REQUIRED_MESSAGE),
    code: null,
  });

  useEffect(() => {
    setValue("code", props?.data?.code);
    setValue("description", props?.data?.description);
    setValue("status", props?.data?.status);
    setValue("name", props?.data?.name);
    setValuesForm({ ...valuesForm, status: props?.data?.status });
  }, [props?.open]);
  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const handleCloseModal = () =>
    props.setMessage({ ...props.message, open: false });
  const submitAndCloseModal = () => {
    setDisabledSubmit(false);
    handleCloseModal();
  };

  const handleCloseModalAndReset = () => {
    setDisabledSubmit(false);
    props?.closeEdit();
    props?.setMessage({ ...props?.message, open: false });
    if (props?.createInQuestion) {
      props?.refrehsTags();
    }
    if (competencieSearch) {
      return dispatch({
        type: "SET_FILTER_COMPETENCIE",
        filter: competencieSearch?.filter,
        isFilter: true,
        typeAction: "EDIT",
      });
    } else {
      return null;
    }
  };

  const onSubmit = (data) => {
    const update = async () => {
      setDisabledSubmit(true);
      delete data?.code;
      const result = await updateCompetencie(props?.data?.uuid, data, dispatch);
      if (result && (result?.status === 200 || result?.status === 204)) {
        props.setMessage({
          open: true,
          title: "¡Proceso exitoso!",
          description: "Información almacenada correctamente ",
          handleClick: () => handleCloseModalAndReset(),
          oneButtons: true,
          textButtonSubmit: "ACEPTAR",
          type: "SUCCESS",
        });
      } else if (result?.status === 400) {
        props.setMessage({
          open: true,
          description: result?.data?.message,
          handleClick: () => submitAndCloseModal(),
          oneButtons: true,
          type: "WARNING",
        });
      } else if (result?.status > 399) {
        props.setMessage({
          open: true,
          description: "Error al guardar la información",
          handleClick: () => submitAndCloseModal(),
          oneButtons: true,
          type: "WARNING",
        });
      } else {
        props.setMessage({
          open: true,
          description:
            "Ocurrió un error procesando su solicitud, inténtelo nuevamente",
          handleClick: () => submitAndCloseModal(),
          oneButtons: true,
          type: "WARNING",
        });
      }
    };
    update();
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setValuesForm({ ...valuesForm, [name]: value });
    setValue(name, value);
    trigger(name);
  };

  const closeModalEdit = () => {
    clearErrors(["code", "description", "status", "name"]);
    setValue("code", "");
    setValue("description", "");
    setValue("status", "");
    setValue("name", "");
    setValuesForm({ ...valuesForm, status: "" });
    props?.closeEdit();
  };

  return (
    <div>
      <Dialog
        className={classes.widthModal}
        open={props?.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography
            id="alert-dialog-title"
            style={{ fontSize: 24, fontWeight: 400, marginBottom: "2%" }}
          >
            Editar criterio
          </Typography>
          <DialogContentText id="alert-dialog-description">
            {/* <Container maxWidth="lg"> */}
            <form id="create" onSubmit={handleSubmit(onSubmit)}>
              <Grid container maxWidth="lg" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={7.5}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <TextField
                      {...register("name")}
                      className={classes.dropdown}
                      fullWidth
                      type="text"
                      onChange={handleOnchange}
                      InputLabelProps={{
                        shrink: watch("name") ? true : false,
                        notched: watch("name") ? true : false,
                      }}
                      multiline
                      minRows={3}
                      maxRows={7}
                      size="small"
                      label="Criterio"
                      error={
                        !!errors.hasOwnProperty("name") &&
                        errors["name"].message
                      }
                    />
                  </FormControl>
                  <FormHelperText style={{ color: "red", marginLeft: "3%" }}>
                    {errors.hasOwnProperty("name") && errors["name"].message}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <TextField
                      {...register("code")}
                      className={classes.dropdown}
                      fullWidth
                      onChange={handleOnchange}
                      InputLabelProps={{
                        shrink: watch("code") ? true : false,
                        notched: watch("code") ? true : false,
                      }}
                      size="small"
                      disabled
                      label="Código"
                      error={
                        !!errors.hasOwnProperty("code") &&
                        errors["code"].message
                      }
                      helperText={
                        !!errors.hasOwnProperty("code") &&
                        errors["code"].message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2.5}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel
                      error={
                        !!errors.hasOwnProperty("status") &&
                        errors["status"].message
                      }
                    >
                      Estado
                    </InputLabel>
                    <Select
                      {...register("status")}
                      name="status"
                      value={valuesForm.status}
                      onChange={handleOnchange}
                      shrink={true}
                      // status="Estado"
                      className={classes.dropdown}
                      label="Estado "
                      error={
                        !!errors.hasOwnProperty("status") &&
                        errors["status"].message
                      }
                    >
                      <MenuItem value="ACTIVE" key={1}>
                        Activo
                      </MenuItem>
                      <MenuItem value="INACTIVE" key={2}>
                        Inactivo
                      </MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {errors.hasOwnProperty("status") &&
                        errors["status"].message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <>
                      <TextField
                        {...register("description")}
                        className={classes.dropdown}
                        fullWidth
                        onChange={handleOnchange}
                        type="text"
                        InputLabelProps={{
                          shrink: watch("description") ? true : false,
                          notched: watch("description") ? true : false,
                        }}
                        multiline
                        minRows={7}
                        maxRows={10}
                        size="small"
                        label="Descripción"
                        error={
                          !!errors.hasOwnProperty("description") &&
                          errors["description"].message
                        }
                      />
                      <div className={classes.accountant}>{`${
                        watch("description")?.length
                          ? watch("description")?.length
                          : 0
                      }/1000`}</div>
                    </>
                  </FormControl>
                  <FormHelperText style={{ color: "red", marginLeft: "3%" }}>
                    {errors.hasOwnProperty("description") &&
                      errors["description"].message}
                  </FormHelperText>
                </Grid>
              </Grid>
            </form>
            {/* </Container> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disableElevation
            onClick={() => closeModalEdit()}
            style={{
              color: "#6D9FFF",
              marginBottom: 5,
              borderColor: "#80808070",
              marginLeft: "40%",
            }}
          >
            CANCELAR
          </Button>
          &nbsp;&nbsp;
          <Button
            type="submit"
            form="create"
            disabled={disabledSubmit}
            variant="contained"
            style={{
              color: "#ffffff",
              background: "#2F71CB",
              marginBottom: 5,
              marginLeft: 5,
            }}
          >
            GUARDAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
