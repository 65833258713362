import { makeStyles } from '@mui/styles';


export const styles = makeStyles({
    backgroundDates: {
        background: '#C4C4C476',
        margin: '0px 19% 0px 30px',
        borderRadius: 4
    },
    dropdown: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        height: 40,
        fontSize: "14px",
        width: '-webkit-fill-available',
        '& ::placeholder': {
          fontSize: 14
        },
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
        '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
          background: '#dfe7fd',
        },
    
      },
      fieldDateTimeErrorSearch: {
        '& .css-ly45cz-MuiInputBase-root-MuiOutlinedInput-root':{
          background: '#ffffff',
        },
        '& .MuiOutlinedInput-notchedOutline':{
        borderRadius: 15,
  
        },
        '& .css-i8lz65':{
          background: '#ffffff',
          borderRadius: 20
        },
        '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':{
          background: '#ffffff',
          color: '#545353',
          borderRadius: 20,
          fontSize: 14
          
        }
      },
      dropdownMultiline: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        height: 40,
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
        [`& .css-2laola-MuiInputBase-root-MuiOutlinedInput-root`]: {
          backgroundColor: "#FFFFFF",
        },
        '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
          background: '#dfe7fd',
        },
        [`& .css-ktmv9s `] :{
          backgroundColor: "#FFFFFF",
        }
    
      },
      widthModal: {
        [`& .css-1t1j96h-MuiPaper-root-MuiDialog-paper`] : {
          maxWidth: 600,
          minWidth: 350,
          borderRadius: 20
      },
      [`& .css-uhb5lp`] : {
        maxWidth: 600,
          minWidth: 350,
          borderRadius: 20
      }
      },
    title: {
        fontSize: 14, 
        fontWeight: 400, 
        color: '#4B4B4B', 
        textAlign: 'center'
    }
})