import React, {useState, useEffect} from 'react'
import { Container,
     Grid,
     TextField, 
     FormControl, 
     Switch,
     IconButton,
     Typography,
     Tooltip
} from '@mui/material'
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { withStyles } from '@mui/styles';
import {styles} from '../styles';
import { useStateValue } from "../../context/store";
import { REQUIRED_MESSAGE } from '../../constants';
import { useForm } from "react-hook-form";
import { updateLabel } from '../../actions/label-actions';
import { yupResolver } from "@hookform/resolvers/yup";
import Cancel from '../../images/icons/cancel.svg'
import * as yup from "yup";

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.white,
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: 'green',
          borderColor: 'green',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.white}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.error.main,
    },
    checked: {},
  }))(Switch);

export const EditLabel = (props) => {

    const classes = styles()
    const [{ labelSearch }, dispatch] = useStateValue();
    const [activeChecked, setActiveChecked] = useState(true)
    
    const schema = yup.object().shape({
        name: yup.string()
        .required(REQUIRED_MESSAGE)
        .min(2, 'Ingrese mínimo 2 caracteres')
        .max(20, 'Ingrese máximo 20 caracteres')
        .matches(
          /^(([A-Za-z0-9]))*$/g,
          "Ingrese un nombre válido"
        ),
        status: yup.string(),
      });
    
      
      const { register, handleSubmit, formState:{errors, isDirty}, trigger,  setValue,  } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });

      useEffect(() => {
     
       setValue('status', props?.data?.status === 'ACTIVE' ? true : false)
       setActiveChecked(props?.data?.status === 'ACTIVE' ? true : false)
       setValue('name', props?.data?.name )
      }, [])


      const toggleChecked = () =>setActiveChecked(!activeChecked)  

      const handleCloseSnackbar = () => {
        dispatch({
          type: "SET_FILTER_LABEL",
          filter: labelSearch?.filter,
          isFilter: true,
          typeAction: 'EDIT'
        });
        props.closeDelete(props.index)
      } 

  const handleCloseSuccess = () => {
    props?.setIdField([])
    props.setMessage({...props.message, open:false})
    dispatch({
      type: "SET_FILTER_LABEL",
      filter: labelSearch?.filter,
      isFilter: true,
      typeAction: 'EDIT'
    });
  }


      const handleCloseModal = () => props.setMessage({...props.message, open:false})

  const  onSubmit = (data) => {
    if(!isDirty) return;
    const upgradeLabel = async()=> {
      const newData = { type: 'TAG', status: data?.status === 'false' ? 'INACTIVE': 'ACTIVE', name: data?.name}
      const result = await updateLabel(props?.data?.uuid, newData, dispatch)
      if (result && (result?.status === 200 || result?.status === 201)) {
        props.setMessage({
          open : true,
          title: '¡Proceso exitoso!',
          description: 'Información almacenada correctamente ',
          handleClick : () => handleCloseSuccess() ,
          textButtonSubmit: 'ACEPTAR',
          oneButtons : true,
          type : 'SUCCESS',
        })
        }
        else if (result?.status > 399) {
          props.setMessage({
            open : true,
            description: result?.data?.message,
            handleClick : () => handleCloseModal() ,
            oneButtons : true,
            type : 'WARNING',
          })
          handleCloseSnackbar();
        } else {
          props.setMessage({
            open : true,
            description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
            handleClick : () => handleCloseModal() ,
            oneButtons : true,
            type : 'WARNING',
          })
          handleCloseSnackbar();
        }
    }
    props.closeEdit()
    upgradeLabel()
  }

  
  const handleOnchange = (e) => {
    const {name, value} = e.target;
    setValue(name, value.toUpperCase())
    trigger(name)
  }


    return (
        <Container >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography style={{color: '#8E8E8E', fontSize: 14, fontWeight:400, marginLeft: -15}} > {props?.data?.code}</Typography>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                <FormControl variant='standard' fullWidth size="small" className={classes.borderBottom}>
                    <TextField
                    {...register('name')}
                    className={classes.dropdown}
                    fullWidth
                    variant='standard'
                    size="small"
                    onChange={handleOnchange}
                    style={{ width: 'max-content '}}
                    placeholder="Descripcion"
                    error={!!errors.hasOwnProperty("name") && errors["name"].message}
                    helperText={!!errors.hasOwnProperty("name") && <p style={{marginTop: '-2%'}}>{errors["name"].message}</p>}
                  />
                </FormControl>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} style={{  display: 'flex', justifyContent: 'center' }}>
                <Tooltip title={activeChecked ? "Inactivar" : "Activar"} arrow>
                  <AntSwitch
                  {...register(`status`)}
                  style={{ fontSize: 40, pointerEvents: 'auto'}}
                  checked={ activeChecked}
                  onClick={(event) => { event.stopPropagation(); toggleChecked() }}
                  onFocus={(event) => event.stopPropagation()}
                />
                </Tooltip>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} style={{display: 'flex', alignItems: 'center'}}> 
                <Tooltip title="Cancelar" arrow>
                  <img src={Cancel} alt=''
                     onClick={()=> props.closeDelete(props.index)}
                      className={classes.iconEventCancel}
                      style={{marginLeft: '50%'}}
                  />
                </Tooltip>
                <Tooltip title="Guardar" arrow>
                    <IconButton 
                      variant="contained" 
                      type="submit" 
                    >
                      <WebAssetIcon  className={classes.iconEvent}/>
                    </IconButton>
                </Tooltip>
                </Grid>
            </Grid>
            </form>
        </Container>
    )
}

export default EditLabel
