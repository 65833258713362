import { loadBreadCrum } from "../../validators/localStorage";
export const initialState = loadBreadCrum() || {
  breadCrumbText: "",
  breadCrumbSubText: "",
  disabledSubmit: true,
  index: 0,
  idForm: '',
  isSubmit: false,
  isButtonCreate: false,
  isSaveStartProcess: false,
  isCancelStartProcess: false,
  open: true,
  openModal: true,
  path: "",
  subPath: "",
  module: '',
  textIsSubmit: "",
  totalValueCuestionnaire: '',
  threeButton: false,
  subPathSearch: '',
  dowload: false,
  disabledDowload: false,
  textDowload: ''
};

const breadCrumbsReducer = (state = initialState, action) => {

  if (action.type === "SET_BREADCRUMB") {
    const saveBreadCrum = JSON.stringify({
      ...state,
      breadCrumbText: action.breadCrumbText,
      path: action.path,
      textIsSubmit: action.textIsSubmit,
      openModal: action.openModal,
      subPath: action.subPath,
      isSubmit: action.isSubmit,
      disabledSubmit: action.disabledSubmit,
      breadCrumbSubText: action.breadCrumbSubText,
      open: action.open,
      idForm: action.idForm,
      isButtonCreate: action.isButtonCreate,
      index: action.index,
      isSaveStartProcess: action.isSaveStartProcess,
      isCancelStartProcess: action.isCancelStartProcess,
      totalValueCuestionnaire: action.totalValueCuestionnaire,
      module: action.module,
      threeButton: action.threeButton,
      subPathSearch: action.subPathSearch,
      dowload: action.dowload,
      disabledDowload: action.disabledDowload,
      textDowload: action.textDowload
    });
    localStorage.setItem("breadCrum", saveBreadCrum);
  }

  switch (action.type) {
    case "SET_BREADCRUMB":
      return {
        ...state,
        breadCrumbText: action.breadCrumbText,
        path: action.path,
        subPath: action.subPath,
        textIsSubmit: action.textIsSubmit,
        openModal: action.openModal,
        isSubmit: action.isSubmit,
        disabledSubmit: action.disabledSubmit,
        open: action.open,
        idForm: action.idForm,
        breadCrumbSubText: action.breadCrumbSubText,
        isButtonCreate: action.isButtonCreate,
        index: action.index,
        isSaveStartProcess: action.isSaveStartProcess,
        isCancelStartProcess: action.isCancelStartProcess,
        module: action.module,
        totalValueCuestionnaire: action.totalValueCuestionnaire,
        threeButton: action.threeButton,
        subPathSearch: action.subPathSearch,
        dowload: action.dowload,
        disabledDowload: action.disabledDowload,
        textDowload: action.textDowload
      };
    default:
      return state;
  }
};

export default breadCrumbsReducer;
