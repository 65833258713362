import React, { useEffect, useState } from 'react'
import { useStateValue } from "../../context/store";
import DetailedAccordion from './tableQuestionary';
import SearchQuestionary from './search';
import ModalConfirm from "../../utils/form/modalConfirmBase";
import { getTypesQuestionnaire, getStatusQuestionnaire, getfilterQuestionarys } from '../../actions/questionary-actions'
import { PATH_APP } from '../../constants';

const Index = () => {
  const [{ questionarySearch }, dispatch] = useStateValue();
  const [listTypes, setLisTypes] = useState([])
  const [clearFielForm, setClearFielForm]= useState(false)
  const [listStatus, setListStatus] = useState([])
  const [listQuestionnaires, setListQuestionnaires] = useState([])
  const [data,] = useState({
    createdAtStartDate : null,
    createdAtEndDate: null,
    nameKeyword: null,
    status: null
  })
  

  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
    })

    const closeModalAndClear = () => setClearFielForm(!clearFielForm)

  const typesQuestionnaires = async() => {
    const result = await getTypesQuestionnaire();
    setLisTypes(result?.data)
  }

  const statusQuestionnaires = async() => {
    const result = await getStatusQuestionnaire();
    setListStatus(result?.data)
  }

  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Gestionar cuestionario",
      index: 4,
      path: `${PATH_APP}/questionary-create`,
      textIsSubmit: "CREAR CUESTIONARIO",
      module: 'MANAGE_QUESTIONNAIRE'
    });
    dispatch({
      type: "SET_MODAL_VALORATE_APLICATION",
      data: []
    })
    dispatch({
      type: "SET_VALUE_QUESTIONNAIRE",
      totalValueQuestionnaire: '',
      typeValoration: ''
    });
    if(questionarySearch?.isFilter){
      getAllQuestionnaires()
    }
    statusQuestionnaires()
    typesQuestionnaires()
    window.scrollTo(0,0)
   
  }, [])

  const closeModal = () => {
    setMessage({...message, open: false}); 
    closeModalAndClear();
}

  const getAllQuestionnaires = async() => {
    const result = await getfilterQuestionarys({
      createdAtStartDate : data.code === null ? null : data?.createdAtStartDate,
      createdAtEndDate: data.name === null ? null : data?.createdAtEndDate,
      status: data.status === null ? null : data?.status,
      nameKeyword: data.description === null ? null : data?.nameKeyword,
        
    });

    setListQuestionnaires(result?.data)
    
   if(result?.data?.length < 1 && !questionarySearch?.typeAction){
    return setMessage({
      open : true,
      description: 'No se encontró información relacionada a la búsqueda',
      handleClick : () => closeModal() ,
      oneButtons : true,
      textButtonSubmit: 'ACEPTAR',
      type : 'WARNING',
    })
   }
  
  }

  if(questionarySearch &&
    questionarySearch?.isFilter) {
   
      const newData = questionarySearch?.filter
      data.createdAtStartDate = newData?.createdAtStartDate;
      data.createdAtEndDate = newData?.createdAtEndDate;
      data.nameKeyword = newData?.nameKeyword
      data.status = newData?.status;
      getAllQuestionnaires( )
      dispatch({
        type: "SET_FILTER_QUESTIONARY",
        filter: questionarySearch?.filter,
        isFilter: false,
      })
  }
  const clearFilter = (x) => setListQuestionnaires([])
 

    return (
        <div >
            < SearchQuestionary 
              message={message}
              setMessage={setMessage}
              listTypes={listTypes}
              listStatus={listStatus}
              clearFilter={clearFilter}
              clearFielForm={clearFielForm}
            />
              < DetailedAccordion  dataQuestionary={listQuestionnaires}/>
            <ModalConfirm 
              description = { message.description }
              textButtonSubmit = { message.textButtonSubmit }
              handleClick = { message.handleClick }
              handleClickOut = { message.handleClickOut }
              oneButtons = { message.oneButtons }
              title = { message.title }
              type = { message.type }
              open = { message.open }
            />
            
        </div>
    )
}

export default Index
