import { makeStyles } from '@mui/styles';


export const styles = makeStyles({
    dropdown: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "8px",
        height: 40,
        fontSize: "14px",
        [`& fieldset`]: {
          borderRadius: 8,
          fontSize: 14,
        },
        '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root':{
          background: '#dfe7fd',
        },
    },
    messageInformative: {
      fontSize: 14,
      margin: '2% 0px 0px 10%',
      color: '#7c7575'
    },
    question: {
      fontSize: 14,
      fontWeight: 400,
    }
})