import React, {useState, useEffect} from 'react';
import {Grid,
    FormControl,
    TextField,
    Checkbox,
} from "@mui/material";
import {styles} from "./styles"
import { useStateValue } from "../../../../../context/store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PATH_APP } from '../../../../../constants';

const Index = (props) => {
    const classes = styles();
    const [{ valorationQuestionnaire, valorateAplication, disabledQuestionary, dataAplication },  dispatch] = useStateValue();
    const [checked, setChecked] = useState({})
    const [valueQuestion, setValueQuestion] = useState()


    const schema = yup.object().shape({
     
      valueTotalQuestion: dataAplication?.data?.associatedQuestions?.length === 1 ? 
      yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,3}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
      .test("maxLenght", "Ingrese un valor entre 1 y 100", val => val <= 100 && val >= 1)
    : 
      yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,2}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
    })


    const { register, handleSubmit, trigger,  formState:{errors}, setValue, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });

    const handleChecked = (e) => {
        const { name} = e.target;
        const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)
        if(checked?.name === name){
          return null
        }else { 
          dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList: [
                  { 
                    uuid: name, 
                    valueApplied: Number(valueQuestion)
                  },
                ],
                valueApplied: valueQuestion ? Number(valueQuestion) : null
              }] 
              : 
              [
                {
                  uuid: `${props?.uuid}`,  
                  questionAlternativeList: [
                    { 
                      uuid: name, 
                      valueApplied: Number(valueQuestion)
                    },
                  ],
                  valueApplied: valueQuestion ? Number(valueQuestion) : null
                }],
          })
        }
        if(name){
          setChecked({name: name})
        }
      }


      useEffect(() => {
        if(props?.isDetail){
          dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Detalle aplicación de cuestionario",
            index: 5,
            subPath: `${PATH_APP}/aplication`,
            idForm: 'formAplication',
            module: 'DETAIL_APLICATION',
            textIsSubmit: "GUARDAR",
          });
        }else if(errors['valueTotalQuestion'] || watch('valueTotalQuestion') === ''){
          dispatch({
              type: "SET_BREADCRUMB",
              breadCrumbText: "Autoevaluaciones",
              breadCrumbSubText: "Aplicar cuestionario",
              index: 5,
              subPath: `${PATH_APP}/questionary`,
              isSubmit: true,
              idForm: 'formAplication',
              module: 'MANAGE_QUESTIONNAIRE',
              disabledSubmit: true,
              textIsSubmit: "GUARDAR",
            });
        } else {
          dispatch({
              type: "SET_BREADCRUMB",
              breadCrumbText: "Autoevaluaciones",
              breadCrumbSubText: "Aplicar cuestionario",
              index: 5,
              subPath: `${PATH_APP}/questionary`,
              isSubmit: true,
              idForm: 'formAplication',
              module: 'MANAGE_QUESTIONNAIRE',
              disabledSubmit: false,
              textIsSubmit: "GUARDAR",
            });
        }
      }, [errors['valueTotalQuestion']|| watch('valueTotalQuestion') === '']);

      const handleOnchange = (e)=> {
        const {name, value} = e.target;
        setValue(name, value)
        trigger(name)
        setValueQuestion(value)
      
        const findUuid= valorateAplication?.data.find((x)=> x?.uuid === `${props?.uuid}`)
        const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)

        if(checked?.name){
          dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList:  [
                  { 
                    uuid: checked ? checked?.name : '', 
                    valueApplied: Number(value)
                  },
                ] ,
                valueApplied: Number(value)
              }] 
              : [{uuid: `${props?.uuid}`, answer: name }],
          })
        }else {
          dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList:  [] ,
                valueApplied: Number(value)
              }] 
              : [{uuid: `${props?.uuid}`, answer: name }],
          })
        }
       
      } 

      useEffect(()=> {
        setValue('valueTotalQuestion', Number((valorationQuestionnaire?.totalValueQuestionnaire)) ? Number((valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
        setValueQuestion(Number((valorationQuestionnaire?.totalValueQuestionnaire)) ? Number((valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
        if(valorationQuestionnaire?.typeValoration === 'automatic'){
        
        dispatch({
          type: "SET_VALUE_QUESTIONNAIRE",
          totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
          typeValoration: 'automatic',
          activeValoration: false
        });
        const findUuid= valorateAplication?.data?.find((x)=> x?.uuid === `${props?.uuid}`)
        const filterUuid = valorateAplication?.data?.filter((x)=> x?.uuid !== `${props?.uuid}`)
      
         // agregando nuevo valor aplicado a la pregunta y sus opciones cuando es manual
         if(findUuid?.questionAlternativeList.length){
           
          const newValues = []
           dispatch({
             type: "SET_MODAL_VALORATE_APLICATION",
             icon:'WARNING',
             data: valorateAplication?.data ? 
             [...filterUuid, 
               {
                 uuid: `${props?.uuid}`, 
                 questionAlternativeList: [
                   {
                    uuid: findUuid?.questionAlternativeList[0]?.uuid,
                    valueApplied: valorationQuestionnaire?.totalValueQuestionnaire,
                   }
                 ],
                 valueApplied: valorationQuestionnaire?.totalValueQuestionnaire,
               }
             ] 
               : [{uuid: `${props?.uuid}`,  questionAlternativeList: newValues,
               valueApplied: valorationQuestionnaire?.totalValueQuestionnaire, }],
           })
         } else {
        
          return dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList: [],
                valueApplied: Number(valorationQuestionnaire?.totalValueQuestionnaire)
              }
            ] 
              : [{uuid: `${props?.uuid}`,  questionAlternativeList: [],
              valueApplied: Number(valorationQuestionnaire?.totalValueQuestionnaire) }],
          })
         
         }
        }else {
          dispatch({
            type: "SET_VALUE_QUESTIONNAIRE",
            totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
            typeValoration: 'manual',
            activeValoration: false
          });
        }
      
      },[valorationQuestionnaire?.activeValoration ])
    

      const validateValueCheckeds = (index) => {
       
        if(  valorationQuestionnaire?.totalValueQuestionnaire 
              && checked?.name === `${props?.valuesOptions[index]?.uuid}`
          ){
            return ( valorationQuestionnaire?.totalValueQuestionnaire).toFixed(2)
          }
       else if (checked && !valorationQuestionnaire?.totalValueQuestionnaire 
                  && watch('valueTotalQuestion')  
                  && checked?.name === `${props?.valuesOptions[index]?.uuid}`
                )
        {
        return (Number(watch('valueTotalQuestion'))) % 1 !== 0 ? (Number(watch('valueTotalQuestion'))).toFixed(2) : (Number(watch('valueTotalQuestion')))
       } else {
         return 0
       }
      
      }
      
      
      const onSubmit= () => {
      
        dispatch({
          type: "SET_MODAL_VALORATE_APLICATION",
          activeValorate: true,
          dataClear: valorateAplication?.data ?  valorateAplication?.data :[],
          data: valorateAplication?.data ?  valorateAplication?.data :[],
          valuesQuestions: valueQuestion ? valueQuestion : ''
            })
          }
        
    return <Grid container spacing={1}  maxWidth="lg" justifyContent="center" alignItems="center" style={{ display: 'flex', justifyContent: 'flex-start' }} >
    <Grid item lg={12} style={{display: 'flex'}}>
        <Grid item lg={10}>
            <FormControl variant='outlined' fullWidth size="small">
                <TextField
                disabled={true}
                className={classes.dropdown}
                fullWidth
                multiline
                maxRows={65}
                value={props?.question}
                size="small"
            />
            </FormControl>
        </Grid>
        <Grid item lg={1} style={{display: 'flex', marginLeft: '3%'}}>
            <FormControl variant='outlined' fullWidth size="small">
                <TextField
                {...register('valueTotalQuestion')}
                className={classes.dropdown}
                fullWidth 
                placeholder='Valor'
                size="small"
                onChange={(e)=>handleOnchange(e)}
                error={!!errors.hasOwnProperty("valueTotalQuestion") && errors["valueTotalQuestion"].message}
                disabled={valorationQuestionnaire?.totalValueQuestionnaire || props?.isDetail || !valorationQuestionnaire?.typeValoration || disabledQuestionary?.disabled}
                value={props?.isDetail ? props.valueQuestion : valueQuestion}
            />
            </FormControl>
        </Grid>
     </Grid>
     <Grid container spacing={1} maxWidth="lg" flexDirection="column" style={{margin: '5px 0px 10px -1%'}}>
     {props?.valuesOptions.map((x, index) => { 
      return <form id="formAplication" onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12} sm={12} md={12} lg={10} style={{display: 'flex', marginBotton: 20, marginTop: '1%'}}>
              <div style={{marginRight:5}}>
                <Checkbox
                  disabled={props?.isDetail || disabledQuestionary?.disabled ? true : null}
                  checked={props?.isDetail ? x?.valueApplied !== 0 : checked?.name === `${props?.valuesOptions[index]?.uuid}`}
                  name={`${props?.valuesOptions[index]?.uuid}`}
                  indeterminate={checked?.name === `${props?.valuesOptions[index]?.uuid}`}
                  onChange={handleChecked}
                />
              </div>
            <FormControl variant='outlined' fullWidth size="small">
                <TextField
                disabled={true}
                className={classes.dropdown}
                fullWidth
                multiline
                maxRows={15}
                value={x?.key}
                size="small"
              />
            </FormControl>
            <Grid item lg={1} style={{display: 'flex', marginLeft: '3%'}}>
          <FormControl variant='outlined' fullWidth size="small">
              <TextField
              className={classes.dropdown}
              fullWidth
              placeholder='Valor'
              size="small"
              disabled
              value={props?.isDetail ? x.valueApplied : validateValueCheckeds(index)}
          />
          </FormControl>
        </Grid>
       </Grid> 
       </form>
     })}
     </Grid>
   </Grid>
};

export default Index;
