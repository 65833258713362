import React from "react";
import * as IdKey from "short-uuid";
import {
  Typography,
  Tooltip,
  Button,
  IconButton,
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import {styles} from "./styles";
import moment from "moment"
import { useHistory } from "react-router-dom";
import { useStateValue } from './../../../context/store';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PATH_APP } from "../../../constants";

export default function DetailedAccordion({
  data,
}) {
  const history = useHistory();
  const classes = styles();
  const id = IdKey.generate();
  const [, dispatch] = useStateValue();


  const goToPageGrapich = (x) => {
    dispatch({
      type: 'SET_DATA_PERFORMANCE_EVALUATION',
      data: x
    })
    history.push(`${PATH_APP}/management-aplication-evaluations/results/${x?.uuid}`)
  }
if(data?.length) {
  return (
    <div className={classes.root}>
           <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"15%"} className={classes.wrapper}>
                Fecha de creación
                </TableCell>
                <TableCell align="left" width={"30%"} className={classes.wrapper}>
                Periodo de aplicación
                </TableCell>
                <TableCell align="left" width={"30%"} className={classes.wrapper}>
                Nombre de aplicación
                </TableCell>
                <TableCell align="left" width={"30%"} className={classes.wrapper}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, idColumn = id) => (
                <TableRow key={idColumn}>
                  <TableCell align="left" width={"15%"} className={classes.header}
                    style={{
                      borderTopLeftRadius: idColumn === 0 ? 10 : 0,
                      borderBottomLeftRadius: idColumn === row?.length - 1 ? 10 : 0
                    }}>
                  <Typography className={classes.heading}>{moment(row?.createdAt).format('DD/MM/YYYY')}</Typography>
                  </TableCell>
                  <TableCell align="left" width={"30%"} className={classes.columnOneTitle}>
                    <Typography className={classes.header}>{`${moment(row?.startDate).format('DD/MM/YYYY')} -  ${moment(row?.endDate).format('DD/MM/YYYY')}`}</Typography>
                  </TableCell>
                  <TableCell align="left" width={"30%"} className={classes.header} >
                    <Typography className={classes.heading} >
                      {row?.applicationName ?? ''}
                    </Typography>                  
                  </TableCell>
                  <TableCell align="left" width={"30%"} className={classes.column}
                    style={{
                      borderTopRightRadius: idColumn === 0 ? 10 : 0,
                      borderBottomRightRadius: idColumn === row?.length - 1 ? 10 : 0
                    }} >
                    <Tooltip title="Ver detalle" arrow >
                    <IconButton onClick={() => history.push(`${PATH_APP}/management-aplication-evaluations/detail/${row?.uuid}`)}>
                      <VisibilityIcon className={classes.iconEvent} />
                    </IconButton>
                  </Tooltip>
                  &nbsp;&nbsp;
                  {row?.status === "ACTIVE" &&
                    <Tooltip title="EditarApli" arrow >
                      <EditIcon
                        onClick={() => history.push(`${PATH_APP}/evaluation-performance/evaluator/aplication-edit/${row?.status}/${row?.uuid}`)}
                        className={classes.iconEvent}
                      />
                    </Tooltip>
                  }
                  
                  {/* <Button 
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => goToPageGrapich(row)}
                    >
                    GENERAL
                  </Button> */}
                  &nbsp;&nbsp;
                  <Button 
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => history.push(`${PATH_APP}/management-aplication-evaluateds/${row?.uuid}`)}
                    >
                    INDIVIDUAL
                  </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
      }else {
        return null;
      }
}
