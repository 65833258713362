import React, {useEffect, useState} from 'react'
import { useStateValue } from "../../context/store";
import SearchQuestion from './search';

import TableQuestions from './table';
import ModalConfirm from "../../utils/form/modalConfirmBase";
import {  getfilterQuestions } from '../../actions/question-action';
import {  getALLTags } from '../../actions/label-actions';
import ModalErrorAction from "../../components/common";
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { PATH_APP } from '../../constants';


const Index = () => {
  const [{ questionSearch, spinner}, dispatch] = useStateValue();
  const [clearFielForm, setClearFielForm]= useState(false)
  const [labels, seLabels] = useState([])
  const [scroll, setScroll] = useState(true);
  const [data, ] = useState({
    question : null,
    tagName: null,
    answerAlternativeCode: null,
  })
  const [paginadorResponse, setPaginadorResponse] = useState({
    data: [],
    totalRows: 0,
    pageSize: 0,
    pageNumber: 0,
  });
  const [paginadorRequest, setPaginadorRequest] = useState({
    page: 0,
    size: 10,
  });
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
    })



    useEffect(() => {
      dispatch({
        type: "SET_BREADCRUMB",
        breadCrumbText: "Autoevaluaciones",
        breadCrumbSubText: "Gestionar preguntas",
        index: 3,
        module: 'SET_FILTER_QUESTION',
        path: `${PATH_APP}/create-question`,
        textIsSubmit: "CREAR PREGUNTA",
      });
      if(scroll){
        window.scrollTo(0,0)
      }
      getAllQuestions()
      window.scrollTo(0,0)
      getAllLabels();
    }, [paginadorRequest])
  

    const resetData = () => {
      setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      })
    }

    const closeModal = () => {setMessage({...message, open:false}); resetData(); setClearFielForm(!clearFielForm)}
   

  const getAllQuestions = async() => {
    dispatch({
      type: "SET_FILTER_QUESTION",     
      filter: {
        question: questionSearch?.filter?.question,
        tagName: questionSearch?.filter?.tagName,
        answerAlternativeCode: questionSearch?.filter?.answerAlternativeCode,
        page: paginadorRequest?.page,
        size: paginadorRequest?.size
      },
      isFilter: false
   })
    const response = await getfilterQuestions({
      question : questionSearch?.filter?.question === null ? null : questionSearch?.filter?.question,
      tagName: questionSearch?.filter?.tagName === null ? null : questionSearch?.filter?.tagName,
      answerAlternativeCode: questionSearch?.filter?.answerAlternativeCode === null ? null : questionSearch?.filter?.answerAlternativeCode,
      page: paginadorRequest?.page === null ? null : paginadorRequest?.page,
      size:  paginadorRequest?.size === null ? null : paginadorRequest?.size,
    }, {orderBy: 'createdAt'},dispatch)

    setPaginadorResponse({
      data: [],
      totalRows: 0,
      pageSize:  questionSearch?.filter?.size,
      pageNumber: questionSearch?.filter?.page,
    });
    
     if(response?.status === 204 && !questionSearch?.typeAction){
      return setMessage({
        open : true,
        description: 'No se encontró información relacionada a la búsqueda',
        handleClick : () => closeModal() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })
     }
     if(response?.data?.content?.length < 1 && !questionSearch?.typeAction){
       setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });
      return setMessage({
        open : true,
        description: 'No se encontró información relacionada a la búsqueda',
        handleClick : () => closeModal() ,
        oneButtons : true,
        textButtonSubmit: 'ACEPTAR',
        type : 'WARNING',
      })
     }
    if ((response && response.status === 200) || (response.status === 201)) {
      const data = response?.data;
      if (data?.content?.length > 0) {
        setPaginadorResponse({
          data: data?.content,
          totalRows: data?.totalElements,
          pageSize: data?.size,
          pageNumber: data?.number,
        });
      }
      
    } else {
      return setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });
    }
  }


  const getAllLabels = async() => {
    const result = await getALLTags({status: 'ACTIVE'}, {orderBy: 'name'}, dispatch);
    seLabels(result?.data?.content ? result?.data?.content : [])
  }

 
 
  if(questionSearch &&
    questionSearch?.isFilter) {
      const newData = questionSearch?.filter
      data.question = newData?.question;
      data.tagName = newData?.tagName;
      data.answerAlternativeCode = newData?.answerAlternativeCode;
      paginadorRequest.page = newData?.page;
      paginadorRequest.size = newData?.size
      getAllQuestions()
      dispatch({
        type: "SET_FILTER_QUESTION",
        filter: questionSearch?.filter,
        isFilter: false,
      })
  }

  const moveScroll = () => setScroll(false)
  const clearFilter = () =>  getAllQuestions()

  const changePage = (event, nuevaPagina) => {
       
    setPaginadorRequest({
      ...paginadorRequest,
      page: nuevaPagina,
    });
  };

  const changeAmountOfRecords = (event, siguiente) => {
    setPaginadorRequest({
      ...paginadorRequest,
      size: event.target.value,
      page: 0
    });
  };


  return (
        <>
      <SearchQuestion  
        labels={[...labels]}
        clearFilter={clearFilter}
        clearFielForm={clearFielForm}
        indexQuestion={true}

      />
       {!spinner?.active 
        && (
          <TableQuestions 
            data={[...paginadorResponse?.data]}
            message={message}
            setScroll={moveScroll}
            setMessage={setMessage}
          />
        )
      }
      {paginadorResponse?.totalRows !== 0 && !spinner?.active &&
        <TablePagination
          style={{ justifyContent: 'center', display:"flex", color: '#747578' }}
          component="div"
          rowsPerPageOptions={[5, 10, 15]}
          count={paginadorResponse.totalRows}
          rowsPerPage={paginadorRequest.size}
          page={paginadorRequest.page}
          labelRowsPerPage='Registros por página'
          onPageChange={changePage}
          onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
        />
      }
     
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
      <ModalErrorAction/>
      </>
  )
}

export default Index
