import React, {useState, useEffect} from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography, Grid } from '@mui/material';
import ModalConfirm from "../../utils/form/modalConfirmBase";


import Percentages from "./percentages";
import { useStateValue } from '../../context/store';
import { PATH_APP } from '../../constants';

const Index =(props)  => {

  const {id, status} = props?.match?.params;
  const idEvaluation = id
  const [{typesEvaluations}, dispatch] = useStateValue();
  const [selectedType, setSelectedType] = useState([]);
  const [pos, setPos] = useState(0);
  const [deleteType, setDeleteType] = useState();
  const [message, setMessage] = useState({
    description : '',
    textButtonSubmit : '',
    handleClick : () => {} ,
    handleClickOut : () => {},
    oneButtons : false,
    title: '',
    type : '',
    open : false
  });
  
  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      breadCrumbText: "Autoevaluaciones",
      breadCrumbSubText: "Tipos de evaluación",
      threeButton: true,
      subPathSearch: `${PATH_APP}/evaluation-performance-evaluator`,
      isSubmit: true,
      textIsSubmit: 'CONTINUAR',
      index: 12,
      idForm: 'Evaluation',
      module: 'EVALUATION_EVALUATOR',
      subPath: `${PATH_APP}/evaluation-performance/evaluator/aplication/${idEvaluation}`,
    });
    window.scrollTo(0,0)
  }, [])
  


  const validate = (n) => {
    const validateSelected = typesEvaluations?.applicationTypes?.find((x)=> x?.type === n)
    return validateSelected ? true : false
  }


  const handleChange = (e, lb) => {
    const {name} = e.target;

    const exists = validate(name);
    const differents = typesEvaluations?.applicationTypes?.filter((x)=> x?.type !== name)

    let newTypes = [];
    for (let index = 0; index < differents?.length; index++) {
      newTypes.push({...differents[index], percentage: ''}) 
    }
    if(exists){
      dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: null,
        applicationTypes: newTypes,
        selectAll: typesEvaluations?.selectAll,
        users: typesEvaluations?.users,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders
      });
      const dataLocalStorage = {
        automatic: null,
        applicationTypes: newTypes,
        submitPercentage: typesEvaluations?.submitPercentage,
        selectAll: typesEvaluations?.selectAll,
        users: typesEvaluations?.users,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders
      }
        localStorage.setItem('typesEvaluation', JSON.stringify(dataLocalStorage))

      setDeleteType(name)
      setSelectedType(newTypes)
    }else {

      setPos(pos+1)
      const newValues = [];
      const newTypes = [...selectedType]
      newTypes.push({type: name, label: lb, position:pos, percentage: ''})

      for (let index = 0; index < newTypes?.length; index++) {
        newValues.push({...newTypes[index], percentage: '', position:index}) 
      }

      setSelectedType(newValues)
      dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: null,
        applicationTypes: newValues,
        selectAll: typesEvaluations?.selectAll,
        users: typesEvaluations?.users,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders
      });
      const dataLocalStorage = {
        automatic: null,
        applicationTypes: newValues,
        submitPercentage: typesEvaluations?.submitPercentage,
        selectAll: typesEvaluations?.selectAll,
        users: typesEvaluations?.users,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders
      }
        localStorage.setItem('typesEvaluation', JSON.stringify(dataLocalStorage))
      setDeleteType()
    }
  }



  useEffect(()=> {
    const typeslocal = localStorage.getItem('typesEvaluation')
    const jsonTypes =  JSON.parse(typeslocal)
    if(jsonTypes?.applicationTypes?.length){
      setSelectedType(jsonTypes?.applicationTypes)
      dispatch({
        type: "SET_TYPES_EVALUATIONS",
        automatic: typesEvaluations?.automatic,
        applicationTypes: typesEvaluations?.applicationTypes,
        selectAll: typesEvaluations?.selectAll,
        submitPercentage: typesEvaluations?.submitPercentage,
        users: typesEvaluations?.users,
        dataEvaluation: typesEvaluations?.dataEvaluation,
        pages: typesEvaluations?.pages,
        selectNewLeaders: typesEvaluations?.selectNewLeaders
      });
    }
    if(typesEvaluations?.applicationTypes?.length){
      setSelectedType(typesEvaluations?.applicationTypes)
    }
  },[])


  const typesEval = [
      {key: "SELF_EVALUATION", value: 'Autoevaluación'},
      {key: "PEER_EVALUATION", value: 'Evaluación estudiantes'},
      {key: "LEADER_EVALUATION", value: 'Evaluación institucional'},
    ];
 
  return (
    <>
    <Typography style={{color:'#2F71CB', fontSize: 18, marginBottom: '2%', fontWeight: 500}}>{typesEvaluations?.dataEvaluation?.applicationName}</Typography>
    <Typography style={{color:'#4B4B4B', fontSize: 14, marginBottom: '2%'}}>Seleccione los tipos de evaluación que desea aplicar</Typography>
    <Grid container spacing={12}>
      <Grid item lg={8} style={{display: 'flex', alignItems: 'center'}}>
        {typesEval?.map((t) => (
          <FormGroup >
          <FormControlLabel
              style={{width: 'max-content', fontSize: 14, color: '#8E8E8E'}}
              control={
              <Checkbox 
                size='small'
                disabled={status === 'APPLIED'}
                checked={validate(t?.key)} onChange={(e) => handleChange(e, t?.value)} name={t?.key} 
              />
              }
              label={t?.value}
          />
        </FormGroup>
        ))}
        
      </Grid>
      <Grid item lg={12} style={{marginTop: 'inherit'}} >
        <Percentages types={selectedType} typeDelete={deleteType} history={props?.history} message={message} setMessage={setMessage} props={props}/>
        <Grid item lg={12} style={{marginTop: '4%'}}>
        </Grid>
      </Grid>
    </Grid>
    <ModalConfirm 
      description = { message.description }
      textButtonSubmit = { message.textButtonSubmit }
      handleClick = { message.handleClick }
      handleClickOut = { message.handleClickOut }
      oneButtons = { message.oneButtons }
      title = { message.title }
      type = { message.type }
      open = { message.open }
    />
     
    </>
    
  );
}

export default Index