import React, {useState, useEffect} from 'react'
import {
    Grid,
    TextField, 
    Button,
    Box,
    Autocomplete,
    FormControl,
    FormControlLabel,
    Checkbox,
    ListItemText,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material'
import { useStateValue } from "../../../../context/store";
import {styles} from './styles';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createAplication} from "../../../../actions/Performance_Evaluation-action"
import * as yup from "yup";
import { PATH_APP } from '../../../../constants';
import _ from 'lodash';

const SearchCompetencies = (props) => {

    const classes = styles()
    const [{typesEvaluations, evaluationPerformanceSearch, filterOrganizationChart}, dispatch] = useStateValue();
    const [valuesForm, setValuesForm] = useState({
        regionalName: [],
        evaluatorType: '',
    })

  
    const schema = yup.object().shape({

        idKeyword: yup
        .string()
        .max(15, 'Ingrese máximo 15 caracteres'),
        nameKeyword: yup
        .string()
        .max(40, 'Ingrese máximo 40 caracteres'),
        evaluatorType: yup
        .string(),
        regionalName: yup
        .string()
        .notRequired()
        .nullable(true)
        
      });
    
      
      const { register, handleSubmit, setValue, clearErrors,  watch, formState:{errors} } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });
      
      const onSubmit = (data) => {

        const newPrograms = valuesForm?.regionalName?.length ? valuesForm?.regionalName?.filter((x) => x?.uuid !== '1234') : null;
        if(
            data?.idKeyword !== '' ||
            data.nameKeyword !== '' ||
            data?.evaluatorType !== '' ||
            valuesForm?.regionalName?.length  
        ){
            dispatch({
                type: "SET_FILTER_ORGANIZATION_CHART",
                filter: {
                    nameKeyword: data?.nameKeyword,
                    idKeyword: data?.idKeyword,
                    evaluatorType: data?.evaluatorType,
                    regionalName: newPrograms?.length ? newPrograms?.map((x)=> x?.name): null,
                    page: filterOrganizationChart?.filter?.page ?? 0,
                    size: filterOrganizationChart?.filter?.size ?? 10
                  },
                  isFilter: true,
            })
        } else {
            dispatch({
                type: "SET_FILTER_ORGANIZATION_CHART",
                filter: {
                    nameKeyword: null,
                    idKeyword: null,
                    evaluatorType: null,
                    regionalName: null,
                    page: 0,
                    size: 10
                  },
                  isFilter: true,
            })
        }
      }

      const clear = () => {
        setValue('idKeyword', '')
        setValue('nameKeyword', '')
        setValue('position', '')
        setValue('area', '')
        setValue('regionalName', null)
        setValue('evaluatorType', "")
        clearErrors(['nameKeyword', 'idKeyword'])
        setValuesForm({...valuesForm, regionalName: [], nameKeyword: '' , evaluatorType: '', area: '', regional: ''})
      }

      useEffect(() => {
        clear()
      }, [props?.clearFielForm])

      
      const clearForm = () => {
        clear()
            props.dataFilter()
        }
    
    const disabledSubmit = () => {
        dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Evaluados",
            index: 12,
            threeButton: true,
            subPathSearch: `${PATH_APP}/evaluation-performance-evaluator`,
            idForm: 'typeEvaluation',
            textIsSubmit: "ENVIAR EVALUACIONES",
            isSubmit: true,
            disabledSubmit: true,
            module: 'EVALUATION_EVALUATOR',
            subPath: `${PATH_APP}/evaluation-performance/evaluator/aplication/${props?.props?.match?.params?.id}/type-evaluation`,
          });
    }

    const setNotDisabled = () => {
        dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Evaluados",
            index: 12,
            threeButton: true,
            subPathSearch: `${PATH_APP}/evaluation-performance-evaluator`,
            idForm: 'typeEvaluation',
            textIsSubmit: "ENVIAR EVALUACIONES",
            isSubmit: true,
            disabledSubmit: false,
            module: 'EVALUATION_EVALUATOR',
            subPath: `${PATH_APP}/evaluation-performance/evaluator/aplication/${props?.props?.match?.params?.id}/type-evaluation`,
          });
    }

    const handleChange = (e) => {
        const {name} = e.target;
        const selectAll = '1234';

        const findProgram = valuesForm?.regionalName?.find(x=> x?.uuid === name);
        const findAllPrograms = valuesForm?.regionalName?.find(x=> x?.uuid === selectAll);
        const differentsProgram = valuesForm?.regionalName?.filter(x=> x?.uuid !== name)
        
        if(findProgram && findAllPrograms && selectAll !== name){
            const newColection = differentsProgram?.filter((x) => x.uuid !== selectAll)
            setValuesForm({...valuesForm, regionalName: newColection})
        }else if(name === selectAll && findAllPrograms ){
            setValuesForm({...valuesForm, regionalName: []})
        }else if (findProgram && !findAllPrograms ) {
            setValuesForm({...valuesForm, regionalName: differentsProgram})
        }
    }

    const handleOnChange = (e) => {
        const {name, value} = e.target;
        setValuesForm({...valuesForm, [name]:value})
        setValue(name, value)
    }

    const validateChecked = (option) => {
        const findProgram = valuesForm?.regionalName?.find(x=> x?.uuid === option?.uuid);
        return findProgram ? true: false
    }


    const closeModalWarning = () => props?.setMessage({...props?.message, open:false});
    const closeModal = () => {props?.setMessage({...props?.message, open:false}); setNotDisabled()}
    const handleCloseSuccess = () => {
        closeModal()
        props?.props?.history.push(`${PATH_APP}/evaluation-performance-evaluator`)
        dispatch({
            type: "SET_FILTER_EVALUATION_PERFORMANCE",
            filter: evaluationPerformanceSearch?.filter,
            isFilter: true,
            typeAction: 'GO_BACK'
            });
        dispatch({
            type: 'SET_RELATIONS_EVALUATION',
            data: []
        });
        dispatch({
            type: "SET_DATA_QUESTIONNAIRE",
            data: {}
        });
        dispatch({
            type: "SET_VALORATE_APLICATION",
            disabledValors: false,
            typeValoration: '',
            data: {}
        })
        localStorage.removeItem('typesEvaluation')

    }


        const validate = (watch('nameKeyword') || watch('idKeyword') || valuesForm?.regionalName?.length || watch('evaluatorType') )

        const onSubmitForm = () => {

            const types = [];
            let evaluationLeader = [];
            let evaluationSelf = [];
            const findPeer = typesEvaluations?.applicationTypes?.find((x)=> x?.type === 'PEER_EVALUATION');
            const findSelf  = typesEvaluations?.applicationTypes?.find((x)=> x?.type === 'SELF_EVALUATION');
            const findLeader  = typesEvaluations?.applicationTypes?.find((x)=> x?.type === 'LEADER_EVALUATION');

            const users = []

            for (let index = 0; index < findPeer?.employees?.length; index++) {
               if(!findPeer?.employees[index]?.evaluator?.uuid){
                  users.push(`
                    ${findPeer?.employees[index]?.evaluated?.name ? findPeer?.employees[index]?.evaluated?.name : ''}
                    ${findPeer?.employees[index]?.evaluated?.secondName ? findPeer?.employees[index]?.evaluated?.secondName : ''}
                    ${findPeer?.employees[index]?.evaluated?.surname ? findPeer?.employees[index]?.evaluated?.surname : ''}
                    ${findPeer?.employees[index]?.evaluated?.secondSurname ? findPeer?.employees[index]?.evaluated?.secondSurname : ''}
                    `
                  )
                
               }
                
            }

            if(!typesEvaluations?.dataEvaluation?.competences?.length){
                return  props?.setMessage({
                    open : true,
                    description: 'Proporcione un valor a las criterios y aspectos y/o ingrese la fecha para el periodo de aplicación de esta evaluación',
                    handleClick : () => closeModalWarning() ,
                    oneButtons : true,
                    textButtonSubmit: 'ACEPTAR',
                    type : 'WARNING',
                  })
            }
           
            if(findPeer){
                const newData = []
             
                for (let user = 0; user < typesEvaluations?.users?.length; user++) {
                    if(typesEvaluations?.users[user]?.subalterns?.length){

                        for (let subaltern = 0; subaltern < typesEvaluations?.users[user]?.students?.length; subaltern++) {
                            newData.push({evaluator: {uuid : typesEvaluations?.users[user]?.students[subaltern]?.uuid, evaluated: [{uuid: typesEvaluations?.users[user]?.uuid}]}})
                        }
                    }
                     
                 }

                types?.push({type: 'PEER_EVALUATION', percentage: findPeer?.percentage, employees: newData });

            }if(findLeader){
                

                const groupByProgram =  _.groupBy(typesEvaluations?.users, 'regional.uuid')
                const listUsers = []

                 for( var lead in groupByProgram){
                  
                       listUsers.push(_.groupBy(groupByProgram[lead], 'identificationNumber')) 
                }
                for (let index = 0; index < listUsers?.length; index++) {
                    
                    for( var lid in listUsers[index]){
                        evaluationLeader.push({evaluator: {uuid : listUsers[index][lid][0]?.leader?.uuid, evaluated: [{uuid: listUsers[index][lid][0]?.uuid}]}})
                    }
                    
                }

                types?.push({type: 'LEADER_EVALUATION', percentage: findLeader?.percentage, employees: evaluationLeader })
            
               
            } if(findSelf){
             
                const groupByProgram =  _.groupBy(typesEvaluations?.users, 'regional.uuid')
                const listUsers = []

                 for( var item in groupByProgram){
                  
                       listUsers.push(_.groupBy(groupByProgram[item], 'identificationNumber')) 
                }
                for (let index = 0; index < listUsers?.length; index++) {
                    
                    for( var us in listUsers[index]){
                        evaluationSelf.push({evaluator: {uuid : listUsers[index][us][0]?.uuid, evaluated: [{uuid: listUsers[index][us][0]?.uuid}]}})
                    }
                    
                }
                 
                types.push({type: 'SELF_EVALUATION', percentage: findSelf?.percentage, employees: evaluationSelf })
            }


            const sendData = {
                automatic: typesEvaluations?.dataEvaluation?.automatic,
                competences: typesEvaluations?.dataEvaluation?.competences,
                endDate: typesEvaluations?.dataEvaluation?.endDate,
                startDate: typesEvaluations?.dataEvaluation?.startDate,
                startDatePeriod: typesEvaluations?.dataEvaluation?.startDatePeriod,
                applicationName: typesEvaluations?.dataEvaluation?.applicationName,
                endDatePeriod: typesEvaluations?.dataEvaluation?.endDatePeriod,
                evaluationType: {
                automatic: typesEvaluations?.automatic,
                types: types
            }}
 
            const appliedEvaluation = async()=> {
                disabledSubmit()
                const result = await createAplication(props?.props?.match?.params?.id, sendData, dispatch) 
                if (result && (result?.status >= 200 && result?.status <= 204)) {
                  props?.setMessage({
                    open : true,
                    title: '¡Proceso exitoso!',
                    description: 'Información almacenada correctamente ',
                    handleClick : () => handleCloseSuccess() ,
                    textButtonSubmit: 'ACEPTAR',
                    oneButtons : true,
                    type : 'SUCCESS',
                  })
                  }
                  else if (result?.status === 400) {
                    
                    const users = result?.data?.message?.split(",")
                    let textUsers = []
            
                    for (let index = 0; index < users?.length; index++) {
                        textUsers.push(users[index]) 
                        
                    }
                      props?.setMessage({
                      open : true,
                      description: `Estos usuarios ya poseen una aplicación de esta evaluación : `,
                      handleClick : () => closeModal() ,
                      list: textUsers,
                      oneButtons : true,
                      type : 'WARNING',
                    })
                  } else {
                      props?.setMessage({
                      open : true,
                      description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
                      handleClick : () => closeModal() ,
                      oneButtons : true,
                      type : 'WARNING',
                    })
                  }
                }
                appliedEvaluation()
        
          }

    return (
        <>
         <form onSubmit={handleSubmit(onSubmit)} style={{marginTop: '2%'}} autoComplete="off" >
            <Grid container  spacing={2} style={{marginBottom: '2%'}}>
                <Grid item lg={2.5} md={4}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <Autocomplete
                            {...register('regionalName')}
                            options={[{name: 'Todos', uuid: '1234'},...props?.regionals]}
                            onFocus={(e) => e.target.setAttribute("autoComplete", "off")}
                            autoHighlight
                            multiple
                            disableCloseOnSelect
                            disablePortal
                            limitTags={1}
                            className={classes.dropdown}
                            size="small"
                            openText='Abrir'
                            closeText='Cerrar'
                            clearText='Borrar'
                            noOptionsText="No hay opciones"
                            getOptionLabel={(option) => (option?.name === 'Todos' ? 'Todos' :  option?.name)}
                            onChange={(e, newValue) => {
                                if (newValue?.find(x => x.uuid ==='1234')) {
                                    setValuesForm({...valuesForm, regionalName: [...props?.regionals, {name: 'Todos', uuid: '1234'} ] });
                                  } else {
                                    setValue('regionalName',  newValue?.map((x)=> x?.name));
                                    setValuesForm({...valuesForm, regionalName: newValue})
                                  }
                            }}
                            value={ valuesForm?.regionalName?.find(x=> x?.name === 'Todos') ? [{name: 'Todos', uuid: '1234'}] : valuesForm?.regionalName}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <FormControlLabel
                                        key={option?.uuid}
                                        name={`${option?.uuid}`}
                                        control={<Checkbox checked={validateChecked(option)} onChange={handleChange} />}
                                        label={<ListItemText primary={option.name} />}
                                    />
                                    </li>
                            
                            )}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                {...register('regionalName')}
                                label="Programa"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                placeholder="Programa"
                                />
                            )}
                            
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={2.5} md={4}>
                    <FormControl fullWidth size="small">
                        <InputLabel shrink={!valuesForm?.evaluatorType ? false : true}>Cargo</InputLabel>
                        <TextField
                            select
                            {...register('evaluatorType')}
                            name='evaluatorType'
                            onChange={handleOnChange}
                            value={valuesForm?.evaluatorType}
                            label="Cargo"
                            notched="true"
                            InputLabelProps={{ shrink: true }}
                            placeholder='Cargo'
                            className={`${classes.dropdown} ${classes.heightSelect}`}
                        >

                            <MenuItem value="TEACHER" key={1}>
                                Docente tiempo
                            </MenuItem>
                            <MenuItem value="CHAIR_TEACHER" key={2}>
                                Docente Catedra
                            </MenuItem>
                        
                        </TextField>
                    </FormControl>
                   
                </Grid>
                <Grid item lg={2}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <TextField
                            {...register('idKeyword')}
                            label="Identificación"
                            className={classes.dropdown}
                            size="small"
                            inputProps={{
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder="Identificación"
                            helperText={!!errors.hasOwnProperty("idKeyword") && errors["idKeyword"].message}
                            error={!!errors.hasOwnProperty("idKeyword") && errors["idKeyword"].message}
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={3}>
                    <FormControl variant='outlined' fullWidth size="small">
                        <TextField
                            {...register('nameKeyword')}
                            label="Nombre"
                            className={classes.dropdown}
                            size="small"
                            inputProps={{
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder="Nombre"
                            helperText={!!errors.hasOwnProperty("nameKeyword") && errors["nameKeyword"].message}
                            error={!!errors.hasOwnProperty("nameKeyword") && errors["nameKeyword"].message}
                        />
                    </FormControl>
                </Grid>           
                <Grid item xs={12} sm={12} md={12} lg={2} className={classes.buttons}>
                    <Button 
                        variant="contained" 
                        type="submit" 
                        disableElevation 
                        disabled={!validate}
                        color="primary"
                    >
                        BUSCAR
                    </Button>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 
                    <Button 
                        variant="outlined" 
                        onClick={clearForm} 
                        disableElevation 
                        style={{background: "#ffffff"}}
                    >
                        Limpiar
                    </Button>

                </Grid>
            </Grid>
        
        </form>
        <form id="typeEvaluation" onSubmit={(e)=> {e.preventDefault(); onSubmitForm(onSubmit)}}></form>
        </>
    )
}

export default SearchCompetencies
