import React, {useState, useEffect} from 'react';
import {Grid,
    FormControl,
    TextField,
    Checkbox,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useStateValue } from "../../../../../context/store";
import {styles} from "./styles"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PATH_APP } from '../../../../../constants';

const Index = (props) => {
    const classes = styles();
    const [{ valorationQuestionnaire, disabledQuestionary, valorateAplication, dataAplication },  dispatch] = useStateValue();
    const [checkeds, setCheckeds] = useState([])
    const [valueQuestion, setValueQuestion] = useState()

    const schema = yup.object().shape({
     
      valueTotalQuestion: dataAplication?.data?.associatedQuestions?.length === 1 ? 
      yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,3}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
      .test("maxLenght", "Ingrese un valor entre 1 y 100", val => val <= 100 && val >= 1)
    : 
      yup
      .string()
      .required('Requerido')
      .matches(
        /^[0-9]{1,2}(\.[0-9]{0,2})?$/g,
        "Ejm: 10.30"
      )
    })
    
    const { register, handleSubmit, trigger, formState:{errors}, setValue, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onTouched",
      reValidateMode: "onChange",
    });

    const validateChecked = (data) => {
        const result =  checkeds?.find((x) =>x.uuid === data )
       return result && result?.checked ? true : false
     }


    const handleChangeChecked = (event) => {

      const {name} = event.target;
      const copy= [...checkeds]

        const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)
        const filterCheckeds = checkeds?.find((x) => x.uuid === name)

       if(filterCheckeds?.checked) {
          const different = checkeds.filter((x)=> x?.uuid !== name)
          const notChecked =[]
          //aqui realizo for para dividir valoracion cuando quito una opcion seleccionada
          
          for (let index = 0; index < different?.length; index++) {
            notChecked.push({uuid: different[index]?.uuid, valueApplied: Number(valueQuestion)/(different.length), checked: true}) 
          }
          setCheckeds(notChecked)
        
         dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList: notChecked,
                valueApplied: Number(valueQuestion)
              }] : 
              [{uuid: `${props?.uuid}`, answer: different }],
         })
        }else {
        
          // buscamos el uuid en los checked para ponerlo en true
          const different = checkeds.filter((x)=> x?.uuid !== name)
          const isChecked = different.filter((x)=> x?.checked === true)
          //consulto las no selecionadas 
          const notChecked = different.filter((x)=> x?.checked !== true)
          //realizo for para poner valueAplied igual a todas las seleccionadas 
          for (let index = 0; index < isChecked.length; index++) {
            
            notChecked.push({uuid: isChecked[index]?.uuid, valueApplied: Number(valueQuestion)/(isChecked.length +1), checked: true}) 
          }
          notChecked.push({uuid: name, valueApplied:  Number(valueQuestion)/(isChecked.length+1), checked: true})

          
          dispatch({
            type: "SET_MODAL_VALORATE_APLICATION",
            icon:'WARNING',
            data: valorateAplication?.data ? 
            [...filterUuid, 
              {
                uuid: `${props?.uuid}`, 
                questionAlternativeList: notChecked,
                valueApplied: Number(valueQuestion)
              }
            ] : [{uuid: `${props?.uuid}`, answer: copy }],
         })
         setCheckeds(notChecked);
       } 
    };



    const handleOnchange = (e)=> {
      const {name, value} = e.target;
      setValue(name, value)
      setValueQuestion(value)
      trigger(name)
      const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)

      // buscamos el uuid en los checked para ponerlo en true
          const different = checkeds.filter((x)=> x?.uuid !== name)
          const isChecked = different.filter((x)=> x?.checked === true)
          //consulto las no selecionadas 
          const notChecked = different.filter((x)=> x?.checked !== true)
          
       // agregando nuevo valor aplicado a la pregunta y sus opciones cuando es manual
       if(isChecked){
        for (let index = 0; index < isChecked?.length; index++) {
          notChecked.push(
              {
                uuid: isChecked[index]?.uuid, 
                valueApplied: checkeds ? Number(value)/isChecked.length : '',
                checked: true
              }
            )
        
         }
         dispatch({
          type: "SET_MODAL_VALORATE_APLICATION",
          icon:'WARNING',
          data: valorateAplication?.data ? 
          [...filterUuid, 
            {
              uuid: `${props?.uuid}`, 
              questionAlternativeList: notChecked,
              valueApplied: Number(value)
            }
          ] 
            : [{uuid: `${props?.uuid}`, answer: name }],
        })
       }else {
        dispatch({
          type: "SET_MODAL_VALORATE_APLICATION",
          icon:'WARNING',
          data: valorateAplication?.data ? 
          [...filterUuid, 
            {
              uuid: `${props?.uuid}`, 
              questionAlternativeList: checkeds,
              valueApplied: Number(value)
            }
          ] 
            : [{uuid: `${props?.uuid}`, answer: name }],
        })
       }
      
    }


    useEffect(() => {
      if(props?.isDetail){
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Detalle aplicación de cuestionario",
          index: 5,
          subPath: `${PATH_APP}/aplication`,
          idForm: 'formAplication',
          module: 'DETAIL_APLICATION',
          textIsSubmit: "GUARDAR",
        });
      }else if(errors['valueTotalQuestion'] || watch('valueTotalQuestion') === ''){
        dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Aplicar cuestionario",
            index: 5,
            subPath: `${PATH_APP}/questionary`,
            isSubmit: true,
            idForm: 'formAplication',
            module: 'MANAGE_QUESTIONNAIRE',
            disabledSubmit: true,
            textIsSubmit: "GUARDAR",
          });
      } else {

        dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Aplicar cuestionario",
            index: 5,
            subPath: `${PATH_APP}/questionary`,
            isSubmit: true,
            idForm: 'formAplication',
            module: 'MANAGE_QUESTIONNAIRE',
            disabledSubmit: false,
            textIsSubmit: "GUARDAR",
          });
      }
    }, [errors['valueTotalQuestion'] || watch('valueTotalQuestion') ]);


    useEffect(()=> {
        if(valorationQuestionnaire?.typeValoration === 'automatic'){
          
        dispatch({
          type: "SET_VALUE_QUESTIONNAIRE",
          totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
          typeValoration: 'automatic',
          activeValoration: false
        });
        }else {
          dispatch({
            type: "SET_VALUE_QUESTIONNAIRE",
            totalValueQuestionnaire: valorationQuestionnaire?.totalValueQuestionnaire,
            typeValoration: 'manual',
            activeValoration: false
          });
        }
        setValue('valueTotalQuestion', Number(valorationQuestionnaire?.totalValueQuestionnaire) ? (Number(valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '')
        setValueQuestion(Number(valorationQuestionnaire?.totalValueQuestionnaire) ? (Number(valorationQuestionnaire?.totalValueQuestionnaire)).toFixed(2) : '' )
      },[valorationQuestionnaire?.activeValoration ])


      const validateValueCheckeds = (index) => {
        const isChecked = checkeds.filter((x)=> x?.checked === true)
        if( valorationQuestionnaire?.totalValueQuestionnaire 
          && checkeds 
          && validateChecked(`${props?.valuesOptions[index]?.uuid}`) ){
            return ( valorationQuestionnaire?.totalValueQuestionnaire/isChecked.length).toFixed(2)
          }
       else if (checkeds && !valorationQuestionnaire?.totalValueQuestionnaire && watch('valueTotalQuestion') && validateChecked(`${props?.valuesOptions[index]?.uuid}`)){
        return (Number(watch('valueTotalQuestion'))/isChecked.length) % 1 !== 0 
        ? (Number(watch('valueTotalQuestion'))/isChecked.length).toFixed(2) 
        : Number(watch('valueTotalQuestion'))/isChecked.length
       } else {
         return 0
       }
      
      }

    const onSubmit= () => {
      const filterUuid = valorateAplication?.data.filter((x)=> x?.uuid !== `${props?.uuid}`)
      const newOptions = []

      for (let index = 0; index < checkeds.length; index++) {
        newOptions.push({uuid: checkeds[index]?.uuid, valueApplied: checkeds[index]?.valueApplied})
      }
      filterUuid.push(
          {
            uuid: `${props?.uuid}`,
            valueApplied: watch('valueTotalQuestion'),
            questionAlternativeList: filterUuid
          }
        )
        dispatch({
              type: "SET_MODAL_VALORATE_APLICATION",
              activeValorate: true,
              dataClear: filterUuid,
              data: filterUuid
            })
      }



    return <Grid container spacing={1}  maxWidth="lg" justifyContent="center" alignItems="center" style={{ display: 'flex', justifyContent: 'flex-start' }} >
    <Grid item lg={12} style={{display: 'flex'}}>
        <Grid item lg={10}>
            <FormControl variant='outlined' fullWidth size="small">
                <TextField
                disabled={true}
                className={classes.dropdown}
                fullWidth
                multiline
                maxRows={65}
                value={props?.question}
                size="small"
            />
            </FormControl>
        </Grid>
        <Grid item lg={1} style={{display: 'flex', marginLeft: '3%'}}>
            <FormControl variant='outlined' fullWidth size="small">
                <TextField
                {...register('valueTotalQuestion')}
                className={classes.dropdown}
                fullWidth
                type='string'
                placeholder='Valor'
                size="small"
                error={!!errors.hasOwnProperty("valueTotalQuestion") && errors["valueTotalQuestion"].message}
                onChange={(e)=>handleOnchange(e)}
                disabled={valorationQuestionnaire?.totalValueQuestionnaire || !valorationQuestionnaire?.typeValoration|| props?.isDetail || disabledQuestionary?.disabled}
                value={props?.isDetail ? props?.valueQuestion : valueQuestion}
            />
            </FormControl>
        </Grid>
     </Grid>
     <Grid container spacing={1} flexDirection="column" maxWidth="lg" style={{margin: '5px 0px 10px -1%'}}>
     {props?.valuesOptions.map((x, index) => {
      return  <form id="formAplication" onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12} sm={12} md={12} lg={10} style={{display: 'flex', marginBotton: 20, marginTop: '1.5%'}}>
               <div style={{marginRight:5}}>
               <Checkbox
                     disabled={props?.isDetail || disabledQuestionary?.disabled ? true: false}
                     checked={props?.isDetail ? x?.valueApplied !== 0 : validateChecked(`${props?.valuesOptions[index]?.uuid}`)}
                     name={`${props?.valuesOptions[index]?.uuid}`}
                     onChange={handleChangeChecked}
                   />
               </div>
             <FormControl variant='outlined' fullWidth size="small">
                 <TextField
                 disabled={true}
                 className={classes.dropdown}
                 fullWidth
                 multiline
                 maxRows={20}
                 value={x?.key}
                 size="small"
               />
             </FormControl>
             <Grid item lg={1} style={{display: 'flex', marginLeft: '3%'}}>
            <FormControl variant='outlined' fullWidth size="small">
                <TextField
                className={classes.dropdown}
                fullWidth
                placeholder='Valor'
                size="small"
                multiline
                maxRows={14}
                name={`${props?.valuesOptions[index]?.uuid}`}
                disabled
                value={props?.isDetail ? x?.valueApplied : validateValueCheckeds(index)}
            />
            </FormControl>
        </Grid>
       </Grid> 
       </form>
     })}
     </Grid>
   </Grid>
};

export default Index;
