import React, {useState, useEffect} from 'react'
import { 
  Grid,  
  Radio,
  TextField,
  RadioGroup,
  FormControl, 
  FormControlLabel 
} from '@mui/material';
import FieldAssess from "./assessLinearScale";
import FieldAnswer from "./answerLinearScale";
import ModalPreview from '../../preview';
import { useStateValue } from "../../../../context/store";
import { PATH_APP, REQUIRED_MESSAGE } from '../../../../constants';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createQuestion, updateQuestion } from '../../../../actions/question-action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import "./styles.css";
import _ from "lodash";
import * as yup from "yup";

const Index = (props) => {

  const history = useHistory();

  const [{ questionSearch }, dispatch] = useStateValue();
  const [valuesYup, setValuesYup] = useState({})
  const [valuesOptions, setValuesOptions] = useState([])
  const [currentValues, setCurrentValues] = useState([])
  const [valuesRanking, setValuesRanking] = useState({
    ranking2: 3,
    valueOption: ''
  })
  const [options, ] = useState(4);
  const [defaultValues, ] = useState([
    "N/A", "Regular", "Aceptable", "Bueno", 
  ])


      useEffect(() => {
       
        if(props.oneSubmit){
       
          return dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Gestionar preguntas",
            index: 3,
            idForm: 'formInitial6', 
            path: `${PATH_APP}/create-question`,
            textIsSubmit: "CREAR PREGUNTA",
          });
        }else if(props.isQuestionary){
          return dispatch({
            type: "SET_BREADCRUMB",
            breadCrumbText: "Autoevaluaciones",
            breadCrumbSubText: "Crear cuestionario",
            index: 4,
            isSubmit: true,
            subPath: `${PATH_APP}/questionary`,
            textIsSubmit: "GUARDAR",
            idForm: 'Newary'
          });
        }if (props.isAssess || props?.isAnswer || props?.showDetail || props?.isEdit){
          return null
        }
        else {
          submitNotDisabled();
          let restrictionsOptions = {};
          const fieldsNames = [];

          for (let index = 0; index < options; index++) {
            restrictionsOptions = Object.assign(restrictionsOptions, {[`description${index+1}`]:  yup.string().required(REQUIRED_MESSAGE).min(1, 'Ingrese mínimo 1 caracteres').max(20, 'Ingrese máximo 20 caracteres')} )
            fieldsNames.push({[`description${index+1}`] : `description${index+1}`});
            setValue(`description${index+1}`, defaultValues[index])
          }

          setValuesYup(restrictionsOptions)
          setValuesRanking({...valuesRanking, ranking2: 3})
          setValuesOptions(fieldsNames);
        
        }
        }, [])


      const schema = yup.object().shape(valuesYup);
    
      
      const { register, handleSubmit, control, watch, setValue, formState:{errors} } = useForm({
        resolver: yupResolver(schema),
        mode: "all",
        reValidateMode: "onChange",
      });


      useEffect(() => {
        if(props?.mapQuestionQuestionary?.answerAlternativeCode === 'ESL'){
          const newData = [];
          const newOrder = _.orderBy(props?.mapQuestionQuestionary?.questionAlternativeList, ['position'],['asc'])
          for (let index = 0; index < newOrder.length; index++) {
            newData.push({[`description${index +1}`] : `description${index +1}`, disabled: true})
            setValue(`description${index +1}`, newOrder[index]?.key)
            setValue(`description${index +1}`, newOrder[index]?.key)
        }
        setValuesOptions(newData)
      }
        if(props?.dataQuestion?.answerAlternativeCode === 'ESL'){
          const newData = [];
          const newCurrentData = [];
            const newOrder = _.orderBy(props?.dataQuestion?.questionAlternativeList, ['position'],['asc'])
          for (let index = 0; index < props?.dataQuestion?.questionAlternativeList.length; index++) {
            
            newData.push({[`description${index+1}`]: [`description${index+1}`]})
            newCurrentData.push({[`description${index+1}`]: `description${index+1}`, operationType: null, uuid: newOrder[index]?.uuid})
            setValue(`description${index +1}`, newOrder[index]?.key)
        }
        setValuesOptions(newData)
        setCurrentValues(newCurrentData)
      }
       }, [])

       const submitNotDisabled = () => {
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Crear pregunta",
          index: 3,
          subPath: `${PATH_APP}/questions`,
          isSubmit: true,
          idForm: props.getDataCreate ? 'prueba' :'formInitial6',
          disabledSubmit: false,
        });
       }

       const disabledSubmit = () => {
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Crear pregunta",
          index: 3,
          subPath: `${PATH_APP}/questions`,
          isSubmit: true,
          idForm: 'formInitial4',
          disabledSubmit: true,
        });
      }

      const handleModal = () => history.push(`${PATH_APP}/questions`);
      const closeModal = () =>  props.setMessage({...props.message, open:false});

      const handleCloseModalUpdate = () => {
        submitNotDisabled()
        dispatch({
          type: "SET_BREADCRUMB",
          breadCrumbText: "Autoevaluaciones",
          breadCrumbSubText: "Gestionar preguntas",
          index: 3,
          idForm: 'formInitial6', 
          path: `${PATH_APP}/create-question`,
          textIsSubmit: "CREAR PREGUNTA",
        });
        closeModal()
      }
      const handleCloseModal = () => {
        closeModal()
        submitNotDisabled()
      }
      const handleCloseModalAndReset = () => {
        closeModal();
        props?.setOpenModal()
        resetList()
      }

      const resetList = () => {
        dispatch({
        type: "SET_FILTER_QUESTION",
        filter: questionSearch?.filter,
        isFilter: true,
        typeAction: 'EDIT'
      })
     } 
   
     const handleResetForm = (data) => {
      handleCloseModal();
      props.clearFields()
      clear()
      if(props?.getDataCreate )props?.getDataCreate(data)
    }

    const clear = () => {
      setValuesRanking({...valuesRanking, ranking2: 'placeholder'})
      setValue(`ranking2`, '')
      setValue(`description1`, '')
      setValue(`description2`, '')
    }



    const onSubmit = (data) => {

      if( !props?.dataInitial?.tagUuid || !props?.dataInitial?.question || (props?.errorsHeaderQuestion?.question || props?.errorsHeaderQuestion?.label)){
        return props?.valuesForm()
      } else{
        const questionAlternative = [];

        for (let index = 0; index < options; index++) {
          questionAlternative.push({key : data[`description${index+1}`], position: index, value:0 },)
        }
        const newData = {...props?.dataInitial, 
          numberOfQuestionAlternative: 4,
          questionAlternativeRequest: questionAlternative
        }
        const createQuestions = async() => {
          disabledSubmit()
          const result = await createQuestion(newData, dispatch)
          if (result && (result?.status >= 200 && result?.status <= 201)) {
          
          return  props.setMessage({
            open : true,
            title: '¡Proceso exitoso!',
            description: '¿ Crear nueva pregunta ?',
            handleClickOut : () => handleModal(),
            handleClick : () => handleResetForm(result?.data),
            textButtonSubmit: 'ACEPTAR',
            textButtonCancel: 'CANCELAR',
            type : 'SUCCESS',
              })
        }
        else if (result?.status > 399) {
          props.setMessage({
            open : true,
            description: 'Error al guardar la información',
            handleClick : () => handleCloseModal() ,
            oneButtons : true,
            type : 'WARNING',
          })
        } else {
          props.setMessage({
            open : true,
            description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
            handleClick : () => handleCloseModal() ,
            oneButtons : true,
            type : 'WARNING',
          })
        }
       }
       const updateQuestions = async() => {
         const questionAlternative= []
         if( props?.dataQuestion?.numberOfQuestionAlternative < data?.ranking2){
           for (let index = 0; index < 5; index++) {
             if(currentValues[index] ){

               questionAlternative.push({
                 key: watch(`description${index +1}`),
                 operationType: props?.dataQuestion?.questionAlternativeList[index]?.key === watch(`description${index +1}`) ? null : 'UPDATE',
                 position: index ,
                 value: 0,
                 uuid: props?.dataQuestion?.questionAlternativeList[index]?.uuid

               })
             } else {
              
              questionAlternative.push({
                key: watch(`description${index +1}`),
                operationType: 'CREATE',
                position: index ,
                value: 0,
  
              })
              }
           }
         }
         if (props?.dataQuestion?.numberOfQuestionAlternative >= 5){
        
          for (let index = 0; index < props?.dataQuestion?.numberOfQuestionAlternative; index++) {
            if(valuesOptions[index]){
              
              questionAlternative.push({
                key: watch(`description${index +1}`),
                operationType: props?.dataQuestion?.questionAlternativeList[index]?.key === watch(`description${index +1}`) ? null : 'UPDATE',
                position: index ,
                value: 0,
                uuid: props?.dataQuestion?.questionAlternativeList[index]?.uuid

              })
            } else {
              
              questionAlternative.push({
                operationType: 'DELETE',
                position: index ,
                value: 0,
                uuid: props?.dataQuestion?.questionAlternativeList[index]?.uuid
  
              })
              }
          }
         }
         
        const newDataEdit = {
          question: props?.dataInitial?.question,
          answerAlternativeCode: props?.dataInitial?.answerAlternativeCode,
          tagUuid: props?.dataInitial?.tagUuid,
          numberOfQuestionAlternative: 5,
          questionAlternativeRequest: questionAlternative
        }

        const result = await  updateQuestion(newDataEdit, props?.dataQuestion?.uuid) 
        if (result && (result?.status >= 200 && result?.status <= 204)) {
            props.setMessage({
              open : true,
              title: '¡Proceso exitoso!',
              description: 'Información almacenada correctamente ',
              handleClick : () => handleCloseModalAndReset() ,
              oneButtons : true,
              textButtonSubmit: 'ACEPTAR',
              type : 'SUCCESS',
            })
          } else if (result?.status === 400) {
            props.setMessage({
              open : true,
              description: "La pregunta no se puede modificar porque está asociada a un cuestionario",
              handleClick : () => handleCloseModalUpdate() ,
              oneButtons : true,
              type : 'WARNING',
            })
          }
          else if (result?.status > 399) {
            props.setMessage({
              open : true,
              description: 'Error al guardar la información',
              handleClick : () => handleCloseModalUpdate() ,
              oneButtons : true,
              type : 'WARNING',
            })
          } else {
            props.setMessage({
              open : true,
              description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
              handleClick : () => handleCloseModal() ,
              oneButtons : true,
              type : 'WARNING',
            })
          }
       }
       props.dataQuestion ? updateQuestions() : createQuestions()
    }
  }



  const newOrderOptions = () => {
    const lastPosition = valuesOptions.slice(0)[0];
    const otherPositions = valuesOptions?.slice(1,valuesOptions?.length);
     lastPosition && otherPositions?.push(lastPosition) 
    return otherPositions
  }

  const selectOptions = () => {
    
    
      return <Grid item container  lg={12} justifyContent="center">
        <FormControl >
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue="top"
          >
            {newOrderOptions()?.map( (x, index) => (
              <FormControlLabel
                key={index}
                value={`description${index+1}`}
                control={<Radio  sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 35,
                  },
                }}/>}
                style={{marginLeft: index === 3 ? 50 : ''}}
                className='labelCheckbox'
                disabled={!watch(`description${index+1}`)}
                label={watch(`${Object.keys(x)}`)}
                labelPlacement="top"

              />
            ))}
           
          </RadioGroup>
        </FormControl>      
      </Grid>
  
  }


  const placeholderInput = (i) => {
    if(i === 1){
      return 'opción 1- Mínimo' 
    }
    if(i === valuesOptions.length ){
      return `opción ${valuesOptions.length}- Máximo`
    }
    else {
      return `opción ${i}`
    }
  }

  const handleClosePreview = () => props?.closePreview()
   
  if(props?.isAssess){

    return <FieldAssess 
             isDetail={props?.isDetail}
             valueQuestion={props?.mapQuestionAssess?.valueApplied}
             question={props?.dataInitial?.question}
             checkbox={selectOptions}
             uuid={props?.mapQuestionAssess?.uuid}
             valuesRanking={_.orderBy(props?.mapQuestionAssess?.questionAlternativeList, ['position'],['asc'])}
           />
   } else if (props?.isAnswer){
    return <FieldAnswer
              position={props?.index}
              isDetail={props?.isDetail}
              valueQuestion={props?.mapQuestionAssess?.valueApplied}
              question={props?.dataInitial?.question}
              checkbox={selectOptions}
              uuid={props?.mapQuestionAssess?.uuid}
              valuesRanking={props?.mapQuestionAssess?.questionAlternativeList}
          />
   }else {
    return (
      <>
      {props?.preview ? 
        < ModalPreview 
          scale={true} 
          open={props?.preview} 
          children={selectOptions()}
          handleClose={handleClosePreview}
          question={props?.dataInitial?.question}           
        /> 
              :  null
        }
       
            <form id={props.getDataCreate ? 'prueba':"formInitial6"} onSubmit={handleSubmit(onSubmit)} style={{marginTop: 37, width: '100%'}}>
            <Grid container spacing={1} alignItems="flex-start" style={{marginLeft: '0px'}}>
              <Grid item container lg={12} spacing={3}>
                {newOrderOptions()?.map((x, index) => 
                <React.Fragment key={index}> 
                  <Grid  item xs={3} sm={3} md={3} lg={props.isQuestionary || props?.showDetail || props?.isEdit? 2.4 :3} style={{display: 'flex', alignItems: 'center'}}>
                    <FormControl variant='outlined' fullWidth size="small">
                      <Controller 
                      control={control}
                      render={({field, fieldState})=> (
                        <TextField
                        {...register(`${Object.keys(x)}`)}
                        disabled
                        className="fieldText"
                        fullWidth
                        value={watch(`${Object.keys(x)}`)}
                        size="small"
                        placeholder={placeholderInput(index+1)}
                        error={!!errors.hasOwnProperty(`description${index+1}`) && errors[`description${index+1}`].message}
                        helperText={!!errors.hasOwnProperty(`description${index+1}`) && errors[`description${index+1}`].message}
                      />
                      )}
                      />
                    </FormControl>
                  </Grid> 
                </React.Fragment> 
                )}
              </Grid>
              <Grid item lg={12} md={12} className="wrapperCheckbox">
                {selectOptions()}
              </Grid>             
            </Grid>  
            </form>
           
      </>
    )
  }
}

export default Index
