export const dataEditModel = {
    "uuid": "da218c83-6c98-483b-830b-2e693f1cedd8",
    "createdAt": "2024-02-14T14:20:02.628",
    "lastModifiedAt": "2024-02-14T14:20:02.628",
    "resultAlready": true,
    "automatic": true,
    "startDate": "2024-02-21",
    "endDate": "2024-02-24",
    "creatorUser": {
        "uuid": "e35e77e0-215f-428e-9f2e-47eee54c6e57",
        "username": "P10101012",
        "name": "Stephen",
        "surname": "Dollman",
        "roles": [
            {
                "uuid": "94337cd4-5ef3-4c8b-a054-a71a6be53c2f",
                "createdAt": "2022-08-23T00:00:00",
                "lastModifiedAt": "2022-08-23T00:00:00",
                "name": "EVALUATOR"
            },
            {
                "uuid": "2218e156-00a5-4338-b8b8-82e1b042cede",
                "createdAt": "2022-08-23T00:00:00",
                "lastModifiedAt": "2022-08-23T00:00:00",
                "name": "EVALUATED"
            },
            {
                "uuid": "6afbd312-be3d-4da6-8bf3-3d16c89dbdef",
                "createdAt": "2022-08-23T00:00:00",
                "lastModifiedAt": "2022-08-23T00:00:00",
                "name": "ADMINISTRATOR"
            }
        ]
    },
    "performanceEvaluation": {
        "uuid": "f1a09aac-5ebd-44d2-a1b5-4d879cc62081",
        "createdAt": "2024-01-24T18:17:30.181",
        "lastModifiedAt": "2024-01-24T18:19:06.261",
        "name": "Modelo de evaluacion con Tipos de evaluacion",
        "startDate": "2024-01-24",
        "endDate": "2024-01-31",
        "status": "APPLIED",
        "creatorUser": {
            "uuid": "e35e77e0-215f-428e-9f2e-47eee54c6e57",
            "username": "P10101012",
            "name": "Stephen",
            "surname": "Dollman",
            "roles": [
                {
                    "uuid": "6afbd312-be3d-4da6-8bf3-3d16c89dbdef",
                    "createdAt": "2022-08-23T00:00:00",
                    "lastModifiedAt": "2022-08-23T00:00:00",
                    "name": "ADMINISTRATOR"
                },
                {
                    "uuid": "2218e156-00a5-4338-b8b8-82e1b042cede",
                    "createdAt": "2022-08-23T00:00:00",
                    "lastModifiedAt": "2022-08-23T00:00:00",
                    "name": "EVALUATED"
                },
                {
                    "uuid": "94337cd4-5ef3-4c8b-a054-a71a6be53c2f",
                    "createdAt": "2022-08-23T00:00:00",
                    "lastModifiedAt": "2022-08-23T00:00:00",
                    "name": "EVALUATOR"
                }
            ]
        },
        "description": "creacion de un modelo de evaluacion prueba",
        "competences": [
            {
                "position": "0",
                "domainLevel": 1,
                "domainLevelObtained": 0,
                "value": 50,
                "valueObtained": 0,
                "competence": {
                    "uuid": "462f50c8-595b-4140-8c62-bedd6884258a",
                    "createdAt": "2024-01-11T16:32:13.436",
                    "lastModifiedAt": "2024-01-11T16:32:13.436",
                    "code": 35,
                    "name": "Criterio prueba Niveles de dominio",
                    "description": "prueba1",
                    "status": "ACTIVE",
                    "domainLevelList": [
                        {
                            "number": 1,
                            "conductList": [
                                {
                                    "uuid": "aad3d490-e848-41eb-9787-614b2329d9ec",
                                    "createdAt": "2022-09-07T13:56:13.863",
                                    "lastModifiedAt": "2022-09-07T13:56:13.863",
                                    "name": "Administra practicas operables que adopta de acuerdo con las necesidades específicas de la organización.\t",
                                    "code": 89,
                                    "status": "ACTIVE"
                                },
                                {
                                    "uuid": "74ae1f86-ac78-4cbf-91b9-ba60af9f462c",
                                    "createdAt": "2022-09-07T14:05:51.762",
                                    "lastModifiedAt": "2022-09-07T14:05:51.762",
                                    "name": "Analiza la información disponible para la toma de decisiones acertadas. \t",
                                    "code": 115,
                                    "status": "ACTIVE"
                                },
                                {
                                    "uuid": "5b14196e-da4f-4858-a988-aa8c848e3819",
                                    "createdAt": "2022-09-07T14:06:27.976",
                                    "lastModifiedAt": "2022-09-07T14:06:27.976",
                                    "name": "Accede a la mayor cantidad de información a fin de tomar una buena decisión. \t",
                                    "code": 119,
                                    "status": "ACTIVE"
                                }
                            ]
                        }
                    ]
                },
                "conducts": [
                    {
                        "position": "0",
                        "value": 33.33,
                        "uuid": "aad3d490-e848-41eb-9787-614b2329d9ec",
                        "createdAt": "2022-09-07T13:56:13.863",
                        "lastModifiedAt": "2022-09-07T13:56:13.863",
                        "name": "Administra practicas operables que adopta de acuerdo con las necesidades específicas de la organización.\t",
                        "code": 89,
                        "status": "ACTIVE",
                        "questions": [
                            {
                                "uuid": "954ec635-1827-475a-aa0f-dd19b6e01e4e",
                                "createdAt": "2024-01-22T13:41:49.146",
                                "lastModifiedAt": "2024-01-22T13:41:49.146",
                                "position": "1",
                                "question": "Realizar Review ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            },
                            {
                                "uuid": "ba483fe7-363a-409a-8811-0ba208a38819",
                                "createdAt": "2024-01-22T13:42:59.602",
                                "lastModifiedAt": "2024-01-22T13:42:59.602",
                                "position": "0",
                                "question": "Pregunta de Formatos ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            }
                        ]
                    },
                    {
                        "position": "1",
                        "value": 33.33,
                        "uuid": "74ae1f86-ac78-4cbf-91b9-ba60af9f462c",
                        "createdAt": "2022-09-07T14:05:51.762",
                        "lastModifiedAt": "2022-09-07T14:05:51.762",
                        "name": "Analiza la información disponible para la toma de decisiones acertadas. \t",
                        "code": 115,
                        "status": "ACTIVE",
                        "questions": [
                            {
                                "uuid": "ba483fe7-363a-409a-8811-0ba208a38819",
                                "createdAt": "2024-01-22T13:42:59.602",
                                "lastModifiedAt": "2024-01-22T13:42:59.602",
                                "position": "0",
                                "question": "Pregunta de Formatos ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            }
                        ]
                    },
                    {
                        "position": "2",
                        "value": 33.33,
                        "uuid": "5b14196e-da4f-4858-a988-aa8c848e3819",
                        "createdAt": "2022-09-07T14:06:27.976",
                        "lastModifiedAt": "2022-09-07T14:06:27.976",
                        "name": "Accede a la mayor cantidad de información a fin de tomar una buena decisión. \t",
                        "code": 119,
                        "status": "ACTIVE",
                        "questions": [
                            {
                                "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                                "createdAt": "2024-01-18T22:59:16.411",
                                "lastModifiedAt": "2024-01-18T22:59:16.411",
                                "position": "0",
                                "question": "Validación de preguntas ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "position": "1",
                "domainLevel": 1,
                "domainLevelObtained": 0,
                "value": 50,
                "valueObtained": 0,
                "competence": {
                    "uuid": "62b3a231-bc90-4e84-b943-a09c35a808a3",
                    "createdAt": "2024-01-09T16:24:32.301",
                    "lastModifiedAt": "2024-01-09T16:24:32.301",
                    "code": 34,
                    "name": "COMPETENCIA PRUEBA 1",
                    "description": "FGFGFGFGFG",
                    "status": "ACTIVE",
                    "domainLevelList": [
                        {
                            "number": 1,
                            "conductList": [
                                {
                                    "uuid": "37e03b6f-a29f-4a9d-861b-a64a710af6ba",
                                    "createdAt": "2022-09-07T13:50:35.91",
                                    "lastModifiedAt": "2022-09-07T13:50:35.91",
                                    "name": "Administra en forma autónoma su trabajo de acuerdo a las normas establecidas y los estándares de la organización.",
                                    "code": 48,
                                    "status": "ACTIVE"
                                },
                                {
                                    "uuid": "aad3d490-e848-41eb-9787-614b2329d9ec",
                                    "createdAt": "2022-09-07T13:56:13.863",
                                    "lastModifiedAt": "2022-09-07T13:56:13.863",
                                    "name": "Administra practicas operables que adopta de acuerdo con las necesidades específicas de la organización.\t",
                                    "code": 89,
                                    "status": "ACTIVE"
                                },
                                {
                                    "uuid": "74ae1f86-ac78-4cbf-91b9-ba60af9f462c",
                                    "createdAt": "2022-09-07T14:05:51.762",
                                    "lastModifiedAt": "2022-09-07T14:05:51.762",
                                    "name": "Analiza la información disponible para la toma de decisiones acertadas. \t",
                                    "code": 115,
                                    "status": "ACTIVE"
                                },
                                {
                                    "uuid": "491c4dda-494c-48db-83ba-228a2c2f1b0b",
                                    "createdAt": "2022-09-07T14:06:15.382",
                                    "lastModifiedAt": "2022-09-07T14:06:15.382",
                                    "name": "Analiza las variantes de acción teniendo en cuenta los lineamientos pre- establecidos por la organización. \t",
                                    "code": 117,
                                    "status": "ACTIVE"
                                },
                                {
                                    "uuid": "5b14196e-da4f-4858-a988-aa8c848e3819",
                                    "createdAt": "2022-09-07T14:06:27.976",
                                    "lastModifiedAt": "2022-09-07T14:06:27.976",
                                    "name": "Accede a la mayor cantidad de información a fin de tomar una buena decisión. \t",
                                    "code": 119,
                                    "status": "ACTIVE"
                                },
                                {
                                    "uuid": "a3cb38e7-8ead-40c7-b8f2-6541e48b78e8",
                                    "createdAt": "2023-05-23T20:28:01.739",
                                    "lastModifiedAt": "2023-05-23T20:28:01.74",
                                    "name": "Aplica adecuadamente los procedimientos de atención al paciente de manera segura.",
                                    "code": 186,
                                    "status": "ACTIVE"
                                }
                            ]
                        }
                    ]
                },
                "conducts": [
                    {
                        "position": "0",
                        "value": 16.67,
                        "uuid": "37e03b6f-a29f-4a9d-861b-a64a710af6ba",
                        "createdAt": "2022-09-07T13:50:35.91",
                        "lastModifiedAt": "2022-09-07T13:50:35.91",
                        "name": "Administra en forma autónoma su trabajo de acuerdo a las normas establecidas y los estándares de la organización.",
                        "code": 48,
                        "status": "ACTIVE",
                        "questions": [
                            {
                                "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                                "createdAt": "2024-01-18T22:59:16.411",
                                "lastModifiedAt": "2024-01-18T22:59:16.411",
                                "position": "0",
                                "question": "Validación de preguntas ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            }
                        ]
                    },
                    {
                        "position": "1",
                        "value": 16.67,
                        "uuid": "aad3d490-e848-41eb-9787-614b2329d9ec",
                        "createdAt": "2022-09-07T13:56:13.863",
                        "lastModifiedAt": "2022-09-07T13:56:13.863",
                        "name": "Administra practicas operables que adopta de acuerdo con las necesidades específicas de la organización.\t",
                        "code": 89,
                        "status": "ACTIVE",
                        "questions": [
                            {
                                "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                                "createdAt": "2024-01-18T22:59:16.411",
                                "lastModifiedAt": "2024-01-18T22:59:16.411",
                                "position": "1",
                                "question": "Validación de preguntas ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            },
                            {
                                "uuid": "954ec635-1827-475a-aa0f-dd19b6e01e4e",
                                "createdAt": "2024-01-22T13:41:49.146",
                                "lastModifiedAt": "2024-01-22T13:41:49.146",
                                "position": "0",
                                "question": "Realizar Review ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            }
                        ]
                    },
                    {
                        "position": "2",
                        "value": 16.67,
                        "uuid": "74ae1f86-ac78-4cbf-91b9-ba60af9f462c",
                        "createdAt": "2022-09-07T14:05:51.762",
                        "lastModifiedAt": "2022-09-07T14:05:51.762",
                        "name": "Analiza la información disponible para la toma de decisiones acertadas. \t",
                        "code": 115,
                        "status": "ACTIVE",
                        "questions": [
                            {
                                "uuid": "b51eaac2-6513-44d8-a914-471a4ee9d922",
                                "createdAt": "2024-01-16T23:43:10.602",
                                "lastModifiedAt": "2024-01-16T23:43:10.602",
                                "position": "2",
                                "question": "pregunta de prueba ",
                                "answerAlternativeCode": "ESL",
                                "numberOfQuestionAlternative": "4",
                                "questionAlternativeList": [
                                    {
                                        "uuid": "8a1f3402-bd40-4a2c-baae-94fa2ad772e2",
                                        "createdAt": "2024-01-16T23:43:10.61",
                                        "lastModifiedAt": "2024-01-16T23:43:10.61",
                                        "key": "Ns/Na",
                                        "value": "0",
                                        "position": "0"
                                    },
                                    {
                                        "uuid": "6bdb82a4-667f-4fd4-b23b-98a07c464f38",
                                        "createdAt": "2024-01-16T23:43:10.613",
                                        "lastModifiedAt": "2024-01-16T23:43:10.613",
                                        "key": "Regular",
                                        "value": "0",
                                        "position": "1"
                                    },
                                    {
                                        "uuid": "d0a705b3-f3c7-494e-8dee-ecca0566626f",
                                        "createdAt": "2024-01-16T23:43:10.615",
                                        "lastModifiedAt": "2024-01-16T23:43:10.615",
                                        "key": "Aceptable",
                                        "value": "0",
                                        "position": "2"
                                    },
                                    {
                                        "uuid": "c1f9ff3d-71be-4022-853e-ce2094d431c4",
                                        "createdAt": "2024-01-16T23:43:10.617",
                                        "lastModifiedAt": "2024-01-16T23:43:10.617",
                                        "key": "Bueno",
                                        "value": "0",
                                        "position": "3"
                                    }
                                ],
                                "tag": {
                                    "uuid": "74bd3230-04b1-402a-9e03-fc5a338043e4",
                                    "createdAt": "2022-09-07T15:43:11.877",
                                    "lastModifiedAt": "2022-09-07T15:43:11.877",
                                    "code": 75,
                                    "name": "DISPOSICION1",
                                    "status": "ACTIVE"
                                }
                            },
                            {
                                "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                                "createdAt": "2024-01-18T22:59:16.411",
                                "lastModifiedAt": "2024-01-18T22:59:16.411",
                                "position": "1",
                                "question": "Validación de preguntas ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            },
                            {
                                "uuid": "954ec635-1827-475a-aa0f-dd19b6e01e4e",
                                "createdAt": "2024-01-22T13:41:49.146",
                                "lastModifiedAt": "2024-01-22T13:41:49.146",
                                "position": "0",
                                "question": "Realizar Review ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            }
                        ]
                    },
                    {
                        "position": "3",
                        "value": 16.67,
                        "uuid": "491c4dda-494c-48db-83ba-228a2c2f1b0b",
                        "createdAt": "2022-09-07T14:06:15.382",
                        "lastModifiedAt": "2022-09-07T14:06:15.382",
                        "name": "Analiza las variantes de acción teniendo en cuenta los lineamientos pre- establecidos por la organización. \t",
                        "code": 117,
                        "status": "ACTIVE",
                        "questions": [
                            {
                                "uuid": "fe74c03c-2fe1-4eea-b0e2-baa2fd888ad8",
                                "createdAt": "2024-01-16T23:40:31.668",
                                "lastModifiedAt": "2024-01-16T23:40:31.668",
                                "position": "0",
                                "question": "prueba de pregunta ",
                                "answerAlternativeCode": "ESL",
                                "numberOfQuestionAlternative": "4",
                                "questionAlternativeList": [
                                    {
                                        "uuid": "af6a4546-ab7d-4d7d-957c-2e257d75b580",
                                        "createdAt": "2024-01-16T23:40:35.632",
                                        "lastModifiedAt": "2024-01-16T23:40:35.632",
                                        "key": "Ns/Na",
                                        "value": "0",
                                        "position": "0"
                                    },
                                    {
                                        "uuid": "3c14bd7a-0159-4763-8f29-0e2739f5c135",
                                        "createdAt": "2024-01-16T23:40:35.644",
                                        "lastModifiedAt": "2024-01-16T23:40:35.644",
                                        "key": "Regular",
                                        "value": "0",
                                        "position": "1"
                                    },
                                    {
                                        "uuid": "bd0b4fdc-0b71-4c2c-b73d-c6c2bbb3d550",
                                        "createdAt": "2024-01-16T23:40:35.647",
                                        "lastModifiedAt": "2024-01-16T23:40:35.647",
                                        "key": "Aceptable",
                                        "value": "0",
                                        "position": "2"
                                    },
                                    {
                                        "uuid": "f6cab23b-656a-4ee1-8960-7e307c3c8aa7",
                                        "createdAt": "2024-01-16T23:40:35.649",
                                        "lastModifiedAt": "2024-01-16T23:40:35.649",
                                        "key": "Bueno",
                                        "value": "0",
                                        "position": "3"
                                    }
                                ],
                                "tag": {
                                    "uuid": "ac7c50d4-6175-4d0d-afa8-817c0a961338",
                                    "createdAt": "2022-09-07T15:48:35.958",
                                    "lastModifiedAt": "2022-09-07T15:48:35.958",
                                    "code": 82,
                                    "name": "DECISIONES3",
                                    "status": "ACTIVE"
                                }
                            },
                            {
                                "uuid": "b51eaac2-6513-44d8-a914-471a4ee9d922",
                                "createdAt": "2024-01-16T23:43:10.602",
                                "lastModifiedAt": "2024-01-16T23:43:10.602",
                                "position": "1",
                                "question": "pregunta de prueba ",
                                "answerAlternativeCode": "ESL",
                                "numberOfQuestionAlternative": "4",
                                "questionAlternativeList": [
                                    {
                                        "uuid": "8a1f3402-bd40-4a2c-baae-94fa2ad772e2",
                                        "createdAt": "2024-01-16T23:43:10.61",
                                        "lastModifiedAt": "2024-01-16T23:43:10.61",
                                        "key": "Ns/Na",
                                        "value": "0",
                                        "position": "0"
                                    },
                                    {
                                        "uuid": "6bdb82a4-667f-4fd4-b23b-98a07c464f38",
                                        "createdAt": "2024-01-16T23:43:10.613",
                                        "lastModifiedAt": "2024-01-16T23:43:10.613",
                                        "key": "Regular",
                                        "value": "0",
                                        "position": "1"
                                    },
                                    {
                                        "uuid": "d0a705b3-f3c7-494e-8dee-ecca0566626f",
                                        "createdAt": "2024-01-16T23:43:10.615",
                                        "lastModifiedAt": "2024-01-16T23:43:10.615",
                                        "key": "Aceptable",
                                        "value": "0",
                                        "position": "2"
                                    },
                                    {
                                        "uuid": "c1f9ff3d-71be-4022-853e-ce2094d431c4",
                                        "createdAt": "2024-01-16T23:43:10.617",
                                        "lastModifiedAt": "2024-01-16T23:43:10.617",
                                        "key": "Bueno",
                                        "value": "0",
                                        "position": "3"
                                    }
                                ],
                                "tag": {
                                    "uuid": "74bd3230-04b1-402a-9e03-fc5a338043e4",
                                    "createdAt": "2022-09-07T15:43:11.877",
                                    "lastModifiedAt": "2022-09-07T15:43:11.877",
                                    "code": 75,
                                    "name": "DISPOSICION1",
                                    "status": "ACTIVE"
                                }
                            }
                        ]
                    },
                    {
                        "position": "4",
                        "value": 16.67,
                        "uuid": "5b14196e-da4f-4858-a988-aa8c848e3819",
                        "createdAt": "2022-09-07T14:06:27.976",
                        "lastModifiedAt": "2022-09-07T14:06:27.976",
                        "name": "Accede a la mayor cantidad de información a fin de tomar una buena decisión. \t",
                        "code": 119,
                        "status": "ACTIVE",
                        "questions": [
                            {
                                "uuid": "b51eaac2-6513-44d8-a914-471a4ee9d922",
                                "createdAt": "2024-01-16T23:43:10.602",
                                "lastModifiedAt": "2024-01-16T23:43:10.602",
                                "position": "3",
                                "question": "pregunta de prueba ",
                                "answerAlternativeCode": "ESL",
                                "numberOfQuestionAlternative": "4",
                                "questionAlternativeList": [
                                    {
                                        "uuid": "8a1f3402-bd40-4a2c-baae-94fa2ad772e2",
                                        "createdAt": "2024-01-16T23:43:10.61",
                                        "lastModifiedAt": "2024-01-16T23:43:10.61",
                                        "key": "Ns/Na",
                                        "value": "0",
                                        "position": "0"
                                    },
                                    {
                                        "uuid": "6bdb82a4-667f-4fd4-b23b-98a07c464f38",
                                        "createdAt": "2024-01-16T23:43:10.613",
                                        "lastModifiedAt": "2024-01-16T23:43:10.613",
                                        "key": "Regular",
                                        "value": "0",
                                        "position": "1"
                                    },
                                    {
                                        "uuid": "d0a705b3-f3c7-494e-8dee-ecca0566626f",
                                        "createdAt": "2024-01-16T23:43:10.615",
                                        "lastModifiedAt": "2024-01-16T23:43:10.615",
                                        "key": "Aceptable",
                                        "value": "0",
                                        "position": "2"
                                    },
                                    {
                                        "uuid": "c1f9ff3d-71be-4022-853e-ce2094d431c4",
                                        "createdAt": "2024-01-16T23:43:10.617",
                                        "lastModifiedAt": "2024-01-16T23:43:10.617",
                                        "key": "Bueno",
                                        "value": "0",
                                        "position": "3"
                                    }
                                ],
                                "tag": {
                                    "uuid": "74bd3230-04b1-402a-9e03-fc5a338043e4",
                                    "createdAt": "2022-09-07T15:43:11.877",
                                    "lastModifiedAt": "2022-09-07T15:43:11.877",
                                    "code": 75,
                                    "name": "DISPOSICION1",
                                    "status": "ACTIVE"
                                }
                            },
                            {
                                "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                                "createdAt": "2024-01-18T22:59:16.411",
                                "lastModifiedAt": "2024-01-18T22:59:16.411",
                                "position": "2",
                                "question": "Validación de preguntas ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            },
                            {
                                "uuid": "954ec635-1827-475a-aa0f-dd19b6e01e4e",
                                "createdAt": "2024-01-22T13:41:49.146",
                                "lastModifiedAt": "2024-01-22T13:41:49.146",
                                "position": "1",
                                "question": "Realizar Review ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            },
                            {
                                "uuid": "ba483fe7-363a-409a-8811-0ba208a38819",
                                "createdAt": "2024-01-22T13:42:59.602",
                                "lastModifiedAt": "2024-01-22T13:42:59.602",
                                "position": "0",
                                "question": "Pregunta de Formatos ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            }
                        ]
                    },
                    {
                        "position": "5",
                        "value": 16.67,
                        "uuid": "a3cb38e7-8ead-40c7-b8f2-6541e48b78e8",
                        "createdAt": "2023-05-23T20:28:01.739",
                        "lastModifiedAt": "2023-05-23T20:28:01.74",
                        "name": "Aplica adecuadamente los procedimientos de atención al paciente de manera segura.",
                        "code": 186,
                        "status": "ACTIVE",
                        "questions": [
                            {
                                "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                                "createdAt": "2024-01-18T22:59:16.411",
                                "lastModifiedAt": "2024-01-18T22:59:16.411",
                                "position": "1",
                                "question": "Validación de preguntas ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            },
                            {
                                "uuid": "954ec635-1827-475a-aa0f-dd19b6e01e4e",
                                "createdAt": "2024-01-22T13:41:49.146",
                                "lastModifiedAt": "2024-01-22T13:41:49.146",
                                "position": "0",
                                "question": "Realizar Review ",
                                "answerAlternativeCode": "TEX",
                                "questionAlternativeList": [],
                                "tag": {
                                    "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                    "createdAt": "2023-03-09T21:41:02.772",
                                    "lastModifiedAt": "2023-03-09T21:41:02.772",
                                    "code": 112,
                                    "name": "AUTEVALUACIONDOCENTE",
                                    "status": "ACTIVE"
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "applicationTypes": [
        {
            "uuid": "59495c75-2642-4f69-a78f-5ed5bfe67dbd",
            "createdAt": "2024-02-14T14:20:10.935",
            "lastModifiedAt": "2024-02-14T14:20:10.935",
            "type": "SELF_EVALUATION",
            "percentage": 30,
            "totalObtained": 0,
            "numberOfEvaluationsAnswered": 0,
            "numberOfEvaluationsApplied": 0,
            "totalObtainedInPercentage": 0
        },
        {
            "uuid": "8ff9b9a5-b405-4161-a457-52e390b911b6",
            "createdAt": "2024-02-14T14:20:10.903",
            "lastModifiedAt": "2024-02-14T14:20:10.903",
            "type": "PEER_EVALUATION",
            "percentage": 40,
            "totalObtained": 0,
            "numberOfEvaluationsAnswered": 0,
            "numberOfEvaluationsApplied": 0,
            "totalObtainedInPercentage": 0
        },
        {
            "uuid": "eff77d22-ea14-4e44-8d5a-ea2d6b4ed6f4",
            "createdAt": "2024-02-14T14:20:10.934",
            "lastModifiedAt": "2024-02-14T14:20:10.934",
            "type": "LEADER_EVALUATION",
            "percentage": 30,
            "totalObtained": 0,
            "numberOfEvaluationsAnswered": 0,
            "numberOfEvaluationsApplied": 0,
            "totalObtainedInPercentage": 0
        }
    ]
}

export const model = {
    "uuid": "f1a09aac-5ebd-44d2-a1b5-4d879cc62081",
    "createdAt": "2024-01-24T18:17:30.181",
    "lastModifiedAt": "2024-01-24T18:19:06.261",
    "name": "Modelo de evaluacion con Tipos de evaluacion",
    "startDate": "2024-01-24",
    "endDate": "2024-01-31",
    "status": "APPLIED",
    "creatorUser": {
        "uuid": "e35e77e0-215f-428e-9f2e-47eee54c6e57",
        "username": "P10101012",
        "name": "Stephen",
        "surname": "Dollman",
        "roles": [
            {
                "uuid": "6afbd312-be3d-4da6-8bf3-3d16c89dbdef",
                "createdAt": "2022-08-23T00:00:00",
                "lastModifiedAt": "2022-08-23T00:00:00",
                "name": "ADMINISTRATOR"
            },
            {
                "uuid": "94337cd4-5ef3-4c8b-a054-a71a6be53c2f",
                "createdAt": "2022-08-23T00:00:00",
                "lastModifiedAt": "2022-08-23T00:00:00",
                "name": "EVALUATOR"
            },
            {
                "uuid": "2218e156-00a5-4338-b8b8-82e1b042cede",
                "createdAt": "2022-08-23T00:00:00",
                "lastModifiedAt": "2022-08-23T00:00:00",
                "name": "EVALUATED"
            }
        ]
    },
    "description": "creacion de un modelo de evaluacion prueba",
    "evaluators": [],
    "competences": [
        {
            "position": "1",
            "domainLevel": 1,
            "domainLevelObtained": 0,
            "valueObtained": 0,
            "competence": {
                "uuid": "62b3a231-bc90-4e84-b943-a09c35a808a3",
                "createdAt": "2024-01-09T16:24:32.301",
                "lastModifiedAt": "2024-01-09T16:24:32.301",
                "code": 34,
                "name": "COMPETENCIA PRUEBA 1",
                "description": "FGFGFGFGFG",
                "status": "ACTIVE",
                "domainLevelList": [
                    {
                        "number": 1,
                        "conductList": [
                            {
                                "uuid": "37e03b6f-a29f-4a9d-861b-a64a710af6ba",
                                "createdAt": "2022-09-07T13:50:35.91",
                                "lastModifiedAt": "2022-09-07T13:50:35.91",
                                "name": "Administra en forma autónoma su trabajo de acuerdo a las normas establecidas y los estándares de la organización.",
                                "code": 48,
                                "status": "ACTIVE"
                            },
                            {
                                "uuid": "aad3d490-e848-41eb-9787-614b2329d9ec",
                                "createdAt": "2022-09-07T13:56:13.863",
                                "lastModifiedAt": "2022-09-07T13:56:13.863",
                                "name": "Administra practicas operables que adopta de acuerdo con las necesidades específicas de la organización.\t",
                                "code": 89,
                                "status": "ACTIVE"
                            },
                            {
                                "uuid": "74ae1f86-ac78-4cbf-91b9-ba60af9f462c",
                                "createdAt": "2022-09-07T14:05:51.762",
                                "lastModifiedAt": "2022-09-07T14:05:51.762",
                                "name": "Analiza la información disponible para la toma de decisiones acertadas. \t",
                                "code": 115,
                                "status": "ACTIVE"
                            },
                            {
                                "uuid": "491c4dda-494c-48db-83ba-228a2c2f1b0b",
                                "createdAt": "2022-09-07T14:06:15.382",
                                "lastModifiedAt": "2022-09-07T14:06:15.382",
                                "name": "Analiza las variantes de acción teniendo en cuenta los lineamientos pre- establecidos por la organización. \t",
                                "code": 117,
                                "status": "ACTIVE"
                            },
                            {
                                "uuid": "5b14196e-da4f-4858-a988-aa8c848e3819",
                                "createdAt": "2022-09-07T14:06:27.976",
                                "lastModifiedAt": "2022-09-07T14:06:27.976",
                                "name": "Accede a la mayor cantidad de información a fin de tomar una buena decisión. \t",
                                "code": 119,
                                "status": "ACTIVE"
                            },
                            {
                                "uuid": "a3cb38e7-8ead-40c7-b8f2-6541e48b78e8",
                                "createdAt": "2023-05-23T20:28:01.739",
                                "lastModifiedAt": "2023-05-23T20:28:01.74",
                                "name": "Aplica adecuadamente los procedimientos de atención al paciente de manera segura.",
                                "code": 186,
                                "status": "ACTIVE"
                            }
                        ]
                    }
                ]
            },
            "conducts": [
                {
                    "position": "0",
                    "uuid": "37e03b6f-a29f-4a9d-861b-a64a710af6ba",
                    "createdAt": "2022-09-07T13:50:35.91",
                    "lastModifiedAt": "2022-09-07T13:50:35.91",
                    "name": "Administra en forma autónoma su trabajo de acuerdo a las normas establecidas y los estándares de la organización.",
                    "code": 48,
                    "status": "ACTIVE",
                    "questions": [
                        {
                            "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                            "createdAt": "2024-01-18T22:59:16.411",
                            "lastModifiedAt": "2024-01-18T22:59:16.411",
                            "position": "0",
                            "question": "Validación de preguntas ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        }
                    ]
                },
                {
                    "position": "1",
                    "uuid": "aad3d490-e848-41eb-9787-614b2329d9ec",
                    "createdAt": "2022-09-07T13:56:13.863",
                    "lastModifiedAt": "2022-09-07T13:56:13.863",
                    "name": "Administra practicas operables que adopta de acuerdo con las necesidades específicas de la organización.\t",
                    "code": 89,
                    "status": "ACTIVE",
                    "questions": [
                        {
                            "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                            "createdAt": "2024-01-18T22:59:16.411",
                            "lastModifiedAt": "2024-01-18T22:59:16.411",
                            "position": "1",
                            "question": "Validación de preguntas ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        },
                        {
                            "uuid": "954ec635-1827-475a-aa0f-dd19b6e01e4e",
                            "createdAt": "2024-01-22T13:41:49.146",
                            "lastModifiedAt": "2024-01-22T13:41:49.146",
                            "position": "0",
                            "question": "Realizar Review ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        }
                    ]
                },
                {
                    "position": "2",
                    "uuid": "74ae1f86-ac78-4cbf-91b9-ba60af9f462c",
                    "createdAt": "2022-09-07T14:05:51.762",
                    "lastModifiedAt": "2022-09-07T14:05:51.762",
                    "name": "Analiza la información disponible para la toma de decisiones acertadas. \t",
                    "code": 115,
                    "status": "ACTIVE",
                    "questions": [
                        {
                            "uuid": "b51eaac2-6513-44d8-a914-471a4ee9d922",
                            "createdAt": "2024-01-16T23:43:10.602",
                            "lastModifiedAt": "2024-01-16T23:43:10.602",
                            "position": "2",
                            "question": "pregunta de prueba ",
                            "answerAlternativeCode": "ESL",
                            "numberOfQuestionAlternative": "4",
                            "questionAlternativeList": [
                                {
                                    "uuid": "8a1f3402-bd40-4a2c-baae-94fa2ad772e2",
                                    "createdAt": "2024-01-16T23:43:10.61",
                                    "lastModifiedAt": "2024-01-16T23:43:10.61",
                                    "key": "Ns/Na",
                                    "value": "0",
                                    "position": "0"
                                },
                                {
                                    "uuid": "6bdb82a4-667f-4fd4-b23b-98a07c464f38",
                                    "createdAt": "2024-01-16T23:43:10.613",
                                    "lastModifiedAt": "2024-01-16T23:43:10.613",
                                    "key": "Regular",
                                    "value": "0",
                                    "position": "1"
                                },
                                {
                                    "uuid": "d0a705b3-f3c7-494e-8dee-ecca0566626f",
                                    "createdAt": "2024-01-16T23:43:10.615",
                                    "lastModifiedAt": "2024-01-16T23:43:10.615",
                                    "key": "Aceptable",
                                    "value": "0",
                                    "position": "2"
                                },
                                {
                                    "uuid": "c1f9ff3d-71be-4022-853e-ce2094d431c4",
                                    "createdAt": "2024-01-16T23:43:10.617",
                                    "lastModifiedAt": "2024-01-16T23:43:10.617",
                                    "key": "Bueno",
                                    "value": "0",
                                    "position": "3"
                                }
                            ],
                            "tag": {
                                "uuid": "74bd3230-04b1-402a-9e03-fc5a338043e4",
                                "createdAt": "2022-09-07T15:43:11.877",
                                "lastModifiedAt": "2022-09-07T15:43:11.877",
                                "code": 75,
                                "name": "DISPOSICION1",
                                "status": "ACTIVE"
                            }
                        },
                        {
                            "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                            "createdAt": "2024-01-18T22:59:16.411",
                            "lastModifiedAt": "2024-01-18T22:59:16.411",
                            "position": "1",
                            "question": "Validación de preguntas ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        },
                        {
                            "uuid": "954ec635-1827-475a-aa0f-dd19b6e01e4e",
                            "createdAt": "2024-01-22T13:41:49.146",
                            "lastModifiedAt": "2024-01-22T13:41:49.146",
                            "position": "0",
                            "question": "Realizar Review ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        }
                    ]
                },
                {
                    "position": "3",
                    "uuid": "491c4dda-494c-48db-83ba-228a2c2f1b0b",
                    "createdAt": "2022-09-07T14:06:15.382",
                    "lastModifiedAt": "2022-09-07T14:06:15.382",
                    "name": "Analiza las variantes de acción teniendo en cuenta los lineamientos pre- establecidos por la organización. \t",
                    "code": 117,
                    "status": "ACTIVE",
                    "questions": [
                        {
                            "uuid": "fe74c03c-2fe1-4eea-b0e2-baa2fd888ad8",
                            "createdAt": "2024-01-16T23:40:31.668",
                            "lastModifiedAt": "2024-01-16T23:40:31.668",
                            "position": "0",
                            "question": "prueba de pregunta ",
                            "answerAlternativeCode": "ESL",
                            "numberOfQuestionAlternative": "4",
                            "questionAlternativeList": [
                                {
                                    "uuid": "af6a4546-ab7d-4d7d-957c-2e257d75b580",
                                    "createdAt": "2024-01-16T23:40:35.632",
                                    "lastModifiedAt": "2024-01-16T23:40:35.632",
                                    "key": "Ns/Na",
                                    "value": "0",
                                    "position": "0"
                                },
                                {
                                    "uuid": "3c14bd7a-0159-4763-8f29-0e2739f5c135",
                                    "createdAt": "2024-01-16T23:40:35.644",
                                    "lastModifiedAt": "2024-01-16T23:40:35.644",
                                    "key": "Regular",
                                    "value": "0",
                                    "position": "1"
                                },
                                {
                                    "uuid": "bd0b4fdc-0b71-4c2c-b73d-c6c2bbb3d550",
                                    "createdAt": "2024-01-16T23:40:35.647",
                                    "lastModifiedAt": "2024-01-16T23:40:35.647",
                                    "key": "Aceptable",
                                    "value": "0",
                                    "position": "2"
                                },
                                {
                                    "uuid": "f6cab23b-656a-4ee1-8960-7e307c3c8aa7",
                                    "createdAt": "2024-01-16T23:40:35.649",
                                    "lastModifiedAt": "2024-01-16T23:40:35.649",
                                    "key": "Bueno",
                                    "value": "0",
                                    "position": "3"
                                }
                            ],
                            "tag": {
                                "uuid": "ac7c50d4-6175-4d0d-afa8-817c0a961338",
                                "createdAt": "2022-09-07T15:48:35.958",
                                "lastModifiedAt": "2022-09-07T15:48:35.958",
                                "code": 82,
                                "name": "DECISIONES3",
                                "status": "ACTIVE"
                            }
                        },
                        {
                            "uuid": "b51eaac2-6513-44d8-a914-471a4ee9d922",
                            "createdAt": "2024-01-16T23:43:10.602",
                            "lastModifiedAt": "2024-01-16T23:43:10.602",
                            "position": "1",
                            "question": "pregunta de prueba ",
                            "answerAlternativeCode": "ESL",
                            "numberOfQuestionAlternative": "4",
                            "questionAlternativeList": [
                                {
                                    "uuid": "8a1f3402-bd40-4a2c-baae-94fa2ad772e2",
                                    "createdAt": "2024-01-16T23:43:10.61",
                                    "lastModifiedAt": "2024-01-16T23:43:10.61",
                                    "key": "Ns/Na",
                                    "value": "0",
                                    "position": "0"
                                },
                                {
                                    "uuid": "6bdb82a4-667f-4fd4-b23b-98a07c464f38",
                                    "createdAt": "2024-01-16T23:43:10.613",
                                    "lastModifiedAt": "2024-01-16T23:43:10.613",
                                    "key": "Regular",
                                    "value": "0",
                                    "position": "1"
                                },
                                {
                                    "uuid": "d0a705b3-f3c7-494e-8dee-ecca0566626f",
                                    "createdAt": "2024-01-16T23:43:10.615",
                                    "lastModifiedAt": "2024-01-16T23:43:10.615",
                                    "key": "Aceptable",
                                    "value": "0",
                                    "position": "2"
                                },
                                {
                                    "uuid": "c1f9ff3d-71be-4022-853e-ce2094d431c4",
                                    "createdAt": "2024-01-16T23:43:10.617",
                                    "lastModifiedAt": "2024-01-16T23:43:10.617",
                                    "key": "Bueno",
                                    "value": "0",
                                    "position": "3"
                                }
                            ],
                            "tag": {
                                "uuid": "74bd3230-04b1-402a-9e03-fc5a338043e4",
                                "createdAt": "2022-09-07T15:43:11.877",
                                "lastModifiedAt": "2022-09-07T15:43:11.877",
                                "code": 75,
                                "name": "DISPOSICION1",
                                "status": "ACTIVE"
                            }
                        }
                    ]
                },
                {
                    "position": "4",
                    "uuid": "5b14196e-da4f-4858-a988-aa8c848e3819",
                    "createdAt": "2022-09-07T14:06:27.976",
                    "lastModifiedAt": "2022-09-07T14:06:27.976",
                    "name": "Accede a la mayor cantidad de información a fin de tomar una buena decisión. \t",
                    "code": 119,
                    "status": "ACTIVE",
                    "questions": [
                        {
                            "uuid": "b51eaac2-6513-44d8-a914-471a4ee9d922",
                            "createdAt": "2024-01-16T23:43:10.602",
                            "lastModifiedAt": "2024-01-16T23:43:10.602",
                            "position": "3",
                            "question": "pregunta de prueba ",
                            "answerAlternativeCode": "ESL",
                            "numberOfQuestionAlternative": "4",
                            "questionAlternativeList": [
                                {
                                    "uuid": "8a1f3402-bd40-4a2c-baae-94fa2ad772e2",
                                    "createdAt": "2024-01-16T23:43:10.61",
                                    "lastModifiedAt": "2024-01-16T23:43:10.61",
                                    "key": "Ns/Na",
                                    "value": "0",
                                    "position": "0"
                                },
                                {
                                    "uuid": "6bdb82a4-667f-4fd4-b23b-98a07c464f38",
                                    "createdAt": "2024-01-16T23:43:10.613",
                                    "lastModifiedAt": "2024-01-16T23:43:10.613",
                                    "key": "Regular",
                                    "value": "0",
                                    "position": "1"
                                },
                                {
                                    "uuid": "d0a705b3-f3c7-494e-8dee-ecca0566626f",
                                    "createdAt": "2024-01-16T23:43:10.615",
                                    "lastModifiedAt": "2024-01-16T23:43:10.615",
                                    "key": "Aceptable",
                                    "value": "0",
                                    "position": "2"
                                },
                                {
                                    "uuid": "c1f9ff3d-71be-4022-853e-ce2094d431c4",
                                    "createdAt": "2024-01-16T23:43:10.617",
                                    "lastModifiedAt": "2024-01-16T23:43:10.617",
                                    "key": "Bueno",
                                    "value": "0",
                                    "position": "3"
                                }
                            ],
                            "tag": {
                                "uuid": "74bd3230-04b1-402a-9e03-fc5a338043e4",
                                "createdAt": "2022-09-07T15:43:11.877",
                                "lastModifiedAt": "2022-09-07T15:43:11.877",
                                "code": 75,
                                "name": "DISPOSICION1",
                                "status": "ACTIVE"
                            }
                        },
                        {
                            "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                            "createdAt": "2024-01-18T22:59:16.411",
                            "lastModifiedAt": "2024-01-18T22:59:16.411",
                            "position": "2",
                            "question": "Validación de preguntas ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        },
                        {
                            "uuid": "954ec635-1827-475a-aa0f-dd19b6e01e4e",
                            "createdAt": "2024-01-22T13:41:49.146",
                            "lastModifiedAt": "2024-01-22T13:41:49.146",
                            "position": "1",
                            "question": "Realizar Review ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        },
                        {
                            "uuid": "ba483fe7-363a-409a-8811-0ba208a38819",
                            "createdAt": "2024-01-22T13:42:59.602",
                            "lastModifiedAt": "2024-01-22T13:42:59.602",
                            "position": "0",
                            "question": "Pregunta de Formatos ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        }
                    ]
                },
                {
                    "position": "5",
                    "uuid": "a3cb38e7-8ead-40c7-b8f2-6541e48b78e8",
                    "createdAt": "2023-05-23T20:28:01.739",
                    "lastModifiedAt": "2023-05-23T20:28:01.74",
                    "name": "Aplica adecuadamente los procedimientos de atención al paciente de manera segura.",
                    "code": 186,
                    "status": "ACTIVE",
                    "questions": [
                        {
                            "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                            "createdAt": "2024-01-18T22:59:16.411",
                            "lastModifiedAt": "2024-01-18T22:59:16.411",
                            "position": "1",
                            "question": "Validación de preguntas ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        },
                        {
                            "uuid": "954ec635-1827-475a-aa0f-dd19b6e01e4e",
                            "createdAt": "2024-01-22T13:41:49.146",
                            "lastModifiedAt": "2024-01-22T13:41:49.146",
                            "position": "0",
                            "question": "Realizar Review ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        }
                    ]
                }
            ]
        },
        {
            "position": "0",
            "domainLevel": 1,
            "domainLevelObtained": 0,
            "valueObtained": 0,
            "competence": {
                "uuid": "462f50c8-595b-4140-8c62-bedd6884258a",
                "createdAt": "2024-01-11T16:32:13.436",
                "lastModifiedAt": "2024-01-11T16:32:13.436",
                "code": 35,
                "name": "Criterio prueba Niveles de dominio",
                "description": "prueba1",
                "status": "ACTIVE",
                "domainLevelList": [
                    {
                        "number": 1,
                        "conductList": [
                            {
                                "uuid": "aad3d490-e848-41eb-9787-614b2329d9ec",
                                "createdAt": "2022-09-07T13:56:13.863",
                                "lastModifiedAt": "2022-09-07T13:56:13.863",
                                "name": "Administra practicas operables que adopta de acuerdo con las necesidades específicas de la organización.\t",
                                "code": 89,
                                "status": "ACTIVE"
                            },
                            {
                                "uuid": "74ae1f86-ac78-4cbf-91b9-ba60af9f462c",
                                "createdAt": "2022-09-07T14:05:51.762",
                                "lastModifiedAt": "2022-09-07T14:05:51.762",
                                "name": "Analiza la información disponible para la toma de decisiones acertadas. \t",
                                "code": 115,
                                "status": "ACTIVE"
                            },
                            {
                                "uuid": "5b14196e-da4f-4858-a988-aa8c848e3819",
                                "createdAt": "2022-09-07T14:06:27.976",
                                "lastModifiedAt": "2022-09-07T14:06:27.976",
                                "name": "Accede a la mayor cantidad de información a fin de tomar una buena decisión. \t",
                                "code": 119,
                                "status": "ACTIVE"
                            }
                        ]
                    }
                ]
            },
            "conducts": [
                {
                    "position": "0",
                    "uuid": "aad3d490-e848-41eb-9787-614b2329d9ec",
                    "createdAt": "2022-09-07T13:56:13.863",
                    "lastModifiedAt": "2022-09-07T13:56:13.863",
                    "name": "Administra practicas operables que adopta de acuerdo con las necesidades específicas de la organización.\t",
                    "code": 89,
                    "status": "ACTIVE",
                    "questions": [
                        {
                            "uuid": "954ec635-1827-475a-aa0f-dd19b6e01e4e",
                            "createdAt": "2024-01-22T13:41:49.146",
                            "lastModifiedAt": "2024-01-22T13:41:49.146",
                            "position": "1",
                            "question": "Realizar Review ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        },
                        {
                            "uuid": "ba483fe7-363a-409a-8811-0ba208a38819",
                            "createdAt": "2024-01-22T13:42:59.602",
                            "lastModifiedAt": "2024-01-22T13:42:59.602",
                            "position": "0",
                            "question": "Pregunta de Formatos ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        }
                    ]
                },
                {
                    "position": "1",
                    "uuid": "74ae1f86-ac78-4cbf-91b9-ba60af9f462c",
                    "createdAt": "2022-09-07T14:05:51.762",
                    "lastModifiedAt": "2022-09-07T14:05:51.762",
                    "name": "Analiza la información disponible para la toma de decisiones acertadas. \t",
                    "code": 115,
                    "status": "ACTIVE",
                    "questions": [
                        {
                            "uuid": "ba483fe7-363a-409a-8811-0ba208a38819",
                            "createdAt": "2024-01-22T13:42:59.602",
                            "lastModifiedAt": "2024-01-22T13:42:59.602",
                            "position": "0",
                            "question": "Pregunta de Formatos ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        }
                    ]
                },
                {
                    "position": "2",
                    "uuid": "5b14196e-da4f-4858-a988-aa8c848e3819",
                    "createdAt": "2022-09-07T14:06:27.976",
                    "lastModifiedAt": "2022-09-07T14:06:27.976",
                    "name": "Accede a la mayor cantidad de información a fin de tomar una buena decisión. \t",
                    "code": 119,
                    "status": "ACTIVE",
                    "questions": [
                        {
                            "uuid": "40a313bf-60bc-4847-a218-f833d32c4a21",
                            "createdAt": "2024-01-18T22:59:16.411",
                            "lastModifiedAt": "2024-01-18T22:59:16.411",
                            "position": "0",
                            "question": "Validación de preguntas ",
                            "answerAlternativeCode": "TEX",
                            "questionAlternativeList": [],
                            "tag": {
                                "uuid": "4ac85494-9ca6-40ab-a2a2-a1dc0bae8adf",
                                "createdAt": "2023-03-09T21:41:02.772",
                                "lastModifiedAt": "2023-03-09T21:41:02.772",
                                "code": 112,
                                "name": "AUTEVALUACIONDOCENTE",
                                "status": "ACTIVE"
                            }
                        }
                    ]
                }
            ]
        }
    ]
}