import React from 'react';
import {Button} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {styles} from '../styles';
import Question from "../questions/createQuestions";
import { useStateValue } from "../../context/store";



export default function CreateNewQuestion(props) {
    const classes = styles();
    const [{ breadCrumb }, ] = useStateValue();


const closeModalEdit = () => props.closeModalCreateQuestion()
  

  return (
    <div >
      <Dialog
       className={classes.widthModalEdit}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleCreteQuestion}>
         CREAR PREGUNTA
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <React.Fragment >
              <Question  
                notDispatch={true} 
                getDataCreate={props?.getDataCreate}
                />
              <div style={{marginTop: '2%'}}>
                <Button variant="outlined"  
                  disableElevation 
                  onClick={()=>closeModalEdit()} 
                  style={{color: "#6D9FFF", marginBottom: 5, borderColor: '#80808070', marginLeft: '40%'}}
                >
                CANCELAR
                </Button>
                    &nbsp;&nbsp;
                <Button
                  type="submit"
                  disabled={breadCrumb?.disabledSubmit}
                  form={breadCrumb?.idForm }
                  variant="contained"
                  style={{ color: "#ffffff", background: '#2F71CB', marginBottom: 5, marginLeft: 5 }}
                >
                    GUARDAR
                </Button>
                </div>
               </React.Fragment>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
        </DialogActions>
      </Dialog>
    </div>
  );
}
