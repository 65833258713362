import React, {useState, useEffect} from 'react'
import { Grid, Typography, Paper } from '@mui/material';
import { useStateValue } from '../../../../../context/store';
import Search from "./search";
import Table from "./tableUsers";
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import ModalConfirm from "../../../../../utils/form/modalConfirmBase";
import { getRegionals, getEmployeesLeaders, getPositions} from '../../../../../actions/OrganizationChart-action';
import ModalActions from "../../../../../components/common";

const Index = (props) => {

    const [{addNewLeaders, loadingTables}, dispatch] = useStateValue();
    const [clearAll, setClearAll] = useState(false);
    const [clearFielForm, setClearFielForm] = useState(false);
    const [allRegionals, setAllRegionals] = useState([]);
    const [allPositions, setAllPositions] = useState([]);

    const [data, ] = useState({
      idKeyword: null,
      nameKeyword: null,
      positionName: null,
      regionalName: null,
      })
      const [paginadorResponse, setPaginadorResponse] = useState({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 0,
      });
      const [paginadorRequest, setPaginadorRequest] = useState({
        page: 0,
        size: 5,
      });
    const [message, setMessage] = useState({
      description : '',
      textButtonSubmit : '',
      textButtonCancel: '',
      handleClick : () => {} ,
      handleClickOut : () => {},
      oneButtons : false,
      title: '',
      list: [],
      type : '',
      open : false
    })
    

    useEffect(()=> {
      
      getRegional();
      getPosition();
      window.scrollBy(0,0)
    },[])

    useEffect(()=> {
  
       if(paginadorResponse?.data?.length ){
           getAllEmployees()
       }
      },[paginadorRequest])



      const getAllEmployees = async() => {
        const result = await getEmployeesLeaders({
          identificationKeyword : data.idKeyword === null ? null : data?.idKeyword,
            nameKeyword: data.nameKeyword === null ? null : data?.nameKeyword,
            positionName: data.positionName === null ? null : data?.positionName,
            regionalName: data.regionalName === null ? null : data?.regionalName,
            page: paginadorRequest?.page === null ? null : paginadorRequest?.page,
            size:  paginadorRequest?.size === null ? null : paginadorRequest?.size,
            
        },
        dispatch,
        // {orderBy: 'createdAt'},
        );
  
      if(result?.status === 204 && !addNewLeaders?.typeAction){
        setMessage({
          open : true,
          description: 'No se encontró información relacionada a la búsqueda',
          handleClick : () => closeModalWarning() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      }
      if(result?.status === 400){
        setMessage({
          open : true,
          description: 'No se encontró información relacionada a la búsqueda',
          handleClick : () => closeModalWarning() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
        setPaginadorResponse({
          data: [],
          totalRows: 0,
          pageSize: 0,
          pageNumber: 0,
        });
      }
      if(result?.status > 399 && result?.status < 405  && !addNewLeaders?.typeAction){
        setMessage({
          open : true,
          description: 'No se encontró información relacionada a la búsqueda',
          handleClick : () => closeModalWarning() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      }
      if(result?.data?.content?.length < 1 && !addNewLeaders?.typeAction){
        setPaginadorResponse({
          data: [],
          totalRows: 0,
          pageSize: 0,
          pageNumber: 0,
        });
        return setMessage({
          open : true,
          description: 'No se encontró información relacionada a la búsqueda',
          handleClick : () => closeModalWarning() ,
          oneButtons : true,
          textButtonSubmit: 'ACEPTAR',
          type : 'WARNING',
        })
      }
      if ((result && result.status === 200) || (result.status === 201)) {
        const data = result?.data;
        if (data?.content?.length > 0) {
          setPaginadorResponse({
            data: data?.content,
            totalRows: data?.totalElements,
            pageSize: data?.size,
            pageNumber: data?.number,
          });
          
        }
        
      } else {
        setPaginadorResponse({
          data: [],
          totalRows: 0,
          pageSize: 0,
          pageNumber: 0,
        });
      }
      }

      if(addNewLeaders &&
        addNewLeaders?.isFilter) {
          const newData = addNewLeaders?.filter
          data.idKeyword = newData?.idKeyword;
          data.nameKeyword = newData?.nameKeyword
          data.positionName = newData?.positionName;
          data.regionalName = newData?.regionalName;
          paginadorRequest.page = newData?.page;
          paginadorRequest.size = newData?.size
          getAllEmployees()
          dispatch({
            type: "ADD_NEW_LEADERS",
            filter: addNewLeaders?.filter,
            isFilter: false,
          })
      }

  
      const dataFilter = () => setPaginadorResponse({
        data: [],
        totalRows: 0,
        pageSize: 0,
        pageNumber: 1,
      });
    
      const changeAmountOfRecords = (event, siguiente) => {
    
        setPaginadorRequest({
          ...paginadorRequest,
          size: event.target.value,
          page: 0 
        });
      };
    
      const changePage = (event, nuevaPagina) => {
       
        setPaginadorRequest({
          ...paginadorRequest,
          page: nuevaPagina,
      
         
        });
      };
  
      const closeModal = () => {
        setMessage({...message, open: false})
        dataFilter();
        setClearFielForm(!clearFielForm);
        clierFilterLeader()
      }
  
      const closeModalWarning = () => {
        setMessage({...message, open: false})
        dataFilter();
        setClearFielForm(!clearFielForm);
      }
  
  
    const clierFilterLeader = () => setClearAll(!clearAll)

      const getRegional = async() => {
        const result = await getRegionals();  
        setAllRegionals(result?.data?.length ? result?.data : [] )
      }

      const getPosition = async() => {
        const result = await getPositions();  
        setAllPositions(result?.data?.length ? result?.data : [] )
      }

      

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item lg={12} style={{marginBottom: loadingTables?.active ? '4%' :'2%'}}>
        <Typography style={{color:'#4B4B4B', fontSize: 18, fontWeight: 500}}>Agregar líder</Typography>
        <Paper style={{background: '#F5F5F5', borderRadius: '10px'}} elevation={3}>
          <Grid container >
          
            <Grid item lg={11} style={{margin: 'auto'}}>
              < Search 
                dataFilter={dataFilter}
                regionals={allRegionals} 
                positions={allPositions} 
                clearFielForm={clearFielForm}
                setMessage={setMessage}
                message={message}
                props={props}
              />
            </Grid>
            <Grid item lg={11} style={{margin: 'auto'}}>
              {loadingTables?.active ?
                <Box sx={{ display: 'flex',margin: '5%', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                  :
                  < Table 
                    data={paginadorResponse?.data} 
                    clearFields={closeModal} 
                    message={message}
                    setMessage={setMessage}
                    page={paginadorResponse?.pageNumber}
                    firsLeader={props?.firsLeader}
                    confirm={props?.confirm}
                    handleClickOut={props?.handleClickOut}
                    handleClick={props?.handleClick}
                    regionals={allRegionals} 
                    positions={allPositions} 
                  /> 
              }
              {paginadorResponse?.totalRows !== 0 && !loadingTables?.active &&
                <TablePagination
                    style={{ display:"flex", color: '#747578', justifyContent: 'center' }}
                    component="div"
                    rowsPerPageOptions={[7]}
                    count={paginadorResponse.totalRows}
                    rowsPerPage={paginadorRequest.size}
                    page={paginadorRequest.page}
                    labelRowsPerPage='Registros por página'
                    onPageChange={changePage}
                    onRowsPerPageChange={(e)=>changeAmountOfRecords(e)}
                />
              }
            </Grid>
          </Grid>   
        </Paper>

      </Grid>
        
        
      <ModalConfirm 
        description = { message.description }
        textButtonSubmit = { message.textButtonSubmit }
        textButtonCancel = {message.textButtonCancel}
        handleClick = { message.handleClick }
        handleClickOut = { message.handleClickOut }
        list = {message.list}
        oneButtons = { message.oneButtons }
        title = { message.title }
        type = { message.type }
        open = { message.open }
      />
      <ModalActions/>
    </Grid>
  )
}

export default Index