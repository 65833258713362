export const initialState = {
    data: {},
  };
  
  const headerAplicationReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_HEADER_APLICATION":
        return {
          ...state,
          data: action.data,
        };
      default:
        return state;
    }
  };
  
  export default headerAplicationReducer;
  