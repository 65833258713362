import React,{ useEffect, useState} from "react";
import { withRouter } from "react-router-dom";
// import { logoutUser } from "../../actions/loginActions";
import { useStateValue } from "../../context/store";
import Logo from "../../images/Logo_autoevaluciones.png";
import LogoutIcon from '@mui/icons-material/Logout';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Menu } from "@mui/icons-material";
import clsx from "clsx";
import { resetDispatchs } from "../../constants";


const useStyles = makeStyles((theme) => ({
  appBar: {
    minHeight: 42,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#6D9FFF",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarClose: {
    backgroundColor: "#6D9FFF",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShiftClose: {
    marginLeft: '20%',
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 2
  },
  appBarShift: {
    marginLeft: '20%',
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    minHeight: 48,
    display: "flex",
    justifyContent: "space-between",
    background: "#6D9FFF",
    boxShadow: '10px 5px 5px #00000014',
  },
  toolbarIconsContainer: {
    display: "flex",
    alignItems: "center",
    color: "white",
    marginTop: 3,
    '& .MuiButtonBase-root':{
      padding: '9px !important',
    },
  },
  menuButton: {
    marginLeft: "-20px!important",
    color: '#ffffff',

  },
  hide: {
    display: "none",
  },
  avatar: {
    marginLeft: ".6em",
    width: theme.spacing(3),
    height: theme.spacing(4),
    cursor: 'pointer'
  },
  icons: {
    color: '#ffffff'
  },
}));

const Header = ({ toggleDrawer, isOpen, history, contenValueHome, state }) => {

  const classes = useStyles();
  const [{ breadCrumb }, dispatch] = useStateValue();
  const [newValueHome] = useState(0);

  useEffect(()=> {
    contenValueHome(newValueHome);
  },[])


  const exit = () => {
    dispatch({
      type: 'LOGIN_SESSION',
    });
    resetDispatchs(dispatch);
    dispatch({
      type: 'LOGIN_TOKEN',
      token: ''
    })
    localStorage.removeItem('typesEvaluation')
    history.push("/auth/login")
  }
  return (
    <AppBar
    elevation= { 0 }
      id="headerComponent"
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: !state.left,
        [`${classes.appBarClose} ${classes.appBarShiftClose}`]: state.left,
      })}
    >
      <Toolbar id="toolbarComponent" className={classes.toolbar}>
        <div className={classes.toolbarIconsContainer}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer('left', !state.left)}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: state.left,
            })}
          >
           
            <Menu />
          </IconButton>
          <img
            src={Logo}
            width="150"
            height="29"
            alt=""
            style={{ paddingLeft: "20px", marginRight: 15}}
          />
          <div className={classes.toolbarIconsContainer}>
          <Typography style={{ fontSize: "13px", color: "#ffffff" }}>
            {breadCrumb?.breadCrumbText }
          </Typography>
        </div>
        </div>
        <div className={classes.toolbarIconsContainer}>
          {/* <IconButton fontSize="small" className={classes.icons}>
            <SearchOutlinedIcon />
          </IconButton>
          <IconButton fontSize="small" className={classes.icons}>
            <AppsOutlinedIcon />
          </IconButton> */}
          {/* <IconButton fontSize="small" className={classes.icons}>
            <HomeOutlinedIcon  onClick={() => handleListItemClick('Inicio')}/>
            <HomeOutlinedIcon  onClick={() => history.push( `/autoevaluaciones/home`)}/>

          </IconButton> */}
          {/* <IconButton fontSize="small" className={classes.icons}>
            <NotificationsNone />
          </IconButton>
          <IconButton fontSize="small" className={classes.icons}>
            <ContactSupportOutlined />
          </IconButton> */}
          <Tooltip title="Cerrar sesión" arrow>
            <LogoutIcon
              src={require(`../../images/user1.jpg`)}
              className={classes.avatar}
              onClick={()=>exit() }
            />
          </Tooltip>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Header);
