import React, { useState } from "react";
import * as IdKey from "short-uuid";
import { 
  Typography,
  Tooltip,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useStyles } from "./styles";

import ModalEdit from './../edit';
import ModalDelete from '../../../utils/form/modalConfirmBase'
import { deleteQuestion } from '../../../actions/question-action';
import { useStateValue } from './../../../context/store';
import { typesQuestions } from "../../../constants";



export default function DetailedAccordion({
  setMessage,
  message,
  data,
  setScroll,
}) {
  const classes = useStyles();
  const id = IdKey.generate();
  const [{ questionSearch }, dispatch] = useStateValue();
  const [openEdit, setOpenEdit] = useState(false);
  const [dataQuestion, setDataQuestion] = useState({})
  const [showText, setShowText] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [messageDelete,] = useState({
    open: false,
    type: '',
    description: '',
    handleClickOut: () => {},
    handleClick: () => {}
  })

  
  const closeModal = () => setMessage({...message, open:false})
  const closeAndReset = () => {
    dispatch({
      type: "SET_FILTER_QUESTION",
      filter: questionSearch?.filter,
      isFilter: true,
      typeAction: 'DELETE'
    })
    closeModal()
  }

  const deleteQuestions = async(data) => {
    const result = await deleteQuestion(data?.uuid)
    if (result && (result?.status === 200 || result?.status <= 204)) {
      return  setMessage({
        open : true,
        type : 'SUCCESS',
        oneButtons : true,
        title: '¡Proceso exitoso!',
        textButtonSubmit: 'ACEPTAR',
        handleClick : () => closeAndReset(),
        description: 'Información eliminada correctamente ',
          })
    }
    else if (result?.status === 400 ) {
      setMessage({
        open : true,
        description: result?.data?.message,
        handleClick : () => closeModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    } else {
      setMessage({
        open : true,
        description: 'Ocurrió un error procesando su solicitud, inténtelo nuevamente',
        handleClick : () => closeModal() ,
        oneButtons : true,
        type : 'WARNING',
      })
    }
  }
  

const changeStateModal = () => {setScroll(); setOpenModal(!openModal)}
const confirmOpenDelete = (data) => {
  return  setMessage({
    open : true,
    description: '¿Está seguro de eliminar la pregunta?',
    handleClick : () => deleteQuestions(data),
    handleClickOut: () => closeModal(),
    type : 'DELETE',
      })
}
const showQuestion = (data, id) => {
  const result = showText.find((x)=> x?.id === id)
  if(result?.show) {  
    return data 
  } else {
    return `${data.substring(0, 400)}` 
  }
}

  const allShow = (id) => {
   const result = showText.find((x)=> x?.id === id)
   if(result?.show) {
     setShowText([{show: false, id: id}])
   }else {
    setShowText([{show: true, id: id}])
   }
  }

  const validateShowText = (id) => {
   const result = showText.find((x)=> x?.id === id)
    return result?.show ? true : false
  }


if(data?.length) {
  return (
    <div className={classes.root}>
      <Paper elevation={0} style={{ background: 'inherit' }}>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="caption table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Etiqueta
                </TableCell>
                <TableCell align="left" width={"20%"} className={classes.wrapper}>
                  Tipo
                </TableCell>
                <TableCell align="left" width={"50%"} className={classes.wrapper}>
                  Pregunta
                </TableCell>
                <TableCell align="left" width={"10%"} className={classes.wrapper}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((data, idColumn = id) => (
                <TableRow key={idColumn}>
                  <TableCell align="left" width={"20%"} className={classes.header}
                    style={{
                      borderTopLeftRadius: idColumn === 0 ? 10 : 0,
                      borderBottomLeftRadius: idColumn === data?.length - 1 ? 10 : 0
                    }}>
                    {data?.tag?.name}

                  </TableCell>
                  <TableCell align="left" width={"20%"} className={classes.header}>
                    {typesQuestions[data?.answerAlternativeCode]}
                  </TableCell>
                  <TableCell align="left" width={"50%"} className={classes.header}>
                    {data?.question?.length > 300 ? showQuestion(data?.question, idColumn) : data?.question}
                    {data?.question?.length > 300 
                      &&
                      <Typography variant="caption"
                        className={classes.expandText}
                        onClick={() => allShow(idColumn)}>{validateShowText(idColumn) ? "...ver menos" : "...ver más"}
                      </Typography>
                    }                  
                  </TableCell>
                  <TableCell align="left" width={"10%"} className={classes.header}
                    style={{
                      borderTopRightRadius: idColumn === 0 ? 10 : 0,
                      borderBottomRightRadius: idColumn === data?.length - 1 ? 10 : 0
                    }}  >
                    <div className={classes.columnEvent} >
                      &nbsp;&nbsp;
                      <Tooltip title="Editar" arrow >
                        <EditIcon
                          onClick={() => { setOpenEdit(true); setOpenModal(!openModal); setDataQuestion(data) }}
                          style={{ marginLeft: '10%' }}
                          className={classes.iconEvent}
                        />
                      </Tooltip>
                      &nbsp;&nbsp;
                      <Tooltip title="Eliminar" arrow >
                        <DeleteIcon
                          onClick={() => confirmOpenDelete(data)}
                          className={classes.iconEvent}
                        />
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {openEdit ? 
        <ModalEdit 
          open={openModal}
          setOpenModal={changeStateModal}
          dataQuestion={dataQuestion}
          isEdit={true}
        /> : null }
           <ModalDelete 
            open={messageDelete.open}
            type={messageDelete.type}
            description={messageDelete.description}
            handleClick={messageDelete.handleClick}
            handleClickOut={messageDelete.handleClickOut}
          />
    </div>
  );
      }else {
        return null;
      }
}
