import { makeStyles } from '@mui/styles';
import Login from '../../images/Inicio_session.png';
import theme from "../../theme/theme";


export const styles = makeStyles({
    paper: {
      marginTop: 8,
      display: "flex",
      flexDirection: "column",
      marginLeft: "25px"
    },
    containerImageLogin: {
      [theme.breakpoints.only('lg')]:{
        height: '100vh',
        background: '#BEE1E6',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginLeft: 10
      },
      [theme.breakpoints.only('md')]:{
        height: '100vh',
        background: '#BEE1E6',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginLeft: 10
      },
      [theme.breakpoints.only('sm')]:{
        height: '100vh',
        background: '#BEE1E6',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginLeft: 10
      },
      [theme.breakpoints.only('xs')]:{
        height: '100vh',
        background: '#BEE1E6',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginLeft: 10
      },
      [theme.breakpoints.down('md')]:{
        height: '100vh',
        width: '100vw',
        background: '#BEE1E6',
        position: 'relative',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginLeft: 10
      }
     
    },
    containerForm :{
     
      [theme.breakpoints.only('sm')]:{
        display: 'grid',
        placeItems: 'center',
        position: 'absolute',
      },
      [theme.breakpoints.down('sm')]:{
        position: 'absolute',
      },
      [theme.breakpoints.only('xs')]:{
        display: 'grid',
        placeItems: 'center'
      }
    },
    form: {
      [theme.breakpoints.only('lg')]:{
        padding: 20,
      },
      [theme.breakpoints.only('sm')]:{
        height: '100vh',
        width: '100vw',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginLeft: 10 
      },
      [theme.breakpoints.down('sm')]:{
        height: '100vh',
        width: '100vw',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginLeft: 10 
      },
     
    },
    containerImage: {
      display: 'flex', 
      placeItems: 'center',
      background: '#BEE1E6',
      height: '100vh'
    },
    submit: {
      width: "100%",
      background: '#6D9FFF',
      color: 'white',
      padding: "10px",
      marginBottom: '20px',
      borderRadius: 20,
      textTransform: 'capitalize',
      '&:hover': {
        color: 'white',
        background: '#6D9FFF'
      }
    },
    submitRegister: {
      width: "100%",
      background: '#6F83CC',
      color: 'white',
      padding: "10px",
      marginBottom: '20px',
      borderRadius: 20,
      '&:hover': {
        color: 'white',
        background: '#6F83CC'
      }
    },
    buttonBack : {
      width: "100%",
      background: '#6F83CC',
      color: 'white',
      padding: "10px",
      marginBottom: '20px',
      borderRadius: 20,
      '&:hover': {
        color: 'white',
        background: '#3451BE'
      }
    },
    error: {
      "&.MuiFormHelperText-root.Mui-error": {
        color: "red"
      }
    },
    titleOne: {
      fontFamily: "Roboto",
      fontStyle: "Medium",
      fontSize: "24px",
      lineHeight: "28px",
      align: "left",
      verticalAlign: "top",
      letterSpacing: "-0.06px",
      textAlign: "center",
      weight: 700,
    },
    formControl: {
      width: "100%"
    },
    mt10: {
      backgroundColor: '#ffffff',
      marginTop: "15px",
      borderRadius: 10,
      [`& fieldset `]: {
        borderRadius: 10,
        fontSize: 14,
      },
      [`& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input `]: {
        borderRadius: 10,
        fontSize: 14,
        backgroundColor: "#ffffff"
      },
    },
    container: {
      display: 'flex',
    },
    iconLogin: {
      color: '#646464',
    },
    mt40: {
      display: 'flex',
      alignItems: 'center', 
      justifyContent: 'space-between',
    },
    regetPassword: {
       marginBottom: '15%',
       marginRight: 'auto',
       marginLeft: 'auto'
    },
  papper: {
      width: '700px',
      alignContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      top: 'auto',
      margin: 'auto',
      marginTop: '15%',
  },
  footer: {
      alignContent: 'left',
      flexDirection: 'column',
      margin: 'auto',
      paddingLeft: '20px',
  },
  imgGradientRegister: {
      height: 'inherit',
      width: '50%', 
      marginBottom: '20%',
  },
  imgGradientLogin: {
    height: 'inherit',
    width: '50%', 
},
  textGradient: {
      textAlign: 'center',
      color: 'white',
      fontSize: 15,
      marginTop: '20%',
  },
  containerFormLogin:{
      position: 'sticky',
      margin: ' 5% 10% '
  },
  containerCardRegister:{
    position: 'sticky',
    margin: ' 5% 3% '
},
  containerFormRegister:{
    position: 'absolute',
    right: '10%',
    top: 0
},
  
backgroundLogin: {
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(${Login})`,
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      backgroundSize: 'cover',
      height: 'inherit',
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: `url(${Login})`,
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      backgroundSize: 'cover',
      height: 'inherit',
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${Login})`,
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      backgroundSize: 'cover',
      height: 'inherit',
    },
  },
 
  forgot: {
    fontSize: 18,
    fontFamily: 'Roboto',
    color: '#000000',
    fontWeight: 'normal',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  register: {
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center'
  },
  redirectRegister: {
    color: '#099F9F',
    fontSize: 11,
    fontWeight: 'bold',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  textRegister: {
    color: 'black',
    fontSize: 11,
    margin: '5px 0px 0px 0px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    
  },
  backgroundRegister: {
    backgroundImage: `url(${Login})`,
    backgroundRepeat: 'round',
    backgroundSize: 'cover',
    width: 'fit-content',
  },
  fontLogin: {
    height: '100vh',
    backgroundImage: `url(${Login})`,
    backgroundRepeat: 'round',
    backgroundSize: 'cover',
    width: 'fit-content',
  },
  menuItem: {
    "&:hover": {
      borderLeft: "4px solid #098B8B",
    },
  },
  cursorPointer: {
    cursor: "pointer"
  },
  borderImageLogo: {
    
  },
  classContainerLogos: {
    flexDirection: 'row',
     alignItems: 'center',
     margin: '3% 0px',
    //  width: '60%'
  },
  classContainerLogosRegister: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: '3% 0px',
    width: '60%'
  },
  classCardLogos: {
    display: 'flex',
    flexDirection: 'column'
  },

  titleOneRecovery: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "-0.06px",
    color: '#3451BE',
    textAlign: "center"
},
titleTwo: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "-0.05px",
    textAlign: "center"
},

  stylesTypography: {
    color: '#3451BE',
    fontSize: 35,
    fontWeight: 700,
    fontFamily: "Roboto",
    textAlign: "center"
  },
  stylesTypographyRestorePassword: {
    color: '#3451BE',
    fontSize: 30,
    fontWeight: 700,
    fontFamily: "Roboto",
    textAlign: "center",
    marginBottom: '5%'
  },
  fontSize14: {
    fontSize: 14,
    textAlign: "center"
  },
   
  marginMessageSuccess : {
    marginBottom: '15%'
  },
  submitRestorePassword: {
    width: "100%",
    background: '#3451BE',
    color: 'white',
    padding: "10px",
    fontSize: 13,
    marginBottom: '20px',
    borderRadius: 20,
    '&:hover': {
      color: 'white',
      background: '#3451bebf'
    }
  },
  buttonBackRestorePassword : {
    width: "100%",
    background: '#6F83CC',
    color: 'white',
    padding: "10px",
    fontSize: 13,
    marginBottom: '20px',
    borderRadius: 20,
    '&:hover': {
      color: 'white',
      background: '#3451BE'
    }
  },
  dropdown: {
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "8px",
    height: 40,
    fontSize: "14px",
    [`& fieldset`]: {
      borderRadius: 8,
      fontSize: 14,
    },

  },

  });

  