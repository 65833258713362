// localStorage.js
export const loadState = () => {
    try {
      const serializedState = localStorage.getItem('token_app');
      if (serializedState === null) {
        return {};
      }
  
      return JSON.parse(serializedState);
    } catch (err) {
      return {};
    }
  };
  
  export const loadSession = () => {
    try {
      const serializedState = localStorage.getItem('session');
      if (serializedState === null) {
        return {};
      }
  
      return JSON.parse(serializedState);
    } catch (err) {
      return {};
    }
  };

  export const saveToken= (state) => {
    try {
      localStorage.setItem('token_app', state?.token);
    } catch {
      // ignore write errors
    }
  };
  
  export const loadBreadCrum = () => {
    try {
      const serializedState = localStorage.getItem('breadCrum');
      if (serializedState === null) {
        return {};
      }
  
      return JSON.parse(serializedState);
    } catch (err) {
      return {};
    }
  };

  
  export const saveSession = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('session', serializedState);
    } catch (err) {
      return {};
    }
  };