import HttpClient from '../services/HttpClient';
import { APLICATION, PERFORMANCEV2, EVALUATION_EVALUATED, RESULT, GENERALRESULT, EVALUATED, EVALUATOR, BY_EVALUATED, BY_PROGRAM } from '../constants'



export const getfilterAplicationsEvaluations = (dataFilter, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

        const keys = ['nameKeyword', 'createdAtStartDate', 'createdAtEndDate'];

        for (let index = 0; index < keys?.length; index++) {
          if(dataFilter[keys[index]] ){
              if(keys[index] === 'nameKeyword' && dataFilter[keys[index]]){
                filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
              }else {
                filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
              }
          } else {
              continue
          }
        }
        HttpClient.get(`${PERFORMANCEV2}${APLICATION}${filters}`).then(response => {
            resolve(response);
             dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
             dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
          if(error.response?.status === 500){
            dispatch({
              type: "SET_MODAL_ACTION",
              open: true,
              icon:'WARNING',
              message: 'Occurió un problema comuniquese con el administrador.'
            })
          }  
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getfilterDataGraphics = (dataFilter, id, dispatch) => {
  dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
  return new Promise((resolve, reject) => {
      let filters = `${GENERALRESULT}?`

      const keys = ['positionName', 'regionalName'];

      for (let index = 0; index < keys?.length; index++) {
        if(dataFilter[keys[index]] ){
            if(keys[index] === 'nameKeyword' && dataFilter[keys[index]]){
              filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
            }else {
              filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
            }
        } else {
            continue
        }
      }
      HttpClient.get(`${PERFORMANCEV2}${APLICATION}/${id}${filters}`).then(response => {
          resolve(response);
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
      }).catch(error => {
        resolve(error.response);
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false }); 
          if (typeof error.response === 'undefined') {
              console.log('A network error occurred. ')
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}

export const getByIdAplicationEvaluationAdmin = (id, dispatch) => {
  // dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
  return new Promise((resolve, reject) => {
      HttpClient.get(`${PERFORMANCEV2}${APLICATION}/${id}`).then(response => {
          resolve(response);
          //  dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
      }).catch(error => {
          //  dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
          if (typeof error.response === 'undefined') {
              console.log('A network error occurred. ')
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}

export const getByIdAplicationEvaluation = (id, dispatch) => {
    dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
    return new Promise((resolve, reject) => {
        HttpClient.get(`${EVALUATION_EVALUATED}/${id}${RESULT}`).then(response => {
            resolve(response);
             dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        }).catch(error => {
             dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
            if (typeof error.response === 'undefined') {
                console.log('A network error occurred. ')
                reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
            }
            reject(error.response);
            resolve(error.response);
        });
    });
}

export const getByIdAplicationEvaluationGeneral = (id, dispatch) => {
  dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
  return new Promise((resolve, reject) => {
      HttpClient.get(`${PERFORMANCEV2}${APLICATION}/${id}${GENERALRESULT}`).then(response => {
          resolve(response);
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
      }).catch(error => {
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
          if (typeof error.response === 'undefined') {
              console.log('A network error occurred. ')
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}

export const getByIdUserAplicationEvaluation = (idAply, userId, dispatch) => {
  dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
  return new Promise((resolve, reject) => {
      HttpClient.get(`${PERFORMANCEV2}${APLICATION}/${idAply}/result?userUuid=${userId}`).then(response => {
          resolve(response);
          dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
      }).catch(error => {
          dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
          if (typeof error.response === 'undefined') {
              console.log('A network error occurred. ')
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}


export const getfilterEvaluatedsApplication = (idApply, dataFilter, dispatch) => {
  dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
  return new Promise((resolve, reject) => {
      let filters = `?pageNumber=${dataFilter.page}&pageSize=${dataFilter.size}`

      const keys = ['programId', 'positionName'];

      for (let index = 0; index < keys?.length; index++) {
        if(dataFilter[keys[index]] ){
            if(keys[index] === 'nameKeyword' && dataFilter[keys[index]]){
              filters = `${filters}&${keys[index]}=${encodeURIComponent(dataFilter[keys[index]])}`
            }else {
              filters = `${filters}&${keys[index]}=${dataFilter[keys[index]]}`
            }
        } else {
            continue
        }
      }
      HttpClient.get(`${PERFORMANCEV2}${APLICATION}/${idApply}${EVALUATED}${filters}`).then(response => {
          resolve(response);
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
      }).catch(error => {
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
        if(error.response?.status === 500){
          dispatch({
            type: "SET_MODAL_ACTION",
            open: true,
            icon:'WARNING',
            message: 'Occurió un problema comuniquese con el administrador.'
          })
        }  
          if (typeof error.response === 'undefined') {
              console.log('A network error occurred. ')
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}


export const getResultEvaluationEvalutor = (idAply, evaluatorId, evaluatedId, type, dispatch) => {
  dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
  return new Promise((resolve, reject) => {
      HttpClient.get(`${PERFORMANCEV2}${APLICATION}/${idAply}/individual-result?evaluatorId=${evaluatorId}&evaluatedId=${evaluatedId}&evaluationType=${type}`).then(response => {
          resolve(response);
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
      }).catch(error => {
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
          if (typeof error.response === 'undefined') {
              console.log('A network error occurred. ')
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}

export const getResultEvaluationEvaluated = (idAply, evaluatedId, dispatch) => {
  dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
  return new Promise((resolve, reject) => {
      HttpClient.get(`${PERFORMANCEV2}${APLICATION}/${idAply}/individual-result?evaluatedId=${evaluatedId}`).then(response => {
          resolve(response);
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
      }).catch(error => {
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
          if (typeof error.response === 'undefined') {
              console.log('A network error occurred. ')
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}

export const getResultGeneral = (idAply, dispatch) => {
  dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
  return new Promise((resolve, reject) => {
      HttpClient.get(`${PERFORMANCEV2}${APLICATION}/${idAply}/general-result`).then(response => {
          resolve(response);
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
      }).catch(error => {
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
          if (typeof error.response === 'undefined') {
              console.log('A network error occurred. ')
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}

export const evaluatorsByEvaluated = (idAply, identificationEvaluated, programId, dispatch) => {
  dispatch({ type: 'SET_ACTIVE_SPINNER', active: true });
  return new Promise((resolve, reject) => {
      HttpClient.get(`${PERFORMANCEV2}${APLICATION}/${idAply}${EVALUATOR}${BY_EVALUATED}/${identificationEvaluated}${BY_PROGRAM}/${programId}`).then(response => {
          resolve(response);
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
      }).catch(error => {
           dispatch({ type: 'SET_ACTIVE_SPINNER', active: false });
          if (typeof error.response === 'undefined') {
              console.log('A network error occurred. ')
              reject({ error: 'technical_error', error_description: 'Occurió un problema comuniquese con el administrador.' });
          }
          reject(error.response);
          resolve(error.response);
      });
  });
}

export const enableEvaluator = ( idAplication, idEvaluated, idEvaluator, body, dispatch ) => {
  return new Promise((resolve, reject) => {
      HttpClient.put(`${PERFORMANCEV2}${APLICATION}/${idAplication}${EVALUATED}/${idEvaluated}?evaluatorUuid=${idEvaluator}`, body).then(response => {
          resolve(response);
      }).catch(error => {
          resolve(error.response)
      })
  })
}

export const updateObservation = (body, idAplication, evaluatedId) => {
  return new Promise((resolve, reject) => {
      HttpClient.patch(`${PERFORMANCEV2}${APLICATION}/${idAplication}/individual-result?evaluatedId=${evaluatedId}`, body).then(response => {
          resolve(response);
      }).catch(error => {
          resolve(error.response)
      })
  })
}