import React,{useState, useEffect} from 'react';
import { Grid, Typography} from '@mui/material';
import BehaviorsList from "./behaviorsList";
import { styles } from "./styles";
import { useStateValue } from '../../../../../context/store';
import ModalSections from "../../createSection";
import _ from "lodash";
import Section from "./sections";


const Index = (props) => {
    const classes = styles();
    const [{relations}, ] = useStateValue()
    const [display, setDisplay] = useState([])
    const [, setCheckeds] = useState([])
    const [, setDomains] = useState({});
    const [openSection, setOpenSection] = useState(false);


   useEffect(()=> {
    let initialDomains = {}
    for (let index = 0; index < props?.data?.length; index++) {
        initialDomains = Object.assign(initialDomains, {[props?.data[index]?.uuid] :props?.data[index]?.domainLevel })
    }
    setDomains(initialDomains);

    if(!props?.data?.length ){
        setCheckeds([])
    }else {
        return null
    }
   
   },[props?.data])

   
    const showOptions = (x) => {
        const findUuid = display?.find((f)=> f === x)
        const differentUuid = display?.filter((f)=> f !== x)

        if(findUuid) {
            setDisplay(differentUuid)
        } else {
            let currentData = [...display]
            currentData.push(x)

            setDisplay(currentData)
        }
    }

    const validateUuid = (x) => {
        const findUuid = display?.find((f)=> f === x)
        return findUuid ? true : false
    }

    useEffect(() => {
        const selectedCompetencie = relations?.data.find((x)=> x?.uuid === relations?.competencieSelected)
        setCheckeds([selectedCompetencie])
    }, [relations?.competencieSelected])



    const handleCloseSection = () => setOpenSection(!openSection);

    const mapSections = () => {
        if(relations?.dataSections?.length) {
            return _.orderBy(relations?.dataSections, ['position'],['asc'])?.map((x, index)=> (
                        <Grid item container lg={11.8} md={12} style={{ border: '2px solid #979191', borderRadius: 5, marginBottom: '1%', marginRight: '1.5%'}} key={index}>
                            <Section 
                                isEdit={props?.isEdit}
                                isCopy={props?.isCopy}
                                currentData={props?.currentData}
                                dataScton={x} 
                                message={props?.message}
                                setMessage={props?.setMessage}
                            />
                        </Grid>
            ))
        } else {
            return null
        }
    }


  return (
      
        <Grid item  container style={{position: 'relative'}}>
            <Grid item container style={{background: !props?.modelDetail && '#ffffff'}}>
                { _.orderBy(props?.data, ['position'],['asc'])?.map((x, index)=> {
                    if(!x?.section){
                        
                        return<>
                            <Grid item lg={2.5} className={classes.competences}> 
                                <Typography style={{paddingLeft: 8}}>{x?.name}</Typography>
                            </Grid>
                            <Grid item container lg={9.5}>
                            <Grid item container lg={12}>
                      
                            <BehaviorsList 
                                data={x?.behaviors} 
                                idCompetence={x?.uuid} 
                                modelDetail={props?.modelDetail}
                                openQuestions={props?.openQuestions}
                                messageQuestions={props?.messageQuestions}
                                typesEvaluators={props?.typesEvaluators}
                                isCopy={props?.isCopy}
                            />
                                
                            </Grid>
                        
                            </Grid>
                        </>
                    }
                })}

            </Grid> 
            {openSection && <ModalSections open={openSection} handleCloseSection={handleCloseSection} message={props?.message}
                        setMessage={props?.setMessage}/>
            }
        </Grid>
  )
}

export default Index